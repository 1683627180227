import React, { useState, useEffect, useCallback } from 'react';
import { Form, Drawer, Button, Col, Row, Icon, Modal, Message, Tooltip } from 'antd';
import moment from 'moment';

import FormDatePickerRange from 'components/FormDatePickerRange/FormDatePickerRange';
import FormSelection from 'components/FormSelection/FormSelection';
import ShowMoreList from 'components/ShowMoreList/ShowMoreList';
import styles from './seasonalRuleDetails.module.css';
import { putSeasonalRuleTimeline, deleteSeasonalRule } from 'utils/apis/seasonalRule';

const SET_NEW_RULE = 'Add';
const NONE = 'None';

const useGenerateSeasonalRuleDetail = (
  ruleGroupDetails,
  selectedTimelineRule,
  onClickShowEditModal,
  setSelectedRuleId,
  selectedRuleId,
  setFieldsValue
) => {
  const [selectedRuleDetail, setSelectedRuleDetail] = useState({});
  const [ruleOptions, setRuleOptions] = useState([]);
  const [ruleStartDate, setRuleStartDate] = useState(undefined);
  const [ruleEndDate, setRuleEndDate] = useState(undefined);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const generateRuleOptions = useCallback((ruleGroupDetails, selectedRuleId) => {
    const noneOption = [
      {
        key: NONE,
        value: NONE,
        displayValue: 'None'
      }
    ];
    const addNewOption = [
      {
        key: SET_NEW_RULE,
        value: SET_NEW_RULE,
        displayValue: 'Add rule-set...'
      }
    ];

    // For sake of output existed rule with no name
    const ruleGroupOptions = ruleGroupDetails
      .filter(rule => rule.airbnbSeasonalRuleGroupId === selectedRuleId || rule.title)
      .map(rule => ({
        key: rule.airbnbSeasonalRuleGroupId,
        value: rule.title || 'Custom Rule',
        displayValue: rule.title || 'Custom Rule'
      }));

    return noneOption.concat(ruleGroupOptions).concat(addNewOption);
  }, []);

  const handleOnIsSaving = boolean => {
    setIsSaveLoading(boolean);
  };

  const handleOnLoad = useCallback(() => {
    if (selectedTimelineRule) {
      const { sinceDate, endDate } = selectedTimelineRule;
      const selectedRuleGroupId = selectedTimelineRule.airbnbSeasonalRuleGroupId;
      setSelectedRuleId(selectedRuleGroupId);
      setRuleStartDate(sinceDate);
      setRuleEndDate(endDate);
    }
  }, [selectedTimelineRule, setSelectedRuleId]);

  useEffect(() => {
    handleOnLoad();
  }, [handleOnLoad]);

  useEffect(() => {
    if (selectedRuleId === NONE || selectedRuleId === SET_NEW_RULE) {
      setSelectedRuleDetail({});
    } else {
      const selectedRuleDetail = ruleGroupDetails.find(ruleGroupDetail => ruleGroupDetail.airbnbSeasonalRuleGroupId === selectedRuleId);
      setSelectedRuleDetail(selectedRuleDetail);
    }
  }, [selectedRuleId, ruleGroupDetails]);

  useEffect(() => {
    setRuleOptions(generateRuleOptions(ruleGroupDetails, selectedRuleId));
  }, [selectedRuleId, ruleGroupDetails, generateRuleOptions]);

  const handleOnSelectChange = selectedRuleId => {
    setSelectedRuleId(selectedRuleId);
    if (selectedRuleId === SET_NEW_RULE) {
      setSelectedRuleId(null);
      setFieldsValue({
        ruleSet: NONE
      });
      onClickShowEditModal();
    } else {
      if (selectedRuleId === NONE) {
        setSelectedRuleId(null);
      } else {
        setSelectedRuleId(selectedRuleId);
      }
    }
  };

  return {
    selectedRuleId,
    selectedRuleDetail,
    ruleOptions,
    ruleStartDate,
    ruleEndDate,
    handleOnIsSaving,
    isSaveLoading,
    handleOnSelectChange
  };
};

const SeasonalRuleDetails = ({
  form,
  isShowDrawer,
  ruleGroupDetails,
  selectedTimelineRule,
  onClickOpenDrawer,
  onClickCloseDrawer,
  onClickShowEditModal,
  isFetchLoading,
  refetchRuleTimeline,
  refetchRuleGroup,
  airbnbListingId,
  setSelectedRuleId,
  selectedRuleId,
  airbnbListings
}) => {
  const { setFieldsValue } = form;
  const {
    selectedRuleDetail,
    ruleOptions,
    ruleStartDate,
    ruleEndDate,
    handleOnIsSaving,
    isSaveLoading,
    handleOnSelectChange
  } = useGenerateSeasonalRuleDetail(ruleGroupDetails, selectedTimelineRule, onClickShowEditModal, setSelectedRuleId, selectedRuleId, setFieldsValue);

  const generateDateRange = (ruleStartDate, ruleEndDate) => {
    if (ruleStartDate && ruleEndDate) {
      return [moment(ruleStartDate), moment(ruleEndDate)];
    } else {
      return [];
    }
  };

  const handleOnDeleteRule = e => {
    e.preventDefault();

    const seasonalRuleGroupId = form.getFieldValue('ruleSet');

    Modal.confirm({
      icon: <Icon type="warning" />,
      title: `Delete rule "${selectedRuleDetail.title}"?`,
      content: (
        <div>
          <p>All existing dates assigned with "{selectedRuleDetail.title}" rule will be removed</p>
          <p>
            <b>Note</b>: If you only wanted to remove the rules in the current date range, please select "None" in the Rule-Sets and press "Save"
            instead.
          </p>
        </div>
      ),
      onOk() {
        handleOnIsSaving(true);
        return deleteSeasonalRule(airbnbListingId, seasonalRuleGroupId)
          .then(() => {
            Message.success('Successfully deleted seasonal rule timeline');
            handleOnIsSaving(false);
            refetchRuleGroup();
            refetchRuleTimeline();
          })
          .catch(ex => {
            handleOnIsSaving(false);
            Message.error(ex.toString());
          });
      },
      onCancel() {
        handleOnIsSaving(false);
      }
    });
  };

  const handleOnFormSubmit = e => {
    e.preventDefault();

    const formatDate = momentDate => {
      return moment(momentDate).format('YYYY-MM-DD');
    };

    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const sinceDate = formatDate(values.dateRange[0]);
        const endDate = formatDate(values.dateRange[1]);
        const seasonalRuleGroupId = values.ruleSet === NONE ? null : values.ruleSet;
        const note = values.note ? values.note : null;
        const airbnbListingIds = values.airbnbListingIds || [];

        Modal.confirm({
          icon: <Icon type="warning" />,
          title: selectedRuleDetail
            ? `Update current timeline, ${sinceDate} to ${endDate}, with rule ${selectedRuleDetail.title}?`
            : `Remove all rules between ${sinceDate} to ${endDate}?`,
          content: `This action will overwrite any existing overlapping dates.`,
          onOk() {
            handleOnIsSaving(true);
            return Promise.all(
              airbnbListingIds.map(airbnbListingId => {
                return putSeasonalRuleTimeline(airbnbListingId, sinceDate, endDate, seasonalRuleGroupId, note).catch(ex => {
                  Message.error(ex.toString());
                });
              })
            )
              .then(() => {
                Message.success('Successfully applied seasonal rule timeline to listing(s)');
                handleOnIsSaving(false);
                refetchRuleTimeline();
              })
              .catch(() => {
                handleOnIsSaving(false);
              });
          },
          onCancel() {
            handleOnIsSaving(false);
          }
        });
      }
    });
  };

  if (selectedRuleId === SET_NEW_RULE || !ruleOptions.filter(ruleOption => ruleOption.key === selectedRuleId).length > 0) {
    selectedRuleId = NONE;
    form.resetFields(['ruleSet']);
  }

  return (
    !isFetchLoading && (
      <Drawer
        width={isShowDrawer ? '360px' : '60px'}
        height={'100vh'}
        className={styles.drawerOpen}
        style={{ position: 'absolute' }}
        onClose={onClickCloseDrawer}
        placement="right"
        visible={true}
        mask={false}
        getContainer={false}
        destroyOnClose={true}
        closable={false}
      >
        {isShowDrawer ? (
          <Row type="flex" justify="start" align="middle" style={{ minWidth: '200px' }}>
            <Col span={24} style={{ margin: '0px 0px 10px 0px' }}>
              <Icon
                rotate={180}
                type="left"
                style={{ fontSize: '18px', fontWeight: 'bold', right: '-10px', color: 'grey' }}
                theme="outlined"
                onClick={onClickCloseDrawer}
              />
            </Col>
            <Col span={24}>
              <FormDatePickerRange
                formLabel="For these dates"
                form={form}
                fieldName="dateRange"
                requiredErrorMessage="Please select a range of dates"
                defaultPickerValue={generateDateRange(ruleStartDate, ruleEndDate)}
                placeholder={['Start Date', 'End Date']}
                style={{ width: '100%' }}
                allowClear={false}
                disabled={isSaveLoading}
              />
            </Col>
            <Col span={24}>
              <FormSelection
                formLabel={
                  <span>
                    Rule-set{' '}
                    <Tooltip title={`Selecting 'None' removes rules for this date range.`}>
                      <Icon type="question-circle" />
                    </Tooltip>
                  </span>
                }
                name="ruleSet"
                placeholder="None"
                form={form}
                defaultValue={selectedRuleId}
                selections={ruleOptions}
                size="large"
                allowClear={false}
                onChange={handleOnSelectChange}
                shouldDisable={isSaveLoading}
                key={selectedRuleId}
              />
            </Col>
            {selectedRuleDetail && Object.keys(selectedRuleDetail).length > 0 ? (
              <>
                <Col span={24} style={{ margin: '0em 0em 1em 0em' }}>
                  <ShowMoreList itemLists={selectedRuleDetail && selectedRuleDetail.rulesString} itemRow={2} />
                </Col>
                <Col span={24} style={{ margin: '-15px 0px 0px -15px' }}>
                  <Col span={8}>
                    <Button type="link" onClick={onClickShowEditModal}>
                      Edit Rule-set
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Button type="link" onClick={handleOnDeleteRule}>
                      Delete Rule-set
                    </Button>
                  </Col>
                </Col>
              </>
            ) : null}
            <Col span={24}>
              <FormSelection
                form={form}
                multipleMode
                formLabel="Apply Rule-set to"
                name="airbnbListingIds"
                requiredErrorMessage="Rule-set has to apply to at least 1 listing."
                defaultValue={[airbnbListingId]}
                placeholder="Select listings"
                selections={airbnbListings.map(airbnbListing => {
                  return { key: airbnbListing._id, displayValue: `${airbnbListing.listingId} | ${airbnbListing.data.listing.name}` };
                })}
              />
            </Col>
            <Col span={24} style={{ margin: '10px 0px' }}>
              <Button type="primary" onClick={handleOnFormSubmit} style={{ width: '100%' }} loading={isSaveLoading}>
                Apply Rule-set
              </Button>
            </Col>
          </Row>
        ) : (
          <Row justify="start" align="middle">
            <Col>
              <Icon
                type="left"
                style={{ fontSize: '18px', fontWeight: 'bold', right: '-10px', color: 'grey' }}
                theme="outlined"
                onClick={onClickOpenDrawer}
              />
            </Col>
          </Row>
        )}
      </Drawer>
    )
  );
};

export default Form.create()(SeasonalRuleDetails);
