import React from 'react';
import PropTypes from 'prop-types';
import { Row, Modal } from 'antd';
import { withAppContext } from 'context/AppContext';
import moment from 'moment';

import { generateDisplayFee } from 'utils/general';
import { getBankConstant } from 'utils/apis/constants';

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bankList: []
    };
  }

  componentDidMount() {
    this.fetchBankConstant();
  }

  fetchBankConstant = () => {
    getBankConstant()
      .then(bankList => {
        this.setState({
          bankList
        });
      })
      .catch(err => console.error(err));
  };

  render() {
    const { bankList } = this.state;
    const { confirmationData, visible, onCancel } = this.props;

    const foundBank = bankList.find(
      bank => confirmationData && confirmationData.payment_method_details.fpx && bank.key === confirmationData.payment_method_details.fpx.bank
    );
    return (
      <Modal visible={visible} maskClosable={false} title="Payment Confirmation" footer={null} onCancel={onCancel}>
        <Row type="flex" justify="center">
          <b>Transaction Date & Time:</b>
        </Row>
        <Row type="flex" justify="center">
          {confirmationData ? moment.unix(confirmationData.created).format() : 'Not available'}
        </Row>
        <br />
        <Row type="flex" justify="center">
          <b>Amount paid:</b>
        </Row>
        <Row type="flex" justify="center">
          {confirmationData ? generateDisplayFee(confirmationData.amount / 100) : 'Not available'}
        </Row>
        <br />
        <Row type="flex" justify="center">
          <b>Seller Order No.:</b>
        </Row>
        <Row type="flex" justify="center">
          {confirmationData ? confirmationData.statement_descriptor : 'Not available'}
        </Row>
        <br />
        <Row type="flex" justify="center">
          <b>FPX Transaction ID:</b>
        </Row>
        <Row type="flex" justify="center">
          {confirmationData && confirmationData.payment_method_details.fpx
            ? confirmationData.payment_method_details.fpx.transaction_id
            : 'Not available'}
        </Row>
        <br />
        <Row type="flex" justify="center">
          <b>Buyer Bank Name:</b>
        </Row>
        <Row type="flex" justify="center">
          {(foundBank && foundBank.value) || 'Not available'}
        </Row>
        <br />
        <Row type="flex" justify="center">
          <b>Transaction Status:</b>
        </Row>
        <Row type="flex" justify="center">
          {confirmationData && confirmationData.status ? 'Succeeded' : 'Failed'}
        </Row>
      </Modal>
    );
  }
}

ConfirmationModal.propTypes = {
  confirmationData: PropTypes.object,
  visible: PropTypes.bool,
  onCancel: PropTypes.func
};

ConfirmationModal.defaultProps = {
  confirmationData: {},
  visible: false,
  onCancel: () => {}
};

export default withAppContext(ConfirmationModal);
