import React, { useState, useMemo } from 'react';
import { Row, Col, Modal, Form, message } from 'antd';
import moment from 'moment';
import { CSVDownload } from 'react-csv';
import { useFetchConstant } from 'hooks/constants';
import FormDatePickerRange from '../../../../components/FormDatePickerRange/FormDatePickerRange';
import FormSelector from '../../../../components/FormSelection/FormSelection';
import { getTaskListWithDateRange } from '../../../../utils/apis/taskManagement';

const DownloadCSVModal = props => {
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [data, setData] = useState();
  const { selection: taskStatusEnum, isLoading: taskStatusEnumLoading, refetch } = useFetchConstant('taskStatus');

  const handleOK = async () => {
    const values = await props.form.validateFields();
    console.log(values, 18);

    setLoading(true);
    const res = await getTaskListWithDateRange(values.status, values.dateRange[0].format('DD-MMM-YYYY'), values.dateRange[1].format('DD-MMM-YYYY'));
    console.log(res);
    setLoading(false);

    if (res.status === 200) {
      setData(res.data);
      setReady(true);
      Modal.success({
        title: 'Download successful',
        onOk: () => {
          props.onCancel();
        }
      });
    } else {
      message.error('Encounter error, please try again');
    }
  };

  const constructCSV = tasklists => {
    return tasklists.map(tasklist => {
      return {
        'Creation Date': tasklist.createdAt,
        'Unit Name': tasklist.unitName,
        'Property Name': tasklist.property,
        'Team Name': tasklist.teamName,
        Status: tasklist.status,
        Cleaner: tasklist.cleanerName,
        Priority: tasklist.priority,
        'Completion Date Time': tasklist.finalCompletedAt
      };
    });
  };

  const formComponent = (
    <Form>
      <FormSelector
        form={props.form}
        name="status"
        formLabel="Task Status : "
        allowClear={false}
        multipleMode
        selections={taskStatusEnum}
        defaultValue={['pending', 'completed', 'inProgress']}
        shouldDisable={loading}
      />
      <FormDatePickerRange
        form={props.form}
        label="Select date to export : "
        fieldName="dateRange"
        requiredErrorMessage="Please select date range."
        defaultPickerValue={[moment().subtract(1, 'month'), moment()]}
        disabled={loading}
      />
    </Form>
  );
  return (
    <Modal
      onOk={handleOK}
      visible={props.visible}
      onCancel={props.onCancel}
      title="Export Task Record"
      okButtonProps={{ loading }}
      cancelButtonProps={{ disabled: loading }}
    >
      <Row>
        <Col>{formComponent}</Col>
      </Row>
      {ready && <CSVDownload filename={'my-file.csv'} data={constructCSV(data)} target="_blank" />}
    </Modal>
  );
};

export default Form.create()(DownloadCSVModal);
