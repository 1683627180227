import { httpClient } from './../httpClient';
import { apiErrorHandler } from './helpers';

export const getPayoutDetail = (hostId, ownerId, year, month) =>
  httpClient
    .get(`/payout/owner/${ownerId}/getPayout?year=${year}&month=${month}&hostId=${hostId}`, { timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);

export const getPayoutCalendar = (hostId, ownerId, year, month) =>
  httpClient
    .get(`/payout/owner/${ownerId}/getPayoutCalendar?year=${year}&month=${month}&hostId=${hostId}`, { timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);

export const getPayoutStatistic = (hostId, ownerId, year, month) =>
  httpClient
    .get(`/payout/owner/${ownerId}/getPayoutStatistic?year=${year}&month=${month}&hostId=${hostId}`, { timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);

export const getPayoutSummary = (hostId, year, month, paginate) =>
  httpClient
    .get(`/payout/host/${hostId}/getPayoutSummary?year=${year}&month=${month}&paginate=${paginate}`, { timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);

export const getPayoutExpensesAttachment = (hostId, ownerId, year, month) =>
  httpClient
    .get(`/payout/owner/${ownerId}/getPayoutExpensesAttachment?year=${year}&month=${month}&hostId=${hostId}`, { timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);

export const getIsPayoutFreeze = (hostId, ownerId, date) =>
  httpClient
    .get(`/payout/checkFreezePayoutSnapshot`, { params: { hostId, ownerId, date }, timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);

export const freezePayout = data =>
  httpClient
    .post(`/payout/freezePayout`, data, { timeout: 180000 })
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);
