import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';
// import registerServiceWorker from './registerServiceWorker';

import { AppContextProvider } from './context/AppContext';
import ProtectedRoute from './utils/ProtectedRoute';
import LocaleProvider from './utils/LocaleProvider';

import DashboardLayout from './layouts/DashboardLayout/DashboardLayout';
import APStandardLayout from 'layouts/AdminPanel/StandardLayout/StandardLayout';

import APBillingInventory from 'views/AdminPanel/BillingInventory/BillingInventory';
import APResyncOTAs from './views/AdminPanel/ResyncOTA/ResyncOTA';
import APUploadExpediaTrx from './views/AdminPanel/UploadExpediaTrx/UploadExpediaTrx';
import BookingForm from './views/BookingForm/BookingForm';
import Expenses from './views/Expenses/Expenses';
import ForgotPassword from './views/ForgotPassword/ForgotPassword';
import GuestBook from './views/GuestBook/GuestBook';
import Listing from './views/Listing/Listing';
import ListingWizard from './views/ListingWizard/ListingWizard';
import ListingDetails from './views/ListingDetails/ListingDetails';
import Login from './views/Login/Login';
import Logout from './views/Logout/Logout';
import MultiCalendar from './views/MultiCalendar/MultiCalendar';
import Overview from './views/Overview/Overview';
import Payout from './views/Payout/Payout';
import RateModifier from './views/RateModifier/RateModifier';
import Reporting from './views/Reporting/Reporting';
import BillingInventory from './views/Billing/BillingInventory/BillingInventory';
import Reservation from './views/Reservation/Reservation';
import ReservationForm from './components/Reservation/ReservationForm';
import ResetExpiredPassword from './views/ResetExpiredPassword/ResetExpiredPassword';
import ResetPassword from './views/ResetPassword/ResetPassword';
import SignUp from './views/SignUp/SignUp';
import Page404 from './views/404/404';
import Forbidden403 from './views/403/403';
import ServicePackage from './views/ServicePackage/ServicePackage';
import ServicePackageForm from './components/ServicePackageForm/ServicePackageForm';
import Host from './views/Host/Host';
import HostForm from './components/HostForm/HostForm';
import Users from './views/Users/Users';
import UsersForm from './components/UsersForm/UsersForm';
import AirbnbButton from './views/OTAIntegration/AirbnbButton';
import AgodaListing from './views/OTAIntegration/Agoda/AgodaListing';
import ExpediaListing from './views/OTAIntegration/Expedia/ExpediaListing';
import BookingcomListing from './views/OTAIntegration/Bookingcom/BookingcomListing';
import CtripListing from './views/OTAIntegration/Ctrip/CtripListing';
import TiketcomListing from './views/OTAIntegration/Tiketcom/TiketcomListing';
import TravelokaListing from './views/OTAIntegration/Traveloka/TravelokaListing';
import OTAListing from './views/OTAIntegration/OTAListing';
import OTAForm from './views/OTAIntegration/OTAForm/OTAForm';
import RevenueShare from './views/RevenueShare/RevenueShare';
import RevenueShareForm from './views/RevenueShareForm/RevenueShareForm';
import BillingInvoice from './views/Billing/BillingInvoice/BillingInvoice';
import ActivityLog from './views/ActivityLog/ActivityLog';
import UserProfile from './views/UserProfile/UserProfile';
import UserProfileDetails from './views/UserProfileDetails/UserProfileDetails';
import UserProfileResetPassword from './views/UserProfileResetPassword/UserProfileResetPassword';
import Access from './views/HostStay/Access/Access';
import Property from './views/HostStay/Property/Property';
import CleaningTeam from './views/TaskManagement/CleaningTeam/CleaningTeam';
import CleaningTeamForm from 'components/CleaningTeamForm/CleaningTeamForm';
import Tasklist from './views/TaskManagement/Tasklist/Tasklist';
import Checklist from './views/TaskManagement/Checklist/Checklist';
import ChecklistForm from 'components/ChecklistForm/ChecklistForm';
import PresetTask from 'views/TaskManagement/PresetTask/PresetTask';
import PresetTaskForm from 'components/PresetTaskForm/PresetTaskForm';
import TaskForm from 'components/TaskForm/TaskForm';
import TaskEditForm from 'components/TaskEditForm/TaskEditForm';
import Notifications from './views/Notifications/Notifications';
import Webhook from './views/Webhook/Webhook';

import {
  buildAdminPanelUri,
  buildAPBillingInventoryUri,
  buildAPResyncOTAUri,
  buildAPUploadExpediaTrxUri,
  buildBillingInvoiceUri,
  buildBillingInventoryUri,
  buildMultiCalendarUri,
  buildPayoutUri,
  buildReportingUri,
  buildIntegrationUri,
  buildResetExpiredPasswordUri,
  buildTaskManagementUri,
  buildTaskManagementCTCreateUri,
  buildTaskManagementChecklistCreateUri,
  buildTaskManagementPresetTaskCreateUri,
  buildTaskManagementTaskCreateUri,
  buildWebhookUri
} from 'utils/routes';

import './index.css';
import { SuperAdminList } from 'utils/constants';

const queryConfig = {
  queries: {
    retry: false,
    refetchOnWindowFocus: false
  }
};

const getUserId = () => {
  const authObj = JSON.parse(localStorage.getItem('auth'));
  if (!authObj) return null;
  return authObj.user._id;
};

if (process.env.NODE_ENV === 'production') {
  console.log = function no_console() {};
}

ReactDOM.render(
  <ReactQueryConfigProvider config={queryConfig}>
    <AppContextProvider>
      <LocaleProvider>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route path={buildResetExpiredPasswordUri()} component={ResetExpiredPassword} />
            <ProtectedRoute path="/listing-wizard" component={ListingWizard} requiredPermissions={[5]} />
            <Route path="/404NotFound" component={Page404} />
            <Route path="/forbidden" component={Forbidden403} />
            <Route path={buildAdminPanelUri()} isAdmin>
              <APStandardLayout>
                <Switch>
                  <ProtectedRoute exact path={buildAPBillingInventoryUri()} component={APBillingInventory} />
                  <ProtectedRoute exact path={buildAPUploadExpediaTrxUri()} component={APUploadExpediaTrx} />
                  {SuperAdminList.filter(usr => {
                    return String(usr) === String(getUserId());
                  }).length > 0 && <ProtectedRoute exact path={buildAPResyncOTAUri()} component={APResyncOTAs} />}
                </Switch>
              </APStandardLayout>
            </Route>
            <DashboardLayout>
              <Switch>
                <ProtectedRoute exact path="/userprofile/:id" component={UserProfile} />
                <ProtectedRoute exact path="/userprofile/:id/edit" component={UserProfileDetails} />
                <ProtectedRoute exact path="/userprofile/:id/resetpassword" component={UserProfileResetPassword} />

                <ProtectedRoute path="/overview" component={Overview} requiredPermissions={[8]} />
                <ProtectedRoute path="/bookingform" component={BookingForm} requiredPermissions={[9]} />
                <ProtectedRoute path={buildMultiCalendarUri()} component={MultiCalendar} requiredPermissions={[8]} />
                <ProtectedRoute exact path="/reservation" component={Reservation} requiredPermissions={[8]} />
                <ProtectedRoute path="/reservation/:id" component={ReservationForm} requiredPermissions={[8]} />
                <ProtectedRoute exact path="/host" component={Host} requiredPermissions={[28]} />
                <ProtectedRoute path="/host/new" component={HostForm} requiredPermissions={[29]} />
                <ProtectedRoute path="/host/:id/edit" component={HostForm} requiredPermissions={[30]} />
                <ProtectedRoute path={buildBillingInvoiceUri()} component={BillingInvoice} requiredPermissions={[49]} />
                <ProtectedRoute path={buildBillingInventoryUri()} component={BillingInventory} requiredPermissions={[49]} />
                <ProtectedRoute path="/ratemodifier" component={RateModifier} requiredPermissions={[45]} />
                <ProtectedRoute exact path="/users" component={Users} requiredPermissions={[0]} />
                <ProtectedRoute path="/users/new" component={UsersForm} requiredPermissions={[1]} />
                <ProtectedRoute path="/users/:id/edit" component={UsersForm} requiredPermissions={[2]} />
                <ProtectedRoute exact path="/listing" component={Listing} requiredPermissions={[4, 53]} />
                <ProtectedRoute exact path="/listing/:id" component={ListingDetails} requiredPermissions={[4]} />
                <ProtectedRoute exact path="/servicepackage" component={ServicePackage} requiredPermissions={[20]} />
                <ProtectedRoute path="/servicepackage/new" component={ServicePackageForm} requiredPermissions={[21]} />
                <ProtectedRoute path="/servicepackage/:id/edit" component={ServicePackageForm} requiredPermissions={[22]} />
                <ProtectedRoute exact path="/revenueshare" component={RevenueShare} requiredPermissions={[9001]} />
                <ProtectedRoute exact path="/revenueshare/new" component={RevenueShareForm} requiredPermissions={[9001]} />
                <ProtectedRoute exact path="/revenueshare/:id/edit" component={RevenueShareForm} requiredPermissions={[9001]} />
                <ProtectedRoute path="/expenses" component={Expenses} requiredPermissions={[32]} />
                <ProtectedRoute exact path={buildPayoutUri()} component={Payout} requiredPermissions={[12]} />
                <ProtectedRoute path={buildReportingUri()} component={Reporting} requiredPermissions={[16]} />
                <ProtectedRoute exact path={`${buildIntegrationUri()}/`} component={OTAListing} requiredPermissions={[24]} />
                <ProtectedRoute exact path={`${buildIntegrationUri()}/otaForm`} component={OTAForm} requiredPermissions={[24]} />
                <ProtectedRoute exact path={`${buildIntegrationUri()}/airbnb-connect`} component={AirbnbButton} requiredPermissions={[24]} />
                <ProtectedRoute exact path={`${buildIntegrationUri()}/agoda-connect`} component={AgodaListing} requiredPermissions={[24]} />
                <ProtectedRoute exact path={`${buildIntegrationUri()}/expedia-connect`} component={ExpediaListing} requiredPermissions={[24]} />
                <ProtectedRoute exact path={`${buildIntegrationUri()}/bookingcom-connect`} component={BookingcomListing} requiredPermissions={[24]} />
                <ProtectedRoute exact path={`${buildIntegrationUri()}/ctrip-connect`} component={CtripListing} requiredPermissions={[24]} />
                <ProtectedRoute exact path={`${buildIntegrationUri()}/traveloka-connect`} component={TravelokaListing} requiredPermissions={[24]} />
                <ProtectedRoute exact path={`${buildIntegrationUri()}/tiketcom-connect`} component={TiketcomListing} requiredPermissions={[24]} />

                <ProtectedRoute path="/host-a-stay/access" component={Access} /* requiredPermissions={[4]} */ />
                <ProtectedRoute path="/host-a-stay/property" component={Property} /* requiredPermissions={[4]} */ />

                <ProtectedRoute path="/guestbook" component={GuestBook} requiredPermissions={[4]} />
                <ProtectedRoute path="/activitylog" component={ActivityLog} requiredPermissions={[36]} />

                <ProtectedRoute exact path={`${buildTaskManagementUri()}/tasklist`} component={Tasklist} requiredPermissions={[60, 61, 62, 63]} />
                <ProtectedRoute path={`${buildTaskManagementTaskCreateUri()}`} component={TaskForm} requiredPermissions={[61]} />
                <ProtectedRoute path={`${buildTaskManagementUri()}/task/:id/edit`} component={TaskEditForm} requiredPermissions={[60, 62, 63]} />
                <ProtectedRoute exact path={`${buildTaskManagementUri()}/checklist`} component={Checklist} requiredPermissions={[61, 62, 63]} />
                <ProtectedRoute path={`${buildTaskManagementChecklistCreateUri()}`} component={ChecklistForm} requiredPermissions={[61]} />
                <ProtectedRoute path={`${buildTaskManagementUri()}/checklist/:id/edit`} component={ChecklistForm} requiredPermissions={[62, 63]} />
                <ProtectedRoute
                  exact
                  path={`${buildTaskManagementUri()}/cleaning-team`}
                  component={CleaningTeam}
                  requiredPermissions={[61, 62, 63]}
                />
                <ProtectedRoute path={`${buildTaskManagementCTCreateUri()}`} component={CleaningTeamForm} requiredPermissions={[61]} />
                <ProtectedRoute
                  path={`${buildTaskManagementUri()}/cleaning-team/:id/edit`}
                  component={CleaningTeamForm}
                  requiredPermissions={[62, 63]}
                />
                <ProtectedRoute exact path={`${buildTaskManagementUri()}/preset-task`} component={PresetTask} requiredPermissions={[61, 62, 63]} />
                <ProtectedRoute path={`${buildTaskManagementPresetTaskCreateUri()}`} component={PresetTaskForm} requiredPermissions={[61]} />
                <ProtectedRoute path={`${buildTaskManagementUri()}/preset-task/:id/edit`} component={PresetTaskForm} requiredPermissions={[62, 63]} />
                <ProtectedRoute path="/notifications" component={Notifications} requiredPermissions={[24]} />
                <ProtectedRoute path={`${buildWebhookUri()}`} component={Webhook} requiredPermissions={[75]} />

                <Redirect from="*" to="/404NotFound" />
              </Switch>
            </DashboardLayout>
            <Redirect from="*" to="/404NotFound" />
          </Switch>
        </Router>
      </LocaleProvider>
    </AppContextProvider>
  </ReactQueryConfigProvider>,
  document.getElementById('root')
);
// registerServiceWorker();
