import React from 'react';
import { Card, Row } from 'antd';
import PropTypes from 'prop-types';

import AmenitiesSelection from 'components/AmenitiesSelection/AmenitiesSelection';

class AmenitiesCard extends React.Component {
  // NOTE: Mainly for performance gain, if this component doesn't render as expected, try to comment out this function and test again
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.hasFetchedAmenities !== this.props.hasFetchedAmenities || nextProps.shouldUpdate;
  }

  render() {
    const { hasFetchedAmenities, amenities, cardClassname, form, defaultValues } = this.props;
    return (
      <Card title="Amenities" className={cardClassname}>
        <Row type="flex" justify="space-between">
          {hasFetchedAmenities &&
            amenities &&
            Object.keys(amenities).map(key => (
              <AmenitiesSelection
                form={form}
                key={key}
                title={key}
                fieldName={String(key).toLowerCase()}
                items={amenities[key].data}
                icon={amenities[key].icon}
                defaultValue={defaultValues[key]}
              />
            ))}
        </Row>
      </Card>
    );
  }
}

AmenitiesCard.propTypes = {
  form: PropTypes.object.isRequired,
  hasFetchedAmenities: PropTypes.bool.isRequired,
  amenities: PropTypes.object.isRequired,
  cardClassname: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  shouldUpdate: PropTypes.bool
};

AmenitiesCard.defaultProps = {
  defaultValues: {},
  shouldUpdate: true
};

export default AmenitiesCard;
