import React from 'react';
import { Col } from 'antd';
import PropTypes from 'prop-types';

import moment from 'moment';

import styles from './MCDate.module.css';

const DATE_FORMAT = 'YYYY-MM-DD';

const MCDate = ({ month, today, blockWidth }) => {
  const generateCol = [];
  const daysInMonth = moment(month).daysInMonth();

  for (let day = 1; day <= daysInMonth; day++) {
    const currentDateString = `${month}-${day}`;
    const currentDate = moment(currentDateString, DATE_FORMAT);
    const currentDayString = currentDate.format(`ddd`);

    const stylesDateBlockSunday = currentDayString === 'Sun' ? styles.dateBlockSunday : '';
    const stylesDateBlockToday = currentDate.format(DATE_FORMAT) === today ? styles.dateBlockToday : '';

    generateCol.push(
      <Col
        key={currentDateString}
        className={`${styles.dateBlock}
        ${stylesDateBlockToday}
        ${stylesDateBlockSunday}`}
        style={{ width: `${blockWidth}px` }}
      >
        {`${day}`}
        <br />
        {`${currentDayString}`}
      </Col>
    );
  }

  return generateCol;
};

MCDate.propTypes = {
  month: PropTypes.string.isRequired
};

MCDate.defaultProps = {
  month: moment().format('YYYY-MM')
};

export default MCDate;
