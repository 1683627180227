import React, { Component } from 'react';
import { Card, Col, Row } from 'antd';
import { withRouter } from 'react-router-dom';

import LoginForm from 'components/LoginForm/LoginForm';
import SingleViewLayout from 'layouts/SingleViewLayout/SingleViewLayout';
import { buildPayoutUri, buildTaskManagementUri } from 'utils/routes';

import Logo from 'images/hostplatform-logo.png';
import './Login.css';
import { guard } from 'utils/general';

class Login extends Component {
  componentWillMount() {
    const authObj = JSON.parse(localStorage.getItem('auth'));
    if (guard(() => authObj.user.roles.length, true)) {
      return;
    }
    //TODO: Weird login hardcoded redirect shit, let's revisit when we have multiple hosts
    if (authObj && authObj.user && authObj.user.roles.length > 0 && authObj.user.roles[0].permissions.includes(8)) {
      this.props.history.push('/overview');
    } else if (authObj && authObj.user && authObj.user.roles.length > 0 && authObj.user.roles[0].permissions.includes(12)) {
      this.props.history.push(buildPayoutUri());
    } else if (
      authObj &&
      authObj.user &&
      authObj.user.roles.length > 0 &&
      (authObj.user.roles[0].permissions.includes(4) || authObj.user.roles[0].permissions.includes(53))
    ) {
      this.props.history.push('/listing');
    } else if (
      authObj &&
      authObj.user &&
      authObj.user.roles.length > 0 &&
      (authObj.user.roles[0].permissions.includes(60) || authObj.user.roles[0].permissions.includes(62))
    ) {
      this.props.history.push(`${buildTaskManagementUri()}/tasklist`);
    }
  }

  render() {
    return (
      <SingleViewLayout className="login-page">
        <Row type="flex" justify="center">
          <img alt="hostplatform logo" src={Logo} className="login-hp-logo" />
        </Row>
        <Row type="flex" justify="center">
          <Col xs={22} sm={18} md={14} lg={10} xl={8} xxl={6}>
            <Card className="login-card">
              <LoginForm />
            </Card>
          </Col>
        </Row>
      </SingleViewLayout>
    );
  }
}

export default withRouter(Login);
