import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Drawer, Input, Icon, Button, Select, message, DatePicker } from 'antd';
import './ExpensesUpdate.css';
import { upsertExpenses } from '../../utils/apis/expenses';
import moment from 'moment';
import UploadFiles from '../UploadFiles/UploadFiles';

const Option = Select.Option;

class ExpensesUpdate extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    host: PropTypes.object.isRequired,
    svcPacName: PropTypes.string.isRequired,
    expenses: PropTypes.array.isRequired,
    onInputChange: PropTypes.func,
    currency: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired
  };
  static defaultProps = {
    host: {},
    svcPacName: '',
    expenses: []
  };
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'others',
      svcPacName: '',
      host: {},
      originalExpenses: [],
      updatedExpenses: [],
      electricity: 0,
      water: 0,
      internet: 0,
      cleaning: 0,
      laundry: 0,
      toiletry: 0,
      others: 0,
      service: 0,
      checkIn: 0,
      checkOut: 0,
      isLoading: true,
      isSaveButtonLoading: false
    };
    this.utilityType = [
      { key: 'others', tab: 'Others' },
      { key: 'electricity', tab: 'Electricity' },
      { key: 'water', tab: 'Water' },
      { key: 'internet', tab: 'Internet' },
      { key: 'cleaning', tab: 'Cleaning' },
      { key: 'laundry', tab: 'Laundry' },
      { key: 'service', tab: 'Service' },
      { key: 'checkIn', tab: 'Check In' },
      { key: 'checkOut', tab: 'Check Out' },
      { key: 'toiletry', tab: 'Toiletry Sets' }
    ];

    this.toiletryType = [
      { label: 'Body Wash', value: 'bodywash' },
      { label: 'Lotion', value: 'lotion' },
      { label: 'Shampoo', value: 'shampoo' },
      { label: 'Conditioner', value: 'conditioner' },
      { label: 'Shaver', value: 'shaver' },
      { label: 'Toilet Rolls', value: 'toiletrolls' },
      { label: 'Toothbrush', value: 'toothbrush' },
      { label: 'Toothpaste', value: 'toothpaste' }
    ];

    this.contentList = {
      electricity: <h2>Electricity Expense</h2>,
      water: <h2>Water Expense</h2>,
      internet: <h2>Internet Expense</h2>,
      cleaning: <h2>Cleaning Expense</h2>,
      laundry: <h2>Laundry Expense</h2>,
      service: <h2>Services Expense</h2>,
      checkIn: <h2>Check In Expense</h2>,
      checkOut: <h2>Check Out Expense</h2>,
      toiletry: <h2>Toiletry Set Expense</h2>,
      others: <h2>Others Expense</h2>
    };

    this.onTabChange = this.onTabChange.bind(this);
    this.generateInput = this.generateInput.bind(this);
    this.generateOtherInput = this.generateOtherInput.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOtherInputChange = this.handleOtherInputChange.bind(this);
    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleOnCancel = this.handleOnCancel.bind(this);
    this.remove = this.remove.bind(this);
    this.add = this.add.bind(this);
    this.handleAddExpensesAttachment = this.handleAddExpensesAttachment.bind(this);
    this.handleDeleteAttachment = this.handleDeleteAttachment.bind(this);
  }

  componentDidMount() {
    const { onInputChange, host, svcPacName, expenses } = this.props;
    this.setState({
      electricity: onInputChange('electricity', expenses),
      water: onInputChange('water', expenses),
      internet: onInputChange('internet', expenses),
      cleaning: onInputChange('cleaning', expenses),
      laundry: onInputChange('laundry', expenses),
      toiletry: onInputChange('toiletry', expenses),
      others: onInputChange('others', expenses),
      service: onInputChange('service', expenses),
      checkIn: onInputChange('checkIn', expenses),
      checkOut: onInputChange('checkOut', expenses),
      isLoading: false,
      host: host,
      svcPacName: svcPacName,
      originalExpenses: expenses
    });
  }

  componentDidUpdate() {
    const { originalExpenses, updatedExpenses } = this.state;
    if (originalExpenses.length > 0 && updatedExpenses.length === 0) {
      this.setState({
        updatedExpenses: JSON.parse(JSON.stringify(originalExpenses))
      });
    }
  }

  remove = (unitId, expenseType, idx) => e => {
    const { updatedExpenses } = this.state;
    const { onInputChange } = this.props;
    const targetedExpenses = updatedExpenses.find(ue => ue.unitId === unitId)[expenseType];
    targetedExpenses.splice(idx, 1);
    this.setState({
      updatedExpenses,
      [expenseType]: onInputChange(expenseType, updatedExpenses)
    });
  };

  add = (unitId, expenseType) => e => {
    const { updatedExpenses } = this.state;
    updatedExpenses.find(ue => ue.unitId === unitId)[expenseType].push({ label: undefined, amount: undefined });
    this.setState({
      updatedExpenses
    });
  };

  onTabChange = (key, type) => {
    this.setState({ [type]: key });
  };

  handleInputChange = unitId => e => {
    const { updatedExpenses } = this.state;
    const { onInputChange } = this.props;
    const expenseType = e.target.name;
    const value = e.target.value;
    updatedExpenses.find(ue => ue.unitId === unitId)[expenseType] = parseFloat(value);
    this.setState({
      updatedExpenses,
      [expenseType]: onInputChange(expenseType, updatedExpenses)
    });
  };

  handleInputAttachment = (unitId, expenseType, file) => {
    const { updatedExpenses } = this.state;
    const { onInputChange } = this.props;

    updatedExpenses
      .find(ue => ue.unitId === unitId)
      [expenseType].push({
        name: file.fileKey,
        link: file.fileUrl
      });

    this.setState({
      updatedExpenses,
      [expenseType]: onInputChange(expenseType, updatedExpenses)
    });
  };

  handleDeleteInputAttachment = (unitId, expenseType, index) => {
    const { updatedExpenses } = this.state;
    const { onInputChange } = this.props;

    updatedExpenses.find(ue => ue.unitId === unitId)[expenseType] = updatedExpenses
      .find(ue => ue.unitId === unitId)
      [expenseType].filter((value, inIndex) => inIndex !== index);

    this.setState({
      updatedExpenses,
      [expenseType]: onInputChange(expenseType, updatedExpenses)
    });
  };

  handleOtherInputChange = (unitId, expenseType, idx, inputType) => value => {
    const { updatedExpenses } = this.state;
    const { onInputChange } = this.props;
    const formattedValue = inputType === 'label' || 'transactionDate' ? value : parseFloat(value);
    updatedExpenses.find(ue => ue.unitId === unitId)[expenseType][idx][inputType] = formattedValue;
    this.setState({
      updatedExpenses,
      [expenseType]: onInputChange(expenseType, updatedExpenses)
    });
  };

  handleAddExpensesAttachment = (unitId, expenseType, idx, inputType, file) => {
    var { updatedExpenses } = this.state;
    var { onInputChange } = this.props;

    var updatedExpensesBuffer = [...updatedExpenses];

    if (Array.isArray(updatedExpensesBuffer.find(ue => ue.unitId === unitId)[expenseType][idx][inputType])) {
      updatedExpensesBuffer
        .find(ue => ue.unitId === unitId)
        [expenseType][idx][inputType].push({
          name: file.fileKey,
          link: file.fileUrl
        });
    } else {
      updatedExpensesBuffer.find(ue => ue.unitId === unitId)[expenseType][idx][inputType] = [
        {
          name: file.fileKey,
          link: file.fileUrl
        }
      ];
    }

    var output = onInputChange(expenseType, updatedExpenses);
    this.setState({
      updatedExpenses: updatedExpensesBuffer,
      [expenseType]: output
    });
  };

  handleDeleteAttachment = (unitId, expenseType, idx, inputType, index) => {
    const { updatedExpenses } = this.state;
    const { onInputChange } = this.props;

    if (Array.isArray(updatedExpenses.find(ue => ue.unitId === unitId)[expenseType][idx][inputType])) {
      updatedExpenses.find(ue => ue.unitId === unitId)[expenseType][idx][inputType] = updatedExpenses
        .find(ue => ue.unitId === unitId)
        [expenseType][idx][inputType].filter((value, inIndex) => inIndex !== index);
    } else {
      updatedExpenses.find(ue => ue.unitId === unitId)[expenseType][idx][inputType] = [];
    }
    this.setState({
      updatedExpenses,
      [expenseType]: onInputChange(expenseType, updatedExpenses)
    });
  };

  generateInput = (expenseType, presetType) => {
    const { updatedExpenses } = this.state;
    return updatedExpenses.map((unit, idx) => {
      const expenseAmount = unit[expenseType] || '';
      const presetAmount = unit[presetType] || 0;
      const expenseAttachment = unit[expenseType + '_attachment'] || [];
      return (
        <Row className="eu-inputRow" key={`input${idx}`}>
          <Col span={5} xs={24} sm={11} md={9} lg={5} xl={3} xxl={3}>
            <label className="eu-inputLabel">{unit['unitNo']}</label>
          </Col>
          <Col span={5} xs={24} sm={12} md={12} lg={6} xl={3} xxl={3}>
            <Input
              name={expenseType}
              placeholder="0"
              type="number"
              step="0.01"
              value={expenseAmount}
              onChange={this.handleInputChange(unit.unitId)}
              className="eu-inputs"
            />
          </Col>
          <React.Fragment>
            <Col offset={1} span={5} xs={24} sm={10} md={8} lg={4} xl={2} xxl={2}>
              <label className="eu-inputLabel">+ preset expenses</label>
            </Col>
            <Col span={5} xs={24} sm={12} md={12} lg={6} xl={3} xxl={3}>
              <Input
                name={presetType}
                placeholder="0"
                type="number"
                disabled
                step="0.01"
                value={presetAmount}
                onChange={this.handleInputChange(unit.unitId)}
                className="eu-inputs"
              />
            </Col>
            <Col offset={1} span={5} xs={24} sm={10} md={8} lg={4} xl={1} xxl={1}>
              <label className="eu-inputLabel">total</label>
            </Col>
            <Col span={5} xs={24} sm={12} md={12} lg={6} xl={3} xxl={3}>
              <Input
                name={presetType}
                placeholder="0"
                type="number"
                disabled
                step="0.01"
                value={expenseAmount + presetAmount}
                onChange={this.handleInputChange(unit.unitId)}
                className="eu-inputs"
              />
            </Col>

            <Col span={5} xs={24} sm={12} md={12} lg={6} xl={3} xxl={3}>
              <UploadFiles
                files={expenseAttachment}
                onUploadFinish={e => {
                  this.handleInputAttachment(unit.unitId, expenseType + '_attachment', e);
                }}
                onFileDelete={index => v => {
                  this.handleDeleteInputAttachment(unit.unitId, expenseType + '_attachment', index);
                }}
                checkIsAdmin
              />
            </Col>
          </React.Fragment>
        </Row>
      );
    });
  };

  generateOtherInput = expenseType => {
    const { updatedExpenses } = this.state;
    return updatedExpenses.map((unit, idx) => {
      return (
        <React.Fragment>
          <Row className="eu-inputRow" key={`input${idx}`}>
            <Col span={3} xs={24} sm={24} md={24} lg={5} xl={5} xxl={3}>
              <label className="eu-inputLabel">{unit['unitNo']}</label>
            </Col>
            <Col span={21} xs={24} sm={24} md={24} lg={19} xl={19} xxl={21}>
              {unit[expenseType].map((other, idx) => {
                return (
                  <Row gutter={{ sm: 16 }}>
                    <Col span={8} xs={24} sm={24} md={24} lg={8} xl={8} xxl={6}>
                      {expenseType === 'others' ? (
                        <Input
                          value={other.label}
                          addonBefore={`Item Name ${idx + 1}`}
                          placeholder="Item Name"
                          style={{ width: '100%' }}
                          type="text"
                          onChange={e => this.handleOtherInputChange(unit.unitId, expenseType, idx, 'label')(e.target.value)}
                        />
                      ) : (
                        <Select
                          value={other.label}
                          defaultActiveFirstOption={false}
                          placeholder={`Toiletry Type ${idx + 1}`}
                          style={{ width: '100%' }}
                          onChange={this.handleOtherInputChange(unit.unitId, expenseType, idx, 'label')}
                        >
                          {this.toiletryType.map(t => {
                            return <Option value={t.value}>{t.label}</Option>;
                          })}
                        </Select>
                      )}
                    </Col>
                    <Col span={7} xs={24} sm={24} md={24} lg={7} xl={7} xxl={6}>
                      <Input
                        value={other.amount || ''}
                        addonBefore={`Amount ${idx + 1}`}
                        placeholder="0"
                        style={{
                          width: '100%',
                          marginRight: '10px'
                        }}
                        type="number"
                        step="0.01"
                        onChange={e => this.handleOtherInputChange(unit.unitId, expenseType, idx, 'amount')(e.target.value)}
                      />
                    </Col>

                    {expenseType === 'others' && (
                      <Col span={8} xs={24} sm={24} md={24} lg={15} xl={8} xxl={6}>
                        <DatePicker
                          value={other.transactionDate ? moment(other.transactionDate) : moment()}
                          name="transactionDate"
                          placeholder={`Transaction Date ${idx + 1}`}
                          style={{ width: '100%' }}
                          onChange={(date, dateString) => this.handleOtherInputChange(unit.unitId, expenseType, idx, 'transactionDate')(dateString)}
                        />
                      </Col>
                    )}

                    <Col span={8} xs={24} sm={24} md={24} lg={15} xl={8} xxl={5}>
                      <UploadFiles
                        files={other.expensesAttachment}
                        onUploadFinish={e => {
                          this.handleAddExpensesAttachment(unit.unitId, expenseType, idx, 'expensesAttachment', e);
                        }}
                        // onFileDelete={index => {
                        //   this.handleDeleteAttachment(unit.unitId, expenseType, idx, 'expensesAttachment', index);
                        // }}
                        onFileDelete={index => v => {
                          console.log(index);
                          this.handleDeleteAttachment(unit.unitId, expenseType, idx, 'expensesAttachment', index);
                        }}
                        checkIsAdmin
                      />
                    </Col>

                    <Col span={1} xs={2} sm={1} md={1} lg={1} xl={1} xxl={1}>
                      <Button
                        icon="minus"
                        type="danger"
                        shape="circle"
                        size="small"
                        style={{ margin: '5px 0 10px 0' }}
                        onClick={this.remove(unit.unitId, expenseType, idx)}
                      />
                    </Col>
                  </Row>
                );
              })}
              <Button type="dashed" style={{ width: '120px' }} onClick={this.add(unit.unitId, expenseType)}>
                <Icon type="plus" /> Add field
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    });
  };

  handleOnSave = () => {
    const { updatedExpenses } = this.state;
    const { onClose, year, month } = this.props;
    this.setState({
      isSaveButtonLoading: true
    });
    const expenses = updatedExpenses.map(unitExp => ({
      unit: unitExp.unitId,
      expenses: {
        ...unitExp,
        others: unitExp.others
          .filter(o => o.label && o.amount)
          .map(o => {
            const transactionDate = o.transactionDate || moment().format('YYYY-MM-DD');
            const expensesAttachment = o.expensesAttachment;
            return { label: o.label, amount: o.amount, transactionDate, expensesAttachment };
          }),
        toiletry: unitExp.toiletry
          .filter(o => o.label && o.amount)
          .map(o => {
            return { label: o.label, amount: o.amount || 0, expensesAttachment: o.expensesAttachment };
          })
      }
    }));
    upsertExpenses({
      year,
      month,
      expenses
    })
      .then(() => {
        this.setState({
          originalExpenses: updatedExpenses
        });
        onClose(updatedExpenses);
      })
      .catch(error => {
        message.error(`Error updating expenses: ${error}`);
        this.handleOnCancel();
      })
      .finally(() => {
        this.setState({
          isSaveButtonLoading: false
        });
      });
  };

  handleOnCancel = () => {
    const originalExpenses = [...this.state.originalExpenses];
    const { onInputChange, onClose } = this.props;

    this.setState({
      updatedExpenses: originalExpenses,
      electricity: onInputChange('electricity'),
      water: onInputChange('water'),
      internet: onInputChange('internet'),
      cleaning: onInputChange('cleaning'),
      laundry: onInputChange('laundry'),
      toiletry: onInputChange('toiletry'),
      others: onInputChange('others'),
      service: onInputChange('service'),
      checkIn: onInputChange('checkIn'),
      checkOut: onInputChange('checkOut')
    });
    onClose();
  };

  render() {
    const {
      svcPacName,
      host,
      electricity,
      water,
      internet,
      cleaning,
      laundry,
      toiletry,
      others,
      service,
      checkIn,
      checkOut,
      currentTab,
      utilityType = [
        { label: 'Electricity', value: electricity },
        { label: 'Water', value: water },
        { label: 'Internet', value: internet },
        { label: 'Cleaning', value: cleaning },
        { label: 'Laundry', value: laundry },
        { label: 'Service', value: service },
        { label: 'Check In', value: checkIn },
        { label: 'Check Out', value: checkOut },
        { label: 'Toiletry Sets', value: toiletry },
        { label: 'Others', value: others }
      ],
      totalExpenses = electricity + water + internet + cleaning + laundry + toiletry + others + service + checkIn + checkOut,
      numberWithCommas = num => {
        return Number.parseFloat(num)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      isLoading,
      isSaveButtonLoading
    } = this.state;
    const { visible } = this.props;
    return (
      <Drawer title="Update Expenses" width="90%" placement="right" onClose={this.handleOnCancel} maskClosable={false} visible={visible}>
        <Row gutter={8}>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card className="eu-cards eu-cards-first" isLoading={isLoading}>
              <Row>
                <span className="eu-toplabel">Service Package: </span>
                <span className="eu-top-description">{svcPacName}</span>
              </Row>
              <Row>
                <span className="eu-toplabel">Host: </span>
                <span className="eu-top-description">{host.name}</span>
              </Row>
              <Row>
                <span className="eu-toplabel">Total Expenses: </span>
                <span className="eu-top-description">
                  {this.props.currency} {numberWithCommas(totalExpenses)}
                </span>
              </Row>
            </Card>
          </Col>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Card className="eu-cards" isLoading={isLoading}>
              {utilityType.map((u, idx) => {
                return (
                  <Row key={`utilityPrice${idx}`}>
                    <span className="eu-toplabel">{u.label}: </span>
                    {this.props.currency} {numberWithCommas(u.value)}
                  </Row>
                );
              })}
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Card
            style={{ width: '100%' }}
            tabList={this.utilityType}
            activeTabKey={currentTab}
            isLoading={isLoading}
            onTabChange={key => {
              this.onTabChange(key, 'currentTab');
            }}
          >
            {this.contentList[currentTab]}
            {currentTab === 'others' || currentTab === 'toiletry'
              ? this.generateOtherInput(currentTab)
              : this.generateInput(currentTab, 'preset' + currentTab)}
          </Card>
        </Row>
        <Row type="flex" justify="end" className="eu-buttonrow">
          <Button id="cancel-button2-updexp" type="secondary" onClick={this.handleOnCancel}>
            Cancel
          </Button>
          <Button id="save-button5-updexp" type="primary" onClick={this.handleOnSave} className="eu-savebutton" loading={isSaveButtonLoading}>
            Save All Expenses
          </Button>
        </Row>
      </Drawer>
    );
  }
}

export default ExpensesUpdate;
