import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Alert, Upload, message, Table, Tooltip, Tag } from 'antd';
import { InboxOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import readXlsxFile from 'read-excel-file';
import moment from 'moment';
import { validateBulk, uploadBulk } from 'utils/apis/bulkUpload';

const BulkImportMOdal = props => {
  // // Your array of objects
  // const dataArray = [
  //   {
  //     booked: '2023-04-30T00:06:00-07:00',
  //     bookingAmount: 909.56,
  //     checkIn: '45142',
  //     checkOut: '45144',
  //     confirmation: '1500634960',
  //     guest: 'YEONGMUN YOON',
  //     haveError: [],
  //     paymentType: 'Expedia Collect',
  //     reservationId: '1500634960',
  //     room: 'Family Penthouse',
  //     status: 'postStay'
  //   },
  //   {
  //     booked: '2023-04-30T00:06:00-07:00',
  //     bookingAmount: 909.56,
  //     checkIn: '45142',
  //     checkOut: '45144',
  //     confirmation: '1500634960',
  //     guest: 'YEONGMUN YOON',
  //     haveError: [],
  //     paymentType: 'Expedia Collect',
  //     reservationId: '1500634960',
  //     room: 'Family Penthouse',
  //     status: 'postStay'
  //   }
  //   // ... (other objects in the array)
  // ];

  // // Use map to create a new array with haveError removed
  // const newArray = dataArray.map(obj => {
  //   // Create a copy of the object without the haveError property
  //   const newObj = { ...obj };
  //   delete newObj.haveError;
  //   return newObj;
  // });

  // console.log('newArray', newArray);

  const [loading, setLoading] = useState(false);
  const [validatedData, setValidatedData] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);

  const handleUploadedTemplate = async value => {
    /* 
Flow
1. Check is file valid
2. Parse excel file
3. Check parsed file format
4. Compose data
5. Check value
 */
    setUploadedFile([value.file]);

    var output = await parseExcel(value.file);

    const isSchemaValid = await validateFormat(output);
    if (isSchemaValid.error === true) {
      message.error(isSchemaValid.message);
      setUploadedFile([]);
      return;
    }

    var newData = [];
    for (let i = 0; i < output.rows.length; i++) {
      var item = output.rows[i];
      let errorList = [];
      // // Check email duplicate data
      // const idDuplicate = output.rows.filter(x => x.bookingId === item.bookingId);
      // if (idDuplicate.length > 1) {
      //   errorList.push({
      //     error: 'Duplicate',
      //     column: 'Booking ID'
      //   });
      // }
      // // Check username duplicate
      // const nricDuplicate = output.rows.filter(x => x.nric === item.nric);
      // if (nricDuplicate.length > 1) {
      //   errorList.push({
      //     error: 'Duplicate',
      //     column: 'NRIC'
      //   });
      // }

      errorList = [...errorList, ...output.errors.filter(err => err.row === i + 2)];

      newData.push({
        ...item,
        haveError: errorList
      });
    }
    console.log(59, newData, output);
    setValidatedData(newData);

    setLoading(true);
    const apiValidatedData = await validateBulk(newData);
    console.log('apiValidatedData', apiValidatedData);

    if (apiValidatedData.status === 200) {
      setValidatedData(newData);
    }

    setLoading(false);
  };

  const validateFormat = output => {
    if (output.rows.length === 0) {
      setUploadedFile([]);
      return {
        error: true,
        message: 'Invalid schema'
      };
    }
    if (output.errors.length > 0) {
      message.error('Contain error please fix and try again');
      return {
        error: false,
        message: 'Contain error please fix and try again'
      };
    }
    return {
      error: false
    };
  };

  const parseExcel = file => {
    return new Promise((resolve, reject) => {
      readXlsxFile(file, {
        schema: {
          Guest: {
            prop: 'guest',
            type: String,
            required: true
          },
          'Check-In': {
            prop: 'checkIn',
            type: Date,
            required: true
          },
          'Check-Out': {
            prop: 'checkOut',
            type: Date,
            required: true
          },
          Booked: {
            prop: 'booked',
            type: String,
            required: true
          },
          Room: {
            prop: 'room',
            type: String,
            required: true
          },
          'Payment Type': {
            prop: 'paymentType',
            type: String,
            required: true
          },
          'Booking Amount': {
            prop: 'bookingAmount',
            type: Number,
            required: true
          },
          'Reservation ID': {
            prop: 'reservationId',
            type: String,
            required: true
          },
          'Confirmation #': {
            prop: 'confirmation',
            type: String,
            required: true
          },
          Status: {
            prop: 'status',
            type: String,
            required: true
          }
        }
      })
        .then(output => {
          resolve(output);
        })
        .catch(err => {
          message.error('Invalid Excel File');
          reject(err);
        });
    });
  };

  const resetUpload = () => {
    setUploadedFile([]);
    setValidatedData([]);
  };

  const handleUpload = async () => {
    setLoading(true);
    const res = uploadBulk(validatedData);

    console.log('res', res);

    Modal.success({
      title: 'Bulk upload successful!',
      onOk: () => {
        window.location.reload();
      }
    });

    props.onCancel();
    setLoading(false);
  };

  const columns = [
    {
      title: 'Status',
      sorter: (a, b) => a.haveError.length - b.haveError.length,
      render: index => {
        if (index.haveError.length === 0) {
          return <Tag color="green">Accepted</Tag>;
        } else {
          return (
            <Tooltip
              title={
                <Row>
                  <Col span={24} style={{ fontWeight: 600 }}>
                    Error List :
                  </Col>
                  {index.haveError.map((value, index) => (
                    <Col key={index} span={24}>
                      {index + 1}. {value.column} {value.error}
                    </Col>
                  ))}
                </Row>
              }
            >
              <Tag color="red">
                <QuestionCircleOutlined /> Error
              </Tag>
            </Tooltip>
          );
        }
      }
    },
    {
      title: 'Guest',
      dataIndex: 'guest',
      key: 'guest'
    },
    {
      title: 'Check-In',
      dataIndex: 'checkIn',
      key: 'checkIn',
      render: (value, record) => {
        return moment(value).format('DD/MM/YYYY');
      }
    },
    {
      title: 'Check-Out',
      dataIndex: 'checkOut',
      key: 'checkOut',
      render: (value, record) => {
        return moment(value).format('DD/MM/YYYY');
      }
    },
    {
      title: 'Booked',
      dataIndex: 'booked',
      key: 'booked',
      render: (value, record) => {
        return moment(value).format('DD/MM/YYYY');
      }
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType',
      key: 'paymentType'
    },
    {
      title: 'Booking Amount',
      dataIndex: 'bookingAmount',
      key: 'bookingAmount'
    },
    {
      title: 'Reservation ID',
      dataIndex: 'reservationId',
      key: 'reservationId'
    },
    {
      title: 'Confirmation #',
      dataIndex: 'confirmation',
      key: 'confirmation'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    }
  ];

  return (
    <Modal
      title="Bulk Booking ID Import"
      visible={props.visible}
      onOk={handleUpload}
      onCancel={() => {
        resetUpload();
        props.onCancel();
      }}
      width={'50%'}
      okText="Upload"
      okButtonProps={{
        disabled: validatedData.length === 0 || validatedData.filter(x => x.haveError.length > 0).length > 0,
        loading: loading
      }}
      cancelText={'Close'}
    >
      <Alert
        description={
          <div>
            Information Note :{' '}
            <a href="/template/Host_Platform_ID_UploadTemplate.xlsx" target="_blank">
              Download template link
            </a>
          </div>
        }
        type="info"
        showIcon
        style={{
          marginBottom: 16
        }}
      />

      {validatedData.length === 0 ? (
        <Upload.Dragger beforeUpload={() => false} onChange={handleUploadedTemplate} fileList={[]} withCredentials>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Support only for template upload. Strictly prohibit from uploading others data or other band files</p>
        </Upload.Dragger>
      ) : (
        <Table
          bordered
          size="small"
          rowKey={'id'}
          scroll={{
            x: true
          }}
          columns={columns}
          dataSource={validatedData}
          loading={loading}
        />
      )}
    </Modal>
  );
};

export default BulkImportMOdal;
