import React from 'react';
import { message } from 'antd';

import styles from './NetworkConnectivity.module.css';

const TIMEOUT_TIME_MS = 2000;
const onlinePollingInterval = 10000;

const timeout = (time, promise) => {
  return new Promise(function(resolve, reject) {
    setTimeout(() => {
      reject(new Error('Request timed out.'));
    }, time);
    promise.then(resolve, reject);
  });
};

const checkOnlineStatus = async () => {
  const controller = new AbortController();
  const { signal } = controller;

  // If the browser has no network connection return offline
  if (!navigator.onLine) return navigator.onLine;

  //
  try {
    await timeout(
      TIMEOUT_TIME_MS,
      fetch('https://www.google.com/', {
        mode: 'no-cors',
        signal
      })
    );
    return true;
  } catch (error) {
    // Error Log
    message.error('Poor Network Connection, please check your internet connection', 8);
    console.error(error);

    // This can be because of request timed out
    // so we abort the request for any case
    controller.abort();
  }
  return false;
};

const NetworkConnectivity = () => {
  const [isOnline, setIsOnline] = React.useState(true);

  const checkStatus = async () => {
    const online = await checkOnlineStatus();
    setIsOnline(online);
  };

  React.useEffect(() => {
    window.addEventListener('offline', () => {
      setIsOnline(false);
    });

    // Add polling incase of slow connection
    const id = setInterval(() => {
      checkStatus();
    }, onlinePollingInterval);

    return () => {
      window.removeEventListener('offline', () => {
        setIsOnline(false);
      });

      clearInterval(id);
    };
  }, []);

  return (
    <>
      {isOnline ? (
        <></>
      ) : (
        <></>
        // <div className={styles.overlay}>
        //   <div className={`${styles.container}`}>
        //     {/* <Avatar src={nowifi} /> */}
        //     <span className={`${styles.textLabel}`}>Poor Network Connection</span>
        //   </div>
        // </div>
      )}
    </>
  );
};

export default NetworkConnectivity;
