import React, { Component, useEffect, useState } from 'react';
import moment from 'moment';
import { Modal, Table, Row, Col, Button } from 'antd';
import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';
import CreateChainIDModal from './component/CreateChainIDModal';
import { deleteAgodaChainID, getAgodaChainIDs } from 'utils/apis/agodaOnboarding';
import { addMoment } from 'utils/date';

const ManageAdgodaChainIdModal = props => {
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [mode, setMode] = useState('create');
  const [selectedRecord, setSelectedRecord] = useState(null);
  // Regiser Chain ID modal
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    getChainIDList();
  }, []);

  const getChainIDList = async () => {
    setLoading(true);
    const res = await getAgodaChainIDs();
    console.log(res, 20);
    setdata(res);
    setLoading(false);
  };

  const handleDeleteChainID = async recordId => {
    setLoading(true);
    console.log(recordId, 28);
    const res = await deleteAgodaChainID(recordId);
    console.log(res);
    setLoading(false);
    await getChainIDList();
  };

  const columns = [
    {
      ...constructColumn('Creation Date', 'createdAt', {
        sorter: true
      }),
      render: text => moment(text).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      ...constructColumn('Host', 'host.name'),
      ...constructColumnFilterSearch('Host', 'host.name')
    },
    {
      ...constructColumn('Chain ID', 'chainID'),
      ...constructColumnFilterSearch('Chain ID', 'chainID')
    },
    {
      title: 'Action',
      render: (text, record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setMode('edit');
                setSelectedRecord(record);
                setVisible(true);
              }}
            >
              Edit
            </Button>{' '}
            <Button
              type="link"
              onClick={() => {
                Modal.confirm({
                  title: 'Confirm delete chain ID?',
                  content: 'Do you want to delete this chain ID?',
                  onOk: () => handleDeleteChainID(record._id)
                });
              }}
            >
              Delete
            </Button>
          </>
        );
      }
    }
  ];

  return (
    <Modal title="Manage Agoda Host Chain Id" visible={props.visible} onCancel={props.onCancel}>
      <Row
        type="flex"
        justify="end"
        style={{
          marginBottom: 16
        }}
      >
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setVisible(true);
              setMode('create');
            }}
          >
            Add New Chain ID
          </Button>
        </Col>
      </Row>

      <Table rowKey={'_id'} dataSource={data} columns={columns} loading={loading} />

      <CreateChainIDModal
        mode={mode}
        selectedRecord={selectedRecord}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </Modal>
  );
};

export default ManageAdgodaChainIdModal;
