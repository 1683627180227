import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { withAppContext } from '../context/AppContext';

import { getHostStatus } from 'utils/apis/billingInvoice';

const checkIfUserPermitted = (requiredPermissions, isBanned) => {
  const authObj = JSON.parse(localStorage.getItem('auth'));

  if (isBanned) {
    return true;
  }

  if (!requiredPermissions || requiredPermissions.length === 0) {
    return true;
  } else if (!authObj) {
    return false;
  } else {
    const userRoles = authObj.user.roles;
    const permitted =
      authObj.user.isAdmin ||
      (userRoles.length > 0 && requiredPermissions.filter(requiredPermission => userRoles[0].permissions.includes(requiredPermission)).length > 0);
    return permitted;
  }
};

const ProtectedRoute = ({ isAuth, isAdmin, isBanned, path, component, requiredPermissions, ...otherRouteProps }) => {
  var isUserPermitted = checkIfUserPermitted(requiredPermissions, isBanned);

  if (isAuth && (isAdmin || isUserPermitted)) {
    return <Route path={path} component={component} {...otherRouteProps} />;
  } else if (isAuth && !isUserPermitted && !isBanned) {
    return <Redirect to="/forbidden" />;
  } else {
    return <Redirect to="/" />;
  }
};

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.func,
  requiredPermissions: PropTypes.array
};

export default withAppContext(ProtectedRoute);
export { checkIfUserPermitted };
