import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Card } from 'antd';

import ListingWizardLayout from 'layouts/ListingWizardLayout/ListingWizardLayout';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

import Counter from 'components/Counter/Counter';

import styles from './BasicDetails.module.css';
import adultImage from './adult-image.png';
import childImage from './child-image.png';

const TYPE_ADULT = 'adult';
const TYPE_CHILD = 'child';

const OccupancyCounter = ({ image, label, count = 0, increment, decrement }) => (
  <Col span={24} md={11} className={styles.occupancyContainer}>
    <Card className={styles.occupancyCard} cover={<img className={styles.occupancyCardCover} alt="example" src={image} />}>
      <Card.Meta title={<h3 className={styles.occupancyCardTitle}>{label}</h3>} />
    </Card>
    <Counter count={count} onDecrement={decrement} onIncrement={increment} />
  </Col>
);

class BasicDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adultMaxOccupancy: props.defaultValues.adultMaxOccupancy || 1,
      childMaxOccupancy: props.defaultValues.childMaxOccupancy || 0
    };
  }

  handleCounterIncrement = type => () => {
    const key = this.mapCounterTypeKey(type);
    if (key) {
      this.setState(prevState => ({
        [key]: prevState[key] + 1
      }));
    }
  };

  handleCounterDecrement = type => () => {
    const key = this.mapCounterTypeKey(type);
    const limit = this.mapCounterTypeLimit(type);
    if (key) {
      this.setState(prevState => {
        if (prevState[key] > limit) {
          return {
            [key]: prevState[key] - 1
          };
        }
      });
    }
  };

  mapCounterTypeKey = type => {
    const counterTypeKeyMap = {
      [TYPE_ADULT]: 'adultMaxOccupancy',
      [TYPE_CHILD]: 'childMaxOccupancy'
    };
    return counterTypeKeyMap[type];
  };

  mapCounterTypeLimit = type => {
    const counterTypeLimit = {
      [TYPE_ADULT]: 1,
      [TYPE_CHILD]: 0
    };
    return counterTypeLimit[type];
  };

  handleOnSubmit = e => {
    e.preventDefault();
    const { form, onSave } = this.props;
    const { adultMaxOccupancy, childMaxOccupancy } = this.state;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const newValues = {
          ...values,
          adultMaxOccupancy,
          childMaxOccupancy
        };
        onSave(newValues);
      }
    });
  };

  render() {
    const { defaultValues, form, isLastPage, onBack, percentage, title } = this.props;
    const { adultMaxOccupancy, childMaxOccupancy } = this.state;
    return (
      <ListingWizardLayout
        isLastPage={isLastPage}
        percentage={percentage}
        title={title}
        onBackButtonClick={onBack}
        onNextButtonClick={this.handleOnSubmit}
      >
        <Row type="flex" justify="space-between" className={styles.formTitleContainer}>
          <h2 className={styles.formTitle}>Some basic details</h2>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={24} className={styles.formItem}>
            <FormInput
              form={form}
              defaultValue={defaultValues.name}
              label="Room Type Name"
              name="name"
              placeholder="Type in your room type name"
              requiredErrorMessage="Please provide a room type name"
              size="large"
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={24} className={styles.formItem}>
            <FormInputNumber
              form={form}
              defaultValue={defaultValues.size}
              name="size"
              label="Size (sqft)"
              minValue={1}
              placeholder="Size of your room type"
              requiredErrorMessage="Please provide size of your room type in sqft"
              size="large"
            />
          </Col>
        </Row>
        <Row className={styles.formItemContainer}>
          <p style={{ marginBottom: '8px' }}>Max Occupancy</p>
          <Row type="flex" justify="space-between">
            <OccupancyCounter
              image={adultImage}
              label="Adult"
              count={adultMaxOccupancy}
              increment={this.handleCounterIncrement(TYPE_ADULT)}
              decrement={this.handleCounterDecrement(TYPE_ADULT)}
            />
            <OccupancyCounter
              image={childImage}
              label="Child (< 12 years old)"
              count={childMaxOccupancy}
              increment={this.handleCounterIncrement(TYPE_CHILD)}
              decrement={this.handleCounterDecrement(TYPE_CHILD)}
            />
          </Row>
        </Row>
      </ListingWizardLayout>
    );
  }
}

BasicDetails.propTypes = {
  form: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  percentage: PropTypes.number.isRequired,
  onBack: PropTypes.func,
  defaultValues: PropTypes.object,
  isLastPage: PropTypes.bool
};

BasicDetails.defaultProps = {
  isLastPage: false
};

const WrappedFormBasicDetails = Form.create()(BasicDetails);

export default WrappedFormBasicDetails;
