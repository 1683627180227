import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Modal, Row, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import MonthPicker from 'components/MonthPicker/MonthPicker';

import styles from './CalendarHeader.module.css';
import { withAppContext } from 'context/AppContext';

class CalendarHeader extends Component {
  handleOnExpandAllRoomTypesClick = () => {
    const { onExpandAllRoomTypesClick } = this.props;

    Modal.confirm({
      icon: <WarningOutlined />,
      title: 'You are about to expand all room types and show all units.',
      content: <Alert type="warning" message="This action may impact your computer performance and require a longer time to load." />,
      okText: 'Proceed to expand',
      onOk() {
        return onExpandAllRoomTypesClick();
      },
      onCancel() {}
    });
  };

  render() {
    const {
      className,
      month,
      onMonthChange,
      onRateUpdateWizardButtonClick,
      isRUWButtonDisabled,
      onBlockWizardButtonClick,
      checkIsAdminReadOnly
    } = this.props;
    return (
      <div className={`${styles.CalendarHeaderRow} ${className}`}>
        <Row gutter={[8, 0]} type="flex">
          <Col /* span={12} */>
            <Button id="ea-button1-multical" onClick={this.handleOnExpandAllRoomTypesClick} type="primary">
              Expand All Room Types
            </Button>
          </Col>
          {this.props.checkAbleEditRoomRates() && (
            <Col /* span={12} */>
              <Tooltip title={isRUWButtonDisabled && 'Select a property to enable feature'} placement="right" mouseEnterDelay={0.5}>
                <Button
                  id="ruw-button1-multical"
                  onClick={onRateUpdateWizardButtonClick}
                  type="primary"
                  disabled={isRUWButtonDisabled || checkIsAdminReadOnly()}
                >
                  Rate Update Wizard
                </Button>
              </Tooltip>
            </Col>
          )}
          <Col /* span={8} */>
            <Button id="ea-button1-multical" onClick={onBlockWizardButtonClick} type="danger" disabled={checkIsAdminReadOnly()}>
              Block Wizard
            </Button>
          </Col>
        </Row>
        <div>
          <MonthPicker todayButtonId="today-date-button1-multical" defaultValue={month} onChange={onMonthChange} />
        </div>
      </div>
    );
  }
}

CalendarHeader.propTypes = {
  month: PropTypes.object.isRequired,
  selectedPropertyId: PropTypes.string.isRequired,
  selectedRateFilters: PropTypes.array.isRequired,
  onMonthChange: PropTypes.func.isRequired,
  onRateUpdateWizardButtonClick: PropTypes.func.isRequired
};

export default withAppContext(CalendarHeader);
