import { httpClient } from './../httpClient';

export const getRevenueShare = id => {
  let params = '/';
  if (id) {
    params = '/' + id;
  }
  return httpClient
    .get('/revenueShare' + params)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};
export const saveRevenueShare = (body, id) => {
  if (id) {
    return httpClient.put('/revenueShare/' + id, body);
  } else {
    return httpClient.post('/revenueShare', body);
  }
};
