import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Button, Row, Col, Card } from 'antd';

import { getConstants } from 'utils/apis/constants';
import { getUserProfileById } from 'utils/apis/user';

import { DEFAULT_USER_PROFILE_URL } from 'utils/constants';

import './UserProfileDisplay.css';

const UserDetailsRow = ({ fieldName, value }) => (
  <Row gutter={{ sm: 24, xs: 16, span: 0 }} className="user-detail-row">
    <Col span={12} lg={8} className="user-detail-field-name">
      {fieldName}:
    </Col>
    <Col span={12} lg={16}>
      {value || '-'}
    </Col>
  </Row>
);

class UserProfileDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: '',
      nationality: ''
    };
  }

  componentWillMount() {
    getUserProfileById(this.props.id).then(res => {
      let nationality = res.data.userProfile && res.data.userProfile.nationality;
      getConstants('countries').then(resCountry => {
        if (resCountry && resCountry.status === 200) {
          Object.keys(resCountry.data).map(
            k =>
              resCountry.data[k].iso2 === nationality &&
              this.setState({
                nationality: resCountry.data[k].name
              })
          );
        } else {
          console.log('Error while retrieving country name');
        }
      });

      this.setState({
        name: res.data.username,
        email: res.data.email,
        firstName: res.data.userProfile ? res.data.userProfile.firstName : '',
        lastName: res.data.userProfile ? res.data.userProfile.lastName : '',
        contactNos: res.data.userProfile ? res.data.userProfile.contactNos : '',
        gender: res.data.userProfile ? (res.data.userProfile.gender === 1 ? 'Male' : 'Female') : '',
        identificationNo: res.data.userProfile ? res.data.userProfile.identificationNo : '',
        createdAt: res.data.userProfile ? res.data.userProfile.createdAt : '',
        imageUrl:
          res.data.userProfile && res.data.userProfile.displayPicture && res.data.userProfile.displayPicture.imageUrl
            ? res.data.userProfile.displayPicture.imageUrl
            : DEFAULT_USER_PROFILE_URL
      });
    });
  }

  constructName = (firstName, lastName) => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    } else if (firstName || lastName) {
      return `${firstName || lastName}`;
    } else {
      return '-';
    }
  };

  render() {
    const { imageUrl, firstName, lastName, name, email, contactNos, gender, identificationNo, nationality } = this.state;

    return (
      <div>
        <Card className="user-profile-card">
          <Row>
            <Col span={24} lg={12}>
              <img src={imageUrl && imageUrl} alt="Profile Pic" className="user-profile-image" />
            </Col>
            <Col span={24} lg={12} className="user-details-col">
              <h3 className="user-profile-name">{this.constructName(firstName, lastName)}</h3>
              <UserDetailsRow fieldName="Username" value={name} />
              <UserDetailsRow fieldName="Email" value={email} />
              <UserDetailsRow fieldName="First Name" value={firstName} />
              <UserDetailsRow fieldName="Last Name" value={lastName} />
              <UserDetailsRow fieldName="Contact No" value={contactNos} />
              {/* <UserDetailsRow fieldName="Gender" value={gender} /> */}
              {this.state.identificationNo && <UserDetailsRow fieldName="NRIC/Passport Number" value={identificationNo} />}
              <UserDetailsRow fieldName="Nationality" value={nationality} />
              <Row type="flex" justify="center" gutter={8} className="user-profile-buttons-row">
                <Col xs={24} sm={24} md={9} lg={9} xl={9} className="user-profile-buttons">
                  <Link to={'/userProfile/' + this.props.id + '/edit'}>
                    <Button id="edit-button1-profile" type="primary" style={{ verticalAlign: 'bottom' }}>
                      Edit Profile
                    </Button>
                  </Link>
                </Col>
                <Col xs={24} sm={24} md={15} lg={15} xl={15} className="user-profile-buttons">
                  <Link to={'/userProfile/' + this.props.id + '/resetpassword'}>
                    <Button id="pass-button1-profile" type="primary" style={{ verticalAlign: 'bottom' }}>
                      Reset Password
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

export default withRouter(UserProfileDisplay);
