import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu } from 'antd';

import { buildAPBillingUri, buildAPBillingInventoryUri, buildAPResyncOTAUri, buildAPUploadExpediaTrxUri } from 'utils/routes';

import MenuItemTitle from './components/MenuItemTitle/MenuItemTitle';
import { SuperAdminList } from 'utils/constants';

const { Item: MenuItem, SubMenu } = Menu;

const MENU_KEY_LOGOUT = 'logout';

const getSubMenuKey = currentMenuKey => {
  if (!currentMenuKey) {
    return undefined;
  }

  const billingUri = buildAPBillingUri();
  let currentSubMenuKey;

  if (currentMenuKey.includes(billingUri)) {
    currentSubMenuKey = billingUri;
  }

  return currentSubMenuKey;
};

const useGetMenuKeys = location => {
  const [currentMenuKey, setCurrentMenuKey] = useState(undefined);
  const [currentSubMenuKey, setCurrentSubMenuKey] = useState(undefined);

  const currentSelectedKeys = useMemo(() => (!!currentMenuKey ? [currentMenuKey] : undefined), [currentMenuKey]);
  const currentOpenKeys = useMemo(() => (!!currentSubMenuKey ? [currentSubMenuKey] : undefined), [currentSubMenuKey]);

  useEffect(() => {
    const defaultMenuKey = location.pathname;

    setCurrentMenuKey(defaultMenuKey);
    setCurrentSubMenuKey(getSubMenuKey(defaultMenuKey));
  }, [location]);

  return { currentMenuKey, currentSelectedKeys, currentOpenKeys, setCurrentMenuKey, setCurrentSubMenuKey };
};

const getUserId = () => {
  const authObj = JSON.parse(localStorage.getItem('auth'));
  return authObj.user._id;
};

const Navbar = ({ history, location }) => {
  const { currentMenuKey, currentSelectedKeys, currentOpenKeys, setCurrentMenuKey, setCurrentSubMenuKey } = useGetMenuKeys(location);

  const handleOnMenuItemClick = menuItem => {
    const selectedMenuKey = menuItem.key;

    if (selectedMenuKey === MENU_KEY_LOGOUT) {
      history.push('/logout');
      return;
    }

    history.push(selectedMenuKey);
  };

  const handleOnSubMenuClick = subMenuKeys => {
    const selectedSubMenuKey = subMenuKeys[subMenuKeys.length - 1];
    setCurrentSubMenuKey(selectedSubMenuKey);

    if (selectedSubMenuKey !== getSubMenuKey(currentMenuKey)) {
      setCurrentMenuKey(undefined);
    }
  };

  return (
    <Menu
      mode="inline"
      selectedKeys={currentSelectedKeys}
      openKeys={currentOpenKeys}
      onClick={handleOnMenuItemClick}
      onOpenChange={handleOnSubMenuClick}
      style={{
        height: '100%'
      }}
    >
      <SubMenu key={buildAPBillingUri()} title={<MenuItemTitle iconType="transaction" title="Billing" />}>
        <MenuItem key={buildAPBillingInventoryUri()}>
          <MenuItemTitle iconType="inventory" title="Inventory" />
        </MenuItem>
      </SubMenu>

      {SuperAdminList.filter(usr => {
        return String(usr) === String(getUserId());
      }).length > 0 && (
        <MenuItem key={buildAPResyncOTAUri()}>
          <MenuItemTitle iconType="global" title="Resync OTA" />
        </MenuItem>
      )}

      <MenuItem key={buildAPUploadExpediaTrxUri()}>
        <MenuItemTitle iconType="cloud-upload" title="Expedia Trx" />
      </MenuItem>

      <MenuItem key={MENU_KEY_LOGOUT}>
        <MenuItemTitle iconType="logout" title="Logout" />
      </MenuItem>
    </Menu>
  );
};

export default withRouter(Navbar);
