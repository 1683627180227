import { httpClient } from 'utils/httpClient';
import { useCustomQuery } from 'hooks/reactQuery';
import { apiErrorHandler } from 'utils/apis/helpers';

export const useFetchConstant = (name, { valueKey = 'code', labelKey = 'label', extraCustomKey = '', query = {} } = {}) => {
  const getConstant = (key, name, query) => {
    return httpClient
      .get(`/constants/${name}`, { params: query })
      .then(response => response.data)
      .catch(apiErrorHandler);
  };

  const { isLoading, data, ...returnedParams } = useCustomQuery(`constant${name}${extraCustomKey}`, [name, query], getConstant, {
    shouldDefaultEmptyObject: false,
    prefixErrorMessage: `Error while getting ${name}.\n`
  });

  let selection = [];
  if (name === 'countries' && query.isFetchingAllCountries && valueKey === 'phoneCode') {
    selection =
      isLoading || !data
        ? []
        : Object.values(data).reduce((uniqueCountryCodes, country) => {
            if (!uniqueCountryCodes.find(countryCode => countryCode.phoneCode === country.phoneCode)) {
              uniqueCountryCodes.push({
                ...country,
                value: country[valueKey],
                label: country[labelKey]
              });
            }
            return uniqueCountryCodes;
          }, []);
  } else {
    selection =
      isLoading || !data
        ? []
        : Object.values(data).map(selection => ({
            ...selection,
            value: String(selection[valueKey]),
            label: selection[labelKey]
          }));
  }

  return { isLoading, selection, data, ...returnedParams };
};
