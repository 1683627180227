import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Form, Row, Col, Alert, Button, Message } from 'antd';

import Card from 'components/Card/Card';
import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import RateModifierFormItems from 'components/RateModifierFormItems/RateModifierFormItems';

import { getHosts } from 'utils/apis/host';
import { getRateModifiersByHost, createRateModifier, updateRateModifier, deleteRateModifier } from 'utils/apis/rateModifier';
import { generatePercentageFromDisplay } from 'utils/general';

import styles from './ModalRateModifier.module.css';

const useFetchHosts = () => {
  const [hostOptions, setHostOptions] = useState([]);

  const fetchHosts = useCallback(async () => {
    const hostOptions = await getHosts().then(res =>
      res.data.map(host => ({
        key: host._id,
        value: host.name,
        currency: host.currency,
        timezone: host.timezone
      }))
    );

    setHostOptions(hostOptions);
  }, []);

  useEffect(() => {
    fetchHosts();
  }, [fetchHosts]);

  return { hostOptions };
};

const useFetchRateModifierOptions = (hostId, form) => {
  const [rateModifierOptions, setRateModifierOptions] = useState([]);
  const [initialCall, setInitialCall] = useState(true);

  const fetchRateModifiers = useCallback(async () => {
    const rateModifierOptions = await getRateModifiersByHost(hostId);

    setRateModifierOptions(rateModifierOptions);
  }, [hostId]);

  useEffect(() => {
    fetchRateModifiers().then(() => {
      // force to validate Rate Name again when onChange of Host
      if (initialCall !== true) {
        form.validateFieldsAndScroll(['rateName'], { force: true }, (err, values) => {});
      } else {
        setInitialCall(false);
      }
    });
  }, [fetchRateModifiers]);

  return { rateModifierOptions };
};

const useRateModifierDetails = selectedRateModifier => {
  const [selectedHost, setSelectedHost] = useState('');

  const isEdit = !!selectedRateModifier;

  const title = useMemo(() => (isEdit ? 'Editing Rate Modifier' : 'Creating Rate Modifier'), [isEdit]);
  const selectedHostId = useMemo(() => (!!selectedHost ? selectedHost : isEdit ? selectedRateModifier.hostId : ''), [
    selectedHost,
    isEdit,
    selectedRateModifier.hostId
  ]);
  const rateName = useMemo(() => (isEdit ? selectedRateModifier.name : ''), [isEdit, selectedRateModifier.name]);
  const remarks = useMemo(() => (isEdit ? selectedRateModifier.remarks : ''), [isEdit, selectedRateModifier.remarks]);

  return { isEdit, title, rateName, remarks, selectedHostId, setSelectedHost };
};

const useDefaultRateModifierOptions = (selectedRateModifier, rateModifierTypes, rateModifierSigns) => {
  const [selectedModifierType, setSelectedModifierType] = useState('');

  const modifierTypeObject = useMemo(
    () =>
      rateModifierTypes.find(type => type.code === (selectedModifierType || (selectedRateModifier && selectedRateModifier.type)) || type.isDefault),
    [selectedRateModifier, selectedModifierType, rateModifierTypes]
  );

  const modifierSignObject = useMemo(
    () => rateModifierSigns.find(sign => (!!selectedRateModifier ? sign.isPositive === selectedRateModifier.isPositive : sign.isDefault)),
    [selectedRateModifier, rateModifierSigns]
  );

  const isTypePercentage = useMemo(() => !!modifierTypeObject && modifierTypeObject.isPercentage, [modifierTypeObject]);
  const defaultModifierType = useMemo(() => (!!modifierTypeObject ? modifierTypeObject.code : ''), [modifierTypeObject]);
  const defaultModifierSign = useMemo(() => (!!modifierSignObject ? modifierSignObject.code : ''), [modifierSignObject]);
  const defaultRateModifierAmount = useMemo(() => (!!selectedRateModifier ? selectedRateModifier.amount : 0), [selectedRateModifier]);

  return { isTypePercentage, defaultModifierType, defaultModifierSign, defaultRateModifierAmount, setSelectedModifierType };
};

const ModalRateModifier = ({
  form,
  isShowModalRateModifier,
  selectedRateModifier,
  onModalRateModifierClose,
  rateModifierTypes,
  rateModifierSigns,
  isAllowEditRateModifier,
  isAllowDeleteRateModifier,
  checkIsAdminReadOnly
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currency, setCurrency] = useState('RM');
  const [timezone, setTimezone] = useState('');

  const { isEdit, title, rateName, remarks, selectedHostId, setSelectedHost } = useRateModifierDetails(selectedRateModifier);
  const { hostOptions } = useFetchHosts();
  const { rateModifierOptions } = useFetchRateModifierOptions(selectedHostId, form);
  const {
    isTypePercentage,
    defaultModifierType,
    defaultModifierSign,
    defaultRateModifierAmount,
    setSelectedModifierType
  } = useDefaultRateModifierOptions(selectedRateModifier, rateModifierTypes, rateModifierSigns);

  const hostSelections = useMemo(() => (hostOptions.length > 0 ? hostOptions : []), [hostOptions]);
  const rateModifiers = useMemo(() => (rateModifierOptions.length > 0 ? rateModifierOptions : []), [rateModifierOptions]);

  const handleOnHostChange = selectedHostId => {
    setSelectedHost(selectedHostId);
  };

  useEffect(() => {
    if (!selectedHostId || hostOptions.length === 0) {
      return;
    }
    const selectedHost = hostOptions.find(host => host.key === selectedHostId);
    if (selectedHost) {
      setCurrency(selectedHost.currency);
      setTimezone(selectedHost.timezone);
    }
  }, [selectedHostId, hostOptions]);

  const handleOnModifierTypeChange = selectedType => {
    setSelectedModifierType(selectedType);
  };

  const checkIsDuplicateRateName = (rule, value, callback) => {
    const isDuplicated = !!rateModifiers.find(rateModifier => rateModifier.name === value) && (!isEdit || rateName !== value);

    if (isDuplicated) {
      callback('Rate Name exists, please use another Rate Name');
    } else {
      callback();
    }
  };

  const handleOnSubmit = e => {
    e.preventDefault();

    form.validateFieldsAndScroll({ force: true }, (err, values) => {
      if (!err) {
        const isPositive = !!rateModifierSigns.find(sign => sign.isPositive && sign.code === values.modifierSign);

        const isPercentage = !!rateModifierTypes.find(type => type.code === values.modifierType && type.isPercentage);
        const amount = isPercentage ? generatePercentageFromDisplay(values.modifierAmount) : values.modifierAmount;

        const formattedRateModifier = {
          host: selectedHostId,
          name: values.rateName,
          type: values.modifierType,
          isPositive,
          amount,
          remarks: values.remarks
        };

        setIsLoading(true);

        if (isEdit) {
          updateRateModifier(selectedRateModifier._id, formattedRateModifier)
            .then(() => {
              onModalRateModifierClose();
              Message.success('Successfully updated rate modifier!');
            })
            .catch(ex => {
              setIsLoading(false);
              Message.Error(ex.toString());
            });
        } else {
          createRateModifier(formattedRateModifier)
            .then(() => {
              onModalRateModifierClose();
              Message.success('Successfully created rate modifier!');
            })
            .catch(ex => {
              setIsLoading(false);
              Message.Error(ex.toString());
            });
        }
      }
    });
  };

  const handleOnDelete = e => {
    e.preventDefault();

    Modal.confirm({
      title: 'Are you sure you want to delete this rate modifier?',
      onOk() {
        setIsLoading(true);

        deleteRateModifier(selectedRateModifier._id)
          .then(() => {
            onModalRateModifierClose();
            Message.success('Successfully deleted this rate modifier!');
          })
          .catch(ex => {
            setIsLoading(false);
            Message.error(ex.toString());
            console.error(ex);
          });
      },
      onCancel() {}
    });
  };

  const rateModifierTitle = (
    <>
      <span className={`${styles.rateAdjustmentLabelAsterisk}`}>*</span>
      <span className={`${styles.rateAdjustmentLabel}`}>Rate Modifier</span>
    </>
  );

  return (
    <Modal title={title} visible={isShowModalRateModifier} onCancel={() => onModalRateModifierClose()} footer={null} destroyOnClose="true">
      <Card className={styles.cardContainer} hideCloseIcon="true">
        <FormSelection
          form={form}
          name="hostId"
          formLabel="Host Name"
          placeholder="Please select a host"
          requiredErrorMessage="Please select a host"
          defaultValue={selectedHostId}
          selections={hostSelections}
          onChange={handleOnHostChange}
        />
        <FormInput
          form={form}
          name="rateName"
          formLabel="Rate Name"
          placeholder="Please enter Rate Name"
          requiredErrorMessage="Please enter Rate Name"
          extraRules={[{ validator: checkIsDuplicateRateName }]}
          defaultValue={rateName}
        />
        <RateModifierFormItems
          form={form}
          title={rateModifierTitle}
          isPercentage={isTypePercentage}
          modifierTypeName={'modifierType'}
          defaultModifierType={defaultModifierType}
          modifierSignName={'modifierSign'}
          defaultModifierSign={defaultModifierSign}
          modifierAmountName={'modifierAmount'}
          defaultRateModifierAmount={defaultRateModifierAmount}
          rateModifierTypeConstants={rateModifierTypes}
          rateModifierSignConstants={rateModifierSigns}
          onTypeChange={handleOnModifierTypeChange}
          currency={currency}
          timezone={timezone}
        />

        <Row>
          <Row>
            <FormInput
              form={form}
              name="remarks"
              formLabel="Remarks"
              inputType="textarea"
              placeholder="Please enter remarks (optional)"
              defaultValue={remarks}
            />
          </Row>

          <Row>
            <Alert type="info" className="styles.info" message="Updating or removing rate modifier will not affect any existing reservation" />
          </Row>
        </Row>
      </Card>

      <Row type="flex" justify="start" gutter={8} className={styles.button}>
        {(!isEdit || isAllowEditRateModifier) && (
          <Col>
            <Button
              id="create-button3a-rate"
              type="primary"
              size="large"
              onClick={e => handleOnSubmit(e)}
              loading={isLoading}
              disabled={checkIsAdminReadOnly()}
            >
              {isEdit ? 'Save' : 'Create'}
            </Button>
          </Col>
        )}
        {isAllowDeleteRateModifier && (
          <Col>
            <Button
              id="del-button1-editrate"
              disabled={!isEdit || checkIsAdminReadOnly()}
              type="danger"
              size="large"
              onClick={e => handleOnDelete(e)}
              loading={isLoading}
            >
              Delete
            </Button>
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default Form.create()(ModalRateModifier);
