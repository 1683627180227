import React, { Component } from 'react';
import { Icon } from 'antd';

class CloseButtonReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <button aria-label="Close" className="ant-drawer-close ant-drawer-close-reservation-x" onClick={this.props.onClick}>
        <span>
          <Icon type="close" theme="outlined" />
        </span>
      </button>
    );
  }
}

export default CloseButtonReservation;
