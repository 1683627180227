import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Card, Row, Col, Form, message, Modal, notification, Skeleton } from 'antd';
import PropTypes from 'prop-types';

import FormInput from 'components/FormInput/FormInput';
import UploadFile from 'components/UploadFile/UploadFile';
import FormDatePickerRange from 'components/FormDatePickerRange/FormDatePickerRange';

import { useGetUnitDetails, putUpdateHostProtect } from 'utils/apis/unit';
import { checkIsObjectEmpty, guard } from 'utils/general';
import { formatToMomentObject, formatToDateString } from 'utils/date';

const FIELD_NAME_INSURED_NAME = 'insured.insuredName';
const FIELD_NAME_ID_NUM = 'insured.idNo';
const FIELD_NAME_REF_CODE = 'insured.refCode';
const FIELD_NAME_LOCATION = 'location';
const FIELD_NAME_CERT_NO = 'certNo';
const FIELD_NAME_MASTER_ALL_RISK_NUM = 'masterPolicyNos.allRisk';
const FIELD_NAME_MASTER_PUBLIC_LIABILITY_NUM = 'masterPolicyNos.publicLiability';
const FIELD_NAME_START_DATE = 'insuranceStartDate';
const FIELD_NAME_END_DATE = 'insuranceEndDate';

const useGetHostProtectDefaultValues = unitId => {
  const { data: unit, isLoading: isUnitLoading } = useGetUnitDetails(unitId);

  const defaultValues = useMemo(() => guard(() => unit.protection, {}), [unit]);
  const getFieldDefaultValues = useCallback(
    keyPath => (!checkIsObjectEmpty(defaultValues) ? keyPath.split('.').reduce((obj, key) => (!!obj ? obj[key] : obj), defaultValues) : undefined),
    [defaultValues]
  );

  return { defaultValues, getFieldDefaultValues, isUnitLoading };
};

const HostProtectModal = ({ form, unitId, onOk, onCancel }) => {
  const [certFile, setCertFile] = useState({});
  const { defaultValues, getFieldDefaultValues, isUnitLoading } = useGetHostProtectDefaultValues(unitId);

  useEffect(() => {
    setCertFile(defaultValues.file);
  }, [defaultValues]);

  const handleOnUploadFinish = e => {
    if (e.file.type === 'application/pdf') {
      const uploadedFile = {
        name: e.file.name,
        link: e.fileUrl
      };
      setCertFile(uploadedFile);
    } else {
      message.error('File must be uploaded in pdf format!');
    }
  };

  const handleOnFileDelete = () => {
    setCertFile({});
  };

  const handleOnSave = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) {
        Object.values(err).forEach(field => message.error(field.errors[0].message));
      } else {
        const protection = {
          ...values,
          insuranceStartDate: formatToDateString(values.insurancePeriod[0]),
          insuranceEndDate: formatToDateString(values.insurancePeriod[1]),
          file: certFile
        };

        putUpdateHostProtect(unitId, protection).then(() => {
          notification.success({
            message: 'Success',
            description: "You've successfully updated this unit's protection details."
          });
          onOk();
        });
      }
    });
  };

  return (
    <Modal visible={true} onOk={handleOnSave} onCancel={onCancel}>
      {isUnitLoading ? (
        <Skeleton />
      ) : (
        <Form>
          <Card title="HostProtect Information" style={{ marginTop: 20 }}>
            <Row gutter={16}>
              <Col span={24}>
                <FormInput
                  form={form}
                  name={FIELD_NAME_CERT_NO}
                  label="Certification No."
                  defaultValue={getFieldDefaultValues(FIELD_NAME_CERT_NO)}
                  requiredErrorMessage="Please enter the certificate number"
                />
              </Col>
              <Col span={24}>
                <FormDatePickerRange
                  formLabel="Insurance Period"
                  form={form}
                  fieldName="insurancePeriod"
                  requiredErrorMessage="Please select a range of dates"
                  defaultPickerValue={[
                    formatToMomentObject(getFieldDefaultValues(FIELD_NAME_START_DATE)),
                    formatToMomentObject(getFieldDefaultValues(FIELD_NAME_END_DATE))
                  ]}
                  placeholder={['Start Date', 'End Date']}
                />
              </Col>
              <Col span={24}>
                <FormInput
                  form={form}
                  name={FIELD_NAME_INSURED_NAME}
                  label="Insured Name"
                  defaultValue={getFieldDefaultValues(FIELD_NAME_INSURED_NAME)}
                />
              </Col>
              <Col span={24}>
                <FormInput
                  form={form}
                  name={FIELD_NAME_ID_NUM}
                  label="Identification No. / Registration No."
                  defaultValue={getFieldDefaultValues(FIELD_NAME_ID_NUM)}
                />
              </Col>
              <Col span={24}>
                <FormInput form={form} name={FIELD_NAME_REF_CODE} label="Ref. Code" defaultValue={getFieldDefaultValues(FIELD_NAME_REF_CODE)} />
              </Col>
              <Col span={24}>
                <FormInput
                  form={form}
                  name={FIELD_NAME_LOCATION}
                  label="Location to be insured"
                  defaultValue={getFieldDefaultValues(FIELD_NAME_LOCATION)}
                />
              </Col>
              <Col span={24}>
                <FormInput
                  form={form}
                  name={FIELD_NAME_MASTER_ALL_RISK_NUM}
                  label="Master Policy No. (All Risk)"
                  defaultValue={getFieldDefaultValues(FIELD_NAME_MASTER_ALL_RISK_NUM)}
                />
              </Col>
              <Col span={24}>
                <FormInput
                  form={form}
                  name={FIELD_NAME_MASTER_PUBLIC_LIABILITY_NUM}
                  label="Master Policy No. (Public Liability)"
                  defaultValue={getFieldDefaultValues(FIELD_NAME_MASTER_PUBLIC_LIABILITY_NUM)}
                />
              </Col>
            </Row>
          </Card>
          <Card title="HostProtect Certificate" style={{ marginTop: 10 }}>
            <Row>
              <UploadFile
                file={certFile}
                buttonText="Upload HostProtect Certificate"
                onUploadFinish={handleOnUploadFinish}
                onFileDelete={handleOnFileDelete}
              />
            </Row>
          </Card>
        </Form>
      )}
    </Modal>
  );
};

HostProtectModal.propTypes = {
  unitId: PropTypes.string.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func
};

HostProtectModal.defaultProps = {
  unitId: undefined,
  onOk: () => {},
  onCancel: () => {}
};

export default Form.create()(HostProtectModal);
