import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Button, Col, Icon, Row, Table, Tooltip } from 'antd';
import moment from 'moment';

import { withAppContext } from 'context/AppContext';
import { useGetHosts } from 'utils/apis/host';
import { useGetPaginatedHostInventory, useGetHostInventoryUsage, useGetHostInventoryCsvData } from 'utils/apis/billingInventory';

import { guard } from 'utils/general';
import { constructColumn, constructColumnFilterSearch, constructColumnFilterRadio, handleOnAggregationTableChange } from 'utils/table/table';

import MonthPicker from 'components/MonthPicker/MonthPicker';
import { BasicSelect } from 'components/FormSelection/FormSelection';

import { BodyContainer, HeaderContainer, InventoryContainer, StatBoxContainer, StatTitle, StatValue } from './BillingInventory.styles';

const MONTH_YEAR_FORMAT = 'YYYY-MM';

const useHostOptions = (getUserHosts, checkIsAdmin) => {
  const [selectedHostId, setSelectedHostId] = useState();

  const constructHostOptions = useCallback(hosts => {
    const hostOptions = hosts.map(host => ({ label: host.name, value: host._id }));
    return hostOptions;
  }, []);

  const { data: hostOptions, isLoading: isLoadingHostOptions } = useGetHosts(constructHostOptions);

  useEffect(() => {
    if (!selectedHostId) {
      if (checkIsAdmin() && hostOptions) {
        setSelectedHostId(hostOptions[0].value);
      } else {
        const userHosts = getUserHosts();
        setSelectedHostId(userHosts[0]);
      }
    }
  }, [selectedHostId, hostOptions, getUserHosts, checkIsAdmin]);

  return { isLoadingHostOptions, hostOptions, selectedHostId, setSelectedHostId };
};

const constructColumns = () => {
  const PLAN_ACTIVE_SELECTIONS = [{ label: 'Active', value: true }, { label: 'Inactive', value: false }];

  return [
    {
      ...constructColumn('Unit', 'unitName', { hasSorter: true }),
      ...constructColumnFilterSearch('unitName', 'Search Unit'),
      render: (text, record) => {
        if (record.isUnitDeleted) {
          return (
            <>
              {text}{' '}
              <Tooltip title="This unit has been deleted">
                <Icon type="question-circle-o" />
              </Tooltip>
            </>
          );
        } else {
          return <Link to={`/listing/${record.unitId}`}>{text}</Link>;
        }
      }
    },
    {
      ...constructColumn('Property', 'propertyName', { hasSorter: true }),
      ...constructColumnFilterSearch('propertyName', 'Search Property')
    },
    {
      ...constructColumn('HostManage', 'isHostManageActive'),
      ...constructColumnFilterRadio('isHostManageActive', PLAN_ACTIVE_SELECTIONS),
      render: isHostManageActive => {
        return isHostManageActive ? <Icon type="check" /> : '-';
      }
    },
    {
      ...constructColumn('HostConnect', 'isHostConnectActive'),
      ...constructColumnFilterRadio('isHostConnectActive', PLAN_ACTIVE_SELECTIONS),
      render: isHostConnectActive => {
        return isHostConnectActive ? <Icon type="check" /> : '-';
      }
    },
    {
      ...constructColumn('D.O.R.A', 'isDORAActive'),
      ...constructColumnFilterRadio('isDORAActive', PLAN_ACTIVE_SELECTIONS),
      render: isDoraActive => {
        return isDoraActive ? <Icon type="check" /> : '-';
      }
    },
    {
      ...constructColumn('HostProtect Expired Date', 'hostProtectExpiredDate', { hasSorter: true })
    }
  ];
};

const StatisticBox = ({ title, value, loading }) => (
  <StatBoxContainer>
    <StatTitle span={24}>{title}</StatTitle>
    <StatValue span={24}>{loading ? <Icon type="loading" spin /> : value}</StatValue>
  </StatBoxContainer>
);

const DownloadCsvButton = ({ monthWithYear, hostId, hostOptions }) => {
  const { data: csvData, isLoading: isCsvDataLoading } = useGetHostInventoryCsvData(monthWithYear, hostId);

  const getCsvFileName = () => {
    const defaultFileName = 'Inventory Reporting';
    const stringMonthYear = moment(monthWithYear).format('MMM YYYY');
    const defaultFileNameWithMonthYear = `${stringMonthYear} - ${defaultFileName}`;

    const hostLabel = guard(() => hostOptions.find(option => option.value === hostId).label);
    const fileName = !!hostLabel ? `${hostLabel} - ${defaultFileNameWithMonthYear}` : `${defaultFileNameWithMonthYear}`;

    return `${fileName}.csv`;
  };

  return (
    <CSVLink filename={getCsvFileName()} data={csvData || []}>
      <Button type="primary" icon="download" loading={isCsvDataLoading}>
        {isCsvDataLoading ? 'Fetching...' : 'Download CSV'}
      </Button>
    </CSVLink>
  );
};

const BillingInventory = ({ getUserHosts, checkIsAdmin }) => {
  const [monthWithYear, setMonthWithYear] = useState(moment().format(MONTH_YEAR_FORMAT));
  const [query, setQuery] = useState({});
  const [isFetchCsvButtonClicked, setIsFetchCsvButtonClicked] = useState(false);

  const { isLoadingHostOptions, hostOptions, selectedHostId, setSelectedHostId } = useHostOptions(getUserHosts, checkIsAdmin);
  const { paginatedData: inventoryStatuses, total, isLoading: isFetchingInventoryStatuses } = useGetPaginatedHostInventory(
    { ...query, monthWithYear },
    selectedHostId
  );
  const { data: unitUsageStats, isLoading: isUnitUsageStatsLoading } = useGetHostInventoryUsage(monthWithYear, selectedHostId);

  useEffect(() => {
    setIsFetchCsvButtonClicked(false);
  }, [selectedHostId, monthWithYear]);

  const constructUnitUsageStats = useCallback(
    stats =>
      guard(
        () => [
          { title: 'Entry Plan', value: stats.entryPlan || 0 },
          { title: 'Starter Plan', value: stats.starterPlan || 0 },
          { title: 'Standard Plan', value: stats.standardPlan || 0 },
          { title: 'Host Protect', value: stats.hostProtect || 0 }
        ],
        []
      ),
    []
  );

  const handleOnMonthYearChange = date => {
    const formattedDate = date.format(MONTH_YEAR_FORMAT);
    setMonthWithYear(formattedDate);
  };

  return (
    <InventoryContainer>
      <HeaderContainer>
        <Row type="flex" justify="end" gutter={[16, { xs: 16, sm: 16, md: 0 }]}>
          <Col>
            <MonthPicker defaultValue={moment(monthWithYear)} onChange={handleOnMonthYearChange} />
          </Col>
          {!isLoadingHostOptions && (
            <Col>
              <BasicSelect label="Host" selections={hostOptions} value={selectedHostId} onChange={setSelectedHostId} placeholder="No Host" />
            </Col>
          )}
        </Row>
      </HeaderContainer>

      <BodyContainer>
        <Row gutter={[16, { xs: 16, sm: 16, lg: 0 }]}>
          {constructUnitUsageStats(unitUsageStats).map(stat => (
            <Col span={24} sm={12} md={6} key={stat.title}>
              <StatisticBox title={stat.title} value={stat.value} loading={isUnitUsageStatsLoading} />
            </Col>
          ))}
        </Row>
        <Row type="flex" justify="end" style={{ marginBottom: 20, marginTop: 40 }}>
          {isFetchCsvButtonClicked ? (
            <DownloadCsvButton hostId={selectedHostId} monthWithYear={monthWithYear} hostOptions={hostOptions} />
          ) : (
            <Button type="primary" icon="download" onClick={() => setIsFetchCsvButtonClicked(true)}>
              Fetch CSV Data
            </Button>
          )}
        </Row>
        <Table
          loading={isFetchingInventoryStatuses}
          rowKey={record => record.unitId}
          dataSource={inventoryStatuses}
          columns={constructColumns()}
          pagination={{ total }}
          scroll={{ x: 1000 }}
          onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
        />
      </BodyContainer>
    </InventoryContainer>
  );
};

export default withAppContext(BillingInventory);
