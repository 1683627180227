import React, { Component } from 'react';
import { Form, Icon, Input, Button, Alert } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import intl from 'react-intl-universal';

import FormPassword from 'components/FormPassword/FormPassword.js';

import { postSignUp } from '../../utils/apis/authentication.js';
import './SignUpForm.css';
const FormItem = Form.Item;

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSigningUp: false,
      errorMsg: ''
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ isSigningUp: true });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        postSignUp(values)
          .then(res => {
            res.status === 200 && this.props.history.push('/login');
            res.status === 422 && this.setState({ errorMsg: res.data.error, isSigningUp: false });
          })
          .catch(error => {
            this.setState({
              errorMsg: 'Something went wrong! Please directly contact our Customer Service for assistance.',
              isSigningUp: false
            });
          });
      } else {
        this.setState({
          isSigningUp: false
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="signup-form">
        <FormItem>
          {getFieldDecorator('firstName', {
            rules: [
              {
                required: true,
                message: intl
                  .get('forms.required', {
                    formField: intl.get('forms.placeholders.firstName').d('first name')
                  })
                  .d('Please enter your first name!')
              }
            ]
          })(<Input prefix={<Icon type="user" />} placeholder={intl.get('forms.placeholders.firstName')} />)}
        </FormItem>

        <FormItem>
          {getFieldDecorator('lastName', {
            rules: [
              {
                required: true,
                message: intl
                  .get('forms.required', {
                    formField: intl.get('forms.placeholders.lastName').d('last name')
                  })
                  .d('Please enter your last name!')
              }
            ]
          })(<Input prefix={<Icon type="user" />} placeholder={intl.get('forms.placeholders.lastName').d('Last Name')} />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('username', {
            rules: [
              {
                required: true,
                message: intl
                  .get('forms.required', {
                    formField: intl.get('forms.placeholders.username').d('username')
                  })
                  .d('Please enter your last name!')
              }
            ]
          })(<Input prefix={<Icon type="user" />} placeholder={intl.get('forms.placeholders.username').d('Username')} />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: intl
                  .get('forms.required', {
                    formField: intl.get('forms.placeholders.email').d('email')
                  })
                  .d('Please enter your email!')
              },
              {
                type: 'email',
                message: intl.get('forms.valid.email').d('You have entered an invalid email!')
              }
            ]
          })(<Input prefix={<Icon type="mail" />} placeholder={intl.get('forms.placeholders.email').d('Email')} />)}
        </FormItem>
        <FormPassword form={this.props.form} shouldHideLabel />
        <FormItem>
          {getFieldDecorator('icNo', {
            rules: [
              {
                required: true,
                message: intl
                  .get('forms.required', {
                    formField: intl.get('forms.placeholders.icNo').d('NRIC/Passport Number')
                  })
                  .d('Please enter your NRIC/Passport Number.')
              }
            ]
          })(<Input prefix={<Icon type="idcard" />} placeholder={intl.get('forms.placeholders.icNo').d('NRIC/Passport Number')} />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('contactNo', {
            rules: [
              {
                required: true,
                message: intl
                  .get('forms.required', {
                    formField: intl.get('forms.placeholders.contactNo').d('contact number')
                  })
                  .d('Please enter your contact number.')
              }
            ]
          })(<Input prefix={<Icon type="phone" />} type="tel" placeholder={intl.get('forms.placeholders.contactNo').d('Contact Number')} />)}
        </FormItem>
        {this.state.errorMsg && <Alert message={this.state.errorMsg} type="error" showIcon />}
        <FormItem>
          <Button type="primary" htmlType="submit" className="signup-form-button" loading={this.state.isSigningUp}>
            {intl.get('forms.labels.signup')}
          </Button>
          {intl.get('forms.links.haveAcc').d('Already have an account? ')}
          <Link to={'/login'}>{intl.get('forms.links.login').d('Login now!')}</Link>
        </FormItem>
      </Form>
    );
  }
}

export default withRouter(Form.create()(SignUpForm));
