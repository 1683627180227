import React, { Component } from 'react';
import SingleViewLayout from './../../layouts/SingleViewLayout/SingleViewLayout';
import ForgotPasswordForm from './../../components/ForgotPasswordForm/ForgotPasswordForm.js';
import { Card, Col, Alert } from 'antd';
import intl from 'react-intl-universal';
import './ForgotPassword.css';

class ForgotPassword extends Component {
  render() {
    return (
      <SingleViewLayout className="centralise">
        <Col xs={22} sm={16} md={14} lg={10} xl={8}>
          <Card
            title={intl.get('titles.forgotPassword').d('Forgot Your Password?')}
          >
            <p>
              {intl
                .get('description.forgotPassword')
                .d(
                  'Enter your email address and we will sent an instruction on reseting password.'
                )}
            </p>
            <ForgotPasswordForm />
            <Alert
              closable
              message={intl
                .get('description.resetReminder')
                .d(
                  "If you don't receive an email from us within a few minutes, please check your spam filter as sometimes they end up in there."
                )}
              type="info"
              showIcon
            />
          </Card>
        </Col>
      </SingleViewLayout>
    );
  }
}

export default ForgotPassword;
