import React, { Component } from 'react';
import { Form, Input, Icon, Button, Popconfirm, Row, Col } from 'antd';
import './TransportFieldSet.css';
import { PropTypes } from 'prop-types';
const FormItem = Form.Item;

class ItemsProvided extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    data: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      update: false
    };
  }

  remove = k => {
    const { form, onChange } = this.props;
    // can use data-binding to get
    const newKeys = form.getFieldValue('keys').filter(key => key !== k);

    // can use data-binding to set
    form.setFieldsValue({
      keys: newKeys
    });

    form.validateFields(
      newKeys.reduce((fieldsToValidate, key) => {
        return [...fieldsToValidate, `item[${key}]`, `label[${key}]`];
      }, []),
      { first: true },
      (err, values) => {
        const newValues = form.getFieldsValue();
        newValues.keys = newKeys; //in case setFieldsValue sets keys after the getFieldsValue
        onChange(newValues, !err);
      }
    );
  };

  add = () => {
    const { form, onChange } = this.props;
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(Math.max(0, ...keys) + 1);
    form.setFieldsValue({
      keys: nextKeys
    });

    const newValues = form.getFieldsValue();
    newValues.keys = nextKeys; //in case setFieldsValue sets keys after the getFieldsValue
    onChange(newValues, false);
  };

  handleSubmit = e => {
    const { form, onChange } = this.props;
    form.validateFields({ first: true }, (err, values) => {
      onChange(values, !err);
    });
  };

  componentDidMount() {
    const { data, form } = this.props;
    let keys = data.map(d => d.key);
    if (keys.length > 0) {
      form.setFieldsValue(
        {
          keys
        },
        this.setState({
          update: true
        })
      );
    }
  }

  componentDidUpdate(prevProp, prevState) {
    const { data, form } = this.props;
    const { update } = this.state;
    if (update) {
      data.forEach(d => {
        let obj = {};
        let key = `item[${d.key}]`;
        let key2 = `label[${d.key}]`;
        obj[key] = d.item;
        obj[key2] = d.label;
        form.setFieldsValue({
          ...obj
        });
      });
      this.setState({
        update: false
      });
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => {
      return (
        <React.Fragment>
          <Row justify="start" gutter={8}>
            <Col span={24} lg={12}>
              <FormItem required={false} key={`item[${k}]`} layout="inline">
                <div className="booking-form-item-control-wrapper">
                  <label>Item Name:</label>
                </div>
                <div className="booking-form-item-control-wrapper">
                  {getFieldDecorator(`item[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please input item name.'
                      }
                    ]
                  })(<Input placeholder="Item Name" onBlur={this.handleSubmit} />)}
                </div>
              </FormItem>
            </Col>
            <Col span={24} lg={12}>
              <FormItem required={false} key={`label[${k}]`}>
                <div className="booking-form-item-control-wrapper">
                  <label>Label:</label>
                </div>
                <div className="booking-form-item-control-wrapper">
                  {getFieldDecorator(`label[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [
                      {
                        required: false,
                        whitespace: true
                      }
                    ]
                  })(<Input placeholder="Label" onBlur={this.handleSubmit} />)}
                </div>
              </FormItem>
            </Col>
          </Row>

          <FormItem className="guest-input" required={false} key={`removelabel[${k}]`}>
            <div className="booking-form-item-control-wrapper">
              <Popconfirm title="Are you sure you want to remove this item?" onConfirm={() => this.remove(k)} okText="Yes" cancelText="No">
                <Button className="booking-form-danger" type="danger" style={{ width: '100%' }}>
                  <Icon type="minus" /> Remove item
                </Button>
              </Popconfirm>
            </div>
          </FormItem>
        </React.Fragment>
      );
    });
    return (
      <Form>
        {formItems}
        <FormItem className="guest-input">
          <Button type="dashed" onClick={this.add} style={{ width: '100%' }}>
            <Icon type="plus" /> Add item
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const WrappedItemsProvided = Form.create()(ItemsProvided);
export default WrappedItemsProvided;
