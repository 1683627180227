import React, { Component } from 'react';
import { BlobProvider, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import NumberToWords from 'number-to-words';

import { numberWithCommas } from 'utils/general';
export default class RefundableDeposit extends Component {
  static propTypes = {
    data: PropTypes.object,
    currency: PropTypes.string
  };

  handleOnClickPdfBtn = url => {
    window.open(url, '_blank');
  };

  constructDataForRefundDepositPdf = reservation => {
    const { depositCollected, guestDetails, unit } = reservation;
    return {
      propertyName: unit.roomType.property.name || '',
      depositCollected: depositCollected || 0,
      guestName: (guestDetails.userProfile && `${guestDetails.userProfile.firstName || '-'} ${guestDetails.userProfile.lastName || ''}`) || '-',
      hostEmail: unit.roomType.property.host.email || '-',
      hostContactNo: unit.roomType.property.host.contactNo || '-',
      hostData: unit.roomType.property.host || {}
    };
  };

  generateDepositWording = deposit => {
    const depositValuesAfterSplits = Number(deposit)
      .toFixed(2)
      .split('.');
    const combinedDepositWording = depositValuesAfterSplits.reduce((text, currentWord) => {
      if (!!!text) {
        return `${text} ${NumberToWords.toWords(Number(currentWord)).toUpperCase()} RINGGIT`;
      } else if (Number(currentWord) > 0) {
        if (Number(currentWord) > 1) {
          return `${text} AND ${NumberToWords.toWords(Number(currentWord)).toUpperCase()} CENTS`;
        } else {
          return `${text} AND ${NumberToWords.toWords(Number(currentWord)).toUpperCase()} CENT`;
        }
      }
      return text;
    }, '');
    return `${combinedDepositWording} ONLY`;
  };

  render() {
    const { data, reservation, currency } = this.props;

    const refundData = {
      ...this.constructDataForRefundDepositPdf(reservation),
      ...data
    };

    const styles = StyleSheet.create({
      page: {
        flexDirection: 'row'
      },
      image: {
        width: 80,
        height: 80
      },
      text: {
        color: '#212121',
        width: '30%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 10,
        paddingTop: 5
      },
      flex: {
        flexDirection: 'row',
        width: '100%',
        marginTop: 50,
        fontSize: 10,
        justifyContent: 'space-between'
      },
      row: {
        flexDirection: 'row'
      },
      paddingBtm: {
        paddingBottom: 5
      },
      body: {
        flexDirection: 'column',
        borderBottomWidth: 2,
        borderBottomColor: '#112131',
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
        paddingTop: 20
      },
      move: {
        margin: '10px'
      },
      ReceiptNumtext: {
        textAlign: 'right',
        fontSize: 10,
        paddingTop: 25,
        paddingRight: 20
      },
      fields: {
        color: '#212121',
        width: '100%',
        textAlign: 'left',
        fontSize: 10,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 50
      },
      line: {
        borderBottomColor: '#112131',
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
        paddingTop: 20,
        paddingBottom: 20
      },
      issuedByHelper: {
        paddingLeft: 70
      },
      addressText: {
        color: '#212121',
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 10,
        paddingTop: 5
      },
      title: {
        textAlign: 'center',
        fontSize: 20,
        paddingTop: 15,
        paddingLeft: 150
      },
      fieldsHostDetails: {
        fontSize: 10,
        width: '80%',
        textAlign: 'left',
        paddingLeft: 10,
        paddingRight: 10,
        whiteSpace: 'normal'
      },
      groupFields: {
        width: '100%',
        flexDirection: 'row',
        paddingRight: 50,
        paddingLeft: 50,
        paddingBottom: 10,
        margin: 'auto',
        paddingTop: 20,
        alignItems: 'stretch'
      }
    });
    const address = refundData.hostData.useHostDetails
      ? refundData.hostData.street +
        (refundData.hostData.city ? ', ' + refundData.hostData.city : '') +
        (refundData.hostData.zipCode ? ', ' + refundData.hostData.zipCode : '') +
        (refundData.hostData.state ? ', ' + refundData.hostData.state : '') +
        (refundData.hostData.country ? ', ' + refundData.hostData.country : '')
      : refundData.street +
        (refundData.city ? ', ' + refundData.city : '') +
        (refundData.zipCode ? ', ' + refundData.zipCode : '') +
        (refundData.state ? ', ' + refundData.state : '') +
        (refundData.country ? ', ' + refundData.country : '');

    const MyDocument = (
      <Document>
        <Page size="A4" wrap>
          <View>
            <View style={styles.groupFields}>
              <View>
                <Image style={styles.image} src={refundData.image || ''} />
              </View>
              <View style={styles.fieldsHostDetails}>
                <Text style={{ ...styles.paddingBtm, fontSize: 12 }}>
                  {refundData.hostData.useHostDetails ? refundData.hostData.name : refundData.propertyName || '-'}
                </Text>
                <View style={styles.row}>
                  <View style={{ width: '20%' }}>
                    <Text style={styles.paddingBtm}>Address</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.paddingBtm}>: </Text>
                  </View>
                  <View style={{ width: '85%' }}>
                    <Text style={{ ...styles.paddingBtm, whiteSpace: 'normal' }}>{address}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '20%' }}>
                    <Text style={styles.paddingBtm}>Email</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.paddingBtm}>: </Text>
                  </View>
                  <View style={{ width: '85%' }}>
                    <Text style={styles.paddingBtm}>{refundData.hostEmail || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '20%' }}>
                    <Text style={styles.paddingBtm}>Contact Number</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.paddingBtm}>: </Text>
                  </View>
                  <View style={{ width: '85%' }}>
                    <Text style={styles.paddingBtm}>{refundData.hostContactNo || '-'}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%'
              }}
            >
              <View style={styles.row}>
                <View style={{ width: '70%' }}>
                  <Text style={styles.title}>Refund Deposit</Text>
                </View>
                <View style={{ width: '30%' }}>
                  <Text style={styles.ReceiptNumtext}>Receipt No : {refundData.confirmationCode || '-'}</Text>
                </View>
              </View>
            </View>
            <View style={styles.body}>
              <Text style={styles.fields}>RECEIVED FROM : {refundData.guestName || '-'}</Text>
            </View>
            {/* <View style={styles.line}>
              <Text style={styles.fields}>
                THE SUM OF RINGGIT : {refundData.depositCollected ? this.generateDepositWording(refundData.depositCollected) : '-'}{' '}
              </Text>
            </View> */}
            <View style={styles.line}>
              <Text style={styles.fields}>IN PAYMENT OF : REFUNDABLE DEPOSIT</Text>
            </View>
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                paddingRight: 15,
                paddingLeft: 15,
                margin: 'auto'
              }}
            >
              <View
                style={{
                  color: '#212121',
                  width: '50%',
                  textAlign: 'left',
                  fontSize: 10,
                  paddingTop: 20,
                  paddingBottom: 5,
                  paddingRight: 35,
                  paddingLeft: 35
                }}
              >
                <Text style={styles.amount}>
                  {' '}
                  Amount : {currency ? currency : 'RM'} {numberWithCommas(refundData.depositCollected) || '-'}
                </Text>
              </View>
              <View
                style={{
                  color: '#212121',
                  width: '50%',
                  textAlign: 'right',
                  fontSize: 10,
                  paddingTop: 100,
                  paddingBottom: 5
                }}
              >
                <Text stlye={{ paddingRight: '100%' }}>ISSUED BY : ________________________</Text>
                <Text style={{ paddingRight: 20 }}>(Name/Sign/Stamp)</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );

    return (
      <BlobProvider document={MyDocument} fileName="Refund_deposit.pdf">
        {({ blob, url, loading, error }) => (
          <Button type="primary" loading={loading} className="modal-receipts-btn" onClick={() => this.handleOnClickPdfBtn(url)}>
            {loading ? 'Loading' : 'Refund Deposit'}
          </Button>
        )}
      </BlobProvider>
    );
  }
}
