import React from 'react';
import { Link } from 'react-router-dom';

import { buildOverviewUri } from 'utils/routes';

import Logo from 'images/hostplatform-logo.png';
import SmallLogo from 'images/hostplatform-small-logo.png';

import styles from './HomeLogo.module.css';

const HomeLogo = ({ isCollapsed }) => {
  return (
    <div className={styles.logoContainer}>
      <Link to={buildOverviewUri()}>
        <img src={isCollapsed ? SmallLogo : Logo} alt="hostplatform logo" className={styles.logo} />
      </Link>
    </div>
  );
};

export default HomeLogo;
