import React, { useState, useEffect } from 'react';
import { Alert, Button, Card, Col, Form, Modal, notification, Row, Skeleton, Switch } from 'antd';

import AutoSearch from '../../../../../components/AutoSearch';
import FormSelection from '../../../../../../../components/FormSelection/FormSelection';
import OTARateInput from '../../../OTARateInput/OTARateInput';
import CtripCMAdvancedOptionsModal from './components/CtripCMAdvancedOptionsModal';

import { getCtripListing, getCtripListings, syncUpdateCtrip, syncNewCtrip } from 'utils/apis/integration';
import { getCtripCMSyncStatusConstant, getRateModifierTypeConstant, getRateModifierSignConstant } from 'utils/apis/constants';
import { getWebRateByRoomType } from 'utils/apis/rate';
import { STANDARD_RATE_CODE } from 'utils/constants';
import { checkIsObjectEmpty, generatePercentageFromDisplay, guard } from 'utils/general';

const MAPPING_TOGGLE = {
  ON: 'on',
  OFF: 'off'
};

const CtripCMForm = ({ form, property, data, onAfterSave, onClose }) => {
  const [listingIdsOption, setListingIdsOption] = useState([]);
  const [syncStatusConstants, setSyncStatusConstants] = useState({});
  const [rateModifierTypeConstants, setRateModifierTypeConstants] = useState({});
  const [rateModifierSignConstants, setRateModifierSignConstants] = useState({});
  const [isAdvancedOptionsModalVisible, setIsAdvancedOptionsModalVisible] = useState(false);

  const [selectedCtripId, setSelectedCtripId] = useState('');
  const [ctripListing, setCtripListing] = useState({});
  const [ctripRoomOptions, setCtripRoomOptions] = useState([]);
  const [roomsMapping, setRoomsMapping] = useState([]);
  const [currency, setCurrency] = useState('RM');

  const initializeFetch = async () => {
    const ctripListingIds = await getCtripListings().then(ctripListings => ctripListings.map(listing => listing.ctripListingId));
    const syncStatusConstants = await getCtripCMSyncStatusConstant();
    const rateModifierTypeConstants = await getRateModifierTypeConstant().then(constant => Object.values(constant));
    const rateModifierSignConstants = await getRateModifierSignConstant().then(constant => Object.values(constant));

    return { ctripListingIds, syncStatusConstants, rateModifierTypeConstants, rateModifierSignConstants };
  };

  useEffect(() => {
    if (data && data.currency) {
      setCurrency(data.currency);
    }

    if (property.roomTypes) {
      const roomsMapping = property.roomTypes.map(roomType => ({
        roomType: roomType._id,
        toggle: MAPPING_TOGGLE.OFF
      }));

      if (data && data.roomTypes && !!ctripListing && !checkIsObjectEmpty(ctripListing)) {
        const mappedRoomsMapping = roomsMapping.map(roomMapping => {
          ctripListing.rooms.forEach(ctripRoom => {
            if (ctripRoom.roomType === roomMapping.roomType) {
              const syncedRoomData = data.roomTypes.find(roomType => roomType.roomType._id === ctripRoom.roomType);

              // when moving from ctrip to another ota, data changed, so syncedRoomData becomes undefined causing lookup failure
              if (syncedRoomData === undefined) {
                return;
              }

              roomMapping.ctripRoomId = ctripRoom.ctripRoomId;
              roomMapping.rates = syncedRoomData.rates;
              roomMapping.toggle = MAPPING_TOGGLE.ON;
            }
          });
          return roomMapping;
        });
        setRoomsMapping(mappedRoomsMapping);
      } else {
        setRoomsMapping(roomsMapping);
      }
    }
  }, [property, data, ctripListing]);

  useEffect(() => {
    if (data && data.foreignPropertyId) {
      setSelectedCtripId(data.foreignPropertyId);
    }
  }, [data]);

  useEffect(() => {
    if (selectedCtripId) {
      getCtripListing(selectedCtripId, false)
        .then(ctripListing => {
          setCtripListing(ctripListing);
          if (ctripListing && ctripListing.rooms) {
            const roomOptions = ctripListing.rooms.map(room => ({
              value: room.ctripRoomName,
              key: room.ctripRoomId
            }));
            setCtripRoomOptions(roomOptions);
            setCurrency(guard(() => ctripListing.rooms[0].rates[0].currencyCode), 'RM');
          }
        })
        .catch(ex => {
          console.log(ex);
        });
    }
  }, [selectedCtripId]);

  useEffect(() => {
    initializeFetch().then(({ ctripListingIds, syncStatusConstants, rateModifierTypeConstants, rateModifierSignConstants }) => {
      setListingIdsOption(ctripListingIds);
      setSyncStatusConstants(syncStatusConstants);
      setRateModifierTypeConstants(rateModifierTypeConstants);
      setRateModifierSignConstants(rateModifierSignConstants);
    });
  }, []);

  const handleOnSelectHotel = selectedListingId => {
    setSelectedCtripId(selectedListingId);
  };

  const handleOnAdvanceOptionsClick = isVisible => e => {
    e.preventDefault();
    setIsAdvancedOptionsModalVisible(isVisible);
  };

  const handleOnToggleRoomSync = roomTypeId => isToggleOn => {
    if (!isToggleOn) {
      const clearedRoomsMapping = roomsMapping.map(roomMapping => {
        if (roomMapping.roomType === roomTypeId) {
          roomMapping.toggle = MAPPING_TOGGLE.OFF;
          delete roomMapping.ctripRoomId;
          delete roomMapping.rates;
        }
        return roomMapping;
      });
      setRoomsMapping(clearedRoomsMapping);
    } else {
      const mappedRoomsMapping = roomsMapping.map(roomMapping => {
        if (roomMapping.roomType === roomTypeId) {
          roomMapping.toggle = MAPPING_TOGGLE.ON;
        }
        return roomMapping;
      });

      setRoomsMapping(mappedRoomsMapping);
    }
  };

  const handleOnSelectRoom = roomTypeId => async ctripRoomId => {
    const ctripRoom = ctripListing.rooms.find(room => room.ctripRoomId === ctripRoomId);

    if (!ctripRoomId) {
      const unMappedRoomsMapping = roomsMapping.map(roomMapping => {
        if (roomMapping.roomType === roomTypeId) {
          delete roomMapping.ctripRoomId;
          delete roomMapping.rates;
        }
        return roomMapping;
      });
      setRoomsMapping(unMappedRoomsMapping);
    } else {
      const mappedRoomsMapping = await Promise.all(
        roomsMapping.map(async roomMapping => {
          if (roomMapping.roomType === roomTypeId) {
            return {
              ...roomMapping,
              ctripRoomId,
              rates: await generateRatesMapping(ctripRoom, roomMapping, roomTypeId)
            };
          } else {
            return roomMapping;
          }
        })
      );

      setRoomsMapping(mappedRoomsMapping);
    }
  };

  const generateRatesMapping = async (ctripRoom, roomMapping = null, roomTypeId) => {
    return Promise.all(
      ctripRoom.rates.map(async ctripRate => {
        const mappedRate = roomMapping && roomMapping.rates && roomMapping.rates.find(rate => rate.ctripRate === ctripRate.ctripRateId);
        const hasMappedRate = mappedRate && Object.entries(mappedRate).length !== 0;

        return {
          ctripRateId: ctripRate.ctripRateId,
          ctripRateName: ctripRate.ctripRateName,
          rate: hasMappedRate ? mappedRate.rate : await fetchStandardRate(roomTypeId)
        };
      })
    );
  };

  const generateAvailRoomOptions = () => {
    const mappedRoomIds = roomsMapping.map(roomMapping => roomMapping.ctripRoomId);

    return ctripRoomOptions.map(option => {
      if (mappedRoomIds.includes(option.key)) {
        option.disabled = true;
      } else {
        delete option.disabled;
      }
      return option;
    });
  };

  const fetchStandardRate = async roomTypeId => {
    return getWebRateByRoomType(roomTypeId).then(webRates => {
      const stdRate = webRates.data.find(rate => rate.code === STANDARD_RATE_CODE);

      if (stdRate) {
        return {
          weekday: stdRate.weekday,
          weekend: stdRate.weekend,
          isDerived: false
        };
      }
    });
  };

  const updateRates = (roomTypeId, ctripRateId) => newRates => {
    const mappedRoomsMapping = roomsMapping.map(roomMapping => {
      if (roomMapping.roomType === roomTypeId) {
        roomMapping.rates.map(currentRate => {
          if (currentRate.ctripRateId === ctripRateId) {
            currentRate.rate = {
              ...currentRate.rate,
              ...newRates
            };
          }
          return currentRate;
        });
      }
      return roomMapping;
    });

    setRoomsMapping(mappedRoomsMapping);
  };

  const handleOnAdvancedOptionsConfirm = payload => {
    const processedRoomMapping = roomsMapping.map(room => {
      const rateDistribution = payload.rateDistributionPayload.find(rateDistribution => room.roomType && room.roomType === rateDistribution.roomId);

      if (!!rateDistribution) {
        const processedRates = room.rates.map(roomRateObject => {
          const rateObject = rateDistribution.rates.find(rateObject => String(rateObject.otaId) === String(roomRateObject.ctripRateId));

          if (!!rateObject) {
            const isDerived = rateObject.rate.isDerived;
            let processedRoomRateObject = {
              ctripRateId: String(roomRateObject.ctripRateId),
              rate: {
                weekday: rateObject.rate.weekdayRate,
                weekend: rateObject.rate.weekendRate,
                isDerived
              }
            };

            if (isDerived) {
              const isPercentage = !!rateModifierTypeConstants.find(type => type.code === rateObject.rate.modifierType && type.isPercentage);
              const isPositive = !!rateModifierSignConstants.find(sign => sign.code === rateObject.rate.modifierSign && sign.isPositive);
              let amount = rateObject.rate.modifierAmount;
              amount = isPercentage ? generatePercentageFromDisplay(amount) : amount;

              processedRoomRateObject.rate.calculation = {
                type: rateObject.rate.modifierType,
                isPositive,
                amount
              };
            }

            return processedRoomRateObject;
          } else {
            return { ...roomRateObject };
          }
        });

        return {
          ...room,
          rates: processedRates
        };
      }

      return { ...room };
    });

    setRoomsMapping(processedRoomMapping);
    setIsAdvancedOptionsModalVisible(false);
  };

  const handleOnSave = async e => {
    Modal.confirm({
      title: 'Are you sure you want to save this integration setting?',
      content: '',
      okText: 'Continue',
      okType: 'warning',
      cancelText: 'Back',
      onOk() {
        return onSubmit();
      },
      onCancel() {}
    });
  };

  const onSubmit = async () => {
    const isSynced = ctripListing.status === syncStatusConstants.SYNCED.code;
    const syncFunction = isSynced ? syncUpdateCtrip : syncNewCtrip;

    return syncFunction({
      property: property._id,
      ctripListingId: selectedCtripId,
      roomMapping: roomsMapping
        .filter(roomMapping => roomMapping.ctripRoomId)
        .map(roomMapping => {
          let activeRate = [];
          roomMapping.rates.forEach(rate => {
            if (rate.rate) {
              activeRate.push({
                ...rate
              });
            }
          });
          delete roomMapping.toggle;
          roomMapping.rates = activeRate;
          return roomMapping;
        })
    })
      .then(res => {
        notification.success({
          message: 'Successfully synced ctrip'
        });
        onAfterSave();
        onClose();
      })
      .catch(ex => {
        notification.error({
          message: 'Error during syncing ctrip '
        });
      });
  };

  const selectedCtripListing = ctripListing && Object.entries(ctripListing).length !== 0;

  return (
    <Row className="scroll-bar-style" style={{ marginTop: 10 }}>
      <Row className="ota-row full-length-box">
        <Alert
          type="warning"
          message="Warning"
          description="Please make sure CtripCM booking is created in HostPlatform BEFORE sync to prevent double booking as CtripCM DOES NOT provide an API to pull all your booking(s)."
          showIcon
        />
      </Row>
      <Row className="ota-row full-length-box">
        <Card title={'Hotel Details'}>
          <label className="ota-label">Ctrip Hotel ID </label>
          <AutoSearch disabled={!!data} defaultValue={data && data.foreignPropertyId} dataSource={listingIdsOption} onSelect={handleOnSelectHotel} />
          {selectedCtripListing ? (
            <div style={{ marginTop: 20 }}>
              <p>
                <b>Found Hotel:</b> ({ctripListing.ctripListingId}) {ctripListing.ctripPropertyName}
              </p>
            </div>
          ) : (
            <div style={{ marginTop: 20 }}>
              <p>Please input a valid Ctrip Hotel ID</p>
            </div>
          )}
        </Card>
      </Row>
      {(selectedCtripListing &&
        property &&
        property.roomTypes &&
        property.roomTypes.map((roomType, i) => {
          const currentRoomMap = roomsMapping.find(roomMapping => roomMapping.roomType === roomType._id);
          const isToggleChecked = currentRoomMap && currentRoomMap.toggle === MAPPING_TOGGLE.ON;

          return (
            <Row key={i} style={{ marginTop: 20 }}>
              <Card title={roomType.name} className="full-length-box" headStyle={{ backgroundColor: '#cbf2f4' }}>
                <label className="booking-label">{`Sync ${roomType.name} to Ctrip`}</label>
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={handleOnToggleRoomSync(roomType._id)}
                  checked={isToggleChecked}
                  style={{ marginLeft: '5px' }}
                />
                {isToggleChecked && (
                  <>
                    <Row>
                      <FormSelection
                        key={roomType._id}
                        name={roomType._id}
                        form={form}
                        isHideLabel={true}
                        placeholder={`Select ctrip room`}
                        defaultValue={currentRoomMap.ctripRoomId}
                        selections={generateAvailRoomOptions()}
                        onChange={handleOnSelectRoom(roomType._id)}
                      />
                    </Row>
                    {currentRoomMap.rates &&
                      currentRoomMap.rates.map((rate, j) => {
                        return (
                          <Row key={`rate${i}-${j}`} style={{ marginTop: 20 }}>
                            <Card title={`${rate.ctripRateName} | ${rate.ctripRateId}`}>
                              <OTARateInput rate={rate.rate} updateRates={updateRates(roomType._id, rate.ctripRateId)} currency={currency} />
                            </Card>
                          </Row>
                        );
                      })}
                  </>
                )}
              </Card>
            </Row>
          );
        })) ||
        (data && <Skeleton active />)}
      {selectedCtripListing && (
        <Row style={{ marginTop: 20 }}>
          <a href=" " onClick={handleOnAdvanceOptionsClick(true)}>
            Advanced Options
          </a>
        </Row>
      )}
      <Row gutter={12} type="flex" justify="end" className="ota-action-button-row" style={{ marginTop: 20 }}>
        <Col>
          <Button type="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            disabled={!roomsMapping.length > 0 || !roomsMapping.find(roomMapping => roomMapping.ctripRoomId)}
            onClick={handleOnSave}
          >
            Save
          </Button>
        </Col>
      </Row>
      {isAdvancedOptionsModalVisible && (
        <CtripCMAdvancedOptionsModal
          roomMapping={roomsMapping.filter(roomMapping => roomMapping.ctripRoomId)}
          property={property}
          rateModifierTypeConstants={rateModifierTypeConstants}
          rateModifierSignConstants={rateModifierSignConstants}
          isVisible={isAdvancedOptionsModalVisible}
          onConfirm={handleOnAdvancedOptionsConfirm}
          onClose={handleOnAdvanceOptionsClick(false)}
          currency={currency}
        />
      )}
    </Row>
  );
};

export default Form.create()(CtripCMForm);
