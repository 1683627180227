import { httpClient } from './../httpClient';
import { apiErrorHandler } from './helpers';

export const getWebRateByRoomType = roomTypeId => {
  let query = '?roomType=' + roomTypeId;
  return httpClient
    .get('/rate/getWebRateByRoomType' + query)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getSTDRateByRoomType = (roomTypeId, { fields } = {}) => {
  return httpClient
    .get(`/rate/${roomTypeId}/stdRate`, { params: { _fields: fields } })
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const getRates = roomTypeId => {
  let query = '?roomType=' + roomTypeId;
  return httpClient
    .get('/rate' + query)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const postRate = payload =>
  httpClient
    .post('/rate', payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const putUpdateRate = (id, payload) =>
  httpClient
    .put(`/rate/${id}`, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);
