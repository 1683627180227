import React from 'react';
import PropTypes from 'prop-types';

import Listing from './components/Listing/Listing';
import Rental from './components/Rental/Rental';

const Body = ({ LISTING_TYPES_OBJECT, selectedListingType, isAllowEditRental }) => {
  return (
    <>
      {selectedListingType === LISTING_TYPES_OBJECT.LISTING.value && <Listing />}
      {selectedListingType === LISTING_TYPES_OBJECT.RENTAL.value && <Rental isAllowEditRental={isAllowEditRental} />}
    </>
  );
};

Body.propTypes = {
  LISTING_TYPES_OBJECT: PropTypes.object.isRequired,
  selectedListingType: PropTypes.string.isRequired,
  isAllowEditRental: PropTypes.bool.isRequired
};

export default Body;
