import qs from 'query-string';

import { REACT_APP_BOOKING_ENGINE_URL, REACT_APP_HOTEL_BOOKING_ENGINE_URL } from 'config/env';

import { guard } from 'utils/general';

/* ============================================ Admin Panel ============================================ */

export const buildAdminPanelUri = () => '/adminPanel';

export const buildAPBillingUri = (hasBaseUri = true) => `${hasBaseUri ? `${buildAdminPanelUri()}/billing` : ''}`;
export const buildAPBillingInventoryUri = (query, hasBaseUri = true) => {
  const baseUrl = `${buildAPBillingUri(hasBaseUri)}/inventory`;
  const stringQuery = qs.stringify(query);
  return query ? `${baseUrl}?${stringQuery}` : baseUrl;
};
export const buildAPResyncOTAUri = (query, hasBaseUri = true) => {
  const baseUrl = `${buildAPBillingUri(hasBaseUri)}/resync-ota`;
  const stringQuery = qs.stringify(query);
  return query ? `${baseUrl}?${stringQuery}` : baseUrl;
};
export const buildAPUploadExpediaTrxUri = (query, hasBaseUri = true) => {
  const baseUrl = `${buildAPBillingUri(hasBaseUri)}/upload-expedia-trx`;
  const stringQuery = qs.stringify(query);
  return query ? `${baseUrl}?${stringQuery}` : baseUrl;
};

//the query is used to not overlap query when clicked many times lol
export const buildDeletedRemovalUri = (query, hasBaseUri = true) => {
  const baseUrl = `${buildAdminPanelUri()}/deletedRemoval`;
  //console.log('This is from Me ' + baseUrl); // /adminPanel/billing/upload-expedia-trx
  const stringQuery = qs.stringify(query);
  return query ? `${baseUrl}?${stringQuery}` : baseUrl;
};

/* ============================================ Host Platform ============================================ */
export const buildBillingUri = (hasBaseUri = true) => `${hasBaseUri ? '/billing' : ''}`;
export const buildBillingInvoiceUri = (hasBaseUri = true) => `${buildBillingUri(hasBaseUri)}/invoice`;
export const buildBillingInventoryUri = (hasBaseUri = true) => `${buildBillingUri(hasBaseUri)}/inventory`;

export const buildOverviewUri = () => '/overview';

export const buildListingUri = () => '/listing';
export const buildListingDetailsUri = (id = ':id') => `${buildListingUri()}/${id}`;

export const buildWebhookUri = () => '/webhook';

export const buildListingWizardUri = () => '/listing-wizard';
export const buildLWCreatePropertyUri = (search = '') => `${buildListingWizardUri()}/property${search}`;
export const buildLWCreatePropertyBasicUri = (search = '') => `${buildLWCreatePropertyUri()}/basic${search}`;
export const buildLWCreatePropertyLocationUri = (search = '') => `${buildLWCreatePropertyUri()}/location${search}`;
export const buildLWCreatePropertyAccommodationUri = (search = '') => `${buildLWCreatePropertyUri()}/accommodation${search}`;
export const buildLWCreatePropertyAmenitiesUri = (search = '') => `${buildLWCreatePropertyUri()}/amenities${search}`;
export const buildLWCreateRoomTypeUri = (search = '') => `${buildListingWizardUri()}/room-type${search}`;
export const buildLWCreateRoomTypeBasicUri = (search = '') => `${buildLWCreateRoomTypeUri()}/basic${search}`;
export const buildLWCreateRoomTypePlaceSetupUri = (search = '') => `${buildLWCreateRoomTypeUri()}/setup${search}`;
export const buildLWCreateRoomTypeRateUri = (search = '') => `${buildLWCreateRoomTypeUri()}/rate${search}`;
export const buildLWCreateRoomTypeAmenitiesUri = (search = '') => `${buildLWCreateRoomTypeUri()}/amenities${search}`;
export const buildLWCreateUnitUri = (search = '') => `${buildListingWizardUri()}/unit${search}`;
export const buildLWCreateUnitBasicUri = (search = '') => `${buildLWCreateUnitUri()}/basic${search}`;
export const buildLWCreateUnitDescriptionUri = (search = '') => `${buildLWCreateUnitUri()}/description${search}`;
export const buildLWCreateUnitCostUri = (search = '') => `${buildLWCreateUnitUri()}/cost${search}`;
export const buildLWCreateUnitPhotosUri = (search = '') => `${buildLWCreateUnitUri()}/photos${search}`;
export const buildLWCreateUnitContractUri = (search = '') => `${buildLWCreateUnitUri()}/contract${search}`;
export const buildLWCreateUnitFinishUri = (search = '') => `${buildLWCreateUnitUri()}/finish${search}`;

export const buildMultiCalendarUri = ({ host, property, roomType, date } = {}) => {
  const baseUrl = '/multicalendar';
  const query = qs.stringify({ host, property, roomType, date });
  return query ? `${baseUrl}?${query}` : baseUrl;
};

export const buildReservationUri = ({ res } = {}) => {
  const baseUrl = '/reservation';
  const query = qs.stringify({ res });
  return query ? `${baseUrl}/${res}` : baseUrl;
};

export const buildPayoutUri = ({ host, owner, date } = {}) => {
  const baseUrl = '/payout';
  const query = qs.stringify({ host, owner, date });
  return query ? `${baseUrl}?${query}` : baseUrl;
};

export const buildExpensesUri = ({ host, date } = {}) => {
  const baseUrl = '/expenses';
  const query = qs.stringify({ host, date });
  return query ? `${baseUrl}?${query}` : baseUrl;
};

export const buildExpensesUpdateUri = ({ host, date, svc } = {}) => {
  const baseUrl = '/expenses';
  const query = qs.stringify({ host, date, svc });
  return query ? `${baseUrl}?${query}` : baseUrl;
};

export const buildReportingUri = ({ property, date } = {}) => {
  const baseUrl = '/reporting';
  const query = qs.stringify({ property, date });
  return query ? `${baseUrl}?${query}` : baseUrl;
};

export const buildServicePackageUri = () => '/servicepackage';
export const buildServicePackageEditUri = (id = ':id') => `${buildServicePackageUri()}/${id}/edit`;

const buildUserUri = () => '/users';
export const buildUserCreateUri = () => `${buildUserUri()}/new`;
export const buildUserEditUri = userId => `${buildUserUri()}/${userId}/edit`;

export const buildTaskManagementUri = () => '/task-management';
export const buildTaskManagementCTCreateUri = () => `${buildTaskManagementUri()}/cleaning-team/new`;
export const buildTaskManagementCTEditUri = teamId => `${buildTaskManagementUri()}/cleaning-team/${teamId}/edit`;
export const buildTaskManagementChecklistCreateUri = () => `${buildTaskManagementUri()}/checklist/new`;
export const buildTaskManagementPresetTaskCreateUri = () => `${buildTaskManagementUri()}/preset-task/new`;
export const buildTaskManagementPresetTaskEditUri = presetTaskId => `${buildTaskManagementUri()}/preset-task/${presetTaskId}/edit`;
export const buildTaskManagementChecklistEditUri = checklistId => `${buildTaskManagementUri()}/checklist/${checklistId}/edit`;
export const buildTaskManagementAutomatedTaskCreateUri = () => `${buildTaskManagementUri()}/automated-task/new`;
export const buildTaskManagementAutomatedTaskEditUri = automatedTaskId => `${buildTaskManagementUri()}/automated-task/${automatedTaskId}/edit`;
export const buildTaskManagementTaskCreateUri = () => `${buildTaskManagementUri()}/task/new`;
export const buildTaskManagementTaskEditUri = taskId => `${buildTaskManagementUri()}/task/${taskId}/edit`;
/* ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ Integration ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ */
// Build
export const buildIntegrationUri = () => '/integrations';
export const buildIOTAFormUri = ({ propertyId, unitId, integrationType } = {}) => {
  const baseUrl = `${buildIntegrationUri()}/otaForm`;

  const queryStringObject = filterQueryStringObject({ type: integrationType, property: propertyId, unit: unitId });
  const query = qs.stringify(queryStringObject);

  return query ? `${baseUrl}?${query}` : baseUrl;
};
// Get
export const getIOTAFormQuery = queryString => {
  const { type: integrationType, property: propertyId, unit: unitId } = qs.parse(queryString);

  return { propertyId, unitId, integrationType };
};

const filterQueryStringObject = (paramsInObject = {}) => {
  return guard(
    () =>
      Object.entries(paramsInObject).reduce((queryStringObject, paramArray) => {
        if (!!paramArray && paramArray.length === 2 && !!paramArray[1]) {
          const objectKey = paramArray[0];
          const objectData = paramArray[1];

          if (!!objectData) {
            queryStringObject[objectKey] = objectData;
          }
        }

        return queryStringObject;
      }, {}),
    {}
  );
};
/* ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ Integration ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ */

export const buildResetExpiredPasswordUri = ({ usernameOrEmail } = {}) => {
  const baseUrl = '/reset-expired-password';
  const query = qs.stringify({ username: usernameOrEmail });
  return query ? `${baseUrl}?${query}` : baseUrl;
};

/* --------------------------------External Sites-------------------------------- */
export const buildHostBookingEngineUrl = hostId => {
  return `${REACT_APP_BOOKING_ENGINE_URL}/${hostId}`;
};

export const buildHostHotelBookingEngineUrl = hostId => {
  return `${REACT_APP_HOTEL_BOOKING_ENGINE_URL}/${hostId}/homepage`;
};
