import React, { Component } from 'react';
import SingleViewLayout from './../../layouts/SingleViewLayout/SingleViewLayout';
import ResetPasswordForm from './../../components/ResetPasswordForm/ResetPasswordForm.js';
import { Card, Col } from 'antd';
import intl from 'react-intl-universal';
import './ResetPassword.css';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';

class ResetPassword extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    this.returnToLogin = this.returnToLogin.bind(this);
  }

  returnToLogin = function() {
    this.props.history.push('/');
  };
  render() {
    return (
      <SingleViewLayout className="centralise">
        <Col xs={22} sm={16} md={14} lg={10} xl={8}>
          <Card title={intl.get('titles.resetPassword').d('Reset Password')}>
            <p>
              {intl
                .get('description.resetPassword')
                .d('Enter your new password here.')}
            </p>
            <ResetPasswordForm
              successCall={this.returnToLogin}
              token={this.props.match.params.token}
            />
          </Card>
        </Col>
      </SingleViewLayout>
    );
  }
}

export default withRouter(ResetPassword);
