import React, { Component } from 'react';
import ExpensesListing from '../../components/ExpensesListing/ExpensesListing';
import { Select, Row, Col, message, Skeleton, Button } from 'antd';
import { withAppContext } from 'context/AppContext';
import { getExpensesListing } from '../../utils/apis/expenses';
import { getHosts } from '../../utils/apis/host';
import './Expenses.css';
import moment from 'moment';
import MonthPicker from '../../components/MonthPicker/MonthPicker';
import CsvModal from './components/CsvModal';
import { buildExpensesUri } from 'utils/routes';
import queryString from 'query-string';
import { MONTH_FORMAT } from 'utils/constants';

const Option = Select.Option;

const constructReadableYearMonthFormat = (year, month) => {
  if (year && month > -1) {
    return month < 9 ? `${year}-0${month + 1}` : `${year}-${month + 1}`;
  } else {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    return month < 9 ? `${year}-0${month + 1}` : `${year}-${month + 1}`;
  }
};

class Expenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hostArr: [],
      host: '',
      expensesList: [],
      servicePackages: [],
      year: moment().format('YYYY'),
      month: new Date().getMonth(),
      isLoading: true,
      csvModalVisible: false,
      isFirstLoad: true
    };
    this.handleChange = this.handleChange.bind(this);
    this.populateListing = this.populateListing.bind(this);
    this.handleOnMonthChange = this.handleOnMonthChange.bind(this);
    this.loadHosts = this.loadHosts.bind(this);
    this.handleModalVisibility = this.handleModalVisibility.bind(this);
  }

  componentDidMount = async () => {
    const { location } = this.props;
    const queryStringDetails = location.search && queryString.parse(location.search);
    const host = await this.firstHostID(queryStringDetails.host);
    const allHost = await this.loadHosts();
    const first = allHost[0].value;
    //got host give host if no give the first record host from hostArr
    console.log('host ' + host);
    if (host === first) {
      this.populateListing();
    }

    let mountYear, mountMonth;
    if (queryStringDetails.date) {
      [mountYear, mountMonth] = queryStringDetails.date.split('-').map(Number);
      mountMonth = mountMonth - 1;
    } else {
      mountMonth = this.state.month;
      mountYear = this.state.year;
    }

    this.setURLQuery({ hostId: host, date: queryStringDetails.date });
    this.setState({
      host: host,
      isFirstLoad: false,
      year: mountYear,
      month: mountMonth
    });
  };

  //call after re render
  componentDidUpdate(prevProps, prevState) {
    const { host, hostArr, year, month, isFirstLoad } = this.state;

    let date = constructReadableYearMonthFormat(year, month);
    const monthWithYearInString = moment(date).format(MONTH_FORMAT);

    if (!isFirstLoad && hostArr.length > 0 && (prevState.host !== host || prevState.month !== month)) {
      this.setURLQuery({ hostId: host, date: monthWithYearInString });
      this.populateListing();
    }
  }

  setURLQuery = ({ hostId, date }) => {
    const { month, year, selectedHostId } = this.state;
    const { history } = this.props;

    if (!hostId) {
      hostId = selectedHostId || '';
    }
    if (!date) {
      date = constructReadableYearMonthFormat(year, month);
    }
    const monthWithYearInString = moment(date).format(MONTH_FORMAT);
    //here rerun but add URI data

    history.replace(buildExpensesUri({ host: hostId, date: monthWithYearInString }));
  };

  populateListing = () => {
    const { year, month, host } = this.state;
    this.setState({
      isLoading: true
    });
    getExpensesListing(year, month, host && host !== 'all' ? host : null)
      .then((res, err) => {
        if (res && res.status === 200) {
          this.setState({
            servicePackages: res.data,
            isLoading: false
          });
        } else {
          message.error('No data found');
          this.setState({ isLoading: false });
        }
      })
      .catch(error => {
        this.setState({
          isLoading: false
        });
      });
  };

  loadHosts = async () => {
    this.setState({
      isLoading: true
    });
    try {
      const hostRes = await getHosts();
      const hostArr = hostRes.data
        .map(host => ({
          value: host._id,
          label: host.name
        }))
        .concat([
          {
            label: 'All',
            value: 'all'
          }
        ]);
      this.setState({
        hostArr,
        host: hostArr[0] ? hostArr[0].value : ``,
        isLoading: false
      });
      return hostArr;
    } catch (error) {
      console.error('Error loading hosts:', error);
      throw error;
    }
  };

  firstHostID = async selectedHostId => {
    let firstHostId;
    const res = await this.loadHosts();
    if (selectedHostId) {
      const foundHost = res.find(res => String(res.value) === String(selectedHostId));
      firstHostId = foundHost.value;
    } else {
      firstHostId = res.length > 0 ? res[0].value : '';
    }
    return firstHostId;
  };

  handleOnMonthChange(date) {
    const year = date.format('YYYY');
    const month = parseInt(date.format('M'), 10) - 1; // months start at 0
    this.setState({
      year,
      month,
      isLoading: true
    });
  }

  handleChange(value) {
    this.setState({
      host: value
    });
  }

  handleModalVisibility(value) {
    this.setState({
      csvModalVisible: value
    });
  }

  render() {
    const { checkIsAdminReadOnly } = this.props;

    const createOptions = function(optionsArray) {
      return (
        <Option key={optionsArray.value} value={optionsArray.value}>
          {optionsArray.label}
        </Option>
      );
    };
    const { servicePackages, hostArr, host, isLoading, year, month, csvModalVisible } = this.state;
    return (
      <React.Fragment>
        <Row type="flex" justify="end" className="el-title" gutter={24}>
          <Col className="col-wrapper">
            <MonthPicker
              todayButtonId="today-date-button2-exp"
              label="Month: "
              onChange={this.handleOnMonthChange}
              defaultValue={moment(constructReadableYearMonthFormat(year, month))}
              disabled={isLoading}
            />
          </Col>
          <Col className="col-wrapper">
            <label className="el-pageTitle">Host: </label>
            <Select
              showSearch
              placeholder="Select host"
              optionFilterProp="children"
              onChange={this.handleChange}
              size="default"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              className="el-hostSelect"
              value={host}
              disabled={isLoading}
            >
              {hostArr.map(createOptions)}
            </Select>
          </Col>
          <Col className="col-wrapper">
            <Button
              id="csv-button-task"
              type="primary"
              icon="download"
              onClick={() => this.handleModalVisibility(true)}
              disabled={this.props.checkIsAdminReadOnly() || isLoading}
            >
              Download CSV
            </Button>
          </Col>
        </Row>
        <CsvModal
          visible={csvModalVisible}
          closeModal={() => this.handleModalVisibility(false)}
          servicePackages={servicePackages}
          year={year}
          month={month}
          host={host}
        />
        {isLoading ? (
          <Skeleton active />
        ) : (
          <Row type="flex" className="el-cardAlign" gutter={{ md: 16, lg: 16, xl: 48 }}>
            {servicePackages.length > 0 ? (
              servicePackages.map((svcPacList, idx) => {
                return (
                  <Col span={24} md={20} lg={7} push={1} key={`expenseslisting${idx}`}>
                    <ExpensesListing
                      data={svcPacList}
                      year={year}
                      month={month}
                      onAfterUpdate={this.populateListing}
                      checkIsAdminReadOnly={checkIsAdminReadOnly}
                    />
                  </Col>
                );
              })
            ) : (
              <Col span={24} md={20} lg={7} push={1} key={`No Data`}>
                No Service Packages For Host
              </Col>
            )}
          </Row>
        )}
      </React.Fragment>
    );
  }
}

export default withAppContext(Expenses);
