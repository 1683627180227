import { Modal } from 'antd';
const FIELDS_SEPERATOR = ',';

export const apiErrorHandler = err => {
  if (err.response && err.response.data && err.response.data.message) {
    throw new Error(err.response.data.message);
  } else if (err.message) {
    const forgetPasswordRegex = /\/reset-password\/[a-zA-Z0-9][a-zA-Z0-9]+/;
    const isForgetPassword = forgetPasswordRegex.test(window.location.pathname);

    if (
      String(err) === 'Error: Request failed with status code 401' &&
      (window.location.pathname !== '/login' &&
        window.location.pathname !== '/forgot-password' &&
        window.location.pathname !== '/' &&
        !isForgetPassword)
    ) {
      Modal.error({
        title: 'Session Expired',
        content: 'Your session has expired. Please login again.',
        okText: 'Login',
        onOk: () => {
          localStorage.removeItem('auth');
          window.location.assign('/');
          Modal.destroyAll();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        },
        onCancel: () => {
          localStorage.removeItem('auth');
          window.location.assign('/');
          Modal.destroyAll();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      });
    } else {
      throw err;
    }
  } else {
    throw new Error('Unexpected error occured when calling api. Please contact admin.');
  }
};

export const constructFieldsToString = fieldsInArray => {
  const fieldsInString = fieldsInArray.join(FIELDS_SEPERATOR);
  return { _fields: fieldsInString };
};
