import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Card } from 'antd';
import { REACT_APP_IS_SHOW_ADDON } from 'config/env';
import { BareRadioButton } from 'components/FormRadioButton/FormRadioButton';

import './Reservation.css';

import { withAppContext } from 'context/AppContext';
import Standard from './Standard/Standard';
import AddOn from './AddOn/AddOn';

const RESERVATION_TYPES = {
  STANDARD: { value: 'reservation', label: 'Reservations', id: 'tab1-standard' },
  ADDON: { value: 'addon', label: 'Activities', id: 'tab2-addon' }
};

const useSelectReservationTypes = (checkIsAdmin, isAllowedHotelBookingEngine) => {
  const isShowAddOn = useMemo(() => REACT_APP_IS_SHOW_ADDON && (checkIsAdmin() || isAllowedHotelBookingEngine === true), [
    checkIsAdmin,
    isAllowedHotelBookingEngine
  ]);

  const [selectedReservationType, setSelectedReservationType] = useState('');

  const constructPermittedReservationTypes = useCallback(() => {
    const reservationTypes = [RESERVATION_TYPES.STANDARD, isShowAddOn && RESERVATION_TYPES.ADDON];

    return reservationTypes.filter(data => !!data);
  }, [isShowAddOn]);

  const permittedReservationTypes = useMemo(() => constructPermittedReservationTypes(), [constructPermittedReservationTypes]);

  useEffect(() => {
    setSelectedReservationType(permittedReservationTypes[0].value);
  }, [permittedReservationTypes]);

  return {
    permittedReservationTypes,
    selectedReservationType,
    setSelectedReservationType,
    isShowAddOn
  };
};

const Reservation = ({ checkIsAdmin, isAllowedHotelBookingEngine }) => {
  const { permittedReservationTypes, selectedReservationType, setSelectedReservationType, isShowAddOn } = useSelectReservationTypes(
    checkIsAdmin,
    isAllowedHotelBookingEngine
  );

  return (
    <>
      {isShowAddOn && (
        <div className="header">
          <BareRadioButton options={permittedReservationTypes} onChange={e => setSelectedReservationType(e.target.value)} />
        </div>
      )}
      <div>
        <Card className="list-card">
          {selectedReservationType === RESERVATION_TYPES.STANDARD.value && <Standard />}
          {selectedReservationType === RESERVATION_TYPES.ADDON.value && <AddOn />}
        </Card>
      </div>
    </>
  );
};

export default withAppContext(Reservation);
