import React, { Component } from 'react';
import {
  Row,
  Col,
  PageHeader,
  Table,
  Drawer,
  Button,
  Modal,
  Form,
  Input,
  Divider,
  Select,
  message,
  Alert,
  Collapse,
  InputNumber,
  Popconfirm,
  Space,
  Tag
} from 'antd';
import {
  SearchOutlined,
  HomeOutlined,
  RedoOutlined,
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { withAppContext } from 'context/AppContext';
import moment from 'moment';
import { StyledCard, StyledHeader } from './Access.styles';
import Highlighter from 'react-highlight-words';
import { getAccessList, editToken, deleteToken, createToken } from '../../../utils/apis/torusAccess';

class Access extends Component {
  state = {
    loading: false,
    data: [
      {
        _id: '12',
        timestamp: 1634738440,
        name: 'Lim Ding Dong',
        token:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
        status: 'Active'
      },
      {
        _id: '17',
        timestamp: 1635738440,
        name: 'Jackson Wang',
        token:
          'iOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5ceyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ',
        status: 'Active'
      },
      {
        _id: '25',
        timestamp: 1636738440,
        name: 'Oh Chin Chin',
        token:
          'kpvaG4gRG9lIiwiaWF0IjoxNTiOiIxMjM0NTY3ODkwIiwibmFtZSI6IE2MjM5MDIyfQ.SflKxwRJSMkpvaG4gRG9lIiwiaWF0IjoxNTeKKF2QT4fwpMeJf36POk6yJV_adQssw5ceyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ',
        status: 'Deactivated'
      }
    ],
    // Table Search
    searchText: '',

    // TokenModal
    visible: false,
    modal_loading: false
  };

  formRef = React.createRef();

  componentDidMount() {
    this.mounted = true;
    this.getAccessList();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getAccessList = async () => {
    this.setState({
      loading: true
    });

    const res = await getAccessList();
    console.log(res);
    if (res && res.status === 200) {
      this.setState({
        loading: false,
        data: res.data
      });
    } else {
      this.setState({
        loading: false
      });
      console.log(res);
      console.log('Error get access list');
    }
  };

  deleteAccessToken = async token => {
    this.setState({
      loading: true
    });
    const res = await deleteToken({ token });
    console.log(res);
    if (res && res.status === 200) {
      message.success('Delete successful');
      this.getAccessList();
    } else {
      console.log('Error delete token');
    }
  };

  ToggleAccessTokenStatus = async (token, status) => {
    this.setState({
      loading: true
    });
    const res = await editToken({ token, status });
    console.log(res);
    if (res && res.status === 200) {
      message.success('Update successful');
      this.getAccessList();
    } else {
      console.log('Error delete token');
    }
  };

  handleCreateToken = () => {
    this.props.form.validateFields(async (err, value) => {
      if (err) {
        return;
      }
      this.setState({
        modal_loading: true
      });
      const res = await createToken(value.name);
      console.log(res);
      if (res && res.status === 200) {
        this.props.form.resetFields();
        this.setState(
          {
            modal_loading: false,
            visible: false
          },
          () => {
            Modal.info({
              title: 'Create token successful',
              content: (
                <Row>
                  <Col>Token :</Col>
                  <Col>{res.token}</Col>
                </Row>
              ),
              onOk: () => this.getAccessList(),
              onCancel: () => this.getAccessList()
            });
          }
        );
      }
    });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          marginRight: 8,
          fontSize: 18,
          color: filtered ? '#ffc069' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => {
      if (text) {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        );
      }
    }
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const { checkIsAdminReadOnly } = this.props;

    const CreateTokenModal = (
      <Modal
        visible={this.state.visible}
        title="Create Token"
        onCancel={() => {
          this.setState({
            visible: false
          });
        }}
        onOk={this.handleCreateToken}
        okButtonProps={{
          loading: this.state.modal_loading
        }}
        cancelButtonProps={{ disabled: this.state.modal_loading }}
      >
        <Form>
          <Form.Item label="Token Name" hasFeedback>
            {this.props.form.getFieldDecorator('name', {
              rules: [{ required: true, message: 'Token name is required' }]
            })(<Input autoFocus placeholder="Name" disabled={this.state.modal_loading} />)}
          </Form.Item>
        </Form>
      </Modal>
    );

    const columns = checkIsAdminReadOnly => [
      {
        title: 'Creation Date',
        dataIndex: 'timestamp',
        key: 'timestamp',
        width: '15%',
        sorter: (a, b) => a.timestamp - b.timestamp,
        sortDirections: ['descend', 'ascend'],
        render: value => {
          if (value) {
            return moment.unix(value).format('DD-MMM-YYYY hh:mm A');
          }
        }
      },
      {
        title: 'Key Name',
        dataIndex: 'name',
        key: 'name',
        ...this.getColumnSearchProps('name')
      },
      {
        title: 'Token',
        dataIndex: 'token',
        key: 'token',
        width: 500
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: value => (value ? <Tag color="green">Active</Tag> : <Tag color="red">In-active</Tag>)
      },
      {
        title: 'Last Active',
        dataIndex: 'last_active',
        key: 'last_active',
        render: value => moment.unix(value).format('DD-MMM-YYYY hh:mm A')
      },
      {
        title: 'Actions',
        align: 'center',
        width: 200,
        render: (value, record, index) => {
          return (
            <Row type="flex" justify="center">
              <Col>
                <a
                  onClick={() => {
                    if (!checkIsAdminReadOnly()) {
                      if (record.status) {
                        //
                        this.ToggleAccessTokenStatus(record.token, !record.status);
                      } else {
                        // Enable
                        this.ToggleAccessTokenStatus(record.token, !record.status);
                      }
                    }
                  }}
                  style={{
                    cursor: checkIsAdminReadOnly() ? 'not-allowed' : 'pointer'
                  }}
                >
                  {record.status ? (
                    <span>
                      <EditOutlined /> Disable
                    </span>
                  ) : (
                    <span>
                      <EditOutlined /> Enable
                    </span>
                  )}
                </a>
              </Col>

              <Col>
                <Divider type="vertical" />
              </Col>

              <Col>
                {checkIsAdminReadOnly ? (
                  <a
                    style={{
                      color: 'red',
                      cursor: 'not-allowed'
                    }}
                  >
                    Delete <DeleteOutlined />
                  </a>
                ) : (
                  <Popconfirm
                    title="Are you sure to delete this?"
                    onConfirm={() => {
                      this.deleteAccessToken(record.token);
                    }}
                    // onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <a
                      style={{
                        color: 'red'
                      }}
                    >
                      Delete <DeleteOutlined />
                    </a>
                  </Popconfirm>
                )}
              </Col>
            </Row>
          );
        }
      }
    ];

    return (
      <StyledCard>
        <Row type="flex" justify="space-between" align="middle" style={{ marginBottom: 16 }}>
          <Col style={{ fontWeight: 600 }}>Torus External Access Token</Col>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                this.setState({
                  visible: true
                });
              }}
              disabled={checkIsAdminReadOnly()}
            >
              Create Token
            </Button>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Table
              size="small"
              bordered
              columns={columns(checkIsAdminReadOnly)}
              dataSource={this.state.data}
              loading={this.state.loading}
              rowKey={'_id'}
              scroll={{
                scrollToFirstRowOnChange: true,
                x: true
              }}
            />
          </Col>
        </Row>

        {CreateTokenModal}
      </StyledCard>
    );
  }
}

export default withAppContext(withRouter(Form.create()(Access)));
