import { httpClient } from '../httpClient';

export const validateBulk = data => {
  return httpClient
    .post('/expedia/validateExpediaCSV', data)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
};

export const uploadBulk = data => {
  return httpClient
    .post('/expedia/updateExpediaNettPrice', data)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
};
