import { httpClient } from './../httpClient';
import { apiErrorHandler } from './helpers';

export const postCreateTrxn = payload =>
  httpClient
    .post('/transaction', payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const putUpdateTrxn = payload =>
  httpClient
    .put(`/transaction/${payload.id}?type=${payload.type}`, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const deleteTrxn = payload =>
  httpClient
    .delete(`/transaction/${payload.id}?type=${payload.type}`)
    .then(response => response.data)
    .catch(apiErrorHandler);
