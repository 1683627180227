import React, { Component } from 'react';
import { Icon } from 'antd';

class CloseButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <button aria-label="Close" className="ant-drawer-close" onClick={this.props.onClick}>
        <span className="ant-drawer-close-x">
          <Icon type="close" theme="outlined" />
        </span>
      </button>
    );
  }
}

export default CloseButton;
