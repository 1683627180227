import React from 'react';
import { Skeleton } from 'antd';

import Filter from '../components/Filter/Filter';
import Table from '../components/Table/Table';

import { getIntegrationTypesConstant } from 'utils/general';
const { HOTEL_TYPE } = getIntegrationTypesConstant();

const HotelIntegration = ({
  isLoadingDefaultData,
  isLoadingHotelsIntegration,
  otaSyncStatusConstants,
  propertyOptions,
  hotelIntegrations,
  selectedPropertyId,
  onChangeProperty,
  onClickButtonCreateEdit,
  onClickButtonDelete
}) => {
  return (
    <Skeleton active loading={isLoadingDefaultData}>
      <Filter type={HOTEL_TYPE.code} propertyOptions={propertyOptions} selectedPropertyId={selectedPropertyId} onChangeProperty={onChangeProperty} />
      <Skeleton active loading={isLoadingHotelsIntegration}>
        <Table
          otaSyncStatusConstants={otaSyncStatusConstants}
          integrations={hotelIntegrations}
          type={HOTEL_TYPE.code}
          onClickButtonCreateEdit={onClickButtonCreateEdit}
          onClickButtonDelete={onClickButtonDelete}
        />
      </Skeleton>
    </Skeleton>
  );
};

export default HotelIntegration;
