import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Empty, Modal, notification, Row, Skeleton, Alert } from 'antd';

import { getRateModifierTypeConstant, getRateModifierSignConstant } from 'utils/apis/constants';
import { getTiketcom, getTiketcoms, putSyncTiketcom } from 'utils/apis/integration';
import { getSTDRateByRoomType } from 'utils/apis/rate';
import { checkIsObjectEmpty, errorHandlerWrapper, generatePercentageFromDisplay, guard } from 'utils/general';

import HotelDetails from './HotelDetails/HotelDetails';
import RoomListing from './RoomListing/RoomListing';
import TiketcomAdvancedOptionsModal from './TiketcomAdvancedOptionsModal/TiketcomAdvancedOptionsModal';

import styles from './TiketcomForm.module.css';

const fetchAndConstructTiketcomRoomRates = async (tiketcomRoomRates, roomTypeId) => {
  const isAllRoomHasRate = !tiketcomRoomRates.find(tiketcomRoomRate => !tiketcomRoomRate.rate);
  const stdRate = isAllRoomHasRate ? undefined : await getSTDRateByRoomType(roomTypeId, { fields: ['weekday', 'weekend'] });

  return tiketcomRoomRates.map(tiketcomRoomRate => ({
    ...tiketcomRoomRate,
    rate: tiketcomRoomRate.rate || { weekday: stdRate.weekday, weekend: stdRate.weekend, isDerived: false }
  }));
};

// ===================================================== Others UseEffect functions
const useFetchConstants = () => {
  const [isLoadingConstants, setIsLoadingConstants] = useState(true);
  const [rateModifierTypeConstants, setRateModifierTypeConstants] = useState({});
  const [rateModifierSignConstants, setRateModifierSignConstants] = useState({});

  const fetchConstants = useCallback(async () => {
    setIsLoadingConstants(true);

    const rateModifierTypeConstants = await getRateModifierTypeConstant().then(constant => Object.values(constant));
    const rateModifierSignConstants = await getRateModifierSignConstant().then(constant => Object.values(constant));

    setRateModifierTypeConstants(rateModifierTypeConstants);
    setRateModifierSignConstants(rateModifierSignConstants);
    setIsLoadingConstants(false);
  }, []);

  useEffect(() => {
    fetchConstants();
  }, [fetchConstants]);

  return { isLoadingConstants, rateModifierTypeConstants, rateModifierSignConstants };
};

const useFetchTiketcom = (isLoadingDefaultData, selectedTiketcomId) => {
  const [tiketcom, setTiketcom] = useState({});
  const [isLoadingTiketcom, setIsLoadingTiketcom] = useState(false);

  const fetchTiketcom = useCallback(async selectedTiketcomId => {
    setIsLoadingTiketcom(true);

    const tiketcom = await getTiketcom(selectedTiketcomId, {
      fields: ['rooms', 'tiketcomPropertyId', 'tiketcomPropertyName', 'currency']
    });
    setTiketcom(tiketcom);

    setIsLoadingTiketcom(false);
  }, []);

  useEffect(() => {
    if (!isLoadingDefaultData && !!selectedTiketcomId) {
      fetchTiketcom(selectedTiketcomId);
    }
  }, [isLoadingDefaultData, selectedTiketcomId, fetchTiketcom]);

  return { isLoadingTiketcom, tiketcom };
};

const useFetchTiketcoms = propertyId => {
  const [tiketcomsSelection, setTiketcomsSelection] = useState([]);
  const [isLoadingTiketcomsSelection, setIsLoadingTiketcomsSelection] = useState(true);

  const fetchTiketcoms = useCallback(async () => {
    setIsLoadingTiketcomsSelection(true);

    const tiketcomsSelection = await getTiketcoms({ query: { propertyId }, fields: ['tiketcomPropertyName', 'isSynced'] }).then(tiketcoms =>
      tiketcoms.map(tiketcom => ({ value: tiketcom._id, label: tiketcom.tiketcomPropertyName, isDisabled: tiketcom.isSynced }))
    );

    setTiketcomsSelection(tiketcomsSelection);

    setIsLoadingTiketcomsSelection(false);
  }, [propertyId]);

  useEffect(() => {
    fetchTiketcoms();
  }, [fetchTiketcoms]);

  return { isLoadingTiketcomsSelection, tiketcomsSelection };
};

const useRoomsMapping = (isEdit, shouldProcessRoomTypeDetais, roomTypes, integratedRooms, tiketcomRooms) => {
  const [roomsMapping, setRoomsMapping] = useState([]);

  const constructRoomsMappingDetails = useCallback(() => {
    const roomsMapping = roomTypes.map(roomType => {
      const integratedRoom = isEdit ? integratedRooms.find(integratedRoom => integratedRoom.roomType._id === roomType._id) : undefined;
      const integratedTiketcomRoom = !!integratedRoom
        ? tiketcomRooms.find(tiketRoom => tiketRoom.tiketcomRoomId === integratedRoom.tiketcomRoomId)
        : undefined;

      return {
        _id: roomType._id,
        name: roomType.name,
        isToggled: !!integratedTiketcomRoom,
        formData: {
          ...(!!integratedTiketcomRoom && {
            tiketcomRoomId: integratedTiketcomRoom.tiketcomRoomId,
            tiketcomRates: integratedTiketcomRoom.rates
          })
        }
      };
    });

    setRoomsMapping([...roomsMapping]);
  }, [isEdit, roomTypes, integratedRooms, tiketcomRooms]);

  useEffect(() => {
    if (shouldProcessRoomTypeDetais) {
      constructRoomsMappingDetails();
    }
  }, [shouldProcessRoomTypeDetais, constructRoomsMappingDetails]);

  const rateDistributionRoomsMapping = useMemo(() => roomsMapping.filter(roomMapping => !!roomMapping.formData.tiketcomRoomId), [roomsMapping]);

  return { roomsMapping, rateDistributionRoomsMapping, setRoomsMapping };
};

const useTiketcomRoomSelection = (shouldProcessRoomTypeDetais, tiketcomRooms, roomsMapping) => {
  const tiketcomRoomsSelection = useMemo(
    () =>
      shouldProcessRoomTypeDetais
        ? tiketcomRooms.map(tiketcomRoom => ({
            label: tiketcomRoom.tiketcomRoomName,
            value: tiketcomRoom.tiketcomRoomId,
            isDisabled: !!roomsMapping.find(roomMapping => roomMapping.formData.tiketcomRoomId === tiketcomRoom.tiketcomRoomId)
          }))
        : [],
    [shouldProcessRoomTypeDetais, tiketcomRooms, roomsMapping]
  );

  return { tiketcomRoomsSelection };
};

// ===================================================== Main UseEffect functions
const useFetchDefaultData = (propertyId, selectedHotelIntegration) => {
  const isEdit = useMemo(() => !!selectedHotelIntegration, [selectedHotelIntegration]);
  const integratedTiketcomId = useMemo(() => (isEdit ? selectedHotelIntegration._id : undefined), [isEdit, selectedHotelIntegration]);
  const integratedRooms = useMemo(() => (isEdit ? selectedHotelIntegration.roomTypes : undefined), [isEdit, selectedHotelIntegration]);

  const { isLoadingConstants, rateModifierTypeConstants, rateModifierSignConstants } = useFetchConstants();
  const { isLoadingTiketcomsSelection, tiketcomsSelection } = useFetchTiketcoms(propertyId);

  const isLoadingDefaultData = useMemo(() => isLoadingConstants || isLoadingTiketcomsSelection, [isLoadingConstants, isLoadingTiketcomsSelection]);

  return {
    isEdit,
    isLoadingDefaultData,
    integratedTiketcomId,
    integratedRooms,
    rateModifierTypeConstants,
    rateModifierSignConstants,
    tiketcomsSelection
  };
};

const usePropertyDetails = (isLoadingDefaultData, integratedTiketcomId) => {
  const [selectedTiketcomId, setSelectedTiketcomId] = useState(integratedTiketcomId);

  const { isLoadingTiketcom, tiketcom } = useFetchTiketcom(isLoadingDefaultData, selectedTiketcomId);

  const isLoadingPropertyDetails = useMemo(() => isLoadingTiketcom, [isLoadingTiketcom]);

  return { isLoadingPropertyDetails, selectedTiketcomId, tiketcom, setSelectedTiketcomId };
};

const useRoomTypesDetails = (isLoadingPropertyDetails, isEdit, property, integratedRooms, tiketcomRooms) => {
  const hasRoomTypes = useMemo(() => !!property.roomTypes && property.roomTypes.length > 0, [property]);
  const shouldProcessRoomTypeDetais = useMemo(() => !isLoadingPropertyDetails && hasRoomTypes && !!tiketcomRooms, [
    isLoadingPropertyDetails,
    hasRoomTypes,
    tiketcomRooms
  ]);

  const { roomsMapping, rateDistributionRoomsMapping, setRoomsMapping } = useRoomsMapping(
    isEdit,
    shouldProcessRoomTypeDetais,
    property.roomTypes,
    integratedRooms,
    tiketcomRooms
  );
  const { tiketcomRoomsSelection } = useTiketcomRoomSelection(shouldProcessRoomTypeDetais, tiketcomRooms, roomsMapping);

  const canEdit = useMemo(
    () => guard(() => roomsMapping.length > 0 && roomsMapping.find(roomMapping => roomMapping.formData.tiketcomRoomId), false),
    [roomsMapping]
  );

  return { hasRoomTypes, canEdit, tiketcomRoomsSelection, tiketcomRooms, roomsMapping, rateDistributionRoomsMapping, setRoomsMapping };
};

// ===================================================== Components
const Footer = ({ canEdit, onAdvanceOptionsButtonClick, onSubmit, onClose }) => {
  const handleOnSave = e => {
    e.preventDefault();
    Modal.confirm({
      title: 'Are you sure you want to save this integration setting?',
      content: '',
      okText: 'Continue',
      okType: 'warning',
      cancelText: 'Back',
      onOk: onSubmit,
      onCancel: () => {}
    });
  };

  return (
    <>
      {canEdit && (
        <Row>
          <Button type="link" onClick={onAdvanceOptionsButtonClick}>
            Advanced Options
          </Button>
        </Row>
      )}
      <Row gutter={12} type="flex" justify="end">
        <Col>
          <Button type="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="primary" disabled={!canEdit} onClick={handleOnSave}>
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
};

const TiketcomForm = ({ property, selectedHotelIntegration, onAfterSave, onClose }) => {
  const [isAdvancedOptionsModalVisible, setIsAdvancedOptionsModalVisible] = useState(false);
  const [currency, setCurrency] = useState('RM');
  const {
    isEdit,
    isLoadingDefaultData,
    integratedTiketcomId,
    integratedRooms,
    rateModifierTypeConstants,
    rateModifierSignConstants,
    tiketcomsSelection
  } = useFetchDefaultData(property._id, selectedHotelIntegration);
  const { isLoadingPropertyDetails, selectedTiketcomId, tiketcom, setSelectedTiketcomId } = usePropertyDetails(
    isLoadingDefaultData,
    integratedTiketcomId
  );
  const {
    hasRoomTypes,
    canEdit,
    tiketcomRoomsSelection,
    tiketcomRooms,
    roomsMapping,
    rateDistributionRoomsMapping,
    setRoomsMapping
  } = useRoomTypesDetails(isLoadingPropertyDetails, isEdit, property, integratedRooms, tiketcom.rooms);

  useEffect(() => {
    if (tiketcom.currency) {
      setCurrency(tiketcom.currency);
    }
  }, [tiketcom]);

  const handleOnToggleRoomSync = roomTypeId => isToggled => {
    const roomMappingToUpdateIndex = roomsMapping.findIndex(roomMapping => roomMapping._id === roomTypeId);
    roomsMapping[roomMappingToUpdateIndex] = { ...roomsMapping[roomMappingToUpdateIndex], isToggled, formData: {} };

    setRoomsMapping([...roomsMapping]);
  };

  const handleOnSelectTiketcomRoom = roomTypeId => async tiketcomRoomId => {
    const roomMappingToUpdateIndex = roomsMapping.findIndex(roomMapping => roomMapping._id === roomTypeId);
    const roomMappingToUpdate = roomsMapping[roomMappingToUpdateIndex];
    const selectedTiketcomRoom = !!tiketcomRoomId ? tiketcomRooms.find(tiketcomRoom => tiketcomRoom.tiketcomRoomId === tiketcomRoomId) : undefined;

    roomsMapping[roomMappingToUpdateIndex] = {
      ...roomMappingToUpdate,
      isToggled: roomMappingToUpdate.isToggled,
      formData: {
        ...(!!selectedTiketcomRoom && {
          tiketcomRoomId: selectedTiketcomRoom.tiketcomRoomId,
          tiketcomRates: await fetchAndConstructTiketcomRoomRates(selectedTiketcomRoom.rates, roomTypeId)
        })
      }
    };

    setRoomsMapping([...roomsMapping]);
  };

  const handleonChangeRate = (roomTypeId, tiketcomRateId) => newRates => {
    const roomMappingToUpdateIndex = roomsMapping.findIndex(roomMapping => roomMapping._id === roomTypeId);
    const roomMappingToUpdate = roomsMapping[roomMappingToUpdateIndex];
    const roomRateToUpdateIndex = roomMappingToUpdate.formData.tiketcomRates.findIndex(
      tiketcomRate => tiketcomRate.tiketcomRateId === tiketcomRateId
    );
    const roomRateToUpdate = roomMappingToUpdate.formData.tiketcomRates[roomRateToUpdateIndex];

    roomsMapping[roomMappingToUpdateIndex].formData.tiketcomRates[roomRateToUpdateIndex].rate = {
      ...roomRateToUpdate.rate,
      ...newRates
    };

    setRoomsMapping([...roomsMapping]);
  };

  const handleOnAdvanceOptionsButtonClick = () => {
    setIsAdvancedOptionsModalVisible(true);
  };

  const handleOnAdvancedOptionsConfirm = ({ rateDistributionPayload }) => {
    rateDistributionPayload.forEach(rateDistribution => {
      const roomMappingToUpdateIndex = roomsMapping.findIndex(roomMapping => roomMapping._id === rateDistribution.roomId);
      const roomMappingRatesToUpdate = roomsMapping[roomMappingToUpdateIndex].formData.tiketcomRates;

      roomsMapping[roomMappingToUpdateIndex].formData.tiketcomRates = roomMappingRatesToUpdate.map(tiketcomRate => {
        const tiketcomRateUpdateData = rateDistribution.rates.find(
          tiketcomRateUpdateData => String(tiketcomRateUpdateData.otaId) === String(tiketcomRate.tiketcomRateId)
        );
        const isDerived = tiketcomRateUpdateData.rate.isDerived;

        const processedRoomRateObject = {
          ...tiketcomRate,
          rate: {
            weekday: tiketcomRateUpdateData.rate.weekdayRate,
            weekend: tiketcomRateUpdateData.rate.weekendRate,
            isDerived
          }
        };

        if (isDerived) {
          const isPercentage = !!rateModifierTypeConstants.find(type => type.code === tiketcomRateUpdateData.rate.modifierType && type.isPercentage);
          const isPositive = !!rateModifierSignConstants.find(sign => sign.code === tiketcomRateUpdateData.rate.modifierSign && sign.isPositive);
          const displayAmount = tiketcomRateUpdateData.rate.modifierAmount;
          const amount = isPercentage ? generatePercentageFromDisplay(displayAmount) : displayAmount;

          processedRoomRateObject.rate.calculation = {
            type: tiketcomRateUpdateData.rate.modifierType,
            isPositive,
            amount
          };
        }

        return processedRoomRateObject;
      });
    });

    setRoomsMapping([...roomsMapping]);
  };

  const handleOnAdvancedOptionsClose = () => {
    setIsAdvancedOptionsModalVisible(false);
  };

  const handleOnSubmit = async () => {
    const roomsMappingPayload = roomsMapping
      .filter(roomMapping => !checkIsObjectEmpty(roomMapping.formData))
      .map(roomMapping => ({ _id: roomMapping._id, formData: roomMapping.formData }));

    errorHandlerWrapper(
      putSyncTiketcom(selectedTiketcomId, {
        propertyId: property._id,
        roomsMapping: roomsMappingPayload
      }).then(() => {
        notification.success({
          message: 'Successfully synced tiket.com for the selected property and room types'
        });
        onAfterSave();
        onClose();
      })
    );
  };

  return (
    <>
      {isLoadingDefaultData ? (
        <Skeleton active loading />
      ) : (
        <div className="scroll-bar-style">
          <div className={styles.root}>
            <Row>
              <Alert
                type="warning"
                message="Warning"
                description="Please make sure Tiket.com booking is created in HostPlatform BEFORE sync to prevent double booking as Tiket.com DOES NOT provide an API to pull all your booking(s)."
                showIcon
              />
            </Row>
            <Row className={styles.row}>
              <HotelDetails
                isEdit={isEdit}
                tiketcomsSelection={tiketcomsSelection}
                selectedTiketcomId={selectedTiketcomId}
                selectedTiketcom={tiketcom}
                setSelectedTiketcomId={setSelectedTiketcomId}
              />
            </Row>
            <Row className={styles.row}>
              {!hasRoomTypes ? (
                <Empty description="There is no room type for the selected property, you can still proceed to sync this property to Tiket.com" />
              ) : !selectedTiketcomId ? (
                <Empty description="Please select a Tiket.com hotel to begin to sync room types" />
              ) : isLoadingPropertyDetails ? (
                <Skeleton active loading />
              ) : (
                <RoomListing
                  tiketcomRoomsSelection={tiketcomRoomsSelection}
                  roomsMapping={roomsMapping}
                  onToggleRoom={handleOnToggleRoomSync}
                  onSelectTiketcomRoom={handleOnSelectTiketcomRoom}
                  onChangeRate={handleonChangeRate}
                  currency={currency}
                />
              )}
            </Row>
            <Row className={styles.row}>
              <Footer canEdit={canEdit} onAdvanceOptionsButtonClick={handleOnAdvanceOptionsButtonClick} onSubmit={handleOnSubmit} onClose={onClose} />
            </Row>
          </div>
        </div>
      )}
      {isAdvancedOptionsModalVisible && (
        <TiketcomAdvancedOptionsModal
          isVisible={isAdvancedOptionsModalVisible}
          rateModifierTypeConstants={rateModifierTypeConstants}
          rateModifierSignConstants={rateModifierSignConstants}
          roomsMapping={rateDistributionRoomsMapping}
          onConfirm={handleOnAdvancedOptionsConfirm}
          onClose={handleOnAdvancedOptionsClose}
          currency={currency}
        />
      )}
    </>
  );
};

export default TiketcomForm;
