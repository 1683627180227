import React, { Component } from 'react';
import { Form, Icon, Input, Button, Alert } from 'antd';
import intl from 'react-intl-universal';
import { withRouter, Link } from 'react-router-dom';

import { AppContextConsumer } from 'context/AppContext';
import { buildPayoutUri, buildResetExpiredPasswordUri, buildTaskManagementUri } from 'utils/routes';

import './LoginForm.css';

const FormItem = Form.Item;

class LoginForm extends Component {
  constructor() {
    super();
    this.state = {
      isLoggingIn: false,
      wrongCombo: false
    };
  }

  componentWillReceiveProps() {
    this.props.form.isFieldsTouched && this.setState({ wrongCombo: false });
  }

  handleSubmit = contextLoginFn => e => {
    e.preventDefault();
    this.setState({ isLoggingIn: true, wrongCombo: false });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        contextLoginFn(values).then(response => {
          if (response.status === 200) {
            const authObj = JSON.parse(localStorage.getItem('auth'));
            //TODO: Weird login hardcoded redirect shit, let's revisit when we have multiple hosts
            if (authObj && authObj.user && authObj.user.roles.length > 0 && authObj.user.roles[0].permissions.includes(8)) {
              this.props.history.push('/overview');
            } else if (authObj && authObj.user && authObj.user.roles.length > 0 && authObj.user.roles[0].permissions.includes(12)) {
              this.props.history.push(buildPayoutUri());
            } else if (
              authObj &&
              authObj.user &&
              authObj.user.roles.length > 0 &&
              (authObj.user.roles[0].permissions.includes(4) || authObj.user.roles[0].permissions.includes(53))
            ) {
              this.props.history.push('/listing');
            } else if (
              authObj &&
              authObj.user &&
              authObj.user.roles.length > 0 &&
              (authObj.user.roles[0].permissions.includes(60) || authObj.user.roles[0].permissions.includes(62))
            ) {
              this.props.history.push(`${buildTaskManagementUri()}/tasklist`);
            } else if (authObj && authObj.user) {
              this.props.history.push('/overview');
            }
          } else if (response.status === 401) {
            if (response.data && response.data.code === '40001') {
              this.props.history.push(buildResetExpiredPasswordUri({ usernameOrEmail: values.email }));
            } else {
              this.setState({ wrongCombo: true, isLoggingIn: false });
            }
          }
        });
      } else if (err) {
        this.setState({ isLoggingIn: false });
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <AppContextConsumer>
        {({ login }) => (
          <Form onSubmit={this.handleSubmit(login)} className="login-form">
            <FormItem>
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: intl
                      .get('forms.required', {
                        formField: intl.get('forms.placeholders.usernameOrEmail').d('email')
                      })
                      .d('Please enter your Username/Email.')
                  }
                ]
              })(
                <Input
                  type="text"
                  autoComplete="email"
                  prefix={<Icon type="user" />}
                  placeholder={intl.get('forms.placeholders.usernameOrEmail').d('Username/Email')}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: intl
                      .get('forms.required', {
                        formField: intl.get('forms.placeholders.password').d('password')
                      })
                      .d('Please enter your password.')
                  }
                ]
              })(
                <Input.Password
                  prefix={<Icon type="lock" />}
                  type="password"
                  autoComplete="current-password"
                  placeholder={intl.get('forms.placeholders.password').d('Password')}
                />
              )}
            </FormItem>
            {this.state.wrongCombo && (
              <Alert message={intl.get('forms.errors.combo').d('Invalid email/password combination.')} type="error" showIcon />
            )}
            <FormItem>
              <Link className="login-form-forgot" to={'/forgot-password'}>
                {intl.get('forms.links.forgotPassword').d('Forgot Password')}
              </Link>
              <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.isLoggingIn}>
                {intl.get('forms.labels.logIn').d('Log in')}
              </Button>
              {/* {intl.get('forms.links.or').d('Or ')}
              <Link to={'/signup'}>
                {intl.get('forms.links.signup').d('sign up now!')}
              </Link> */}
            </FormItem>
          </Form>
        )}
      </AppContextConsumer>
    );
  }
}

export default withRouter(Form.create()(LoginForm));
