import React, { useState, useEffect } from 'react';
import { withAppContext } from 'context/AppContext';
import { Modal, Row, Col, Card, notification, Table, Button, Form, Message } from 'antd';

import { getColumnFilterSearchProps, getColumnSorterProps } from 'components/Table/Table';
import FormSelection from '../../../components/FormSelection/FormSelection';
import FormInput from '../../../components/FormInput/FormInput';
import FormInputNumber from '../../../components/FormInputNumber/FormInputNumber';

import { getCtripListings, pullNewCtripListing, putCtripListing } from 'utils/apis/integration';
import { getHosts } from '../../../utils/apis/host';
import { guard } from 'utils/general';

const MODAL_CONTENT = {
  NEW_CTRIP: 'newCtrip',
  UPDATE_CTRIP: 'updateCtrip'
};

const MESSAGE_TYPE = {
  SUCCESS_UPDATE: 'successUpdate',
  ERROR_UPDATE: 'errorUpdate',
  SUCCESS_PULL: 'successPull',
  ERROR_PULL: 'errorPull'
};

const generateTableSetting = ctripListingsData =>
  ctripListingsData.map(listing => ({
    _id: listing._id,
    ctripListingId: listing.ctripListingId,
    status: listing.status,
    ctripPropertyName: listing.ctripPropertyName || ' Property ID Only (Temporary)',
    host: listing.host,
    rooms: listing.rooms.length
  }));

const mapHostListsOptions = hostListsData =>
  hostListsData.map(host => ({
    label: host.name,
    value: host._id
  }));

const generateNotificationMessage = (messageType, listingId = null, error = null) => {
  switch (messageType) {
    case MESSAGE_TYPE.SUCCESS_PULL:
      return {
        message: `Succesfully retrieve Hotel (${listingId})`,
        description: <div>Please assign a host to this Ctrip Listing</div>
      };
    case MESSAGE_TYPE.ERROR_PULL:
      return {
        message: `Failed to retrieve Ctrip Listing`,
        description: (
          <div>
            The provided listing ID ({listingId}) is not associated with Host Platform.
            <div>
              If you are sure it should, please contact{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://api.whatsapp.com/send?phone=60162537829&text=Hello,%20I%20have%20a%20question%20about%20http%3A%2F%2Fplatform.hostastay.com%2Fintegrations%2Fctrip-connect"
              >
                customer support
              </a>
              !
            </div>
          </div>
        )
      };
    case MESSAGE_TYPE.SUCCESS_UPDATE:
      return {
        message: `Nice One`,
        description: (
          <div>
            <p>
              <b>Succesfully added Host to Ctrip Listing</b>
            </p>
          </div>
        )
      };
    case MESSAGE_TYPE.ERROR_UPDATE:
      return {
        message: `Oh Crap`,
        description: (
          <div>
            <p>
              <b>Failed to update Ctrip Listing</b>
            </p>
            Try refreshing the page. If problem presist, contact{' '}
            <a target="_blank" rel="noreferrer noopener" href="http://hostastay.com/helpdesk-cs/">
              tech support
            </a>
            !
          </div>
        )
      };
    default:
  }
};

const generateModalTitle = selectedCtripListing => {
  return `Editing ${selectedCtripListing.ctripPropertyName} (${selectedCtripListing.ctripListingId})`;
};

const getTableColumns = (filtersInfo, sorterInfo, handleOnShowEditModal, ctripListings, isAllowEdit, checkIsAdminReadOnly) => {
  const tableColumns = [];

  tableColumns.push({
    title: 'Ctrip Property ID',
    dataIndex: 'ctripListingId',
    key: 'ctripListingId',
    ...getColumnFilterSearchProps('ctripListingId', 'Ctrip Property ID', { filtersInfo }),
    ...getColumnSorterProps('ctripListingId', { sorterInfo })
  });

  tableColumns.push({
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: Array.from(new Set(ctripListings.map(listing => listing.status))).map(item => ({ text: item, value: item })),
    onFilter: (value, record) => record.status === value
  });

  tableColumns.push({
    title: 'Ctrip Property Name',
    dataIndex: 'ctripPropertyName',
    key: 'ctripPropertyName',
    ...getColumnFilterSearchProps('ctripPropertyName', 'Ctrip Property Name', { filtersInfo }),
    ...getColumnSorterProps('ctripPropertyName', { sorterInfo })
  });

  tableColumns.push({
    title: 'Host',
    dataIndex: 'host',
    key: 'host',
    ...getColumnFilterSearchProps('host', 'Host', { filtersInfo }),
    ...getColumnSorterProps('host', { sorterInfo }),
    render: (text, record) => guard(() => text.name, '')
  });

  tableColumns.push({
    title: 'Ctrip Rooms',
    dataIndex: 'rooms',
    key: 'rooms',
    ...getColumnSorterProps('rooms', { sorterInfo })
  });

  tableColumns.push({
    title: 'Actions',
    key: 'action',
    render: (text, record) => {
      return (
        <Button onClick={handleOnShowEditModal(record)} disabled={!isAllowEdit || checkIsAdminReadOnly()}>
          Edit
        </Button>
      );
    }
  });

  return tableColumns;
};

const CtripListing = ({ form, checkIsAllowCreateIntegration, checkIsAllowEditIntegration, checkIsAdminReadOnly }) => {
  const [filtersInfo, setFilters] = useState({});
  const [sorterInfo, setSorter] = useState({});
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [refetchTableListing, setRefetchTableListing] = useState(true);

  const [ctripListings, setCtripListings] = useState([]);
  const [selectedCtripListing, setSelectedCtripListing] = useState({});
  const [hostLists, setHostsLists] = useState([]);

  const isAllowCreate = !!checkIsAllowCreateIntegration();
  const isAllowEdit = !!checkIsAllowEditIntegration();

  useEffect(() => {
    if (refetchTableListing) {
      setIsTableLoading(true);

      getCtripListings()
        .then(ctripListings => {
          const listingTableData = generateTableSetting(ctripListings);

          setCtripListings(listingTableData);
          refetchCtripListings(false);
        })
        .catch(ex => Message.error(ex.toString()));

      setIsTableLoading(false);
    }
  }, [refetchTableListing]);

  useEffect(() => {
    getHosts().then(response => {
      const mappedHostListsOptions = mapHostListsOptions(response.data);
      setHostsLists(mappedHostListsOptions);
    });
  }, []);

  const handleOnTableChange = (filtersInfo, sorterInfo) => {
    setFilters(filtersInfo);
    setSorter(sorterInfo);
  };

  const handleOnOpenCloseModal = (boolean, modalContentType) => {
    setIsShowModal(boolean);

    if (modalContentType) {
      setModalContent(modalContentType);
    }
  };

  const refetchCtripListings = isRefetch => {
    setRefetchTableListing(isRefetch);
  };

  const switchModalNewToEdit = ctripListing => {
    setSelectedCtripListing(ctripListing);
    setModalContent(MODAL_CONTENT.UPDATE_CTRIP);
  };

  const onClickEditModal = ctripListing => e => {
    setSelectedCtripListing(ctripListing);
    handleOnOpenCloseModal(true, MODAL_CONTENT.UPDATE_CTRIP);
  };

  const handleOnFormSave = e => {
    e.preventDefault();

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const ctripListingId = selectedCtripListing.ctripListingId;
        const ctripId = selectedCtripListing._id;

        const updateListingPayload = {
          ...values,
          ctripListingId
        };

        return putCtripListing(ctripId, updateListingPayload)
          .then(() => {
            const successUpdateMessage = generateNotificationMessage(MESSAGE_TYPE.SUCCESS_UPDATE);
            notification.success(successUpdateMessage);

            refetchCtripListings(true);

            setIsModalLoading(false);
            handleOnOpenCloseModal(false);
          })
          .catch(ex => {
            const errorUpdateMessage = generateNotificationMessage(MESSAGE_TYPE.ERROR_UPDATE);
            notification.error(errorUpdateMessage);

            setIsModalLoading(false);
          });
      }
    });
  };

  const handleOnClickSaveNew = async e => {
    setIsModalLoading(true);

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        return pullNewCtripListing(values.ctripListingId)
          .then(ctripListing => {
            const successPullMessage = generateNotificationMessage(MESSAGE_TYPE.SUCCESS_PULL, values.ctripListingId);
            notification.success(successPullMessage);

            refetchCtripListings(true);
            switchModalNewToEdit(ctripListing);

            setIsModalLoading(false);
          })
          .catch(ex => {
            console.log('ex :>> ', ex);

            const errorPullMessage = generateNotificationMessage(MESSAGE_TYPE.ERROR_PULL, values.ctripListingId, ex);
            notification.error(errorPullMessage);

            setIsModalLoading(false);
          });
      }
    });
  };

  return (
    <Card className="list-card">
      <Row>
        <Col className="list-card-control-section">
          <Button
            type="primary"
            icon="plus"
            onClick={() => handleOnOpenCloseModal(true, MODAL_CONTENT.NEW_CTRIP)}
            loading={isModalLoading}
            disabled={!isAllowCreate || checkIsAdminReadOnly()}
          >
            New Ctrip
          </Button>
        </Col>
        <Col>
          <Table
            rowKey={record => record._id}
            dataSource={ctripListings}
            columns={getTableColumns(filtersInfo, sorterInfo, onClickEditModal, ctripListings, isAllowEdit, checkIsAdminReadOnly)}
            onChange={(pagination, filtersInfo, sorterInfo) => {
              handleOnTableChange(filtersInfo, sorterInfo);
            }}
            loading={isTableLoading}
          />
        </Col>
      </Row>
      {modalContent === MODAL_CONTENT.NEW_CTRIP ? (
        <Modal
          title={'New Ctrip Property'}
          visible={isShowModal}
          onOk={handleOnClickSaveNew}
          onCancel={() => handleOnOpenCloseModal(false, '')}
          okText="Save"
          confirmLoading={isModalLoading}
          destroyOnClose={true}
        >
          <FormInputNumber
            name={'ctripListingId'}
            formLabel={`Ctrip property Id:`}
            placeholder={`Ctrip property Id (example: 5694326)`}
            form={form}
            requiredErrorMessage="Please input listing Id"
            disabled={isModalLoading}
          />
        </Modal>
      ) : (
        <Modal
          title={generateModalTitle(selectedCtripListing)}
          visible={isShowModal}
          onOk={handleOnFormSave}
          onCancel={() => handleOnOpenCloseModal(false, '')}
          okText="Save"
          confirmLoading={isModalLoading}
          destroyOnClose={true}
        >
          <FormInput
            name={'ctripPropertyName'}
            formLabel={`Assign a property nickname:`}
            placeHolder={`Please input property nickname`}
            form={form}
            defaultValue={selectedCtripListing.ctripPropertyName}
            requiredErrorMessage="Please input a property nickname"
            disabled={isModalLoading}
          />
          <FormSelection
            name={'host'}
            formLabel={`Assign a host:`}
            placeHolder={`Please select and assign a host`}
            form={form}
            defaultValue={guard(() => selectedCtripListing.host._id, '')}
            selections={hostLists}
            disabled={!isModalLoading}
            allowClear={false}
          />
        </Modal>
      )}
    </Card>
  );
};

export default Form.create()(withAppContext(CtripListing));
