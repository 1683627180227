import React, { Component } from 'react';
import { Layout } from 'antd';

class Footer extends Component {
  render() {
    const year = new Date();

    return <Layout.Footer style={{ bottom: '0', top: 'auto' }}>© Copyright - {year.getFullYear()} Host Platform Sdn. Bhd.</Layout.Footer>;
  }
}

export default Footer;
