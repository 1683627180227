import { httpClient } from 'utils/httpClient';
import { apiErrorHandler } from './helpers';
import { useCustomPaginatedQuery } from 'hooks/reactQuery';

export const createCleaningTeam = data => {
  return httpClient
    .post('/task-management/create-cleaning-team', data)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);
};

export const getCleaningTeamsList = () => {
  return httpClient
    .get('/task-management/cleaning-team')
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getCleaningTeamById = id => {
  return httpClient
    .get(`/task-management/cleaning-team/${id}`)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);
};

export const deleteTeamById = id => {
  return httpClient
    .delete(`/task-management/cleaning-team/${id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const updateCleaningTeam = (id, body) => {
  return httpClient
    .put(`/task-management/cleaning-team/${id}`, body)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getCleaningTeamByPropertyId = id => {
  return httpClient
    .get(`/task-management/cleaning-team/property/${id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const createChecklist = data => {
  return httpClient
    .post('/task-management/create-checklist', data)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);
};

export const getAllChecklists = () => {
  return httpClient
    .get('/task-management/checklist')
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getCLById = id => {
  return httpClient
    .get(`/task-management/checklist/${id}`)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);
};

export const deleteChecklistById = id => {
  return httpClient
    .delete(`/task-management/checklist/${id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const updateChecklist = (id, body) => {
  return httpClient
    .put(`/task-management/checklist/${id}`, body)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getChecklistByPropertyId = id => {
  return httpClient
    .get(`/task-management/checklist/property/${id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const createAutomatedTask = data => {
  return httpClient
    .post('/task-management/create-automated-task', data)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);
};

export const getAllAutomatedTasks = () => {
  return httpClient
    .get('/task-management/automated-task')
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getATById = id => {
  return httpClient
    .get(`/task-management/automated-task/${id}`)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);
};

export const deleteAutomatedTaskById = id => {
  return httpClient
    .delete(`/task-management/automated-task/${id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const updateAutomatedTask = (id, body) => {
  return httpClient
    .put(`/task-management/automated-task/${id}`, body)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const createPresetTask = data => {
  return httpClient
    .post('/task-management/create-preset-task', data)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);
};

export const getAllPresetTasks = () => {
  return httpClient
    .get('/task-management/preset-task')
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getPTById = id => {
  return httpClient
    .get(`/task-management/preset-task/${id}`)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);
};

export const deletePresetTaskById = id => {
  return httpClient
    .delete(`/task-management/preset-task/${id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const updatePresetTask = (id, body) => {
  return httpClient
    .put(`/task-management/preset-task/${id}`, body)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getTasklistOverview = () => {
  return httpClient
    .get('/task-management/tasklist-overview')
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const createNewTask = data => {
  return httpClient
    .post('/task-management/create-new-task', data)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);
};

export const getAllTasks = type => {
  return httpClient
    .get('/task-management/tasklist', { params: { type } })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getTaskById = id => {
  return httpClient
    .get(`/task-management/task/${id}`)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);
};

export const deleteTaskById = id => {
  return httpClient
    .delete(`/task-management/task/${id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const updateTask = (id, body) => {
  return httpClient
    .put(`/task-management/task/${id}`, body)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const useGetPaginatedTaskList = query => {
  const getPaginatedTaskList = async (key, query) => {
    return httpClient
      .get('/task-management/paginated', { params: query, timeout: 180000 })
      .then(res => res.data)
      .catch(apiErrorHandler);
  };

  return useCustomPaginatedQuery('taskList', getPaginatedTaskList, query);
};

export const getTaskListWithDateRange = (status, startDate, endDate) => {
  return httpClient
    .get(`/task-management/export-task-with-date-range?status=${status}&startDate=${startDate}&endDate=${endDate}`)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);
};
