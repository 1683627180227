import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Row, Col, Icon } from 'antd';

import styles from './ShowMoreList.module.css';

const { Paragraph } = Typography;

const useListProps = (itemLists, itemRow) => {
  const [hasShowMoreButton, setHasShowMoreButton] = useState(false);
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [showList, setShowList] = useState([]);

  const handleOnShowMore = () => {
    if (isShowingMore) {
      setIsShowingMore(false);
      setShowList(itemLists.slice(0, itemRow));
    } else {
      setIsShowingMore(true);
      setShowList(itemLists);
    }
  };

  useEffect(() => {
    if (itemLists.length > itemRow) {
      setHasShowMoreButton(true);
      setIsShowingMore(false);
    } else {
      setHasShowMoreButton(false);
      setIsShowingMore(true);
    }
    setShowList(itemLists.slice(0, itemRow));
  }, [itemLists, itemRow]);

  return { hasShowMoreButton, isShowingMore, showList, handleOnShowMore };
};

const generateItemList = showList => {
  return showList.map((list, i) => {
    return (
      <Paragraph className={styles.listText} ellipsis={{ rows: 1 }} key={i}>
        {list}
      </Paragraph>
    );
  });
};

const generateShowMoreButton = (isShowingMore, listsLength, showListLength, handleOnShowMore) => {
  return isShowingMore ? (
    <div className={styles.showMore}>
      <Button type="link" onClick={handleOnShowMore}>
        {`Show less`}
        <Icon type="up" />
      </Button>
    </div>
  ) : (
    <div className={styles.showMore}>
      <Button type="link" onClick={handleOnShowMore}>
        {`+${listsLength - showListLength} more rules`}
        <Icon type="down" />
      </Button>
    </div>
  );
};

/*----------------------------------------------------------------------------- */

const ShowMoreList = ({ itemLists, itemRow, ...otherProps }) => {
  const { hasShowMoreButton, isShowingMore, showList, handleOnShowMore } = useListProps(itemLists, itemRow);
  if (itemLists.length > 0) {
    const listsLength = itemLists.length;
    const showListLength = showList.length;

    return (
      <Row>
        <Col>{generateItemList(showList)} </Col>
        {hasShowMoreButton ? <Col>{generateShowMoreButton(isShowingMore, listsLength, showListLength, handleOnShowMore)} </Col> : null}
      </Row>
    );
  } else {
    return null;
  }
};

ShowMoreList.propTypes = {
  itemLists: PropTypes.array.isRequired,
  itemRow: PropTypes.number.isRequired
};

ShowMoreList.defaultProps = {
  itemLists: [],
  itemRow: 2
};

export default ShowMoreList;
