import { httpClient } from '../httpClient';
import { apiErrorHandler } from './helpers';

export const getBanner = () => {
  return httpClient
    .get('/post/banner')
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const getAnnouncements = () => {
  return httpClient
    .get('/post/announcements')
    .then(response => response.data)
    .catch(apiErrorHandler);
};
