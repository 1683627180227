import React from 'react';
import { Card, Row } from 'antd';

import { BasicSelect } from 'components/FormSelection/FormSelection';

import styles from './HotelDetails.module.css';

const HotelDetails = ({ isEdit, tiketcomsSelection, selectedTiketcomId, setSelectedTiketcomId, selectedTiketcom }) => {
  return (
    <Card title="Hotel Details">
      <label className="ota-label">Tiket.com Hotel</label>
      <BasicSelect
        selectClassName={styles.selection}
        selections={tiketcomsSelection}
        value={selectedTiketcomId}
        placeholder="Select a Tiket.com hotel"
        onChange={setSelectedTiketcomId}
        isDisabled={isEdit}
      />
      {selectedTiketcom.tiketcomPropertyId ? (
        <Row style={{ marginTop: 12 }}>
          <div>
            <b>Found Hotel: </b> ({selectedTiketcom.tiketcomPropertyId}) {selectedTiketcom.tiketcomPropertyName || 'No Property Name'}
          </div>
        </Row>
      ) : (
        <Row style={{ marginTop: 12 }}>
          <div>Please input a valid Tiket.com Hotel Code</div>
        </Row>
      )}
    </Card>
  );
};

export default HotelDetails;
