import { httpClient } from './../httpClient';
import { apiErrorHandler } from './helpers';
import { useCustomPaginatedQuery } from 'hooks/reactQuery';

export const getAccessList = () => {
  return httpClient
    .get('/torus-access/access-list')
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const createToken = name => {
  return httpClient
    .post('/torus-access/create-token', {
      name: name
    })
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const editToken = data => {
  return httpClient
    .post('/torus-access/edit-token', data)
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const deleteToken = token => {
  return httpClient
    .post('/torus-access/delete-token', token)
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const useGetPaginatedUnitListings = query => {
  const getPaginatedUnitListings = async (key, query) => {
    if (!query.token) {
      return;
    }

    return httpClient
      .get('/torus-access/authorised-list', { params: query })
      .then(res => res.data)
      .catch(apiErrorHandler);
  };

  return useCustomPaginatedQuery('units', getPaginatedUnitListings, query);
};

export const updateAuthorizeToken = body => {
  return httpClient
    .post('/torus-access/update-authorize-unit-list', body)
    .then(response => response.data)
    .catch(apiErrorHandler);
};
