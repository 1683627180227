import { Button, Table } from 'antd';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from 'context/AppContext';
import { StyledCard, StyledHeader } from './CleaningTeam.styles';

import { buildTaskManagementCTCreateUri, buildTaskManagementCTEditUri } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';
import { getCleaningTeamsList } from 'utils/apis/taskManagement';

const CleaningTeam = () => {
  const contextProvider = useContext(AppContext);
  const [isLoading, setLoading] = useState(true);
  const [cleaningTeams, setCleaningTeams] = useState([]);

  useEffect(() => {
    getCleaningTeamsList().then(res => {
      if (res.status === 200) {
        setCleaningTeams(res.data);
        setLoading(false);
      }
    });
  }, []);

  const constructColumns = () => {
    return [
      {
        ...constructColumn('Team Name', 'teamName', { linkFunc: record => buildTaskManagementCTEditUri(record._id) }),
        ...constructColumnFilterSearch('teamName', 'Search Team')
      },
      {
        ...constructColumn('Host', 'host'),
        ...constructColumnFilterSearch('host', 'Search Host')
      },
      {
        ...constructColumn("User's Name", 'users'),
        ...constructColumnFilterSearch('users', 'Search User')
      },
      {
        ...constructColumn('Property Name', 'property'),
        ...constructColumnFilterSearch('property', 'Search Property')
      }
    ];
  };

  return (
    <StyledCard>
      <StyledHeader>
        {contextProvider.checkIsAllowCreateTask() && (
          <Link to={buildTaskManagementCTCreateUri()}>
            <Button id="create-button-cleaning-team" type="primary" icon="plus" disabled={contextProvider.checkIsAdminReadOnly()}>
              Create Cleaner Team
            </Button>
          </Link>
        )}
      </StyledHeader>
      <Table loading={isLoading} scroll={{ x: 1000 }} rowKey={record => record._id} columns={constructColumns()} dataSource={cleaningTeams} />
    </StyledCard>
  );
};

export default CleaningTeam;
