import React, { useMemo } from 'react';
import { Alert, Card, Col, Row } from 'antd';
import PropTypes from 'prop-types';

import FormInput from 'components/FormInput/FormInput';

import styles from './BookingEngineConfigDetailsCard.module.css';

const BasicInformation = ({ form, cardClassname, defaultBookingEnginePropertyDisplayName }) => {
  return (
    <Card title="Basic Information" className={cardClassname}>
      <FormInput
        form={form}
        name="displayName"
        defaultValue={defaultBookingEnginePropertyDisplayName}
        inputType="input"
        label="External Property Display Name"
        placeholder="Type in display name for booking website"
        size="large"
      />
    </Card>
  );
};

const GeneralAlert = () => {
  return (
    <Alert
      className={styles.notes}
      message="Notes"
      description={
        <div>
          <p className={styles.notesText}>All settings configured here will be shown in booking website.</p>
        </div>
      }
      type="info"
      showIcon
    />
  );
};

const BookingEngineConfigDetailsCard = ({ form, cardClassname, defaultValues }) => {
  const defaultBookingEnginePropertyDisplayName = useMemo(() => defaultValues.displayName, [defaultValues]);

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <GeneralAlert />
      </Col>
      <Col span={24}>
        <BasicInformation
          form={form}
          cardClassname={cardClassname}
          defaultBookingEnginePropertyDisplayName={defaultBookingEnginePropertyDisplayName}
        />
      </Col>
    </Row>
  );
};

BookingEngineConfigDetailsCard.propTypes = {
  form: PropTypes.object.isRequired,
  cardClassname: PropTypes.string,
  defaultValues: PropTypes.object
};

BookingEngineConfigDetailsCard.defaultProps = {
  defaultValues: {}
};

export default BookingEngineConfigDetailsCard;
