import { httpClient } from './../httpClient';
import { apiErrorHandler } from './helpers';
import { useCustomPaginatedQuery } from 'hooks/reactQuery';

export const useGetPaginatedWebhook = query => {
  const getPaginatedWebhook = async (key, query) => {
    return httpClient
      .get('/webhook/paginated', { params: query, timeout: 180000 })
      .then(res => res.data)
      .catch(apiErrorHandler);
  };

  return useCustomPaginatedQuery('webhookLogs', getPaginatedWebhook, query);
};

export const getWebhookByHost = hostId => {
  return httpClient
    .get('/webhook/host/' + hostId)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const updateWebhookConfigure = body => {
  return httpClient
    .put(`/webhook`, body)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const retryWebhook = id => {
  return httpClient
    .post(`/webhook/${id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const testWebhook = body => {
  return httpClient
    .post(`/webhook/test-webhook`, body)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};
