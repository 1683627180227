import { httpClient } from './../httpClient';
import { apiErrorHandler } from './helpers';

export const getSeasonalRuleTimeline = (airbnbListingId, sinceDate, endDate) =>
  httpClient
    .get(`/airbnb/getSeasonalRuleGroupTimeline/${airbnbListingId}?sinceDate=${sinceDate}&endDate=${endDate}`)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getSeasonalRuleGroupDetails = airbnbListingId =>
  httpClient
    .get(`/airbnb/seasonalRuleGroup/${airbnbListingId}`)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const putSeasonalRuleTimeline = (airbnbListingId, sinceDate, endDate, airbnbSeasonalRuleGroupId, note) =>
  httpClient
    .put(`/airbnb/setSeasonalRuleGroupTimeline/${airbnbListingId}`, { sinceDate, endDate, airbnbSeasonalRuleGroupId, note })
    .then(response => response.data)
    .catch(apiErrorHandler);

export const deleteSeasonalRule = (airbnbListingId, airbnbSeasonalRuleGroupId) =>
  httpClient
    .delete(`/airbnb/seasonalRuleGroup/${airbnbListingId}/${airbnbSeasonalRuleGroupId}`)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const postSeasonalRuleGroup = (airbnbListingId, payload) =>
  httpClient
    .post(`/airbnb/seasonalRuleGroup/${airbnbListingId}`, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const putSeasonalRuleGroup = (airbnbListingId, airbnbSeasonalRuleGroupId, payload) =>
  httpClient
    .put(`/airbnb/seasonalRuleGroup/${airbnbListingId}/${airbnbSeasonalRuleGroupId}`, payload)
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
