import { httpClient } from './../httpClient';

export const createServicePackage = servicePackageDetails => {
  return httpClient.post('/servicePackage', servicePackageDetails);
};

export const getServicePackage = () => {
  return httpClient
    .get('/servicePackage/populated')
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getServicePackageById = id => {
  return httpClient
    .get(`/servicePackage/${id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getServicePackageUnits = host => {
  return httpClient
    .get(`/servicePackage/units`, { params: { host } })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const deleteServicePackage = id => {
  return httpClient
    .delete(`/servicePackage/${id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const updateServicePackage = (id, body) => {
  return httpClient
    .put(`/servicePackage/${id}`, body)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};
