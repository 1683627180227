import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Row, Checkbox, message, Modal } from 'antd';
import { withAppContext } from 'context/AppContext';
import styles from './FpxModal.module.css';
import fpxLogo from 'images/fpx-logo.png';

import { getBankConstant } from 'utils/apis/constants';
import { createPaymentViaFpx } from 'utils/apis/billingInvoice';
import FormSelection from 'components/FormSelection/FormSelection';
import { buildBillingInvoiceUri } from 'utils/routes';
import { generateDisplayFee } from 'utils/general';

const stripe = !!window.Stripe && window.Stripe(process.env.REACT_APP_STRIPE_KEY || 'pk_test_ecHsiw8fixGWjZ7HwmP5bk3y00gwo2VxfK');

const FormItem = Form.Item;

class FpxModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bankList: []
    };
  }

  componentDidMount() {
    this.fetchBankConstant();
  }

  fetchBankConstant = () => {
    getBankConstant()
      .then(bankList => {
        this.setState({
          bankList
        });
      })
      .catch(err => console.error(err));
  };

  handleOnMakeFpxPaymentBtnClicked = record => {
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const formattedPayload = {
          id: record._id,
          amount: record.amount,
          bank: values.bank,
          paymentToken: record.paymentToken,
          invoiceNo: record.invoiceNo
        };
        createPaymentViaFpx(formattedPayload)
          .then(paymentRes => {
            const session = paymentRes.session;
            const payment = paymentRes.payment;
            const paymentToken = paymentRes.paymentToken;

            stripe
              .confirmPaymentIntent(session.client_secret, {
                payment_method: payment.id,
                return_url: process.env.REACT_APP_DOMAIN
                  ? `${process.env.REACT_APP_DOMAIN}${buildBillingInvoiceUri()}?fpx_success=true&token=${paymentToken}`
                  : `http://localhost:3001${buildBillingInvoiceUri()}?fpx_success=true&token=${paymentToken}`
              })
              .then(confirmRes => {
                if (confirmRes && confirmRes.paymentIntent) {
                  window.open(confirmRes.paymentIntent.next_action.redirect_to_url.url);
                } else {
                  message.error('There was an error processing your payment. Please try again.');
                }
              })
              .catch(ex => {
                console.log(ex);
              });
          })
          .catch(ex => {
            console.log(ex);
          });
      }
    });
  };

  render() {
    const { bankList } = this.state;
    const { form, record, visible, onCancel } = this.props;
    return (
      <Modal visible={visible} maskClosable={false} footer={null} onCancel={onCancel}>
        <Form style={{ width: '100%' }}>
          <Row type="flex" justify="center">
            <img className={styles.marginBottom} alt="FPX" src={fpxLogo} width="150px" />
          </Row>
          <Row type="flex" justify="center">
            Amount to be paid:
          </Row>
          <Row type="flex" justify="center">
            <div className={styles.amount}>
              <b>{generateDisplayFee(record.amount)}</b>
            </div>
          </Row>
          <Row>
            <FormSelection
              name="bank"
              requiredErrorMessage="Please select a bank"
              selections={bankList}
              placeholder="Choose a bank"
              form={form}
              onSearch={() => {}}
              size="large"
            />
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <FormItem>
              {form.getFieldDecorator('termsAndCondition', {
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      const form = this.props.form;
                      if (!form.getFieldValue('termsAndCondition')) {
                        callback('Please read and accept the terms and condition');
                      } else {
                        callback();
                      }
                    }
                  }
                ]
              })(<Checkbox className={styles.checkbox} />)}
              I agree to the Terms and Conditions
            </FormItem>
          </Row>
          <Row className={styles.marginBottom} type="flex" justify="center" gutter={16}>
            <a href="https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp" target="_blank" rel="noopener noreferrer">
              View Terms and Conditions
            </a>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Button
              onClick={() => {
                this.handleOnMakeFpxPaymentBtnClicked(record);
              }}
              type="primary"
              size="large"
              style={{ width: '70%' }}
            >
              Make Payment
            </Button>
          </Row>
        </Form>
      </Modal>
    );
  }
}

FpxModal.propTypes = {
  record: PropTypes.object,
  visible: PropTypes.bool,
  onCancel: PropTypes.func
};

FpxModal.defaultProps = {
  record: {},
  visible: false,
  onCancel: () => {}
};

export default withAppContext(Form.create()(FpxModal));
