import React from 'react';
import PropTypes from 'prop-types';
import { Table, Switch, Form, Card, Col, Row, Tooltip, Icon, message } from 'antd';

import FormInput from 'components/FormInput/FormInput';

import styles from './SyncCard.module.css';

const FormItem = Form.Item;

const contstructFormInput = (form, inputName, value, disabled = false) => (
  <FormInput className={styles.formInput} name={inputName} form={form} defaultValue={value} size="large" disabled={disabled} />
);
class SyncCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSetDefaultValue: false,
      roomTypes: [],
      isLoading: true
    };
  }

  componentDidMount() {
    const { defaultValues } = this.props;
    // const roomTypes = form.getFieldValue('roomTypes') || [];

    this.setState({
      roomTypes: defaultValues.roomTypes,
      propSync: defaultValues.propSync,
      isLoading: false
    });
  }

  static getDerivedStateFromProps(props, state) {
    // Set roomTypes state with data
    const { defaultValues, form } = props;
    const { isSetDefaultValue } = state;
    const roomTypes = form.getFieldValue('roomTypes') || [];
    if (!isSetDefaultValue && roomTypes.length === 0 && defaultValues.roomTypes && defaultValues.roomTypes.length > 0 && defaultValues.propSync) {
      props.form.setFieldsValue({
        roomTypes: defaultValues.roomTypes,
        propSync: defaultValues.propSync
      });
      return {
        isSetDefaultValue: true
      };
    }
    return null;
  }

  handleOnSync = (index, name) => checked => {
    const { form } = this.props;
    let roomTypes = this.state.roomTypes;
    roomTypes[index].sync = checked;

    roomTypes[index] = {
      ...roomTypes[index],
      sync: checked
    };
    form.setFieldsValue({
      sync: checked
    });
    this.setState({
      roomTypes
    });
  };

  handleOnPropSync = () => checked => {
    const { form } = this.props;

    form.setFieldsValue({
      propSync: checked
    });
    this.setState({
      propSync: checked
    });
  };

  render() {
    const { form, defaultValues, cardClassname } = this.props;
    const { isLoading, roomTypes, propSync } = this.state;
    const columns = [
      {
        title: 'Sync',
        dataIndex: 'sync',
        render: (value, row, index) => {
          return (
            <FormItem>
              {form.getFieldDecorator(`${row.key}.sync`, {
                initialValue: defaultValues.roomTypes[index].sync
              })(<Switch checked={roomTypes[index].sync} onChange={this.handleOnSync(index, `${row.key}.sync`)} disabled={!propSync} />)}
            </FormItem>
          );
        }
      },
      {
        title: 'Room Type',
        dataIndex: 'name',
        render: (value, row) => {
          return contstructFormInput(form, `${row.key}.name`, value, true);
        }
      },
      {
        title: 'External Display Name',
        dataIndex: 'externalDisplayName',
        render: (value, row) => {
          const syncStatus = form.getFieldValue(`${row.key}.sync`);
          return contstructFormInput(form, `${row.key}.externalDisplayName`, value, !syncStatus || !propSync);
        }
      }
      // {
      //   title: 'Weekday Pricing',
      //   dataIndex: 'rate.weekday',
      //   render: (value, row) => {
      //     return contstructFormInput(form, `${row.key}.weekday`, value);
      //   }
      // },
      // {
      //   title: 'Weekend Pricing',
      //   dataIndex: 'rate.weekend',
      //   render: (value, row) => {
      //     return contstructFormInput(form, `${row.key}.weekend`, value);
      //   }
      // }
    ];

    return (
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Card
            className={cardClassname}
            title="Property Sync"
            loading={isLoading}
            extra={form.getFieldDecorator(`propSync`, {
              initialValue: defaultValues.propSync
            })(
              <Row>
                <Switch checked={propSync} onChange={this.handleOnPropSync()} />
                <Tooltip title="Toggle to start/stop selling in Booking Website">
                  <Icon style={{ marginLeft: 20 }} type="question-circle-o" />
                </Tooltip>
              </Row>
            )}
          >
            <Table columns={columns} dataSource={defaultValues.roomTypes} scroll={{ x: 700 }} bordered pagination={false} />
          </Card>
        </Col>
      </Row>
    );
  }
}

SyncCard.propTypes = {
  form: PropTypes.object.isRequired,
  cardClassname: PropTypes.string,
  defaultValues: PropTypes.object,
  hasFetchedRoomType: PropTypes.bool
};

SyncCard.defaultProps = {
  defaultValues: {},
  form: {},
  cardClassname: '',
  hasFetchedRoomType: false
};

export default SyncCard;
