import React, { useState, useMemo } from 'react';
import { Button, Row, Table } from 'antd';

import { useGetPaginatedPromotions } from 'utils/apis/rateModifier';
import { generateDisplayPercentage, generateDisplayFee, getConstantLabel, guard } from 'utils/general';
import { constructColumn, constructColumnFilterSearch, handleOnAggregationTableChange } from 'utils/table/table';

import { useFetchConstant } from 'hooks/constants';

import ModalPromotion from '../components/ModalPromotion/ModalPromotion';

import styles from '../RateModifier.module.css';

const constructColumns = (rateModifierTypes, promotionStatuses, handleOnClickEditPromotion) => {
  const columns = [
    {
      ...constructColumn('Promotion Name', 'name', { hasSorter: true }),
      ...constructColumnFilterSearch('name', 'Search Promotion Name'),
      render: (text, record) => {
        return (
          <Button type="link" onClick={() => handleOnClickEditPromotion(record)}>
            {text}
          </Button>
        );
      }
    },
    {
      ...constructColumn('Host Name', 'hostName', { hasSorter: true }),
      ...constructColumnFilterSearch('hostName', 'Search Host Name')
    },
    {
      ...constructColumn('Amount/Percentage', 'amount', { hasSorter: true }),
      render: (text, record) => {
        const rateModifierTypeObject = rateModifierTypes.find(type => type.code === record.type);
        return guard(
          () => (rateModifierTypeObject.isPercentage ? generateDisplayPercentage(text, true) : generateDisplayFee(text, true, 0, record.currency)),
          ''
        );
      }
    },
    {
      ...constructColumn('Promotion Start', 'promotionDetails.promotionPeriod.startDate', { hasSorter: true })
    },
    {
      ...constructColumn('Promotion End', 'promotionDetails.promotionPeriod.endDate', { hasSorter: true })
    },
    {
      ...constructColumn('Status', 'promotionDetails.status', { hasSorter: true }),
      render: status => getConstantLabel(promotionStatuses, status)
    }
  ];

  return columns;
};

const useFetchConstants = () => {
  const { selection: rateModifierTypes, isLoading: isRateModifierTypesLoading } = useFetchConstant('rateModifierType');
  const { selection: rateModifierSigns, isLoading: isRateModifierSignsLoading } = useFetchConstant('rateModifierSign');
  const { selection: promotionStatuses, isLoading: isPromotionStatusesLoading } = useFetchConstant('promotionStatus');

  const selections = { rateModifierTypes, rateModifierSigns, promotionStatuses };
  const isLoading = isRateModifierTypesLoading || isRateModifierSignsLoading || isPromotionStatusesLoading;

  return { selections, isLoading };
};

const Promotion = ({ isAllowCreateRateModifier, isAllowEditRateModifier, isAllowDeleteRateModifier, checkIsAdminReadOnly }) => {
  const [query, setQuery] = useState({});
  const [isShowModalPromotion, setIsShowModalPromotion] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState('');

  const {
    isLoading: isConstantsLoading,
    selections: { rateModifierTypes, rateModifierSigns, promotionStatuses }
  } = useFetchConstants();
  const { paginatedData: promotions, total, isLoading: isPromotionsLoading, refetch: refetchPromotions } = useGetPaginatedPromotions(query);

  const filteredRateModifierSigns = useMemo(() => !!rateModifierSigns && rateModifierSigns.filter(sign => sign.isPositive === false), [
    rateModifierSigns
  ]);

  const isLoading = isConstantsLoading || isPromotionsLoading;

  const handleOnClickEditPromotion = promotion => {
    setIsShowModalPromotion(true);
    setSelectedPromotion(promotion);
  };

  const handleOnModalPromotionClose = () => {
    setIsShowModalPromotion(false);
    setSelectedPromotion('');
    refetchPromotions();
  };

  return (
    <>
      {isShowModalPromotion && (
        <ModalPromotion
          isShowModalPromotion={isShowModalPromotion}
          selectedPromotion={selectedPromotion}
          onModalPromotionClose={handleOnModalPromotionClose}
          rateModifierTypes={rateModifierTypes}
          rateModifierSigns={filteredRateModifierSigns}
          isAllowEditRateModifier={isAllowEditRateModifier}
          isAllowDeleteRateModifier={isAllowDeleteRateModifier}
          checkIsAdminReadOnly={checkIsAdminReadOnly}
        />
      )}
      {isAllowCreateRateModifier && (
        <Row>
          <Button id="create-button3-rate" type="primary" icon="plus" onClick={() => setIsShowModalPromotion(true)} disabled={checkIsAdminReadOnly()}>
            Create Promotion
          </Button>
        </Row>
      )}
      <Row className={styles.table}>
        <Table
          loading={isLoading}
          rowKey={record => record._id}
          dataSource={promotions}
          columns={constructColumns(rateModifierTypes, promotionStatuses, handleOnClickEditPromotion)}
          pagination={{ total }}
          onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
        />
      </Row>
    </>
  );
};

export default Promotion;
