import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { BlobProvider, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import { getRevenueShare } from 'utils/apis/revenueshare';

import { MONTHS } from 'utils/constants';
import { getConstantLabel, guard, numberWithCommas } from 'utils/general';

const DEFAULT_DOCUMENT_DOM = <Document />;
const DEFAULT_DOCUMENT_SIZE = 369;

const useFetchLabels = (packageTypeConstants, revenueShareConstants, payoutDetail) => {
  const getRevenueShareLabel = (revenueShareConstants, revenueShareType) => {
    return (
      getConstantLabel(revenueShareConstants, revenueShareType) ||
      getRevenueShare(revenueShareType).then(res => {
        return res.data.name;
      })
    );
  };

  const [packageTypeLabel, setPackageTypeLabel] = useState('');
  const [revenueShareLabel, setRevenueShareLabel] = useState('');

  useEffect(() => {
    const fetchLabels = async () => {
      const packageTypeLabel = guard(() => getConstantLabel(packageTypeConstants, payoutDetail.servicePackage.packageType), '-');
      const revenueShareLabel = await getRevenueShareLabel(revenueShareConstants, payoutDetail.servicePackage.revenueShare);

      setPackageTypeLabel(packageTypeLabel);
      setRevenueShareLabel(revenueShareLabel);
    };

    fetchLabels();
  }, [packageTypeConstants, revenueShareConstants, payoutDetail]);

  return { packageTypeLabel, revenueShareLabel };
};

const constructDataForPayoutTemplatePdf = (packageTypeLabel, revenueShareLabel, selectedHostImage, selectedOwnerName, payoutDetail) => {
  let calculation = '';
  let ownersRevenueSharePercentage = false;

  const getCurrency = (payoutDetailInfo = {}) => guard(() => payoutDetailInfo.unit.currency, 'RM');
  if (payoutDetail.servicePackage.calculations) {
    const calculate = payoutDetail.servicePackage.calculations.find(calculation => {
      return Object.values(calculation)[0];
    });
    if (calculate) {
      if (String(calculate.type) === '1') {
        calculation = `Fixed Amount ${getCurrency(payoutDetail)} ${numberWithCommas(calculate.amount)}`;
      } else {
        calculation = `${calculate.ownerShare}`;
        ownersRevenueSharePercentage = true;
      }
    }
  }

  const allowDisplayTotalPayoutAmount =
    (payoutDetail.year >= new Date().getFullYear() && payoutDetail.month >= new Date().getMonth()) || payoutDetail.year > new Date().getFullYear();

  return {
    ownerName: selectedOwnerName,
    totalUnits: payoutDetail.totalUnits,
    propertyName: payoutDetail.property.name || '',
    unitName: payoutDetail.unit.name || '',
    currentCollectionToDate: numberWithCommas(payoutDetail.perUnitRevenue),
    certNo: payoutDetail.protection && payoutDetail.protection.certNo ? payoutDetail.protection.certNo : 'Not Activated',
    servicePackageName: payoutDetail.servicePackage.name || '',
    servicePackageTypeLabel: packageTypeLabel,
    servicePackageUnits: payoutDetail.servicePackage.totalUnits,
    servicePackageAmount: `Owner ${calculation}%`,
    revenueShareLabel,
    monthString: getConstantLabel(MONTHS, payoutDetail.month),
    year: payoutDetail.year,
    expenses: payoutDetail.expenses,
    otherExpensesData: payoutDetail.otherExpensesData ? payoutDetail.otherExpensesData.othersBreakdownDetails || [] : {},
    reservations: payoutDetail.reservations,
    ownersRevenueShare: numberWithCommas(Number(payoutDetail.borneExpenses.owner) + Number(payoutDetail.nettOwnersProfit)),
    ownersRevenueSharePercentage: ownersRevenueSharePercentage ? `(${calculation}%)` : '',
    totalPayoutAmount: allowDisplayTotalPayoutAmount ? '-' : `${getCurrency(payoutDetail)} ${numberWithCommas(payoutDetail.totalPayoutAmount)}`,
    totalOwnerBorneExpenses: numberWithCommas(payoutDetail.borneExpenses.owner),
    nettProfit: numberWithCommas(payoutDetail.perUnitRevenue),
    totalRevenue: payoutDetail.totalRevenue,
    numberofReservations: payoutDetail.reservations.length,
    hostImage: selectedHostImage,
    totalNetProfit: numberWithCommas(Number(payoutDetail.totalRevenue) - Number(payoutDetail.borneExpenses.shared))
  };
};

const generatePayoutDocument = (
  integrationSourceConstants,
  packageTypeLabel,
  revenueShareLabel,
  selectedHostImage,
  selectedOwnerName,
  payoutDetail,
  calculateOtherCharges
) => {
  const getCurrency = payoutDetailInfo => guard(() => payoutDetailInfo.unit.currency, 'RM');
  const payoutData = {
    ...constructDataForPayoutTemplatePdf(packageTypeLabel, revenueShareLabel, selectedHostImage, selectedOwnerName, payoutDetail)
  };

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      paddingBottom: 35,
      paddingTop: 35
    },
    image: {
      width: 80,
      height: 80
    },
    row: {
      flexDirection: 'row',
      display: 'flex',
      flexWrap: 'wrap'
    },
    heightOfTen: {
      height: 10
    },
    paddingBtm: {
      paddingBottom: 5
    },
    groupFields: {
      width: '100%',
      flexDirection: 'row',
      paddingLeft: 20,
      paddingBottom: 10,
      alignItems: 'stretch'
    },
    title: {
      textAlign: 'center',
      fontSize: 20
      // paddingTop: 15
    },
    fieldsOwnerDetails: {
      fontSize: 10,
      width: '80%',
      textAlign: 'left',
      paddingLeft: 30,
      paddingRight: 10,
      paddingTop: 10,
      whiteSpace: 'normal'
    },
    normalText: {
      fontSize: 10,
      paddingLeft: 20,
      paddingRight: 20
    },
    greyBlockText: {
      backgroundColor: '#E7E5E6',
      fontSize: 10,
      height: 20,
      marginLeft: 10,
      marginRight: 10,
      paddingLeft: 10,
      paddingRight: 10
    },
    blueBlockText: {
      backgroundColor: '#BAE3EC',
      fontSize: 10,
      height: 20,
      marginLeft: 10,
      marginRight: 10,
      paddingLeft: 10,
      paddingRight: 10
    },
    noReservationText: {
      textAlign: 'center',
      paddingTop: '10',
      fontStyle: 'italic',
      fontSize: 20,
      color: 'grey'
    },
    borderBottom: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      alignItems: 'stretch',
      borderColor: 'black'
    },
    footer: {
      color: '#E7E5E6',
      textAlign: 'center',
      height: '3%',
      position: 'absolute',
      bottom: 0
    }
  });

  const headerDetails = (
    <View>
      <View style={styles.groupFields}>
        <View>
          <Image style={styles.image} src={payoutData.hostImage} />
        </View>
        <View style={styles.fieldsOwnerDetails}>
          <View style={styles.row}>
            <View style={{ width: '25%' }}>
              <Text style={styles.paddingBtm}>Owner Name</Text>
            </View>
            <View style={{ width: '2%' }}>
              <Text style={styles.paddingBtm}>: </Text>
            </View>
            <View style={{ width: '73%' }}>
              <Text style={{ ...styles.paddingBtm, whiteSpace: 'normal' }}>{payoutData.ownerName}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{ width: '25%' }}>
              <Text style={styles.paddingBtm}>Total Units</Text>
            </View>
            <View style={{ width: '2%' }}>
              <Text style={styles.paddingBtm}>: </Text>
            </View>
            <View style={{ width: '73%' }}>
              <Text style={{ ...styles.paddingBtm, whiteSpace: 'normal' }}>{payoutData.totalUnits}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{ width: '25%' }}>
              <Text style={styles.paddingBtm}>Payout Period</Text>
            </View>
            <View style={{ width: '2%' }}>
              <Text style={styles.paddingBtm}>: </Text>
            </View>
            <View style={{ width: '73%' }}>
              <Text style={styles.paddingBtm}>
                {payoutData.monthString} {payoutData.year}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{ width: '25%' }}>
              <Text style={styles.paddingBtm}>Total Payout Amount</Text>
            </View>
            <View style={{ width: '2%' }}>
              <Text style={styles.paddingBtm}>: </Text>
            </View>
            <View style={{ width: '73%' }}>
              <Text style={styles.paddingBtm}>{payoutData.totalPayoutAmount}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.normalText}>
        <View style={styles.row}>
          <View style={{ width: '60%' }}>
            <Text style={styles.paddingBtm}>Property Name: {payoutData.propertyName}</Text>
          </View>
          <View style={{ width: '40%', textAlign: 'right' }}>
            <Text style={styles.paddingBtm}>Current Collection to Date</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={{ width: '60%' }}>
            <Text style={styles.paddingBtm}>Unit Name: {payoutData.unitName}</Text>
          </View>
          <View style={{ width: '40%', textAlign: 'right' }}>
            <Text style={styles.paddingBtm}>
              {getCurrency(payoutDetail)} {payoutData.currentCollectionToDate}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View>
            <Text style={styles.paddingBtm}>Host Protect: {payoutData.certNo}</Text>
          </View>
        </View>
      </View>
    </View>
  );

  let expensesTotal = 0;
  const expensesDetails = payoutData.expenses.map((expense, i) => {
    expensesTotal = expense.amount + expensesTotal;
    const sharingRules = expense.sharingRules && '(' + expense.sharingRules.charAt(0).toUpperCase() + expense.sharingRules.slice(1) + ')';
    return (
      <View key={i}>
        <View key={`expense${i}`} style={styles.row}>
          <View style={{ width: '60%' }}>
            <Text style={styles.paddingBtm}>
              {expense.name} {sharingRules}
            </Text>
          </View>
          <View style={{ width: '40%', textAlign: 'right' }}>
            <Text style={styles.paddingBtm}>
              {expense.name !== 'Others' ? `(${getCurrency(payoutDetail)} ${numberWithCommas(expense.amount)})` : ''}
            </Text>
          </View>
        </View>
        {expense.name === 'Others' &&
          payoutData.otherExpensesData.map((otherExpenseData, j) => {
            return (
              <View key={`other${j}`} style={styles.row}>
                <View style={{ width: '60%' }}>
                  <Text style={styles.paddingBtm}>- {otherExpenseData.label}</Text>
                </View>
                <View style={{ width: '40%', textAlign: 'right' }}>
                  <Text style={styles.paddingBtm}>
                    ({getCurrency(payoutDetail)} {numberWithCommas(otherExpenseData.amount)})
                  </Text>
                </View>
              </View>
            );
          })}
      </View>
    );
  });

  const reservationDetails = payoutData.reservations.map((reservation, i) => {
    return (
      <View key={`reservation${i}`}>
        <View style={styles.row}>
          <View style={{ width: '60%' }}>
            <Text style={styles.paddingBtm}>
              {reservation.startDate} - {reservation.endDate} | {reservation.code}
            </Text>
          </View>
          <View style={{ width: '40%', textAlign: 'right' }}>
            <Text style={styles.paddingBtm}>
              {getCurrency(payoutDetail)} {numberWithCommas(reservation.charges.total)}
            </Text>
          </View>
        </View>
        <View style={{ height: 5 }} />
      </View>
    );
  });

  const detailedReservationBreakdown = payoutData.reservations.map((reservation, i) => {
    const selectedIntegrationSource = Object.values(integrationSourceConstants).filter(
      integrationSource => reservation.platform === integrationSource.code
    );
    let otaFee;
    if (reservation.platform === 'hostplatform') {
      otaFee = 'N/A';
    } else {
      if (reservation.platform === 'others' && !reservation.priceDetails.platformFee) {
        otaFee = 'N/A';
      } else {
        otaFee = `${getCurrency(payoutDetail)} ${numberWithCommas(reservation.priceDetails.platformFee)}`;
      }
    }
    return (
      <View key={`reservationBreakdown${i}`}>
        <View style={styles.normalText}>
          <View style={styles.row}>
            <Text style={{ paddingTop: 6.5, width: '15%' }}>
              {reservation.startDate} - {reservation.endDate}
            </Text>
            <Text style={{ paddingTop: 6.5, width: '18%', textAlign: 'center' }}>{reservation.code}</Text>
            <Text style={{ paddingTop: 6.5, width: '18%', textAlign: 'center' }}>
              {selectedIntegrationSource && selectedIntegrationSource[0] ? selectedIntegrationSource[0].label : '-'}
            </Text>
            <Text style={{ paddingTop: 6.5, width: '18%', textAlign: 'center' }}>{otaFee}</Text>
            <View style={{ paddingTop: 6.5, width: '28%' }}>
              <Text>
                Room Rate: {getCurrency(payoutDetail)} {numberWithCommas(reservation.charges.rental)},
              </Text>
              <Text>
                Cleaning Paid By Guest: {getCurrency(payoutDetail)} {numberWithCommas(reservation.charges.cleaning)},
              </Text>
              <Text>
                Extra Guest Fee: {getCurrency(payoutDetail)} {numberWithCommas(reservation.charges.extraGuest)},
              </Text>
              <Text>
                Other Share Charges: {getCurrency(payoutDetail)} {numberWithCommas(calculateOtherCharges(reservation.charges))}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.heightOfTen} />
      </View>
    );
  });

  const noReservation = (
    <View style={{ height: 50 }}>
      <Text style={styles.noReservationText}>No Reservation</Text>
    </View>
  );

  const footer = (
    <View
      style={{
        ...styles.normalText,
        ...styles.footer
      }}
    >
      <Text>Powered by HostPlatform</Text>
    </View>
  );

  return (
    <Document>
      <Page size="A4" wrap={true} style={styles.page}>
        <View>
          {headerDetails}
          <View style={styles.greyBlockText}>
            <Text style={{ paddingTop: 6.5 }}>Service Package</Text>
          </View>
          <View style={styles.heightOfTen} />
          <View style={styles.normalText}>
            <Text style={styles.paddingBtm}>Service Package Name : {payoutData.servicePackageName}</Text>
            <Text style={styles.paddingBtm}>Total Units in Service Package : {payoutData.servicePackageUnits}</Text>
            <Text style={styles.paddingBtm}>
              Service Package Type : {payoutData.servicePackageTypeLabel} | {payoutData.revenueShareLabel} | {payoutData.servicePackageAmount}
            </Text>
          </View>
          <View style={styles.greyBlockText}>
            <Text style={{ paddingTop: 6.5 }}>Reservation Revenue Summary</Text>
          </View>
          <View style={styles.heightOfTen} />
          <View style={styles.normalText}>
            {reservationDetails.length === 0 ? noReservation : reservationDetails}
            <View style={styles.borderBottom} />
            <View style={styles.heightOfTen} />
            <View style={styles.row}>
              <View style={{ width: '60%' }}>
                <Text style={styles.paddingBtm}>Total Reservation Revenue</Text>
              </View>
              <View style={{ width: '40%', textAlign: 'right' }}>
                <Text style={styles.paddingBtm}>
                  {getCurrency(payoutDetail)} {numberWithCommas(payoutData.totalRevenue)}
                </Text>
              </View>
            </View>
          </View>
          {reservationDetails.length <= 5 && (
            <View>
              <View style={styles.heightOfTen} />
              <View style={styles.greyBlockText}>
                <Text style={{ paddingTop: 6.5 }}>Total Expenses this Period</Text>
              </View>
              <View style={styles.heightOfTen} />
              <View style={styles.normalText}>
                {expensesDetails}
                <View style={styles.borderBottom} />
              </View>
              <View style={styles.heightOfTen} />
              <View style={{ ...styles.row, ...styles.normalText }}>
                <View style={{ width: '60%' }}>
                  <Text style={styles.paddingBtm}>Total Expenses</Text>
                </View>
                <View style={{ width: '40%', textAlign: 'right' }}>
                  <Text style={styles.paddingBtm}>
                    ({getCurrency(payoutDetail)} {numberWithCommas(expensesTotal)})
                  </Text>
                </View>
              </View>
              <View style={styles.heightOfTen} />
              <View style={styles.greyBlockText}>
                <Text style={{ paddingTop: 6.5 }}>Total Profit</Text>
              </View>
              <View style={styles.heightOfTen} />
              {payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) ? (
                <View style={styles.normalText}>
                  <View style={styles.row}>
                    <View style={{ width: '60%' }}>
                      <Text style={styles.paddingBtm}>Total Net Profit</Text>
                    </View>
                    <View style={{ width: '40%', textAlign: 'right' }}>
                      <Text style={styles.paddingBtm}>
                        {getCurrency(payoutDetail)} {payoutData.totalNetProfit}
                      </Text>
                    </View>
                  </View>
                </View>
              ) : (
                <View style={styles.normalText}>
                  <View style={styles.row}>
                    <View style={{ width: '60%' }}>
                      <Text style={styles.paddingBtm}>Owner Revenue Share {payoutData.ownersRevenueSharePercentage}</Text>
                    </View>
                    <View style={{ width: '40%', textAlign: 'right' }}>
                      <Text style={styles.paddingBtm}>
                        {getCurrency(payoutDetail)} {payoutData.ownersRevenueShare}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '60%' }}>
                      <Text style={styles.paddingBtm}>Owner Total Borne Expenses</Text>
                    </View>
                    <View style={{ width: '40%', textAlign: 'right' }}>
                      <Text style={styles.paddingBtm}>
                        ({getCurrency(payoutDetail)} {payoutData.totalOwnerBorneExpenses})
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              <View style={styles.heightOfTen} />
              <View style={styles.blueBlockText}>
                <View style={{ ...styles.row, paddingTop: 6.5 }}>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.paddingBtm}>
                      Owner Net Profit{' '}
                      {payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) &&
                        payoutData.ownersRevenueSharePercentage}
                    </Text>
                  </View>
                  <View style={{ width: '40%', textAlign: 'right' }}>
                    <Text style={styles.paddingBtm}>
                      {getCurrency(payoutDetail)} {payoutData.nettProfit}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}
          {footer}
        </View>
      </Page>
      {reservationDetails.length > 5 && (
        <Page size="A4" wrap={true} style={styles.page}>
          <View>
            <View style={styles.heightOfTen} />
            <View style={styles.greyBlockText}>
              <Text style={{ paddingTop: 6.5 }}>Total Expenses this Period</Text>
            </View>
            <View style={styles.heightOfTen} />
            <View style={styles.normalText}>
              {expensesDetails}
              <View style={styles.borderBottom} />
            </View>
            <View style={styles.heightOfTen} />
            <View style={{ ...styles.row, ...styles.normalText }}>
              <View style={{ width: '60%' }}>
                <Text style={styles.paddingBtm}>Total Expenses</Text>
              </View>
              <View style={{ width: '40%', textAlign: 'right' }}>
                <Text style={styles.paddingBtm}>
                  ({getCurrency(payoutDetail)} {numberWithCommas(expensesTotal)})
                </Text>
              </View>
            </View>
            <View style={styles.heightOfTen} />
            <View style={styles.greyBlockText}>
              <Text style={{ paddingTop: 6.5 }}>Total Profit</Text>
            </View>
            <View style={styles.heightOfTen} />
            {payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) ? (
              <View style={styles.normalText}>
                <View style={styles.row}>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.paddingBtm}>Total Net Profit</Text>
                  </View>
                  <View style={{ width: '40%', textAlign: 'right' }}>
                    <Text style={styles.paddingBtm}>
                      {getCurrency(payoutDetail)} {payoutData.totalNetProfit}
                    </Text>
                  </View>
                </View>
              </View>
            ) : (
              <View style={styles.normalText}>
                <View style={styles.row}>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.paddingBtm}>Owner Revenue Share {payoutData.ownersRevenueSharePercentage}</Text>
                  </View>
                  <View style={{ width: '40%', textAlign: 'right' }}>
                    <Text style={styles.paddingBtm}>
                      {' '}
                      {getCurrency(payoutDetail)} {payoutData.ownersRevenueShare}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.paddingBtm}>Owner Total Borne Expenses</Text>
                  </View>
                  <View style={{ width: '40%', textAlign: 'right' }}>
                    <Text style={styles.paddingBtm}>
                      ({getCurrency(payoutDetail)} {payoutData.totalOwnerBorneExpenses})
                    </Text>
                  </View>
                </View>
              </View>
            )}
            <View style={styles.heightOfTen} />
            <View style={styles.blueBlockText}>
              <View style={{ ...styles.row, paddingTop: 6.5 }}>
                <View style={{ width: '60%' }}>
                  <Text style={styles.paddingBtm}>
                    Owner Net Profit{' '}
                    {payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) &&
                      payoutData.ownersRevenueSharePercentage}
                  </Text>
                </View>
                <View style={{ width: '40%', textAlign: 'right' }}>
                  <Text style={styles.paddingBtm}>
                    {getCurrency(payoutDetail)} {payoutData.nettProfit}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {footer}
        </Page>
      )}
      <Page size="A4" wrap={true} style={styles.page}>
        <View>
          <View>{headerDetails}</View>
          <View style={styles.title}>
            <Text>Detailed Reservation Breakdown</Text>
          </View>
          <View style={styles.heightOfTen} />
          <View style={{ ...styles.greyBlockText, ...styles.row }}>
            <Text style={{ paddingTop: 6.5, width: '15%' }}>Date</Text>
            <Text style={{ paddingTop: 6.5, width: '18%', textAlign: 'center' }}>Confirmation Code</Text>
            <Text style={{ paddingTop: 6.5, width: '18%', textAlign: 'center' }}>Source</Text>
            <Text style={{ paddingTop: 6.5, width: '18%', textAlign: 'center' }}>OTA Fee</Text>
            <Text style={{ paddingTop: 6.5, width: '28%' }}>Revenue Breakdown</Text>
          </View>
          <View style={styles.heightOfTen} />
          {detailedReservationBreakdown.length === 0 ? noReservation : detailedReservationBreakdown}
          <View style={styles.blueBlockText}>
            <View style={{ ...styles.row, paddingTop: 6.5 }}>
              <View style={{ width: '60%' }}>
                <Text style={styles.paddingBtm}>Total Reservation Revenue</Text>
              </View>
              <View style={{ width: '40%', textAlign: 'right' }}>
                <Text style={styles.paddingBtm}>
                  {getCurrency(payoutDetail)} {numberWithCommas(payoutData.totalRevenue)}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ height: 20 }} />
          <View style={styles.blueBlockText}>
            <View style={{ ...styles.row, paddingTop: 6.5 }}>
              <View style={{ width: '60%' }}>
                <Text style={styles.paddingBtm}>Total Number of Reservations</Text>
              </View>
              <View style={{ width: '40%', textAlign: 'right' }}>
                <Text style={styles.paddingBtm}>{payoutData.numberofReservations}</Text>
              </View>
            </View>
          </View>
        </View>
        {footer}
      </Page>
    </Document>
  );
};

const handleOnClickInvoicePayout = (
  integrationSourceConstants,
  packageTypeLabel,
  revenueShareLabel,
  selectedHostImage,
  selectedOwnerName,
  payoutDetail,
  calculateOtherCharges,
  setIsInvoicePayoutClicked,
  setInvoiceData
) => {
  setIsInvoicePayoutClicked(true);

  const invoiceData = generatePayoutDocument(
    integrationSourceConstants,
    packageTypeLabel,
    revenueShareLabel,
    selectedHostImage,
    selectedOwnerName,
    payoutDetail,
    calculateOtherCharges
  );

  setInvoiceData(invoiceData);
};

const PayoutInvoice = ({
  integrationSourceConstants,
  packageTypeConstants,
  revenueShareConstants,
  selectedHostImage,
  selectedOwnerName,
  payoutDetail,
  calculateOtherCharges
}) => {
  const [invoiceData, setInvoiceData] = useState(DEFAULT_DOCUMENT_DOM);
  const [isInvoicePayoutClicked, setIsInvoicePayoutClicked] = useState(false);

  const { packageTypeLabel, revenueShareLabel } = useFetchLabels(packageTypeConstants, revenueShareConstants, payoutDetail);

  return (
    <BlobProvider document={invoiceData} fileName="Invoice-Payout.pdf">
      {({ blob, url, loading, error }) => {
        const isDataLoaded = !!blob && blob.size > DEFAULT_DOCUMENT_SIZE;

        if (isInvoicePayoutClicked && isDataLoaded) {
          window.open(url, '_blank');
          setIsInvoicePayoutClicked(false);
        }

        return isInvoicePayoutClicked ? (
          <Button type="primary" disabled icon="loading">
            Loading...
          </Button>
        ) : (
          <Button
            id="inv-button1-payout"
            type="primary"
            onClick={() =>
              handleOnClickInvoicePayout(
                integrationSourceConstants,
                packageTypeLabel,
                revenueShareLabel,
                selectedHostImage,
                selectedOwnerName,
                payoutDetail,
                calculateOtherCharges,
                setIsInvoicePayoutClicked,
                setInvoiceData
              )
            }
          >
            Invoice Payout
          </Button>
        );
      }}
    </BlobProvider>
  );
};

PayoutInvoice.propTypes = {
  integrationSourceConstants: PropTypes.array.isRequired,
  packageTypeConstants: PropTypes.array.isRequired,
  revenueShareConstants: PropTypes.array.isRequired,
  selectedHostImage: PropTypes.string.isRequired,
  selectedOwnerName: PropTypes.string.isRequired,
  payoutDetail: PropTypes.object.isRequired,
  calculateOtherCharges: PropTypes.func.isRequired
};

export default PayoutInvoice;
