import React, { Component } from 'react';
import { Avatar, Collapse, Row } from 'antd';
import PropTypes from 'prop-types';

import PayoutCalendar from './components/PayoutCalendar/PayoutCalendar';

import './PayoutList.css';

const Panel = Collapse.Panel;

class PayoutList extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  };

  render() {
    let { data } = this.props;
    const customPanelStyle = {
      background: '#f7f7f7',
      borderRadius: 4,
      marginBottom: 24,
      border: 0,
      overflow: 'hidden'
    };
    let currentDate = data.year + (data.month < 10 ? '-0' : '-') + data.month + '-01';
    return (
      <Collapse accordion style={customPanelStyle}>
        {data.payoutCalendars.map((unit, idx) => {
          let image =
            unit.images && unit.images[0]
              ? unit.images[0].imageUrl
              : 'https://d1nhio0ox7pgb.cloudfront.net/_img/g_collection_png/standard/32x32/hotel.png';
          let dateData = {
            bookedDates: unit.bookedDates || [],
            blockedDates: unit.blockedDates || []
          };
          return (
            <Panel
              header={
                <React.Fragment>
                  <Row type="flex" className="collapseHead">
                    <Avatar src={image} shape="circle" size="medium" />
                    <span className="collapseTitle">{unit.name || unit.code}</span>
                  </Row>
                </React.Fragment>
              }
              key={idx}
            >
              <PayoutCalendar date={currentDate} data={dateData} />
            </Panel>
          );
        })}
      </Collapse>
    );
  }
}

export default PayoutList;
