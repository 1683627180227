import React, { Component } from 'react';
import { Card, Row } from 'antd';
import PropTypes from 'prop-types';

import RowFormCounter from '../RowFormCounter/RowFormCounter';
import FormBedrooms from '../FormBedrooms/FormBedrooms';
import styles from './SetupCard.module.css';

const FIELD_NAME_NO_OF_LIVING_ROOMS = 'livingrooms';
const FIELD_NAME_NO_OF_BATH_ROOMS = 'bathrooms';
const FIELD_NAME_BEDROOMS = 'bedrooms';

class SetupCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getBedLabel = bed => {
    const { bedTypes } = this.props;
    const bedTypeKeys = Object.keys(bedTypes);
    for (let i = 0; i < bedTypeKeys.length; i++) {
      const key = bedTypeKeys[i];
      if (String(bedTypes[key].code) === String(bed.type)) {
        return bedTypes[key].label;
      }
    }
    return 'Bed not found';
  };

  render() {
    const { form, cardClassname, defaultValues, bedTypes } = this.props;
    return (
      <Card title="Room Setup" className={cardClassname}>
        <p style={{ marginBottom: '12px', fontSize: '16px' }}>Number of Rooms</p>
        <RowFormCounter
          form={form}
          label="Living Rooms"
          name={FIELD_NAME_NO_OF_LIVING_ROOMS}
          defaultValue={defaultValues[FIELD_NAME_NO_OF_LIVING_ROOMS]}
        />
        <RowFormCounter form={form} label="Bathrooms" name={FIELD_NAME_NO_OF_BATH_ROOMS} defaultValue={defaultValues[FIELD_NAME_NO_OF_BATH_ROOMS]} />

        <p style={{ marginBottom: '12px', fontSize: '16px' }}>Room Arrangements</p>
        <Row type="flex" justify="space-between" className={styles.listArrangementContainer}>
          <FormBedrooms form={form} name={FIELD_NAME_BEDROOMS} defaultValue={defaultValues[FIELD_NAME_BEDROOMS]} bedTypes={bedTypes} />
        </Row>
      </Card>
    );
  }
}

SetupCard.propTypes = {
  bedTypes: PropTypes.object.isRequired,
  cardClassname: PropTypes.string.isRequired,
  defaultValues: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

SetupCard.defaultProps = {
  bedTypes: {},
  cardClassname: '',
  defaultValues: {},
  form: {}
};

export default SetupCard;
