import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'antd';

import styles from './SeasonalRuleColorPicker.module.css';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const addRules = requiredErrorMessage => {
  const rules = [];
  if (requiredErrorMessage && typeof requiredErrorMessage === 'string') {
    rules.push({
      required: true,
      message: requiredErrorMessage
    });
  }
  return rules;
};

const SeasonalRuleColorPicker = ({
  buttonStyle,
  defaultValue,
  form,
  formLabel,
  label,
  onChange,
  name,
  requiredErrorMessage,
  size,
  selections,
  shouldDisable
}) => {
  const rules = addRules(requiredErrorMessage);
  return (
    <Fragment>
      {!formLabel && <p className={styles.label}>{label}</p>}
      <FormItem label={formLabel} colon={false}>
        {form.getFieldDecorator(name, {
          rules: rules,
          initialValue: defaultValue
        })(
          <RadioGroup disabled={shouldDisable} name={name} buttonStyle={buttonStyle} size={size} onChange={onChange}>
            {selections.map(selection => {
              const checked = form.getFieldValue(name) === selection.value;
              return (
                <RadioButton
                  style={{
                    backgroundColor: `rgba(${selection.color[0]},${selection.color[1]},${selection.color[2]},${checked ? 0.3 : 0.1})`,
                    borderColor: checked ? `rgba(${selection.color[0]},${selection.color[1]},${selection.color[2]},0.75)` : `rgb(255,255,255)`
                  }}
                  key={selection.key}
                  value={selection.value}
                ></RadioButton>
              );
            })}
          </RadioGroup>
        )}
      </FormItem>
    </Fragment>
  );
};

SeasonalRuleColorPicker.propTypes = {
  buttonStyle: PropTypes.oneOf(['outline', 'solid']),
  form: PropTypes.object.isRequired,
  formLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  selections: PropTypes.array.isRequired,
  defaultValue: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  requiredErrorMessage: PropTypes.string,
  shouldDisable: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'large'])
};

SeasonalRuleColorPicker.defaultProps = {
  buttonStyle: 'outline',
  defaultValue: undefined,
  form: {},
  label: '',
  onChange: undefined,
  onSearch: undefined,
  placeholder: '',
  shouldDisable: false,
  requiredErrorMessage: '',
  size: 'default'
};

export default SeasonalRuleColorPicker;

/* --------------------------------------Bare Radio Button------------------------------------------- */
export const BareRadioButton = ({ options, defaultValue, buttonStyle, isDiabled, onChange }) => {
  defaultValue = defaultValue || options[0].value;

  return (
    <RadioGroup defaultValue={defaultValue} buttonStyle={buttonStyle} disabled={isDiabled} onChange={onChange}>
      {options.map(option => (
        <RadioButton key={option.value} value={option.value}>
          {option.label || option.value}
        </RadioButton>
      ))}
    </RadioGroup>
  );
};

BareRadioButton.propTypes = {
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string,
  buttonStyle: PropTypes.oneOf(['outline', 'solid']),
  isDiabled: PropTypes.bool,
  onChange: PropTypes.func
};

BareRadioButton.defaultProps = {
  buttonStyle: 'solid',
  isDiabled: false,
  onChange: undefined
};
/* --------------------------------------Bare Radio Button------------------------------------------- */
