import React, { Component } from 'react';
import UserProfileDisplay from './../../components/UserProfileDisplay/UserProfileDisplay';
import './UserProfile.css';

class UserProfile extends Component {
  render() {
    return <UserProfileDisplay id={this.props.match.params.id} />;
  }
}

export default UserProfile;
