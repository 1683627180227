import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'antd';

import ListingWizardLayout from 'layouts/ListingWizardLayout/ListingWizardLayout';
import FormTimePicker from 'components/FormTimePicker/FormTimePicker';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

import styles from './Accommodation.module.css';

class Accommodation extends React.Component {
  handleOnSubmit = e => {
    e.preventDefault();
    const { form, onSave } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values = {
          ...values,
          checkInTime: values['checkInTime'].format('HH:mm'),
          checkOutTime: values['checkOutTime'].format('HH:mm')
        };
        onSave(values);
      }
    });
  };

  handleOnBackClick = e => {
    e.preventDefault();
    const { form, onBack } = this.props;
    const allFieldsValue = form.getFieldsValue();
    const values = {
      ...allFieldsValue,
      checkInTime: allFieldsValue['checkInTime'].format('HH:mm'),
      checkOutTime: allFieldsValue['checkOutTime'].format('HH:mm')
    };
    onBack(values);
  };

  checkMaxIsLongerThanMin = (rule, value, callback) => {
    const form = this.props.form;
    if (Number(value) < Number(form.getFieldValue('minNight'))) {
      callback('Max night should be more than min night');
    } else {
      callback();
    }
  };

  validateMaxNight = (rule, value, callback) => {
    const form = this.props.form;
    form.validateFields(['maxNight'], { force: true });
    callback();
  };

  render() {
    const { form, defaultValues, isLastPage, percentage, title } = this.props;
    return (
      <ListingWizardLayout
        isLastPage={isLastPage}
        percentage={percentage}
        title={title}
        onBackButtonClick={this.handleOnBackClick}
        onNextButtonClick={this.handleOnSubmit}
      >
        <Row type="flex" justify="space-between" className={styles.formTitleContainer}>
          <h2 className={styles.formTitle}>When can your guest check in and check out ?</h2>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={12} className={styles.formItem}>
            <FormTimePicker
              form={form}
              label="Check in time"
              fieldName="checkInTime"
              ruleMessage="Please provide check in time."
              defaultValue={defaultValues.checkInTime ? defaultValues.checkInTime : '14:00'}
              size="large"
            />
          </Col>
          <Col span={11} className={styles.formItem}>
            <FormTimePicker
              form={form}
              label="Check out time"
              fieldName="checkOutTime"
              ruleMessage="Please provide check out time."
              defaultValue={defaultValues.checkOutTime ? defaultValues.checkOutTime : '12:00'}
              size="large"
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formTitleContainer}>
          <h2 className={styles.formTitle}>How long can a guest stay ?</h2>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={12} className={styles.formItem}>
            <FormInputNumber
              form={form}
              label="Min. night(s)"
              name="minNight"
              placeholder="Leave empty for no limit"
              defaultValue={defaultValues.minNight}
              minValue={1}
              extraRules={[
                {
                  validator: this.validateMaxNight
                }
              ]}
              size="large"
            />
          </Col>
          <Col span={11} className={styles.formItem}>
            <FormInputNumber
              form={form}
              label="Max. night(s)"
              name="maxNight"
              placeholder="Leave empty for no limit"
              defaultValue={defaultValues.maxNight}
              minValue={1}
              extraRules={[
                {
                  validator: this.checkMaxIsLongerThanMin
                }
              ]}
              size="large"
            />
          </Col>
        </Row>
      </ListingWizardLayout>
    );
  }
}

Accommodation.propTypes = {
  defaultValues: PropTypes.object,
  form: PropTypes.object.isRequired,
  isLastPage: PropTypes.bool,
  onBack: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  percentage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
};

Accommodation.defaultProps = {
  isLastPage: false
};

const WrappedFormAccommodation = Form.create()(Accommodation);

export default WrappedFormAccommodation;
