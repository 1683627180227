import React from 'react';
import { Skeleton } from 'antd';

import Filter from '../components/Filter/Filter';
import Table from '../components/Table/Table';

import { getIntegrationTypesConstant } from 'utils/general';
const { LISTING_TYPE } = getIntegrationTypesConstant();

const ListingIntegration = ({
  isLoadingDefaultData,
  isLoadingListingIntegration,
  otaSyncStatusConstants,
  propertyOptions,
  unitOptions,
  listingIntegrations,
  selectedPropertyId,
  selectedUnitId,
  onChangeProperty,
  onChangeUnit,
  onClickButtonSeasonal,
  onClickButtonCreateEdit,
  onClickButtonDelete,
  checkIsAdminReadOnly
}) => {
  return (
    <Skeleton active loading={isLoadingDefaultData}>
      <Filter
        type={LISTING_TYPE.code}
        propertyOptions={propertyOptions}
        unitOptions={unitOptions}
        selectedPropertyId={selectedPropertyId}
        selectedUnitId={selectedUnitId}
        onChangeProperty={onChangeProperty}
        onChangeUnit={onChangeUnit}
      />
      <Skeleton active loading={isLoadingListingIntegration}>
        <Table
          otaSyncStatusConstants={otaSyncStatusConstants}
          integrations={listingIntegrations}
          type={LISTING_TYPE.code}
          onClickButtonSeasonal={onClickButtonSeasonal}
          onClickButtonCreateEdit={onClickButtonCreateEdit}
          onClickButtonDelete={onClickButtonDelete}
          checkIsAdminReadOnly={checkIsAdminReadOnly}
        />
      </Skeleton>
    </Skeleton>
  );
};

export default ListingIntegration;
