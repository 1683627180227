import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from 'antd';

import { withAppContext } from 'context/AppContext';
import { REACT_APP_IS_SHOW_PROMOTION } from 'config/env';

import { BareRadioButton } from 'components/FormRadioButton/FormRadioButton';

import Standard from './Standard/Standard';
import Promotion from './Promotion/Promotion';

import styles from './RateModifier.module.css';

const RATE_MODIFIER_TYPES = {
  STANDARD: { value: 'standard', label: 'Standard', id: 'tab1-standard' },
  PROMOTION: { value: 'promotion', label: 'Promotion', id: 'tab2-promotion' }
};

const useSelectRateModifierTypes = (
  checkIsAdmin,
  isAllowedBookingEngine,
  isAllowedHotelBookingEngine,
  checkIsAllowCreateRateModifier,
  checkIsAllowEditRateModifier,
  checkIsAllowDeleteRateModifier
) => {
  const isShowPromotion = checkIsAdmin() || isAllowedBookingEngine === true || isAllowedHotelBookingEngine === true;
  // useMemo(
  //   () => REACT_APP_IS_SHOW_PROMOTION && (checkIsAdmin() || (isAllowedBookingEngine === true || isAllowedHotelBookingEngine === true)),
  //   [checkIsAdmin, isAllowedBookingEngine, isAllowedHotelBookingEngine]
  // );

  const [selectedRateModifierType, setSelectedRateModifierType] = useState('');

  const constructPermittedRateModifierTypes = useCallback(() => {
    const rateModifierTypes = [RATE_MODIFIER_TYPES.STANDARD, isShowPromotion && RATE_MODIFIER_TYPES.PROMOTION];

    return rateModifierTypes.filter(data => !!data);
  }, [isShowPromotion]);

  const permittedRateModifierTypes = useMemo(() => constructPermittedRateModifierTypes(), [constructPermittedRateModifierTypes]);

  useEffect(() => {
    setSelectedRateModifierType(permittedRateModifierTypes[0].value);
  }, [permittedRateModifierTypes]);

  const isAllowCreateRateModifier = useMemo(() => checkIsAdmin() || checkIsAllowCreateRateModifier() === true, [
    checkIsAdmin,
    checkIsAllowCreateRateModifier
  ]);
  const isAllowEditRateModifier = useMemo(() => checkIsAdmin() || checkIsAllowEditRateModifier() === true, [
    checkIsAdmin,
    checkIsAllowEditRateModifier
  ]);
  const isAllowDeleteRateModifier = useMemo(() => checkIsAdmin() || checkIsAllowDeleteRateModifier() === true, [
    checkIsAdmin,
    checkIsAllowDeleteRateModifier
  ]);

  return {
    permittedRateModifierTypes,
    selectedRateModifierType,
    setSelectedRateModifierType,
    isShowPromotion,
    isAllowCreateRateModifier,
    isAllowEditRateModifier,
    isAllowDeleteRateModifier
  };
};

const RateModifier = ({
  checkIsAdmin,
  checkIsAdminReadOnly,
  isAllowedBookingEngine,
  isAllowedHotelBookingEngine,
  checkIsAllowCreateRateModifier,
  checkIsAllowEditRateModifier,
  checkIsAllowDeleteRateModifier
}) => {
  const {
    permittedRateModifierTypes,
    selectedRateModifierType,
    setSelectedRateModifierType,
    isShowPromotion,
    isAllowCreateRateModifier,
    isAllowEditRateModifier,
    isAllowDeleteRateModifier
  } = useSelectRateModifierTypes(
    checkIsAdmin,
    isAllowedBookingEngine,
    isAllowedHotelBookingEngine,
    checkIsAllowCreateRateModifier,
    checkIsAllowEditRateModifier,
    checkIsAllowDeleteRateModifier
  );

  return (
    <>
      {isShowPromotion && (
        <div className={styles.header}>
          <BareRadioButton options={permittedRateModifierTypes} onChange={e => setSelectedRateModifierType(e.target.value)} />
        </div>
      )}

      <div>
        <Card className="list-card">
          {selectedRateModifierType === RATE_MODIFIER_TYPES.STANDARD.value && (
            <Standard
              isAllowCreateRateModifier={isAllowCreateRateModifier}
              isAllowEditRateModifier={isAllowEditRateModifier}
              isAllowDeleteRateModifier={isAllowDeleteRateModifier}
              checkIsAdminReadOnly={checkIsAdminReadOnly}
            />
          )}
          {selectedRateModifierType === RATE_MODIFIER_TYPES.PROMOTION.value && (
            <Promotion
              checkIsAdmin={checkIsAdmin}
              isAllowCreateRateModifier={isAllowCreateRateModifier}
              isAllowEditRateModifier={isAllowEditRateModifier}
              isAllowDeleteRateModifier={isAllowDeleteRateModifier}
              checkIsAdminReadOnly={checkIsAdminReadOnly}
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default withAppContext(RateModifier);
