import React from 'react';
import { Modal } from 'antd';

const ConfirmationModal = ({ title, message, visible, cancelText, okText, onCancel, onOk }) => {
  return (
    <Modal title={title} visible={visible} onOk={onOk} onCancel={onCancel} okText={okText} cancelText={cancelText}>
      <p>{message}</p>
    </Modal>
  );
};

export default ConfirmationModal;
