import React from 'react';
import { Card, Alert, Button, Row } from 'antd';
import PropTypes from 'prop-types';

import FormSelection from 'components/FormSelection/FormSelection';
import styles from './HostRelationshipCard.module.css';

class HostRelationshipCard extends React.Component {
  render() {
    const { form, childrenList, children } = this.props;
    return (
      <Card title="Relationship Details" className={styles.hostRelationshipCard}>
        <Alert
          className={styles.notes}
          message="Tips"
          description={
            <div>
              <p className={styles.notesText}>Setup your host's multi level relationship here!</p>
            </div>
          }
          type="info"
          showIcon
        />
        <Card>
          <Row>
            <FormSelection
              name="children"
              label="Child Host"
              placeholder="Choose a host"
              form={form}
              multipleMode
              defaultValue={children && children.map(child => child)}
              selections={childrenList.map(host => {
                return {
                  key: host._id,
                  value: host.name
                };
              })}
              size="large"
            />
          </Row>
          <Button
            onClick={() => {
              form.setFieldsValue({ children: [] });
            }}
            size="large"
          >
            Remove All Children
          </Button>
        </Card>
      </Card>
    );
  }
}

HostRelationshipCard.propTypes = {
  form: PropTypes.object.isRequired
};

export default HostRelationshipCard;
