import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Icon, Button } from 'antd';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';

import { REACT_APP_ENDPOINT } from 'config/env';

import styles from './UploadFile.module.css';

const uploadOptions = {
  server: REACT_APP_ENDPOINT,
  signingUrlQueryParams: { uploadType: 'avatar' }
};
const s3Url = `https://${process.env.REACT_APP_S3_IMAGES_BUCKET || 'nebula-local-images'}.s3.amazonaws.com/`;

class UploadFile extends React.Component {
  render() {
    const { file, buttonText, containerClassname, onUploadFinish, onFileDelete } = this.props;
    return (
      <Row type="flex" align="middle" className={containerClassname}>
        <div className={styles.uploadContainer}>
          <Col span={24} className={styles.fileContainer}>
            <p className={styles.fileIcon}>
              <Icon type="file-protect" />
            </p>
            <DropzoneS3Uploader onFinish={onUploadFinish} s3Url={s3Url} upload={uploadOptions} className={styles.uploadButton}>
              <Button className={styles.button}>
                <Icon type="upload" /> {buttonText}
              </Button>
            </DropzoneS3Uploader>
            <Col className={styles.fileList}>
              <a href={file.link}>{file.name}</a>
              {Object.getOwnPropertyNames(file).length > 0 && (
                <span className={styles.delete}>
                  <Icon onClick={onFileDelete} type="close" />
                </span>
              )}
            </Col>
          </Col>
        </div>
      </Row>
    );
  }
}

UploadFile.propTypes = {
  onUploadFinish: PropTypes.func.isRequired,
  containerClassname: PropTypes.string,
  file: PropTypes.object
};

UploadFile.defaultProps = {
  containerClassname: '',
  file: {},
  onUploadFinish: () => {}
};

export default UploadFile;
