import React, { useState, useEffect } from 'react';
import { withAppContext } from 'context/AppContext';
import { Card, Form, Row, Col, Table, Button, Modal, notification } from 'antd';

import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import { getColumnFilterSearchProps, getColumnSorterProps } from 'components/Table/Table';

import { createPullTiketcom, getTiketcoms, putUpdateTiketcom } from 'utils/apis/integration';
import { getHosts } from 'utils/apis/host';
import { guard } from 'utils/general';

const MESSAGE_TYPE = {
  SUCCESS_UPDATE: 'successUpdate',
  ERROR_UPDATE: 'errorUpdate',
  SUCCESS_PULL: 'successPull',
  ERROR_PULL: 'errorPull'
};

const useFetchConstant = () => {
  const [hostLists, setHostLists] = useState([]);

  useEffect(() => {
    getHosts().then(response => {
      const hostLists = response.data.map(host => ({
        label: host.name,
        value: host._id
      }));

      setHostLists(hostLists);
    });
  }, []);

  return { hostLists };
};

const useFetchTiketcomListings = () => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [tiketcomListings, setTiketcomListings] = useState([]);
  const [refetchListings, setRefetchListings] = useState(false);

  useEffect(() => {
    setIsTableLoading(true);
    getTiketcoms({ fields: ['tiketcomPropertyId', 'tiketcomPropertyName', 'tiketcomActualPropertyId', 'status', 'host', 'rooms'] }).then(
      tiketcomListings => {
        const formatIntoTableSetting = tiketcomListings.map(listing => ({
          _id: listing._id,
          tiketcomPropertyId: listing.tiketcomPropertyId,
          tiketcomActualPropertyId: listing.tiketcomActualPropertyId,
          tiketcomPropertyName: listing.tiketcomPropertyName,
          status: listing.status,
          host: listing.host,
          rooms: listing.rooms.length
        }));
        // console.log(55, tiketcomListings);
        setTiketcomListings(formatIntoTableSetting);
        setIsTableLoading(false);
        setRefetchListings(false);
      }
    );
  }, [refetchListings]);

  const refetchTiketcomListings = isRefetch => {
    setRefetchListings(isRefetch);
  };

  return { isTableLoading, tiketcomListings, refetchTiketcomListings };
};

const getTableColumns = (filtersInfo, sorterInfo, handleOnShowEditModal, tiketcomListings, isAllowEdit) => {
  const tableColumns = [];

  tableColumns.push({
    title: 'Tiket.com Property Id',
    dataIndex: 'tiketcomPropertyId',
    key: 'tiketcomPropertyId',
    ...getColumnFilterSearchProps('tiketcomPropertyId', 'Tiket.com Property Id', { filtersInfo }),
    ...getColumnSorterProps('tiketcomPropertyId', { sorterInfo })
  });

  // tableColumns.push({
  //   title: 'Tiket.com Property Id',
  //   dataIndex: 'tiketcomActualPropertyId',
  //   key: 'tiketcomActualPropertyId',
  //   ...getColumnFilterSearchProps('tiketcomActualPropertyId', 'Tiket.com Property Id', { filtersInfo }),
  //   ...getColumnSorterProps('tiketcomActualPropertyId', { sorterInfo }),
  //   render: (text, record) => record.tiketcomActualPropertyId || '-'
  // });

  tableColumns.push({
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: Array.from(new Set(tiketcomListings.map(listing => listing.status))).map(item => ({ text: item, value: item })),
    onFilter: (value, record) => record.status === value
  });

  tableColumns.push({
    title: 'Tiket.com Property Name',
    dataIndex: 'tiketcomPropertyName',
    key: 'tiketcomPropertyName',
    ...getColumnFilterSearchProps('tiketcomPropertyName', 'Tiket.com Property Name', { filtersInfo }),
    ...getColumnSorterProps('tiketcomPropertyName', { sorterInfo }),
    render: (text, record) => record.tiketcomPropertyName || '-'
  });

  tableColumns.push({
    title: 'Host',
    dataIndex: 'host',
    key: 'host',
    ...getColumnFilterSearchProps('host', 'Host', { filtersInfo }),
    ...getColumnSorterProps('host', { sorterInfo }),
    render: (text, record) => guard(() => text.name, '')
  });

  tableColumns.push({
    title: 'Tiket.com Rooms',
    dataIndex: 'rooms',
    key: 'rooms',
    ...getColumnSorterProps('rooms', { sorterInfo })
  });

  tableColumns.push({
    title: 'Actions',
    key: 'action',
    render: (text, record) => {
      return (
        <Button onClick={handleOnShowEditModal(record)} disabled={!isAllowEdit}>
          Edit
        </Button>
      );
    }
  });

  return tableColumns;
};

const generateNotificationMessage = (messageType, tiketcomPropertyId = null, error = null) => {
  switch (messageType) {
    case MESSAGE_TYPE.SUCCESS_PULL:
      return {
        message: `Succesfully retrieve Hotel (${tiketcomPropertyId})`,
        description: <div>Please assign a host to this Tiket.com Hotel</div>
      };
    case MESSAGE_TYPE.ERROR_PULL:
      return {
        message: `Failed to retrieve Tiket.com Hotel`,
        description: (
          <div>
            The provided hotel Id ({tiketcomPropertyId}) is not associated with Host Platform.
            <div>
              If you are sure it should, please contact{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://api.whatsapp.com/send?phone=60162537829&text=Hello,%20I%20have%20a%20question%20about%20http%3A%2F%2Fplatform.hostastay.com%2Fintegrations%2Ftiketcom-connect"
              >
                customer support
              </a>
              !
            </div>
          </div>
        )
      };
    case MESSAGE_TYPE.SUCCESS_UPDATE:
      return {
        message: `Nice One`,
        description: (
          <div>
            <p>
              <b>Succesfully added Host to Tiket.com hotel</b>
            </p>
          </div>
        )
      };
    case MESSAGE_TYPE.ERROR_UPDATE:
      return {
        message: `Oh Crap`,
        description: (
          <div>
            <p>
              <b>Failed to update Tiket.com hotel</b>
            </p>
            Try refreshing the page. If problem presist, contact{' '}
            <a target="_blank" rel="noreferrer noopener" href="http://hostastay.com/helpdesk-cs/">
              tech support
            </a>
            !
          </div>
        )
      };
    default:
  }
};

const TiketcomListing = ({ form, checkIsAllowCreateIntegration, checkIsAllowEditIntegration }) => {
  const { hostLists } = useFetchConstant();
  const { isTableLoading, tiketcomListings, refetchTiketcomListings } = useFetchTiketcomListings();

  const [filtersInfo, setFilters] = useState({});
  const [sorterInfo, setSorter] = useState({});
  const [isShowModal, setIsShowModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [selectedTiketcom, setSelectedTiketcom] = useState({});

  const isAllowCreate = !!checkIsAllowCreateIntegration();
  const isAllowEdit = !!checkIsAllowEditIntegration();

  const handleOnTableChange = (filtersInfo, sorterInfo) => {
    setFilters(filtersInfo);
    setSorter(sorterInfo);
  };

  const handleOnOpenCloseModal = isShow => {
    setIsShowModal(isShow);

    if (!isShow) {
      setSelectedTiketcom({});
    }
  };

  const handleOnClickEdit = tiketcom => e => {
    setSelectedTiketcom(tiketcom);
    handleOnOpenCloseModal(true);
  };

  const handleOnModalNewSave = e => {
    e.preventDefault();
    setIsModalLoading(true);

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        return createPullTiketcom(values.tiketcomPropertyId)
          .then(tiketcom => {
            const successPullMessage = generateNotificationMessage(MESSAGE_TYPE.SUCCESS_PULL, values.tiketcomPropertyId);
            notification.success(successPullMessage);

            refetchTiketcomListings(true);

            setIsModalLoading(false);
            setSelectedTiketcom(tiketcom);
          })
          .catch(ex => {
            const errorPullMessage = generateNotificationMessage(MESSAGE_TYPE.ERROR_PULL, values.tiketcomPropertyId, ex);
            notification.error(errorPullMessage);

            setIsModalLoading(false);
          });
      }
    });
  };

  const handleOnModalEditSave = e => {
    e.preventDefault();

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const tiketcomaId = selectedTiketcom._id;

        return putUpdateTiketcom(tiketcomaId, values)
          .then(() => {
            const successUpdateMessage = generateNotificationMessage(MESSAGE_TYPE.SUCCESS_UPDATE);
            notification.success(successUpdateMessage);

            setIsModalLoading(false);
            handleOnOpenCloseModal(false);

            refetchTiketcomListings(true);
          })
          .catch(ex => {
            const errorUpdateMessage = generateNotificationMessage(MESSAGE_TYPE.ERROR_UPDATE);
            notification.error(errorUpdateMessage);

            setIsModalLoading(false);
          });
      }
    });
  };

  const isEditMode = Object.entries(selectedTiketcom).length !== 0;

  return (
    <Card>
      <Row gutter={[0, 16]}>
        <Col>
          <Button type="primary" icon="plus" onClick={() => handleOnOpenCloseModal(true)} disabled={!isAllowCreate}>
            New Tiket.com
          </Button>
        </Col>
        <Col>
          <Table
            rowKey={record => record._id}
            dataSource={tiketcomListings}
            columns={getTableColumns(filtersInfo, sorterInfo, handleOnClickEdit, tiketcomListings, isAllowEdit)}
            onChange={(pagination, filtersInfo, sorterInfo) => {
              handleOnTableChange(filtersInfo, sorterInfo);
            }}
            loading={isTableLoading}
          />
        </Col>
      </Row>

      {isEditMode ? (
        <Modal
          title={`Editing Tiket.com Property (${selectedTiketcom.tiketcomPropertyId})`}
          visible={isShowModal}
          onOk={handleOnModalEditSave}
          onCancel={() => handleOnOpenCloseModal(false)}
          onText="Save"
          confirmLoading={isModalLoading}
          destroyOnClose={true}
        >
          <FormInput
            name="tiketcomPropertyName"
            formLabel="Assign a property nickname:"
            placeholder="Please input property nickname"
            form={form}
            defaultValue={selectedTiketcom.tiketcomPropertyName}
            requiredErrorMessage="Please input a property nickname"
            disabled={isModalLoading}
          />
          {/* <FormInput
            name="tiketcomActualPropertyId"
            formLabel="Input tiketcom property Id (example: 441231846) :"
            placeholder="Please input tiketcom property Id"
            form={form}
            defaultValue={selectedTiketcom.tiketcomActualPropertyId}
            requiredErrorMessage="Please input tiketcom property Id"
            disabled={isModalLoading}
          /> */}
          <FormSelection
            name="host"
            formLabel="Assign a host:"
            placeholder="Please select and assign a host"
            form={form}
            defaultValue={guard(() => selectedTiketcom.host._id, '')}
            requiredErrorMessage="Please select a host"
            selections={hostLists}
            disabled={isModalLoading}
            allowClear={false}
          />
        </Modal>
      ) : (
        <Modal
          title="New Tiketcom Property"
          visible={isShowModal}
          onOk={handleOnModalNewSave}
          onCancel={() => handleOnOpenCloseModal(false)}
          onText="Save"
          confirmLoading={isModalLoading}
          destroyOnClose={true}
        >
          <FormInput
            name="tiketcomPropertyId"
            formLabel="Tiket.com Property Id:"
            placeholder="Tiket.com property id (example: BCUrjagLmelB)"
            form={form}
            requiredErrorMessage="Please input tiketcom property id"
            disabled={isModalLoading}
          />
        </Modal>
      )}
    </Card>
  );
};

export default Form.create()(withAppContext(TiketcomListing));
