import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Button, Icon, Row, Table } from 'antd';
import moment from 'moment';

import { withAppContext } from 'context/AppContext';
import { useGetPaginatedInventoryForAdmin, useGetInventoryUsageForAdmin, useGetInventoryCsvDataForAdmin } from 'utils/apis/billingInventory';
import { guard } from 'utils/general';
import { constructColumn, constructColumnFilterSearch, handleOnAggregationTableChange } from 'utils/table/table';

import HostActionModal from './components/HostActionModal/HostActionModal';

import {
  BodyContainer,
  HostLink,
  InventoryContainer,
  StatisticBoxesContainer,
  StatisticBoxContainer,
  StatisticBoxTitle,
  StatisticBoxValue
} from './Overview.styles';

const useInventoryUsageStatistics = monthWithYear => {
  const postProcessInventoryUsage = useCallback(result => {
    return [
      { title: 'Normal', value: guard(() => result.liveType, 0) },
      { title: 'Phantom', value: guard(() => result.ghostType, 0) },
      { title: 'Demo', value: guard(() => result.demoType, 0) },
      { title: 'Entry Plan', value: guard(() => result.entryPlan, 0) },
      { title: 'Starter Plan', value: guard(() => result.starterPlan, 0) },
      { title: 'Standard Plan', value: guard(() => result.standardPlan, 0) },
      { title: 'Host Protect', value: guard(() => result.hostProtect, 0) }
    ];
  }, []);

  const { data, isLoading: isPlanUnitUsageStatsLoading } = useGetInventoryUsageForAdmin(monthWithYear);

  const inventoryUsageStatistics = useMemo(() => postProcessInventoryUsage(data), [data, postProcessInventoryUsage]);

  return { inventoryUsageStatistics, isPlanUnitUsageStatsLoading };
};

const purchasedPlanRenderProps = planType => ({
  render: (text, record) => `${text} / ${record.hostPurchasedPlan[planType]}`
});

const constructColumns = (onHostClick, onActionButtonClick) => [
  {
    ...constructColumn('Host', 'hostName', { hasSorter: true }),
    ...constructColumnFilterSearch('hostName', 'Search Host'),
    render: (text, record) => {
      return (
        <HostLink type="link" onClick={onHostClick(record.hostId)}>
          {text}
        </HostLink>
      );
    }
  },
  {
    ...constructColumn('Entry (M)', 'noOfMonthlyEntryPlan', { hasSorter: true, isNumber: true })
  },
  {
    ...constructColumn('Starter (M)', 'noOfMonthlyStarterPlan', { hasSorter: true, isNumber: true })
  },
  {
    ...constructColumn('Standard (M)', 'noOfMonthlyStandardPlan', { hasSorter: true, isNumber: true })
  },
  {
    ...constructColumn('Entry (Y)', 'noOfYearlyEntryPlan', { hasSorter: true, isNumber: true }),
    ...purchasedPlanRenderProps('entry')
  },
  {
    ...constructColumn('Starter (Y)', 'noOfYearlyStarterPlan', { hasSorter: true, isNumber: true }),
    ...purchasedPlanRenderProps('starter')
  },
  {
    ...constructColumn('Standard (Y)', 'noOfYearlyStandardPlan', { hasSorter: true, isNumber: true }),
    ...purchasedPlanRenderProps('standard')
  },
  {
    ...constructColumn('HostProtect', 'noOfHostProtect', { hasSorter: true, isNumber: true }),
    ...purchasedPlanRenderProps('hostProtect')
  },
  constructColumn('Free Unit', 'noOfFreeUnit', { hasSorter: true, isNumber: true }),
  {
    ...constructColumn('Actions', 'hostId'),
    render: hostId => {
      return (
        <Button type="primary" onClick={onActionButtonClick(hostId)}>
          <Icon type="edit" />
        </Button>
      );
    }
  }
];

const StatisticBoxes = ({ isPlanUnitUsageStatsLoading, inventoryUsageStatistics }) => {
  return (
    <StatisticBoxesContainer>
      {inventoryUsageStatistics.map(statistic => (
        <StatisticBoxContainer key={statistic.title}>
          <StatisticBoxTitle span={24}>{statistic.title}</StatisticBoxTitle>
          <StatisticBoxValue span={24}>{isPlanUnitUsageStatsLoading ? <Icon type="loading" spin /> : statistic.value}</StatisticBoxValue>
        </StatisticBoxContainer>
      ))}
    </StatisticBoxesContainer>
  );
};

const DownloadCsvButton = ({ monthWithYear }) => {
  const { data: planStatsCsvData, isLoading: isPlanStatsCsvDataLoading } = useGetInventoryCsvDataForAdmin(monthWithYear);

  const stringMonthYear = moment(monthWithYear).format('MMM YYYY');
  const filename = `${stringMonthYear} - Host Plan Statistics Reporting.csv`;

  return (
    <CSVLink filename={filename} data={planStatsCsvData || []}>
      <Button type="primary" icon="download" loading={isPlanStatsCsvDataLoading}>
        {isPlanStatsCsvDataLoading ? 'Fetching...' : 'Download CSV'}
      </Button>
    </CSVLink>
  );
};

const Overview = ({ monthWithYear, onHostClick }) => {
  const [query, setQuery] = useState({});
  const [isFetchCsvButtonClicked, setIsFetchCsvButtonClicked] = useState(false);
  const [showHostActionModal, setShowHostActionModal] = useState(false);
  const [selectedHostId, setSelectedHostId] = useState();

  const { paginatedData: productStatistics, total, isLoading: isProductStatisticsLoading, refetch } = useGetPaginatedInventoryForAdmin({
    ...query,
    monthWithYear
  });
  const { inventoryUsageStatistics, isPlanUnitUsageStatsLoading } = useInventoryUsageStatistics(monthWithYear);

  useEffect(() => {
    setIsFetchCsvButtonClicked(false);
  }, [monthWithYear]);

  const handleOnActionButtonClick = hostId => () => {
    setSelectedHostId(hostId);
    setShowHostActionModal(true);
  };

  const handleOnActionModalOk = () => {
    setShowHostActionModal(false);
    refetch();
  };

  return (
    <InventoryContainer>
      <BodyContainer>
        <StatisticBoxes isPlanUnitUsageStatsLoading={isPlanUnitUsageStatsLoading} inventoryUsageStatistics={inventoryUsageStatistics} />
        <Row type="flex" justify="end" style={{ marginBottom: 20, marginTop: 40 }}>
          {isFetchCsvButtonClicked ? (
            <DownloadCsvButton monthWithYear={monthWithYear} />
          ) : (
            <Button type="primary" icon="download" onClick={() => setIsFetchCsvButtonClicked(true)}>
              Fetch CSV Data
            </Button>
          )}
        </Row>
        <Table
          loading={isProductStatisticsLoading}
          rowKey={record => record._id}
          dataSource={productStatistics}
          columns={constructColumns(onHostClick, handleOnActionButtonClick)}
          pagination={{ total }}
          scroll={{ x: 1000 }}
          onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
        />
      </BodyContainer>
      {showHostActionModal && <HostActionModal hostId={selectedHostId} onOk={handleOnActionModalOk} onCancel={() => setShowHostActionModal(false)} />}
    </InventoryContainer>
  );
};

export default withAppContext(Overview);
