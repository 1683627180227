import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Skeleton } from 'antd';

const AdvancedOptionsModal = ({
  isVisible,
  isLoading,

  onSave,
  onClose,

  children
}) => {
  return (
    <Modal
      title="Advanced Options"
      visible={isVisible}
      onOk={onSave}
      onCancel={onClose}
      okText="Save"
      cancelText="Cancel"
      width="60%"
      closable={!isLoading}
      maskClosable={false}
      confirmLoading={isLoading}
      cancelButtonProps={{ disabled: isLoading }}
      centered
      bodyStyle={{ height: '75vh' }}
    >
      {isLoading ? <Skeleton active={true} /> : children}
    </Modal>
  );
};

AdvancedOptionsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

AdvancedOptionsModal.defaultProps = {
  isLoading: false,
  onSave: () => {}
};

export default AdvancedOptionsModal;
