import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Form, Row, Col, DatePicker, Tooltip, Icon } from 'antd';

import ListingWizardLayout from 'layouts/ListingWizardLayout/ListingWizardLayout';
import FormSelection from 'components/FormSelection/FormSelection';

import ColMobileSeperator from '../../components/ColMobileSeperator/ColMobileSeperator';

import styles from './ContractInformation.module.css';

const FormItem = Form.Item;

const FORM_ITEM_KEY_CONTRACT_START = 'contractPeriodStart';
const FORM_ITEM_KEY_CONTRACT_END = 'contractPeriodEnd';

const populateOwnerList = owners => {
  return owners.map(owner => ({
    key: owner._id,
    value: `${owner.name} (${owner.username})`
  }));
};

class ContractInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ownerList: [],
      shouldShowDate: !!props.defaultValues.selectedOwner
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Set owners state with api data
    let newState = null;
    if (state.ownerList.length === 0 && props.hasFetchedOwners && props.owners.length > 0) {
      newState = {
        ownerList: populateOwnerList(props.owners)
      };
    }
    return newState;
  }

  handleOnOwnerChange = value => {
    if (!value) {
      this.resetContractDate();
    } else {
      this.setState(prevState => {
        // show the date
        if (!prevState.shouldShowDate) {
          return {
            shouldShowDate: true
          };
        }
      });
    }
  };

  resetContractDate = () => {
    const { form } = this.props;
    form.setFieldsValue({
      [FORM_ITEM_KEY_CONTRACT_START]: undefined,
      [FORM_ITEM_KEY_CONTRACT_END]: undefined
    });
    this.setState({
      shouldShowDate: false
    });
  };

  handleOnSearchOwner = value => {
    const { owners } = this.props;
    const ownerSearchList = populateOwnerList(owners);
    let newList = [];
    newList = ownerSearchList.filter(owner => owner.value.toLowerCase().includes(String(value).toLowerCase()));
    this.setState({
      ownerList: newList
    });
  };

  disabledStartDate = value => {
    const { form } = this.props;
    const { contractPeriodEnd } = form.getFieldsValue([FORM_ITEM_KEY_CONTRACT_END]);
    if (!value || !contractPeriodEnd) {
      return false;
    }
    return value.valueOf() > contractPeriodEnd.valueOf();
  };

  disabledEndDate = value => {
    const { form } = this.props;
    const { contractPeriodStart } = form.getFieldsValue([FORM_ITEM_KEY_CONTRACT_START]);
    if (!value || !contractPeriodStart) {
      return false;
    }
    return value.valueOf() <= contractPeriodStart.valueOf();
  };

  validateDate = (rule, value, callback) => {
    const { form } = this.props;
    let error;
    const endDate = form.getFieldValue(FORM_ITEM_KEY_CONTRACT_END);
    if (endDate && !value) {
      error = 'Please provide start date for the contract.';
    } else if (value && !endDate) {
      error = 'Please provide end date for the contract.';
      form.setFields({
        [FORM_ITEM_KEY_CONTRACT_END]: {
          value: endDate,
          errors: [new Error(error)]
        }
      });
    } else if (!value && !endDate) {
      form.setFields({
        [FORM_ITEM_KEY_CONTRACT_END]: {
          value: endDate,
          errors: undefined
        }
      });
    }
    callback(error);
  };

  triggerValidateDate = (rule, value, callback) => {
    const { form } = this.props;
    form.validateFields([FORM_ITEM_KEY_CONTRACT_START], { force: true }, (errors, value) => {
      if (errors) {
        callback(errors[FORM_ITEM_KEY_CONTRACT_START].errors[0].message);
      } else {
        callback();
      }
    });
    callback();
  };

  handleOnSubmit = e => {
    e.preventDefault();
    const { form, onSave } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSave({
          ...values,
          contractPeriod:
            values[FORM_ITEM_KEY_CONTRACT_START] && values[FORM_ITEM_KEY_CONTRACT_END]
              ? {
                  start: values[FORM_ITEM_KEY_CONTRACT_START].format('YYYY-MM-DD'),
                  end: values[FORM_ITEM_KEY_CONTRACT_END].format('YYYY-MM-DD')
                }
              : {}
        });
      }
    });
  };

  handleOnBackClick = e => {
    e.preventDefault();
    const { form, onBack } = this.props;
    const allFieldsValue = form.getFieldsValue();
    const values = {
      ...allFieldsValue,
      contractPeriod: {
        start: allFieldsValue[FORM_ITEM_KEY_CONTRACT_START] ? allFieldsValue[FORM_ITEM_KEY_CONTRACT_START].format('YYYY-MM-DD') : '',
        end: allFieldsValue[FORM_ITEM_KEY_CONTRACT_END] ? allFieldsValue[FORM_ITEM_KEY_CONTRACT_END].format('YYYY-MM-DD') : ''
      }
    };
    onBack(values);
  };

  render() {
    const { form, defaultValues, isLastPage, percentage, title } = this.props;
    const { ownerList, shouldShowDate } = this.state;
    return (
      <ListingWizardLayout
        isLastPage={isLastPage}
        percentage={percentage}
        title={title}
        onBackButtonClick={this.handleOnBackClick}
        onNextButtonClick={this.handleOnSubmit}
      >
        <Row type="flex" justify="space-between" className={styles.formTitleContainer}>
          <h2 className={styles.formTitle}>Contract Information</h2>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={24} className={styles.formItem}>
            <FormSelection
              label="Owner (if any)"
              name="selectedOwner"
              placeholder="Select one owner (if any)"
              form={form}
              defaultValue={defaultValues.selectedOwner}
              selections={ownerList}
              onChange={this.handleOnOwnerChange}
              onSearch={this.handleOnSearchOwner}
              size="large"
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer} style={shouldShowDate ? {} : { display: 'none' }}>
          <Col span={24} className={styles.formItem}>
            <p style={{ marginBottom: '8px' }}>
              Contract period{' '}
              <Tooltip title="This is required to calculate your payout">
                <Icon type="question-circle-o" />
              </Tooltip>
            </p>
            <Row type="flex" justify="space-between" className={styles.formItemContainer}>
              <Col span={24} md={11}>
                <FormItem>
                  {form.getFieldDecorator(FORM_ITEM_KEY_CONTRACT_START, {
                    rules: [{ validator: this.validateDate }],
                    initialValue:
                      defaultValues.contractPeriod && defaultValues.contractPeriod.start ? moment(defaultValues.contractPeriod.start) : undefined
                  })(<DatePicker disabledDate={this.disabledStartDate} placeholder="Start Date" size="large" style={{ width: '100%' }} />)}
                </FormItem>
              </Col>
              <ColMobileSeperator />
              <Col span={24} md={12}>
                <FormItem>
                  {form.getFieldDecorator(FORM_ITEM_KEY_CONTRACT_END, {
                    rules: [{ validator: this.triggerValidateDate }],
                    initialValue:
                      defaultValues.contractPeriod && defaultValues.contractPeriod.end ? moment(defaultValues.contractPeriod.end) : undefined
                  })(<DatePicker disabledDate={this.disabledEndDate} placeholder="End Date" size="large" style={{ width: '100%' }} />)}
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
      </ListingWizardLayout>
    );
  }
}

ContractInformation.propTypes = {
  form: PropTypes.object.isRequired,
  hasFetchedOwners: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  owners: PropTypes.array.isRequired,
  percentage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isLastPage: PropTypes.bool,
  onBack: PropTypes.func,
  defaultValues: PropTypes.object
};

ContractInformation.defaultProps = {
  isLastPage: false,
  hasFetchedOwners: false,
  owners: [],
  defaultValues: {}
};

const WrappedFormContractInformation = Form.create({})(ContractInformation);

export default WrappedFormContractInformation;
