import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

class MyGoogleMap extends React.Component {
  static propTypes = {
    lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  };

  static defaultProps = {
    lat: 4.2105,
    lng: 101.9758 //Malaysia
  };

  handleOnMarkerDragEnd = e => {
    const { onMarkerMove } = this.props;
    onMarkerMove({
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    });
  };

  render() {
    // return <div style={{ height: '300px', width: '100%' }} id="map" />;
    const { lat, lng } = this.props;
    return (
      <GoogleMap defaultZoom={16} defaultCenter={{ lat: -34.397, lng: 150.644 }} center={{ lat: lat, lng: lng }}>
        <Marker draggable={true} position={{ lat: lat, lng: lng }} onDragEnd={this.handleOnMarkerDragEnd} />
      </GoogleMap>
    );
  }
}

export default withGoogleMap(MyGoogleMap);
