import moment from 'moment';
import { message } from 'antd';
import ReactHtmlParser from 'react-html-parser';

import { CONTACT_NUMBER_REGEX, INTEGRATION_TYPES, REGEX_NOT_NUMBER_N_DOT } from 'utils/constants';

/* ------------------------------------------------General function------------------------------------------------- */
export const checkHasValue = value => {
  return value !== undefined && value !== null;
};

export const cloneNewObject = object => {
  return JSON.parse(JSON.stringify(object));
};

export const createNumArray = count => {
  let numArray = [];

  for (let i = 0; i < count; i++) {
    numArray.push(i);
  }
  return numArray;
};

export const checkIsObjectEmpty = object => {
  return Object.keys(object).length === 0;
};

export const checkIncludesAllElements = (bigArray, smallArray) => {
  for (let i = 0; i < smallArray.length; i++) {
    const element = smallArray[i];
    if (!bigArray.includes(element)) {
      return false;
    }
  }
  return true;
};

export const guard = (callback, fallbackValue) => {
  try {
    const value = callback();
    if (value === undefined || value === null) {
      return fallbackValue;
    }

    return value;
  } catch {
    return fallbackValue;
  }
};

// +++++++++++++++++++++++++Array
export const checkIsArray = array => {
  return Array.isArray(array);
};

export const flatArray = (initialArray, flatLevel = 1) => {
  let flattenedArray = [...initialArray];

  for (let i = 0; i < flatLevel; i++) {
    flattenedArray = flatOneLevelArray(flattenedArray);
  }

  return flattenedArray;
};

export const uniqueArray = a => {
  return [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s));
};

// +++++++++++++++++++++++++String
export const capitalizeFirstLetter = (str, options = { lowerOthers: false }) => {
  str = String(str);
  return options.lowerOthers ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : str.charAt(0).toUpperCase() + str.slice(1);
};

export const constructFullName = (firstName, lastName, middleName) => {
  let fullName = [];

  if (!!firstName) {
    fullName.push(capitalizeFirstLetter(firstName));
  }

  if (!!middleName) {
    fullName.push(capitalizeFirstLetter(middleName));
  }

  if (!!lastName) {
    fullName.push(capitalizeFirstLetter(lastName));
  }

  return fullName.join(' ');
};

// +++++++++++++++++++++++++Number
export const cleanNumber = numberString => numberString.replace(/\D/g, '');

export const constructNumberToArray = count => {
  let numArray = [];

  for (let i = 0; i < count; i++) {
    numArray.push(i);
  }
  return numArray;
};

export const generateNumberIndicator = number => {
  const numberInString = String(number);
  const lastDigit = numberInString.charAt(numberInString.length - 1);
  const lastSecondDigit = numberInString.charAt(numberInString.length - 2);

  if (lastSecondDigit === '1') {
    return 'th';
  } else {
    return lastDigit === '1' ? 'st' : lastDigit === '2' ? 'nd' : lastDigit === '3' ? 'rd' : 'th';
  }
};

export const numberWithCommas = (num, dp = 2) => {
  let formattedNum = num;
  if (!isNaN(formattedNum)) {
    formattedNum = Number(formattedNum).toFixed(dp);
  } else {
    formattedNum = Number(0).toFixed(dp);
  }
  return formattedNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const rounds = (value, decimals) => {
  return Number(`${Math.round(`${Number(value).toFixed(20)}e${decimals}`)}e-${decimals}`);
};

// +++++++++++++++++++++++++Date
export const checkIsOverlapped = (dateA1, dateA2, dateB1, dateB2) => {
  const startDateA = dateA1 < dateA2 ? dateA1 : dateA2;
  const endDateA = dateA1 < dateA2 ? dateA2 : dateA1;

  const startDateB = dateB1 < dateB2 ? dateB1 : dateB2;
  const endDateB = dateB1 < dateB2 ? dateB2 : dateB1;

  return startDateA <= endDateB && startDateB <= endDateA;
};

export const dateToISO8601String = inputDate => {
  if (inputDate.constructor.name !== 'Date') {
    return console.log('Input must be date');
  } else {
    return (
      inputDate.getFullYear() +
      '-' +
      (inputDate.getMonth() + 1 >= 10 ? inputDate.getMonth() + 1 : '0' + (inputDate.getMonth() + 1)) +
      '-' +
      (inputDate.getDate() >= 10 ? inputDate.getDate() : '0' + inputDate.getDate())
    );
  }
};

export const differenceBetweenDates = (date1, date2) => {
  if (date1 > date2) {
    let temp = date2;
    date2 = date1;
    date1 = temp;
  }
  return (new Date(date2) - new Date(date1)) / 86400000;
};

export const generateEndDate = (startDate, { monthNumber }, dayToSubtract = 0) => {
  const type = !!monthNumber ? 'month' : '';

  const endDate = moment(startDate)
    .add(monthNumber, type)
    .subtract(dayToSubtract, 'days');

  return endDate;
};

/* ------------------------------------------------Regex related------------------------------------------------- */
export const checkValidContactNumber = number => {
  return CONTACT_NUMBER_REGEX.test(number);
};

export const checkValidStrongPassword = password => {
  // Both FE and BE shared the same policy, if you are updating the password policy, please update both
  return new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{12,30}$').test(password);
};

export const constructTwoDecimalsRule = (errorMessage = 'Only allow numeric input with max 2 decimal points') => {
  return {
    pattern: /^(\d+|\d{1,3}(,\d{3})*)(\.?\d{1,2})?$/,
    message: errorMessage
  };
};

export const checkIsValidUrl = url => /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(url);

export const removeNonNumbers = num => String(num).replace(/[^0-9]/g, '');

/* ------------------------------------------------Specific function------------------------------------------------- */
// Rate
export const constructDisplayNumber = (number, decimal = 2) => {
  const numberWithDecimal = Number(number).toFixed(decimal);
  const numberWithDecimalAndComma = numberWithDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return numberWithDecimalAndComma;
};

export const constructDisplayPrice = (feeAmount = 0, prefix = '' /* prefix = 'RM' */) => {
  const displayFee = String(constructDisplayNumber(feeAmount));
  const displayFeeWithPrefix = prefix ? `${prefix} ${displayFee}` : displayFee;

  return displayFeeWithPrefix;
};

export const constructShortenedFinancialNumber = (number, prefix = 'RM') => {
  if (number >= 1000000000000) {
    return (prefix ? `${prefix} ` : '') + (number / 1000000000000).toFixed(1).replace(/\.0$/, '') + ' Tril';
  }
  if (number >= 1000000000) {
    return (prefix ? `${prefix} ` : '') + (number / 1000000000).toFixed(1).replace(/\.0$/, '') + ' Bil';
  }
  if (number >= 1000000) {
    return (prefix ? `${prefix} ` : '') + (number / 1000000).toFixed(1).replace(/\.0$/, '') + ' Mil';
  }
  if (number >= 9999) {
    return (prefix ? `${prefix} ` : '') + (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return constructDisplayPrice(number, prefix);
};

// +++++++++++++++++++++++++Listing
export const convertAmenitiesSelectionToObj = (amenitiesSelection, listOfAmenities) => {
  let extractedAmenities = {};
  if (amenitiesSelection) {
    Object.keys(listOfAmenities).forEach(key => {
      extractedAmenities[key] = amenitiesSelection.filter(
        payloadValue => !!listOfAmenities[key].data.find(amentity => amentity.key === payloadValue)
      );
    });
  }
  return extractedAmenities;
};

// +++++++++++++++++++++++++Rate
export const calculateDerivedRate = ({ weekdayRate, weekendRate, isDerived, isPercentage, isPositive, calcAmount, parentWeekday, parentWeekend }) => {
  if (isDerived && (!!calcAmount || calcAmount === 0) && (!!parentWeekday || parentWeekday === 0) && (!!parentWeekend || parentWeekend === 0)) {
    const signInNumber = isPositive ? 1 : -1; // to indicate if is positive or negative in calculation

    const resultWeekdayRate = parentWeekday + signInNumber * (calcAmount * (isPercentage ? parentWeekday : 1));
    const resultWeekendRate = parentWeekend + signInNumber * (calcAmount * (isPercentage ? parentWeekend : 1));

    return { weekdayRate: resultWeekdayRate, weekendRate: resultWeekendRate };
  }

  return { weekdayRate, weekendRate };
};

export const generateDisplayFee = (feeAmount, shouldPrefix = true, defaultValue, currency = 'RM') => {
  const displayFee = !!feeAmount || feeAmount === 0 ? String(numberWithCommas(feeAmount)) : defaultValue;
  const displayFeeWithPrefix = shouldPrefix ? `${currency} ${displayFee}` : displayFee;

  return displayFeeWithPrefix;
};

export const generateDisplayPercentage = (percentage, isString) => {
  const numberPercentage = percentage * 100;
  const displayPercentage = `${numberPercentage}%`;

  return isString ? displayPercentage : numberPercentage;
};

export const generateFeeFromDisplay = displayFee => {
  const fee = !!displayFee || displayFee === 0 ? displayFee.replace(REGEX_NOT_NUMBER_N_DOT, '') : displayFee;

  return fee;
};

export const generatePercentageFromDisplay = displayPercentage => {
  const percentage = displayPercentage / 100;

  return percentage;
};

export const findArrayValueAndIndex = (array, callback) => {
  let foundIndex = 0;
  const foundValue = array.find((value, index) => {
    if (callback(value)) {
      foundIndex = index;
      return true;
    }
    return false;
  });
  return [foundValue, foundIndex];
};

// +++++++++++++++++++++++++Transaction
export const convertVariableNameToReadableString = str => {
  return capitalizeFirstLetter(str)
    .match(/[A-Z][a-z]+/g)
    .join(' ');
};

/* ------------------------------------------------Link related------------------------------------------------- */
export const findAndGenerateLink = str => {
  const findAndGenerateLinkInString = str => {
    const urlRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/g;

    return str.replace(urlRegex, url => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };
  return ReactHtmlParser(findAndGenerateLinkInString(str));
};

/* ------------------------------------------------API related------------------------------------------------- */
export const errorHandlerWrapper = (func, fallbackValue) => {
  return func.catch(err => {
    console.error(err);

    const errorMessage = err.message || 'Something went wrong while accessing server, please contact our technical support';
    message.error(errorMessage);

    return fallbackValue;
  });
};

/* ------------------------------------------------Constants related------------------------------------------------- */
export const getConstantLabel = (constantsInArray, code, keyToSearch = 'code') => getConstantObject(constantsInArray, code, keyToSearch).label;

// +++++++++++++++++++++++++Integration Type
export const getIntegrationTypesConstant = () => {
  const LISTING_TYPE = getIntegrationType(true);
  const HOTEL_TYPE = getIntegrationType(false);

  return { LISTING_TYPE, HOTEL_TYPE };
};

export const getIntegrationType = isListingIntegration =>
  getConstantObject(INTEGRATION_TYPES, isListingIntegration, 'isListingIntegration', { isKeyBoolean: true });

/* ------------------------------------------------Local Functions------------------------------------------------- */ const getConstantObject = (
  constantsInArray,
  valueToSearch,
  keyToSearch,
  { isKeyBoolean } = {}
) => {
  const constantObject = constantsInArray.find(constantObject =>
    isKeyBoolean ? !!constantObject[keyToSearch] === valueToSearch : String(constantObject[keyToSearch]) === String(valueToSearch)
  );
  return constantObject || {};
};

const flatOneLevelArray = initialArray => {
  const flattenedArray = [];

  for (let i = 0; i < initialArray.length; i++) {
    const current = initialArray[i];

    if (checkIsArray(current)) {
      for (let j = 0; j < current.length; j++) {
        flattenedArray.push(current[j]);
      }
    }
  }

  return flattenedArray;
};
