import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Button, Col, Icon, Row, Table, Tooltip } from 'antd';
import moment from 'moment';

import { withAppContext } from 'context/AppContext';
import { useFetchConstant } from 'hooks/constants';
import {
  useGetPaginatedHostInventoryForAdmin,
  useGetHostInventoryUsageForAdmin,
  useGetHostInventoryCsvDataForAdmin
} from 'utils/apis/billingInventory';

import { formatToDateString } from 'utils/date';
import { getConstantLabel, guard } from 'utils/general';
import { buildListingDetailsUri } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch, constructColumnFilterRadio, handleOnAggregationTableChange } from 'utils/table/table';

import UnitConfigModal from './components/UnitConfigModal/UnitConfigModal';
import HostProtectModal from './components/HostProtectModal/HostProtectModal';

import { BodyContainer, InventoryContainer, StatBoxContainer, StatTitle, StatValue } from './ViewByUnits.styles';

const useFetchConstants = () => {
  const { selection: unitSourceSelection, isLoading: isUnitSourceLoading } = useFetchConstant('unitSource');
  const { selection: unitTypes, isLoading: isUnitTypesLoading } = useFetchConstant('unitType');
  const { selection: planSelection, isLoading: isPlanSelectionLoading } = useFetchConstant('plan');
  const { selection: subscriptionModes, isLoading: isSubscriptionModesLoading } = useFetchConstant('subscriptionMode');
  const isLoadingConstants = useMemo(() => isUnitSourceLoading || isUnitTypesLoading || isPlanSelectionLoading || isSubscriptionModesLoading, [
    isUnitSourceLoading,
    isUnitTypesLoading,
    isPlanSelectionLoading,
    isSubscriptionModesLoading
  ]);

  return { isLoadingConstants, unitSourceSelection, unitTypes, planSelection, subscriptionModes };
};

const useInventoryStatuses = (monthWithYear, tableQuery, hostId, unitSourceSelection, planSelection, unitTypes) => {
  const formatParentPrimary = useCallback((parent, primary) => {
    const parentUnitName = guard(() => parent.name, '');
    const primaryUnitName = guard(() => primary.name, '');
    return `${parentUnitName}${primaryUnitName}` || undefined;
  }, []);

  const formatRenewalDate = useCallback((renewalDate, isMonthlySubscription) => {
    if (isMonthlySubscription) {
      return 'Monthly';
    } else {
      return formatToDateString(renewalDate);
    }
  }, []);

  const postProcessHostInventory = useCallback(
    result => {
      const formattedInventoryStatuses = result.inventories.map(inventoryStatus => ({
        ...inventoryStatus,
        parentPrimaryUnitName: formatParentPrimary(inventoryStatus.unitParent, inventoryStatus.unitPrimary),
        planNameLabel: getConstantLabel(planSelection, inventoryStatus.planName),
        unitConfig: {
          ...inventoryStatus.unitConfig,
          renewal: formatRenewalDate(inventoryStatus.unitConfig.renewalDate, inventoryStatus.unitConfig.isMonthlySubscription),
          unitTypeLabel: getConstantLabel(unitTypes, inventoryStatus.unitConfig.unitType)
        },
        unitSourceLabel: getConstantLabel(unitSourceSelection, inventoryStatus.unitSource)
      }));

      return { ...result, inventories: formattedInventoryStatuses };
    },
    [planSelection, unitSourceSelection, unitTypes, formatParentPrimary, formatRenewalDate]
  );

  const { paginatedData: inventoryStatuses, total, isLoading: isFetchingInventoryStatuses, refetch } = useGetPaginatedHostInventoryForAdmin(
    monthWithYear,
    hostId,
    tableQuery,
    !!hostId && !!monthWithYear && guard(() => unitSourceSelection.length > 0 && planSelection.length > 0 && unitTypes.length > 0, false),
    postProcessHostInventory
  );

  return { isFetchingInventoryStatuses, total, inventoryStatuses, refetchInventoryStatuses: refetch };
};

const getUnitRenderProp = onHostProtectIconClick => ({
  render: (unitName, record) => {
    const hasProtection = record.hasProtection;
    const isProtectionActive = record.isProtectionActive;

    const title = `HostProtect ${hasProtection ? (isProtectionActive ? 'Activated' : 'Expired') : 'Not Activated'}`;
    const type = hasProtection ? (isProtectionActive ? 'check-circle' : 'clock-circle-o') : 'warning';
    const twoToneColor = hasProtection && isProtectionActive ? '#52c41a' : '#db4437';

    if (record.isUnitDeleted) {
      return (
        <>
          {unitName}{' '}
          <Tooltip title="This unit has been deleted">
            <Icon type="question-circle-o" />
          </Tooltip>
        </>
      );
    } else {
      return (
        <>
          <Tooltip title={title}>
            <Icon type={type} theme="twoTone" twoToneColor={twoToneColor} onClick={onHostProtectIconClick(record.unitId)} />{' '}
          </Tooltip>
          <Link to={buildListingDetailsUri(record.unitId)}>{unitName}</Link>
        </>
      );
    }
  }
});

const constructColumns = (unitSourceSelection, unitTypes, planSelection, handleOnActionButtonClick, handleOnHostProtectIconClick) => {
  const YES_NO_SELECTIONS = [{ label: 'Yes', value: true }, { label: 'No', value: false }];

  return [
    {
      ...constructColumn('Unit', 'unitName', { hasAggregationSorter: true, width: 150, fixed: 'left' }),
      ...constructColumnFilterSearch('unitName', 'Search Unit'),
      ...getUnitRenderProp(handleOnHostProtectIconClick)
    },
    {
      ...constructColumn('Property', 'propertyName', { hasAggregationSorter: true, width: 150 }),
      ...constructColumnFilterSearch('propertyName', 'Search Property')
    },
    {
      ...constructColumn('Source', 'unitSourceLabel', { key: 'unitSource', width: 120 }),
      ...constructColumnFilterRadio('unitSource', unitSourceSelection)
    },
    {
      ...constructColumn('Plan', 'planNameLabel', { key: 'planName', hasAggregationSorter: true, width: 120 }),
      ...constructColumnFilterRadio('planName', planSelection)
    },
    // {
    //   ...constructColumn('Active', 'unitConfig.isActive'),
    //   ...constructColumnFilterRadio('unitConfig.isActive', YES_NO_SELECTIONS),
    //   render: isActive => {
    //     return isActive ? 'Yes' : 'No';
    //   }
    // },
    {
      ...constructColumn('Identity', 'unitConfig.unitTypeLabel', { key: 'unitConfig.unitType', width: 120 }),
      ...constructColumnFilterRadio('unitConfig.unitType', unitTypes)
    },
    {
      ...constructColumn('Parent/ Primary', 'parentPrimaryUnitName', { width: 120 })
    },
    {
      ...constructColumn('Occupancy Rate (%)', 'occupancyRate', { hasSorter: true, width: 125 }),
      render: (text, record) => {
        return (
          <>
            {Number((record.occupancyRate || 0) * 100).toFixed(0)}
            {' %'}
          </>
        );
      }
    },
    {
      ...constructColumn('FOC', 'unitConfig.isFree', { width: 100 }),
      ...constructColumnFilterRadio('unitConfig.isFree', YES_NO_SELECTIONS),
      render: isFreeOfCharge => {
        return isFreeOfCharge ? 'Yes' : 'No';
      }
    },
    {
      ...constructColumn('Renewal', 'unitConfig.renewal', { key: 'unitConfig.renewalDate', hasAggregationSorter: true, width: 120 })
    },
    {
      ...constructColumn('Subs Fee', 'unitConfig.subscriptionFee', { hasAggregationSorter: true, isRM: true, width: 120 })
    },
    {
      ...constructColumn('HP Exp. Date', 'hostProtectExpiredDate', { hasAggregationSorter: true, isDate: true })
    },
    {
      ...constructColumn('Created Date', 'unitCreatedAt', { width: 120 }),
      render: (text, record) => {
        return <>{moment(record.unitCreatedAt).format('DD-MMM-YYYY HH:mm')}</>;
      }
    },
    {
      ...constructColumn('Deleted Date', 'unitDeletedAt', { width: 120 }),
      render: (text, record) => {
        if (!!record.isUnitDeleted) {
          return <>{moment(record.unitDeletedAt).format('DD-MMM-YYYY HH:mm')}</>;
        } else {
          return <>{' - '}</>;
        }
      }
    },
    {
      ...constructColumn('Actions', 'unitId', { width: 100, fixed: 'right' }),
      render: (unitId, record) => {
        return (
          <span>
            {!record.isUnitDeleted && (
              <Button type="primary" onClick={handleOnActionButtonClick(unitId)}>
                <Icon type="edit" />
              </Button>
            )}
          </span>
        );
      }
    }
  ];
};

const StatisticBox = ({ title, value, loading }) => (
  <StatBoxContainer>
    <StatTitle span={24}>{title}</StatTitle>
    <StatValue span={24}>{loading ? <Icon type="loading" spin /> : value}</StatValue>
  </StatBoxContainer>
);

const DownloadCsvButton = ({ monthWithYear, hostId, filename }) => {
  const { data: csvData, isLoading: isCsvDataLoading } = useGetHostInventoryCsvDataForAdmin(monthWithYear, hostId);

  return (
    <CSVLink filename={filename} data={csvData || []}>
      <Button type="primary" icon="download" loading={isCsvDataLoading}>
        {isCsvDataLoading ? 'Fetching...' : 'Download CSV'}
      </Button>
    </CSVLink>
  );
};

const ViewByUnits = ({ hostId, monthWithYear, getCsvFileName }) => {
  const [query, setQuery] = useState({});
  const [isShowUnitConfigModal, setIsShowUnitConfigModal] = useState(false);
  const [isShowHostProtectModal, setIsShowHostProtectModal] = useState(false);
  const [selectedUnitId, setSelectedUnitId] = useState('');
  const [isFetchCsvButtonClicked, setIsFetchCsvButtonClicked] = useState(false);

  const { isLoadingConstants, unitSourceSelection, unitTypes, planSelection, subscriptionModes } = useFetchConstants();
  const { isFetchingInventoryStatuses, total, inventoryStatuses, refetchInventoryStatuses } = useInventoryStatuses(
    monthWithYear,
    query,
    hostId,
    unitSourceSelection,
    planSelection,
    unitTypes
  );
  const { data: unitUsageStats, isLoading: isUnitUsageStatsLoading } = useGetHostInventoryUsageForAdmin(monthWithYear, hostId);

  const isTableLoading = useMemo(() => isLoadingConstants || isFetchingInventoryStatuses, [isLoadingConstants, isFetchingInventoryStatuses]);

  useEffect(() => {
    setIsFetchCsvButtonClicked(false);
  }, [hostId, monthWithYear]);

  const constructUnitUsageStats = useCallback(
    stats =>
      guard(
        () => [
          { title: 'Entry Plan', value: stats.entryPlan || 0 },
          { title: 'Starter Plan', value: stats.starterPlan || 0 },
          { title: 'Standard Plan', value: stats.standardPlan || 0 },
          { title: 'Host Protect', value: stats.hostProtect || 0 }
        ],
        []
      ),
    []
  );

  const handleOnActionButtonClick = unitId => () => {
    setSelectedUnitId(unitId);
    setIsShowUnitConfigModal(true);
  };

  const handleOnHostProtectIconClick = unitId => () => {
    setSelectedUnitId(unitId);
    setIsShowHostProtectModal(true);
  };

  const handleOnHostProtectModalOk = () => {
    refetchInventoryStatuses();
    setIsShowHostProtectModal(false);
    setSelectedUnitId();
  };

  const handleOnHostProtectModalCancel = () => {
    setIsShowHostProtectModal(false);
    setSelectedUnitId();
  };

  const handleOnUnitConfigModalOk = () => {
    refetchInventoryStatuses();
    setIsShowUnitConfigModal(false);
    setSelectedUnitId();
  };

  const handleOnUnitConfigModalCancel = () => {
    setIsShowUnitConfigModal(false);
    setSelectedUnitId();
  };

  return (
    <InventoryContainer>
      <BodyContainer>
        <Row gutter={[16, { xs: 16, sm: 16, lg: 0 }]}>
          {constructUnitUsageStats(unitUsageStats).map(stat => (
            <Col span={24} sm={12} md={6} key={stat.title}>
              <StatisticBox title={stat.title} value={stat.value} loading={isUnitUsageStatsLoading} />
            </Col>
          ))}
        </Row>
        <Row type="flex" justify="end" style={{ marginBottom: 20, marginTop: 40 }}>
          {isFetchCsvButtonClicked ? (
            <DownloadCsvButton filename={getCsvFileName()} hostId={hostId} monthWithYear={monthWithYear} />
          ) : (
            <Button type="primary" icon="download" onClick={() => setIsFetchCsvButtonClicked(true)}>
              Fetch CSV Data
            </Button>
          )}
        </Row>
        <Table
          loading={isTableLoading}
          rowKey={record => record.unitId}
          dataSource={inventoryStatuses}
          columns={constructColumns(unitSourceSelection, unitTypes, planSelection, handleOnActionButtonClick, handleOnHostProtectIconClick)}
          pagination={{ total }}
          scroll={{ x: 1750 }}
          onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
        />
      </BodyContainer>

      {selectedUnitId && (
        <>
          {isShowUnitConfigModal && (
            <UnitConfigModal
              unitId={selectedUnitId}
              hostId={hostId}
              unitTypes={unitTypes}
              subscriptionModes={subscriptionModes}
              onOk={handleOnUnitConfigModalOk}
              onCancel={handleOnUnitConfigModalCancel}
            />
          )}
          {isShowHostProtectModal && (
            <HostProtectModal unitId={selectedUnitId} onCancel={handleOnHostProtectModalCancel} onOk={handleOnHostProtectModalOk} />
          )}
        </>
      )}
    </InventoryContainer>
  );
};

export default withAppContext(ViewByUnits);
