import React, { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Form, TimePicker } from 'antd';

import styles from './FormTimePicker.module.css';

const FormItem = Form.Item;

const FormTimePicker = ({
  form,
  fieldName,
  label,
  ruleMessage,
  extraRules,
  defaultValue,
  size,
  format,
  disabledHours,
  use12Hours = false,
  ...otherProps
}) => {
  const rule = ruleMessage
    ? {
        rules: [
          {
            required: true,
            message: ruleMessage
          },
          ...extraRules
        ]
      }
    : { rules: [...extraRules] };
  return (
    <Fragment>
      <p className={styles.timepickerInputLabel}>{label}</p>
      <FormItem>
        {form.getFieldDecorator(fieldName, {
          ...rule,
          initialValue: (defaultValue && moment(defaultValue, format)) || undefined
        })(
          <TimePicker
            className={styles.timepickerInput}
            disabledHours={() => disabledHours}
            size={size}
            format={format}
            autoComplete="false"
            use12Hours={use12Hours}
            {...otherProps}
          />
        )}
      </FormItem>
    </Fragment>
  );
};

FormTimePicker.propTypes = {
  form: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  format: PropTypes.string,
  disabledHours: PropTypes.array,
  extraRules: PropTypes.array,
  label: PropTypes.string.isRequired,
  ruleMessage: PropTypes.string,
  defaultValue: PropTypes.string,
  size: PropTypes.oneOf(['default', 'large'])
};

FormTimePicker.defaultProps = {
  defaultValue: '',
  disabledHours: [],
  extraRules: [],
  format: 'HH:mm',
  size: 'default'
};

export default FormTimePicker;
