import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from 'antd';

import styles from './FormInput.module.css';

const FormItem = Form.Item;

const TYPE_INPUT = 'input';
const TYPE_TEXTAREA = 'textarea';
const TYPE_PASSWORD = 'password';

const sizes = {
  default: {
    className: ''
  },
  small: {
    className: styles.small
  },
  medium: {
    className: styles.medium
  },
  large: {
    className: styles.large
  }
};

const addRules = (requiredErrorMessage, extraRules) => {
  // Temporary remove whitespace checking rule
  // const whiteSpaceRule = {
  //   whitespace: true,
  //   message: 'Value must not only be whitespaces'
  // };
  const rules = [];
  if (requiredErrorMessage && typeof requiredErrorMessage === 'string') {
    rules.push({
      required: true,
      message: requiredErrorMessage
    });
  }
  return [...rules, ...extraRules];
};

const generateInputPropsFromType = ({ inputType, placeholder, onChange, size, classNameInput, addonBefore, addonAfter }) => {
  const defaultProps = {
    placeholder,
    onChange,
    className: `${sizes[size].className} ${classNameInput}`
  };
  const normalInputProps = {
    size,
    addonBefore,
    addonAfter
  };
  const typeToProps = {
    [TYPE_INPUT]: normalInputProps,
    [TYPE_PASSWORD]: normalInputProps,
    [TYPE_TEXTAREA]: { rows: 4 }
  };
  const typeProps = typeToProps[inputType] || {};

  return {
    ...defaultProps,
    ...typeProps
  };
};

const generateInputPropsFromRules = rules => {
  return rules.reduce((newProps, rule) => {
    if (rule.max) {
      return { ...newProps, maxLength: rule.max };
    }
    return newProps;
  }, {});
};

const generateInputComponent = type => {
  const defaultInput = Input;
  const supportedInput = {
    [TYPE_INPUT]: Input,
    [TYPE_PASSWORD]: Input.Password,
    [TYPE_TEXTAREA]: Input.TextArea
  };
  return supportedInput[type] || defaultInput;
};

const FormInput = ({
  className,
  classNameInput,
  defaultValue,
  disabled,
  extraRules,
  extraProps,
  form,
  formLabel,
  inputType,
  label,
  isHideLabel,
  onChange,
  name,
  placeholder,
  requiredErrorMessage,
  size,
  addonBefore,
  addonAfter,
  uppercase,
  ...props
}) => {
  const rules = addRules(requiredErrorMessage, extraRules);
  const inputProps = {
    ...generateInputPropsFromRules(extraRules),
    ...generateInputPropsFromType({ inputType, placeholder, onChange, size, classNameInput, addonBefore, addonAfter }),
    ...extraProps
  };

  const InputComponent = generateInputComponent(inputType);

  return (
    <Fragment>
      {!isHideLabel && !formLabel && <p className={styles.label}>{label}</p>}
      <FormItem label={formLabel} className={className} colon={false}>
        {form.getFieldDecorator(name, {
          rules: rules,
          initialValue: defaultValue,
          validateFirst: true,
          ...(uppercase && { normalize: value => (value || '').toUpperCase() })
        })(<InputComponent disabled={disabled} {...inputProps} />)}
      </FormItem>
    </Fragment>
  );
};

FormInput.propTypes = {
  form: PropTypes.object.isRequired,
  formLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  classNameInput: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  extraRules: PropTypes.array,
  extraProps: PropTypes.object,
  inputType: PropTypes.oneOf([TYPE_INPUT, TYPE_TEXTAREA, TYPE_PASSWORD]),
  label: PropTypes.string,
  isHideLabel: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  requiredErrorMessage: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizes)),
  addonBefore: PropTypes.string,
  addonAfter: PropTypes.string
};

FormInput.defaultProps = {
  className: '',
  classNameInput: '',
  defaultValue: undefined,
  extraRules: [],
  extraProps: {},
  inputType: TYPE_INPUT,
  label: '',
  isHideLabel: false,
  onChange: undefined,
  placeholder: '',
  requiredErrorMessage: '',
  size: Object.keys(sizes)[0],
  addonBefore: '',
  addonAfter: ''
};

export default FormInput;

export class FormInputReadOnly extends PureComponent {
  render() {
    const { text, label, className } = this.props;
    return (
      <FormItem label={label} colon={false} className={className}>
        <Input value={text} disabled />
      </FormItem>
    );
  }
}

FormInputReadOnly.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired
};
