import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, List } from 'antd';

import ListingWizardLayout from 'layouts/ListingWizardLayout/ListingWizardLayout';

import Counter from 'components/Counter/Counter';
import { BedRoomListItem, BedConfiguration } from 'components/Bedrooms/Bedrooms';
import ColMobileSeperator from '../../components/ColMobileSeperator/ColMobileSeperator';

import styles from './PlaceSetup.module.css';

const TYPE_LIVING_ROOM = 'livingRoom';
const TYPE_BATH_ROOM = 'bathRoom';

const generateRoomName = index => `Room ${index + 1}`;

const mapBedTypesToConfiguration = (count, bedTypes) => {
  const newBedTypes = Object.keys(bedTypes).reduce((acc, key) => {
    return {
      ...acc,
      [key]: { ...bedTypes[key], count: 0 }
    };
  }, {});
  return {
    beds: { ...newBedTypes },
    name: generateRoomName(count),
    totalBedCount: 0
  };
};

class PlaceSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numOfBathRooms: props.defaultValues.numOfBathRooms || 0,
      numOfLivingRooms: props.defaultValues.numOfLivingRooms || 0,

      selectedRoomIndex: -1,
      bedroomConfiguration: props.defaultValues.bedroomConfiguration || []
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.hasFetchedBedTypes && state.bedroomConfiguration.length === 0) {
      const defaultBedroom = {
        ...mapBedTypesToConfiguration(0, props.bedTypes)
      };
      return {
        bedroomConfiguration: [...state.bedroomConfiguration, defaultBedroom],
        selectedRoomIndex: 0
      };
    }
    return null;
  }

  handleOnRoomCounterDecrement = type => () => {
    const key = this.mapCounterRoomTypeKey(type);
    this.setState(prevState => {
      if (prevState[key] > 0) {
        return {
          [key]: prevState[key] - 1
        };
      }
    });
  };

  handleOnRoomCounterIncrement = type => () => {
    const key = this.mapCounterRoomTypeKey(type);
    this.setState(prevState => ({
      [key]: prevState[key] + 1
    }));
  };

  mapCounterRoomTypeKey = type => {
    const counterRoomTypeKeyMap = {
      [TYPE_BATH_ROOM]: 'numOfBathRooms',
      [TYPE_LIVING_ROOM]: 'numOfLivingRooms'
    };
    return counterRoomTypeKeyMap[type];
  };

  handleOnAddBedroom = () => {
    this.setState((prevState, props) => {
      const { bedTypes } = props;
      const bedroomConfiguration = mapBedTypesToConfiguration(prevState.bedroomConfiguration.length, bedTypes);
      return {
        bedroomConfiguration: [...prevState.bedroomConfiguration, bedroomConfiguration],
        selectedRoomIndex: prevState.bedroomConfiguration.length
      };
    });
  };

  handleOnBedroomRemove = indexToRemove => e => {
    e.preventDefault();
    this.setState(prevState => {
      const newBedroomConfiguration = prevState.bedroomConfiguration.filter((config, index) => index !== indexToRemove).map((bedroom, index) => {
        return {
          ...bedroom,
          name: generateRoomName(index)
        };
      });
      const newState = {
        bedroomConfiguration: [...newBedroomConfiguration]
      };
      newState.selectedRoomIndex = this.calculateSelectedRoomIndexOnRemove(indexToRemove, prevState.selectedRoomIndex);
      return newState;
    });
  };

  calculateSelectedRoomIndexOnRemove = (indexToRemove, selectedRoomIndex) => {
    if (indexToRemove === selectedRoomIndex) {
      return -1;
    } else if (selectedRoomIndex > indexToRemove) {
      return selectedRoomIndex - 1;
    }
    return selectedRoomIndex;
  };

  handleOnBedroomEdit = index => e => {
    e.preventDefault();
    this.setState({
      selectedRoomIndex: index
    });
  };

  handleOnBedUpdate = (isIncrement = true) => key => e => {
    e.preventDefault();
    const { selectedRoomIndex } = this.state;
    this.setState(prevState => {
      const newBedroomConfiguration = prevState.bedroomConfiguration;
      const selectedBedRoomConfiguration = newBedroomConfiguration[selectedRoomIndex];
      const selectedBedTypeCount = selectedBedRoomConfiguration.beds[key].count;

      let newBedConfiguration;
      if (isIncrement) {
        newBedConfiguration = {
          ...selectedBedRoomConfiguration,
          beds: {
            ...selectedBedRoomConfiguration.beds,
            [key]: {
              ...selectedBedRoomConfiguration.beds[key],
              count: selectedBedTypeCount + 1
            }
          },
          totalBedCount: selectedBedRoomConfiguration.totalBedCount + 1
        };
      } else {
        if (selectedBedTypeCount && selectedBedTypeCount > 0) {
          newBedConfiguration = {
            ...selectedBedRoomConfiguration,
            beds: {
              ...selectedBedRoomConfiguration.beds,
              [key]: {
                ...selectedBedRoomConfiguration.beds[key],
                count: selectedBedTypeCount - 1
              }
            },
            totalBedCount: selectedBedRoomConfiguration.totalBedCount - 1
          };
        }
      }

      if (newBedConfiguration) {
        newBedroomConfiguration[selectedRoomIndex] = newBedConfiguration;
        return {
          bedroomConfiguration: newBedroomConfiguration
        };
      }
      return null;
    });
  };

  generateBeds = () => {
    const { bedroomConfiguration, selectedRoomIndex } = this.state;
    const selectedBedRooms = bedroomConfiguration[selectedRoomIndex].beds;
    return Object.keys(selectedBedRooms).map(key => {
      return {
        key,
        label: selectedBedRooms[key].label,
        count: selectedBedRooms[key].count
      };
    });
  };

  getPayload = () => {
    const { numOfBathRooms, numOfLivingRooms, bedroomConfiguration } = this.state;
    return {
      numOfBathRooms,
      numOfLivingRooms,
      bedrooms: bedroomConfiguration
    };
  };

  handleOnBackClick = e => {
    e.preventDefault();
    const { onBack } = this.props;
    const payload = this.getPayload();
    onBack(payload);
  };

  handleOnFormSubmit = e => {
    e.preventDefault();
    const { onSave } = this.props;
    const { bedroomConfiguration } = this.state;
    if (this.validateBedroomConfiguration(bedroomConfiguration)) {
      const payload = this.getPayload();
      onSave(payload);
    }
  };

  validateBedroomConfiguration = bedrooms => {
    const bedroomKeys = Object.keys(bedrooms);
    let validated = true;
    for (let i = 0; i < bedroomKeys.length; i++) {
      const currentKey = bedroomKeys[i];
      if (bedrooms[currentKey].totalBedCount <= 0) {
        validated = false;
        break;
      }
    }
    return validated;
  };

  handleOnBackClick = e => {
    e.preventDefault();
    const { onBack } = this.props;
    const { numOfBathRooms, numOfLivingRooms, bedroomConfiguration } = this.state;
    const allFieldsValue = {
      numOfBathRooms,
      numOfLivingRooms,
      bedrooms: bedroomConfiguration
    };
    onBack(allFieldsValue);
  };

  render() {
    const { isLastPage, percentage, title } = this.props;
    const { bedroomConfiguration, selectedRoomIndex, numOfBathRooms, numOfLivingRooms } = this.state;
    return (
      <ListingWizardLayout
        isLastPage={isLastPage}
        percentage={percentage}
        title={title}
        onBackButtonClick={this.handleOnBackClick}
        onNextButtonClick={this.handleOnFormSubmit}
      >
        <Row type="flex" justify="space-between" className={styles.formTitleContainer}>
          <h2 className={styles.formTitle}>How's your place setup ?</h2>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={16}>
            <p style={{ marginBottom: '8px', fontSize: '16px' }}>Living Room</p>
          </Col>
          <Col span={8}>
            <Counter
              count={numOfLivingRooms}
              onDecrement={this.handleOnRoomCounterDecrement(TYPE_LIVING_ROOM)}
              onIncrement={this.handleOnRoomCounterIncrement(TYPE_LIVING_ROOM)}
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={16}>
            <p style={{ marginBottom: '8px', fontSize: '16px' }}>Bathroom(s)</p>
          </Col>
          <Col span={8}>
            <Counter
              count={numOfBathRooms}
              onDecrement={this.handleOnRoomCounterDecrement(TYPE_BATH_ROOM)}
              onIncrement={this.handleOnRoomCounterIncrement(TYPE_BATH_ROOM)}
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formTitleContainer}>
          <h2 className={styles.formTitle}>Room Arrangements</h2>
        </Row>
        <Row type="flex" justify="space-between" className={styles.listArrangementContainer}>
          <Col span={24} md={12}>
            <List className={styles.listArrangementContainer} itemLayout="horizontal" locale={{ emptyText: 'No bed room added' }}>
              {bedroomConfiguration &&
                bedroomConfiguration.length > 0 &&
                bedroomConfiguration.map((bedConfiguration, index) => {
                  const { beds, name } = bedConfiguration;
                  const description = Object.keys(beds).reduce((newDescription, bedKey) => {
                    const bed = beds[bedKey];
                    if (bed.count && bed.count > 0) {
                      return `${newDescription} ${bed.count} ${bed.label},`;
                    }
                    return newDescription;
                  }, '') || <span className={styles.bedRoomListError}>No bed added (At least 1 is required)</span>;
                  return (
                    <BedRoomListItem
                      key={name}
                      title={name}
                      description={description}
                      onEditClick={this.handleOnBedroomEdit(index)}
                      onRemoveClick={this.handleOnBedroomRemove(index)}
                      shouldDisableRemove={bedroomConfiguration.length === 1} // Disable if only left one room
                    />
                  );
                })}
            </List>
            <div style={{ width: '100%' }}>
              <Button block type="primary" onClick={this.handleOnAddBedroom}>
                Add a new room
              </Button>
            </div>
          </Col>
          <ColMobileSeperator />
          {bedroomConfiguration &&
            bedroomConfiguration.length > 0 &&
            selectedRoomIndex > -1 && (
              <Col span={24} md={12}>
                <div className={styles.listArrangementDetailsContainer}>
                  <BedConfiguration
                    roomName={bedroomConfiguration[selectedRoomIndex].name}
                    beds={this.generateBeds()}
                    onBedIncrement={this.handleOnBedUpdate(true)}
                    onBedDecrement={this.handleOnBedUpdate(false)}
                  />
                </div>
              </Col>
            )}
        </Row>
      </ListingWizardLayout>
    );
  }
}

PlaceSetup.propTypes = {
  bedTypes: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  percentage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isLastPage: PropTypes.bool,
  onBack: PropTypes.func
};

PlaceSetup.defaultProps = {
  bedTypes: {},
  isLastPage: false
};

export default PlaceSetup;
