import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Row, Switch, Select, Card, Col } from 'antd';

import { STANDARD_RATE_CODE } from 'utils/constants';
import { errorHandlerWrapper, generatePercentageFromDisplay, guard } from 'utils/general';
import { getAgoda, syncAgoda } from 'utils/apis/integration';
import { getWebRateByRoomType } from 'utils/apis/rate';
import { getRateModifierTypeConstant, getRateModifierSignConstant } from 'utils/apis/constants';

import OTARateInput from '../../../OTARateInput/OTARateInput';
import AgodaAdvancedOptionsModal from './components/AgodaAdvancedOptionsModal';

import '../../../../../OTAIntegration.css';

const Option = Select.Option;

class AgodaForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      agodas: [],
      selectedAgodaId: '',
      roomMapping: [],

      rateModifierSignConstants: [],
      rateModifierTypeConstants: [],

      isAdvancedOptionsModalVisible: false,
      currency: 'RM'
    };
  }

  componentDidMount = async () => {
    const { data } = this.props;
    const { roomMapping, selectedAgodaId } = this.getRoomMappingFromProps();

    const [agodas, [rateModifierSignConstants, rateModifierTypeConstants]] = await Promise.all([this.getAgodaDetails(), this.getConstants()]);
    this.setState({
      agodas,
      rateModifierSignConstants,
      rateModifierTypeConstants,
      roomMapping,
      selectedAgodaId,
      currency: guard(() => data.currency, 'RM')
    });
  };

  getAgodaDetails = () => {
    return errorHandlerWrapper(
      getAgoda()
        .then(res => res.data)
        .catch(ex => console.log({ ex })),
      []
    );
  };

  getConstants = () => {
    return Promise.all([
      errorHandlerWrapper(getRateModifierSignConstant().then(constant => Object.values(constant)), []),
      errorHandlerWrapper(getRateModifierTypeConstant().then(constant => Object.values(constant)), [])
    ]);
  };

  getRoomMappingFromProps = () => {
    const { data } = this.props;

    if (data) {
      return {
        roomMapping: data.roomTypes.map(roomType => ({
          roomType: roomType.roomType._id,
          rates: roomType.rates || [],
          agodaRoomType: roomType._id
        })),
        selectedAgodaId: data._id
      };
    } else {
      return { roomMapping: [], selectedAgodaId: '' };
    }
  };

  verifyRoomMapping = () => {
    var okToProceed = true;
    // Checking if all room types are mapped
    if (this.state.roomMapping.length === 0) {
      return false;
    }

    // If one of room type is not mapped, return false
    this.state.roomMapping.forEach(roomMap => {
      if (roomMap && roomMap.rates && roomMap.rates.length === 0) {
        okToProceed = false;
      }
    });

    return okToProceed;
  };

  getRateByRoomTypeAndRatePlanId = (roomTypeId, agodaRatePlanId) => {
    const { roomMapping } = this.state;
    const roomMap = roomMapping.find(roomMap => roomMap.roomType === roomTypeId);
    const currentRate = roomMap.rates.find(rate => String(rate.agodaRatePlanId) === String(agodaRatePlanId));

    if (guard(() => currentRate.rate)) {
      return currentRate.rate;
    } else {
      getWebRateByRoomType(roomTypeId).then(webRates => {
        const stdRate = webRates.data.find(rate => rate.code === STANDARD_RATE_CODE);
        if (stdRate) {
          const { weekday, weekend, isDerived = false } = stdRate;
          this.handleOnUpdateRates(roomTypeId, agodaRatePlanId)({ weekday, weekend, isDerived });
        }
      });
    }
  };

  handleOnAdvancedOptionsClick = isVisible => e => {
    e.preventDefault();
    this.setState({
      isAdvancedOptionsModalVisible: isVisible
    });
  };

  handleOnAdvancedOptionsConfirm = rateDistributionPayload => {
    const { roomMapping, rateModifierTypeConstants, rateModifierSignConstants } = this.state;

    const processedRoomMapping = roomMapping.map(room => {
      const rateDistribution = rateDistributionPayload.find(rateDistribution => room.roomType === rateDistribution.roomId);

      if (!!rateDistribution) {
        const processedRates = room.rates.map(roomRateObject => {
          const rateObject = rateDistribution.rates.find(rateObject => String(rateObject.otaId) === String(roomRateObject.agodaRatePlanId));

          if (!!rateObject) {
            const isDerived = rateObject.rate.isDerived;
            let processedRoomRateObject = {
              agodaRatePlanId: String(roomRateObject.agodaRatePlanId),
              ...(rateObject.occupancyRate && { occupancyRate: rateObject.occupancyRate }),
              rate: {
                _id: rateObject.rate._id,
                weekday: rateObject.rate.weekdayRate,
                weekend: rateObject.rate.weekendRate,
                isDerived
              }
            };

            if (isDerived) {
              const isPercentage = !!rateModifierTypeConstants.find(type => type.code === rateObject.rate.modifierType && type.isPercentage);
              const isPositive = !!rateModifierSignConstants.find(sign => sign.code === rateObject.rate.modifierSign && sign.isPositive);
              let amount = rateObject.rate.modifierAmount;
              amount = isPercentage ? generatePercentageFromDisplay(amount) : amount;

              processedRoomRateObject.rate.calculation = {
                type: rateObject.rate.modifierType,
                isPositive,
                amount
              };
            }

            return processedRoomRateObject;
          } else {
            return { ...roomRateObject };
          }
        });

        return {
          agodaRoomType: room.agodaRoomType,
          roomType: room.roomType,
          rates: processedRates
        };
      }

      return { ...room };
    });

    this.setState({ roomMapping: processedRoomMapping, isAdvancedOptionsModalVisible: false });
  };

  handleOnSelectAgodaId = selectedAgodaId => {
    let currency = 'RM';
    const selectedAgodaObj = this.state.agodas.find(agoda => String(agoda._id) === String(selectedAgodaId));
    if (selectedAgodaObj) {
      currency = guard(() => selectedAgodaObj.propertyData.currency, 'RM');
    }

    this.setState({
      selectedAgodaId,
      roomMapping: [],
      currency
    });
  };

  handleOnToggleRoomTypeSync = roomType => isSync => {
    const { roomMapping } = this.state;
    let updatedRoomMapping;
    if (isSync) {
      updatedRoomMapping = [
        ...roomMapping,
        {
          roomType,
          rates: []
        }
      ];
    } else {
      updatedRoomMapping = roomMapping.filter(roomMap => roomMap.roomType !== roomType);
    }
    this.setState({
      roomMapping: updatedRoomMapping
    });
  };

  handleOnSaveAgoda = async () => {
    const { roomMapping, selectedAgodaId } = this.state;
    const { property } = this.props;

    this.setState({
      loading: true
    });
    syncAgoda(selectedAgodaId, property._id, null, roomMapping)
      .then(() => {
        this.setState({
          loading: false
        });
        window.location.reload();
      })
      .catch(ex => {
        this.setState({
          loading: false
        });
        console.log({ ex });
      });
  };

  handleOnChangeAgodaRoomType = roomType => e => {
    const { roomMapping } = this.state;
    const roomMap = roomMapping.find(roomMap => roomMap.roomType === roomType);
    if (roomMap) {
      roomMap.agodaRoomType = e;
      this.setState({
        roomMapping
      });
    }
  };

  constructRoomRateForDisplay = selectedAgodaId => {
    const { agodas } = this.state;

    const selectedAgodaObj = agodas.find(agoda => String(agoda._id) === String(selectedAgodaId));
    const { rooms, ratePlans } = selectedAgodaObj;

    return rooms.reduce((roomsRatesMapping, room) => {
      const roomId = guard(() => String(room._id));

      const roomRatesDetail = room.rates.map(rate => {
        const ratePlan = ratePlans.find(ratePlan => String(ratePlan.rateplan_id) === String(rate.agodaRatePlanId));
        const ratePlanName = guard(() => ratePlan.rateplan_name, '');

        return { roomId, ratePlanId: String(rate.agodaRatePlanId), ratePlanName };
      });

      return { ...roomsRatesMapping, [roomId]: roomRatesDetail };
    }, {});
  };

  handleOnUpdateRates = (roomType, agodaRatePlanId) => newRates => {
    const { roomMapping } = this.state;
    const roomMappingForRoomType = roomMapping.find(mapping => mapping.roomType === roomType);

    const currentRoomRate = roomMappingForRoomType.rates.find(rate => String(rate.agodaRatePlanId) === String(agodaRatePlanId));

    if (currentRoomRate) {
      currentRoomRate.rate = {
        ...currentRoomRate.rate,
        ...newRates
      };
    } else {
      roomMappingForRoomType.rates.push({ agodaRatePlanId, rate: newRates });
    }
    this.setState({ roomMapping });
  };

  render() {
    const { roomMapping, selectedAgodaId, agodas, rateModifierTypeConstants, rateModifierSignConstants, isAdvancedOptionsModalVisible } = this.state;
    const { property, onClose, data } = this.props;
    let agodaObj = null;
    let roomRateMapping = {};
    let agodaRooms = [];
    let ratePlans = [];

    if (selectedAgodaId && agodas) {
      agodaObj = agodas.find(agodaItem => String(agodaItem._id) === String(selectedAgodaId));
      if (agodaObj) {
        agodaRooms = agodaObj.rooms;
        ratePlans = agodaObj.ratePlans;
      }
      roomRateMapping = this.constructRoomRateForDisplay(selectedAgodaId);
    }

    const mappedRooms = roomMapping.map(room => room.agodaRoomType);

    return (
      <Row className="scroll-bar-style">
        {isAdvancedOptionsModalVisible && (
          <AgodaAdvancedOptionsModal
            roomMapping={roomMapping}
            ratePlans={ratePlans}
            property={property}
            rateModifierTypeConstants={rateModifierTypeConstants}
            rateModifierSignConstants={rateModifierSignConstants}
            isVisible={isAdvancedOptionsModalVisible}
            onConfirm={this.handleOnAdvancedOptionsConfirm}
            onClose={this.handleOnAdvancedOptionsClick(false)}
            currency={this.state.currency}
          />
        )}
        <Row className="ota-row">
          <label className="ota-label">Agoda Hotel ID</label>
          <Select className="full-length-box" disabled={!!data || this.state.loading} value={selectedAgodaId} onChange={this.handleOnSelectAgodaId}>
            {agodas &&
              agodas
                .filter(agoda => (!agoda.property || (property && agoda.property.toString() === property._id)) && !agoda.isHomes)
                .map(agoda => {
                  return (
                    <Option value={agoda._id} key={agoda._id}>
                      {agoda.propertyData ? `${agoda.propertyData.name} (${agoda.agodaPropertyId})` || 'No Property Name' : 'No Property Name'}
                    </Option>
                  );
                })}
          </Select>
        </Row>
        {property &&
          property.roomTypes &&
          property.roomTypes.map(roomType => {
            const roomMap = roomMapping.find(roomMap => roomMap.roomType === roomType._id);

            const isSynced = !!roomMap;
            return (
              <Row key={roomType._id}>
                <Card className="ota-card" title={roomType.name}>
                  <label className="booking-label"> {'Sync ' + roomType.name + ' to Agoda'}</label>
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={this.handleOnToggleRoomTypeSync(roomType._id)}
                    checked={isSynced}
                    style={{ marginLeft: '5px' }}
                    disabled={this.state.loading}
                  />
                  {isSynced && (
                    <>
                      <Row>
                        <div className="form-row">
                          <Select value={roomMap.agodaRoomType} style={{ width: '100%' }} onChange={this.handleOnChangeAgodaRoomType(roomType._id)}>
                            {agodaRooms &&
                              agodaRooms
                                .filter(
                                  agodaRoom =>
                                    !mappedRooms.includes(agodaRoom._id) ||
                                    roomMapping.find(room => room.roomType === roomType._id).agodaRoomType === agodaRoom._id
                                )
                                .map(agodaRoom => {
                                  return (
                                    <Option value={agodaRoom._id} key={agodaRoom._id}>
                                      {agodaRoom.roomData ? agodaRoom.roomData.room_name || 'No Room Name' : 'No Room name'}
                                    </Option>
                                  );
                                })}
                          </Select>
                        </div>
                      </Row>
                      <Row>
                        {selectedAgodaId &&
                          roomRateMapping[roomMap.agodaRoomType] &&
                          roomRateMapping[roomMap.agodaRoomType].map(plan => {
                            const rate = this.getRateByRoomTypeAndRatePlanId(roomType._id, plan.ratePlanId);
                            return (
                              <div key={plan.ratePlanId}>
                                <Card title={`${plan.ratePlanName} | ${plan.ratePlanId}`}>
                                  <OTARateInput
                                    rate={rate}
                                    updateRates={this.handleOnUpdateRates(roomType._id, plan.ratePlanId)}
                                    currency={this.state.currency}
                                  />
                                </Card>
                              </div>
                            );
                          })}
                      </Row>
                    </>
                  )}
                </Card>
              </Row>
            );
          })}
        {roomMapping.length > 0 && (
          <Row>
            <a href=" " onClick={this.handleOnAdvancedOptionsClick(true)}>
              Advanced Options
            </a>
          </Row>
        )}
        <Row gutter={12} type="flex" justify="end" className="ota-action-button-row">
          <Col>
            <Button type="secondary" disabled={this.state.loading} onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button type="primary" loading={this.state.loading} onClick={this.handleOnSaveAgoda} disabled={!this.verifyRoomMapping()}>
              Save
            </Button>
          </Col>
        </Row>
      </Row>
    );
  }
}

AgodaForm.propTypes = {
  data: PropTypes.object,
  property: PropTypes.object.isRequired,
  onClose: PropTypes.func
};

AgodaForm.defaultProps = {
  property: {},
  onClose: () => {}
};

export default withRouter(AgodaForm);
