import React, { Component } from 'react';
import GuestBookListing from './../../components/GuestBookListing/GuestBookListing';

class GuestBook extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <GuestBookListing />;
  }
}

export default GuestBook;
