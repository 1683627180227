import React, { useState, useEffect } from 'react';
import { withAppContext } from 'context/AppContext';
import { Card, Form, Row, Col, Table, Button, Modal, notification } from 'antd';

import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import { getColumnFilterSearchProps, getColumnSorterProps } from 'components/Table/Table';

import { createPullTraveloka, getTravelokas, putUpdateTraveloka } from 'utils/apis/integration';
import { getHosts } from 'utils/apis/host';
import { guard } from 'utils/general';

const MESSAGE_TYPE = {
  SUCCESS_UPDATE: 'successUpdate',
  ERROR_UPDATE: 'errorUpdate',
  SUCCESS_PULL: 'successPull',
  ERROR_PULL: 'errorPull'
};

const useFetchConstant = () => {
  const [hostLists, setHostLists] = useState([]);

  useEffect(() => {
    getHosts().then(response => {
      const hostLists = response.data.map(host => ({
        label: host.name,
        value: host._id
      }));

      setHostLists(hostLists);
    });
  }, []);

  return { hostLists };
};

const useFetchTravelokaListings = () => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [travelokaListings, setTravelokaListings] = useState([]);
  const [refetchListings, setRefetchListings] = useState(false);

  useEffect(() => {
    setIsTableLoading(true);
    getTravelokas({ fields: ['travelokaPropertyId', 'travelokaPropertyName', 'travelokaActualPropertyId', 'status', 'host', 'rooms'] }).then(
      travelokaListings => {
        const formatIntoTableSetting = travelokaListings.map(listing => ({
          _id: listing._id,
          travelokaPropertyId: listing.travelokaPropertyId,
          travelokaActualPropertyId: listing.travelokaActualPropertyId,
          travelokaPropertyName: listing.travelokaPropertyName,
          status: listing.status,
          host: listing.host,
          rooms: listing.rooms.length
        }));

        setTravelokaListings(formatIntoTableSetting);
        setIsTableLoading(false);
        setRefetchListings(false);
      }
    );
  }, [refetchListings]);

  const refetchTravelokaListings = isRefetch => {
    setRefetchListings(isRefetch);
  };

  return { isTableLoading, travelokaListings, refetchTravelokaListings };
};

const getTableColumns = (filtersInfo, sorterInfo, handleOnShowEditModal, travelokaListings, isAllowEdit) => {
  const tableColumns = [];

  tableColumns.push({
    title: 'Traveloka Hotel Code',
    dataIndex: 'travelokaPropertyId',
    key: 'travelokaPropertyId',
    ...getColumnFilterSearchProps('travelokaPropertyId', 'Traveloka Hotel Code', { filtersInfo }),
    ...getColumnSorterProps('travelokaPropertyId', { sorterInfo })
  });

  tableColumns.push({
    title: 'Traveloka Property Id',
    dataIndex: 'travelokaActualPropertyId',
    key: 'travelokaActualPropertyId',
    ...getColumnFilterSearchProps('travelokaActualPropertyId', 'Traveloka Property Id', { filtersInfo }),
    ...getColumnSorterProps('travelokaActualPropertyId', { sorterInfo }),
    render: (text, record) => record.travelokaActualPropertyId || '-'
  });

  tableColumns.push({
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: Array.from(new Set(travelokaListings.map(listing => listing.status))).map(item => ({ text: item, value: item })),
    onFilter: (value, record) => record.status === value
  });

  tableColumns.push({
    title: 'Traveloka Property Name',
    dataIndex: 'travelokaPropertyName',
    key: 'travelokaPropertyName',
    ...getColumnFilterSearchProps('travelokaPropertyName', 'Traveloka Property Name', { filtersInfo }),
    ...getColumnSorterProps('travelokaPropertyName', { sorterInfo }),
    render: (text, record) => record.travelokaPropertyName || '-'
  });

  tableColumns.push({
    title: 'Host',
    dataIndex: 'host',
    key: 'host',
    ...getColumnFilterSearchProps('host', 'Host', { filtersInfo }),
    ...getColumnSorterProps('host', { sorterInfo }),
    render: (text, record) => guard(() => text.name, '')
  });

  tableColumns.push({
    title: 'Traveloka Rooms',
    dataIndex: 'rooms',
    key: 'rooms',
    ...getColumnSorterProps('rooms', { sorterInfo })
  });

  tableColumns.push({
    title: 'Actions',
    key: 'action',
    render: (text, record) => {
      return (
        <Button onClick={handleOnShowEditModal(record)} disabled={!isAllowEdit}>
          Edit
        </Button>
      );
    }
  });

  return tableColumns;
};

const generateNotificationMessage = (messageType, travelokaPropertyId = null, error = null) => {
  switch (messageType) {
    case MESSAGE_TYPE.SUCCESS_PULL:
      return {
        message: `Succesfully retrieve Hotel (${travelokaPropertyId})`,
        description: <div>Please assign a host to this Traveloka Hotel</div>
      };
    case MESSAGE_TYPE.ERROR_PULL:
      return {
        message: `Failed to retrieve Traveloka Hotel`,
        description: (
          <div>
            The provided hotel Id ({travelokaPropertyId}) is not associated with Host Platform.
            <div>
              If you are sure it should, please contact{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://api.whatsapp.com/send?phone=60162537829&text=Hello,%20I%20have%20a%20question%20about%20http%3A%2F%2Fplatform.hostastay.com%2Fintegrations%2Ftraveloka-connect"
              >
                customer support
              </a>
              !
            </div>
          </div>
        )
      };
    case MESSAGE_TYPE.SUCCESS_UPDATE:
      return {
        message: `Nice One`,
        description: (
          <div>
            <p>
              <b>Succesfully added Host to Traveloka hotel</b>
            </p>
          </div>
        )
      };
    case MESSAGE_TYPE.ERROR_UPDATE:
      return {
        message: `Oh Crap`,
        description: (
          <div>
            <p>
              <b>Failed to update Traveloka hotel</b>
            </p>
            Try refreshing the page. If problem presist, contact{' '}
            <a target="_blank" rel="noreferrer noopener" href="http://hostastay.com/helpdesk-cs/">
              tech support
            </a>
            !
          </div>
        )
      };
    default:
  }
};

const TravelokaListing = ({ form, checkIsAllowCreateIntegration, checkIsAllowEditIntegration }) => {
  const { hostLists } = useFetchConstant();
  const { isTableLoading, travelokaListings, refetchTravelokaListings } = useFetchTravelokaListings();

  const [filtersInfo, setFilters] = useState({});
  const [sorterInfo, setSorter] = useState({});
  const [isShowModal, setIsShowModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [selectedTraveloka, setSelectedTraveloka] = useState({});

  const isAllowCreate = !!checkIsAllowCreateIntegration();
  const isAllowEdit = !!checkIsAllowEditIntegration();

  const handleOnTableChange = (filtersInfo, sorterInfo) => {
    setFilters(filtersInfo);
    setSorter(sorterInfo);
  };

  const handleOnOpenCloseModal = isShow => {
    setIsShowModal(isShow);

    if (!isShow) {
      setSelectedTraveloka({});
    }
  };

  const handleOnClickEdit = traveloka => e => {
    setSelectedTraveloka(traveloka);
    handleOnOpenCloseModal(true);
  };

  const handleOnModalNewSave = e => {
    e.preventDefault();
    setIsModalLoading(true);

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        return createPullTraveloka(values.travelokaPropertyId)
          .then(traveloka => {
            const successPullMessage = generateNotificationMessage(MESSAGE_TYPE.SUCCESS_PULL, values.travelokaPropertyId);
            notification.success(successPullMessage);

            refetchTravelokaListings(true);

            setIsModalLoading(false);
            setSelectedTraveloka(traveloka);
          })
          .catch(ex => {
            const errorPullMessage = generateNotificationMessage(MESSAGE_TYPE.ERROR_PULL, values.travelokaPropertyId, ex);
            notification.error(errorPullMessage);

            setIsModalLoading(false);
          });
      }
    });
  };

  const handleOnModalEditSave = e => {
    e.preventDefault();

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const travelokaId = selectedTraveloka._id;

        return putUpdateTraveloka(travelokaId, values)
          .then(() => {
            const successUpdateMessage = generateNotificationMessage(MESSAGE_TYPE.SUCCESS_UPDATE);
            notification.success(successUpdateMessage);

            setIsModalLoading(false);
            handleOnOpenCloseModal(false);

            refetchTravelokaListings(true);
          })
          .catch(ex => {
            const errorUpdateMessage = generateNotificationMessage(MESSAGE_TYPE.ERROR_UPDATE);
            notification.error(errorUpdateMessage);

            setIsModalLoading(false);
          });
      }
    });
  };

  const isEditMode = Object.entries(selectedTraveloka).length !== 0;

  return (
    <Card>
      <Row gutter={[0, 16]}>
        <Col>
          <Button type="primary" icon="plus" onClick={() => handleOnOpenCloseModal(true)} disabled={!isAllowCreate}>
            New Traveloka
          </Button>
        </Col>
        <Col>
          <Table
            rowKey={record => record._id}
            dataSource={travelokaListings}
            columns={getTableColumns(filtersInfo, sorterInfo, handleOnClickEdit, travelokaListings, isAllowEdit)}
            onChange={(pagination, filtersInfo, sorterInfo) => {
              handleOnTableChange(filtersInfo, sorterInfo);
            }}
            loading={isTableLoading}
          />
        </Col>
      </Row>

      {isEditMode ? (
        <Modal
          title={`Editing ${selectedTraveloka.travelokaPropertyName}`}
          visible={isShowModal}
          onOk={handleOnModalEditSave}
          onCancel={() => handleOnOpenCloseModal(false)}
          onText="Save"
          confirmLoading={isModalLoading}
          destroyOnClose={true}
        >
          <FormInput
            name="travelokaPropertyName"
            formLabel="Assign a property nickname:"
            placeholder="Please input property nickname"
            form={form}
            defaultValue={selectedTraveloka.travelokaPropertyName}
            requiredErrorMessage="Please input a property nickname"
            disabled={isModalLoading}
          />
          <FormInput
            name="travelokaActualPropertyId"
            formLabel="Input traveloka property Id (example: 441231846) :"
            placeholder="Please input traveloka property Id"
            form={form}
            defaultValue={selectedTraveloka.travelokaActualPropertyId}
            requiredErrorMessage="Please input traveloka property Id"
            disabled={isModalLoading}
          />
          <FormSelection
            name="host"
            formLabel="Assign a host:"
            placeholder="Please select and assign a host"
            form={form}
            defaultValue={guard(() => selectedTraveloka.host._id, '')}
            requiredErrorMessage="Please select a host"
            selections={hostLists}
            disabled={isModalLoading}
            allowClear={false}
          />
        </Modal>
      ) : (
        <Modal
          title="New Traveloka Property"
          visible={isShowModal}
          onOk={handleOnModalNewSave}
          onCancel={() => handleOnOpenCloseModal(false)}
          onText="Save"
          confirmLoading={isModalLoading}
          destroyOnClose={true}
        >
          <FormInput
            name="travelokaPropertyId"
            formLabel="Traveloka hotel code:"
            placeholder="Traveloka hotel code (example: BCUrjagLmelB)"
            form={form}
            requiredErrorMessage="Please input traveloka hotel code"
            disabled={isModalLoading}
          />
        </Modal>
      )}
    </Card>
  );
};

export default Form.create()(withAppContext(TravelokaListing));
