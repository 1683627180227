import { Button, Table } from 'antd';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { AppContext } from 'context/AppContext';

import { formatToDateString, getCurrentMoment } from 'utils/date';
import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';
import { buildTaskManagementPresetTaskCreateUri, buildTaskManagementPresetTaskEditUri } from 'utils/routes';
import { getAllPresetTasks } from 'utils/apis/taskManagement';

import { StyledCard, StyledHeader } from './PresetTask.styles';

const PresetTask = () => {
  const contextProvider = useContext(AppContext);
  const [isLoading, setLoading] = useState(true);
  const [presetTasks, setPresetTasks] = useState([]);

  useEffect(() => {
    getAllPresetTasks().then(res => {
      // console.log(res);
      if (res.status === 200) {
        setPresetTasks(res.data);
        setLoading(false);
      }
    });
  }, []);

  const constructColumns = () => {
    return [
      {
        ...constructColumn('Preset Task Name', 'taskName', { linkFunc: record => buildTaskManagementPresetTaskEditUri(record._id) }),
        ...constructColumnFilterSearch('taskName', 'Search Task')
      },
      {
        ...constructColumn('Property Name', 'property'),
        ...constructColumnFilterSearch('property', 'Search Property')
      },
      {
        ...constructColumn('Cleaning Team', 'cleaningTeam'),
        ...constructColumnFilterSearch('cleaningTeam', 'Search Cleaning Team')
      },
      {
        ...constructColumn('# of Cleaner', 'noOfCleaner', { hasSorter: true })
        // ...constructColumnFilterSearch('noOfCleaner', 'Search Cleaning Team')
      },
      {
        ...constructColumn('Checklist Use', 'checklist'),
        ...constructColumnFilterSearch('checklist', 'Search Checklist')
      }
    ];
  };

  const constructCSV = presetTasks => {
    return presetTasks.map(presetTask => {
      return {
        'Preset Task Name': presetTask.taskName,
        'Property Name': presetTask.property,
        'Cleaning Team': presetTask.cleaningTeam,
        '# of Cleaner': presetTask.noOfCleaner,
        Checklist: presetTask.checklist,
        'Units Applied': presetTask.units,
        Remarks: presetTask.remark
      };
    });
  };

  const currentDate = useMemo(() => formatToDateString(getCurrentMoment()), []);
  const csvName = useMemo(() => `preset_tasks_${currentDate}.csv`, [currentDate]);

  return (
    <StyledCard>
      <StyledHeader>
        {contextProvider.checkIsAllowCreateTask() && (
          <Link to={buildTaskManagementPresetTaskCreateUri()}>
            <Button id="create-button-preset-task" type="primary" icon="plus" disabled={contextProvider.checkIsAdminReadOnly()}>
              Create Preset Task
            </Button>
          </Link>
        )}
        <CSVLink filename={csvName} data={constructCSV(presetTasks)}>
          <Button id="csv-button-tasklist" type="primary" icon="download" disabled={contextProvider.checkIsAdminReadOnly()}>
            Download CSV
          </Button>
        </CSVLink>
      </StyledHeader>
      <Table loading={isLoading} scroll={{ x: 1000 }} rowKey={record => record._id} columns={constructColumns()} dataSource={presetTasks} />
    </StyledCard>
  );
};

export default PresetTask;
