import { httpClient } from './../httpClient';
import { apiErrorHandler } from './helpers';
import { useCustomPaginatedQuery } from 'hooks/reactQuery';

export const getRateModifiers = () =>
  httpClient
    .get('/rateModifier/')
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getRateModifiersByHost = hostId =>
  httpClient
    .get('/rateModifier/getRateModifiersByHost/' + hostId)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getPromotionsByHost = hostId =>
  httpClient
    .get('/rateModifier/getPromotionsByHost/' + hostId)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getRateModifierById = rateModifierId =>
  httpClient
    .get('/rateModifier/' + rateModifierId)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const createRateModifier = body =>
  httpClient
    .post('/rateModifier/', body)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const updateRateModifier = (id, body) =>
  httpClient
    .put(`/rateModifier/${id}`, body)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const deleteRateModifier = rateModifierId =>
  httpClient
    .delete('/rateModifier/' + rateModifierId)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const useGetPaginatedRateModifiers = query => {
  const getPaginatedRateModifier = async (key, query) => {
    return httpClient
      .get('/rateModifier/paginated', { params: query })
      .then(res => res.data)
      .catch(apiErrorHandler);
  };

  return useCustomPaginatedQuery('rateModifiers', getPaginatedRateModifier, query);
};

export const useGetPaginatedPromotions = query => {
  const getPaginatedPromotions = async (key, query) => {
    return httpClient
      .get('/rateModifier/promotion/paginated', { params: query })
      .then(res => res.data)
      .catch(apiErrorHandler);
  };

  return useCustomPaginatedQuery('promotions', getPaginatedPromotions, query);
};
