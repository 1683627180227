import { useCallback, useMemo, useState } from 'react';
import { useQuery, usePaginatedQuery, queryCache } from 'react-query';
import { message } from 'antd';
import { guard } from 'utils/general';

const useDisplayError = (shouldDisplayError, error, prefixErrorMessage) => {
  const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);

  if (!isErrorDisplayed && shouldDisplayError && !!error) {
    setIsErrorDisplayed(true);
    console.error(error);

    const baseErrorMessage = error.message || 'Something went wrong while retriving data, please contact our technical support';
    const errorMessage = `${prefixErrorMessage}${baseErrorMessage}`;
    message.error(errorMessage);
  }
};

export const useCustomQuery = (
  key,
  paramsInArray = [],
  apiFunction,
  {
    shouldDisplayError = true,
    shouldDefaultEmptyObject = true,
    prefixErrorMessage = '',
    shouldFetch = true,
    postProcessFunc = returnedData => returnedData,
    ...queryOptions
  } = {}
) => {
  const keysInQuery = useMemo(() => [key, ...paramsInArray], [key, paramsInArray]);

  const {
    data,
    isFetching, // isFetching is the loading state that we always expected
    isLoading, // We deconstruct isLoading to prevent overriding our custom field when return
    error,
    ...returnedParams
  } = useQuery(keysInQuery, (...params) => apiFunction(...params).then(postProcessFunc), { enabled: shouldFetch, ...queryOptions });
  useDisplayError(shouldDisplayError, error, prefixErrorMessage);

  const returnedData = useMemo(() => data || (shouldDefaultEmptyObject ? {} : data), [shouldDefaultEmptyObject, data]);
  const refetch = useCallback(() => queryCache.invalidateQueries(keysInQuery), [keysInQuery]);
  // isFetching is alaways true even when it is not fetching.
  return { isLoading: isFetching, data: returnedData, error, refetch, ...returnedParams };
};

export const useCustomPaginatedQuery = (
  key,
  apiFunction,
  { filter, sorter, currentPage = 1, limit = 10, ...otherQuery } = {},
  {
    shouldDisplayError = true,
    shouldDefaultEmptyObject = true,
    prefixErrorMessage = '',
    shouldFetch = true,
    postProcessFunc = returnedData => returnedData,
    ...queryOptions
  } = {}
) => {
  const queryToFetch = {
    ...(filter && { filter: encodeURIComponent(JSON.stringify(filter)) }),
    ...(sorter && { sort: encodeURIComponent(JSON.stringify(sorter)) }),
    pagination: encodeURIComponent(JSON.stringify({ limit, currentPage })),
    ...otherQuery
  };

  const {
    resolvedData,
    latestData,
    error,
    isFetching, // isFetching is the loading state that we always expected
    isLoading, // We deconstruct isLoading to prevent overriding our custom field when return
    ...returnedParams
  } = usePaginatedQuery([key, filter, sorter, currentPage, limit, otherQuery], () => apiFunction(key, queryToFetch).then(postProcessFunc), {
    enabled: shouldFetch,
    ...queryOptions
  });
  useDisplayError(shouldDisplayError, error, prefixErrorMessage);
  const refetch = useCallback(() => queryCache.invalidateQueries([key]), [key]);

  return {
    isLoading: isFetching,
    paginatedData: guard(() => resolvedData[key], []),
    total: guard(() => resolvedData.totalCount, 0),
    error,
    latestData,
    refetch,
    ...returnedParams
  };
};
