import React from 'react';
import { Card, Col, Row } from 'antd';
import PropTypes from 'prop-types';

import FormInput from 'components/FormInput/FormInput';

class DetailedDescriptionCard extends React.Component {
  render() {
    const { form, cardClassname, defaultValues } = this.props;
    return (
      <Card title="Detailed Description" className={cardClassname}>
        <Row gutter={16}>
          <Col span={24}>
            <FormInput
              inputType="textarea"
              label="Description"
              name="summary"
              placeholder="Your unit description should help guests to imagine what it's like to stay at your place."
              requiredErrorMessage="Please provide summary description of your place."
              form={form}
              defaultValue={defaultValues.summary}
              size="large"
            />

            <FormInput
              inputType="textarea"
              label="About your place"
              name="space"
              placeholder="Give more information about what makes your space unique."
              requiredErrorMessage="Please provide information about what makes your space unique."
              form={form}
              defaultValue={defaultValues.space}
              size="large"
            />

            <FormInput
              inputType="textarea"
              label="Overview"
              name="neighborhoodOverview"
              placeholder="Let guests know in English what your neighborhood is like, and what makes it unique."
              requiredErrorMessage="Please provide description about your place neighbourhood"
              form={form}
              defaultValue={defaultValues.neighborhoodOverview}
              size="large"
            />

            <FormInput
              inputType="textarea"
              label="Getting around"
              name="transit"
              placeholder="You can let guests know if your listing is close to public transportation (or far from it). You can also mention nearby parking options."
              requiredErrorMessage="Please provide description about how to access your place"
              form={form}
              defaultValue={defaultValues.transit}
              size="large"
            />

            <FormInput
              inputType="textarea"
              label="Guest access"
              name="access"
              placeholder="Let guests know what parts of the space they will be able to access."
              form={form}
              defaultValue={defaultValues.access}
              size="large"
            />

            <FormInput
              inputType="textarea"
              label="Other things to note"
              name="notes"
              placeholder="Let guests know if there are other details that will impact their stay."
              form={form}
              defaultValue={defaultValues.notes}
              size="large"
            />

            <FormInput
              inputType="textarea"
              label="House rules"
              name="houseRules"
              placeholder="You can include any requirements around safety concerns, shared space rules, and your community regulations, as well as rules like: 'No solo travelers' or 'Quiet time after 10pm'"
              form={form}
              defaultValue={defaultValues.houseRules}
              size="large"
            />

            <FormInput
              inputType="textarea"
              label="Guest interaction"
              name="interaction"
              placeholder="Tell guests if you'll be available to offer help throughout their stay."
              form={form}
              defaultValue={defaultValues.interaction}
              size="large"
            />
          </Col>
        </Row>
      </Card>
    );
  }
}

DetailedDescriptionCard.propTypes = {
  form: PropTypes.object.isRequired,
  cardClassname: PropTypes.string.isRequired,
  defaultValues: PropTypes.object
};

DetailedDescriptionCard.defaultProps = {
  defaultValues: {}
};

export default DetailedDescriptionCard;
