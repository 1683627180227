import { httpClient } from './../httpClient';
import { apiErrorHandler } from './helpers';

const REPORTING_URI = '/reporting';

export const getProfitAndExpenses = (propertyId, date) => {
  return httpClient
    .get(`${REPORTING_URI}/profitAndExpenses`, { params: { propertyId, date }, timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const getOccupancyComparison = (propertyId, date, type) => {
  return httpClient
    .get(`${REPORTING_URI}/occupancyComparison`, { params: { propertyId, date, type }, timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const getOccupancyByType = (propertyId, date, type) => {
  return httpClient
    .get(`${REPORTING_URI}/occupancyByType`, { params: { propertyId, date, type }, timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const getRevenueComparison = (propertyId, date, type) => {
  return httpClient
    .get(`${REPORTING_URI}/revenueComparison`, { params: { propertyId, date, type }, timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const getRevenueByType = (propertyId, date, type) => {
  return httpClient
    .get(`${REPORTING_URI}/revenueByType`, { params: { propertyId, date, type }, timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const getMonthlyRevenue = (propertyId, date) => {
  return httpClient
    .get(`${REPORTING_URI}/monthlyRevenueBreakdown`, { params: { propertyId, date }, timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const getOwnerHostProfitData = (propertyId, date) => {
  return httpClient
    .get(`${REPORTING_URI}/ownerHostProfitBreakdown`, { params: { propertyId, date }, timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const getPlatformSourceRevenue = (propertyId, date) => {
  return httpClient
    .get(`${REPORTING_URI}/platformSourceRevenueBreakdown`, { params: { propertyId, date }, timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const getMonthlyExpenses = (propertyId, date) => {
  return httpClient
    .get(`${REPORTING_URI}/monthlyExpensesBreakdown`, { params: { propertyId, date }, timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const getIsReportFreeze = (propertyId, date) => {
  return httpClient
    .get(`${REPORTING_URI}/checkFreezeReportSnapshot`, { params: { propertyId, date }, timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const freezeReport = data => {
  return httpClient
    .post(`${REPORTING_URI}/freezeReport`, data)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);
};
