import React from 'react';
import { Row, Col } from 'antd';
import FormCounter from 'components/FormCounter/FormCounter';

import styles from './RowFormCounter.module.css';

const RowFormCounter = ({ form, label, name, defaultValue }) => {
  return (
    <Row type="flex" gutter={{ span: 32, md: 0 }} className={styles.counterContainer}>
      <Col span={12} md={4}>
        <p style={{ marginBottom: '8px' }}>{label}</p>
      </Col>
      <Col span={12} md={8}>
        <FormCounter form={form} name={name} defaultValue={defaultValue} />
      </Col>
    </Row>
  );
};

export default RowFormCounter;
