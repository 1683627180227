/*
When changing anything related to height for MCHeader, please confirm if the trembling issue will not reproduce.
issue details: https://trello.com/c/pDcfFs9I/1430-multicalendar-trembling-when-trying-to-stick-the-date-header
*/
import React from 'react';
import { Button, Checkbox } from 'antd';

import CalendarHeader from './components/CalendarHeader/CalendarHeader';
import ClashingReservationWarning from './components/ClashingReservationWarning/ClashingReservationWarning';
import ReservationColorLegend from './components/ReservationColorLegend/ReservationColorLegend';

import { BasicSelect, MCBookingStatusSelect } from 'components/FormSelection/FormSelection';

import styles from './MCHeader.module.css';

const CheckboxGroup = Checkbox.Group;

const MCHeader = ({
  isAdmin,
  month,

  clashingReservationDetails,
  bookingStatusTypeOptions,
  hostOptions,
  propertyOptions,
  filteredIntegrationSourceOptions,

  selectedHostId,
  selectedPropertyId,
  selectedBookingTypeStatus,
  selectedRateFilters,

  onMonthChange,
  onHostFilterChange,
  onPropertyFilterChange,
  onBookingStatusFilterChange,
  onRateCheckboxGroupChange,
  onClearButtonClick,
  onRateUpdateWizardButtonClick,
  onExpandAllRoomTypesClick,

  reservationsColorsConstants,

  clashingReservationIconStyle,

  isRUWButtonDisabled,
  onBlockWizardButtonClick
}) => {
  const hasClashingReservation = clashingReservationDetails && clashingReservationDetails.totalNumberOfClashes > 0;
  const hasMultiHost = hostOptions.length > 1;

  return (
    <>
      {hasClashingReservation && (
        <ClashingReservationWarning
          className={styles.ClashingReservationWarningBanner}
          isAdmin={isAdmin}
          clashingReservationDetails={clashingReservationDetails}
          clashingReservationIconStyle={clashingReservationIconStyle}
          hostId={selectedHostId}
        />
      )}

      <div className={styles.firstRow}>
        <div className={styles.filterRow}>
          <BasicSelect
            label="Host"
            selections={hostOptions}
            value={selectedHostId}
            selectClassName={styles.selectionHost}
            placeholder="No Host"
            onChange={onHostFilterChange}
            isDisabled={!hasMultiHost}
          />

          <BasicSelect
            label="Property"
            selections={propertyOptions}
            value={selectedPropertyId}
            selectClassName={styles.selectionProperty}
            placeholder="No Property"
            onChange={onPropertyFilterChange}
          />

          {/* <BasicSelect
            label="Booking Status"
            selections={bookingStatusTypeOptions}
            value={selectedBookingTypeStatus}
            selectClassName={styles.selectionStatus}
            placeholder="No Booking Status"
            onChange={onBookingStatusFilterChange}
            isMultiple
          />

          <Button id="clear-button1-multical" type="secondary" onClick={onClearButtonClick}>
            Clear
          </Button> */}
        </div>
      </div>

      <div className={styles.secondRow}>
        <CheckboxGroup options={filteredIntegrationSourceOptions} value={selectedRateFilters} onChange={onRateCheckboxGroupChange} />
      </div>
      {/* <div>
        <ReservationColorLegend reservationsColorsConstants={reservationsColorsConstants} />
      </div> */}
      <div className={styles.bookingStatusRow}>
        <div className={styles.filterRow}>
          <MCBookingStatusSelect
            label="Booking Status"
            selections={bookingStatusTypeOptions}
            value={selectedBookingTypeStatus}
            selectClassName={styles.selectionStatus}
            placeholder="No Booking Status"
            onChange={onBookingStatusFilterChange}
            isMultiple
          />

          <Button id="clear-button1-multical" type="secondary" onClick={onClearButtonClick}>
            Reset
          </Button>
        </div>
      </div>

      <CalendarHeader
        month={month}
        selectedPropertyId={selectedPropertyId}
        selectedRateFilters={selectedRateFilters}
        onMonthChange={onMonthChange}
        onRateUpdateWizardButtonClick={onRateUpdateWizardButtonClick}
        onExpandAllRoomTypesClick={onExpandAllRoomTypesClick}
        isRUWButtonDisabled={isRUWButtonDisabled}
        onBlockWizardButtonClick={onBlockWizardButtonClick}
      />
    </>
  );
};

export default MCHeader;
