import React from 'react';
import PropTypes from 'prop-types';

import { BareRadioButton } from 'components/FormRadioButton/FormRadioButton';

const handleOnChangeListingPageType = (e, setSelectedListingType) => {
  const selectedPageType = e.target.value;
  setSelectedListingType(selectedPageType);
};

const Header = ({ permittedListingTypes, setSelectedListingType, selectedListingType }) => {
  return (
    permittedListingTypes.length > 1 && (
      <BareRadioButton
        options={permittedListingTypes}
        onChange={event => handleOnChangeListingPageType(event, setSelectedListingType)}
        value={selectedListingType}
      />
    )
  );
};

Header.propTypes = {
  permittedListingTypes: PropTypes.array.isRequired,
  setSelectedListingType: PropTypes.func.isRequired
};

export default Header;
