import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Tooltip } from 'antd';

import { buildOverviewUri } from 'utils/routes';

import styles from './Header.module.css';

const Header = ({ history, userId, firstName, userProfileAvatar }) => {
  const handleOnHostViewButtonClick = () => {
    history.push(buildOverviewUri());
  };

  return (
    <Row className={styles.header}>
      <Col span={12}>
        <Button onClick={handleOnHostViewButtonClick}>Back to Host View</Button>
      </Col>
      <Col span={12} className={styles.userProfileRow}>
        <Link className={styles.userProfileLink} to={`/userProfile/${userId}`}>
          <Tooltip title="User Profile">
            <img className="avatar" src={userProfileAvatar} id="image" title="User profile" alt="User Icon" />
            Hi, {firstName}!
          </Tooltip>
        </Link>
      </Col>
    </Row>
  );
};

export default Header;
