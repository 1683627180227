import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import UserProfilePasswordForm from './../../components/UserProfilePasswordForm/UserProfilePasswordForm';

class UserProfileResetPassword extends Component {
  render() {
    return (
      <Row>
        <Col>
          <UserProfilePasswordForm />
        </Col>
      </Row>
    );
  }
}

export default withRouter(UserProfileResetPassword);
