import { httpClient } from './../httpClient';
import { apiErrorHandler } from './helpers';
import { useCustomPaginatedQuery } from 'hooks/reactQuery';

export const getReservations = options => {
  let query = {};
  if (options) {
    const { filters, pagination, sorter, startDate, endDate, startDateOnly, bookingStatus } = options;
    if (filters) {
      const { confirmationCode, propertyName, unitName, contactNumber, guestName } = filters;
      query = {
        code: confirmationCode && confirmationCode[0],
        propertyName: propertyName && propertyName[0],
        unitName: unitName && unitName[0],
        contactNo: contactNumber && contactNumber[0],
        guestName: guestName && guestName[0],
        bookingStatuses: bookingStatus
      };
    }
    if (pagination) {
      const { pageSize, current } = pagination;
      query = {
        ...query,
        limit: pageSize,
        currentPage: current
      };
    }
    if (sorter) {
      const { field, order } = sorter;
      query = {
        ...query,
        sortBy: field,
        orderBy: order
      };
    }
    if (startDate && endDate) {
      query = {
        ...query,
        startDate,
        endDate
      };
    }
    if (startDateOnly) {
      query = {
        ...query,
        startDateOnly
      };
    }
  }

  return httpClient
    .get('/reservation/populated', { params: query, timeout: 180000 })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getReservationsCSV = options => {
  const query = { ...options, limit: options.pageSize };

  return httpClient
    .get('/reservation/csv', { params: query, timeout: 180000 })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response;
    });
};

export const getReservationCountForCSV = query => {
  return httpClient
    .get('/reservation/csv/count', { params: query })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response;
    });
};

export const createReservation = reservationDetails => {
  return httpClient
    .post('/reservation', reservationDetails)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getReservationsById = (id, isWithTransaction) => {
  return httpClient
    .get(`/reservation/${id}/populated`, { params: isWithTransaction })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const deleteReservation = id => {
  return httpClient
    .delete(`/reservation/${id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const deleteMultipleBlockReservation = reservationDetails => {
  return httpClient
    .post('/reservation/delete-multiple', reservationDetails)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const updateReservation = (id, body, isIgnoreClash) => {
  return httpClient
    .put(`/reservation/${id}`, body, { params: { isIgnoreClash } })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const calculatePrice = (
  unitId,
  rateId,
  startDate,
  endDate,
  cleaningFee = 0,
  extraGuestFee = 0,
  earlyCheckin = 0,
  lateCheckout = 0,
  transportFee = 0,
  breakfastFee = 0,
  lunchFee = 0,
  dinnerFee = 0,
  otherFee = 0,
  shuttleFee = 0,
  tax,
  addOns,
  rateModifier
) => {
  const query = {
    unitId,
    rateId,
    startDate,
    endDate,
    cleaningFee,
    extraFee: extraGuestFee,
    earlyCheckin,
    lateCheckout,
    transportFee,
    breakfastFee,
    lunchFee,
    dinnerFee,
    otherFee,
    shuttleFee,
    tax: JSON.stringify(tax),
    addOns: JSON.stringify(addOns),
    rateModifier
  };

  return httpClient
    .get('/reservation/calculatePrice', { params: query })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

/**
 * @param
 * propertyId : Id of the property
 * startDate: 'YYYY-MM-DD'
 * endDate: 'YYYY-MM-DD'
 * unitViews: [ string ]
 */
export const getUnitListings = ({ propertyId, roomTypeId, startDate, endDate, totalOccupants, unitViews, isBookingLogic }) => {
  const query = {
    startDate,
    endDate,
    property: propertyId,
    roomType: roomTypeId,
    totalOccupants,
    unitViews,
    isBookingLogic
  };

  return httpClient
    .get('/reservation/getUnitListings', { params: query })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const useGetPaginatedReservations = query => {
  const getPaginatedReservations = async (key, query) => {
    return httpClient
      .get('/reservation/paginated', { params: query, timeout: 180000 })
      .then(res => res.data)
      .catch(apiErrorHandler);
  };

  return useCustomPaginatedQuery('reservations', getPaginatedReservations, query);
};

export const SendReservationCsv = data => {
  return httpClient
    .post('/reservation/send-reservation-csv', data)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);
};

export const SendAdminReservationCsv = data => {
  return httpClient
    .post('/csvExportQueue/downloadCSV', data)
    .then(response => {
      return response;
    })
    .catch(err => err);
  // .catch(apiErrorHandler);
};
