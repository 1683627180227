import React, { Component } from 'react';
import { Form, Icon, Button, Row, Col, Popconfirm, Card, Tooltip } from 'antd';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormSelection from 'components/FormSelection/FormSelection';
import FormInput from 'components/FormInput/FormInput';

import styles from './TaxFieldSet.module.css';

const taxTypes = [
  { key: 'pass_through_hotel_tax', value: 'pass_through_hotel_tax', displayValue: 'Hotel tax' },
  { key: 'pass_through_lodging_tax', value: 'pass_through_lodging_tax', displayValue: 'Lodging tax' },
  { key: 'pass_through_room_tax', value: 'pass_through_room_tax', displayValue: 'Room tax' },
  { key: 'pass_through_tourist_tax', value: 'pass_through_tourist_tax', displayValue: 'Tourist tax' },
  { key: 'pass_through_transient_occupancy_tax', value: 'pass_through_transient_occupancy_tax', displayValue: 'Transient occupancy tax' },
  { key: 'pass_through_sales_tax', value: 'pass_through_sales_tax', displayValue: 'Sales tax' },
  { key: 'pass_through_vat_gst', value: 'pass_through_vat_gst', displayValue: 'VAT/GST' },
  { key: 'pass_through_tourism_assessment_fee', value: 'pass_through_tourism_assessment_fee', displayValue: 'Tourism Assessment/Fee' }
];

const amountTypes = [
  { key: 'percent_per_reservation', value: 'percent_per_reservation', displayValue: 'Percentage per booking' },
  { key: 'flat_per_guest', value: 'flat_per_guest', displayValue: 'Fee per guest' },
  { key: 'flat_per_guest_per_night', value: 'flat_per_guest_per_night', displayValue: 'Fee per guest, per night' },
  { key: 'flat_per_night', value: 'flat_per_night', displayValue: 'Fee per night' }
];

class TaxProvided extends Component {
  constructor(props) {
    super(props);
    this.state = {
      update: false
    };
  }

  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const newKeys = form.getFieldValue('keys').filter(key => key !== k);
    // can use data-binding to set
    form.setFieldsValue({
      keys: newKeys
    });

    form.validateFields(
      newKeys.reduce((fieldsToValidate, key) => {
        return [
          ...fieldsToValidate,
          `taxType[${key}]`,
          `amountType[${key}]`,
          `taxAmount[${key}]`,
          `businessTaxID[${key}]`,
          `accommodationsTaxRegistrationNumber[${key}]`,
          `minimumDaysTaxExemption[${key}]`,
          `provideExemptionsLongTermStay[${key}]`
        ];
      }, [])
    );
  };

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(Math.max(0, ...keys) + 1);
    form.setFieldsValue({
      keys: nextKeys
    });

    const newValues = form.getFieldsValue();
    newValues.keys = nextKeys; //in case setFieldsValue sets keys after the getFieldsValue
  };

  componentDidMount() {
    const { defaultValues, form } = this.props;
    let keys = defaultValues.map(d => d.key);
    if (keys.length > 0) {
      form.setFieldsValue(
        {
          keys
        },
        this.setState({
          update: true
        })
      );
    }
  }

  componentDidUpdate(prevProp, prevState) {
    const { defaultValues, form } = this.props;
    const { update } = this.state;
    if (update) {
      defaultValues.forEach(defaultValue => {
        let obj = {};
        let key = `taxType[${defaultValue.key}]`;
        let key2 = `amountType[${defaultValue.key}]`;
        let key3 = `taxAmount[${defaultValue.key}]`;
        let key4 = `businessTaxID[${defaultValue.key}]`;
        let key5 = `accommodationsTaxRegistrationNumber[${defaultValue.key}]`;
        let key6 = `minimumDaysTaxExemption[${defaultValue.key}]`;
        obj[key] = defaultValue.taxType;
        obj[key2] = defaultValue.amountType;
        obj[key3] = defaultValue.taxAmount;
        obj[key4] = defaultValue.businessTaxID;
        obj[key5] = defaultValue.accommodationsTaxRegistrationNumber;
        obj[key6] = defaultValue.minimumDaysTaxExemption;
        form.setFieldsValue({
          ...obj
        });
      });
      this.setState({
        update: false
      });
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { form, defaultValues, currency } = this.props;

    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const switchGeneralSelections = [{ key: true, value: true, displayValue: 'Yes' }, { key: false, value: false, displayValue: 'No' }];
    const formItems = keys.map((k, index) => {
      const provideExemptionsLongTermStay = getFieldValue(`provideExemptionsLongTermStay[${k}]`);
      const showTaxExemption =
        provideExemptionsLongTermStay === undefined ? defaultValues[k] && defaultValues[k].minimumDaysTaxExemption : provideExemptionsLongTermStay;
      const output = getFieldValue(`amountType[${k}]`) === 'percent_per_reservation' ? '%' : currency || 'RM';
      return (
        <React.Fragment key={index}>
          <Card className={styles.formItem} style={{ width: '97.5%' }}>
            <Row justify="start" gutter={8}>
              <Col span={24}>
                <FormSelection
                  form={form}
                  formLabel="Tax Type"
                  name={`taxType[${k}]`}
                  selections={taxTypes}
                  placeholder="Choose one"
                  requiredErrorMessage="Please select tax type"
                />
              </Col>
            </Row>
            <Row justify="start" gutter={8}>
              <Col span={24} lg={12}>
                <FormSelection
                  form={form}
                  formLabel="Type of Charge"
                  name={`amountType[${k}]`}
                  selections={amountTypes}
                  requiredErrorMessage="Please select type of charge"
                  placeholder="Choose one"
                />
              </Col>
              <Col span={24} lg={12}>
                <FormInputNumber
                  form={form}
                  formLabel={`Tax Amount (${output})`}
                  name={`taxAmount[${k}]`}
                  minValue={0}
                  precision={2}
                  requiredErrorMessage="Please input tax amount"
                />
              </Col>
            </Row>
            <Row justify="start" gutter={8}>
              <Col span={24} lg={12}>
                <FormInput form={form} formLabel="Business Tax ID" name={`businessTaxID[${k}]`} requiredErrorMessage="Please input business tax ID" />
              </Col>
              <Col span={24} lg={12}>
                <FormInput
                  form={form}
                  formLabel="Accomodations Tax Registration Number"
                  name={`accommodationsTaxRegistrationNumber[${k}]`}
                  requiredErrorMessage="Please input accomodations tax registration number"
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <FormRadioButton
                  form={form}
                  formLabel="Does your region provide exemptions for long-term stay?"
                  name={`provideExemptionsLongTermStay[${k}]`}
                  defaultValue={!!showTaxExemption}
                  buttonStyle="solid"
                  selections={switchGeneralSelections}
                />
              </Col>
            </Row>
            {!!showTaxExemption && (
              <Row>
                <Col span={24}>
                  <label>Minimum number of days required for tax exemption </label>
                  <Tooltip title="Long term stay exemption is between 13 and 185 days">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                  <FormInputNumber
                    form={form}
                    name={`minimumDaysTaxExemption[${k}]`}
                    minValue={13}
                    maxValue={185}
                    requiredErrorMessage="Please input minimum number of days"
                  />
                </Col>
              </Row>
            )}
            <Popconfirm title="Are you sure you want to remove this tax?" onConfirm={() => this.remove(k)} okText="Yes" cancelText="No">
              <Button className="booking-form-danger" type="danger" style={{ width: '100%' }}>
                <Icon type="minus" /> Remove tax
              </Button>
            </Popconfirm>
          </Card>
        </React.Fragment>
      );
    });
    return (
      <Form>
        {formItems}
        <Button type="dashed" onClick={this.add} style={{ width: '97.5%' }}>
          <Icon type="plus" /> Add tax
        </Button>
      </Form>
    );
  }
}

const WrappedTaxProvided = TaxProvided;
export default WrappedTaxProvided;
