import React, { Component, Fragment } from 'react';
import { Alert, Input, Form, Slider, Button, Select, Radio, InputNumber, Card, Tooltip, Icon, Modal, Tabs, message, Switch, Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { AppContextConsumer } from 'context/AppContext';
import { withAppContext } from 'context/AppContext';

import { getConstants, getPayoutCalcMethodConstant, getChargesTypesConstant } from 'utils/apis/constants';
import { getHosts } from 'utils/apis/host';
import { getRevenueShare } from 'utils/apis/revenueshare';
import {
  createServicePackage,
  getServicePackageById,
  updateServicePackage,
  deleteServicePackage,
  getServicePackageUnits
} from 'utils/apis/servicePackage';
import { generateEndDate, generateNumberIndicator } from 'utils/general';

import CloseButton from 'components/CloseButton/CloseButton';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

import './ServicePackageForm.css';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const TabPane = Tabs.TabPane;

class ServicePackageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hostOptions: [],
      unitOptions: [],
      packageTypeOptions: [],
      revenueShareOptions: [],
      payoutCalcMethodOptions: [],
      selectedPayoutCalcMethod: '',
      threshold: 0,
      ownerShareAfterThreshold: 0,
      adminCharges: 0,
      hidePayout: false,
      mode: 'new',
      loading: true,
      hasFetchedChargesTypes: false,
      shareTypes: null,
      thresholdTypes: null,
      dataCalculations: [],
      dataChargesSetting: {},
      startDay: 1,
      startDayMessage: '',
      chargesTypes: [],
      isAllowToAddHostCharges: true,
      isSaveButtonLoading: false,
      isDeleteButtonLoading: false,
      hostCharge: 0
    };
    this.generateSubmitValue = this.generateSubmitValue.bind(this);
    this.getCalculationScehma = this.getCalculationScehma.bind(this);
    this.hidePayoutChange = this.hidePayoutChange.bind(this);
  }

  componentDidMount = () => {
    const {
      form,
      match: {
        params: { id }
      }
    } = this.props;

    this.fetchChargesTypes();

    getRevenueShare()
      .then(res => {
        if (res && res.status === 200 && Array.isArray(res.data)) {
          getConstants('shareTypes').then(shareTypesRes => {
            getConstants('thresholdTypes').then(thresholdTypesRes => {
              this.setState({
                revenueShareOptions: res.data.map(u => {
                  return { value: u._id, label: u.name, calculations: u.calculations };
                }),
                thresholdTypes: thresholdTypesRes.data,
                shareTypes: shareTypesRes.data
              });
            });
          });
        } else {
        }
      })
      .catch(ex => {});

    if (id) {
      getServicePackageById(id)
        .then(res => {
          if (res && res.status === 200) {
            const hasHostCharges = res.data.hostCharges && Object.keys(res.data.hostCharges).length > 0;
            form.setFields({
              units: {
                value: res.data.units
              },
              host: {
                value: res.data.host
              },
              name: {
                value: res.data.name
              },
              packageType: {
                value: res.data.packageType
              },
              revenueShare: {
                value: res.data.revenueShare
              },
              ownerShare: {
                value: res.data.calculations[0].ownerShare // TODO: REMOVE THIS
              },
              water: {
                value: res.data.expensesSetting ? res.data.expensesSetting.water : 'shared'
              },
              electricity: {
                value: res.data.expensesSetting ? res.data.expensesSetting.electricity : 'shared'
              },
              internet: {
                value: res.data.expensesSetting ? res.data.expensesSetting.internet : 'shared'
              },
              cleaningFee: {
                value: res.data.expensesSetting ? res.data.expensesSetting.cleaningFee : 'shared'
              },
              laundryFee: {
                value: res.data.expensesSetting ? res.data.expensesSetting.laundryFee : 'shared'
              },
              serviceFee: {
                value: res.data.expensesSetting ? res.data.expensesSetting.serviceFee : 'shared'
              },
              checkInFee: {
                value: res.data.expensesSetting ? res.data.expensesSetting.checkInFee : 'shared'
              },
              checkOutFee: {
                value: res.data.expensesSetting ? res.data.expensesSetting.checkOutFee : 'shared'
              },
              toiletryFee: {
                value: res.data.expensesSetting ? res.data.expensesSetting.toiletryFee : 'shared'
              },
              otherFee: {
                value: res.data.expensesSetting ? res.data.expensesSetting.otherFee : 'shared'
              },
              adminFee: {
                value: res.data.expensesSetting ? res.data.expensesSetting.adminFee : 'shared'
              },
              isChargedAdminFee: {
                value: res.data.adminCharges && res.data.adminCharges > 0 ? true : false
              },
              hidePayout: {
                value: res.data.hidePayout
              },
              payoutCalcMethod: {
                value: res.data.payoutCalcMethod
              },
              splitBySqft: {
                value: res.data.splitBySqft ? true : false
              },
              isChargedHostCharge: {
                value: hasHostCharges
              },
              isSeparateHostCharge: {
                value: hasHostCharges ? res.data.hostCharges.showInPayout : false
              },
              hostChargeLabel: {
                value: hasHostCharges ? res.data.hostCharges.label : undefined
              },
              hostChargeRate: { value: hasHostCharges ? res.data.hostCharges.value : 0 }
            });

            this.changeHost(res.data.host);

            const startDay = res.data.startDay || 1;
            const startDayMessage = this.getStartDayMessage(startDay);
            this.setState({
              startDay,
              startDayMessage,
              isAllowToAddHostCharges: hasHostCharges || res.data.allowToAddHostCharges
            });

            if (res.data.calculations.length > 1) {
              this.setState({
                threshold: res.data.calculations[1].threshold.amount, // TODO: REMOVE THIS
                ownerShareAfterThreshold: res.data.calculations[1].ownerShare, // TODO: REMOVE THIS
                adminCharges: res.data.adminCharges,
                mode: 'edit',
                loading: false,
                dataCalculations: res.data.calculations,
                dataChargesSetting: res.data.chargesSetting,
                hidePayout: res.data.hidePayout
              });
            } else {
              this.setState({
                mode: 'edit',
                calculations: res.data.calculations,
                loading: false,
                adminCharges: res.data.adminCharges,
                dataCalculations: res.data.calculations,
                dataChargesSetting: res.data.chargesSetting,
                hidePayout: res.data.hidePayout
              });
            }
          } else {
            console.log('There was an error fetching this service package data');
            this.setState({ loading: false });
          }
        })
        .catch(ex => {
          console.log(ex);
          this.setState({ loading: false });
        });
    } else {
      const startDayMessage = this.getStartDayMessage(1);
      this.setState({ startDayMessage, loading: false });
    }

    getHosts()
      .then(res => {
        if (res && res.status === 200) {
          this.setState({
            hostOptions: res.data.map(data => {
              return { label: data.name, value: data._id, allowHostChargesInServicePackage: data.allowHostChargesInServicePackage };
            })
          });
        } else {
          console.log('There was an error fetching hosts data');
        }
      })
      .catch(ex => {
        console.log(ex);
      });

    getConstants('packageTypes')
      .then(res => {
        if (res && res.status === 200) {
          let packageTypes = Object.keys(res.data).map(k => res.data[k]);
          if (packageTypes.length > 0) {
            this.setState({
              packageTypeOptions: packageTypes.map(u => {
                return { value: u.code, label: u.label };
              })
            });
          }
        } else {
          console.log('There was an error fetching package types data');
        }
      })
      .catch(ex => {
        console.log(ex);
      });

    getPayoutCalcMethodConstant()
      .then(res => {
        const payoutCalcMethodOptions =
          res &&
          Object.keys(res).map(key => {
            return { value: res[key].code, label: res[key].label };
          });
        this.setState({
          payoutCalcMethodOptions
        });
      })
      .catch(ex => {
        console.log(ex);
      });
  };

  fetchChargesTypes = () => {
    getChargesTypesConstant()
      .then(res => {
        this.setChargesTypesState(res);
      })
      .catch(err => console.error(err));
  };

  setChargesTypesState = chargesTypes => {
    const formattedChargesTypes = Object.keys(chargesTypes)
      .filter(key => chargesTypes[key].code !== 'addon' && chargesTypes[key].code !== 'stripeCharges')
      .map(key => ({
        key: chargesTypes[key].code,
        value: chargesTypes[key].label
      }));

    this.setState({
      hasFetchedChargesTypes: true,
      chargesTypes: formattedChargesTypes
    });
  };

  generateSubmitValue = values => {
    if (!values.isChargedAdminFee) {
      values.adminCharges = 0;
    }
    let calculations = this.getCalculationScehma(values.revenueShare);
    let valueObj = JSON.parse(JSON.stringify(values));
    valueObj.calculations = valueObj.calculations.map((calculation, i) => {
      let resObj = calculation;
      resObj.type = calculations[i].shareType;
      if (calculations[i].thresholdType || calculations[i].thresholdType === 0) {
        if (!resObj.threshold) {
          resObj.threshold = {};
        }
        resObj.threshold.type = calculations[i].thresholdType;
      }
      return resObj;
    });
    valueObj.expensesSetting = {
      water: valueObj.water,
      electricity: valueObj.electricity,
      internet: valueObj.internet,
      cleaningFee: valueObj.cleaningFee,
      laundryFee: valueObj.laundryFee,
      serviceFee: valueObj.serviceFee,
      checkInFee: valueObj.checkInFee,
      checkOutFee: valueObj.checkOutFee,
      toiletryFee: valueObj.toiletryFee,
      otherFee: valueObj.otherFee,
      adminFee: valueObj.adminFee
    };
    return valueObj;
  };

  constructHostCharges = values => {
    const { isChargedHostCharge, isSeparateHostCharge, hostChargeLabel, hostChargeRate } = values;
    if (isChargedHostCharge) {
      return {
        showInPayout: isSeparateHostCharge,
        label: hostChargeLabel,
        value: hostChargeRate
      };
    }
    return undefined;
  };

  constructChargesSettingData = values => {
    const { chargesTypes } = this.state;
    let chargesSetting = {};
    chargesTypes.forEach(charge => {
      chargesSetting[charge.key] = values[charge.key];
    });
    return chargesSetting;
  };

  handleSubmit = e => {
    e.preventDefault();
    const state = this;
    const {
      history,
      form,
      match: {
        params: { id }
      }
    } = this.props;
    const { mode } = this.state;

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const revenueShareData = {
          ...this.generateSubmitValue(values),
          hostCharges: values.isChargedHostCharge ? this.constructHostCharges(values) : undefined,
          chargesSetting: this.constructChargesSettingData(values),
          payoutCalcMethod: values.payoutCalcMethod,
          splitBySqft: values.splitBySqft
        };
        if (mode === 'new') {
          state.setState({ isSaveButtonLoading: true });
          createServicePackage(revenueShareData)
            .then(() => {
              history.push('/servicepackage');
              message.success('Service package created!');
            })
            .catch(ex => {
              console.log(ex);
              message.error('Something went wrong and service package is not created.');
            });
        } else if (mode === 'edit') {
          Modal.confirm({
            title: 'Are you sure you want to overwrite existing data?',
            content: 'You will not be able to undo this action, but you may update it again.',
            onOk() {
              state.setState({ isSaveButtonLoading: true });
              updateServicePackage(id, revenueShareData)
                .then(res => {
                  if (res.status === 200) {
                    history.push('/servicepackage');
                    message.success('Service package updated!');
                  } else {
                    message.error('Something went wrong and service package is not updated.');
                  }
                })
                .catch(ex => {
                  console.log(ex);
                });
            },
            onCancel() {},
            okButtonProps: { id: 'save-cfm-button4a-editsp' },
            cancelButtonProps: { id: 'cancelsave-cfm-button4b-editsp' }
          });
        }
      } else {
        message.error('Something is wrong with the form, please review and submit again');
      }
    });
  };

  handleDelete = e => {
    const {
      match: {
        params: { id }
      },
      history
    } = this.props;
    e.preventDefault();
    const state = this;
    Modal.confirm({
      title: 'Are you sure you want to delete this service package?',
      content: 'This action cannot be reversed. Once deleted, it cannot be recovered.',
      onOk() {
        state.setState({ isDeleteButtonLoading: true });
        deleteServicePackage(id)
          .then(res => {
            if (res.status === 204) {
              history.push('/servicepackage');
              message.success('Service package deleted!');
            } else {
              message.error('Something went wrong and service package is not deleted.');
            }
          })
          .catch(ex => {
            console.log(ex);
          });
      },
      onCancel() {},
      okButtonProps: { id: 'del-cfm-button4a-editsp' },
      cancelButtonProps: { id: 'del-cfm-button4b-editsp' }
    });
  };

  changeHost = host => {
    getServicePackageUnits(host)
      .then(res => {
        if (res && res.status === 200) {
          this.setState({
            unitOptions: res.data.map(data => {
              return {
                label: data.roomType.property.name + ' | ' + data.name,
                value: data._id,
                isAssigned: data.isAssigned
              };
            })
          });
        } else {
          console.log('There was an error fetching units data');
        }
      })
      .catch(ex => {
        console.log(ex);
      });
  };

  hidePayoutChange = checked => {
    let hidePayout = this.state.hidePayout;
    if (checked) {
      hidePayout = true;
    } else {
      hidePayout = false;
    }
    this.setState({
      hidePayout
    });
  };

  getCalculationScehma = reveneShareId => {
    const { revenueShareOptions } = this.state;
    if ((reveneShareId || reveneShareId === 0) && revenueShareOptions) {
      let revenueShareOption = revenueShareOptions.filter(revenueShareOption => revenueShareOption.value.toString() === reveneShareId.toString())[0];
      if (revenueShareOption) {
        return revenueShareOption.calculations;
      } else {
        return null;
      }
    }
  };

  handleOnPayoutCalcMethodChange = event => {
    this.setState({
      selectedPayoutCalcMethod: event.target.value
    });
  };

  handleOnStartDayChange = startDay => {
    const startDayMessage = this.getStartDayMessage(startDay);

    this.setState({ startDayMessage });
  };

  getStartDayMessage = startDay => {
    const startDate = moment()
      .startOf('month')
      .add(startDay - 1, 'days')
      .format('YYYY-MM-DD');
    const endDate = generateEndDate(startDate, { monthNumber: 1 }, 1);

    const endDay = Number(endDate.format('D'));
    const endDayMonthInString = endDate.format('MM') === moment().format('MM') ? 'this month' : 'next month';

    const startDayInString = (
      <span className="bold-day">
        {startDay}
        {generateNumberIndicator(startDay)}
      </span>
    );
    const endDayInString = (
      <span className="bold-day">
        {endDay}
        {generateNumberIndicator(endDay)}
      </span>
    );

    return (
      <span>
        {startDayInString} day of this month to {endDayInString} day of {endDayMonthInString}.
      </span>
    );
  };

  getDescriptionOnPayoutMethod = method => {
    switch (method) {
      case 'perDay':
        return 'In this method, payout will be calculated based on the daily charges price.';
      case 'perDayAndTransaction':
        return 'In this method, payout will be calculated based on the daily price of rental charges, while for other charges will be based on the transaction(s).';
      case 'perTransaction':
        return 'In this method, payout will be calculated based on transaction(s).';
      case 'perCheckOut':
        return 'In this method, payout will be calculated according to the checkout date of the guest(s).';
      case 'perAverage':
        return 'In this method, payout will be calculated based on the average of all charges.';
      default:
        return '';
    }
  };

  getDefaultSettingForCharges = () => {
    const { chargesTypes } = this.state;
    let charges = {};
    chargesTypes.forEach(charge => {
      switch (charge.key) {
        case 'rental':
        case 'extraGuest':
          charges[charge.key] = 'shared';
          break;
        default:
          charges[charge.key] = 'host';
          break;
      }
    });
    return charges;
  };

  checkIsAllowToAddHostCharges = selectedHost => {
    const { hostOptions } = this.state;
    const foundHost = hostOptions.find(host => String(host.value) === String(selectedHost));
    this.setState({
      isAllowToAddHostCharges: foundHost && foundHost.allowHostChargesInServicePackage
    });
  };

  render() {
    const { checkIsAllowDeleteServicePackage, form, history } = this.props;
    const {
      hostOptions,
      shareTypes,
      thresholdTypes,
      dataCalculations,
      hasFetchedChargesTypes,
      chargesTypes,
      dataChargesSetting,
      startDay,
      startDayMessage,
      isAllowToAddHostCharges,
      isDeleteButtonLoading,
      isSaveButtonLoading
    } = this.state;
    const { getFieldValue, getFieldDecorator, setFieldsValue } = form;
    const selectedRevenueShareId = getFieldValue('revenueShare');
    const splitBySqft = getFieldValue('splitBySqft');
    const defaultChargesSetting = this.getDefaultSettingForCharges();

    let shareTypesMap = {};
    let thresholdMap = {};
    if (shareTypes && thresholdTypes) {
      Object.keys(shareTypes).forEach(key => {
        shareTypesMap[shareTypes[key].code] = shareTypes[key];
      });

      Object.keys(thresholdTypes).forEach(key => {
        thresholdMap[thresholdTypes[key].code] = thresholdTypes[key];
      });
    }

    let calculations = this.getCalculationScehma(selectedRevenueShareId) || [];

    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <Card className="sp-form-card" title="Basic Information" loading={this.state.loading}>
          <CloseButton
            onClick={() => {
              history.push('/servicepackage');
            }}
          />
          <FormItem label="For Host">
            {getFieldDecorator('host', {
              rules: [{ required: true, message: 'Please select a host!' }]
            })(
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Select a host"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={value => {
                  setFieldsValue({
                    units: []
                  });
                  this.checkIsAllowToAddHostCharges(value);
                  this.changeHost(value);
                }}
              >
                {hostOptions.map(hostOption => {
                  return (
                    <Option key={hostOption.value} value={hostOption.value}>
                      {hostOption.label}
                    </Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
          <FormItem label="Service Package Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Please input a service package name!'
                }
              ]
            })(<Input placeholder="eg. 70/30 Revenue Equal Expenses, 80/20 Revenue Owner Expense" />)}
          </FormItem>
          <FormItem
            label={
              <span>
                Package Type&nbsp;
                <Tooltip title="Pool rules apply to units that share a pool. Individual rules apply to selected units individually.">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('packageType', {
              rules: [{ required: true, message: 'Please select a package type!' }]
            })(
              <Radio.Group buttonStyle="solid">
                {this.state.packageTypeOptions.map(packageTypeOption => {
                  return (
                    <Radio.Button key={packageTypeOption.value} value={packageTypeOption.value}>
                      {packageTypeOption.label}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            )}
          </FormItem>
          <FormItem label={getFieldValue('packageType') === 0 ? 'Units In This Pool' : 'Package Applies To Units'}>
            {getFieldDecorator('units', {
              rules: [{ required: true, message: 'Please select at least 1 unit!' }]
            })(
              <Select
                mode="multiple"
                placeholder="Start typing to choose units."
                filterOption={(e, option) => {
                  var regex = new RegExp(e, 'im');
                  return regex.test(option.props.children);
                }}
              >
                <OptGroup label="Unassigned Units">
                  {this.state.unitOptions
                    .filter(unit => !unit.isAssigned)
                    .map(unitOption => (
                      <Option key={unitOption.value} value={unitOption.value}>
                        {unitOption.label}
                      </Option>
                    ))}
                </OptGroup>
                <OptGroup label="Assigned Units">
                  {this.state.unitOptions
                    .filter(unit => unit.isAssigned)
                    .map(unitOption => (
                      <Option key={unitOption.value} value={unitOption.value} disabled>
                        {unitOption.label}
                      </Option>
                    ))}
                </OptGroup>
              </Select>
            )}
          </FormItem>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Service Package" key="1" forceRender={true}>
              <FormItem label="Revenue Share">
                {getFieldDecorator('revenueShare', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a revenue share!'
                    }
                  ],
                  initialValue: 0
                })(
                  <Radio.Group buttonStyle="solid">
                    {this.state.revenueShareOptions.map(revenueShareOption => {
                      return (
                        <Radio.Button key={revenueShareOption.value} value={revenueShareOption.value} className="sp-button-margin">
                          {revenueShareOption.label}
                        </Radio.Button>
                      );
                    })}
                  </Radio.Group>
                )}
              </FormItem>
              {calculations.map((calculation, i) => {
                let dataCalculation = (dataCalculations && dataCalculations[i]) || null;
                const shareTypeObj = shareTypesMap[calculation.shareType];
                const thresholdObj = calculation ? thresholdMap[calculation.thresholdType] : null;
                let fieldsArr = Object.keys(shareTypeObj.fields).map(key => {
                  return {
                    key,
                    fieldType: shareTypeObj.fields[key]
                  };
                });
                let thresholdFieldsArr =
                  thresholdObj && thresholdObj.fields
                    ? Object.keys(thresholdObj.fields).map(key => {
                        return {
                          key,
                          fieldType: thresholdObj.fields[key]
                        };
                      })
                    : [];
                return (
                  <Card title={'Step ' + (i + 1)} key={i}>
                    {i > 0 && thresholdObj ? (
                      <div>
                        <p>{thresholdObj.label + ' Threshold'}</p>
                        {thresholdFieldsArr.map(field => {
                          let thresholdValue =
                            getFieldValue(`calculations[${i}].threshold.${field.key}`) ||
                            (dataCalculation &&
                              dataCalculation.threshold &&
                              thresholdObj &&
                              (dataCalculation.threshold.type || dataCalculation.threshold.type === 0) &&
                              thresholdObj.code.toString() === dataCalculation.threshold.type.toString() &&
                              dataCalculation.threshold.amount) ||
                            0;
                          return (
                            <Fragment>
                              {field.fieldType === 'Percentage' ? (
                                <div>
                                  <InputNumber
                                    min={0}
                                    max={100}
                                    onChange={e => {
                                      let calculations = getFieldValue(`calculations`);
                                      calculations[i][field.key] = e;
                                      setFieldsValue({
                                        calculations
                                      });
                                    }}
                                    value={thresholdValue}
                                  />
                                  {' %'}
                                </div>
                              ) : (
                                ''
                              )}
                              <FormItem>
                                {getFieldDecorator(`calculations[${i}].threshold.${field.key}`, {
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Please insert the ' + thresholdObj.label
                                    },
                                    {
                                      type: 'number',
                                      message: 'Invalid ' + thresholdObj.label
                                    }
                                  ],
                                  initialValue: thresholdValue
                                })(
                                  field.fieldType === 'Percentage' ? (
                                    <Slider
                                      marks={{
                                        0: '0%',
                                        20: '20%',
                                        40: '40%',
                                        60: '60%',
                                        80: '80%',
                                        100: '100%'
                                      }}
                                    />
                                  ) : (
                                    <InputNumber value={thresholdValue} min={0} />
                                  )
                                )}
                              </FormItem>
                            </Fragment>
                          );
                        })}
                      </div>
                    ) : (
                      ''
                    )}
                    {fieldsArr.map(field => {
                      let shareValue =
                        getFieldValue(`calculations[${i}].${field.key}`) ||
                        (dataCalculation &&
                          shareTypeObj &&
                          (dataCalculation.type || dataCalculation.type === 0) &&
                          shareTypeObj.code.toString() === dataCalculation.type.toString() &&
                          dataCalculation[field.key]) ||
                        0;
                      return (
                        <Fragment key={field.key}>
                          <div>{shareTypeObj.label}</div>
                          {field.fieldType === 'Percentage' ? (
                            <div>
                              <InputNumber
                                min={0}
                                max={100}
                                onChange={e => {
                                  let calculations = getFieldValue(`calculations`);
                                  calculations[i][field.key] = e;
                                  setFieldsValue({
                                    calculations
                                  });
                                }}
                                value={shareValue}
                              />
                              {' %'}
                            </div>
                          ) : (
                            ''
                          )}
                          <FormItem>
                            {getFieldDecorator(`calculations[${i}].${field.key}`, {
                              rules: [
                                {
                                  required: true,
                                  message: 'Please insert the ' + shareTypeObj.label
                                },
                                {
                                  type: 'number',
                                  message: 'Invalid ' + shareTypeObj.label
                                }
                              ],
                              initialValue: shareValue
                            })(
                              field.fieldType === 'Percentage' ? (
                                <Slider
                                  marks={{
                                    0: '0%',
                                    20: '20%',
                                    40: '40%',
                                    60: '60%',
                                    80: '80%',
                                    100: '100%'
                                  }}
                                />
                              ) : (
                                <InputNumber min={0} />
                              )
                            )}
                          </FormItem>
                        </Fragment>
                      );
                    })}
                  </Card>
                );
              })}
              <Row className="sp-fees-row" gutter={8}>
                <Col span={24} md={8}>
                  <Card
                    title="Administrative Fee"
                    extra={getFieldDecorator('isChargedAdminFee', {
                      valuePropName: 'checked',
                      initialValue: false
                    })(<Switch />)}
                    bodyStyle={{ display: !getFieldValue('isChargedAdminFee') && 'none' }}
                  >
                    <FormItem label="Administrative Fee Rate">
                      {getFieldDecorator('adminCharges', {
                        initialValue: this.state.adminCharges
                      })(
                        <InputNumber
                          className="sp-input"
                          min={0}
                          max={100}
                          disabled={!getFieldValue('isChargedAdminFee')}
                          formatter={value => `${value}%`}
                          parser={value => value.replace('%', '')}
                          placeholder="Enter the admin fee rate"
                        />
                      )}
                    </FormItem>
                  </Card>
                </Col>
                {isAllowToAddHostCharges && (
                  <Col span={24} md={8}>
                    <Card
                      title="Host Charge"
                      extra={getFieldDecorator('isChargedHostCharge', {
                        valuePropName: 'checked',
                        initialValue: false
                      })(<Switch />)}
                      bodyStyle={{ display: !getFieldValue('isChargedHostCharge') && 'none' }}
                    >
                      <FormItem
                        label={
                          <div>
                            Display Charge Separately
                            <Tooltip title="If turned on, the charge will be displayed separately in the Payout tab. If turned off, the charge will be deducted automatically from the room rate and cleaning fees.">
                              <Icon type="question-circle-o" className="sp-icon" />
                            </Tooltip>
                          </div>
                        }
                      >
                        {getFieldDecorator('isSeparateHostCharge', {
                          valuePropName: 'checked',
                          initialValue: false
                        })(<Switch disabled={!getFieldValue('isChargedHostCharge')} />)}
                      </FormItem>
                      <FormItem
                        label={
                          <div>
                            Display Label
                            <Tooltip title='e.g. Host Gratuity. If not defined, the default name is "Tax"'>
                              <Icon type="question-circle-o" className="sp-icon" />
                            </Tooltip>
                          </div>
                        }
                      >
                        {getFieldDecorator('hostChargeLabel')(
                          <Input
                            className="sp-input"
                            disabled={getFieldValue('isChargedHostCharge') ? !getFieldValue('isSeparateHostCharge') : true}
                            placeholder="Enter the charge's display label"
                          />
                        )}
                      </FormItem>
                      <FormItem label="Host Charge Rate">
                        {getFieldDecorator('hostChargeRate', {
                          initialValue: this.state.hostCharge
                        })(
                          <InputNumber
                            className="sp-input"
                            min={0}
                            max={100}
                            disabled={!getFieldValue('isChargedHostCharge')}
                            formatter={value => `${value}%`}
                            parser={value => value.replace('%', '')}
                            placeholder="Enter the host charge rate"
                          />
                        )}
                      </FormItem>
                    </Card>
                  </Col>
                )}
              </Row>
            </TabPane>
            <TabPane tab="Expenses Setting" key="2" forceRender={true}>
              <p className="ant-form-text">This service package applies the following expenses sharing rules.</p>
              <FormItem className="sp-expenses-item" FormItem label="Water">
                {getFieldDecorator('water', { initialValue: 'shared' })(
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="shared">Shared</Radio.Button>
                    <Radio.Button value="host">Host</Radio.Button>
                    <Radio.Button value="owner">Owner</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
              <FormItem className="sp-expenses-item" FormItem label="Electricity">
                {getFieldDecorator('electricity', { initialValue: 'shared' })(
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="shared">Shared</Radio.Button>
                    <Radio.Button value="host">Host</Radio.Button>
                    <Radio.Button value="owner">Owner</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
              <FormItem className="sp-expenses-item" FormItem label="Internet">
                {getFieldDecorator('internet', { initialValue: 'shared' })(
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="shared">Shared</Radio.Button>
                    <Radio.Button value="host">Host</Radio.Button>
                    <Radio.Button value="owner">Owner</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
              <FormItem className="sp-expenses-item" label="Cleaning Fee">
                {getFieldDecorator('cleaningFee', { initialValue: 'shared' })(
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="shared">Shared</Radio.Button>
                    <Radio.Button value="host">Host</Radio.Button>
                    <Radio.Button value="owner">Owner</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
              <FormItem className="sp-expenses-item" label="Laundry Fee">
                {getFieldDecorator('laundryFee', { initialValue: 'shared' })(
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="shared">Shared</Radio.Button>
                    <Radio.Button value="host">Host</Radio.Button>
                    <Radio.Button value="owner">Owner</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
              <FormItem className="sp-expenses-item" label="Service Fee">
                {getFieldDecorator('serviceFee', { initialValue: 'shared' })(
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="shared">Shared</Radio.Button>
                    <Radio.Button value="host">Host</Radio.Button>
                    <Radio.Button value="owner">Owner</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
              <FormItem className="sp-expenses-item" label="Check In Fee">
                {getFieldDecorator('checkInFee', { initialValue: 'shared' })(
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="shared">Shared</Radio.Button>
                    <Radio.Button value="host">Host</Radio.Button>
                    <Radio.Button value="owner">Owner</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
              <FormItem className="sp-expenses-item" label="Check Out Fee">
                {getFieldDecorator('checkOutFee', { initialValue: 'shared' })(
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="shared">Shared</Radio.Button>
                    <Radio.Button value="host">Host</Radio.Button>
                    <Radio.Button value="owner">Owner</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
              <FormItem className="sp-expenses-item" label="Toiletry Set Fee">
                {getFieldDecorator('toiletryFee', { initialValue: 'shared' })(
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="shared">Shared</Radio.Button>
                    <Radio.Button value="host">Host</Radio.Button>
                    <Radio.Button value="owner">Owner</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
              <FormItem label="Other Fee">
                {getFieldDecorator('otherFee', { initialValue: 'shared' })(
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="shared">Shared</Radio.Button>
                    <Radio.Button value="host">Host</Radio.Button>
                    <Radio.Button value="owner">Owner</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
              <AppContextConsumer>
                {({ user }) =>
                  user.isAdmin && getFieldValue('isChargedAdminFee') ? (
                    <FormItem label="Adminstrative Charges">
                      {getFieldDecorator('adminFee', { initialValue: 'shared' })(
                        <Radio.Group buttonStyle="solid">
                          <Radio.Button value="shared">Shared</Radio.Button>
                          <Radio.Button value="host">Host</Radio.Button>
                          <Radio.Button value="owner">Owner</Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                  ) : null
                }
              </AppContextConsumer>
            </TabPane>
            <TabPane tab="Charges (Income) Setting" key="3" forceRender={true}>
              <p className="ant-form-text">This service package applies the following income sharing rules.</p>
              {hasFetchedChargesTypes &&
                chargesTypes &&
                chargesTypes.map(charge => (
                  <FormItem key={charge.key} className="sp-expenses-item" label={charge.value}>
                    {getFieldDecorator(charge.key, {
                      initialValue: (dataChargesSetting && dataChargesSetting[charge.key]) || defaultChargesSetting[charge.key]
                    })(
                      <Radio.Group buttonStyle="solid">
                        <Radio.Button value="shared">Shared</Radio.Button>
                        <Radio.Button value="host">Host</Radio.Button>
                        <Radio.Button value="owner">Owner</Radio.Button>
                      </Radio.Group>
                    )}
                  </FormItem>
                ))}
            </TabPane>
            <TabPane tab="Payout Configuration" key="4" forceRender={true}>
              <p className="ant-form-text">This service package applies the following payout configurations.</p>
              <p>
                Payout Calculation Method &nbsp;
                <Tooltip title="Your payout will be calculated based on the method you chose.">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </p>
              {getFieldValue('payoutCalcMethod') && (
                <Alert
                  message="Tips"
                  description={this.getDescriptionOnPayoutMethod(getFieldValue('payoutCalcMethod'))}
                  type="info"
                  showIcon
                  style={{ marginBottom: '16px' }}
                />
              )}
              <FormItem>
                {getFieldDecorator('payoutCalcMethod')(
                  <Radio.Group buttonStyle="solid">
                    {this.state.payoutCalcMethodOptions.map(payoutCalcMethodOption => {
                      return (
                        <Radio.Button key={payoutCalcMethodOption.value} value={payoutCalcMethodOption.value} className="sp-button-margin">
                          {payoutCalcMethodOption.label}
                        </Radio.Button>
                      );
                    })}
                  </Radio.Group>
                )}
              </FormItem>

              <FormInputNumber
                className="start-day"
                form={form}
                name="startDay"
                defaultValue={startDay}
                label={
                  <span>
                    When to start this month's payout calculation?&nbsp;
                    <Tooltip
                      overlayClassName="payout-configuration-tooltips"
                      title={
                        <>
                          <span>
                            The expected payout duration would be from the date you set to the same date of next month. (Excluding the date itself)
                          </span>
                          <br />
                          <span>for example: 15th of January to 14th of February, or 1st of January to end of January</span>
                        </>
                      }
                    >
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
                requiredErrorMessage="Please enter an amount"
                minValue={1}
                maxValue={28}
                parser={value => value.replace(/[^0-9.]/g, '')}
                onChange={this.handleOnStartDayChange}
              />
              <span className="start-day-message">{startDayMessage}</span>
              <br />
              <br />

              {getFieldValue('packageType') === 0 && (
                <FormItem
                  label={
                    <span>
                      Split revenue by square feet ratio&nbsp;
                      <Tooltip title="Only applies to package type: Pool">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('splitBySqft', { initialValue: splitBySqft })(
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  )}
                </FormItem>
              )}
              <FormItem
                label={
                  <span>
                    Hide Payout Calendar&nbsp;
                    <Tooltip title="Hide payout calendar for this user?">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('hidePayout', {
                  message: 'Hide payout calendar for this user'
                })(<Switch checked={this.state.hidePayout} onChange={this.hidePayoutChange} />)}
              </FormItem>
            </TabPane>
          </Tabs>
        </Card>
        <div className="detele-and-save-btn">
          <FormItem>
            {this.state.mode === 'new' ? (
              <Fragment>
                <Button id="create-button7a-sp" type="primary" htmlType="submit" loading={isSaveButtonLoading}>
                  Create
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <Button id="save-button4-editsp" type="primary" htmlType="submit" loading={isSaveButtonLoading}>
                  Save
                </Button>
                {checkIsAllowDeleteServicePackage() && (
                  <Button
                    id="del-button4-editsp"
                    type="danger"
                    onClick={this.handleDelete}
                    className="sp-button-margin"
                    loading={isDeleteButtonLoading}
                  >
                    Delete
                  </Button>
                )}
              </Fragment>
            )}
          </FormItem>
        </div>
      </Form>
    );
  }
}

export default withRouter(withAppContext(Form.create()(ServicePackageForm)));
