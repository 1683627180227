import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Row, Col, Message } from 'antd';
import moment from 'moment';

import SeasonalRuleListing from './components/seasonalRuleListing';
import SeasonalRuleDetails from './components/seasonalRuleDetails';
import SeasonalRuleEdit from './components/seasonalRuleEdit';
import { getSeasonalRuleTimeline, getSeasonalRuleGroupDetails } from 'utils/apis/seasonalRule';
import { capitalizeFirstLetter } from 'utils/general';
import { getAirbnbListings } from 'utils/apis/integration';

const AIRBNB_PRICE_CHANGE_TYPE = {
  NIGHTLY: 'SEASONAL_ADJUSTMENT',
  LOS: 'STAYED_AT_LEAST_X_DAYS',
  LAST_MINUTE: 'BOOKED_WITHIN_AT_MOST_X_DAYS',
  EARLY_BIRD: 'BOOKED_BEYOND_AT_LEAST_X_DAYS'
};

const seasonalRuleTimelineMapper = (seasonalRuleTimelines, searchEnd) => {
  return seasonalRuleTimelines.map(seasonalRuleTimeline => {
    // use searchEnd to endDate
    if (!seasonalRuleTimeline.endDate) {
      seasonalRuleTimeline.endDate = searchEnd;
      seasonalRuleTimeline.isMoreThanEndDate = true;
    }
    return seasonalRuleTimeline;
  });
};

const seasonalGroupRulesMapper = seasonalGroupRules => {
  return seasonalGroupRules.map(seasonalGroupRule => {
    const pricingRuleString =
      seasonalGroupRule.pricingRule && Array.isArray(seasonalGroupRule.pricingRule)
        ? seasonalGroupRule.pricingRule.map(pricingRule => pricingRuleToPricingRuleString(pricingRule))
        : [];
    const availabilityRulesString = seasonalGroupRule.availabilityRules
      ? availabilityRulesToAvailabilityRulesString(seasonalGroupRule.availabilityRules)
      : [];
    const rulesString = pricingRuleString.concat(availabilityRulesString);
    return {
      ...seasonalGroupRule,
      rulesString
    };
  });
};

const pricingRuleToPricingRuleString = pricingRule => {
  switch (pricingRule.ruleType) {
    case AIRBNB_PRICE_CHANGE_TYPE.NIGHTLY:
      return `${Math.abs(pricingRule.priceChange)}% nightly price ${pricingRule.priceChange > 0 ? 'in' : 'de'}crease`;
    case AIRBNB_PRICE_CHANGE_TYPE.LOS:
      return `${-pricingRule.priceChange}% discount · ${pricingRule.thresholdOne}-night${pricingRule.thresholdOne > 1 ? 's' : ''} minimum`;
    case AIRBNB_PRICE_CHANGE_TYPE.LAST_MINUTE:
      return `${-pricingRule.priceChange}% discount · within ${pricingRule.thresholdOne} day${pricingRule.thresholdOne > 1 ? 's' : ''} of arrival`;
    case AIRBNB_PRICE_CHANGE_TYPE.EARLY_BIRD:
      return `${-pricingRule.priceChange}% discount · ${pricingRule.thresholdOne / 30} month${
        pricingRule.thresholdOne / 30 > 1 ? 's' : ''
      } in advance`;
    default:
      return '';
  }
};

const availabilityRulesToAvailabilityRulesString = availabilityRules => {
  const availabilityRulesString = [];

  if (availabilityRules.minNights) {
    Object.keys(availabilityRules.minNights).map(minNight => {
      if (minNight === 'UNSPECIFIED' && availabilityRules.minNights[minNight] !== null) {
        availabilityRulesString.push(`Min nights: ${availabilityRules.minNights[minNight]}`);
      } else if (availabilityRules.minNights[minNight] !== null) {
        availabilityRulesString.push(
          `${capitalizeFirstLetter(minNight, { lowerOthers: true })} check-in: ${availabilityRules.minNights[minNight]} nights minimum`
        );
      }
      return availabilityRulesString;
    });
  }

  if (availabilityRules.maxNights) {
    Object.keys(availabilityRules.maxNights).map(maxNight => {
      if (maxNight === 'UNSPECIFIED' && availabilityRules.maxNights[maxNight] !== null) {
        availabilityRulesString.push(`Max nights: ${availabilityRules.maxNights[maxNight]}`);
      }
      return availabilityRulesString;
    });
  }

  if (availabilityRules.closedForCheckin && availabilityRules.closedForCheckin.length > 0) {
    const closedForCheckinWithProperCasing = availabilityRules.closedForCheckin.map(closedForCheckin =>
      capitalizeFirstLetter(closedForCheckin, { lowerOthers: true })
    );
    availabilityRulesString.push(`Check-in: all days except ${closedForCheckinWithProperCasing.join(', ')}`);
  }

  if (availabilityRules.closedForCheckout && availabilityRules.closedForCheckout.length > 0) {
    const closedForCheckoutWithProperCasing = availabilityRules.closedForCheckout.map(closedForCheckout =>
      capitalizeFirstLetter(closedForCheckout, { lowerOthers: true })
    );
    availabilityRulesString.push(`Check-out: all days except ${closedForCheckoutWithProperCasing.join(', ')}`);
  }

  return availabilityRulesString;
};

const getTodayDate = () => {
  return moment().format('YYYY-MM-DD');
};

const getEndDate = () => {
  return moment()
    .add(30, 'days')
    .format('YYYY-MM-DD');
};

const useFetchApprovedAirbnbListing = host => {
  const [airbnbListings, setAirbnbListings] = useState([]);

  useEffect(() => {
    const fetchAirbnbListings = async () => {
      const airbnbListings = await getAirbnbListings({ hosts: host, approvedOnly: true });
      setAirbnbListings(airbnbListings.data);
    };

    fetchAirbnbListings();
  }, [host]);

  return airbnbListings;
};

const useFetchSeasonalRulesList = (airbnbListingId, sinceDate, endDate) => {
  const [isSeasonalRuleLoading, setIsSeasonalRuleLoading] = useState(true);
  const [isTimelineLoading, setIsTimelineLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [ruleGroupDetails, setRuleGroupDetails] = useState([]);
  const [ruleTimeline, setRuleTimeline] = useState([]);
  const [ruleTimelineWithDetails, setRuleTimelineWithDetails] = useState([]);

  const fetchRuleTimeline = useCallback(async () => {
    setIsTimelineLoading(true);
    return getSeasonalRuleTimeline(airbnbListingId, sinceDate, endDate)
      .then(resSeasonalRuleTimeline => {
        setRuleTimeline(seasonalRuleTimelineMapper(resSeasonalRuleTimeline, endDate));
        setIsTimelineLoading(false);
      })
      .catch(ex => {
        setIsTimelineLoading(false);
        Message.error(ex.toString());
      });
  }, [airbnbListingId, sinceDate, endDate]);

  const fetchRuleGroupDetails = useCallback(async () => {
    setIsSeasonalRuleLoading(true);
    return getSeasonalRuleGroupDetails(airbnbListingId)
      .then(resSeasonalGroupRules => {
        setIsSeasonalRuleLoading(false);
        return setRuleGroupDetails(seasonalGroupRulesMapper(resSeasonalGroupRules));
      })
      .catch(ex => {
        setIsSeasonalRuleLoading(false);
        Message.error(ex.toString());
      });
  }, [airbnbListingId]);

  const mergeIntoRuleTimelineAndDetails = (ruleTimelineLists, ruleGroupDetails) => {
    let ruleTimelineAndDetails = [];
    ruleGroupDetails.map(groupDetail => {
      return ruleTimelineLists.forEach(list => {
        if (groupDetail.airbnbSeasonalRuleGroupId === list.airbnbSeasonalRuleGroupId) {
          ruleTimelineAndDetails.push({
            key: list.airbnbSeasonalRuleGroupId + list.sinceDate + list.endDate,
            sinceDate: list.sinceDate,
            endDate: list.endDate,
            isMoreThanEndDate: list.isMoreThanEndDate,
            ...groupDetail
          });
        }
      });
    });
    return ruleTimelineAndDetails;
  };

  const resetSeasonalRules = () => {
    setRuleGroupDetails([]);
    setRuleTimelineWithDetails([]);
  };

  useEffect(() => {
    if (ruleGroupDetails && ruleTimeline) {
      const ruleTimelineAndDetails = mergeIntoRuleTimelineAndDetails(ruleTimeline, ruleGroupDetails);
      setRuleTimelineWithDetails(ruleTimelineAndDetails);
    }
  }, [ruleGroupDetails, ruleTimeline]);

  useEffect(() => {
    setIsLoading(isTimelineLoading || isSeasonalRuleLoading);
  }, [isTimelineLoading, isSeasonalRuleLoading]);

  useEffect(() => {
    if (airbnbListingId) {
      fetchRuleTimeline();
    }
  }, [airbnbListingId, sinceDate, endDate, fetchRuleTimeline]);

  useEffect(() => {
    if (airbnbListingId) {
      fetchRuleGroupDetails();
    }
  }, [airbnbListingId, fetchRuleGroupDetails]);

  return {
    ruleGroupDetails,
    ruleTimelineWithDetails,
    resetSeasonalRules,
    fetchRuleTimeline,
    fetchRuleGroupDetails,
    isLoading
  };
};

const useDetailsDrawerProps = (ruleTimelineWithDetails, ruleGroupDetails) => {
  const [selectedRuleId, setSelectedRuleId] = useState('');
  const [isShowDetailsDrawer, setIsShowDetailsDrawer] = useState(false);
  const [selectedSeasonalTimelineId, setSelectedSeasonalTimelineId] = useState('');
  const [selectedTimelineRule, setSelectedTimelineRule] = useState({});
  const [selectedRuleDetail, setSelectedRuleDetail] = useState({});

  const selectTimelineRule = (ruleTimelineWithDetails, key) => {
    return ruleTimelineWithDetails.find(rule => rule.key === key);
  };

  const selectRuleDetail = (ruleGroupDetails, selectedRuleId) => {
    return ruleGroupDetails.find(rule => rule.airbnbSeasonalRuleGroupId === selectedRuleId);
  };

  const handleOnChangeDetailsDrawer = key => async e => {
    const selectedTimelineRule = selectTimelineRule(ruleTimelineWithDetails, key);
    if (!!selectedTimelineRule) {
      setSelectedRuleId(selectedTimelineRule.airbnbSeasonalRuleGroupId);
      handleOnOpenDetailDrawer().then(() => {
        setSelectedTimelineRule(selectedTimelineRule);
      });
    }
  };

  const handleOnOpenDetailDrawer = async e => {
    await resetSelectedSeasonalRuleId();
    if (selectedTimelineRule) {
      setSelectedRuleId(selectedTimelineRule.airbnbSeasonalRuleGroupId);
    }
    setIsShowDetailsDrawer(true);
  };

  const resetSelectedSeasonalRuleId = () => {
    setSelectedSeasonalTimelineId('');
    setIsShowDetailsDrawer(false);
    // setSelectedRuleDetail({});
  };

  const handleOnCloseDetailsDrawer = () => {
    setIsShowDetailsDrawer(false);
  };

  const resetDetailsDrawer = () => {
    setSelectedSeasonalTimelineId('');
    setSelectedTimelineRule({});
    // setSelectedRuleDetail({});
    setIsShowDetailsDrawer(false);
  };

  useEffect(() => {
    const selectedRuleDetail = selectedRuleId ? selectRuleDetail(ruleGroupDetails, selectedRuleId) : {};
    setSelectedRuleDetail(selectedRuleDetail);
  }, [ruleTimelineWithDetails, ruleGroupDetails, selectedRuleId]);

  return {
    isShowDetailsDrawer,
    handleOnChangeDetailsDrawer,
    handleOnOpenDetailDrawer,
    handleOnCloseDetailsDrawer,
    selectedSeasonalTimelineId,
    selectedTimelineRule,
    selectedRuleDetail,
    resetDetailsDrawer,
    selectedRuleId,
    setSelectedRuleId
  };
};

const useEditModalProps = () => {
  const [isShowEditModal, setShowEditModal] = useState(false);

  const handleOnShowEditModal = () => {
    setShowEditModal(true);
  };

  const handleOnCloseEditModal = e => {
    setShowEditModal(false);
  };

  const resetEditModal = () => {
    setShowEditModal(false);
  };

  return { isShowEditModal, handleOnShowEditModal, handleOnCloseEditModal, resetEditModal };
};

const AirbnbSeasonalRuleModal = ({ isShowModal, onModalCancel, airbnbListingId, host }) => {
  const [sinceDate, setSinceDate] = useState(getTodayDate());
  const [endDate, setEndDate] = useState(getEndDate());
  const airbnbListings = useFetchApprovedAirbnbListing(host);

  const {
    ruleGroupDetails,
    ruleTimelineWithDetails,
    resetSeasonalRules,
    isLoading,
    fetchRuleTimeline,
    fetchRuleGroupDetails
  } = useFetchSeasonalRulesList(airbnbListingId, sinceDate, endDate);

  const {
    isShowDetailsDrawer,
    handleOnOpenDetailDrawer,
    handleOnChangeDetailsDrawer,
    handleOnCloseDetailsDrawer,
    selectedSeasonalTimelineId,
    selectedTimelineRule,
    selectedRuleDetail,
    resetDetailsDrawer,
    selectedRuleId,
    setSelectedRuleId
  } = useDetailsDrawerProps(ruleTimelineWithDetails, ruleGroupDetails);

  const { isShowEditModal, handleOnShowEditModal, handleOnCloseEditModal, resetEditModal } = useEditModalProps();

  const handleOnSearchClick = (sinceDate, endDate) => {
    setSinceDate(sinceDate);
    setEndDate(endDate);
  };

  const handleOnModalClose = e => {
    resetSeasonalRules();
    resetDetailsDrawer();
    resetEditModal();

    onModalCancel(e);
  };

  return (
    <Modal
      title="Add or edit airbnb seasonal rule"
      style={{ minWidth: '350px', maxWidth: '1000px', overflow: 'hidden' }}
      bodyStyle={{ minHeight: '73vh' }}
      width="90vw"
      visible={isShowModal}
      onCancel={handleOnModalClose}
      isFetchLoading={isLoading}
      footer={null}
      destroyOnClose
    >
      <Row type="flex" justify="space-between">
        <Col span={20}>
          <SeasonalRuleListing
            ruleTimelineWithDetails={ruleTimelineWithDetails}
            onSearchClick={handleOnSearchClick}
            onClickSeasonalRule={handleOnChangeDetailsDrawer}
            onClickSearch={handleOnSearchClick}
            sinceDate={sinceDate}
            endDate={endDate}
            isFetchLoading={isLoading}
          />
        </Col>
      </Row>
      <SeasonalRuleDetails
        isShowDrawer={isShowDetailsDrawer}
        ruleGroupDetails={ruleGroupDetails}
        selectedTimelineRule={selectedTimelineRule}
        onClickOpenDrawer={handleOnOpenDetailDrawer}
        onClickCloseDrawer={handleOnCloseDetailsDrawer}
        onClickShowEditModal={handleOnShowEditModal}
        refetchRuleTimeline={fetchRuleTimeline}
        refetchRuleGroup={fetchRuleGroupDetails}
        isFetchLoading={isLoading}
        airbnbListingId={airbnbListingId}
        selectedRuleId={selectedRuleId}
        setSelectedRuleId={setSelectedRuleId}
        airbnbListings={airbnbListings}
      />
      <SeasonalRuleEdit
        isShowEditModal={isShowEditModal}
        handleOnCloseEditModal={handleOnCloseEditModal}
        selectedSeasonalTimelineId={selectedSeasonalTimelineId}
        selectedRuleDetail={selectedRuleDetail}
        airbnbListingId={airbnbListingId}
        refetchRuleGroup={fetchRuleGroupDetails}
        selectedRuleId={selectedRuleId}
        setSelectedRuleId={setSelectedRuleId}
      />
    </Modal>
  );
};

export default AirbnbSeasonalRuleModal;
