import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, Col, Radio, Row, Table } from 'antd';

import { numberWithCommas, checkHasValue } from 'utils/general';

import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import RateFormInputNumber from '../RateFormInputNumber/RateFormInputNumber';

const { Group: RadioGroup, Button: RadioButton } = Radio;

const GuestFormInputNumber = ({ form, name, label, isDisabled, maxValue }) => {
  const guestMinValue = 0;
  const guestPrecision = 0;
  const guestFormatter = value => value && `${value} guests`;
  const guestParser = value => value.replace(/[^0-9]/g, '');

  return (
    <FormInputNumber
      form={form}
      name={name}
      label={label}
      requiredErrorMessage="Please enter number of guests"
      minValue={guestMinValue}
      maxValue={maxValue}
      precision={guestPrecision}
      formatter={guestFormatter}
      parser={guestParser}
      disabled={isDisabled}
    />
  );
};

const columns = [
  {
    title: 'No. of Guest',
    dataIndex: 'noOfGuest',
    key: 'noOfGuest'
  },
  {
    title: 'Weekday',
    dataIndex: 'weekday',
    key: 'weekday',
    render: value => `RM ${numberWithCommas(value)}`
  },
  {
    title: 'Weekend',
    dataIndex: 'weekend',
    key: 'weekend',
    render: value => `RM ${numberWithCommas(value)}`
  }
];

const OccupancyRate = ({ form, hasOccupancyRate, onChange, maxCapacity, fieldNames }) => {
  const { fieldNameWeekdayRate, fieldNameWeekendRate, fieldNamePaxThreshold, fieldNameExtraPaxFee } = fieldNames;
  const {
    [fieldNameWeekdayRate]: weekdayRate,
    [fieldNameWeekendRate]: weekendRate,
    [fieldNamePaxThreshold]: noOfPaxThreshold,
    [fieldNameExtraPaxFee]: feePerExtraPax
  } = form.getFieldsValue([fieldNameWeekdayRate, fieldNameWeekendRate, fieldNamePaxThreshold, fieldNameExtraPaxFee]);

  const generateEstimatedPriceData = () => {
    const estimatedPriceData = [];
    let weekday = weekdayRate;
    let weekend = weekendRate;

    for (let i = 1; i <= maxCapacity; i++) {
      if (i > noOfPaxThreshold) {
        weekday += feePerExtraPax;
        weekend += feePerExtraPax;
      }
      estimatedPriceData.push({
        key: i,
        noOfGuest: i,
        weekday,
        weekend
      });
    }

    return estimatedPriceData;
  };

  return (
    <>
      <Row>
        <label>Do you want to set Occupancy Rate for this rate?</label>

        <RadioGroup value={hasOccupancyRate} buttonStyle="solid" onChange={onChange} style={{ float: 'right' }}>
          <RadioButton value={true}>Yes</RadioButton>
          <RadioButton value={false}>Not for now</RadioButton>
        </RadioGroup>
      </Row>

      {hasOccupancyRate && (
        <Card title="Setting for Occupancy Rate" type="inner" style={{ marginTop: 20 }}>
          <Row gutter={8}>
            <Col span={12}>
              <GuestFormInputNumber form={form} name={fieldNamePaxThreshold} label="For each guest, after" maxValue={maxCapacity - 1} />
            </Col>

            <Col span={12}>
              <RateFormInputNumber form={form} name={fieldNameExtraPaxFee} label="Charged for" />
            </Col>
          </Row>

          {checkHasValue(noOfPaxThreshold) && checkHasValue(feePerExtraPax) && (
            <>
              <Alert message={`After ${noOfPaxThreshold} guest(s), RM ${feePerExtraPax} per person, per night.`} type="info" showIcon />

              <Table
                title={() => 'Estimated Price'}
                bordered
                style={{ marginTop: 20 }}
                columns={columns}
                dataSource={generateEstimatedPriceData()}
                pagination={{ pageSize: 5 }}
              />
            </>
          )}
        </Card>
      )}
    </>
  );
};

OccupancyRate.propTypes = {
  form: PropTypes.object.isRequired,
  hasOccupancyRate: PropTypes.bool,
  onChange: PropTypes.func,
  maxCapacity: PropTypes.number.isRequired,
  fieldNames: PropTypes.object.isRequired
};

OccupancyRate.defaultProps = {
  hasOccupancyRate: false,
  onChange: () => {},
  maxCapacity: 1,
  fieldNames: {}
};

export default OccupancyRate;
