import React from 'react';
import { Row, Col, Button, List, Divider } from 'antd';

import Counter from 'components/Counter/Counter';

import styles from './Bedrooms.module.css';

export const BedRoomListItem = ({ title, description, onEditClick, onRemoveClick, shouldDisableRemove }) => {
  return (
    <List.Item>
      <List.Item.Meta title={title} description={description} />
      <div className={styles.bedRoomListItemButtonContainer}>
        <Button ghost type="primary" onClick={onEditClick}>
          Edit
        </Button>
        <Button icon="delete" disabled={shouldDisableRemove} ghost type="danger" onClick={onRemoveClick} />
      </div>
    </List.Item>
  );
};

export const BedConfiguration = ({ beds, onBedDecrement, onBedIncrement, roomName }) => {
  return (
    <div>
      <h4>Editing: {roomName}</h4>
      <Divider />
      {beds &&
        beds.length > 0 &&
        beds.map(bed => (
          <Row key={bed.label} type="flex" justify="start" className={styles.listArrangementDetails}>
            <Col span={16} md={13}>
              <p style={{ marginBottom: '8px', fontSize: '16px' }}>{bed.label}</p>
            </Col>
            <Col span={8} md={11}>
              <Counter count={bed.count} onDecrement={onBedDecrement(bed.key)} onIncrement={onBedIncrement(bed.key)} />
            </Col>
          </Row>
        ))}
    </div>
  );
};
