import React, { Component } from 'react';
import { Form, Modal, notification, Row } from 'antd';
import csvjson from 'csvjson';
import moment from 'moment';
import PropTypes from 'prop-types';

import FormSelection from 'components/FormSelection/FormSelection';
import { getExpensesListing } from 'utils/apis/expenses';
import { guard } from 'utils/general';

class CsvModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    servicePackages: PropTypes.array.isRequired
  };

  static defaultProps = {
    closeModal: () => {},
    visible: false,
    servicePackages: []
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currency: 'RM'
      // startDate: moment()
      //   .startOf('month')
      //   .format(DATE_FORMAT),
      // endDate: moment()
      //   .endOf('month')
      //   .format(DATE_FORMAT)
    };
  }

  getPaymentDetails = (payment = {}) => {
    let methodsUsed = [];
    let remarks = [];
    let cardNos = [];
    let expiryDates = [];
    if (payment && payment.details) {
      payment.details.forEach(detail => {
        if (!methodsUsed.includes(detail.method)) {
          methodsUsed.push(detail.method);
        }
        remarks.push(detail.remarks);
        if (!cardNos.includes(detail.cardNo)) {
          cardNos.push(detail.cardNo);
          expiryDates.push(detail.expiryDate);
        }
      });
    }
    return {
      methods: methodsUsed.length > 0 ? methodsUsed.filter(method => !!method).join(', ') : '',
      remarks: remarks.length > 0 ? remarks.filter(remark => !!remark).join('. ') : '',
      cardNos: cardNos.length > 0 ? cardNos.filter(no => !!no).join(', ') : '',
      expiryDates: expiryDates.length > 0 ? expiryDates.filter(date => !!date).join(', ') : ''
    };
  };

  downloadCsv = expenses => {
    const { closeModal } = this.props;

    const curDateTime = moment().format('YYYY_MM_DD_HH_mm_ss');
    const filename = `expenses_${curDateTime}.csv`;

    const url = window.URL.createObjectURL(new Blob([csvjson.toCSV(expenses, { headers: 'key' })]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    this.setState({ isLoading: false });
    closeModal();
  };

  fetchCsv = () => {
    const { year, month, host, form } = this.props;
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const servicePackageIds = values.servicePackages || [];
        getExpensesListing(year, month, host && host !== 'all' ? host : null, servicePackageIds).then(async res => {
          const allExpenses = [];
          console.log(88, res.data);
          if (res.data.length > 0) {
            notification.info({
              message: 'Generating CSV...',
              description: 'Please wait while we generate the CSV file for you. It will be downloaded automatically once it is done.'
            });
            this.setState({
              currency: guard(() => res.data[0].host.currency, 'RM')
            });
            for (let i = 0; i < res.data.length; i++) {
              const formattedReservations = this.formatCsvData(res.data[i].expenses, res.data[i].svcPacName);
              allExpenses.push(formattedReservations);
            }
            this.downloadCsv(allExpenses);
          } else {
            const { closeModal } = this.props;

            notification.error({
              message: 'No Expenses',
              description: 'There are no reservations within the selected date range.'
            });

            this.setState({ isLoading: false });
            closeModal();
          }
        });
      }
    });
  };

  formatCsvData = (expenses, svcPacName) => {
    return expenses.map(a => {
      const {
        unitNo,
        electricity,
        water,
        internet,
        cleaning,
        laundry,
        checkIn,
        checkOut,
        service,
        presetelectricity,
        presetwater,
        presetinternet,
        presetcleaning,
        presetlaundry,
        presetcheckIn,
        presetcheckOut,
        presetservice,
        others,
        toiletry
      } = a;

      let moreThanOneAmount = (a, b) => {
        return a + ' ,' + (b.amount ? b.amount : '-');
      };

      let moreThanOneLabel = (a, b) => {
        return a + ' ,' + (b.label ? b.label : '-');
      };

      return {
        'Service Package Name': svcPacName,
        Unit: unitNo || '',
        Currency: this.state.currency,
        Electricity: electricity || 0,
        'Preset Electricity': presetelectricity || 0,
        'Total Electricity': electricity + presetelectricity || 0,
        Water: water || 0,
        'Preset Water': presetwater || 0,
        'Total Water': water + presetwater || 0,
        Internet: internet || 0,
        'Preset Internet': presetinternet || 0,
        'Total Internet': internet + presetinternet || 0,
        Cleaning: cleaning || 0,
        'Preset Cleaning': presetcleaning || 0,
        'Total Cleaning': cleaning + presetcleaning || 0,
        Laundry: laundry || 0,
        'Preset Laundry': presetlaundry || 0,
        'Total Laundry': laundry + presetlaundry || 0,
        'Check-In': checkIn || 0,
        'Preset Check-In': presetcheckIn || 0,
        'Total Check-In': checkIn + presetcheckIn || 0,
        'Check-Out': checkOut || 0,
        'Preset Check-Out': presetcheckOut || 0,
        'Total Check-Out': checkOut + presetcheckOut || 0,
        Service: service || 0,
        'Preset Service': presetservice || 0,
        'Total Service': service + presetservice || 0,
        Others: others
          ? others && others.length > 0
            ? others.length === 1
              ? others[0].label
                ? others[0].label
                : '-'
              : others.reduce(moreThanOneLabel, '')
            : ''
          : '-',
        OthersAmount: others
          ? others && others.length > 0
            ? others.length === 1
              ? others[0].amount
                ? others[0].amount
                : '-'
              : others.reduce(moreThanOneAmount, '')
            : ''
          : '-',
        Toiletry: toiletry
          ? toiletry && toiletry.length > 0
            ? toiletry.length === 1
              ? toiletry[0].label
                ? toiletry[0].label
                : '-'
              : toiletry.reduce(moreThanOneLabel, '')
            : ''
          : '-',
        ToiletryAmount: toiletry
          ? toiletry && toiletry.length > 0
            ? toiletry.length === 1
              ? toiletry[0].amount
                ? toiletry[0].amount
                : '-'
              : toiletry.reduce(moreThanOneAmount, '')
            : ''
          : '-'
      };
    });
  };

  handleOnOk = () => {
    this.setState({ isLoading: true });

    this.fetchCsv();
  };

  render() {
    const { visible, closeModal, form, servicePackages, year, month } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        title={`Download Expenses CSV for ${year}/${month + 1}`}
        destroyOnClose={true}
        maskClosable={false}
        cancelButtonProps={{ id: 'closecsv-cfm-button1b-expcsv' }}
        cancelText="Close"
        okButtonProps={{ loading: isLoading, id: 'csv-cfm-button1a-expcsv' }}
        okText={'Download CSV'}
        onOk={this.handleOnOk}
      >
        <Row type="flex" justify="space-around" align="middle">
          <FormSelection
            form={form}
            multipleMode
            formLabel="Select the service packages you wish to export: "
            name="servicePackages"
            defaultValue={[]}
            placeholder="Select service packages"
            selections={servicePackages.map(svcPck => {
              return { key: svcPck.svcId, displayValue: `${svcPck.svcPacName}` };
            })}
          />
        </Row>
      </Modal>
    );
  }
}

export default Form.create()(CsvModal);
