import React, { Component } from 'react';
import { DatePicker, Modal, notification, Row, Col, Checkbox, Form, Input, message, Switch } from 'antd';
import csvjson from 'csvjson';
import moment from 'moment';
import PropTypes from 'prop-types';

import { getReservationsCSV, getReservationCountForCSV, SendReservationCsv, SendAdminReservationCsv } from 'utils/apis/reservation';
import { errorHandlerWrapper, getConstantLabel, guard } from 'utils/general';
import { getDifferenceBetweenDate } from 'utils/date';
import { calculatePaymentReceived, calculateTotalAmount, calculateTotalServiceFee } from 'utils/transaction';
import { getHostsAutomated } from 'utils/apis/host';
import { BasicSelect } from 'components/FormSelection/FormSelection';

const { RangePicker } = DatePicker;

const DATE_FORMAT = 'YYYY-MM-DD';

class CsvModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    bookingStatus: PropTypes.array.isRequired,
    bookingTypes: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired,
    states: PropTypes.array.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isAllowViewTransaction: PropTypes.bool.isRequired
  };

  static defaultProps = {
    closeModal: () => {},
    visible: false,
    bookingStatus: [],
    bookingTypes: [],
    countries: [],
    states: [],
    isAdmin: false,
    isAllowViewTransaction: false,
    isAddOn: undefined
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      // startDate: moment()
      //   .startOf('days')
      //   .subtract(7, 'days')
      //   .format(DATE_FORMAT),
      // endDate: moment()
      //   .endOf('days')
      //   .format(DATE_FORMAT),
      startDate: moment()
        .startOf('month')
        .format(DATE_FORMAT),
      endDate: moment()
        .endOf('month')
        .format(DATE_FORMAT),
      selectHostLoading: false,
      selectHostOptions: [],
      optionStatus: [
        {
          value: 'Confirmed',
          label: 'Confirm Booking'
        },
        {
          value: 'Reserved',
          label: 'Booking Inquiry'
        },
        {
          value: 'Awaiting Payment',
          label: 'Awaiting Payment'
        },
        {
          value: 'checkin',
          label: 'Checked-in'
        },
        {
          value: 'checkout',
          label: 'Checked-out'
        },
        {
          value: 'Cancelled by Host',
          label: 'Cancel'
        },
        {
          value: 'Cancelled by Guest',
          label: 'Cancelled by Guest'
        },
        {
          value: 'Cancelled by OTA',
          label: 'Cancelled by OTA'
        },
        {
          value: 'No Show',
          label: 'No Show'
        },
        {
          value: 'vacantDirty',
          label: 'Vacant Dirty (VD)'
        },
        {
          value: 'vacantClean',
          label: 'Vacant Clean (VC)'
        }
      ],
      selectBookingStatus: [
        'Confirmed',
        'Reserved',
        'Awaiting Payment',
        'checkin',
        'checkout',
        'Cancelled by Host',
        'Cancelled by Guest',
        'Cancelled by OTA',
        'No Show',
        'vacantDirty',
        'vacantClean'
      ],

      platFormList: [
        { value: 'hostplatform', label: 'HostPlatform', cleaningFee: true, isIntegrated: false },
        { value: 'agoda', label: 'Agoda', cleaningFee: true, isIntegrated: true },
        { value: 'agodahomes', label: 'Agoda Homes', cleaningFee: true, isIntegrated: true },
        { value: 'airbnb', label: 'Airbnb', cleaningFee: true, isIntegrated: true },
        { value: 'bookingcom', label: 'Booking.com', cleaningFee: true, isIntegrated: true },
        { value: 'bookingEngine', label: 'Homes Booking Website', cleaningFee: true, isIntegrated: false },
        { value: 'hotelBookingEngine', label: 'Hotel Booking Website', cleaningFee: true, isIntegrated: false },
        // { value: 'ctrip', label: 'Ctrip (Deprecated)', isIntegrated: true },
        { value: 'ctripcm', label: 'Ctrip', isIntegrated: true },
        // { value: 'derantauCode', label: 'derantauLabel', isIntegrated: false }, // TEMP: Prevent DERantau rate from showing in MC. isIntegrated will be override by controller when GET
        { value: 'expedia', label: 'Expedia', cleaningFee: true, isIntegrated: true },
        { value: 'extIntegration', label: 'ExtIntegration', isIntegrated: false },
        // { value: 'homeaway', label: 'HomeAway', cleaningFee: true, isIntegrated: false },
        // { value: 'staytion', label: 'Staytion', isIntegrated: false },
        // { value: 'staysuites', label: 'StaySuites', isIntegrated: false }, // TEMP: Prevent SS rate from showing in MC. isIntegrated will be override by controller when GET
        { value: 'traveloka', label: 'Traveloka', isIntegrated: true },
        { value: 'tiketcom', label: 'Tiket.com', isIntegrated: true },
        { value: 'others', label: 'Others', isIntegrated: false }
      ],
      selectPlatform: [
        'hostplatform',
        'agoda',
        'agodahomes',
        'airbnb',
        'bookingcom',
        'bookingEngine',
        'hotelBookingEngine',
        'ctrip',
        'ctripcm',
        'derantauCode',
        'expedia',
        'extIntegration',
        'homeaway',
        'staytion',
        'staysuites',
        'traveloka',
        'tiketcom',
        'others'
      ]
    };
  }

  componentDidMount() {
    if (this.props.isAdmin) {
      this.getHostsList();

      const GetExcludeHost = window.localStorage.getItem('excludeHost');

      if (GetExcludeHost) {
        this.props.form.setFieldsValue({
          excludeHost: JSON.parse(GetExcludeHost)
        });
      }
    }
  }

  getBookingStatusLabelForCSV = bookingStatusCode => {
    const { bookingStatus } = this.props;
    const bookingStatusLabel = getConstantLabel(bookingStatus, bookingStatusCode);
    return bookingStatusLabel || '-';
  };

  getBookingTypeLabelForCSV = (bookingTypesCode = 0) => {
    const { bookingTypes } = this.props;
    const bookingTypeLabel = getConstantLabel(bookingTypes, bookingTypesCode);
    return bookingTypeLabel || '-';
  };

  getGuestNationality = nationality => {
    const { countries } = this.props;
    const nationalityLabel = getConstantLabel(countries, nationality, 'iso2');
    return nationalityLabel || '';
  };

  getPaymentDetails = (payment = {}) => {
    let methodsUsed = [];
    let remarks = [];
    let cardNos = [];
    let expiryDates = [];
    if (payment && payment.details) {
      payment.details.forEach(detail => {
        if (!methodsUsed.includes(detail.method)) {
          methodsUsed.push(detail.method);
        }
        remarks.push(detail.remarks);
        if (!cardNos.includes(detail.cardNo)) {
          cardNos.push(detail.cardNo);
          expiryDates.push(detail.expiryDate);
        }
      });
    }
    return {
      methods: methodsUsed.length > 0 ? methodsUsed.filter(method => !!method).join(', ') : '',
      remarks: remarks.length > 0 ? remarks.filter(remark => !!remark).join('. ') : '',
      cardNos: cardNos.length > 0 ? cardNos.filter(no => !!no).join(', ') : '',
      expiryDates: expiryDates.length > 0 ? expiryDates.filter(date => !!date).join(', ') : ''
    };
  };

  getStatesLabelForCSV = stateCode => {
    const { states } = this.props;
    const filteredState = states.filter(state => state.code === stateCode);
    if (filteredState.length > 0) {
      return filteredState[0].label;
    }
    return '-';
  };

  downloadCsv = async reservations => {
    const { closeModal } = this.props;

    const curDateTime = moment().format('YYYY_MM_DD_HH_mm_ss');
    const filename = `reservations_${curDateTime}.csv`;

    const url = window.URL.createObjectURL(new Blob([csvjson.toCSV(reservations, { headers: 'key' })]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    this.setState({ isLoading: false });
    closeModal();
  };

  fetchCsv = () => {
    const { startDate, endDate } = this.state;

    getReservationCountForCSV({ startDate, endDate }).then(async reservationCount => {
      const { isAddOn } = this.props;
      const pageSize = 1000;
      const maxPage = Math.ceil(reservationCount / pageSize);
      const allReservations = [];

      // check if email is key in
      const { form } = this.props;
      const values = await form.validateFields();

      this.setState({ isLoading: true });

      if (maxPage) {
        notification.info({
          message: 'Generating CSV...',
          description: 'Please wait while we generate the CSV file for you. It will be downloaded automatically once it is done.'
        });

        let pageCounter = 0;

        const handleReservationsResponse = async reservations => {
          var filterBookingStatusReservations = [];
          var filterPLatformReservations = [];

          for (let i = 0; i < this.state.selectBookingStatus.length; i++) {
            await filterBookingStatusReservations.push(...reservations.filter(obj => obj.bookingStatus === this.state.selectBookingStatus[i]));
          }

          for (let i = 0; i < this.state.selectPlatform.length; i++) {
            filterPLatformReservations.push(
              ...filterBookingStatusReservations.filter(
                obj =>
                  (obj.platform === this.state.selectPlatform[i] && obj.bookingType !== 5) ||
                  (obj.platform === this.state.selectPlatform[i] && obj.bookingType !== 6)
              )
            );
          }

          if (reservations) {
            const formattedReservations = this.formatCsvData(filterPLatformReservations);
            allReservations.push(formattedReservations);
            if (++pageCounter === maxPage) {
              //call api if email is key in
              this.downloadCsv(allReservations);
              if (values.email) {
                const res = await SendReservationCsv({
                  email: values.email,
                  reservation: allReservations.reduce((acc, val) => acc.concat(val), [])
                });
              }
            }
          }
        };

        for (let currentPage = 1; currentPage <= maxPage; currentPage++) {
          getReservationsCSV({
            pageSize,
            startDate,
            endDate,
            currentPage,
            isAddOn
          }).then(handleReservationsResponse);
        }
      } else {
        const { closeModal } = this.props;

        notification.error({
          message: 'No Reservations',
          description: 'There are no reservations within the selected date range.'
        });

        this.setState({ isLoading: false });
        closeModal();
      }
    });
  };

  formatCsvData = reservations => {
    const { isAdmin, isAllowViewTransaction } = this.props;
    return reservations.map(a => {
      const {
        bookingType,
        bookingStatus,
        code,
        createdAt,
        createdBy,
        charges,
        deposit,
        endDate,
        guestDetails,
        payment,
        priceDetails,
        remarks,
        transactionRemarks,
        paymentRemarks,
        startDate,
        source,
        rateModifier,
        platform,
        tax,
        addon,
        unit,
        ratePlanName
      } = a;
      const paymentDetails = this.getPaymentDetails(payment);
      const showPrice = isAdmin || isAllowViewTransaction ? true : false;
      let moreThanOneItem = (a, b) => {
        return a + ' ;' + (b.item ? b.item : '-');
      };
      let moreThanOneLabel = (a, b) => {
        return a + ' ;' + (b.label ? b.label : '-');
      };
      let calculateTotalAddOnAmount = (addons = {}) => {
        const allAddOns =
          addons &&
          Object.keys(addons).map(key => {
            return addons[key] && addons[key] > 0 ? addons[key] : 0;
          });
        return allAddOns.reduce((total, amount) => total + amount, 0);
      };

      return showPrice
        ? {
            'Booking Type': this.getBookingTypeLabelForCSV(bookingType),
            'Host Name': unit.roomType.property.host.name || '',
            'Property Name': unit.roomType.property.name || '',
            'Property City': unit.roomType.property.city || '',
            'Property State': this.getStatesLabelForCSV(unit.roomType.property.state),
            'Property Country': unit.roomType.property.countryCode || '',
            'Room Type': unit.roomType.name || '',
            'Capacity (Adults)': unit.roomType.capacity ? unit.roomType.capacity.adult : '',
            'Capacity (Children)': unit.roomType.capacity ? unit.roomType.capacity.children : '',
            'Room Size (sqft)': unit.roomType.roomSizeInSqFt || '',
            'No. of Bed Rooms': unit.roomType.bedrooms ? unit.roomType.bedrooms.length : '',
            'No. of Bath Rooms': unit.roomType.bathrooms || '',
            'No. of Living Rooms': unit.roomType.livingrooms || '',
            'Unit Name': "'" + unit.name || '',
            'Check Out Time': unit.roomType.property.checkOutTime || '',
            'Check In Date': startDate || '',
            'Check Out Date': endDate || '',
            'Total Nights': getDifferenceBetweenDate(startDate, endDate) || '',
            'Confirmation Code': code || '',
            'Created At': createdAt || '',
            'Created By': (createdBy.userProfile && `${createdBy.userProfile.firstName || '-'} ${createdBy.userProfile.lastName || ''}`) || '-',
            'Booking Source': platform || source || '',
            'Booking Status': this.getBookingStatusLabelForCSV(bookingStatus),
            'Number of Pax': guestDetails.numberOfPax || 0,
            Remarks: remarks || '',
            'Booking Website Promotion code': rateModifier ? rateModifier.code : '',
            'Deposit Collected': (deposit && deposit.collect) || 0,
            'Deposit Refunded': (deposit && deposit.refund) || 0,
            Currency: priceDetails.currency || '',
            'Rate Name': ratePlanName || '',
            'Room Rate': (charges && charges.rental) || 0,
            'Platform Fee': (priceDetails && priceDetails.platformFee) || 0,
            'Cleaning Fee': (charges && charges.cleaning) || 0,
            'Extra Guest Fee': (charges && charges.extraGuest) || 0,
            'Early Check-in Fee': (charges && charges.earlyCheckIn) || 0,
            'Late Check-out Fee': (charges && charges.lateCheckOut) || 0,
            'Shuttle Fee': (charges && charges.shuttle) || 0,
            'Transportation Fee': (charges && charges.transportation) || 0,
            'Breakfast Fee': (charges && charges.breakfast) || 0,
            'Lunch Fee': (charges && charges.lunch) || 0,
            'Dinner Fee': (charges && charges.dinner) || 0,
            'Other Fee': (charges && charges.other) || 0,
            'Add-ons': addon ? calculateTotalAddOnAmount(addon) : 0,
            'Total Service Fee': calculateTotalServiceFee(charges),
            'Total Price': calculateTotalAmount(charges, tax, addon),
            'Average Price per Night': calculateTotalAmount(charges, tax, addon) / (getDifferenceBetweenDate(startDate, endDate) || 1),
            'Sales Service Tax': (tax && tax.sst) || 0,
            'Tourism Tax': (tax && tax.tourism) || 0,
            'Heritage Tax': (tax && tax.heritage) || 0,
            'OTA Tax': (tax && tax.ota) || 0,
            'First Name': guestDetails.userProfile ? guestDetails.userProfile.firstName : '-',
            'Last Name': guestDetails.userProfile ? guestDetails.userProfile.lastName : '-',
            'Guest Name':
              (guestDetails.userProfile && `${guestDetails.userProfile.firstName || '-'} ${guestDetails.userProfile.lastName || ''}`) || '-',
            'Identification Number': guestDetails.userProfile ? guestDetails.userProfile.identificationNo : '',
            'User Email': guestDetails.userProfile ? guestDetails.userProfile.emails : '',
            'Contact Number': guestDetails.userProfile ? guestDetails.userProfile.contactNos : '-',
            'Host Email': unit.roomType.property.host.email ? unit.roomType.property.host.email : '-',
            'Host Contact Number': unit.roomType.property.host.contactNo ? unit.roomType.property.host.contactNo : '-',
            Nationality: guestDetails.userProfile && this.getGuestNationality(guestDetails.userProfile.nationality),
            'Vehicle Type': guestDetails
              ? guestDetails.vehicleDetails && guestDetails.vehicleDetails[0]
                ? guestDetails.vehicleDetails[0].vehicleType
                : ''
              : '',
            'Vehicle Model': guestDetails
              ? guestDetails.vehicleDetails && guestDetails.vehicleDetails[0]
                ? guestDetails.vehicleDetails[0].vehicleModel
                : ''
              : '',
            'Vehicle Number': guestDetails
              ? guestDetails.vehicleDetails && guestDetails.vehicleDetails[0]
                ? guestDetails.vehicleDetails[0].vehicleNo
                : ''
              : '',
            'Vehicle color': guestDetails
              ? guestDetails.vehicleDetails && guestDetails.vehicleDetails[0]
                ? guestDetails.vehicleDetails[0].vehicleColor
                : ''
              : '',
            Item: guestDetails
              ? guestDetails.itemsProvided && guestDetails.itemsProvided.length > 0
                ? guestDetails.itemsProvided.length === 1
                  ? guestDetails.itemsProvided[0].item
                    ? guestDetails.itemsProvided[0].item
                    : '-'
                  : guestDetails.itemsProvided.reduce(moreThanOneItem, '')
                : ''
              : '-',
            Label: guestDetails
              ? guestDetails.itemsProvided && guestDetails.itemsProvided.length > 0
                ? guestDetails.itemsProvided.length === 1
                  ? guestDetails.itemsProvided[0].label
                    ? guestDetails.itemsProvided[0].label
                    : '-'
                  : guestDetails.itemsProvided.reduce(moreThanOneLabel, '')
                : ''
              : '-',
            'Emergency Name': guestDetails ? (guestDetails.emergency ? guestDetails.emergency.emergencyName : '') : '',
            'Payment Method': paymentDetails.methods,
            'Credit Card/Debit Card No.': paymentDetails.cardNos,
            'Expiry Date': paymentDetails.expiryDates,
            'Payment Remarks': paymentRemarks,
            'Transaction Remarks': transactionRemarks,
            'Payment Received': calculatePaymentReceived(payment)
          }
        : {
            'Booking Type': this.getBookingTypeLabelForCSV(bookingType),
            'Host Name': unit.roomType.property.host.name || '',
            'Property Name': unit.roomType.property.name || '',
            'Property City': unit.roomType.property.city || '',
            'Property State': this.getStatesLabelForCSV(unit.roomType.property.state),
            'Property Country': unit.roomType.property.countryCode || '',
            'Room Type': unit.roomType.name || '',
            'Capacity (Adults)': unit.roomType.capacity ? unit.roomType.capacity.adult : '',
            'Capacity (Children)': unit.roomType.capacity ? unit.roomType.capacity.children : '',
            'Room Size (sqft)': unit.roomType.roomSizeInSqFt || '',
            'No. of Bed Rooms': unit.roomType.bedrooms ? unit.roomType.bedrooms.length : '',
            'No. of Bath Rooms': unit.roomType.bathrooms || '',
            'No. of Living Rooms': unit.roomType.livingrooms || '',
            'Unit Name': "'" + unit.name || '',
            'Check Out Time': unit.roomType.property.checkOutTime || '',
            'Check In Date': startDate || '',
            'Check Out Date': endDate || '',
            'Total Nights': getDifferenceBetweenDate(startDate, endDate) || '',
            'Confirmation Code': code || '',
            'Created At': createdAt || '',
            'Created By': (createdBy.userProfile && `${createdBy.userProfile.firstName || '-'} ${createdBy.userProfile.lastName || ''}`) || '-',
            'Booking Source': platform || source || '',
            'Booking Status': this.getBookingStatusLabelForCSV(bookingStatus),
            'Number of Pax': guestDetails.numberOfPax || 0,
            Remarks: remarks || '',
            'Booking Website Promotion code': rateModifier ? rateModifier.code : '',
            'First Name': guestDetails.userProfile ? guestDetails.userProfile.firstName : '-',
            'Last Name': guestDetails.userProfile ? guestDetails.userProfile.lastName : '-',
            'Guest Name':
              (guestDetails.userProfile && `${guestDetails.userProfile.firstName || '-'} ${guestDetails.userProfile.lastName || ''}`) || '-',
            'Identification Number': guestDetails.userProfile ? guestDetails.userProfile.identificationNo : '',
            'User Email': guestDetails.userProfile ? guestDetails.userProfile.emails : '',
            'Contact Number': guestDetails.userProfile ? guestDetails.userProfile.contactNos : '-',
            'Host Email': unit.roomType.property.host.email ? unit.roomType.property.host.email : '-',
            'Host Contact Number': unit.roomType.property.host.contactNo ? unit.roomType.property.host.contactNo : '-',
            Nationality: guestDetails.userProfile && this.getGuestNationality(guestDetails.userProfile.nationality),
            'Vehicle Type': guestDetails
              ? guestDetails.vehicleDetails && guestDetails.vehicleDetails[0]
                ? guestDetails.vehicleDetails[0].vehicleType
                : ''
              : '',
            'Vehicle Model': guestDetails
              ? guestDetails.vehicleDetails && guestDetails.vehicleDetails[0]
                ? guestDetails.vehicleDetails[0].vehicleModel
                : ''
              : '',
            'Vehicle Number': guestDetails
              ? guestDetails.vehicleDetails && guestDetails.vehicleDetails[0]
                ? guestDetails.vehicleDetails[0].vehicleNo
                : ''
              : '',
            'Vehicle color': guestDetails
              ? guestDetails.vehicleDetails && guestDetails.vehicleDetails[0]
                ? guestDetails.vehicleDetails[0].vehicleColor
                : ''
              : '',
            Item: guestDetails
              ? guestDetails.itemsProvided && guestDetails.itemsProvided.length > 0
                ? guestDetails.itemsProvided.length === 1
                  ? guestDetails.itemsProvided[0].item
                    ? guestDetails.itemsProvided[0].item
                    : '-'
                  : guestDetails.itemsProvided.reduce(moreThanOneItem, '')
                : ''
              : '-',
            Label: guestDetails
              ? guestDetails.itemsProvided && guestDetails.itemsProvided.length > 0
                ? guestDetails.itemsProvided.length === 1
                  ? guestDetails.itemsProvided[0].label
                    ? guestDetails.itemsProvided[0].label
                    : '-'
                  : guestDetails.itemsProvided.reduce(moreThanOneLabel, '')
                : ''
              : '-',
            'Emergency Name': guestDetails ? (guestDetails.emergency ? guestDetails.emergency.emergencyName : '') : ''
          };
    });
  };

  sendAdminReservationCsv = async () => {
    const { startDate, endDate } = this.state;
    const { isAdmin, isAllowViewTransaction, closeModal } = this.props;

    // check if email is key in
    const { form } = this.props;
    const values = await form.validateFields();

    // console.log('values', values);

    window.localStorage.setItem('excludeHost', JSON.stringify(values.excludeHost));

    this.setState({ isLoading: true });

    notification.info({
      message: 'Generating CSV...',
      description: 'Please wait while we generate the CSV file for you. It will send to your email once it is done.'
    });

    const res = await SendAdminReservationCsv({
      startDate: startDate,
      endDate: endDate,
      bookingStatus: this.state.selectBookingStatus,
      platform: this.state.selectPlatform,
      email: values.email,
      isAdmin: isAdmin,
      isAllowViewTransaction: isAllowViewTransaction,
      // Admin only
      excludeHost: values.excludeHost,
      pushToRentalytics: values.pushToRentalytics ? true : false
    });

    console.log('res', res.status);
    if (res.status === 200) {
      this.setState({ isLoading: false });
      if (res.status === 200) {
        closeModal();
      }
    } else {
      console.log(res, 564);
      notification.destroy();
      message.error(guard(() => res.response.data.message), 'Encounter error, please try again later...');
      this.setState({ isLoading: false });
    }
  };

  getDatePickerRanges = () => {
    const today = [moment(), moment()];
    const currentMonth = [moment().startOf('month'), moment().endOf('month')];
    const threeMonths = [
      moment()
        .subtract(3, 'month')
        .startOf('month'),
      moment().endOf('month')
    ];
    const sixMonths = [
      moment()
        .subtract(6, 'month')
        .startOf('month'),
      moment().endOf('month')
    ];

    return { Today: today, 'This Month': currentMonth, '3 Months': threeMonths, '6 Months': sixMonths };
  };

  getDisabledDates = () => {};

  handleOnOk = () => {
    const { isAdmin } = this.props;
    if (isAdmin) {
      this.sendAdminReservationCsv();
    } else {
      this.fetchCsv();
    }
  };

  handleCalendarChange = dates => {
    const { startDate: oldStart, endDate: oldEnd } = this.state;
    const [start, end] = dates;
    this.setState({ startDate: start || oldStart, endDate: end || oldEnd });
  };

  getHostsList = async () => {
    if (!this.props.isAdmin) {
      return;
    }
    this.setState({
      selectHostLoading: true
    });
    const hostOptions = await errorHandlerWrapper(getHostsAutomated().then(res => res.data.map(host => ({ label: host.name, value: host._id }))), []);
    console.log(hostOptions, 618);
    this.setState({
      selectHostLoading: false,
      selectHostOptions: hostOptions
    });
  };

  render() {
    const { visible, closeModal, isAdmin } = this.props;
    const { isLoading, startDate, endDate, optionStatus, selectBookingStatus, platFormList, selectPlatform } = this.state;

    const handleDateChange = (dates, [startDate, endDate]) => {
      this.setState({ startDate, endDate });
      // this.setState({ startDate: moment(startDate).format(DATE_FORMAT), endDate: moment(endDate).format(DATE_FORMAT) });
    };

    const disabledDate = dates => current => {
      if (!dates || dates.length === 0) {
        return false;
      }
      return dates[0] && current.diff(dates[0], 'month') > 6;
    };

    return (
      <Modal
        width={600}
        visible={visible}
        onCancel={closeModal}
        title={`Download Reservation CSV`}
        destroyOnClose={true}
        maskClosable={false}
        cancelButtonProps={{ id: 'closecsv-cfm-button1b-rescsv' }}
        cancelText="Close"
        okButtonProps={{ loading: isLoading, id: 'csv-cfm-button1a-rescsv', disabled: selectBookingStatus.length === 0 }}
        okText={'Download CSV'}
        onOk={this.handleOnOk}
        // footer={<Button>Push to Rentalytics</Button>}
      >
        <Row style={{ marginBottom: 6 }}>Check In Date Range:</Row>

        <Row type="flex" justify="center">
          {/* <RangePicker
            autoFocus
            allowClear={false}
            style={{ width: '100%', marginBottom: 16 }}
            value={[moment(startDate, DATE_FORMAT), moment(endDate, DATE_FORMAT)]}
            onCalendarChange={e => {
              if (e.length === 2) {
                const diffInDays = moment(e[1]).diff(moment(e[0]), 'days');
                if (diffInDays > 7) {
                  message.error('Date range cannot be more than 7 days');
                  this.setState({
                    startDate: moment()
                      .subtract(7, 'days')
                      .startOf('days')
                      .format(DATE_FORMAT),
                    endDate: moment()
                      .endOf('days')
                      .format(DATE_FORMAT)
                  });
                  return;
                }
                handleDateChange(e, e);
              }
            }}
            // ranges={this.getDatePickerRanges()}
            disabledDate={disabledDate([startDate, endDate])}
          /> */}
          <RangePicker
            autoFocus
            allowClear={false}
            style={{ width: '100%', marginBottom: 16 }}
            defaultValue={[moment(startDate, DATE_FORMAT), moment(endDate, DATE_FORMAT)]}
            onChange={handleDateChange}
            ranges={this.getDatePickerRanges()}
            onCalendarChange={this.handleCalendarChange}
            disabledDate={disabledDate([startDate, endDate])}
          />
        </Row>

        <Row style={{ marginBottom: 6 }}>Booking Status:</Row>

        <Checkbox.Group
          defaultValue={selectBookingStatus}
          style={{ width: '100%' }}
          onChange={e =>
            this.setState({
              selectBookingStatus: e
            })
          }
        >
          <Row type="flex" gutter={[0, 8]}>
            {optionStatus.map(value => (
              <Col xs={24} md={8} key={value.value}>
                <Checkbox value={value.value}>{value.label}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>

        <Row style={{ marginTop: 16, marginBottom: 6 }}>Platform :</Row>

        <Checkbox.Group
          defaultValue={selectPlatform}
          style={{ width: '100%' }}
          onChange={e =>
            this.setState({
              selectPlatform: e
            })
          }
        >
          <Row type="flex" gutter={[0, 8]} style={{ marginBottom: 24 }}>
            {platFormList.map(value => (
              <Col xs={24} md={8} key={value.value}>
                <Checkbox value={value.value}>{value.label}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>

        <Form layout="vertical">
          {isAdmin && (
            <Form.Item label={'Exclude Host (Admin Only)'} help="Exclude reservation for target host">
              {this.props.form.getFieldDecorator('excludeHost', {
                initialValue: [],
                rules: [
                  {
                    required: false,
                    message: 'Please select host to exclude'
                  }
                ]
              })(
                <BasicSelect
                  isDisabled={this.state.selectHostLoading}
                  selections={this.state.selectHostOptions}
                  placeholder="No Host Excluded"
                  isMultiple
                />
              )}
            </Form.Item>
          )}
          <Form.Item label={isAdmin ? 'Email' : 'Email (Optional)'} help="Fill up your email address to receive CSV file.">
            {this.props.form.getFieldDecorator('email', {
              rules: [
                {
                  required: isAdmin ? true : false,
                  message: 'Please enter Email'
                },
                {
                  type: 'email',
                  message: 'Please enter valid email'
                }
              ]
            })(<Input placeholder={isAdmin ? 'Enter Your Email' : 'Enter Your Email (Optional)'} />)}
          </Form.Item>

          {isAdmin && (
            <Form.Item label="Push to Rentalytics (Admin Only)">
              {this.props.form.getFieldDecorator('pushToRentalytics', { valuePropName: 'checked' })(
                <Switch checkedChildren="Auto export to Rentalytics" unCheckedChildren="No" />
              )}
            </Form.Item>
          )}
        </Form>

        {/* <Row type="flex" justify="center">
          <Select
            style={{ width: '100%' }}
            defaultValue={selectBookingStatus}
            value={selectBookingStatus}
            mode="multiple"
            onChange={e =>
              this.setState({
                selectBookingStatus: e
              })
            }
          >
            {optionStatus.map(value => (
              <Select.Option value={value.value}>{value.label}</Select.Option>
            ))}
          </Select>
        </Row> */}
      </Modal>
    );
  }
}

export default Form.create()(CsvModal);
