import { httpClient } from './../httpClient';
import { apiErrorHandler } from './helpers';
import { useCustomPaginatedQuery } from 'hooks/reactQuery';

export const getOTAResyncStatus = () =>
  httpClient
    .get('/resync-ota')
    .then(response => response.data)
    .catch(apiErrorHandler);

export const postOTAResync = ota =>
  httpClient
    .post('/resync-ota', {
      ota
    })
    .then(response => response.data)
    .catch(apiErrorHandler);
