import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { CSVLink } from 'react-csv';

const CSVDownload = ({ onDownloadClick, fileName, title, data, isLoading, disabled }) => {
  return (
    <div>
      {!isLoading && data && Object.keys(data).length > 0 ? (
        <CSVLink target="_blank" filename={fileName} data={data}>
          <Button className="csvBtn" icon="download" disabled={disabled}>
            Download {title}
          </Button>
        </CSVLink>
      ) : (
        <Button className="csvBtn" icon="download" loading={isLoading} onClick={onDownloadClick} disabled={disabled}>
          Fetch {title}
        </Button>
      )}
    </div>
  );
};

CSVDownload.propTypes = {
  onDownloadClick: PropTypes.func,
  fileName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  data: PropTypes.array
};

CSVDownload.defaultProps = {
  onDownloadClick: () => {},
  data: {}
};

export default CSVDownload;
