import React, { Fragment } from 'react';
import { Alert, Card, Row, Col, Form, DatePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import FormInput from 'components/FormInput/FormInput';
import UploadFile from 'components/UploadFile/UploadFile';
import UploadFiles from 'components/UploadFiles/UploadFiles';

const FIELD_NAME_INSURED_NAME = 'insuredName';
const FIELD_NAME_ID_NUM = 'idNo';
const FIELD_NAME_REF_CODE = 'refCode';
const FIELD_NAME_LOCATION = 'location';
const FIELD_NAME_CERT_NO = 'certNo';
const FIELD_NAME_MASTER_ALL_RISK_NUM = 'allRisk';
const FIELD_NAME_MASTER_PUBLIC_LIABILITY_NUM = 'publicLiability';
const FIELD_NAME_START_DATE = 'insuranceStartDate';
const FIELD_NAME_END_DATE = 'insuranceEndDate';

const FormItem = Form.Item;

class HostProtectCard extends React.Component {
  disabledStartDate = value => {
    const { form } = this.props;
    const endDate = form.getFieldValue(FIELD_NAME_END_DATE);
    if (!value || !endDate) {
      return false;
    }
    return value.valueOf() > endDate.valueOf();
  };

  disabledEndDate = value => {
    const { form } = this.props;
    const startDate = form.getFieldValue(FIELD_NAME_START_DATE);
    if (!value || !startDate) {
      return false;
    }
    return value.valueOf() <= startDate.valueOf();
  };

  validateDate = (rule, value, callback) => {
    const { form } = this.props;
    this.triggerValidateCertNo(undefined, undefined, () => {});
    let error;
    const endDate = form.getFieldValue(FIELD_NAME_END_DATE);
    if (endDate && !value) {
      error = 'Please provide start date for the insurance.';
    } else if (value && !endDate) {
      error = 'Please provide end date for the insurance.';
      form.setFields({
        [FIELD_NAME_END_DATE]: {
          value: endDate,
          errors: [new Error(error)]
        }
      });
    } else if (!value && !endDate) {
      form.setFields({
        [FIELD_NAME_END_DATE]: {
          value: endDate,
          errors: undefined
        }
      });
    }
    callback(error);
  };

  triggerValidateDate = (rule, value, callback) => {
    const { form } = this.props;
    form.validateFields([FIELD_NAME_START_DATE], { force: true }, (errors, value) => {
      if (errors) {
        callback(errors[FIELD_NAME_START_DATE].errors[0].message);
      } else {
        callback();
      }
    });
    callback();
  };

  triggerValidateCertNo = (rule, value, callback) => {
    const { form } = this.props;
    form.validateFields([FIELD_NAME_CERT_NO], { force: true });
    callback();
  };

  validateHostProtectForm = (rule, value, callback) => {
    const { form } = this.props;
    const allValues = form.getFieldsValue([
      FIELD_NAME_INSURED_NAME,
      FIELD_NAME_ID_NUM,
      FIELD_NAME_REF_CODE,
      FIELD_NAME_LOCATION,
      FIELD_NAME_MASTER_ALL_RISK_NUM,
      FIELD_NAME_MASTER_PUBLIC_LIABILITY_NUM,
      FIELD_NAME_START_DATE,
      FIELD_NAME_END_DATE
    ]);
    if (this.checkAnyFieldHasValue(allValues) && !value) {
      callback('Certification number is required');
    } else {
      callback();
    }
  };

  checkAnyFieldHasValue = formValues => {
    let hasAnyValue = false;
    const allFormKeys = Object.keys(formValues);
    for (let i = 0; i < allFormKeys.length; i++) {
      const currentKey = allFormKeys[i];
      if (formValues[currentKey]) {
        hasAnyValue = true;
        break;
      }
    }
    return hasAnyValue;
  };

  render() {
    const {
      form,
      cardClassname,
      defaultValues,
      shouldDisable,
      handleOnUploadFinish,
      hostProtectCertFile,
      handleOnFileDelete,
      hostProtectDocFiles,
      handleOnDocUploadFinish,
      handleOnDocDelete
    } = this.props;
    return (
      <Fragment>
        {shouldDisable && (
          <Fragment>
            <Row>
              <Alert message="View only" description="You have no permission to edit these information" type="warning" showIcon />
            </Row>
            <br />
          </Fragment>
        )}
        <Card title="HostProtect Information" className={cardClassname}>
          <Row gutter={16}>
            <Col span={24} md={24}>
              <FormInput
                form={form}
                name={FIELD_NAME_INSURED_NAME}
                defaultValue={defaultValues[FIELD_NAME_INSURED_NAME]}
                inputType="input"
                label="Insured Name"
                extraRules={
                  !shouldDisable
                    ? [
                        {
                          validator: this.triggerValidateCertNo
                        }
                      ]
                    : []
                }
                extraProps={{
                  disabled: shouldDisable
                }}
                size="large"
              />
            </Col>
            <Col span={24} md={24}>
              <FormInput
                form={form}
                name={FIELD_NAME_ID_NUM}
                defaultValue={defaultValues[FIELD_NAME_ID_NUM]}
                inputType="input"
                label="Identification No. / Registration No."
                extraRules={
                  !shouldDisable
                    ? [
                        {
                          validator: this.triggerValidateCertNo
                        }
                      ]
                    : []
                }
                extraProps={{
                  disabled: shouldDisable
                }}
                size="large"
              />
            </Col>
            <Col span={24} md={24}>
              <FormInput
                form={form}
                name={FIELD_NAME_REF_CODE}
                defaultValue={defaultValues[FIELD_NAME_REF_CODE]}
                inputType="input"
                label="Ref. Code"
                extraRules={
                  !shouldDisable
                    ? [
                        {
                          validator: this.triggerValidateCertNo
                        }
                      ]
                    : []
                }
                extraProps={{
                  disabled: shouldDisable
                }}
                size="large"
              />
            </Col>
            <Col span={24} md={24}>
              <FormInput
                form={form}
                name={FIELD_NAME_LOCATION}
                defaultValue={defaultValues[FIELD_NAME_LOCATION]}
                inputType="input"
                label="Location to be insured"
                extraRules={
                  !shouldDisable
                    ? [
                        {
                          validator: this.triggerValidateCertNo
                        }
                      ]
                    : []
                }
                extraProps={{
                  disabled: shouldDisable
                }}
                size="large"
              />
            </Col>
            <Col span={24} md={24}>
              <FormInput
                form={form}
                name={FIELD_NAME_CERT_NO}
                defaultValue={defaultValues[FIELD_NAME_CERT_NO]}
                inputType="input"
                label="Certification No."
                extraRules={
                  !shouldDisable
                    ? [
                        {
                          validator: this.validateHostProtectForm
                        }
                      ]
                    : []
                }
                extraProps={{
                  disabled: shouldDisable
                }}
                size="large"
              />
            </Col>
            <Col span={24} md={24}>
              <FormInput
                form={form}
                name={FIELD_NAME_MASTER_ALL_RISK_NUM}
                defaultValue={defaultValues[FIELD_NAME_MASTER_ALL_RISK_NUM]}
                inputType="input"
                label="Master Policy No. (All Risk)"
                extraRules={
                  !shouldDisable
                    ? [
                        {
                          validator: this.triggerValidateCertNo
                        }
                      ]
                    : []
                }
                extraProps={{
                  disabled: shouldDisable
                }}
                size="large"
              />
            </Col>
            <Col span={24} md={24}>
              <FormInput
                form={form}
                name={FIELD_NAME_MASTER_PUBLIC_LIABILITY_NUM}
                defaultValue={defaultValues[FIELD_NAME_MASTER_PUBLIC_LIABILITY_NUM]}
                inputType="input"
                label="Master Policy No. (Public Liability)"
                extraRules={
                  !shouldDisable
                    ? [
                        {
                          validator: this.triggerValidateCertNo
                        }
                      ]
                    : []
                }
                extraProps={{
                  disabled: shouldDisable
                }}
                size="large"
              />
            </Col>
          </Row>
          <p style={{ marginBottom: '8px' }}>Insurance Period</p>
          <Row type="flex" justify="space-between">
            <Col span={24} md={11}>
              <FormItem>
                {form.getFieldDecorator(FIELD_NAME_START_DATE, {
                  rules: [{ validator: this.validateDate }],
                  initialValue: defaultValues[FIELD_NAME_START_DATE] ? moment(defaultValues[FIELD_NAME_START_DATE]) : undefined
                })(
                  <DatePicker
                    placeholder="Start Date"
                    size="large"
                    style={{ width: '100%' }}
                    disabledDate={this.disabledStartDate}
                    disabled={shouldDisable}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={24} md={12}>
              <FormItem>
                {form.getFieldDecorator(FIELD_NAME_END_DATE, {
                  rules: [{ validator: this.triggerValidateDate }],
                  initialValue: defaultValues[FIELD_NAME_END_DATE] ? moment(defaultValues[FIELD_NAME_END_DATE]) : undefined
                })(
                  <DatePicker
                    placeholder="End Date"
                    size="large"
                    style={{ width: '100%' }}
                    disabledDate={this.disabledEndDate}
                    disabled={shouldDisable}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Card>
        <Card title="HostProtect Documents" className={cardClassname}>
          <Row>
            <UploadFiles
              files={hostProtectDocFiles}
              onUploadFinish={handleOnDocUploadFinish}
              onFileDelete={handleOnDocDelete}
              checkIsAdmin={!shouldDisable}
            />
          </Row>
        </Card>
        <Card title="HostProtect Certificate" className={cardClassname}>
          <Row>
            <UploadFile
              file={hostProtectCertFile}
              buttonText={'Upload HostProtect Certificate'}
              onUploadFinish={handleOnUploadFinish}
              onFileDelete={handleOnFileDelete}
            />
          </Row>
        </Card>
      </Fragment>
    );
  }
}

HostProtectCard.propTypes = {
  form: PropTypes.object.isRequired,
  cardClassname: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  shouldDisable: PropTypes.bool
};

HostProtectCard.defaultProps = {
  defaultValues: {},
  shouldDisable: true
};

export default HostProtectCard;
