import { httpClient } from './../httpClient';
import { apiErrorHandler } from './helpers';

export const getUsers = () => {
  return httpClient
    .get('/user/populated')
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const createUser = userDetails => {
  return httpClient
    .post('/user/createUser', userDetails)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const updateUser = (userId, body) => {
  return httpClient
    .put(`/user/${userId}`, body)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getUserById = userId => {
  return httpClient
    .get(`/user/${userId}`)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);
};

export const getUserProfileById = userId => {
  return httpClient
    .get('/userProfile/populated?userId=' + userId)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const updateUserProfile = (userProfileId, body) => {
  return httpClient
    .put(`/userProfile/${userProfileId}`, body)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const deleteUser = id => {
  return httpClient
    .delete(`/user/${id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const updateOwner = userId => {
  return httpClient
    .put(`/user/${userId}/updateOwner`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};
