import React, { Component } from 'react';
import { Button, Card, Col, Modal, Row, Switch } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { getWebRateByRoomType } from 'utils/apis/rate';

import OTARateInput from '../../../OTARateInput/OTARateInput';
import CtripSyncModal from './components/CtripSyncModal';
import CtripAdvancedOptionsModal from './components/CtripAdvancedOptionsModal';

import { getConstants } from 'utils/apis/constants';
import { generatePercentageFromDisplay } from 'utils/general';

import '../../../../../OTAIntegration.css';

const STANDARD_RATE_CODE = 'STD';
class CtripForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomTypes: [],
      roomMapping: [],
      ctrip: undefined,
      gotRoomTypes: false,
      isAdvancedOptionsModalVisible: false,
      displayCtripSyncModal: false
    };
    this.getRoomTypes = this.getRoomTypes.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.toggleToSync = this.toggleToSync.bind(this);
    this.onCtripSubmit = this.onCtripSubmit.bind(this);
    this.toggleDisplayCtripSyncModal = this.toggleDisplayCtripSyncModal.bind(this);
    this.handleOnCtripHotelIdChange = this.handleOnCtripHotelIdChange.bind(this);
  }

  componentDidMount = async () => {
    let currentCtrip;
    const { data, property } = this.props;
    if (Object.keys(data).length === 0) {
      currentCtrip = {
        property: property._id,
        roomTypes: []
      };
    } else {
      currentCtrip = {
        ...data,
        roomTypes: data.roomTypes.map(rt => ({ roomType: rt.roomType._id, rate: { ...rt.rate, isDerived: !!rt.rate.isDerived } }))
      };
    }

    const rateModifierTypeConstants = await getConstants('rateModifierType').then(res => Object.values(res.data));
    const rateModifierSignConstants = await getConstants('rateModifierSign').then(res => Object.values(res.data));

    this.setState({
      ctrip: currentCtrip,
      roomMapping: currentCtrip.roomTypes,
      rateModifierTypeConstants,
      rateModifierSignConstants
    });
  };

  componentDidUpdate(prevProps, prevStates) {
    const { property } = this.props;
    const { gotRoomTypes, ctrip } = this.state;

    if (!!ctrip && !gotRoomTypes && property.roomTypes) {
      this.getRoomTypes(ctrip);
    }
  }

  getRoomTypes(ctrip) {
    const { property } = this.props;
    const roomTypes = property.roomTypes || [];
    if (ctrip._id) {
      const ctripRoomTypes = ctrip.roomTypes.map(rts => rts.roomType.toString());
      for (let i = 0; i < roomTypes.length; i++) {
        const roomType = roomTypes[i];
        const syncStatus = ctripRoomTypes.includes(roomType._id.toString());
        roomType.syncStatus = syncStatus;
        roomType.rate = roomType.syncStatus && ctrip.roomTypes.find(crt => crt.roomType && crt.roomType.toString() === roomType._id.toString()).rate;
      }
    }

    this.setState({
      roomTypes,
      gotRoomTypes: true
    });
    this.props.stopLoading();
  }

  toggleDisplayCtripSyncModal() {
    this.setState({
      displayCtripSyncModal: !this.state.displayCtripSyncModal
    });
  }

  async onCtripSubmit() {
    const { roomMapping, ctrip } = this.state;
    this.setState({
      ctrip: {
        ...ctrip,
        data: {
          roomTypes: roomMapping
        }
      }
    });
    this.onConfirm();
  }

  onConfirm() {
    const toggleDisplayCtripSyncModal = () => this.toggleDisplayCtripSyncModal();
    Modal.confirm({
      title: 'Are you sure you want to save this integration setting?',
      okText: 'Continue',
      okType: 'warning',
      cancelText: 'Back',
      onOk() {
        toggleDisplayCtripSyncModal();
      },
      onCancel() {}
    });
  }

  updateRates = roomType => newRates => {
    const { roomMapping } = this.state;
    const roomMappingForRoomType = roomMapping.find(mapping => mapping.roomType === roomType);
    if (roomMappingForRoomType) {
      roomMappingForRoomType.rate = {
        ...roomMappingForRoomType.rate,
        ...newRates
      };
    } else {
      roomMappingForRoomType.rate = newRates;
    }
    this.setState({ roomMapping });
  };

  populateRateByRoomType = roomTypeId => {
    const { roomMapping } = this.state;
    const roomMap = roomMapping.find(roomMap => roomMap.roomType === roomTypeId);
    if (!roomMap.rate) {
      getWebRateByRoomType(roomTypeId).then(webRates => {
        const stdRate = webRates.data.find(rate => rate.code === STANDARD_RATE_CODE);
        if (stdRate) {
          const { weekday, weekend, isDerived } = stdRate;
          this.updateRates(roomTypeId)({ weekday, weekend, isDerived });
        }
      });
    }
  };

  toggleToSync = roomTypeId => isSync => {
    this.setState(state => {
      let { roomTypes, roomMapping } = state;
      if (!isSync) {
        roomMapping = roomMapping.filter(roomMap => String(roomMap.roomType) !== String(roomTypeId));
      } else {
        roomMapping.push({ roomType: roomTypeId });
        this.populateRateByRoomType(roomTypeId);
      }
      roomTypes.find(roomtype => String(roomtype._id) === String(roomTypeId)).syncStatus = isSync;
      return {
        roomTypes,
        roomMapping
      };
    });
  };

  handleOnCtripHotelIdChange(e) {
    const { ctrip } = this.state;
    this.setState({
      ctrip: {
        ...ctrip,
        foreignPropertyId: e.target.value
      }
    });
  }

  handleOnAdvanceOptionsClick = isVisible => e => {
    e.preventDefault();
    this.setState({
      isAdvancedOptionsModalVisible: isVisible
    });
  };

  handleOnAdvancedOptionsConfirm = rateDistributionPayload => {
    const { roomMapping, rateModifierTypeConstants, rateModifierSignConstants } = this.state;

    const processedRoomMapping = roomMapping.map(room => {
      const rateDistribution = rateDistributionPayload.find(rateDistribution => room.roomType === rateDistribution.roomId);

      if (!!rateDistribution) {
        const rateObject = rateDistribution.rates[0];

        const isDerived = rateObject.rate.isDerived;

        let processedRate = {
          weekday: rateObject.rate.weekdayRate,
          weekend: rateObject.rate.weekendRate,
          isDerived
        };

        if (isDerived) {
          const isPercentage = !!rateModifierTypeConstants.find(type => type.code === rateObject.rate.modifierType && type.isPercentage);
          const isPositive = !!rateModifierSignConstants.find(sign => sign.code === rateObject.rate.modifierSign && sign.isPositive);
          let amount = rateObject.rate.modifierAmount;
          amount = isPercentage ? generatePercentageFromDisplay(amount) : amount;

          processedRate.calculation = {
            type: rateObject.rate.modifierType,
            isPositive,
            amount
          };
        }

        return {
          roomType: room.roomType,
          rate: processedRate
        };
      }
      return { ...room };
    });
    this.setState({ roomMapping: processedRoomMapping, isAdvancedOptionsModalVisible: false });
  };

  render() {
    const { property } = this.props;
    const {
      roomTypes,
      displayCtripSyncModal,
      ctrip,
      roomMapping,
      isAdvancedOptionsModalVisible,
      rateModifierTypeConstants,
      rateModifierSignConstants
    } = this.state;
    return (
      <>
        {isAdvancedOptionsModalVisible && (
          <CtripAdvancedOptionsModal
            roomMapping={roomMapping}
            property={property}
            rateModifierTypeConstants={rateModifierTypeConstants}
            rateModifierSignConstants={rateModifierSignConstants}
            isVisible={isAdvancedOptionsModalVisible}
            onConfirm={this.handleOnAdvancedOptionsConfirm}
            onClose={this.handleOnAdvanceOptionsClick(false)}
          />
        )}
        <Row className="scroll-bar-style" style={{ marginTop: '10px' }}>
          <Row className="ota-row" type="flex" justify="space-between" style={{ width: '97.5%' }}>
            <Col>
              <label style={{ fontWeight: 'bold' }}>{`Hotel Code: `}</label>
              {`${(ctrip && ctrip.code) || '(Not Set)'}`}
            </Col>
            <Col>
              <label style={{ fontWeight: 'bold' }}>{`Ctrip Hotel ID: `}</label>
              {`${(ctrip && ctrip.foreignPropertyId) || '(Not Set)'}`}
            </Col>
          </Row>
          {roomTypes &&
            roomTypes.map(roomType => {
              const roomMap = roomMapping.find(roomMap => roomMap.roomType === roomType._id) || {};
              return (
                <Row key={roomType._id}>
                  <Card className="ota-card" title={roomType.name}>
                    <label className="booking-label"> {'Sync ' + roomType.name + ' to Ctrip'}</label>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={this.toggleToSync(roomType._id)}
                      checked={roomType.syncStatus}
                      style={{ marginLeft: '5px' }}
                    />
                    {roomType.syncStatus && (
                      <Row>
                        <OTARateInput rate={roomMap.rate} updateRates={this.updateRates(roomType._id)} />
                      </Row>
                    )}
                  </Card>
                </Row>
              );
            })}
          {!!roomTypes && roomTypes.length > 0 && (
            <Row>
              <a href=" " onClick={this.handleOnAdvanceOptionsClick(true)}>
                Advanced Options
              </a>
            </Row>
          )}
          <Row gutter={12} type="flex" justify="end" className="ota-action-button-row">
            <Col>
              <Button type="secondary" onClick={this.props.onClose}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={this.onCtripSubmit}>
                Save
              </Button>
            </Col>
          </Row>
        </Row>

        <CtripSyncModal visible={displayCtripSyncModal} onCancel={this.toggleDisplayCtripSyncModal} ctrip={ctrip} propertyData={property} />
      </>
    );
  }
}

CtripForm.propTypes = {
  data: PropTypes.object,
  property: PropTypes.object.isRequired,
  stopLoading: PropTypes.func,
  onClose: PropTypes.func
};

CtripForm.defaultProps = {
  data: {},
  property: {},
  stopLoading: () => false,
  onClose: () => {}
};

export default withRouter(CtripForm);
