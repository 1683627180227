import React, { useEffect, useMemo, useState } from 'react';

import { withAppContext } from 'context/AppContext';

import Header from './components/Header/Header';
import Body from './components/Body/Body';

import styles from './Listing.module.css';

const LISTING_TYPES_OBJECT = {
  LISTING: { value: 'listing', label: 'Listing', id: 'tab1-listing' },
  RENTAL: { value: 'rental', label: 'Rental', id: 'tab2-rental-listing' }
};

const useConstructPermittedListingTypes = (checkIsAllowViewListing, checkIsAllowViewRental) => {
  const [permittedListingTypes, setPermittedListingTypes] = useState([]);
  const [selectedListingType, setSelectedListingType] = useState('');

  const constructPermittedListingTypes = (checkIsAllowViewListing, checkIsAllowViewRental) => {
    const listingTypes = [checkIsAllowViewListing() && LISTING_TYPES_OBJECT.LISTING, checkIsAllowViewRental() && LISTING_TYPES_OBJECT.RENTAL];
    const permittedListingTypes = listingTypes.filter(data => !!data);

    setPermittedListingTypes(permittedListingTypes);
    setSelectedListingType(permittedListingTypes[0].value);
  };

  useEffect(() => {
    constructPermittedListingTypes(checkIsAllowViewListing, checkIsAllowViewRental);
  }, [checkIsAllowViewListing, checkIsAllowViewRental]);

  return { permittedListingTypes, selectedListingType, setSelectedListingType };
};

const Listing = ({ checkIsAllowViewListing, checkIsAllowViewRental, checkIsAllowEditRental }) => {
  const { permittedListingTypes, selectedListingType, setSelectedListingType } = useConstructPermittedListingTypes(
    checkIsAllowViewListing,
    checkIsAllowViewRental
  );
  const isAllowEditRental = useMemo(() => checkIsAllowEditRental(), [checkIsAllowEditRental]);

  return (
    <div>
      <div className={styles.header}>
        <Header
          permittedListingTypes={permittedListingTypes}
          setSelectedListingType={setSelectedListingType}
          selectedListingType={selectedListingType}
        />
      </div>
      <div className={styles.body}>
        <Body LISTING_TYPES_OBJECT={LISTING_TYPES_OBJECT} selectedListingType={selectedListingType} isAllowEditRental={isAllowEditRental} />
      </div>
    </div>
  );
};

export default withAppContext(Listing);
