import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';

import { OTAS } from 'utils/constants';

const getOTADetail = otaCode => {
  return OTAS.find(OTA => OTA.code === otaCode) || OTAS.find(OTA => OTA.isDefault);
};

const getOTALogo = otaCode => {
  return getOTADetail(otaCode).logo;
};

export const getOTALabel = otaCode => {
  return getOTADetail(otaCode).label;
};

const OTALogo = ({ otaCode, text, ...props }) => {
  return (
    <>
      <Avatar alt={getOTALabel(otaCode)} src={getOTALogo(otaCode)} {...props} />
      {!!text && ` ${text}`}
    </>
  );
};

OTALogo.propTypes = {
  otaCode: PropTypes.string,
  text: PropTypes.string
};

OTALogo.defaultProps = {
  otaCode: '',
  text: ''
};

export default OTALogo;
