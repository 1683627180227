import { Table, Input, Button, Icon } from 'antd';
import React, { Component } from 'react';
import './GuestBookListing.css';
import { getBookings } from '../../utils/apis/booking.js';
import { CSVLink } from 'react-csv';

class GuestBookListing extends Component {
  constructor() {
    super();
    this.state = {
      filterDropdownVisible: false,
      data: [],
      filteredData: [],
      searchText: '',
      filtered: false,
      email: '',
      fullName: '',
      contactNo: '',
      printCSVData: []
    };
  }

  onInputChange = e => {
    this.setState({ searchText: e.target.value });
  };

  onSearch = () => {
    const { searchText } = this.state;
    const reg = new RegExp(searchText, 'gi');

    //display the filtered data in table with highlight keywords
    const filteredData = this.state.data
      .map(record => {
        const match = record.fullName.match(reg);
        if (!match) {
          return null;
        }
        return {
          ...record,
          fullName: (
            <span>
              {record.fullName
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))
                .map(
                  (text, i) =>
                    text.toLowerCase() === searchText.toLowerCase() ? (
                      <span key={i} className="highlight">
                        {text}
                      </span>
                    ) : (
                      text
                    )
                )}
            </span>
          )
        };
      })
      .filter(record => !!record);

    //print csvdata after filtering
    const printCSVData = this.state.data
      .map(record => {
        const match = record.fullName.match(reg);
        if (!match) {
          return null;
        }
        return {
          ...record
        };
      })
      .filter(record => !!record);

    this.setState({
      filterDropdownVisible: false,
      filtered: !!searchText,
      filteredData,
      printCSVData
    });
  };

  componentWillMount() {
    // FIX ME: create a new endpoint in BE to get guests details only.
    // No need return all bookings back - too heavy.
    // take the guestbook from bookings that already confirmed.
    getBookings()
      .then(res => {
        if (res.status === 200) {
          const userList = this.state.data;
          for (let i = 0; i < res.data.length; i++) {
            userList.push({
              key: res.data[i]._id,
              fullName: res.data[i].guest.fullName,
              contactNo: res.data[i].guest.contactNo + '\t',
              email: res.data[i].guest.emailText
            });
          }
          this.setState({
            data: userList,
            filteredData: userList,
            printCSVData: userList
          });
          return res.data;
        }
      })
      .catch(error => {});
  }

  render() {
    const columns = [
      {
        title: 'Full Name',
        dataIndex: 'fullName',
        key: 'fullName',
        filterDropdown: (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder="Search name"
              value={this.state.searchText}
              onChange={this.onInputChange}
              onPressEnter={this.onSearch}
            />
            <Button type="primary" onClick={this.onSearch}>
              Search
            </Button>
          </div>
        ),
        filterIcon: (
          <Icon
            type="smile-o"
            style={{ color: this.state.filtered ? '#108ee9' : '#aaa' }}
          />
        ),
        filterDropdownVisible: this.state.filterDropdownVisible,
        onFilterDropdownVisibleChange: visible => {
          this.setState(
            {
              filterDropdownVisible: visible
            },
            () => this.searchInput && this.searchInput.focus()
          );
        }
      },
      {
        title: 'Contact Number',
        dataIndex: 'contactNo',
        key: 'contactNo'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
      }
    ];

    return (
      <div className="guestTable">
        <Table columns={columns} dataSource={this.state.filteredData} />
        <CSVLink data={this.state.printCSVData} filename={'Guestbook.csv'}>
          {' '}
          Download Guestbook{' '}
        </CSVLink>
      </div>
    );
  }
}

export default GuestBookListing;
