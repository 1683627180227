import { httpClient } from './../httpClient';

export const postSignUp = signUpDetails => {
  return httpClient
    .post('/auth/register', signUpDetails)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const roleCheck = role => {
  return httpClient
    .get(`/role-check?role=${role}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getForgotPasswordToken = ({ email, host }) => {
  return httpClient
    .post('/auth/forgot-password', { email, host })
    .then(res => {
      return res;
    })
    .catch(error => {
      return error;
    });
};

export const resetPassword = (token, { password }) => {
  return httpClient
    .post('/auth/reset-password/' + token, { password })
    .then(res => {
      return res;
    })
    .catch(error => {
      return error;
    });
};

// Add a function to call backend (oldpassword, password)
export const userProfileResetPassword = (oldpassword, password) => {
  return httpClient
    .post('/auth/userprofile-reset-password', { oldpassword, password })
    .then(res => {
      return res;
    })
    .catch(error => {
      return error;
    });
};

export const getCheckIsUserPasswordExpired = username => {
  return httpClient.get('/auth/check-password-expired', { params: { username } }).then(res => res.data);
};

export const postChangeExpiredPassword = (username, password) => {
  return httpClient.post('/auth/reset-expired-password', { username, password }).then(res => res.data);
};
