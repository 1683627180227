import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Avatar, Button, Modal } from 'antd';

import airbnbLogo from 'images/airbnb-logo.png';
import agodaLogo from 'images/agoda-logo-icon.png';
import expediaLogo from 'images/expedia-logo-icon.png';
import bookingcomLogo from 'images/booking-logo-icon.png';
import ctripLogo from 'images/ctrip-logo-icon.png';
import travelokaLogo from 'images/traveloka-logo-icon.png';
import tiketcomLogo from 'images/tiketcom-logo-icon.png';

import { getOTAResyncStatus, postOTAResync } from 'utils/apis/resyncOTA';

const ResyncOTAs = () => {
  const generateDescription = (roomMapping = false, rate = false, availability = false, reservation = false) => {
    return (
      <Row>
        <Col>
          <Row>
            <Col span={12}>Room Mapping</Col>
            <Col span={1}>-</Col>
            <Col
              span={1}
              style={{
                color: 'rgba(0, 0, 0, 1)'
              }}
            >
              {roomMapping ? '✅' : '⛔'}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col span={12}>Rate</Col>
            <Col span={1}>-</Col>
            <Col
              span={1}
              style={{
                color: 'rgba(0, 0, 0, 1)'
              }}
            >
              {rate ? '✅' : '⛔'}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col span={12}>Availability</Col>
            <Col span={1}>-</Col>
            <Col
              span={1}
              style={{
                color: 'rgba(0, 0, 0, 1)'
              }}
            >
              {availability ? '✅' : '⛔'}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col span={12}>Reservation</Col>
            <Col span={1}>-</Col>
            <Col
              span={1}
              style={{
                color: 'rgba(0, 0, 0, 1)'
              }}
            >
              {reservation ? '✅' : '⛔'}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const [loading, setLoading] = useState(false);
  const [buttonList, setButtonList] = useState([
    {
      title: 'Agoda + Homes',
      code: 'agoda',
      logo: agodaLogo,
      loading: true,
      description: generateDescription(false, true, true, true)
    },
    {
      title: 'Airbnb',
      code: 'airbnb',
      logo: airbnbLogo,
      loading: false,
      description: generateDescription(true, true, true, true)
    },
    {
      title: 'Bookingcom',
      code: 'bookingcom',
      logo: bookingcomLogo,
      loading: false,
      description: generateDescription(true, true, true, true)
    },
    {
      title: 'Ctrip',
      code: 'ctripcm',
      logo: ctripLogo,
      loading: false,
      description: generateDescription(false, true, true, false)
    },
    {
      title: 'Expedia',
      code: 'expedia',
      logo: expediaLogo,
      loading: false,
      description: generateDescription(true, true, true, true)
    },
    {
      title: 'Traveloka',
      code: 'traveloka',
      logo: travelokaLogo,
      loading: false,
      description: generateDescription(false, true, true, false)
    },
    {
      title: 'Tiketcom',
      code: 'tiketcom',
      logo: tiketcomLogo,
      loading: false,
      description: generateDescription(false, true, true, false)
    }
  ]);

  useEffect(() => {
    retrieveOTAStatus();
  }, []);

  const retrieveOTAStatus = async () => {
    setLoading(true);
    const data = await getOTAResyncStatus();

    const newButtonList = buttonList.map(item => {
      const newItem = { ...item };
      const OTA = data.find(ota => ota.ota === item.code);
      if (OTA) {
        newItem.loading = OTA.isBusy;
      }
      return newItem;
    });
    setButtonList(newButtonList);
    setLoading(false);
  };

  const triggerResync = async ota => {
    setLoading(true);
    const res = await postOTAResync(ota);
    console.log(res, 154);
    retrieveOTAStatus();
    setLoading(false);
    Modal.success({
      title: 'Success',
      content: 'Resync has been triggered and will process in the background. Once complete the loading will be removed.'
    });
  };

  const template = buttonList.map((item, index) => {
    return (
      <Col
        key={item.code}
        style={{
          marginBottom: 8
        }}
      >
        <Card
          actions={[
            <Button
              type="primary"
              loading={item.loading || loading}
              onClick={() => {
                Modal.confirm({
                  title: `Confirm to resync ${item.title}`,
                  onOk: () => triggerResync(item.code)
                });
              }}
            >
              Resync
            </Button>
          ]}
        >
          <div
            style={{
              backgroundColor: '#fff',
              width: 240
            }}
          >
            <Row type="flex">
              <Col span={6}>
                <Avatar size={48} src={item.logo} />
              </Col>
              <Col span={18}>
                <div>
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: 'rgba(0, 0, 0, 0.65)'
                    }}
                  >
                    {item.title}
                  </span>
                </div>
                <span
                  style={{
                    color: 'rgba(0, 0, 0, 0.45)',
                    fontSize: 12
                  }}
                >
                  {item.description}
                </span>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    );
  });

  return (
    <div
      style={{
        padding: 24
      }}
    >
      <Row type="flex" gutter={8}>
        {template}
      </Row>
    </div>
  );
};

export default ResyncOTAs;
