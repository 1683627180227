import React from 'react';
import { AppstoreOutlined, LogoutOutlined, TransactionOutlined } from '@ant-design/icons';

const useIcon = iconType => {
  if (iconType === 'inventory') {
    return <AppstoreOutlined />;
  } else if (iconType === 'logout') {
    return <LogoutOutlined />;
  } else if (iconType === 'transaction') {
    return <TransactionOutlined />;
  }
};

const MenuItemTitle = ({ iconType, title }) => {
  const icon = useIcon(iconType);

  return (
    <span>
      {icon}
      {title}
    </span>
  );
};

export default MenuItemTitle;
