import React, { Component } from 'react';
import { Form, Icon, Input, Button, Alert } from 'antd';
import intl from 'react-intl-universal';
import './ForgotPasswordForm.css';
import { getForgotPasswordToken } from '../../utils/apis/authentication';
import { withRouter } from 'react-router-dom';
const FormItem = Form.Item;

class ForgotPasswordForm extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      buttonDisabled: '',
      loading: false,
      emailCheck: ''
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      emailCheck: ''
    });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        getForgotPasswordToken({
          email: values.email,
          host: window.location.host
        })
          .then(res => {
            if (res.status === 200) {
              this.setState({
                loading: false,
                buttonDisabled: 'disabled',
                emailCheck: 'found'
              });
            } else {
              this.setState({
                loading: false,
                emailCheck: 'notFound'
              });
            }
          })
          .catch(error => {});
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem>
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: intl
                  .get('forms.required', {
                    formField: intl.get('forms.placeholders.email').d('Email')
                  })
                  .d('Please enter your Email!')
              }
            ]
          })(<Input prefix={<Icon type="user" />} placeholder={intl.get('forms.placeholders.email').d('Email')} />)}
        </FormItem>
        <Button type="primary" htmlType="submit" className="forgot-form-button" loading={this.state.loading} disabled={this.state.buttonDisabled}>
          {intl.get('forms.labels.confirm').d('Confirm')}
        </Button>
        <Button
          type="default"
          className="back-button"
          onClick={() => {
            this.props.history.push('/');
          }}
        >
          {intl.get('forms.labels.back').d('Back')}
        </Button>
        <FormItem>
          {(this.state.emailCheck === 'found' && (
            <Alert
              message={intl
                .get('forms.success.sentEmail')
                .d('Instructions to reset your password have been emailed to you. Please check your email.')}
              type="success"
              showIcon
            />
          )) ||
            (this.state.emailCheck === 'notFound' && (
              <Alert
                message={intl.get('forms.errors.emailNotFound').d('Email is not found as an existing user in the system.')}
                type="error"
                showIcon
              />
            ))}
        </FormItem>
      </Form>
    );
  }
}

export default withRouter(Form.create()(ForgotPasswordForm));
