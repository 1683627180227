import { httpClient } from './../httpClient';

export const getConstants = constantName => {
  return httpClient
    .get('/constants/' + constantName)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getConstantsNew = name => {
  return httpClient.get(`/constants/${name}`).then(response => response.data);
};

export const getPermissions = () => getConstantsNew('permissions');

export const getPropertyTypesConstant = () => getConstantsNew('propertytypes');
export const getCountriesConstant = () => getConstantsNew('countries');
export const getStatesConstant = countryIsoCode => getConstantsNew(`states${countryIsoCode}`);
export const getPropertyAmenitiesConstant = () => getConstantsNew('propertyamenities');
export const getBedTypesConstant = () => getConstantsNew('bedtypes');
export const getRoomTypeAmenitiesConstant = () => getConstantsNew('roomamenities');
export const getBookingStatusConstant = () => getConstantsNew('bookingStatus');
export const getBookingTypesConstant = () => getConstantsNew('bookingTypes');

export const getTaxTypesConstant = () => getConstantsNew('taxType');
export const getChargesTypesConstant = () => getConstantsNew('chargesType');
export const getDepositTypesConstant = () => getConstantsNew('depositType');
export const getTransactionTypesConstant = () => getConstantsNew('transactionType');
export const getPaymentMethodsConstant = () => getConstantsNew('paymentMethod');
export const getPayoutCalcMethodConstant = () => getConstantsNew('payoutCalcMethod');
export const getIntegrationSourcesConstant = () => getConstantsNew('integrationSource');
export const getBookingcomRoomTypes = () => getConstantsNew('bookingcomRoomTypes');
export const getPackageTypesConstant = () => getConstantsNew('packageTypes');
export const getRevenueShareConstant = () => getConstantsNew('revenueShare');

// --------------------------------integration------------------------------------
// bookingcom
export const getBookingComFeePolicyConstant = () => getConstantsNew('bookingcomFeePolicy');
export const getAgodaContractstatus = () => getConstantsNew('agodaContractstatus');

// --------------------------------rate modifier--------------------------------
export const getRateModifierSignConstant = () => getConstantsNew('rateModifierSign');
export const getRateModifierTypeConstant = () => getConstantsNew('rateModifierType');

// --------------------------------sync statuses--------------------------------
export const getAgodaSyncStatusConstant = () => getConstantsNew('agodaSyncStatus');
export const getAirbnbSyncStatusConstant = () => getConstantsNew('airbnbSyncStatus');
export const getBookingcomSyncStatusConstant = () => getConstantsNew('bookingcomSyncStatus');
export const getCtripSyncStatusConstant = () => getConstantsNew('ctripSyncStatus');
export const getExpediaSyncStatusConstant = () => getConstantsNew('expediaSyncStatus');
export const getCtripCMSyncStatusConstant = () => getConstantsNew('ctripCMSyncStatus');
export const getTravelokaSyncStatusConstant = () => getConstantsNew('travelokaSyncStatus');
export const getTiketcomSyncStatusConstant = () => getConstantsNew('tiketcomSyncStatus');

export const getBankConstant = () => getConstantsNew('banks');
