import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'antd';

import SingleViewLayout from './../../layouts/SingleViewLayout/SingleViewLayout';

import './403.css';
import styles from './page403.module.css';
import placeHolderImage from './403.svg';

class Forbidden403 extends Component {
  render() {
    return (
      <SingleViewLayout className={styles.youShallNotPass}>
        <Row className={styles.errorRowForException}>
          <Col span={24} md={10} className={styles.errorColumnForException}>
            <img src={placeHolderImage} alt="forbidden-page" className={styles.image} />
          </Col>
          <Col span={0} md={1} />
          <Col span={24} md={11} className={styles.errorColumnForException}>
            <div className={styles.errorColumnContent}>
              <span className={styles.errorTitle}>403 Forbidden</span>
              <span className={styles.errorSubtitle}>
                We are sorry, but you do not have access to this page or resource. Please contact the administrator.
              </span>
              <Button type="primary" size="large" style={{ width: 'auto', margin: '0 auto' }}>
                <Link to="/">Turn Back</Link>
              </Button>
            </div>
          </Col>
        </Row>
      </SingleViewLayout>
    );
  }
}

export default withRouter(Forbidden403);
