import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Tooltip, Input } from 'antd';

import styles from './PhotoDisplayCard.module.css';

const MakePrimaryAction = ({ onClick }) => (
  <Tooltip placement="top" title="Make primary photo">
    <span className={styles.actionContainer} onClick={onClick}>
      <Icon type="camera-o" />
    </span>
  </Tooltip>
);

const DeleteAction = ({ onClick }) => (
  <span className={styles.actionContainer} onClick={onClick}>
    <Icon type="delete" />
  </span>
);

const Caption = ({ value, isEdit, onStatusChange, onCaptionUpdate }) => {
  if (isEdit) {
    return <Input.TextArea autosize autoFocus onBlur={onCaptionUpdate} onPressEnter={onCaptionUpdate} defaultValue={value} />;
  }
  return (
    <div className="ant-card-meta-description" onClick={() => onStatusChange(true)}>
      {value || <em>Enter your caption here</em>}
    </div>
  );
};

class PhotoDisplayCard extends React.Component {
  constructor() {
    super();
    this.state = {
      isInEditMode: false
    };
  }

  handleOnEdit = status => {
    this.setState({
      isInEditMode: status
    });
  };

  handleOnCaptionUpdate = event => {
    const { onCaptionChange } = this.props;
    this.setState({
      isInEditMode: false
    });
    return onCaptionChange(event.target.value);
  };

  render() {
    const { isInEditMode } = this.state;
    const { imageLink, imageCaption, onMakePrimaryClick, onDelete } = this.props;
    return (
      <Card
        className={styles.photoCard}
        hoverable
        cover={
          <div className={styles.photoCardCover}>
            <img alt="example" src={imageLink} className={styles.photoCardCoverImg} />
          </div>
        }
        actions={[<DeleteAction onClick={onDelete} />]}
        bodyStyle={{ padding: 0 }}
      ></Card>
    );
  }
}

PhotoDisplayCard.propTypes = {
  imageLink: PropTypes.string.isRequired,
  imageCaption: PropTypes.string,
  onCaptionChange: PropTypes.func.isRequired,
  onMakePrimaryClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

PhotoDisplayCard.defaultProps = {
  onCaptionChange: () => {},
  onMakePrimaryClick: () => {},
  onDelete: () => {}
};

export default PhotoDisplayCard;
