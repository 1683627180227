import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button, Form, Modal, Avatar, notification, Tabs } from 'antd';

import { withAppContext } from 'context/AppContext';
import { getRoomTypeAmenitiesConstant, getBedTypesConstant } from 'utils/apis/constants';
import { putUpdateRoomType, deleteRemoveRoomType } from 'utils/apis/roomtype';
import { putUpdateRate } from 'utils/apis/rate';
import { capitalizeFirstLetter, convertAmenitiesSelectionToObj, generateDisplayFee, generateFeeFromDisplay, guard } from 'utils/general';
import { buildIOTAFormUri, buildListingUri, buildServicePackageEditUri } from 'utils/routes';

import airbnbLogo from 'images/airbnb-logo.png';
import ctripLogo from 'images/ctrip-logo.png';
import bookingcomLogo from 'images/booking-logo-icon.png';

import BasicDetailsCard from './components/BasicDetailsCard/BasicDetailsCard';
import BookingEngineConfigDetailsCard from './components/BookingEngineConfigDetailsCard/BookingEngineConfigDetailsCard';
import SetupCard from './components/SetupCard/SetupCard';
import PhotosCard from '../components/PhotosCard/PhotosCard';
import AmenitiesCard from '../components/AmenitiesCard/AmenitiesCard';
import FinePrintInfoCard from '../Unit/components/FinePrintInfoCard/FinePrintInfoCard';

import styles from './RoomType.module.css';

const TabPane = Tabs.TabPane;
class RoomType extends React.Component {
  constructor() {
    super();

    this.state = {
      hasFetchedBedTypes: false,
      bedTypesSelection: {},
      hasFetchedRoomTypeAmenities: false,
      roomTypeAmenities: {},
      isSaving: false,
      isDeleting: false
    };
  }

  componentDidMount() {
    this.fetchBedTypes();
    this.fetchRoomTypeAmenities();
  }

  fetchBedTypes = () => {
    getBedTypesConstant()
      .then(res => {
        this.setBedTypesState(res);
      })
      .catch(err => console.error(err));
  };

  fetchRoomTypeAmenities = () => {
    getRoomTypeAmenitiesConstant()
      .then(res => {
        this.setRoomTypeAmenitiesState(res);
      })
      .catch(err => console.error(err));
  };

  setBedTypesState = bedTypes => {
    this.setState({
      hasFetchedBedTypes: true,
      bedTypesSelection: bedTypes
    });
  };

  setRoomTypeAmenitiesState = roomTypeAmenities => {
    let formattedRoomTypeAmenities = {};
    Object.keys(roomTypeAmenities).forEach(key => {
      const { code, label, type, airbnb, ctrip, bookingcom } = roomTypeAmenities[key];
      let formattedLabel = (
        <div>
          {label}{' '}
          {airbnb && (
            <>
              <Avatar size={15} src={airbnbLogo} style={{ marginBottom: 3 }} />{' '}
            </>
          )}
          {bookingcom && (
            <>
              <Avatar size={15} src={bookingcomLogo} style={{ marginBottom: 3 }} />{' '}
            </>
          )}
          {ctrip && (
            <>
              <Avatar size={15} src={ctripLogo} style={{ marginBottom: 3 }} />{' '}
            </>
          )}
        </div>
      );
      if (type in formattedRoomTypeAmenities) {
        formattedRoomTypeAmenities[type]['data'].push({
          key: code,
          value: formattedLabel
        });
      } else {
        formattedRoomTypeAmenities[type] = {
          icon: this.getRoomTypeAmenitiesIcon(type),
          data: [
            {
              key: code,
              value: formattedLabel
            }
          ]
        };
      }
    });
    this.setState({
      hasFetchedRoomTypeAmenities: true,
      roomTypeAmenities: formattedRoomTypeAmenities
    });
  };

  getRoomTypeAmenitiesIcon = type => {
    const icons = {
      Accessibility: 'bell',
      Bathroom: 'skin',
      'Food and Drink': 'coffee',
      'Media Technology': 'home',
      'Outdoor and View': 'cloud-o',
      'Room Amenities': 'hdd',
      'Service and Extras': 'smile-o'
    };
    return icons[type] || 'tool';
  };

  extractBasicDetailsData = () => {
    const { data } = this.props;
    return {
      name: data.name,
      roomSizeInSqFt: data.roomSizeInSqFt,
      weekdayPrice: data.rate.weekday,
      weekendPrice: data.rate.weekend,
      adultCapacity: data.capacity.adult,
      childrenCapacity: data.capacity.children
    };
  };

  extractFinePrintInfoData = () => {
    const { data } = this.props;
    return { finePrint: data.finePrint ? data.finePrint : '' };
  };

  extractSetupData = () => {
    const { data } = this.props;
    const { bedTypesSelection } = this.state;

    return {
      bathrooms: data.bathrooms,
      livingrooms: data.livingrooms,
      bedrooms: data.bedrooms
        ? data.bedrooms.map(bedroom => {
            let totalBedCount = 0;
            const beds = Object.keys(bedTypesSelection).reduce((newBeds, key) => {
              let newBed = {
                ...bedTypesSelection[key],
                count: 0
              };
              for (let i = 0; i < bedroom.beds.length; i++) {
                const bed = bedroom.beds[i];
                if (String(bed.type) === String(bedTypesSelection[key].code)) {
                  newBed.count = bed.count;
                  totalBedCount += bed.count;
                  break;
                }
              }
              return {
                ...newBeds,
                [key]: newBed
              };
            }, {});

            return {
              ...bedroom,
              beds,
              totalBedCount
            };
          })
        : undefined
    };
  };

  extractAmenitiesData = () => {
    const { data } = this.props;
    const { roomTypeAmenities } = this.state;
    return convertAmenitiesSelectionToObj(data.amenities, roomTypeAmenities);
  };

  extractPhotosData = () => {
    const { data } = this.props;
    return {
      photos:
        data.images && data.images.length > 0
          ? data.images.map(image => ({
              link: image.imageUrl,
              caption: image.caption[0] && image.caption[0].text ? image.caption[0].text : ''
            }))
          : []
    };
  };

  extractBookingEngineConfigDetailsData = () => {
    const { data } = this.props;
    return {
      bookingEngineRoomTypeDisplayName: guard(() => data.bookingEngine.externalDisplayName),
      bookingEngineRoomTypeCleaningFee: guard(() => generateDisplayFee(data.bookingEngine.priceDetail.cleaningFee, false))
    };
  };

  formatAmenities = formFields => {
    const newFormFields = { ...formFields };
    const { roomTypeAmenities } = this.state;
    const filtteredAmenitiesFormData = Object.keys(roomTypeAmenities).reduce((formattedData, key) => {
      const newKey = String(key).toLowerCase();
      const newData = {
        ...formattedData,
        [newKey]: formFields[newKey]
      };
      // Remove key from form
      delete newFormFields[newKey];
      return newData;
    }, {});

    const formattedAmenities = Object.keys(filtteredAmenitiesFormData).reduce((newData, key) => {
      if (filtteredAmenitiesFormData[key] && Array.isArray(filtteredAmenitiesFormData[key])) {
        return [...newData, ...filtteredAmenitiesFormData[key]];
      }
      return newData;
    }, []);

    return {
      ...newFormFields,
      amenities: formattedAmenities
    };
  };

  formatImages = formFields => {
    return {
      ...formFields,
      photos: undefined,
      images:
        formFields.photos && formFields.photos.length > 0
          ? formFields.photos.map(data => ({
              imageUrl: data.link,
              caption: data.caption ? [{ text: data.caption }] : undefined
            }))
          : []
    };
  };

  formatCapacity = formFields => {
    return {
      ...formFields,
      capacity: {
        adult: formFields.adultCapacity,
        children: formFields.childrenCapacity
      },
      adult: undefined,
      children: undefined
    };
  };

  formatBedrooms = formFields => {
    return {
      ...formFields,
      bedrooms: formFields.bedrooms.map(bedroom => {
        const { beds } = bedroom;
        const newBeds = Object.keys(beds)
          .map(bedKey => {
            const bed = beds[bedKey];
            return bed.count
              ? {
                  type: bed.code,
                  count: bed.count
                }
              : undefined;
          })
          .filter(bed => !!bed); // filter bed that has count 0
        return { name: bedroom.name, beds: newBeds };
      })
    };
  };

  formatBookingEngineConfigDetails = formFields => {
    return {
      ...formFields,
      bookingEngine: {
        externalDisplayName: formFields.bookingEngineRoomTypeDisplayName,
        priceDetail: {
          cleaningFee: generateFeeFromDisplay(formFields.bookingEngineRoomTypeCleaningFee)
        }
      }
    };
  };

  removePrice = formFields => {
    return {
      ...formFields,
      weekdayPrice: undefined,
      weekendPrice: undefined
    };
  };

  generateRatePayload = rate => formFields => {
    return {
      ...rate,
      // Removing unnessary mongo fields from passing to API
      createdAt: undefined,
      createdBy: undefined,
      _v: undefined,
      _id: undefined,
      weekday: formFields.weekdayPrice,
      weekend: formFields.weekendPrice
    };
  };

  handleOnSave = () => {
    const { form, data, allowBookingEngine } = this.props;
    const roomTypePayload =
      allowBookingEngine === true
        ? [form.getFieldsValue()]
            .map(this.formatAmenities)
            .map(this.formatImages)
            .map(this.formatCapacity)
            .map(this.formatBedrooms)
            .map(this.formatBookingEngineConfigDetails)
            .map(this.removePrice)[0]
        : [form.getFieldsValue()]
            .map(this.formatAmenities)
            .map(this.formatImages)
            .map(this.formatCapacity)
            .map(this.formatBedrooms)
            .map(this.removePrice)[0];
    const ratePayload = [form.getFieldsValue()].map(this.generateRatePayload(data.rate))[0];

    this.setState({
      isSaving: true
    });

    const updateRoomTypePromise = putUpdateRoomType(data._id, roomTypePayload);
    const updateRatePromise = putUpdateRate(data.rate._id, ratePayload);
    return Promise.all([updateRoomTypePromise, updateRatePromise])
      .then(() => {
        this.setState({
          isSaving: false
        });
        notification.success({
          message: 'Your room type has been updated!'
        });
      })
      .catch(e => {
        this.setState({
          isSaving: false
        });
        notification.error({
          message: 'Something went wrong and your room type is not updated'
          // description: e.message
        });
      });
  };

  handleOnFormSubmit = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const onOkAction = this.handleOnSave;
        Modal.confirm({
          title: 'Do you want to update this room type?',
          content: 'Updating this room type will affect all units that tied to this room type.',
          onOk() {
            return onOkAction();
          },
          onCancel() {}
        });
      }
    });
  };

  onDeleteRoomType = () => {
    const { data, history } = this.props;
    this.setState({
      isDeleting: true
    });
    deleteRemoveRoomType(data._id)
      .then(() => {
        this.setState({
          isDeleting: false
        });
        notification.success({
          message: 'Your room type and linked unit(s) had been deleted'
        });
        history.push(buildListingUri());
      })
      .catch(err => {
        this.setState({
          isDeleting: false
        });
        if (err.response && err.response.status && err.response.status === 400) {
          const errorRes = err.response.data || {};
          this.roomTypeDeleteErrorMessage(errorRes);
        } else {
          //message.error('Oops! Something went wrong. Please try again later.');
        }
      });
  };

  roomTypeDeleteErrorMessage = errorRes => {
    const { unitData } = this.props;
    let isUnitInRelationshipMsg = [];
    let servicePackageNameMsg = [];
    let syncedOtasErrMsgMsg = [];
    let actionIndex = 0;
    let roomTypeOtasMsgDisplayed = false;

    console.log(errorRes);

    Modal.error({
      title: 'Unable to delete this Room Type',
      content: (
        <div>
          {errorRes.map((error, index) => {
            const isUnitInRelationship = error.isUnitInRelationship;
            const servicePackageName = error.servicePackage && error.servicePackage.name;
            const servicePackageID = error.servicePackage && error.servicePackage._id;
            const syncedOtas = error.syncedOtas;
            const roomTypeOTAs = error.roomTypeOTAs;

            if (isUnitInRelationship) {
              isUnitInRelationshipMsg.push(isUnitInRelationship);
            }
            if (servicePackageName) {
              servicePackageNameMsg.push(servicePackageName);
            }
            if (syncedOtas && syncedOtas.length > 0) {
              syncedOtasErrMsgMsg.push(syncedOtas);
            }
            if (roomTypeOTAs.length > 0) {
              syncedOtasErrMsgMsg.push(roomTypeOTAs);
            }

            const roomTypeOtasMsg = roomTypeOTAs.length !== 0 && !roomTypeOtasMsgDisplayed && (
              <p>
                This room type is synced with{' '}
                <b>
                  <a
                    href={buildIOTAFormUri({ propertyId: unitData.roomType.property._id, unitId: error.unitId, integrationType: 'hotel' })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {roomTypeOTAs
                      .map(syncedOta => capitalizeFirstLetter(syncedOta))
                      .reduce((roomTypeOtasErrMsg, source, i) => `${roomTypeOtasErrMsg}${i === roomTypeOTAs.length - 1 ? ' and ' : ', '}${source}`)}
                  </a>
                </b>{' '}
                <hr />
              </p>
            );
            //Ensure that the display message only Display ONCE !
            if (roomTypeOtasMsg) {
              roomTypeOtasMsgDisplayed = true;
            }

            const servicePackageErrMsg = servicePackageName && (
              <span>
                packaged under{' '}
                <a href={buildServicePackageEditUri(servicePackageID)} target="_blank" rel="noopener noreferrer">
                  <b>{servicePackageName}</b>
                </a>{' '}
                service package
              </span>
            );

            const syncedOtasErrMsg = !!syncedOtas && Array.isArray(syncedOtas) && syncedOtas.length > 0 && (
              <span>
                synced with{' '}
                <b>
                  <a
                    href={buildIOTAFormUri({ propertyId: unitData.roomType.property._id, unitId: error.unitId })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {syncedOtas
                      .map(syncedOta => capitalizeFirstLetter(syncedOta))
                      .reduce((syncedOtasErrMsg, source, i) => `${syncedOtasErrMsg}${i === syncedOtas.length - 1 ? ' and ' : ', '}${source}`)}
                  </a>
                </b>
              </span>
            );

            return (
              <div key={index}>
                {roomTypeOtasMsg}
                {error.unitName && (
                  <div>
                    <p>
                      {error.unitName} is {isUnitInRelationship && `having parent-child relation`}
                      {isUnitInRelationship && servicePackageName && ` and `}
                      {servicePackageErrMsg}
                      {(isUnitInRelationship || servicePackageName) && syncedOtasErrMsg && ` and `}
                      {syncedOtasErrMsg}.
                    </p>
                  </div>
                )}
              </div>
            );
          })}
          <hr />
          <p>
            To delete this Room Type, please:
            {isUnitInRelationshipMsg && isUnitInRelationshipMsg.length > 0 ? (
              <div>
                {++actionIndex}) Unlink the <b>parent-child unit(s)</b>
              </div>
            ) : null}
            {servicePackageNameMsg && servicePackageNameMsg.length > 0 ? <div>{++actionIndex}) Remove all units from the service package</div> : null}
            {syncedOtasErrMsgMsg && syncedOtasErrMsgMsg.length > 0 ? <div>{++actionIndex}) Desync all listings OTAs</div> : null}
          </p>
          <p></p>
        </div>
      )
    });
  };

  handleOnDelete = e => {
    e.preventDefault();

    const onOkAction = this.onDeleteRoomType;

    Modal.confirm({
      title: 'Are you sure you want to delete this room type?',
      content: 'Deleting this room type will also delete ALL the unit(s) that linked to this room type',
      onOk() {
        return onOkAction();
      },
      onCancel() {},
      okButtonProps: { id: 'del-cfm-button3a-editlisting' },
      cancelButtonProps: { id: 'canceldel-cfm-button3b-editlisting' }
    });
  };

  render() {
    const { form, hasFetchedUnit, checkIsAllowDeleteListing, allowBookingEngine, checkIsAdminReadOnly } = this.props;
    const { bedTypesSelection, hasFetchedRoomTypeAmenities, roomTypeAmenities, isSaving, isDeleting } = this.state;
    return (
      <div>
        <Form onSubmit={this.handleOnFormSubmit} style={{ width: '100%' }}>
          <Tabs type="card" className={styles.unitTab} defaultActiveKey="1">
            <TabPane tab="General Details" key="1" className={styles.unitTabPane} forceRender={hasFetchedUnit}>
              <BasicDetailsCard
                form={form}
                cardClassname={styles.cardContainer}
                defaultValues={this.extractBasicDetailsData()}
                currency={this.props.data.currency}
                timezone={this.props.data.timezone}
              />
              <SetupCard form={form} cardClassname={styles.cardContainer} defaultValues={this.extractSetupData()} bedTypes={bedTypesSelection} />
              <AmenitiesCard
                form={form}
                cardClassname={styles.cardContainer}
                hasFetchedAmenities={hasFetchedRoomTypeAmenities}
                amenities={roomTypeAmenities}
                defaultValues={this.extractAmenitiesData()}
              />
              <PhotosCard
                form={form}
                cardClassname={styles.cardContainer}
                captionPrefix="Room Type Image"
                defaultValues={this.extractPhotosData()}
                hasFetchedPhotos={hasFetchedUnit}
              />
            </TabPane>
            {allowBookingEngine === true && (
              <TabPane tab="Booking Website Configuration" key="2" className={styles.unitTabPane} forceRender={hasFetchedUnit}>
                <BookingEngineConfigDetailsCard
                  form={form}
                  cardClassname={styles.cardContainer}
                  defaultValues={this.extractBookingEngineConfigDetailsData()}
                />
                <FinePrintInfoCard form={form} cardClassname={styles.cardContainer} defaultValues={this.extractFinePrintInfoData()} />
              </TabPane>
            )}
          </Tabs>
          <Row type="flex" justify="start" gutter={8}>
            <Col>
              <Button
                id="save-button3-editlisting"
                type="primary"
                className={styles.marginRight}
                size="large"
                htmlType="submit"
                loading={isSaving}
                disabled={checkIsAdminReadOnly()}
              >
                {isSaving ? 'Saving' : 'Save'}
              </Button>
            </Col>
            <Col>
              {checkIsAllowDeleteListing() && (
                <Button
                  id="del-button3-editlisting"
                  type="danger"
                  size="large"
                  onClick={this.handleOnDelete}
                  loading={isDeleting}
                  disabled={checkIsAdminReadOnly()}
                >
                  Delete
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

RoomType.propTypes = {
  form: PropTypes.object.isRequired,
  checkIsAdmin: PropTypes.func,
  data: PropTypes.object,
  hasFetchedUnit: PropTypes.bool,

  allowBookingEngine: PropTypes.bool
};

RoomType.defaultProps = {
  checkIsAdmin: () => {},
  data: {},
  hasFetchedUnit: false,
  allowBookingEngine: false
};

const WrappedFormRoomType = Form.create()(RoomType);

export default withRouter(withAppContext(WrappedFormRoomType));
