import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Card, Modal, message } from 'antd';

import { getTaxTypesConstant, getChargesTypesConstant, getPaymentMethodsConstant } from 'utils/apis/constants';
import { postCreateTrxn } from 'utils/apis/transaction';
import { convertVariableNameToReadableString } from 'utils/general';

import ChargesTabPane from './components/ChargesTabPane/ChargesTabPane.js';
import PaymentTabPane from './components/PaymentTabPane/PaymentTabPane.js';
import TaxesTabPane from './components/TaxesTabPane/TaxesTabPane.js';
import styles from './TransactionTab.module.css';

import TransactionDetailsTable from '../TransactionDetailsTable/TransactionDetailsTable.js';

const TabPane = Tabs.TabPane;

const CHARGES_TYPE_TRXN = 'charges';
const PAYMENT_TYPE_TRXN = 'payment';
const TAX_TYPE_TRXN = 'tax';

export default class TransactionTab extends React.Component {
  static propTypes = {
    reservationId: PropTypes.string.isRequired,
    outstandingAmounts: PropTypes.object.isRequired,
    transactions: PropTypes.array.isRequired
  };

  static defaultProps = {
    reservationId: '',
    outstandingAmounts: {},
    transactions: [],
    currency: 'RM'
  };

  constructor() {
    super();
    this.state = {
      hasFetchedTaxTypes: false,
      hasFetchedChargesTypes: false,
      hasFetchedPaymentMethods: false,

      amountBasedOnPaymentType: 0,
      paymentMethodSelected: '',

      taxTypesSelection: [],
      chargesTypesSelection: [],
      paymentTypesSelection: [],
      paymentMethodsSelection: []
    };
  }

  componentDidMount() {
    this.fetchTaxTypes();
    this.fetchChargesTypes();
    this.fetchPaymentMethod();
  }

  fetchTaxTypes = () => {
    getTaxTypesConstant()
      .then(res => {
        this.setTaxTypesState(res);
      })
      .catch(err => console.error(err));
  };

  fetchChargesTypes = () => {
    getChargesTypesConstant()
      .then(res => {
        this.setChargesTypesState(res);
      })
      .catch(err => console.error(err));
  };

  fetchPaymentMethod = () => {
    getPaymentMethodsConstant()
      .then(res => {
        this.setPaymentMethodsState(res);
      })
      .catch(err => console.error(err));
  };

  setTaxTypesState = taxTypes => {
    const formattedTaxTypes = Object.keys(taxTypes).map(key => ({
      key: taxTypes[key].code,
      value: taxTypes[key].label
    }));

    this.setState({
      hasFetchedTaxTypes: true,
      taxTypesSelection: formattedTaxTypes
    });
  };

  setChargesTypesState = chargesTypes => {
    const formattedChargesTypes = Object.keys(chargesTypes)
      .filter(key => chargesTypes[key].code !== 'addon' && chargesTypes[key].code !== 'stripeCharges')
      .map(key => ({
        key: chargesTypes[key].code,
        value: chargesTypes[key].label
      }))
      .filter(charge => charge.key !== 'rental');

    this.setState({
      hasFetchedChargesTypes: true,
      chargesTypesSelection: formattedChargesTypes
    });
  };

  setPaymentMethodsState = paymentMethods => {
    const formattedPaymentMethods = Object.keys(paymentMethods).map(key => ({
      key: paymentMethods[key].code,
      value: paymentMethods[key].label
    }));

    this.setState({
      hasFetchedPaymentMethods: true,
      paymentMethodsSelection: formattedPaymentMethods
    });
  };

  constructPaymentTypesSelection = () => {
    const { outstandingAmounts, currency } = this.props;
    return outstandingAmounts
      ? Object.keys(outstandingAmounts).map(key => {
          let value = '';
          switch (key) {
            case 'sst':
              value = `Sales & Service Tax - ${currency} ${outstandingAmounts[key]}`;
              break;

            case 'rental':
              value = `Room Rate - ${currency} ${outstandingAmounts[key]}`;
              break;

            case 'earlyCheckIn':
              value = `Early Check-in Fee - ${currency} ${outstandingAmounts[key]}`;
              break;

            case 'lateCheckOut':
              value = `Late Check-out Fee - ${currency} ${outstandingAmounts[key]}`;
              break;

            default:
              value = `${convertVariableNameToReadableString(key)} - ${currency} ${outstandingAmounts[key]}`;
              break;
          }
          return {
            key,
            value
          };
        })
      : [];
  };

  handleOnPaymentTypeChange = value => {
    const { outstandingAmounts } = this.props;
    this.setState({
      amountBasedOnPaymentType: outstandingAmounts[value]
    });
  };

  handleOnPaymentMethodChange = value => {
    this.setState({
      paymentMethodSelected: value
    });
  };

  handleOnPaymentSubmit = (event, form) => {
    const { reservationId, currency } = this.props;
    event.stopPropagation();
    event.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      const formattedPayload = {
        date: values.paymentDate.format('YYYY-MM-DD'),
        type: PAYMENT_TYPE_TRXN,
        subType: values.paymentType,
        amount: values.amount,
        remarks: values.remarks,
        reservationId,
        payment: {
          method: values.paymentMethod,
          remarks: values.paymentRemarks || undefined,
          cardNo: values.paymentCardNo || undefined,
          expiryDate: values.paymentExpiryDate || undefined
        }
      };
      if (!err) {
        Modal.confirm({
          title: `Total payment collected: ${currency} ${formattedPayload.amount}`,
          content: `This payment is for ${convertVariableNameToReadableString(formattedPayload.subType)}.`,
          onOk() {
            postCreateTrxn(formattedPayload)
              .then(res => {
                message.success('Payment success!');
                window.location.reload();
              })
              .catch(ex => {
                console.log(ex);
              });
          },
          onCancel() {}
        });
      }
    });
  };

  handleOnChargesSubmit = (event, form) => {
    const { reservationId, currency } = this.props;
    event.stopPropagation();
    event.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      const formattedPayload = {
        date: values.chargesDate.format('YYYY-MM-DD'),
        type: CHARGES_TYPE_TRXN,
        subType: values.chargesType,
        amount: values.amount,
        remarks: values.remarks,
        reservationId
      };

      if (!err) {
        Modal.confirm({
          title: `Total amount to be charged: ${currency} ${formattedPayload.amount}`,
          content: `This charges is for ${convertVariableNameToReadableString(formattedPayload.subType)}.`,
          onOk() {
            postCreateTrxn(formattedPayload)
              .then(res => {
                message.success('Charges created success!');
                window.location.reload();
              })
              .catch(ex => {
                console.log(ex);
              });
          },
          onCancel() {}
        });
      }
    });
  };

  handleOnTaxSubmit = (event, form) => {
    const { reservationId, currency } = this.props;
    event.stopPropagation();
    event.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      const formattedPayload = {
        date: values.taxDate.format('YYYY-MM-DD'),
        type: TAX_TYPE_TRXN,
        subType: values.taxType,
        amount: values.amount,
        remarks: values.remarks,
        reservationId
      };

      if (!err) {
        Modal.confirm({
          title: `Total amount to be taxed: ${currency} ${formattedPayload.amount}`,
          content: `This tax charges is for ${convertVariableNameToReadableString(formattedPayload.subType)}.`,
          onOk() {
            postCreateTrxn(formattedPayload)
              .then(res => {
                message.success('Tax charges created success!');
                window.location.reload();
              })
              .catch(ex => {
                console.log(ex);
              });
          },
          onCancel() {}
        });
      }
    });
  };

  handleOnAllPaymentSubmit = (event, form) => {
    event.preventDefault();
    const { reservationId, outstandingAmounts } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      const paymentPromises =
        outstandingAmounts &&
        Object.keys(outstandingAmounts).map(key => {
          const formattedPayload = {
            date: values.paymentDate.format('YYYY-MM-DD'),
            type: PAYMENT_TYPE_TRXN,
            subType: key,
            amount: outstandingAmounts[key],
            reservationId,
            payment: {
              method: values.paymentMethod,
              remarks: values.paymentRemarks || undefined,
              cardNo: values.paymentCardNo || undefined,
              expiryDate: values.paymentExpiryDate || undefined
            }
          };
          return postCreateTrxn(formattedPayload);
        });
      if (!err) {
        return Promise.all(paymentPromises).then(() => {
          message.success('Payment success!');
          window.location.reload();
        });
      }
    });
  };

  getTotalAmountForPayment = () => {
    const { outstandingAmounts } = this.props;
    return (
      outstandingAmounts &&
      Object.keys(outstandingAmounts)
        .map(key => {
          return outstandingAmounts[key] > 0 ? outstandingAmounts[key] : 0;
        })
        .reduce((total, outstandingAmount) => total + outstandingAmount, 0)
    );
  };

  getPaymentList = () => {
    const { outstandingAmounts } = this.props;
    let charges = [];
    let taxes = [];
    outstandingAmounts &&
      Object.keys(outstandingAmounts).forEach(key => {
        switch (key) {
          case 'sst':
            taxes.push({
              name: 'Sales & Service Tax',
              amount: outstandingAmounts[key]
            });
            break;
          case 'tourism':
            taxes.push({
              name: 'Tourism Tax',
              amount: outstandingAmounts[key]
            });
            break;
          case 'heritage':
            taxes.push({
              name: 'Heritage Tax',
              amount: outstandingAmounts[key]
            });
            break;

          default:
            charges.push({
              name: convertVariableNameToReadableString(key),
              amount: outstandingAmounts[key]
            });

            break;
        }
      });
    return {
      charges,
      taxes
    };
  };

  render() {
    const { transactions, currency, checkIsAdminReadOnly } = this.props;
    const {
      hasFetchedTaxTypes,
      hasFetchedChargesTypes,
      taxTypesSelection,
      chargesTypesSelection,
      paymentMethodsSelection,
      amountBasedOnPaymentType,
      paymentMethodSelected
    } = this.state;
    const paymentTypesSelection = this.constructPaymentTypesSelection();

    return (
      <div>
        <Tabs defaultActiveKey="charges" tabPosition="top" type="card" className={styles.trxnTab}>
          <TabPane tab="Charges" key="charges" className={styles.trxnTabPane}>
            {hasFetchedChargesTypes && (
              <ChargesTabPane
                charges={chargesTypesSelection}
                handleOnChargesSubmit={this.handleOnChargesSubmit}
                currency={currency}
                checkIsAdminReadOnly={checkIsAdminReadOnly}
              />
            )}
          </TabPane>
          <TabPane tab="Payment" key="payment" className={styles.trxnTabPane}>
            <PaymentTabPane
              paymentTypes={paymentTypesSelection}
              paymentMethods={paymentMethodsSelection}
              handleOnPaymentTypeChange={this.handleOnPaymentTypeChange}
              handleOnPaymentMethodChange={this.handleOnPaymentMethodChange}
              amount={amountBasedOnPaymentType}
              paymentMethodSelected={paymentMethodSelected}
              handleOnPaymentSubmit={this.handleOnPaymentSubmit}
              handleOnAllPaymentSubmit={this.handleOnAllPaymentSubmit}
              getTotalAmountForPayment={this.getTotalAmountForPayment}
              getPaymentList={this.getPaymentList}
              currency={currency}
              checkIsAdminReadOnly={checkIsAdminReadOnly}
            />
          </TabPane>
          <TabPane tab="Taxes" key="taxes" className={styles.trxnTabPane}>
            {hasFetchedTaxTypes && (
              <TaxesTabPane
                taxes={taxTypesSelection}
                handleOnTaxSubmit={this.handleOnTaxSubmit}
                currency={currency}
                checkIsAdminReadOnly={checkIsAdminReadOnly}
              />
            )}
          </TabPane>
        </Tabs>
        <Card title="Transaction Details" type="inner">
          <TransactionDetailsTable transactions={transactions} currency={currency} checkIsAdminReadOnly={checkIsAdminReadOnly} />
        </Card>
      </div>
    );
  }
}
