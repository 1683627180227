import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Tooltip } from 'antd';

import styles from './FormSelection.module.css';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

const sizes = {
  default: {
    className: ''
  },
  large: {
    className: styles.large
  }
};

const selectionStyle = { width: '100%', fontSize: '14px' };

const addRules = (requiredErrorMessage, extraRules = []) => {
  const rules = [];
  if (requiredErrorMessage && typeof requiredErrorMessage === 'string') {
    rules.push({
      required: true,
      message: requiredErrorMessage
    });
  }
  return [...rules, ...extraRules];
};

/* ---------------------------------------Local Component--------------------------------------*/

const BareSelect = ({ value, selectClassName, placeholder, onChange, disabled, children, isMultiple = false }) => (
  <Select
    value={value}
    className={selectClassName}
    placeholder={placeholder}
    showSearch
    filterOption={(input, option) => option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    optionFilterProp="children"
    onChange={onChange}
    disabled={disabled}
    mode={isMultiple ? 'multiple' : 'default'}
  >
    {children}
  </Select>
);

BareSelect.propTypes = {
  selectClassName: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

BareSelect.defaultProps = {
  selectClassName: '',
  value: undefined,
  placeholder: '',
  disabled: false,
  onChange: undefined
};

/* ----------------------------------------FormSelection----------------------------------------*/
const FormSelection = ({
  allowClear,
  defaultValue,
  extraRules,
  form,
  formLabel,
  label,
  isHideLabel,
  name,
  className,
  multipleMode,
  onChange,
  onSearch,
  placeholder,
  requiredErrorMessage,
  shouldDisable,
  selections,
  filterOption,
  optionFilterProp,
  size,
  showArrow = false
}) => {
  const rules = addRules(requiredErrorMessage, extraRules);
  const searchModeProps = onSearch
    ? {
        showSearch: true,
        defaultActiveFirstOption: false,
        onSearch
      }
    : {};
  return (
    <Fragment>
      {!isHideLabel && !formLabel && <p className={styles.label}>{label || name}</p>}
      <FormItem style={{ paddingTop: '8px' }} label={formLabel} colon={false} className={className}>
        {form.getFieldDecorator(name, {
          rules: rules,
          initialValue: defaultValue
        })(
          <Select
            mode={multipleMode ? 'multiple' : 'default'}
            placeholder={placeholder}
            style={selectionStyle}
            className={sizes[size].className}
            size={size}
            disabled={shouldDisable}
            onChange={onChange}
            filterOption={filterOption}
            optionFilterProp={optionFilterProp}
            allowClear={allowClear !== undefined ? allowClear : !requiredErrorMessage}
            showArrow={showArrow}
            {...searchModeProps}
          >
            {selections.map(selection => {
              return (
                /* filtervalue is all lowercase because react complaint about not supported DOM key */
                <Option key={selection.key || selection.value} value={selection.key || selection.value} disabled={selection.disabled}>
                  {selection.displayValue || selection.label || selection.value}
                </Option>
              );
            })}
          </Select>
        )}
      </FormItem>
    </Fragment>
  );
};

FormSelection.propTypes = {
  form: PropTypes.object.isRequired,
  formLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  selections: PropTypes.array.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  extraRules: PropTypes.array,
  label: PropTypes.string,
  isHideLabel: PropTypes.bool,
  multipleMode: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  requiredErrorMessage: PropTypes.string,
  shouldDisable: PropTypes.bool,
  filterOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  optionFilterProp: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizes)),
  allowClear: PropTypes.bool
};

FormSelection.defaultProps = {
  defaultValue: undefined,
  extraRules: [],
  form: {},
  label: '',
  isHideLabel: false,
  multipleMode: false,
  onChange: undefined,
  onSearch: () => {},
  placeholder: '',
  shouldDisable: false,
  requiredErrorMessage: '',
  filterOption: true,
  optionFilterProp: 'children',
  size: Object.keys(sizes)[0],
  allowClear: undefined
};

export default FormSelection;
/* ----------------------------------------FormSelection----------------------------------------*/

/* ----------------------------------FormMultiSelectionReadOnly---------------------------------*/
class FormMultiSelectionReadOnly extends PureComponent {
  render() {
    const { label, selections, size, className } = this.props;
    return (
      <FormItem label={label} colon={false} className={className}>
        <Select
          mode="multiple"
          value={selections.map(selection => selection.key)}
          style={selectionStyle}
          className={sizes[size].className}
          size={size}
          disabled
        >
          {selections.map(selection => (
            <Option key={selection.key} value={selection.key}>
              {selection.displayValue || selection.value}
            </Option>
          ))}
        </Select>
      </FormItem>
    );
  }
}
/* ----------------------------------FormMultiSelectionReadOnly---------------------------------*/

/* ------------------------------------------MCBookingStatusSelect-----------------------------------------*/
const MCBookingStatusSelect = ({ label, selections, value, className, selectClassName, placeholder, onChange, isDisabled, isMultiple }) => {
  return (
    <div className={className}>
      {!!label && <span className={`${styles.selectionLabel}`}>{label}:</span>}
      <BareSelect
        value={value}
        selectClassName={`${styles.selectionDefaultStyle} ${selectClassName}`}
        // selectClassName={`${styles.selectionDefaultStyle} `}
        placeholder={placeholder}
        onChange={onChange}
        disabled={isDisabled}
        isMultiple={isMultiple}
      >
        {selections.map(selection => (
          <Option value={selection.value} label={selection.label} key={selection.value} disabled={selection.isDisabled}>
            <Tooltip title={`${selection.label}${selection.isDisabled ? ' is disabled' : ''}`} placement="leftTop" mouseEnterDelay={0.5}>
              <div className={`${styles.legendBoxRow}`}>
                <div className={`${styles.legendBox} ${selection.color}`} />
                {selection.label}
              </div>
            </Tooltip>
          </Option>
        ))}
      </BareSelect>
    </div>
  );
};

MCBookingStatusSelect.propTypes = {
  label: PropTypes.any,
  selections: PropTypes.array.isRequired,
  value: PropTypes.any,
  className: PropTypes.string,
  selectClassName: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isMultiple: PropTypes.bool
};

MCBookingStatusSelect.defaultProps = {
  value: undefined,
  className: '',
  selectClassName: '',
  placeholder: '',
  onChange: undefined,
  isDisabled: false
};
/* ------------------------------------------MCBookingStatusSelect-----------------------------------------*/

/* ------------------------------------------BasicSelect-----------------------------------------*/
const BasicSelect = ({ label, selections, value, className, selectClassName, placeholder, onChange, isDisabled, isMultiple }) => {
  return (
    <div className={className}>
      {!!label && <span className={`${styles.selectionLabel}`}>{label}:</span>}
      <BareSelect
        value={value}
        selectClassName={`${styles.selectionDefaultStyle} ${selectClassName}`}
        placeholder={placeholder}
        onChange={onChange}
        disabled={isDisabled}
        isMultiple={isMultiple}
      >
        {selections.map(selection => (
          <Option value={selection.value} label={selection.label} key={selection.value} disabled={selection.isDisabled}>
            <Tooltip title={`${selection.label}${selection.isDisabled ? ' is disabled' : ''}`} placement="leftTop" mouseEnterDelay={0.5}>
              {selection.label}
            </Tooltip>
          </Option>
        ))}
      </BareSelect>
    </div>
  );
};

BasicSelect.propTypes = {
  label: PropTypes.any,
  selections: PropTypes.array.isRequired,
  value: PropTypes.any,
  className: PropTypes.string,
  selectClassName: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isMultiple: PropTypes.bool
};

BasicSelect.defaultProps = {
  value: undefined,
  className: '',
  selectClassName: '',
  placeholder: '',
  onChange: undefined,
  isDisabled: false
};
/* ------------------------------------------BasicSelect-----------------------------------------*/

/* ----------------------------------------OptGroupSelect---------------------------------------*/
const OptGroupSelect = ({
  label,
  selectionGroups,
  defaultValue,
  value,
  labelClassName,
  className,
  selectClassName,
  placeholder,
  onChange,
  isDisabled
}) => {
  return (
    <div className={className}>
      <span className={`${styles.selectionLabel} ${labelClassName}`}>{label}</span>
      <BareSelect
        value={value}
        defaultValue={defaultValue}
        selectClassName={selectClassName}
        placeholder={placeholder}
        onChange={onChange}
        disabled={isDisabled}
      >
        {selectionGroups.map(selectionGroup => (
          <OptGroup label={selectionGroup.label} key={selectionGroup.value}>
            {selectionGroup.selections.map(selection => (
              <Option value={selection.value} label={selection.label} key={selection.value} disabled={selection.isDisabled}>
                <Tooltip title={selection.label} placement="leftTop" mouseEnterDelay={0.5}>
                  {selection.label}
                </Tooltip>
              </Option>
            ))}
          </OptGroup>
        ))}
      </BareSelect>
    </div>
  );
};

OptGroupSelect.propTypes = {
  label: PropTypes.any.isRequired,
  selectionGroups: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  selectClassName: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

OptGroupSelect.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  className: '',
  placeholder: '',
  onChange: undefined,
  isDisabled: false
};
/* ----------------------------------------OptGroupSelect---------------------------------------*/

export { FormMultiSelectionReadOnly, MCBookingStatusSelect, BasicSelect, OptGroupSelect };
