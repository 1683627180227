import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { withRouter, Link } from 'react-router-dom';
import { Card, Select, Col, Icon, Table, Button, Spin, Tooltip, Tag, Modal, message } from 'antd';

import { getConstants } from 'utils/apis/constants';
import { constructFullName, cleanNumber, guard, getConstantLabel } from 'utils/general';
import { calculatePaymentReceived, calculateTotalAmount, calculateTotalServiceFee } from 'utils/transaction';
import { constructColumn, constructColumnFilterSearch, constructColumnFilterCheckbox } from 'utils/table/table';
import { updateReservation } from 'utils/apis/reservation';
import { getDifferenceBetweenDate } from 'utils/date';

import OTALogo from 'components/OTALogo/OTALogo';

import styles from './ReservationList.module.css';

import { MIN_INTL_CONTACT_NO_LEN } from 'utils/constants';
import { withAppContext } from 'context/AppContext';

const { Option } = Select;
const getSumAmount = amountObj => {
  return amountObj ? Object.keys(amountObj).reduce((sum, key) => (key === 'details' ? sum : sum + parseFloat(amountObj[key] || 0)), 0) : 0;
};

class ReservationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateMYConstant: [],
      countriesConstant: [],
      taskStatusConstant: [],
      bookingStatusConstant: [],
      bookingTypeConstant: [],

      showPrice: false

      // bookingStatusOptions: []
    };
  }

  componentDidMount = async () => {
    const stateMYConstant = await getConstants('statesMY').then(resState => Object.values(resState.data));
    const countriesConstant = await getConstants('countries').then(resCountry => Object.values(resCountry.data));
    const taskStatusConstant = await getConstants('taskStatusMC').then(resTask => {
      return Object.values(resTask.data).map(data => ({ ...data, value: data.label }));
    });
    const bookingStatusConstant = await getConstants('bookingStatus').then(resBooking => {
      return Object.values(resBooking.data)
        .map(data => ({ ...data, value: data.code }))
        .filter(bs => bs.code !== 'Nebula Cancelled/ Possible Error' && bs.code !== 'Stripe payment is not completed');
    });
    const bookingTypesConstant = await getConstants('bookingTypes').then(resBooking => {
      return Object.values(resBooking.data).map(data => ({ ...data, value: data.code }));
    });

    this.setState({
      stateMYConstant: stateMYConstant,
      countriesConstant: countriesConstant,
      taskStatusConstant: taskStatusConstant,
      bookingStatusConstant: bookingStatusConstant,
      bookingTypesConstant: bookingTypesConstant,
      showPrice: this.props.checkIsAdmin() || this.props.checkIsAllowViewTransaction()
    });
  };

  getBookingStatusLabelForCSV = bookingStatusCode => {
    const { bookingStatusConstant } = this.state;
    const bookingStatusLabel = getConstantLabel(bookingStatusConstant, bookingStatusCode);
    return bookingStatusLabel || '-';
  };

  getBookingTypeLabelForCSV = (bookingTypesCode = 0) => {
    const { bookingTypesConstant } = this.state;
    const bookingTypeLabel = getConstantLabel(bookingTypesConstant, bookingTypesCode);
    return bookingTypeLabel || '-';
  };

  getPropertyAddress = (property, stateMYConstant, countriesConstant) => {
    const propertyState = stateMYConstant.find(state => state.code === property.state) || property.state;
    const propertyCountry = countriesConstant.find(country => country.iso2 === property.countryCode);

    let propertyAddressArray = [];
    propertyAddressArray.push(property.street);
    propertyAddressArray.push(property.city);
    propertyAddressArray.push(property.zipCode);
    propertyAddressArray.push(propertyState.label);
    propertyAddressArray.push(propertyCountry.name);

    return propertyAddressArray.join(', ');
  };

  getPaymentDetails = (payment = {}) => {
    let methodsUsed = [];
    let remarks = [];
    let cardNos = [];
    let expiryDates = [];
    if (payment && payment.details) {
      payment.details.forEach(detail => {
        if (!methodsUsed.includes(detail.method)) {
          methodsUsed.push(detail.method);
        }
        remarks.push(detail.remarks);
        if (!cardNos.includes(detail.cardNo)) {
          cardNos.push(detail.cardNo);
          expiryDates.push(detail.expiryDate);
        }
      });
    }
    return {
      methods: methodsUsed.length > 0 ? methodsUsed.filter(method => !!method).join(', ') : '',
      remarks: remarks.length > 0 ? remarks.filter(remark => !!remark).join('. ') : '',
      cardNos: cardNos.length > 0 ? cardNos.filter(no => !!no).join(', ') : '',
      expiryDates: expiryDates.length > 0 ? expiryDates.filter(date => !!date).join(', ') : ''
    };
  };

  getReservationListCSV = (reservationListData, stateMYConstant, countriesConstant, showPrice) => {
    let reservationListCSV = [];

    for (let i = 0; i < reservationListData.length; i++) {
      const reservation = reservationListData[i];
      const {
        bookingType,
        bookingStatus,
        code,
        createdAt,
        createdBy,
        charges,
        deposit,
        endDate,
        guestDetails,
        payment,
        priceDetails,
        remarks,
        transactionRemarks,
        startDate,
        source,
        platform,
        tax,
        addon,
        unit
      } = reservation;

      const checkInDate = startDate;
      const checkOutDate = endDate;

      const guestName = constructFullName(guestDetails.userProfile.firstName, guestDetails.userProfile.lastName, guestDetails.userProfile.middleName);
      const guestContact = guard(() => guestDetails.userProfile.contactNos[0], '-');

      const unitName = unit.name;

      const property = unit.roomType.property;
      const propertyName = property.name;
      // const propertyAddress = this.getPropertyAddress(property, stateMYConstant, countriesConstant);

      // const outstandingAmounts = getSumAmount(charges) - getSumAmount(payment);
      // const outstandingAmountsFormatted = 'RM' + numberWithCommas(parseFloat(outstandingAmounts));

      const paymentDetails = this.getPaymentDetails(payment);
      // let moreThanOneItem = (a, b) => {
      //   return a + ' ;' + (b.item ? b.item : '-');
      // };
      // let moreThanOneLabel = (a, b) => {
      //   return a + ' ;' + (b.label ? b.label : '-');
      // };
      let calculateTotalAddOnAmount = (addons = {}) => {
        const allAddOns =
          addons &&
          Object.keys(addons).map(key => {
            return addons[key] && addons[key] > 0 ? addons[key] : 0;
          });
        return allAddOns.reduce((total, amount) => total + amount, 0);
      };

      const createdByName = constructFullName(createdBy.userProfile.firstName, createdBy.userProfile.lastName, '');

      // const sourceProcessed = !!source ? source : '-';

      // const remarksProcessed = !!remarks ? remarks : '-';

      if (showPrice) {
        reservationListCSV.push({
          'Booking Type': this.getBookingTypeLabelForCSV(bookingType),
          'Host Name': unit.roomType.property.host.name || '',
          'Property Name': propertyName,
          'Room Type': unit.roomType.name,
          'Unit Name': unitName,
          'Check In': checkInDate,
          'Check Out': checkOutDate,
          'Confirmation Code': code || '',
          'Created At': createdAt || '',
          'Created By': createdByName || '',
          'Booking Source': platform || source || '',
          'Booking Status': this.getBookingStatusLabelForCSV(bookingStatus),
          'Number of Pax': guestDetails.numberOfPax || 0,
          Remarks: remarks || '',
          'Deposit Collected': (deposit && deposit.collect) || 0,
          'Deposit Refunded': (deposit && deposit.refund) || 0,
          'Room Rate': (charges && charges.rental) || 0,
          'Platform Fee': (priceDetails && priceDetails.platformFee) || 0,
          'Cleaning Fee': (charges && charges.cleaning) || 0,
          'Extra Guest Fee': (charges && charges.extraGuest) || 0,
          'Early Check-in Fee': (charges && charges.earlyCheckIn) || 0,
          'Late Check-out Fee': (charges && charges.lateCheckOut) || 0,
          'Shuttle Fee': (charges && charges.shuttle) || 0,
          'Transportation Fee': (charges && charges.transportation) || 0,
          'Breakfast Fee': (charges && charges.breakfast) || 0,
          'Lunch Fee': (charges && charges.lunch) || 0,
          'Dinner Fee': (charges && charges.dinner) || 0,
          'Other Fee': (charges && charges.other) || 0,
          'Add-ons': addon ? calculateTotalAddOnAmount(addon) : 0,
          'Total Service Fee': calculateTotalServiceFee(charges),
          'Total Price': calculateTotalAmount(charges, tax, addon),
          'Total Nights': getDifferenceBetweenDate(startDate, endDate) || '',
          'Average Price per Night': calculateTotalAmount(charges, tax, addon) / (getDifferenceBetweenDate(startDate, endDate) || 1),
          'Sales Service Tax': (tax && tax.sst) || 0,
          'Tourism Tax': (tax && tax.tourism) || 0,
          'Heritage Tax': (tax && tax.heritage) || 0,
          'First Name': guestDetails.userProfile ? guestDetails.userProfile.firstName : '-',
          'Last Name': guestDetails.userProfile ? guestDetails.userProfile.lastName : '-',
          'Guest Name': guestName,
          'Contact Number': guestContact,
          'Payment Method': paymentDetails.methods,
          'Credit Card/Debit Card No.': paymentDetails.cardNos,
          'Expiry Date': paymentDetails.expiryDates,
          'Payment Remarks': paymentDetails.remarks,
          'Transaction Remarks': transactionRemarks,
          'Payment Received': calculatePaymentReceived(payment)
        });
      } else {
        reservationListCSV.push({
          'Booking Type': this.getBookingTypeLabelForCSV(bookingType),
          'Host Name': unit.roomType.property.host.name || '',
          'Property Name': propertyName,
          'Room Type': unit.roomType.name,
          'Unit Name': unitName,
          'Check In': checkInDate,
          'Check Out': checkOutDate,
          'Total Nights': getDifferenceBetweenDate(startDate, endDate) || '',
          'Confirmation Code': code || '',
          'Created At': createdAt || '',
          'Created By': createdByName || '',
          'Booking Source': platform || source || '',
          'Booking Status': this.getBookingStatusLabelForCSV(bookingStatus),
          'Number of Pax': guestDetails.numberOfPax || 0,
          Remarks: remarks || '',
          'First Name': guestDetails.userProfile ? guestDetails.userProfile.firstName : '-',
          'Last Name': guestDetails.userProfile ? guestDetails.userProfile.lastName : '-',
          'Guest Name': guestName,
          'Contact Number': guestContact
        });
      }
    }

    // if empty data
    if (!reservationListCSV || !reservationListCSV.length) {
      reservationListCSV.push({
        'No.': 'NO DATA',
        'Check In': '-',
        'Check Out': '-',
        Name: '-',
        'H/P': '-',
        'Unit Name': '-',
        'Property Name': '-',
        'Property Address': '-',
        'Outstanding Payment': '-',
        Source: '-',
        'Remark from Reservation': '-'
      });
    }

    return reservationListCSV;
  };

  renderDownloadCSVButton = reservationList => {
    const { stateMYConstant, countriesConstant, showPrice } = this.state;

    if (!!reservationList && stateMYConstant.length > 0 && countriesConstant.length > 0) {
      return (
        <CSVLink
          target="_blank"
          filename={`${reservationList.csvName}.csv`}
          data={this.getReservationListCSV(reservationList.data, stateMYConstant, countriesConstant, showPrice)}
        >
          <Button id={reservationList.printButtonId} type="primary" icon="download" className={`${styles.cardTitleButton}`}>
            Download CSV
          </Button>
        </CSVLink>
      );
    } else {
      return <Spin className={`${styles.cardTitleButton}`} />;
    }
  };

  handleOnBookingStatusChange = (res, bookingStatus) => {
    Modal.confirm({
      title: `Are you sure you want to update booking status for booking ${res.code}?`,
      content: 'You will not be able to undo this action, but you may update it again.',
      onOk() {
        updateReservation(res._id, { bookingStatus: bookingStatus })
          .then(res => {
            if (res.status === 200) {
              message.success('Reservation updated!');
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else if (res.status === 403) {
              message.warning('Reservation cannot be updated due to dependency.');
            } else if (res.status === 400 && res.data.code === '30001') {
              message.error('Reservation is not updated due to clashing with other reservation.');
            } else {
              message.error('Something went wrong and reservation is not updated.');
            }
          })
          .catch(ex => {
            console.log(ex);
            message.error(ex);
          });
      },
      onCancel() {}
    });
  };

  renderCleaningStatus = taskStatus => {
    const status = taskStatus.status;
    const taskId = taskStatus.taskId;
    let style = { marginTop: 4 };

    switch (status) {
      case 'Ready':
        return (
          <Col style={{ ...style }}>
            <Tag style={{ ...style }} color="green">
              Room {status}
            </Tag>
          </Col>
        );

      case 'Cleaning':
        return (
          <Col style={{ ...style }}>
            <Tooltip
              title={
                <a href={`/task-management/task/${taskId}/edit`}>
                  <Icon type="link" /> View Task
                </a>
              }
            >
              <Tag style={{ ...style, color: '#FFB800', border: '1px solid #FFB800', backgroundColor: '#fffbe6' }}>Room {status}</Tag>
            </Tooltip>
          </Col>
        );

      case 'Dirty':
        return (
          <Col style={{ ...style }}>
            <Tooltip
              title={
                <a href={`/task-management/task/${taskId}/edit`}>
                  <Icon type="link" /> View Task
                </a>
              }
            >
              <Tag color="red">Room {status}</Tag>
            </Tooltip>
          </Col>
        );

      default:
        return (
          <Col style={{ ...style }}>
            <Tag>No Task Available</Tag>
          </Col>
        );
    }
  };

  constructColumns = ({ filters, sorter }) => {
    const getCodeRenderProp = () => ({
      render: (text, record) => (
        <Link to={`/reservation/${record._id}/edit`}>
          <OTALogo otaCode={record.platform} text={text} size="small" />
        </Link>
      )
    });

    const getContactNoRenderProp = () => ({
      render: text => {
        const contactNo = text || 'N/A';
        return contactNo && contactNo.length > MIN_INTL_CONTACT_NO_LEN ? (
          <Tooltip title={'Chat on WhatsApp'}>
            <a href={`https://wa.me/${cleanNumber(contactNo)}`} target="_blank" rel="noopener noreferrer">
              {contactNo}
            </a>
          </Tooltip>
        ) : (
          <>{contactNo}</>
        );
      }
    });

    return [
      {
        ...constructColumn('Confirmation Code', 'code', { hasSorter: true, sorter, width: 150, fixed: 'left' }),
        ...constructColumnFilterSearch('code', 'Search Confirmation Code', filters),
        ...getCodeRenderProp()
      },
      {
        ...constructColumn('Property Name', 'unit.roomType.property.name', { hasSorter: true, sorter, width: 150 }),
        ...constructColumnFilterCheckbox('unit.roomType.property.name', this.props.propertyOptions)
      },
      {
        ...constructColumn('Room Type', 'unit.roomType.name', { hasSorter: true, sorter, width: 150 }),
        ...constructColumnFilterSearch('unit.roomType.name', 'Search Room Type', filters)
      },
      {
        ...constructColumn('Unit Name', 'unit.name', { hasSorter: true, sorter }),
        ...constructColumnFilterSearch('unit.name', 'Search Unit Name', filters)
      },
      {
        ...constructColumn('Cleaning Status', 'taskStatus.status', { hasSorter: true, width: 150 }),
        ...constructColumnFilterCheckbox('taskStatus.status', this.state.taskStatusConstant),
        render: (text, record) => {
          return this.renderCleaningStatus(record.taskStatus);
        }
      },
      {
        ...constructColumn('Booking Status', 'bookingStatus', { hasSorter: true, width: 200 }),
        ...constructColumnFilterCheckbox('bookingStatus', this.state.bookingStatusConstant),
        // render: bookingStatus => getConstantLabel(this.state.bookingStatusConstant, bookingStatus)
        render: (bookingStatus, record) => {
          return (
            <Select
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              defaultValue={bookingStatus}
              onChange={e => this.handleOnBookingStatusChange(record, e)}
              style={{ width: 160 }}
            >
              {this.state.bookingStatusConstant
                .filter(bs => bs.code !== 'Cancelled by OTA' && bs.code !== 'Cancelled by Guest')
                .map(bkStat => (
                  <Option value={bkStat.value} key={bkStat.value}>
                    {bkStat.label}
                  </Option>
                ))}
            </Select>
          );
        }
      },
      {
        ...constructColumn('Guest Name', 'guestDetails.userProfile.guestName', { hasSorter: true, sorter }),
        ...constructColumnFilterSearch('guestDetails.userProfile.guestName', 'Search Guest Name', filters)
      },
      {
        ...constructColumn('Check-in Date', 'startDate', { hasSorter: true, sorter, width: 120 })
      },
      {
        ...constructColumn('Check-out Date', 'endDate', { hasSorter: true, sorter, width: 120 })
      },
      {
        ...constructColumn('Contact Number', 'guestDetails.userProfile.contactNos[0]'),
        ...constructColumnFilterSearch('guestDetails.userProfile.contactNos[0]', 'Search Contact Number', filters),
        ...getContactNoRenderProp()
      }
    ];
  };

  render() {
    const {
      isLoading,

      todayConstant,
      tomorrowConstant,

      reservationList,
      titleDateRange,
      dashboardStyle,
      handleOnTableDataChange,
      tableQuery
    } = this.props;

    const title = (
      <>
        {reservationList.dashboardTitle + titleDateRange}
        {this.props.checkAbleExportOverview() && this.renderDownloadCSVButton(reservationList)}
      </>
    );

    return (
      <Card title={title} className={dashboardStyle} loading={isLoading}>
        <Table
          rowKey={record => record._id}
          columns={this.constructColumns(tableQuery)}
          dataSource={reservationList.data}
          scroll={{ x: 1500 }}
          loading={isLoading}
          onChange={(pagination, filters, sorter) => handleOnTableDataChange({ pagination, filters, sorter })}
          pagination={{ pageSize: 10 }}
        />
      </Card>
    );
  }
}

export default withRouter(withAppContext(ReservationList));
