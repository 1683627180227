import React, { createRef, forwardRef, useState } from 'react';
import { Alert, Button, Card, Col, Form, Row, Tooltip, Avatar } from 'antd';
import PropTypes from 'prop-types';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';

import FormInput from 'components/FormInput/FormInput';
import { checkIsValidUrl, errorHandlerWrapper } from 'utils/general';

import styles from './HostBookingEngineConfigCard.module.css';

const FormItem = Form.Item;
const DEFAULT_PRIMARY_COLOR = '#00bcd4';
const PRESET_PRIMARY_COLORS = [
  DEFAULT_PRIMARY_COLOR,
  '#03a9f4',
  '#2196f3',
  '#3f51b5',
  '#673ab7',
  '#9c27b0',
  '#e91e63',
  '#f44336',
  '#ff5722',
  '#ff9800',
  '#ffc107',
  '#fcdc02',
  '#cddc39',
  '#8bc34a',
  '#4caf50',
  '#009688',
  '#607d8b',
  '#795548'
];

const validateValidUrl = (rule, value, callback) => {
  if (!!value && !checkIsValidUrl(value)) {
    return callback('Domain is not a valid URL.');
  }
  return callback();
};

const ColorPicker = forwardRef(({ onChange, value }, ref) => {
  const handleOnPrimaryColorSelected = selectedColor => e => {
    onChange(selectedColor);
  };

  return (
    <Row type="flex" style={{ marginBottom: 0 }} ref={ref}>
      {PRESET_PRIMARY_COLORS.map(color => (
        <div
          className={styles.colorPicker}
          style={{
            ...(color === value && { border: `3px solid ${color}`, boxShadow: `0px 2px 6px ${color}` }),
            ...(color !== value && { backgroundColor: color })
          }}
          key={color}
          onClick={handleOnPrimaryColorSelected(color)}
        />
      ))}
    </Row>
  );
});

const HostBookingEngineConfigCard = ({
  form,
  mode,
  defaultValues,
  isLoading,
  onStripeConnectClick,
  S3_URL,
  UPLOAD_OPTIONS,
  handleFinishedUploadBackground,
  checkIsAdminReadOnly
}) => {
  const [isConnectingToStripe, setIsConnectingToStripe] = useState(false);

  const handleOnStripeConnectClick = async () => {
    setIsConnectingToStripe(true);
    await errorHandlerWrapper(onStripeConnectClick());
    setIsConnectingToStripe(false);
  };

  return (
    <>
      <Card loading={isLoading} title="Theme" className={styles.container}>
        <Alert
          className={styles.notes}
          message="Tips"
          description={
            <div>
              <p className={styles.notesText}>
                Please select the following color to set as your primary color. This will affect the color design in your booking website.
              </p>
            </div>
          }
          type="info"
          showIcon
        />
        <Card>
          <Row>
            <FormItem label="Choose Primary Color">
              {form.getFieldDecorator('bookingEnginePrimaryColor', {
                initialValue: defaultValues.bookingEnginePrimaryColor || DEFAULT_PRIMARY_COLOR,
                rules: [{ required: true, message: 'Please select a primary color for booking website to set for design.' }]
              })(<ColorPicker ref={createRef()} />)}
            </FormItem>
          </Row>
        </Card>
      </Card>

      <Card loading={isLoading} title="Payment Info" className={styles.container}>
        <Row>
          <Col span={24}>
            <Tooltip title={mode === 'new' && 'Please complete Host creation before connect to Stripe'} placement="leftTop" mouseEnterDelay={0.5}>
              <Button
                type="primary"
                onClick={handleOnStripeConnectClick}
                loading={isConnectingToStripe}
                disabled={mode === 'new' || checkIsAdminReadOnly()}
              >
                {`${defaultValues.stripeConnectAccountId ? 'Update Stripe Connect Account' : 'Connect to Stripe'}`}
              </Button>
            </Tooltip>
          </Col>
        </Row>
        {!!defaultValues.stripeConnectAccountEmail && (
          <Row className={styles.connectedaccountRow}>
            <Col span={24}>{`Connected Account: ${defaultValues.stripeConnectAccountEmail}`}</Col>
          </Row>
        )}
      </Card>

      <Card loading={isLoading} title="Other Settings" className={styles.container}>
        <Row>
          <Col span={24}>
            <FormInput
              label="Website Domain"
              name="bookingEngineDomain"
              placeholder="e.g.: www.yourdomain.com"
              extraRules={[{ validator: validateValidUrl }]}
              form={form}
              defaultValue={defaultValues.bookingEngineDomain}
            />
          </Col>
        </Row>
      </Card>

      <Card loading={isLoading} title="Cover Photo (Preferable size: 970 x 250 )" className={styles.container}>
        <Row className="host-form-list-upload-logo" type="flex" gutter={24}>
          <FormItem label="Current Booking Website Cover Photo">
            <Avatar shape="square" size={400} src={defaultValues.bookingEngineBackground ? defaultValues.bookingEngineBackground : ''} />
          </FormItem>
          <FormItem label="Upload Booking Website Cover Photo">
            <DropzoneS3Uploader onFinish={handleFinishedUploadBackground} s3Url={S3_URL} maxSize={2048 * 1024 * 5} upload={UPLOAD_OPTIONS} />
          </FormItem>
        </Row>
      </Card>
    </>
  );
};

HostBookingEngineConfigCard.propTypes = {
  form: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  onStripeConnectClick: PropTypes.func.isRequired,
  handleFinishedUploadBackground: PropTypes.func.isRequired
};

HostBookingEngineConfigCard.defaultProps = {
  isLoading: false
};

export default HostBookingEngineConfigCard;
