import styled from '@emotion/styled';
import { Button, Col, Row } from 'antd';

export const InventoryContainer = styled.div`
  width: 100%;
`;

export const BodyContainer = styled.div`
  margin-top: 40px;
  padding: 20px;
  background-color: white;
  border: 1px solid #e8e8e8;
`;

export const StatisticBoxesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(7, 1fr);
  }
`;

export const StatisticBoxContainer = styled(Row)`
  padding: 40px 0;
  border: 1px solid #e8e8e8;
  background-color: white;
`;

export const StatisticBoxTitle = styled(Col)`
  font-size: 16px;
  text-align: center;
`;

export const StatisticBoxValue = styled(Col)`
  font-size: 52px;
  text-align: center;
  font-weight: bold;

  @media (min-width: 1024px) {
    font-size: 36px;
  }
`;

export const HostLink = styled(Button)`
  width: 100%;
  white-space: break-spaces;
  text-align: left;
`;
