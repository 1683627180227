import { httpClient } from './../httpClient';
import { apiErrorHandler } from './helpers';

export const pushNewAgodaListing = body =>
  httpClient
    .post(`/agoda/push-new-listing`, body)
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);

export const getAgodaContract = agoda_id =>
  httpClient
    .get(`/agoda/contract`, {
      params: {
        agoda_id: agoda_id
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);

export const signAgodaContract = (agoda_id, contractCode) =>
  httpClient
    .post(`/agoda/contract`, {
      agoda_id: agoda_id,
      contractCode
    })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);

export const getAgodaChainIDs = () =>
  httpClient
    .get(`/agoda/chain-ids`)
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);

export const createAgodaChainID = body =>
  httpClient
    .post(`/agoda/chain-id`, body)
    .then(response => {
      return response;
    })
    .catch(err => err);

export const deleteAgodaChainID = recordId =>
  httpClient
    .delete(`/agoda/chain-id/${recordId}`)
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);

export const updateAgodaChainID = body =>
  httpClient
    .put(`/agoda/chain-id`, body)
    .then(response => {
      return response;
    })
    .catch(err => err);
