module.exports = {
  REACT_APP_ENDPOINT: process.env.REACT_APP_ENDPOINT || 'http://localhost:8000/v1',
  // REACT_APP_ENDPOINT: process.env.REACT_APP_ENDPOINT || 'http://172.16.50.58:8000/v1',
  // REACT_APP_ENDPOINT: process.env.REACT_APP_ENDPOINT || 'http://172.16.50.81:8005/v1',
  // REACT_APP_ENDPOINT: process.env.REACT_APP_ENDPOINT || 'http://localhost:8000/v1',
  REACT_APP_BOOKING_ENGINE_URL: process.env.REACT_APP_BOOKING_ENGINE_URL || 'http://localhost:3002',
  REACT_APP_HOTEL_BOOKING_ENGINE_URL: process.env.REACT_APP_HOTEL_BOOKING_ENGINE_URL || 'http://localhost:3002',
  REACT_APP_IS_SHOW_TRAVELOKA: process.env.REACT_APP_IS_SHOW_TRAVELOKA,
  REACT_APP_IS_SHOW_TIKETCOM: process.env.REACT_APP_IS_SHOW_TIKETCOM,
  REACT_APP_IS_SHOW_INVENTORY: process.env.REACT_APP_IS_SHOW_INVENTORY,
  REACT_APP_IS_SHOW_PROMOTION: process.env.REACT_APP_IS_SHOW_PROMOTION,
  REACT_APP_IS_SHOW_ADDON: process.env.REACT_APP_IS_SHOW_ADDON,

  REACT_APP_IS_SHOW_BCOM_PUSH_CONTENT: process.env.REACT_APP_IS_SHOW_BCOM_PUSH_CONTENT,
  REACT_APP_IS_SHOW_BCOM_PRICING_SETTINGS: process.env.REACT_APP_IS_SHOW_BCOM_PRICING_SETTINGS
};
