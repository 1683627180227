import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import moment from 'moment';

import { useFetchConstant } from 'hooks/constants';
import { getConstantLabel, generateDisplayFee, guard } from 'utils/general';
import { buildListingWizardUri } from 'utils/routes';
import { useGetUnitListings } from 'utils/apis/unit';

import CSVDownload from 'components/CSVDownload/CSVDownload';

import styles from './Header.module.css';
import { withAppContext } from 'context/AppContext';

const handleOnClickCSVDownload = async (statesMYSelection, units, setCSVName, setCSVData) => {
  const setName = setCSVName => {
    const currentTime = moment().format('YYYY_MM_DD_HH_mm_ss');
    const csvName = `Listing_.${currentTime}.csv`;
    setCSVName(csvName);
  };

  const constructCSVData = units => {
    const constructCostObjects = operationCostObject => {
      const constructCostObject = (costName, costObject) => {
        let csvColumns = {};

        csvColumns[`${costName} (Operation Cost per day)`] = generateDisplayFee(costObject.perDay, false, '0.00');
        csvColumns[`${costName} (Operation Cost per month)`] = generateDisplayFee(costObject.perMonth, false, '0.00');
        csvColumns[`${costName} (Operation Cost per reservation)`] = generateDisplayFee(costObject.perCheckOut, false, '0.00');

        return csvColumns;
      };

      const electricityObject = !!operationCostObject ? operationCostObject.electricity || {} : {};
      const waterObject = !!operationCostObject ? operationCostObject.water || {} : {};
      const internetObject = !!operationCostObject ? operationCostObject.internet || {} : {};
      const cleaningObject = !!operationCostObject ? operationCostObject.cleaning || {} : {};
      const laundryObject = !!operationCostObject ? operationCostObject.laundry || {} : {};
      const serviceObject = !!operationCostObject ? operationCostObject.service || {} : {};
      const checkInObject = !!operationCostObject ? operationCostObject.checkIn || {} : {};
      const checkOutObject = !!operationCostObject ? operationCostObject.checkOut || {} : {};

      return {
        ...constructCostObject('Electricity', electricityObject),
        ...constructCostObject('Water', waterObject),
        ...constructCostObject('Internet', internetObject),
        ...constructCostObject('Cleaning', cleaningObject),
        ...constructCostObject('Laundry', laundryObject),
        ...constructCostObject('Service', serviceObject),
        ...constructCostObject('Check-in', checkInObject),
        ...constructCostObject('Check-out', checkOutObject)
      };
    };

    const constructDescriptionObjects = detailedDescriptionObject => {
      const sanitizeString = string => {
        let sanitizedString = '';
        if (string) {
          sanitizedString = string.replace(/(\r\n|\n|\r|\s+|\t|&nbsp;)/gm, ' ');
          sanitizedString = sanitizedString.replace(/"/g, '""');
        }
        return sanitizedString;
      };

      const constructDescriptionObject = (descriptionName, description) => {
        let csvColumn = {};
        csvColumn[`${descriptionName} (Description)`] = sanitizeString(description);

        return csvColumn;
      };

      const summary = (!!detailedDescriptionObject && detailedDescriptionObject.summary) || '-';
      const space = (!!detailedDescriptionObject && detailedDescriptionObject.space) || '-';
      const access = (!!detailedDescriptionObject && detailedDescriptionObject.access) || '-';
      const interaction = (!!detailedDescriptionObject && detailedDescriptionObject.interaction) || '-';
      const neighborhoodOverview = (!!detailedDescriptionObject && detailedDescriptionObject.neighborhoodOverview) || '-';
      const transit = (!!detailedDescriptionObject && detailedDescriptionObject.transit) || '-';
      const notes = (!!detailedDescriptionObject && detailedDescriptionObject.notes) || '-';
      const houseRules = (!!detailedDescriptionObject && detailedDescriptionObject.houseRules) || '-';

      return {
        ...constructDescriptionObject('Summary', summary),
        ...constructDescriptionObject('Space', space),
        ...constructDescriptionObject('Access', access),
        ...constructDescriptionObject('Interaction', interaction),
        ...constructDescriptionObject('Neighborhood Overview', neighborhoodOverview),
        ...constructDescriptionObject('Transit', transit),
        ...constructDescriptionObject('Notes', notes),
        ...constructDescriptionObject('HouseRules', houseRules)
      };
    };

    return units.map(unit => {
      return {
        Key: unit._id,
        'Unit Name': unit.name,
        'Property Name': guard(() => unit.roomType.property.name, '-'),
        'Room Type': guard(() => unit.roomType.name, '-'),
        Timezone: guard(() => unit.roomType.property.host.timezone, '-'),
        Longitude: guard(() => unit.roomType.property.longitude, '-'),
        Latitude: guard(() => unit.roomType.property.latitude, '-'),
        Street: guard(() => unit.roomType.property.street, '-'),
        City: guard(() => unit.roomType.property.city, '-'),
        State: guard(() => getConstantLabel(statesMYSelection, unit.roomType.property.state), '-'),
        ZipCode: guard(() => unit.roomType.property.zipCode, '-'),
        'Country Code': guard(() => unit.roomType.property.countryCode, '-'),

        Host: guard(() => unit.roomType.property.host.name, '-'),
        Owner: guard(() => `${unit.ownedBy.userProfile.firstName} ${unit.ownedBy.userProfile.lastName}`, '-'),
        'Contract Period Start Date': guard(() => unit.contractPeriod.start, '-'),
        'Contract Period End Date': guard(() => unit.contractPeriod.end, '-'),
        'Parent Unit': guard(() => unit.parent.name, '-'),
        'Parent Room Type Name': guard(() => unit.parent.roomType.name, '-'),
        'Insurance Cert No': guard(() => unit.protection.certNo, '-'),
        'Insurance Start Date': guard(() => unit.protection.insuranceStartDate, '-'),
        'Insurance End Date': guard(() => unit.protection.insuranceEndDate, '-'),
        Currency: guard(() => unit.roomType.property.host.currency, '-'),
        'Weekday Price': guard(() => generateDisplayFee(unit.rate.weekday, false, '0.00')),
        'Weekend Price': guard(() => generateDisplayFee(unit.rate.weekend, false, '0.00')),

        ...constructCostObjects(unit.operationCost),
        ...constructDescriptionObjects(unit.detailedDescription),

        'Created At': unit.createdAt
      };
    });
  };

  setName(setCSVName);
  await setCSVData(constructCSVData(units));
};

const Header = ({ onClickClearAll, checkIsAdminReadOnly, ...props }) => {
  const { selection: statesMYSelection } = useFetchConstant('statesMY');
  const { data: units, isLoading: isLoadingCSV } = useGetUnitListings();
  const [csvName, setCSVName] = useState('');
  const [csvData, setCSVData] = useState([]);

  return (
    <div className={styles.header}>
      <div>
        <Button id="create-button6-listing" type="primary" icon="plus" href={buildListingWizardUri()} disabled={checkIsAdminReadOnly()}>
          Create Listing
        </Button>
      </div>
      <div>
        <Button id="reset-button1-listing" onClick={onClickClearAll}>
          Reset All
        </Button>
        {props.checkAbleExportListing() && (
          <CSVDownload
            id={'csv-button5-listing'}
            className={styles.csvDownload}
            filename={csvName}
            data={csvData}
            onClick={() => handleOnClickCSVDownload(statesMYSelection, units, setCSVName, setCSVData)}
            isLoading={isLoadingCSV}
            checkIsAdminReadOnly={checkIsAdminReadOnly}
          />
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  onClickClearAll: PropTypes.func.isRequired
};

export default withAppContext(Header);
