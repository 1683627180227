import { httpClient } from './../httpClient';
export const getCurrencyExchangeRate = (query = '') => {
  return httpClient
    .get('/currency-exchange')
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};
