import styled from '@emotion/styled';
import { Col, Row } from 'antd';

export const BodyContainer = styled.div`
  margin-top: 40px;
  padding: 20px;
  background-color: white;
  border: 1px solid #e8e8e8;
`;

export const InventoryContainer = styled.div`
  width: 100%;
`;

export const StatBoxContainer = styled(Row)`
  padding: 40px 0;
  border: 1px solid #e8e8e8;
  background-color: white;
`;

export const StatTitle = styled(Col)`
  font-size: 16px;
  text-align: center;
`;

export const StatValue = styled(Col)`
  font-size: 50px;
  text-align: center;
  font-weight: bold;
`;
