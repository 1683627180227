import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Form, Row, Col } from 'antd';

import ListingWizardLayout from 'layouts/ListingWizardLayout/ListingWizardLayout';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

import styles from './BasicDetails.module.css';

class BasicDetails extends React.Component {
  handleOnSubmit = e => {
    e.preventDefault();
    const { form, onSave } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSave(values);
      }
    });
  };

  render() {
    const { defaultValues, form, isLastPage, onBack, percentage, title } = this.props;
    return (
      <ListingWizardLayout
        isLastPage={isLastPage}
        percentage={percentage}
        title={title}
        onBackButtonClick={onBack}
        onNextButtonClick={this.handleOnSubmit}
      >
        <Row type="flex" justify="space-between" className={styles.formTitleContainer}>
          <h2 className={styles.formTitle}>Let's get started with some basic information</h2>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={24} className={styles.formItem}>
            <Alert
              className={styles.notes}
              message="Small Tips"
              description={
                <div>
                  <p style={{ marginBottom: '0', color: '#757575' }}>
                    Give your unit a unique naming so that you can identify them easily in future.
                  </p>
                  <p style={{ marginBottom: '0', color: '#757575' }}>For example: E-11-02, A studio with contemporary design theme.</p>
                </div>
              }
              type="info"
              showIcon
            />
            <br />
            <FormInput
              label="Name your unit"
              name="name"
              placeholder="Provide a brief name of your unit or set your unit no"
              requiredErrorMessage="Please label your unit"
              form={form}
              defaultValue={defaultValues.name}
              size="large"
            />
            <FormInputNumber
              form={form}
              name={'maxFreeStay'}
              defaultValue={defaultValues.maxFreeStay || 0}
              minValue={0}
              label="Maximum Free Stay (days)"
              placeholder="Maximum free stay for this unit in days"
              size="large"
            />
          </Col>
        </Row>
      </ListingWizardLayout>
    );
  }
}

BasicDetails.propTypes = {
  form: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  percentage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  onBack: PropTypes.func,
  isLastPage: PropTypes.bool
};

BasicDetails.defaultProps = {
  defaultValues: {},
  isLastPage: false
};

const WrappedFormBasicDetails = Form.create()(BasicDetails);

export default WrappedFormBasicDetails;
