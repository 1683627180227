import React from 'react';
import { Button, Icon, Input } from 'antd';

import styles from './Table.module.css';

export const getTableColumnProps = (title, dataIndex) => ({
  title,
  dataIndex,
  key: dataIndex
});

export const getColumnFilterSearchProps = (dataIndex, placeholder, { shouldFilter = true, filtersInfo } = {}) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <FilterSearch
      dataIndex={dataIndex}
      placeholder={placeholder}
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
    />
  ),
  filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
  onFilterDropdownVisibleChange: visible => visible && setTimeout(() => document.getElementById(dataIndex).focus()),
  ...(shouldFilter && {
    onFilter: (value, record) => {
      if (dataIndex === 'host') {
        return record[dataIndex] && record[dataIndex].name.toUpperCase().includes(value.toUpperCase());
      } else {
        return record[dataIndex] && record[dataIndex].toUpperCase().includes(value.toUpperCase());
      }
    }
  }),
  ...(!!filtersInfo && { filteredValue: filtersInfo[dataIndex] || '' })
});

export const getColumnSorterProps = (dataIndex, { sorterInfo } = {}) => ({
  sorter: (nextRecord, currentRecord) => {
    const nextValue = String(nextRecord[dataIndex]).toUpperCase();
    const currentValue = String(currentRecord[dataIndex]).toUpperCase();

    return nextValue.localeCompare(currentValue);
  },
  ...(!!sorterInfo && { sortOrder: sorterInfo.columnKey === dataIndex && sorterInfo.order })
});

/*---------------------------------------Local Functions-------------------------------------*/
const FilterSearch = ({ dataIndex, placeholder, setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
  return (
    <div className={`${styles.filterDropdown}`}>
      <Input
        id={dataIndex}
        className={`${styles.filterDropdownInput}`}
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={confirm}
      />
      <Button className={`${styles.filterDropdownButtonSearch}`} type="primary" onClick={confirm}>
        Search
      </Button>
      <Button onClick={clearFilters}>Reset</Button>
    </div>
  );
};
