import React, { Component } from 'react';
import { Alert, Icon } from 'antd';

import MCModalClashingReservationWarning from './components/MCModalClashingReservationWarning/MCModalClashingReservationWarning';

import styles from './ClashingReservationWarning.module.css';

class ClashingReservationWarning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalClashingReservationWarning: false
    };
  }

  handleOnClickClashingReservationWarning = () => {
    this.setState({ showModalClashingReservationWarning: true });
  };

  handleOnCancelClashingReservationWarning = () => {
    this.setState({ showModalClashingReservationWarning: false });
  };

  render() {
    const { showModalClashingReservationWarning } = this.state;
    const { className, isAdmin, clashingReservationDetails, clashingReservationIconStyle, hostId } = this.props;

    return (
      <>
        <MCModalClashingReservationWarning
          clashingReservationIconStyle={clashingReservationIconStyle}
          showModalClashingReservationWarning={showModalClashingReservationWarning}
          clashingReservationDetails={clashingReservationDetails}
          onCancelClashingReservationWarning={this.handleOnCancelClashingReservationWarning}
          hostId={hostId}
        />

        <div className={`${styles.banner} ${className}`} onClick={this.handleOnClickClashingReservationWarning}>
          <Alert
            type="error"
            message={
              <div>
                <Icon className={clashingReservationIconStyle} type="warning" />
                There is a total of <span className={styles.totalNumber}>{clashingReservationDetails.totalNumberOfClashes} </span>
                clashing reservations
                {isAdmin && ` in the selected property`}! Click here to check where they are happening.
              </div>
            }
          />
        </div>
      </>
    );
  }
}

export default ClashingReservationWarning;
