import React from 'react';
import { Icon, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';

const OTAStatusIcon = props => {
  let { tooltip, type, icon, color } = props;
  if (!color) {
    color = type === 'ok' ? '#289D48' : type === 'pending' ? '#FFD966' : type === 'error' ? '#FF5630' : type === 'nosync' ? '#bdbdbd' : '#FF5630';
  }
  if (icon) {
    return (
      <Tooltip title={tooltip || 'Others'}>
        <Icon type={icon} theme="filled" style={{ color }} />
      </Tooltip>
    );
  } else {
    return type === 'ok' ? (
      <Tooltip title={tooltip || 'Synchronization Active'}>
        <Icon type="check-circle" theme="filled" style={{ color }} />
      </Tooltip>
    ) : type === 'pending' ? (
      <Tooltip title={tooltip || 'Pending for Review'}>
        <Icon type="sync" spin="true" />
      </Tooltip>
    ) : type === 'error' ? (
      <Tooltip title={tooltip || 'Error while Synching'}>
        <Icon type="exclamation-circle" theme="filled" style={{ color }} />
      </Tooltip>
    ) : type === 'nosync' ? (
      <Tooltip title={tooltip || 'No Synchronization'}>
        <Icon type="minus-circle" theme="filled" style={{ color }} />
      </Tooltip>
    ) : (
      <Tooltip title={tooltip || 'Unknown Status'}>
        <Icon type="question-circle" theme="filled" style={{ color }} />
      </Tooltip>
    );
  }
};

export default withRouter(OTAStatusIcon);
