import React, { Component } from 'react';
import SingleViewLayout from './../../layouts/SingleViewLayout/SingleViewLayout';
import SignUpForm from './../../components/SignUpForm/SignUpForm';
import { Card, Col, Row } from 'antd';
import intl from 'react-intl-universal';
import './SignUp.css';

class SignUp extends Component {
  render() {
    return (
      <SingleViewLayout className="signup-page">
        <Row type="flex" justify="center">
          <h1>
            {intl.getHTML('titles.welcome').d(
              <React.Fragment>
                Welcome to <strong>HostPlatform</strong>
              </React.Fragment>
            )}
          </h1>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={22} sm={18} md={14} lg={10} xl={8} xxl={6}>
            <Card className="signup-card">
              <SignUpForm />
            </Card>
          </Col>
        </Row>
      </SingleViewLayout>
    );
  }
}

export default SignUp;
