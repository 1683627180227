import React from 'react';
import { Row, Col, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import { generateEndDate, guard, numberWithCommas } from 'utils/general';

import HostProtect from './components/HostProtect/HostProtect';

import './ExpandedDetails.css';

const DATE_FORMAT = 'YYYY-MM-DD';

const getDateRange = (selectedYear, selectedMonth, startDay) => {
  const startDate = moment(`${selectedMonth + 1}-1-${selectedYear}`)
    .add(startDay - 1, 'days')
    .format(DATE_FORMAT);
  const endDate = generateEndDate(startDate, { monthNumber: 1 }, 1).format(DATE_FORMAT);

  return `${startDate} to ${endDate}`;
};

const calculateOwnerRevenue = (totalExpenses, nettProfit) => {
  return Number(totalExpenses) + Number(nettProfit);
};

const calculateTotalNetProfit = (totalRevenue, totalExpenses) => {
  return Number(totalRevenue) - Number(totalExpenses);
};

const getCurrency = payoutDetail => guard(() => payoutDetail.unit.currency, 'RM');

const ExpandedDetails = ({ year, month, payoutDetail, hasProtection, isProtectionActive, shouldSplitBySqft, calculateOtherCharges }) => {
  const dateRange = getDateRange(year, month, payoutDetail.servicePackage.startDay);
  const isOneUnit = payoutDetail.servicePackage.numberOfUnits === 1;
  return (
    <div className="medium-font marginBottom20">
      <HostProtect protection={payoutDetail.protection} hasProtection={hasProtection} isProtectionActive={isProtectionActive} />
      <Row type="flex" justify="start" className="big-font gray">
        <Col>{payoutDetail.servicePackage.name}</Col>
      </Row>
      <Row type="flex" justify="space-between">
        Date Range
        <Col>{dateRange}</Col>
      </Row>
      <Row type="flex" justify="space-between">
        Unit Size (square feet)
        <Col>
          {shouldSplitBySqft
            ? payoutDetail.servicePackage.sqFts.filter(split => split.id === payoutDetail.property.roomType)[0].sqft
            : payoutDetail.servicePackage.roomSizeInSqFt}
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        Total Units per Service Package
        <Col>{payoutDetail.servicePackage.numberOfUnits}</Col>
      </Row>
      <Row type="flex" justify="space-between">
        <div className="big-font">Total Net Revenue</div>
        <div className="big-font">
          {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.totalNetRevenue)}
        </div>
      </Row>
      <Row type="flex" justify="space-between">
        <hr />
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={7}>
          Free stay used <br />
          {payoutDetail.freeStay.used}
          <span className="gray"> night(s)</span>
        </Col>
        <Col span={9}>
          Free stay balance <br />
          {payoutDetail.freeStay.balance}
          <span className="gray"> night(s)</span>
        </Col>
      </Row>
      <hr />
      <Row type="flex" justify="space-between">
        <div className="big-font gray">Booking Summary</div>
      </Row>
      <Row type="flex" justify="space-between">
        <div className="calendarSummary">
          {payoutDetail.reservations.map(r => {
            let startDate = r.startDate.substring(5).replace('-', '/');
            let endDate = r.endDate.substring(5).replace('-', '/');
            let cName = '';
            let stripeCharges = r.charges.stripeCharges ? r.charges.stripeCharges : 0;
            let bookingFee = `${getCurrency(payoutDetail)} ` + numberWithCommas(r.charges.total + stripeCharges);
            let toolTipTitle = (
              <>
                Booking Net Price: {getCurrency(payoutDetail)} {numberWithCommas(r.charges.rental)}
                <br />
                Cleaning Paid By Guest: {getCurrency(payoutDetail)}
                {numberWithCommas(r.charges.cleaning)}
                <br />
                Extra Guest Fee: {getCurrency(payoutDetail)} {numberWithCommas(r.charges.extraGuest)}
                <br />
                Other Shared Charges: {getCurrency(payoutDetail)} {numberWithCommas(calculateOtherCharges(r.charges))}
              </>
            );
            return (
              <Row key={r.id} className={cName} type="flex">
                <Col className="float-center word-wrap" span={12}>
                  {startDate}
                  &nbsp;-&nbsp;
                  {endDate}
                </Col>
                <Col className="float-center" span={12}>
                  {r.numberOfPax} pax
                </Col>
                <Col className="float-center" span={12}>
                  {r.source || r.platform || '-'}
                </Col>
                <Tooltip overlayClassName="medium-font" placement="right" title={toolTipTitle}>
                  <Col span={12} className="float-center">
                    {bookingFee}
                  </Col>
                </Tooltip>
                <hr />
              </Row>
            );
          })}
        </div>
      </Row>
      <br />
      <Row type="flex" justify="space-between">
        <div className="bold">Total Reservations</div>
        <div className="bold">{payoutDetail.reservations.filter(b => b.status === 'Confirmed' || 'checkin' || 'checkout').length}</div>
      </Row>
      <Row type="flex" justify="space-between">
        <div className="bold">Booking Summary Total</div>
        <div className="bold">
          {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.totalNetRevenue)}
        </div>
      </Row>
      <hr />
      {payoutDetail.expenses && payoutDetail.expenses.length > 0 ? (
        <div>
          <Row type="flex" justify="space-between">
            <Col className="gray big-font">{isOneUnit ? `Expenses` : `Expenses of ${payoutDetail.servicePackage.numberOfUnits} Units`}</Col>
          </Row>
          {payoutDetail.expenses.map(expense => (
            <Row key={expense.name} type="flex" justify="space-between">
              {expense.name}
              <Col>
                {getCurrency(payoutDetail)} {numberWithCommas(expense.amount)}
              </Col>
            </Row>
          ))}
          <Row type="flex" justify="space-between">
            <Col className="bold">Total Expenses</Col>
            <Col className="bold">
              <Col>
                {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.totalExpenses)}
              </Col>
            </Col>
          </Row>
          {!payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) && (
            <Row type="flex" justify="space-between" className="small-font gray">
              Borne by Host:
              <Col>
                {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.borneExpenses.host)}
              </Col>
            </Row>
          )}
          {!payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) && (
            <Row type="flex" justify="space-between" className="small-font gray">
              Borne by Owner:
              <Col>
                {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.borneExpenses.owner)}
              </Col>
            </Row>
          )}
        </div>
      ) : (
        ''
      )}
      <hr />
      <Row className="big-font bold" type="flex" justify="space-between">
        Total Net Revenue
        <Col>
          {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.totalNetRevenue)}
        </Col>
      </Row>
      {payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) ? (
        <Row className="big-font bold" type="flex" justify="space-between">
          Total Net Profit
          <Col>
            {getCurrency(payoutDetail)} {numberWithCommas(calculateTotalNetProfit(payoutDetail.totalRevenue, payoutDetail.borneExpenses.shared))}
          </Col>
        </Row>
      ) : (
        <Row className="big-font bold" type="flex" justify="space-between">
          Owner's Revenue
          <Col>
            {getCurrency(payoutDetail)} {numberWithCommas(calculateOwnerRevenue(payoutDetail.borneExpenses.owner, payoutDetail.nettOwnersProfit))}
          </Col>
        </Row>
      )}
      {!payoutDetail.servicePackage.calculations.find(val => val.threshold && val.threshold.type === 4) && (
        <Row type="flex" justify="space-between">
          <Col className="red">Owner Borne Expenses</Col>
          <Col className="red">
            {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.borneExpenses.owner)}
          </Col>
        </Row>
      )}
      <Row className="big-font" type="flex" justify="space-between">
        <Col>Owner's Net Profit</Col>
        <Col className="bold">
          {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.nettOwnersProfit)}
        </Col>
      </Row>
      {!isOneUnit && (
        <Row className="big-font" type="flex" justify="space-between">
          <Col>Per Unit Net Revenue</Col>
          <Col className="bold">
            {getCurrency(payoutDetail)} {numberWithCommas(payoutDetail.perUnitRevenue)}
          </Col>
        </Row>
      )}
    </div>
  );
};

ExpandedDetails.propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  payoutDetail: PropTypes.object.isRequired,
  hasProtection: PropTypes.bool,
  isProtectionActive: PropTypes.bool,
  shouldSplitBySqft: PropTypes.bool.isRequired,
  calculateOtherCharges: PropTypes.func.isRequired
};

ExpandedDetails.defaultProps = {
  hasProtection: false,
  isProtectionActive: false
};

export default ExpandedDetails;
