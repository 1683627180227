import axios from 'axios';
import { REACT_APP_ENDPOINT } from 'config/env';

const FIELDS_SEPERATOR = ',';

/* =========================================== local function =========================================== */
const initiateHTTPClient = () => {
  const httpClient = axios.create({
    baseURL: REACT_APP_ENDPOINT,
    timeout: 300000,
    ...getAuthHeader()
  });

  httpClient.interceptors.request.use(req => {
    mutateParamsArrayToString(req.params);
    return req;
  });

  return httpClient;
};

const mutateParamsArrayToString = params => {
  if (!!params) {
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const valueInString = value.join(FIELDS_SEPERATOR);
        params[key] = valueInString;
      }
    });
  }
};

/* =========================================== exported function =========================================== */
export let httpClient = initiateHTTPClient();

export const postLogin = credentials => {
  return httpClient
    .post('/auth/login', credentials)
    .then(response => {
      localStorage.setItem('auth', JSON.stringify(response.data));
      httpClient = initiateHTTPClient();

      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export function getAuthHeader() {
  if (localStorage.getItem('auth') && localStorage.getItem('auth') !== 'undefined') {
    return {
      headers: {
        Authorization: JSON.parse(localStorage.getItem('auth')).token
      }
    };
  }
}
