import { httpClient } from '../httpClient';
import { apiErrorHandler } from './helpers';

export const getRoomTypesByProperty = (propertyId, startDate, endDate, otas = null) => {
  if (otas && otas.length > 0) {
    otas = otas.join();
  }
  return httpClient
    .get(`/property/${propertyId}/rates`, {
      params: {
        startDate,
        endDate,
        otas
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const getUnitsByRoomType = (roomTypeId, startDate, endDate, otas = null, page, perPage) => {
  if (otas && otas.length > 0) {
    otas = otas.join();
  }
  return httpClient
    .get(`/roomType/${roomTypeId}/rates`, {
      params: {
        startDate,
        endDate,
        otas,
        page,
        perPage
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const getClashingReservationDetails = propertyId => {
  return httpClient
    .get(`/reservation/clashingReservations`, { params: { propertyId } })
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const updateCalendarBulk = payload => {
  return httpClient
    .post('/calendarRate/bulkUpdate', payload)
    .then(response => {
      return response.data;
    })
    .catch(apiErrorHandler);
};

export const updateUnitCalendarRateBulk = (unitId, payload) =>
  httpClient
    .patch(`/unit/${unitId}/calendarRateBulk`, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const updateRoomTypeCalendarRateBulk = (roomTypeId, payload) =>
  httpClient
    .patch(`/roomtype/${roomTypeId}/calendarRateBulk`, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);
