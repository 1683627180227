import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import intl from 'react-intl-universal';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import { Input, Form, Select, Card, Icon, Row, Button, message, Avatar } from 'antd';

import { REACT_APP_ENDPOINT } from 'config/env';

import { getConstants } from 'utils/apis/constants';
import { getUserProfileById, updateUserProfile } from 'utils/apis/user';

import CloseButton from 'components/CloseButton/CloseButton';

const FormItem = Form.Item;
const { Option } = Select;

class UserProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      genderOptions: [],
      nationality: '',
      imageUrl: ''
    };

    this.changeGender = this.changeGender.bind(this);
    this.changeNationality = this.changeNationality.bind(this);
  }

  async componentWillMount() {
    // Retrieve all dropdown list options
    let [countryRes, genderRes] = await Promise.all([getConstants('countries'), getConstants('gender')]);
    let countryOptions = [];
    let genderOptions = [];

    // Assign drop down list options
    if (countryRes && countryRes.status === 200) {
      countryOptions = Object.keys(countryRes.data).map(k => {
        return {
          label: countryRes.data[k].name,
          value: countryRes.data[k].iso2
        };
      });
    }

    if (genderRes && genderRes.status === 200) {
      genderOptions = Object.keys(genderRes.data).map(k => {
        return {
          label: k.charAt(0).toUpperCase() + k.substring(1).toLowerCase(),
          value: genderRes.data[k]
        };
      });
    }

    this.setState({
      countryOptions,
      genderOptions
    });

    if (this.props.match.params.id) {
      getUserProfileById(this.props.match.params.id).then(userRes => {
        if (userRes && userRes.status === 200 && userRes.data) {
          let user = userRes.data;

          this.props.form.setFields({
            username: {
              value: user.username
            },
            email: {
              value: user.email
            },
            firstName: {
              value: user.userProfile.firstName
            },
            lastName: {
              value: user.userProfile.lastName
            },
            contactNos: {
              value: user.userProfile.contactNos
            },
            gender: {
              value: user.userProfile.gender
            },
            identificationNo: {
              value: user.userProfile.identificationNo
            },
            nationality: {
              value: user.userProfile.nationality
            }
          });

          this.setState({
            imageUrl: user.userProfile ? (user.userProfile.displayPicture ? user.userProfile.displayPicture.imageUrl : '') : '',
            userData: user
          });
        } else {
          message.error('Error retrieving user');
        }
      });
    }
  }

  changeNationality(e) {
    this.setState({
      nationality: e
    });
  }

  changeGender = gender => {
    this.setState({
      gender
    });
  };

  handleFinishedUpload = info => {
    this.setState({
      imageUrl: info.fileUrl
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const history = this.props.history;
        const userId = this.props.match.params.id;
        const userProfileId = this.state.userData.userProfile._id;
        const imageUrl = this.state.imageUrl;

        //Check for image file type
        const img_extension = imageUrl.substr(imageUrl.lastIndexOf('.') + 1).toLowerCase();
        if (!imageUrl || (imageUrl && (img_extension === 'png' || img_extension === 'jpg' || img_extension === 'jpeg' || img_extension === 'webp'))) {
          values.displayPicture = { imageUrl: imageUrl };

          updateUserProfile(userProfileId, values).then(resUser => {
            if (resUser.status === 200) {
              history.push(`/userProfile/${userId}`);
              window.location.reload();
              message.success('User Profile updated!');
            } else {
              message.error('Something went wrong and user profile is not updated.');
            }
          });
        } else {
          message.error('Please upload image file type only!');
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const uploadOptions = {
      server: REACT_APP_ENDPOINT,
      signingUrlQueryParams: { uploadType: 'avatar' }
    };
    const s3Url = `https://${process.env.REACT_APP_S3_IMAGES_BUCKET || 'nebula-local-images'}.s3.amazonaws.com/`;

    return (
      <Card className="users-form-card">
        <CloseButton
          onClick={() => {
            this.props.history.push('./users');
          }}
        />
        <Form onSubmit={this.handleSubmit} layout="vertical">
          <Row type="flex" justify="left" gutter={36}>
            <FormItem label="Username" className="users-form-list">
              {getFieldDecorator('username', {})(
                <Input prefix={<Icon type="user" />} placeholder={intl.get('forms.placeholders.username').d('Username')} disabled />
              )}
            </FormItem>
            <FormItem label="E-mail" className="users-form-list">
              {getFieldDecorator('email', {})(
                <Input prefix={<Icon type="mail" />} placeholder={intl.get('forms.placeholders.email').d('Email')} disabled />
              )}
            </FormItem>
            <FormItem label="First Name" className="users-form-list">
              {getFieldDecorator('firstName', {
                rules: [
                  {
                    required: true,
                    message: intl
                      .get('forms.required', {
                        formField: intl.get('forms.placeholders.firstName').d('First Name')
                      })
                      .d('Please enter your first name!')
                  }
                ]
              })(<Input prefix={<Icon type="user" />} placeholder={intl.get('forms.placeholders.firstName').d('First Name')} />)}
            </FormItem>
            <FormItem label="Last Name" className="users-form-list">
              {getFieldDecorator('lastName', {
                rules: [
                  {
                    required: true,
                    message: intl
                      .get('forms.required', {
                        formField: intl.get('forms.placeholders.lastName').d('Last Name')
                      })
                      .d('Please enter your last name!')
                  }
                ]
              })(<Input prefix={<Icon type="user" />} placeholder={intl.get('forms.placeholders.lastName').d('Last Name')} />)}
            </FormItem>
            <FormItem label="Contact Number" className="users-form-list">
              {getFieldDecorator('contactNos', {
                rules: [
                  {
                    required: true,
                    message: intl
                      .get('forms.required', {
                        formField: intl.get('forms.placeholders.contactNo').d('Contact Number')
                      })
                      .d('Please enter your contact number.')
                  }
                ]
              })(<Input prefix={<Icon type="phone" />} type="tel" placeholder={intl.get('forms.placeholders.contactNo').d('Contact Number')} />)}
            </FormItem>
            {/* <FormItem label="Gender" className="users-form-list">
              {getFieldDecorator('gender', {
                rules: [{ required: true, message: 'Please select your gender!' }]
              })(
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select the User's gender"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.changeGender}
                >
                  {this.state.genderOptions.map(genderOption => {
                    return (
                      <Option key={genderOption.value} value={genderOption.value}>
                        {genderOption.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem> */}
            <FormItem label="NRIC/Passport Number" className="users-form-list">
              {getFieldDecorator('identificationNo', {})(
                <Input prefix={<Icon type="idcard" />} placeholder={intl.get('forms.placeholders.icNo').d('NRIC/Passport Number')} />
              )}
            </FormItem>
            <FormItem label="Nationality" className="users-form-list">
              {getFieldDecorator('nationality', {
                validateTrigger: ['onChange', 'onBlur']
              })(
                <Select
                  showSearch
                  placeholder="Select a country"
                  optionFilterProp="children"
                  onChange={this.changeNationality}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {this.state.countryOptions &&
                    this.state.countryOptions.map(countryOptions => (
                      <Option value={countryOptions.value} key={countryOptions.value}>
                        {countryOptions.label}
                      </Option>
                    ))}
                </Select>
              )}
            </FormItem>
            <FormItem className="users-form-list"></FormItem> {/* This is for empty placement so that the profile picture is placed correctly*/}
            <FormItem label="Current User Profile Image">
              <Avatar shape="square" size={200} src={this.state.imageUrl} />
            </FormItem>
            <FormItem label="Upload User Profile Image">
              <DropzoneS3Uploader onFinish={this.handleFinishedUpload} s3Url={s3Url} maxSize={1024 * 1024 * 5} upload={uploadOptions} />
            </FormItem>
            <FormItem className="users-button-wrapper">
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </FormItem>
          </Row>
        </Form>
      </Card>
    );
  }
}
export default withRouter(Form.create()(UserProfileForm));
