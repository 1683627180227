import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import MCModalRateDayAction from '../MCModalRateDayAction/MCModalRateDayAction';
import MCRatePerDay from '../MCRatePerDay/MCRatePerDay';

import { checkIsOverlapped } from 'utils/general';

import styles from './MCRate.module.css';

const WEEKENDS = [5, 6];
const DATE_FORMAT = 'YYYY-MM-DD';
const MIN_DATE = '01-01-0001';

const checkIsInRange = (currentDate, date1, date2) => {
  const startDate = date1 < date2 ? date1 : date2;
  const endDate = date1 < date2 ? date2 : date1;

  return startDate <= currentDate && currentDate <= endDate;
};

// month structure ('YYYY-MM')
const constructDateStringFromMonthAndDay = (month, day) => {
  return moment(month)
    .add(day - 1, 'days')
    .format(DATE_FORMAT);
};

const preProcessReservationEndDate = reservationEndDate => {
  return moment(reservationEndDate)
    .subtract(1, 'days')
    .format(DATE_FORMAT);
};

class MCRate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStartDateClicked: false,
      clickedStartDate: MIN_DATE,
      clickedEndDate: MIN_DATE,
      currentHoverDate: MIN_DATE,
      isClashedReservationOnHightlight: false,

      showModalRateDayAction: false,
      showModalSetPrice: false
    };
  }

  // generate an Array of Inventory+Rates for every day in the month
  getFormattedDisplayDataPerDay = (inventories, rates, calendarRates, month, unitReservations) => {
    const formattedDisplayDataPerDay = [];
    // Inventory per day
    const formattedInventories = this.massageInventories(inventories, month);
    // Reservation and clashed Reservation counter per day
    const { formattedReservations, reservationCounters } = this.massageReservations(unitReservations, month);
    // Rates per day
    const formattedRates = this.massageRates(rates, calendarRates, month);

    const daysInMonth = moment(month).daysInMonth();
    for (let idx = 0; idx < daysInMonth; idx++) {
      formattedDisplayDataPerDay.push({
        inventory: formattedInventories[idx],
        rates: formattedRates[idx],
        reservation: formattedReservations[idx],
        reservationCounter: reservationCounters[idx]
      });
    }
    return formattedDisplayDataPerDay;
  };

  massageInventories = (inventories, month) => {
    const daysInMonth = moment(month).daysInMonth();
    const formattedInventories = [];
    let inventoriesCounter = 0;
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = constructDateStringFromMonthAndDay(month, day);
      // Inventory per day
      if (inventories.length > inventoriesCounter) {
        const { startDate, endDate, total, availability } = inventories[inventoriesCounter];
        const inventory = checkIsInRange(currentDate, startDate, endDate) ? `${availability} | ${total}` : '';
        if (currentDate === endDate) {
          inventoriesCounter++;
        }
        formattedInventories.push(inventory);
      }
    }
    return formattedInventories;
  };

  massageReservations = (unitReservations, month) => {
    const daysInMonth = moment(month).daysInMonth();
    const formattedReservations = [];
    const reservationCounters = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = constructDateStringFromMonthAndDay(month, day);

      const currentReservations = unitReservations.filter(reservation =>
        checkIsInRange(currentDate, reservation.startDate, preProcessReservationEndDate(reservation.endDate))
      );
      const processedReservations = currentReservations.filter(reservation => day === 1 || reservation.startDate === currentDate);

      formattedReservations.push(processedReservations);

      reservationCounters.push(currentReservations.length);
    }
    return { formattedReservations, reservationCounters };
  };

  massageRates = (rates, calendarRates, month) => {
    const formattedRates = [];
    const daysInMonth = moment(month).daysInMonth();
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = constructDateStringFromMonthAndDay(month, day);

      const currentCalendarRates = calendarRates[currentDate] || [];

      // compile a list of Rates for current day
      const currentRates = Object.values(rates).map(rate => {
        const massagedRate = rate[0] || {};
        // set current Rate based on weekend or weekday
        let currentRate = WEEKENDS.includes(moment(currentDate).day()) ? massagedRate.weekend : massagedRate.weekday;
        const massagedCalendarRate = currentCalendarRates.find(calendarRate => calendarRate.rate === massagedRate._id);

        // if there is CalendarRate on current Date AND is matching current Rate, replace the rate to it
        currentRate = massagedCalendarRate && massagedRate._id === massagedCalendarRate.rate ? massagedCalendarRate.amount : currentRate;

        // push currentRate, either CalendarRate, weekendRate or weekdayRate to an Array
        return currentRate;
      });
      formattedRates.push(currentRates);
    }
    return formattedRates;
  };

  getBackgroundColor = (roomTypeUnitId, clickedRateDayRoomTypeUnitId, date, reservationCounter) => {
    const { isStartDateClicked, clickedStartDate, clickedEndDate, currentHoverDate, isClashedReservationOnHightlight } = this.state;

    let backgroundColor = '';

    const endDate = currentHoverDate > MIN_DATE ? currentHoverDate : clickedEndDate;
    const today = moment().format(DATE_FORMAT);
    const isPastDate = date < today;

    const isHighlightedOnHover =
      isStartDateClicked && roomTypeUnitId === clickedRateDayRoomTypeUnitId && checkIsInRange(date, clickedStartDate, endDate);

    if (isHighlightedOnHover) {
      backgroundColor = isClashedReservationOnHightlight ? styles.hoveredHighlightClashed : styles.hoveredHighlight;
    } else if (reservationCounter > 1) {
      backgroundColor = styles.rateDayClasedReservation;
    } else {
      backgroundColor = isPastDate ? styles.rateDayDefaultBackgroundColorPast : styles.rateDayDefaultBackgroundColor;
    }

    return backgroundColor;
  };

  setIsClashedReservation = (startDate, endDate) => {
    const { unitReservations } = this.props;

    const isClashed =
      unitReservations.filter(reservation => {
        const reservationEndDate = preProcessReservationEndDate(reservation.endDate);

        return checkIsOverlapped(startDate, endDate, reservation.startDate, reservationEndDate);
      }).length > 0;

    this.setState({ isClashedReservationOnHightlight: isClashed });
  };

  handleOnClickRateDay = (roomTypeUnitId, clickedRateDayRoomTypeUnitId, isAnyRateDayStartDateClicked, date) => {
    const { isStartDateClicked, clickedStartDate, clickedEndDate, currentHoverDate, showModalRateDayAction, showModalSetPrice } = this.state;
    const { handleOnClickRateDay } = this.props;
    let clickedDates = { isStartDateClicked, clickedStartDate, clickedEndDate, currentHoverDate, showModalRateDayAction, showModalSetPrice };

    const isClickingOnSameRoomTypeUnit = clickedRateDayRoomTypeUnitId === roomTypeUnitId;
    clickedDates.isStartDateClicked = !isAnyRateDayStartDateClicked || (!isStartDateClicked && isClickingOnSameRoomTypeUnit);

    if (clickedDates.isStartDateClicked) {
      clickedDates.clickedStartDate = date;
      clickedDates.clickedEndDate = date;
      clickedDates.currentHoverDate = date;
    } else {
      clickedDates.clickedStartDate = moment.min(moment(clickedStartDate), moment(date)).format(DATE_FORMAT);
      clickedDates.clickedEndDate = moment.max(moment(clickedStartDate), moment(date)).format(DATE_FORMAT);

      if (!isClickingOnSameRoomTypeUnit) {
        clickedDates.clickedStartDate = MIN_DATE;
        clickedDates.clickedEndDate = MIN_DATE;
      } else {
        clickedDates.showModalRateDayAction = true;
      }

      clickedDates.currentHoverDate = clickedDates.clickedEndDate;
    }

    this.setState({
      isStartDateClicked: clickedDates.isStartDateClicked || clickedDates.showModalRateDayAction,
      clickedStartDate: clickedDates.clickedStartDate,
      clickedEndDate: clickedDates.clickedEndDate,
      currentHoverDate: clickedDates.currentHoverDate,
      showModalRateDayAction: clickedDates.showModalRateDayAction
    });

    handleOnClickRateDay(roomTypeUnitId, clickedDates.isStartDateClicked);
  };

  handleOnHoverRateDay = date => {
    const { isStartDateClicked, clickedStartDate } = this.state;

    if (isStartDateClicked) {
      this.setState({
        currentHoverDate: date
      });

      this.setIsClashedReservation(clickedStartDate, date);
    }
  };

  handleOnModalRateDayActionCancel = () => {
    this.setState({
      showModalRateDayAction: false,
      isClashedReservationOnHightlight: false,

      isStartDateClicked: false,
      clickedStartDate: MIN_DATE,
      clickedEndDate: MIN_DATE,
      currentHoverDate: MIN_DATE
    });
  };

  render() {
    const {
      clickedStartDate,
      clickedEndDate,

      showModalRateDayAction,
      isClashedReservationOnHightlight
    } = this.state;

    const {
      selectedPropertyId,

      roomTypeId,
      unitId,
      unitName,
      isRoomType,

      inventories,
      rates,
      calendarRates,
      unitReservations,
      month,

      integrationSourceOptions,
      bookingTypeOptions,
      bookingStatusOptions,

      clickedRateDayRoomTypeUnitId,
      isAnyRateDayStartDateClicked,
      handleOnMCRateDateActionSuccess,
      onRateUpdateWizardButtonClick,

      ratePerDayWidth,

      reservationsColorsConstants,
      permissions,
      permissionConstants,
      isAdmin,
      currency,
      timezone
    } = this.props;

    const daysDetails = this.getFormattedDisplayDataPerDay(inventories, rates, calendarRates, month, unitReservations);
    const roomTypeUnitId = unitId.length > 0 ? unitId : roomTypeId;

    return (
      <>
        <div>
          <MCModalRateDayAction
            selectedPropertyId={selectedPropertyId}
            roomTypeId={roomTypeId}
            unitId={unitId}
            isRoomType={isRoomType}
            clickedStartDateInString={clickedStartDate}
            clickedEndDateInString={clickedEndDate}
            showModalRateDayAction={showModalRateDayAction}
            isClashedReservationOnHightlight={isClashedReservationOnHightlight}
            onModalRateDayActionCancel={this.handleOnModalRateDayActionCancel}
            onRateUpdateWizardButtonClick={onRateUpdateWizardButtonClick}
            rates={rates}
            integrationSourceOptions={integrationSourceOptions}
            handleOnMCRateDateActionSuccess={handleOnMCRateDateActionSuccess}
            currency={currency}
            timezone={timezone}
          />
        </div>

        <div className={`${styles.row}`}>
          {daysDetails.map((detailsPerDay, index) => {
            const day = index + 1;
            const date = constructDateStringFromMonthAndDay(month, day);

            const backgroundColor = this.getBackgroundColor(roomTypeUnitId, clickedRateDayRoomTypeUnitId, date, detailsPerDay.reservationCounter);

            return (
              <MCRatePerDay
                key={`${roomTypeUnitId}${date}`}
                ratePerDayWidth={ratePerDayWidth}
                date={date}
                month={month}
                isRoomType={isRoomType}
                inventory={detailsPerDay.inventory}
                rates={detailsPerDay.rates}
                reservations={detailsPerDay.reservation}
                reservationCounter={detailsPerDay.reservationCounter}
                propertyId={selectedPropertyId}
                roomTypeId={roomTypeId}
                unitId={unitId}
                unitName={unitName}
                integrationSourceOptions={integrationSourceOptions}
                bookingTypeOptions={bookingTypeOptions}
                bookingStatusOptions={bookingStatusOptions}
                backgroundColor={backgroundColor}
                onClickRateDay={() => this.handleOnClickRateDay(roomTypeUnitId, clickedRateDayRoomTypeUnitId, isAnyRateDayStartDateClicked, date)}
                onHoverRateDay={() => this.handleOnHoverRateDay(date)}
                unitReservations={unitReservations}
                preProcessReservationEndDate={preProcessReservationEndDate}
                reservationsColorsConstants={reservationsColorsConstants}
                handleOnMCRateDateActionSuccess={handleOnMCRateDateActionSuccess}
                permissions={permissions}
                permissionConstants={permissionConstants}
                isAdmin={isAdmin}
                currency={currency}
                timezone={timezone}
              />
            );
          })}
        </div>
      </>
    );
  }
}

MCRate.propTypes = {
  rates: PropTypes.object.isRequired,
  calendarRates: PropTypes.object.isRequired,
  month: PropTypes.string.isRequired,
  inventories: PropTypes.array,
  clickedRateDayRoomTypeUnitId: PropTypes.string,
  currency: PropTypes.string,
  timezone: PropTypes.string
};

MCRate.defaultProps = {
  inventories: [],
  rates: {},
  calendarRates: {},
  month: moment().format('YYYY-MM'),
  clickedRateDayRoomTypeUnitId: ''
};

export default MCRate;
