import { httpClient } from './../httpClient';
import { apiErrorHandler } from '../apis/helpers';

export const getExpensesListing = (year, month, host, servicePackages) => {
  const query = '?year=' + year + '&month=' + month + (host ? `&host=${host}` : ``) + (servicePackages ? `&servicePackages=${servicePackages}` : ``);
  return httpClient
    .get('/expenses/getExpensesListing' + query)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const createExpenses = expensesDetails => {
  return httpClient
    .post('/expenses', expensesDetails)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const updateExpenses = expensesDetails => {
  return httpClient
    .put('/expenses/' + expensesDetails._id, expensesDetails)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const upsertExpenses = expensesDetails => {
  return httpClient
    .post('/expenses/upsert', expensesDetails)
    .then(response => response.data)
    .catch(apiErrorHandler);
};
