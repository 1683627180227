import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Table, Tooltip } from 'antd';

import { findAndGenerateLink, generateDisplayFee } from 'utils/general';
import { useGetPaginatedUnitRentals } from 'utils/apis/unit';
import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';

import EditRentalUnitModal from './components/EditRentalUnitModal/EditRentalUnitModal';

import styles from './Body.module.css';

const useInitialModalProps = () => {
  const [isShowEditRentalModal, setIsShowEditRentalModal] = useState(false);
  const [selectedRentalUnit, setSelectedRentalUnit] = useState({});

  const handleOnClickEdit = rentalUnit => {
    setSelectedRentalUnit(rentalUnit);
    setIsShowEditRentalModal(true);
  };

  const handleOnCloseModal = () => {
    setSelectedRentalUnit({});
    setIsShowEditRentalModal(false);
  };

  return { isShowEditRentalModal, selectedRentalUnit, handleOnClickEdit, handleOnCloseModal };
};

const getTableColumns = (isAllowEditRental, handleOnClickEdit) => {
  const getDefaultRenderProp = () => ({
    render: text => text || '-'
  });

  const getUnitRenderProp = () => ({
    render: (text, record) => {
      const hasProtection = record.hasProtection;
      const isProtectionActive = record.isProtectionActive;

      const title = `HostProtect ${hasProtection ? (isProtectionActive ? 'Activated' : 'Expired') : 'Not Activated'}`;
      const type = hasProtection ? (isProtectionActive ? 'check-circle' : 'clock-circle-o') : 'warning';
      const twoToneColor = hasProtection && isProtectionActive ? '#52c41a' : '#db4437';

      return (
        <div>
          <Tooltip title={title}>
            <Icon type={type} theme="twoTone" twoToneColor={twoToneColor} />{' '}
          </Tooltip>
          {text}
        </div>
      );
    }
  });

  const getDetailsRenderProp = () => ({
    render: text => (!!text ? findAndGenerateLink(text) : '-')
  });

  const getActionRenderProp = () => ({
    render: (text, record) => (
      <Button id="edit-button2-rental" className={styles.editButton} type="primary" icon="edit" onClick={() => handleOnClickEdit(record)} />
    )
  });

  const tableColumns = [
    {
      ...constructColumn('Unit', 'unitName', { width: '14%' }),
      ...constructColumnFilterSearch('unitName', 'Search Unit'),
      ...getUnitRenderProp()
    },
    {
      ...constructColumn('Property', 'propertyName', { width: '10%' }),
      ...constructColumnFilterSearch('propertyName', 'Search Property')
    },
    {
      ...constructColumn('Room Type', 'roomTypeName', { width: '10%' }),
      ...constructColumnFilterSearch('roomTypeName', 'Search Room Type')
    },
    {
      ...constructColumn('Weekday Price', 'weekdayPrice', { isRM: true, width: '10%' }),
      render: (text, record) => <span>{generateDisplayFee(text, true, 0, record.currency)}</span>
    },
    {
      ...constructColumn('Weekend Price', 'weekendPrice', { isRM: true, width: '10%' }),
      render: (text, record) => <span>{generateDisplayFee(text, true, 0, record.currency)}</span>
    },
    {
      ...constructColumn('Rental', 'rentalFee', { isRM: true, width: '9%' }),
      ...getDefaultRenderProp()
    },
    {
      ...constructColumn('Cleaning', 'cleaningFee', { isRM: true, width: '10%' }),
      ...getDefaultRenderProp()
    },
    {
      ...constructColumn('Contact No.', 'contactNumber', { width: '10%' }),
      ...getDefaultRenderProp()
    },
    {
      ...constructColumn('Details', 'details', { width: '11%' }),
      ...getDetailsRenderProp()
    }
  ];

  if (isAllowEditRental) {
    tableColumns.push({
      width: '6%',
      ...getActionRenderProp()
    });
  }
  return tableColumns;
};

const Body = ({ query, isAllowEditRental, onTableChange, checkIsAdminReadOnly }) => {
  const { paginatedData: rentalUnits, isLoading, total, refetch: refetchRentalUnits } = useGetPaginatedUnitRentals(query);
  const { isShowEditRentalModal, selectedRentalUnit, handleOnClickEdit, handleOnCloseModal } = useInitialModalProps();

  return (
    <>
      {isAllowEditRental && isShowEditRentalModal && (
        <EditRentalUnitModal
          isShowingModal={isShowEditRentalModal}
          selectedRentalUnit={selectedRentalUnit}
          closeModal={handleOnCloseModal}
          onEditRentalUnitSuccess={refetchRentalUnits}
          checkIsAdminReadOnly={checkIsAdminReadOnly}
        />
      )}
      <Table
        rowKey={record => record._id}
        dataSource={rentalUnits}
        columns={getTableColumns(isAllowEditRental, handleOnClickEdit)}
        scroll={{ x: 1000 }}
        pagination={{ total }}
        onChange={(pagination, filters, sorter) => onTableChange({ pagination, filters, sorter })}
        loading={isLoading}
      />
    </>
  );
};

Body.propTypes = {
  query: PropTypes.object.isRequired,
  isAllowEditRental: PropTypes.bool.isRequired,
  onTableChange: PropTypes.func.isRequired
};

export default Body;
