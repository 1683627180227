import React, { Component } from 'react';
import { Row, Checkbox } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import styles from './MCRateUpdateBody.module.css';
import FormDatePickerRange from 'components/FormDatePickerRange/FormDatePickerRange';

import MCRateUpdate from '../MCRateUpdate/MCRateUpdate';

const formatMomentDateOnly = date => {
  return moment(date).startOf('day');
};

const todayDate = formatMomentDateOnly();

class MCRateUpdateBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRoomTypes: [],
      selectedUnits: [],
      allUnits: []
    };
  }

  componentDidMount() {
    const { defaultRoomType, defaultUnit, roomTypesWithRatesAndUnits, form } = this.props;

    const newStateToSet = {};
    const newFormValue = {};

    if (!defaultRoomType && !defaultUnit) {
      // To preven unnessasry set state
      return;
    }

    if (defaultRoomType) {
      const foundDefaultRoomType = roomTypesWithRatesAndUnits.find(roomTypeWithRatesAndUnits => {
        return roomTypeWithRatesAndUnits.id === defaultRoomType;
      });
      newStateToSet.selectedRoomTypes = [foundDefaultRoomType];
      newFormValue.roomTypesApplied = [foundDefaultRoomType.id];
      newStateToSet.allUnits = foundDefaultRoomType.units;
    }

    if (defaultUnit) {
      if (newStateToSet.allUnits) {
        const newSelectedUnit = newStateToSet.allUnits.find(unit => {
          return unit.id === defaultUnit;
        });
        newStateToSet.selectedUnits = [newSelectedUnit];
        newFormValue.unitsApplied = [newSelectedUnit.id];
      }
    }

    this.setState(newStateToSet);
    form.setFieldsValue(newFormValue);
  }

  handleOnRoomTypeChange = newRoomTypeIds => {
    const { form, roomTypesWithRatesAndUnits } = this.props;
    const { selectedUnits, selectedRoomTypes } = this.state;

    const isAddNewRoomType = newRoomTypeIds.length > selectedRoomTypes.length;
    const allUnits = [];
    const selectedRoomTypeIds = [];

    const getNewSelectedRoomTypes = () => {
      return newRoomTypeIds.map(newRoomTypeId => {
        const selectedRoomType = roomTypesWithRatesAndUnits.find(roomType => {
          return roomType.id === newRoomTypeId;
        });
        allUnits.push(...selectedRoomType.units);
        selectedRoomTypeIds.push(selectedRoomType.id);
        return selectedRoomType;
      });
    };

    const newSelectedRoomTypes = getNewSelectedRoomTypes();

    const getNewSelectedUnits = () => {
      const filteredSelectedUnits = selectedUnits.filter(unit => {
        return selectedRoomTypeIds.includes(unit.roomType);
      });
      if (isAddNewRoomType) {
        const newlyAddedRoomType = newSelectedRoomTypes[newRoomTypeIds.length - 1];
        if (newlyAddedRoomType.units.length === 1) {
          return [...filteredSelectedUnits, ...newlyAddedRoomType.units];
        }
      }
      return filteredSelectedUnits;
    };

    const filteredUnits = getNewSelectedUnits();

    this.setState({
      selectedRoomTypes: newSelectedRoomTypes,
      allUnits,
      selectedUnits: filteredUnits
    });

    form.setFieldsValue({
      unitsApplied: filteredUnits.map(unit => {
        return unit.id;
      })
    });
  };

  handleOnUnitChange = newUnitIds => {
    const { allUnits } = this.state;
    const selectedUnits = newUnitIds.map(newUnitId => {
      return allUnits.find(unit => {
        return unit.id === newUnitId;
      });
    });
    this.setState({
      selectedUnits
    });
  };

  selectAllRoomTypes = () => {
    const { form, roomTypesWithRatesAndUnits } = this.props;

    const allUnits = roomTypesWithRatesAndUnits
      .map(roomType => {
        return roomType.units;
      })
      .flat();

    this.setState({
      allUnits,
      selectedRoomTypes: roomTypesWithRatesAndUnits
    });

    form.setFieldsValue({
      roomTypesApplied: roomTypesWithRatesAndUnits.map(roomType => {
        return roomType.id;
      })
    });
  };

  clearAllRoomTypes = () => {
    const { form } = this.props;
    form.setFieldsValue({ roomTypesApplied: [], unitsApplied: [] });
    this.setState({ selectedRoomTypes: [], selectedUnits: [], allUnits: [] });
  };

  selectAllUnits = () => {
    const { form } = this.props;
    const { allUnits } = this.state;

    this.setState({
      selectedUnits: allUnits
    });

    form.setFieldsValue({
      unitsApplied: allUnits.map(unit => {
        return unit.id;
      })
    });
  };

  clearAllUnits = () => {
    const { form } = this.props;
    form.setFieldsValue({ unitsApplied: [] });
    this.setState({ selectedUnits: [] });
  };

  render() {
    const { allUnits, selectedUnits, selectedRoomTypes } = this.state;
    const {
      form,
      daySelections,
      selectedDays,
      onSelectedDayChange,
      roomTypesWithRatesAndUnits,
      rateTypes,
      defaultStartDate,
      defaultEndDate
    } = this.props;
    console.log(allUnits, 184);
    return (
      <>
        <Row>
          <span style={{ color: 'red' }}>*</span> Date Range
        </Row>
        <Row>
          <FormDatePickerRange
            form={form}
            disabledDate={currentDate => {
              return (
                todayDate.isAfter(currentDate) ||
                moment(todayDate)
                  .add(730, 'days')
                  .isSameOrBefore(currentDate)
              );
            }}
            fieldName="dateRange"
            requiredErrorMessage="Please select a range of dates"
            defaultPickerValue={[
              defaultStartDate ? formatMomentDateOnly(defaultStartDate) : formatMomentDateOnly(),
              defaultEndDate ? formatMomentDateOnly(defaultEndDate) : formatMomentDateOnly()
            ]}
          />
        </Row>
        <Row>Which days of the week do you want to apply the changes to?</Row>
        <Row className={styles.checkboxSelection}>
          <br />
          <Checkbox.Group options={daySelections} value={selectedDays} onChange={onSelectedDayChange} />
        </Row>
        <MCRateUpdate
          type="roomType"
          selectAllButtonId="select-button1-rooms-ruw"
          clearAllButtonId="clear-button1-rooms-ruw"
          disclaimer={
            <span>
              <p>
                <strong>For single selection:</strong> Room type with only one unit will be auto selected.
              </p>
              <p>
                <i>
                  Note: Select all will <strong>NOT</strong> auto select any unit.
                </i>
              </p>
            </span>
          }
          form={form}
          items={roomTypesWithRatesAndUnits}
          selectedItems={selectedRoomTypes}
          rateTypes={rateTypes}
          onSelectionChange={this.handleOnRoomTypeChange}
          onSelectAll={this.selectAllRoomTypes}
          onClearAll={this.clearAllRoomTypes}
          currency={this.props.currency}
          timezone={this.props.timezone}
        />
        <Row>
          <br />
        </Row>
        <MCRateUpdate
          type="unit"
          selectAllButtonId="select-button2-units-ruw"
          clearAllButtonId="clear-button2-units-ruw"
          form={form}
          items={allUnits}
          selectedItems={selectedUnits}
          rateTypes={rateTypes}
          onSelectionChange={this.handleOnUnitChange}
          onSelectAll={this.selectAllUnits}
          onClearAll={this.clearAllUnits}
          currency={this.props.currency}
          timezone={this.props.timezone}
        />
      </>
    );
  }
}

MCRateUpdateBody.propTypes = {
  form: PropTypes.object.isRequired,
  daySelections: PropTypes.array.isRequired,
  selectedDays: PropTypes.array.isRequired,
  onSelectedDayChange: PropTypes.func.isRequired,
  roomTypesWithRatesAndUnits: PropTypes.array.isRequired,
  rateTypes: PropTypes.array.isRequired,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string,
  defaultRoomType: PropTypes.string,
  defaultUnit: PropTypes.string,
  currency: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired
};

MCRateUpdateBody.defaultProps = {
  form: {},
  daySelections: [],
  selectedDays: [],
  onSelectedDayChange: () => {},
  roomTypesWithRatesAndUnits: [],
  rateTypes: []
};

export default withRouter(MCRateUpdateBody);
