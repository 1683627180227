import React, { Component } from 'react';
import { Layout, Alert } from 'antd';
import { withRouter } from 'react-router-dom';

import { withAppContext } from 'context/AppContext';

import Navbar from 'components/Navbar/Navbar';
import Header from 'components/Header/Header';

import HomeLogo from '../components/HomeLogo/HomeLogo';
import NetworkConnectivity from '../components/NetworkConnectivity/NetworkConnectivity';

import './DashboardLayout.css';

const { Sider } = Layout;

const Banner = ({ banner, onBannerClose }) => {
  return (
    localStorage.getItem('closedBannerId') !== banner._id &&
    (banner.url ? (
      <a href={banner.url} target="_blank" rel="noopener noreferrer">
        <Alert
          message={banner.message}
          type="info"
          banner
          closable
          onClose={() => {
            onBannerClose(banner._id);
          }}
        />
      </a>
    ) : (
      <Alert
        message={banner.message}
        type="info"
        banner
        closable
        onClose={() => {
          onBannerClose(banner._id);
        }}
      />
    ))
  );
};

class DashboardLayout extends Component {
  state = {
    isCollapsed: false
  };

  handleOnCollapse = isCollapsed => {
    this.setState({ isCollapsed });
  };

  handleOnBannerClose = id => {
    localStorage.setItem('closedBannerId', id);
  };

  render() {
    const { isCollapsed } = this.state;
    const { children, banner } = this.props;
    const shouldShowBanner = banner && banner._id;

    return (
      <Layout>
        <NetworkConnectivity />
        <Sider breakpoint="lg" collapsedWidth="0" collapsible collapsed={isCollapsed} onCollapse={this.handleOnCollapse}>
          <HomeLogo isCollapsed={isCollapsed} />
          <Navbar />
        </Sider>
        <Layout>
          <Header isCollapsed={isCollapsed} />
          <Layout className="layout">
            {shouldShowBanner && <Banner banner={banner} onBannerClose={this.handleOnBannerClose}></Banner>}
            {children}
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

export default withRouter(withAppContext(DashboardLayout));
