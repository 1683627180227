import React, { Component } from 'react';
import { Table, Input, Form, Select, Card, Icon, Row, Checkbox, Button, message, Modal, Switch, Tooltip, Alert } from 'antd';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';

import { AppContextConsumer, withAppContext } from 'context/AppContext';

import { getUserById, createUser, updateUser, updateUserProfile, updateOwner, deleteUser } from 'utils/apis/user';
import { getConstants } from 'utils/apis/constants';
import { getHostsAutomated } from 'utils/apis/host';
import { cloneNewObject, uniqueArray, checkIncludesAllElements } from 'utils/general';

import CloseButton from '../CloseButton/CloseButton';

import './UsersForm.css';
import FormPassword from 'components/FormPassword/FormPassword';

const FormItem = Form.Item;
const { Option } = Select;

/** Generate permission checkbox */
const PERMISSIONS_LIST = [
  'Host',
  'Users',
  'Overview',
  'Reservations',
  'Room Rates',
  'Transaction',
  'Rate Modifier',
  'Task Management',
  'Listings',
  'Reports',
  'Billing',
  'Integrations',
  'Service Package',
  'Expenses',
  'Payouts',
  'Rental',
  'Activity Log',
  'Torus',
  'Webhook'
];

const MODE_NEW = 'new';
const MODE_EDIT = 'edit';
const ROLE_TYPE_OPERATOR = 0;
const ROLE_TYPE_OWNER = 1;
const CUSTOM_ROLE_SUFFIX = '(Custom)';

class UsersForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      permissionsConst: [],
      rolesAndPermissionConst: [],
      roles: [],
      properties: [],
      modifyingRole: null,
      hostOptions: [],
      propertiesOptions: [],
      originalRoleOptions: [],
      roleOptions: [],
      genderOptions: [],
      host: '',
      roleState: '',
      mode: MODE_NEW,
      isAdmin: false,
      isLoading: true,
      isUnique: false,
      isSaveButtonLoading: false,
      isDeleteButtonLoading: false,
      isHostAllowRentalListing: false
    };

    this.checkIsChecked = this.checkIsChecked.bind(this);
    this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.changeHost = this.changeHost.bind(this);
    this.changeProperties = this.changeProperties.bind(this);
    this.changeRoleType = this.changeRoleType.bind(this);
    this.changePermission = this.changePermission.bind(this);
    this.changeGender = this.changeGender.bind(this);
    this.getRoleTypeByRoleName = this.getRoleTypeByRoleName.bind(this);
    this.getPermissionsByRoleState = this.getPermissionsByRoleState.bind(this);
    this.addCustomPermissionPreset = this.addCustomPermissionPreset.bind(this);
    this.adminChange = this.adminChange.bind(this);
  }

  async componentDidMount() {
    // Retrieve all dropdown list options
    let [hostRes, permissionRes, rolesAndPermissionRes, genderRes] = await Promise.all([
      getHostsAutomated(),
      getConstants('permissions'),
      getConstants('rolesAndPermission'),
      getConstants('gender')
    ]);

    let genderOptions = [];
    let hostOptions = [];
    let rolesAndPermissionConst = null;
    let permissionsConst = null;
    let originalRoleOptions = [];
    let roleOptions = [];

    // Assign drop down list options
    if (genderRes && genderRes.status === 200) {
      genderOptions = Object.keys(genderRes.data).map(k => {
        return {
          label: k.charAt(0).toUpperCase() + k.substring(1).toLowerCase(),
          value: genderRes.data[k]
        };
      });
    }

    if (hostRes && hostRes.status === 200) {
      hostOptions = hostRes.data.map(host => {
        return { label: host.name, value: host._id, type: host.hostType, allowRentalListing: host.allowRentalListing, properties: host.properties };
      });
    }

    const formatOperatorPresets = rolesAndPermissionConst => {
      const { user: viewingUser } = this.props;
      if (!viewingUser.isAdmin) {
        const operatorPermissionPresetKeys = Object.keys(rolesAndPermissionConst.OPERATOR.type);
        const viewingUserPermissions = viewingUser.roles[0].permissions;

        for (let i = 0; i < operatorPermissionPresetKeys.length; i++) {
          const currentPreset = rolesAndPermissionConst.OPERATOR.type[operatorPermissionPresetKeys[i]];
          if (!checkIncludesAllElements(viewingUserPermissions, currentPreset.code) && !currentPreset.isCustom) {
            currentPreset.isCustom = true;
            currentPreset.label = `${currentPreset.label} ${CUSTOM_ROLE_SUFFIX}`;
          }
        }
      }
      return rolesAndPermissionConst;
    };

    if (rolesAndPermissionRes && rolesAndPermissionRes.status === 200) {
      rolesAndPermissionConst = formatOperatorPresets(rolesAndPermissionRes.data);
      roleOptions = Object.keys(rolesAndPermissionConst)
        .map(k => {
          return { key: k, ...rolesAndPermissionConst[k] };
        })
        .map(r => ({
          label: r.label,
          value: r.code,
          key: r.key,
          onlyForAdmin: r.onlyForAdmin,
          isRentalListingPermission: r.isRentalListingPermission
        }));
      originalRoleOptions = roleOptions;
    }

    if (permissionRes && permissionRes.status === 200) {
      permissionsConst = permissionRes.data;
    }

    this.setState({
      genderOptions,
      hostOptions,
      rolesAndPermissionConst,
      originalRoleOptions,
      roleOptions,
      permissionsConst,
      isLoading: false
    });

    if (this.props.match.params.id) {
      let mode = MODE_EDIT;
      getUserById(this.props.match.params.id)
        .then(userRes => {
          if (userRes && userRes.data) {
            const user = userRes.data;

            this.props.form.setFields({
              username: {
                value: user.username
              },
              email: {
                value: user.email
              },
              firstName: {
                value: user.userProfile.firstName
              },
              lastName: {
                value: user.userProfile.lastName
              },
              contactNos: {
                value: user.contactNos
              },
              // gender: {
              //   value: user.userProfile.gender
              // },
              isAdmin: {
                value: user.isAdmin
              }
            });

            const modifyingRole = user.roles[0];
            const selectedHost = !!modifyingRole && hostOptions.find(hostOption => hostOption.value === modifyingRole.host);
            const isHostAllowRentalListing = !!selectedHost && selectedHost.allowRentalListing;
            const propertiesOptions = selectedHost
              ? selectedHost.properties.map(property => {
                  return { label: property.name, value: property._id };
                })
              : [];

            if (!isHostAllowRentalListing) {
              roleOptions = originalRoleOptions.filter(oriRoleOption => !oriRoleOption.isRentalListingPermission);
            }

            this.setState({
              roleOptions,
              mode,
              userData: cloneNewObject(user),
              modifyingRole,
              propertiesOptions: propertiesOptions,
              roles: user.roles,
              host: modifyingRole ? modifyingRole.host : '',
              isAdmin: user.isAdmin,
              isLoading: false,
              isHostAllowRentalListing
            });
          }
        })
        .catch(ex => {
          message.error('Error retrieving user');
          console.error(ex);
          this.setState({ isLoading: false });
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.roleState !== this.state.roleState) {
      this.props.form.setFieldsValue({
        rolesType: this.state.roleState
      });
    }

    if (prevState.host !== this.state.host) {
      let modifyingRole = null;
      let { roles, host, hostOptions, mode } = this.state;
      if (host) {
        // modifyingRole = roles.find(role => role.host.toString() === host.toString());
        // To prevent role type and permission preset to be reset after changing host
        modifyingRole = roles[0];
        if (!modifyingRole) {
          modifyingRole = {
            host,
            permissions: []
          };
          //FIXME: Temporary commented to only support single host
          // roles.push(modifyingRole);
        }
      }

      let roleState = this.getRoleTypeByRoleName(modifyingRole.name);
      if (!roleState && modifyingRole.name) {
        roleState = 0;
      }

      let uniqueRole = hostOptions.find(host => host.value === host);
      const propertiesOpt = hostOptions.find(hostOption => hostOption.value === host).properties.map(property => property._id);

      if (uniqueRole && uniqueRole.type && uniqueRole.type === 4) {
        roleState = 2;
        this.setState({ isUnique: true });
      } else {
        this.setState({ isUnique: false });
      }

      this.setState(
        {
          modifyingRole,
          roles: [modifyingRole], //FIXME: Temporary fixed to one item array.
          roleState
        },
        () => {
          if (this.state.modifyingRole && roleState === 2) {
            this.changeRoleType(roleState);
          }
        }
      );

      let permissionPresets = this.getPermissionsByRoleState(roleState);
      let roleName = modifyingRole.name;
      if (roleName) {
        if (!permissionPresets.map(pp => pp.label).includes(modifyingRole.name)) {
          this.addCustomPermissionPreset(roleState, roleName, modifyingRole.permissions);
          modifyingRole.isCustom = this.checkIsRoleNameCustom(roleName);
        }
      }

      this.props.form.setFieldsValue({
        host: host,
        permissionPreset: roleName,
        properties:
          mode === MODE_NEW ? [] : prevState.host === '' ? (roles.length > 0 && roles[0].properties ? roles[0].properties : propertiesOpt) : []
      });
    }
  }

  checkIsRoleNameCustom = roleName => {
    return roleName.includes(CUSTOM_ROLE_SUFFIX);
  };

  addCustomPermissionPreset = (roleState, roleName, permissions) => {
    const DEFAULT_CUSTOM_KEY = 'CUSTOM';
    let rolesAndPermissionConst = this.state.rolesAndPermissionConst;
    let permissionKey = this.state.roleOptions.find(roleOption => roleOption.value === roleState).key;

    if (!permissionKey) {
      permissionKey = Object.keys(rolesAndPermissionConst)[0];
    }

    const foundRole = rolesAndPermissionConst[permissionKey];
    const roleKeyToMutate =
      Object.keys(foundRole.type).find(key => {
        const foundPresetPermissions = foundRole.type[key];
        return foundPresetPermissions.label === roleName;
      }) || DEFAULT_CUSTOM_KEY;

    foundRole.type[roleKeyToMutate] = {
      label: roleName,
      code: permissions,
      isCustom: this.checkIsRoleNameCustom(roleName)
    };
  };

  changeGender = gender => {
    this.setState({
      gender
    });
  };

  changeHost = host => {
    const { hostOptions, originalRoleOptions } = this.state;
    const isHostAllowRentalListing = hostOptions.find(hostOption => hostOption.value === host).allowRentalListing;
    const propertiesOpt = hostOptions
      .find(hostOption => hostOption.value === host)
      .properties.map(property => {
        return { label: property.name, value: property._id };
      });
    let processedRoleOptions = originalRoleOptions;

    if (!isHostAllowRentalListing) {
      processedRoleOptions = originalRoleOptions.filter(oriRoleOption => !oriRoleOption.isRentalListingPermission);
    }

    this.clearAllProperties();

    this.setState({
      host,
      propertiesOptions: propertiesOpt,
      isHostAllowRentalListing,
      roleOptions: processedRoleOptions
    });
  };

  changeProperties = properties => {
    this.props.form.setFieldsValue({
      properties: properties
    });
    this.setState({
      properties
    });
  };

  selectAllProperties = () => {
    const { form } = this.props;
    const { propertiesOptions } = this.state;

    const allProperties = propertiesOptions.map(properties => properties.value).flat();

    this.setState({
      properties: allProperties
    });

    form.setFieldsValue({
      properties: allProperties
    });
  };

  clearAllProperties = () => {
    const { form } = this.props;

    this.setState({
      properties: []
    });

    form.setFieldsValue({
      properties: []
    });
  };

  changeRoleType = roleState => {
    const { modifyingRole } = this.state;
    const permissionPresets = this.getPermissionsByRoleState(roleState);
    const permissionPreset = (permissionPresets.length > 1 && permissionPresets.find(pp => pp.label === modifyingRole.name)) || permissionPresets[0];
    modifyingRole.name = permissionPreset.label;
    modifyingRole.permissions = this.getRolePermissions(roleState, permissionPreset.code);
    modifyingRole.isCustom = permissionPreset.isCustom || false;
    this.props.form.setFieldsValue({
      permissionPreset: modifyingRole.name
    });
    this.setState({
      roleState,
      modifyingRole
    });
  };

  getRolePermissions = (roleState, presetPermissions) => {
    const { checkIsAdmin, getUserPermissions } = this.props;
    const { userData, mode } = this.state;

    if (checkIsAdmin() || roleState === ROLE_TYPE_OWNER) {
      return presetPermissions;
    }

    const isEditMode = mode === MODE_EDIT;
    const permissions = getUserPermissions();
    const filteredEditablePermissions = presetPermissions.filter(preset => permissions.includes(preset));
    const userRole = userData.roles && userData.roles[0];

    if (isEditMode && this.getRoleTypeByRoleName(userRole.name) === ROLE_TYPE_OPERATOR) {
      const uneditablePermissions = userRole.permissions.filter(userPermission => !permissions.includes(userPermission));
      return uniqueArray(userRole && uneditablePermissions.concat(filteredEditablePermissions));
    } else {
      return filteredEditablePermissions;
    }
  };

  changePermission = permissionLabel => {
    const { modifyingRole, roleOptions, rolesAndPermissionConst } = this.state;
    modifyingRole.name = permissionLabel;
    const roleType = roleOptions.find(roleOption => roleOption.value === this.getRoleTypeByRoleName(permissionLabel)).key;
    const permissionsObj = rolesAndPermissionConst[roleType].type;
    const roleState = rolesAndPermissionConst[roleType].code;
    if (!permissionsObj) {
      alert('Something is wrong!');
    }
    const permissionsItem = Object.keys(permissionsObj)
      .map(key => permissionsObj[key])
      .find(o => o.label === permissionLabel);
    modifyingRole.permissions = this.getRolePermissions(roleState, permissionsItem.code);
    modifyingRole.isCustom = permissionsItem.isCustom || false;
    this.setState({
      modifyingRole
    });
  };

  checkIsChecked = code => {
    const { isAdmin, modifyingRole } = this.state;
    if (isAdmin) {
      return true;
    }
    const permissions = (modifyingRole && modifyingRole.permissions) || [];
    return permissions.includes(code);
  };

  onCheckBoxChange = code => e => {
    const { modifyingRole, permissionsConst } = this.state;
    const checked = e.target.checked;
    if (checked) {
      modifyingRole.permissions.push(code);

      // Link view rate permission with edit rate permission.
      if (checked && code === permissionsConst.ROOM_RATES_MODIFY) {
        modifyingRole.permissions.push(permissionsConst.ROOM_RATES_VIEW);
      }
    } else {
      modifyingRole.permissions = modifyingRole.permissions.filter(p => p !== code);
    }
    this.applyCustomToRole(modifyingRole, modifyingRole.isCustom);
    this.setState({
      modifyingRole
    });
  };

  applyCustomToRole = (modifyingRole, isPresetCustom) => {
    const { form } = this.props;
    const roleState = this.getRoleTypeByRoleName(modifyingRole.name);
    if (!isPresetCustom) {
      modifyingRole.name = `${modifyingRole.name} ${CUSTOM_ROLE_SUFFIX}`;
    }
    this.addCustomPermissionPreset(roleState, modifyingRole.name, modifyingRole.permissions);
    form.setFieldsValue({
      permissionPreset: modifyingRole.name
    });
    modifyingRole.isCustom = true;
  };

  adminChange = checked => {
    this.setState({
      isAdmin: checked
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form, history, match } = this.props;
    const { roles, mode, userData } = this.state;
    form.validateFields((err, values) => {
      if (!err) {
        if (this.checkIsCurrentUserOwnDetails()) {
          delete values.roles;
        } else {
          values.roles = roles.filter(role => role.host && role.name);
        }
        if (mode === MODE_NEW) {
          this.setState({ isSaveButtonLoading: true });
          createUser(values)
            .then(res => {
              if (res.status === 200) {
                history.push('/users');
                message.success('User created!');
              } else {
                message.error(res.data.error);
                this.setState({ isSaveButtonLoading: false });
              }
            })
            .catch(ex => {
              message.error(ex);
            });
        } else if (mode === MODE_EDIT) {
          const userId = match.params.id;
          const userProfileId = userData.userProfile._id;

          const handleOnOk = () => {
            this.setState({ isSaveButtonLoading: true });
            updateUser(userId, {
              ...values,
              onlySupportSingleHost: true
            })
              .then(res => {
                if (res && res.status === 204) {
                  updateUserProfile(userProfileId, values)
                    .then(resUser => {
                      if (resUser.status === 200) {
                        updateOwner(userId).then(res => {
                          history.push('/users');
                          message.success('User updated!');
                        });
                      } else {
                        message.error('Something went wrong and userProfile is not updated.');
                        this.setState({ isSaveButtonLoading: false });
                      }
                    })
                    .catch(ex => {
                      message.error(ex);
                      this.setState({ isSaveButtonLoading: false });
                    });
                } else {
                  message.error('Something went wrong and user is not updated.');
                  this.setState({ isSaveButtonLoading: false });
                }
              })
              .catch(ex => {
                message.error(ex);
                this.setState({ isSaveButtonLoading: false });
              });
          };

          Modal.confirm({
            title: 'Are you sure you want to overwrite existing data?',
            content: 'You will not be able to undo this action, but you may update it again.',
            onOk: handleOnOk,
            onCancel() {},
            okButtonProps: { id: 'save-cfm-button7a-edituser' },
            cancelButtonProps: { id: 'cancelsave-cfm-button7b-edituser' }
          });
        }
      }
    });
  };

  handleDelete = e => {
    e.preventDefault();
    const { match, history } = this.props;
    const userId = match.params.id;

    const handleOnOk = () => {
      this.setState({ isDeleteButtonLoading: true });
      deleteUser(userId)
        .then(res => {
          if (res.status === 204) {
            history.push('/users');
            message.success('User deleted!');
          } else {
            message.error('Something went wrong and user is not deleted.');
            this.setState({ isDeleteButtonLoading: false });
          }
        })
        .catch(ex => {
          message.error(ex || 'Something went wrong and user is not deleted.');
          this.setState({ isDeleteButtonLoading: false });
        });
    };

    Modal.confirm({
      title: 'Are you sure you want to delete this user?',
      content: 'This action cannot be reversed. Once deleted, it cannot be recovered.',
      onOk: handleOnOk,
      onCancel() {},
      okButtonProps: { id: 'del-cfm-button2a-edituser' },
      cancelButtonProps: { id: 'cancel-cfm-button2b-edituser' }
    });
  };

  getRoleTypeByRoleName = roleName => {
    const { roleOptions, rolesAndPermissionConst } = this.state;
    const rolesAndPermissionArr = Object.keys(rolesAndPermissionConst).map(key => {
      const roleOption = roleOptions.find(roleOption => roleOption.key === key);
      return {
        types: Object.keys(rolesAndPermissionConst[key].type).map(key2 => rolesAndPermissionConst[key].type[key2].label),
        value: roleOption ? roleOption.value : ''
      };
    });
    for (let i = 0; i < rolesAndPermissionArr.length; i++) {
      if (rolesAndPermissionArr[i].types.includes(roleName)) {
        return rolesAndPermissionArr[i].value;
      }
    }
    return null;
  };

  getPermissionsByRoleState = roleState => {
    const { roleOptions, rolesAndPermissionConst } = this.state;
    const roleKey = roleOptions.find(roleOption => roleOption.value === roleState);
    const permissionPreset = roleKey && rolesAndPermissionConst[roleKey.key];
    const permissionPresetOptions =
      (permissionPreset && Object.keys(permissionPreset.type).map(key => ({ key, ...permissionPreset.type[key] }))) || [];
    const permissions = this.filterPermissionPresetOptions(permissionPresetOptions, roleState);
    return permissions;
  };

  filterPermissionPresetOptions = (permissionPresetOptions, roleState) => {
    const { checkIsAdmin, getUserPermissions } = this.props;

    if (checkIsAdmin() || roleState === ROLE_TYPE_OWNER) {
      return permissionPresetOptions;
    }

    const permissions = getUserPermissions();

    return permissionPresetOptions.filter(option => {
      const foundPermission = permissions.find(permission => option.code.includes(permission));
      return foundPermission !== null;
    });
  };

  checkIsUserAllowEditPermissions = permission => {
    const { checkIsAdmin, getUserPermissions } = this.props;
    const { modifyingRole, roleState, isAdmin: isCurrentUserAdmin } = this.state;

    const hasSelectedOperatorRole = roleState === ROLE_TYPE_OPERATOR;
    const hasSelectedPresetPermission = modifyingRole && !!modifyingRole.name;
    const isEditableRole = !isCurrentUserAdmin && hasSelectedOperatorRole && hasSelectedPresetPermission;

    const isEditSelf = this.checkIsCurrentUserOwnDetails();

    const canUserEdit = checkIsAdmin() || getUserPermissions().includes(permission);

    return isEditableRole && !isEditSelf && canUserEdit;
  };

  checkIsCurrentUserOwnDetails = () => {
    const { userData } = this.state;
    const { user } = this.props;
    return String(user._id) === String(userData._id);
  };

  generatePermissionsCodeForCheckbox = (permission, recordPermissions) => {
    const { permissionsConst } = this.state;
    switch (recordPermissions) {
      case 'View':
        return permissionsConst[permission.replace(' ', '_').toUpperCase() + '_VIEW'];
      case 'Create':
        return permissionsConst[permission.replace(' ', '_').toUpperCase() + '_CREATE'];
      case 'Edit':
        return permissionsConst[permission.replace(' ', '_').toUpperCase() + '_MODIFY'];
      case 'Delete':
        return permissionsConst[permission.replace(' ', '_').toUpperCase() + '_DELETE'];
      case 'Export CSV':
        return permissionsConst[permission.replace(' ', '_').toUpperCase() + '_CSV_VIEW'];
      default:
        return -1;
    }
  };

  generatePermissions = () => {
    const { isHostAllowRentalListing } = this.state;
    let permissionsList = PERMISSIONS_LIST;

    if (!isHostAllowRentalListing) {
      permissionsList = PERMISSIONS_LIST.filter(permission => permission !== 'Rental');
    }

    return permissionsList.map(permission => ({
      title: permission,
      dataIndex: permission.toLowerCase(),
      key: permission.toLowerCase(),
      align: 'center',
      className: 'users-table-column',
      render: (text, record) => {
        const code = this.generatePermissionsCodeForCheckbox(permission, record.permissions);

        // Link view rate permission with edit rate permission.
        const disableRoomRatesView = () => {
          const isEditRoomRateChecked = this.checkIsChecked(this.state.permissionsConst.ROOM_RATES_MODIFY);
          if (code === this.state.permissionsConst.ROOM_RATES_VIEW && isEditRoomRateChecked) {
            return true;
          } else {
            return false;
          }
        };

        return code >= 0 ? (
          <Checkbox
            disabled={!this.checkIsUserAllowEditPermissions(code) || disableRoomRatesView()}
            checked={this.checkIsChecked(code)}
            onChange={this.onCheckBoxChange(code)}
          />
        ) : null;
      }
    }));
  };

  render() {
    const { checkIsAllowDeleteUser, form, history, match, checkIsAdminReadOnly } = this.props;
    const { getFieldDecorator } = form;
    const {
      roleState,
      isAdmin,
      isUnique,
      mode,
      roleOptions,
      genderOptions,
      properties,
      propertiesOptions,
      hostOptions,
      host,
      isLoading,
      isSaveButtonLoading,
      isDeleteButtonLoading
    } = this.state;
    // Generate permission preset option
    const permissionPresetOptions = this.getPermissionsByRoleState(roleState).map(permissionOption => {
      return (
        <Option key={permissionOption.label} value={permissionOption.label}>
          {permissionOption.label}
        </Option>
      );
    });

    const permissions = [
      {
        key: '1',
        permissions: 'View'
      },
      {
        key: '2',
        permissions: 'Create'
      },
      {
        key: '3',
        permissions: 'Edit'
      },
      {
        key: '4',
        permissions: 'Delete'
      },
      {
        key: '5',
        permissions: 'Export CSV'
      }
    ];

    const columns = [
      {
        title: '',
        dataIndex: 'permissions',
        key: 'permissions',
        align: 'center',
        fixed: 'left',
        className: 'users-table-column',
        width: '84px',
        render: (text, record) => {
          return text ? <span> {text}</span> : '';
        }
      },
      {
        title: 'Module',
        children: this.generatePermissions()
      }
    ];

    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <Card className="users-form-card" title="Basic Information" loading={isLoading}>
          <CloseButton
            onClick={() => {
              history.push('/users');
            }}
          />
          <Row type="flex" justify="start" gutter={36}>
            <FormItem label="Username" className="users-form-list">
              {getFieldDecorator('username', {
                rules: [
                  {
                    required: true,
                    message: intl
                      .get('forms.required', {
                        formField: intl.get('forms.placeholders.username').d('username')
                      })
                      .d('Please enter your Username!')
                  }
                ]
              })(<Input prefix={<Icon type="user" />} placeholder={intl.get('forms.placeholders.username').d('Username')} />)}
            </FormItem>
            <FormItem label="E-mail" className="users-form-list">
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: intl
                      .get('forms.required', {
                        formField: intl.get('forms.placeholders.email').d('email')
                      })
                      .d('Please enter your email!')
                  },
                  {
                    type: 'email',
                    message: intl.get('forms.valid.email').d('You have entered an invalid email!')
                  }
                ]
              })(<Input prefix={<Icon type="mail" />} placeholder={intl.get('forms.placeholders.email').d('Email')} />)}
            </FormItem>
            {mode !== MODE_EDIT && <FormPassword form={form} isSideBySide passwordClassName="users-form-list" />}
            <FormItem label="First Name" className="users-form-list">
              {getFieldDecorator('firstName', {
                rules: [
                  {
                    required: true,
                    message: intl
                      .get('forms.required', {
                        formField: intl.get('forms.placeholders.firstName').d('first name')
                      })
                      .d('Please enter your first name!')
                  }
                ]
              })(<Input prefix={<Icon type="user" />} placeholder={intl.get('forms.placeholders.firstName')} />)}
            </FormItem>
            <FormItem label="Last Name" className="users-form-list">
              {getFieldDecorator('lastName', {
                rules: [
                  {
                    required: true,
                    message: intl
                      .get('forms.required', {
                        formField: intl.get('forms.placeholders.lastName').d('last name')
                      })
                      .d('Please enter your last name!')
                  }
                ]
              })(<Input prefix={<Icon type="user" />} placeholder={intl.get('forms.placeholders.lastName').d('Last Name')} />)}
            </FormItem>
            <FormItem label="Contact Number" className="users-form-list">
              {getFieldDecorator('contactNos', {
                rules: [
                  {
                    required: true,
                    message: intl
                      .get('forms.required', {
                        formField: intl.get('forms.placeholders.contactNo').d('contact number')
                      })
                      .d('Please enter your contact number.')
                  }
                ]
              })(<Input prefix={<Icon type="phone" />} type="tel" placeholder={intl.get('forms.placeholders.contactNo').d('Contact Number')} />)}
            </FormItem>
            {/* <FormItem label="Gender" className="users-form-list">
              {getFieldDecorator('gender', {
                rules: [{ required: true, message: 'Please select your gender!' }]
              })(
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select the User's gender"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.changeGender}
                >
                  {genderOptions.map(genderOption => {
                    return (
                      <Option key={genderOption.value} value={genderOption.value}>
                        {genderOption.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem> */}
            <FormItem label="For Host" className="users-form-list">
              {getFieldDecorator('host', {
                message: 'Please select a host!'
              })(
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select a host"
                  disabled={isAdmin}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.changeHost}
                >
                  {hostOptions.map(hostOption => {
                    return (
                      <Option key={hostOption.value} value={hostOption.value}>
                        {hostOption.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem label="For Properties" className="users-form-list">
              {getFieldDecorator('properties', {
                initialValue: properties
              })(
                <Select
                  showSearch
                  mode="multiple"
                  optionFilterProp="children"
                  placeholder="Select the property to be assigned"
                  disabled={!host || isAdmin || isUnique || this.checkIsCurrentUserOwnDetails()}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.changeProperties}
                >
                  {propertiesOptions.map(propertyOption => {
                    return (
                      <Option key={propertyOption.value} value={propertyOption.value}>
                        {propertyOption.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
              <Button
                id={'select-all-button'}
                ghost
                type="primary"
                disabled={!host || isAdmin || isUnique || this.checkIsCurrentUserOwnDetails() || properties.length === propertiesOptions.length}
                onClick={this.selectAllProperties}
                style={{ marginTop: '5px' }}
              >
                Select All
              </Button>
              <Button
                id={'clear-all-button'}
                ghost
                type="primary"
                disabled={properties.length === 0}
                onClick={this.clearAllProperties}
                style={{ marginTop: '5px', marginLeft: '5px' }}
              >
                Clear All
              </Button>
            </FormItem>
            <AppContextConsumer>
              {({ user }) => {
                return (
                  <FormItem label="User's Role Type" className="users-form-list">
                    {getFieldDecorator('rolesType', {
                      message: `Please select user's role type!`
                    })(
                      <Select
                        showSearch
                        disabled={!host || isAdmin || isUnique || this.checkIsCurrentUserOwnDetails()}
                        optionFilterProp="children"
                        placeholder="Select the User's role type"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={this.changeRoleType}
                      >
                        {user.isAdmin && roleState === 2
                          ? roleOptions.map(roleOption => {
                              return (
                                <Option key={roleOption.value} value={roleOption.value}>
                                  {roleOption.label}
                                </Option>
                              );
                            })
                          : roleOptions
                              .filter(roleOption => !roleOption.onlyForAdmin)
                              .map(roleOption => {
                                return (
                                  <Option key={roleOption.value} value={roleOption.value}>
                                    {roleOption.label}
                                  </Option>
                                );
                              })}
                      </Select>
                    )}
                  </FormItem>
                );
              }}
            </AppContextConsumer>
            <FormItem label="Permissions Preset" className="users-form-list">
              {getFieldDecorator('permissionPreset', {
                message: `Please select permissions for the user `
              })(
                <Select
                  disabled={!permissionPresetOptions || !permissionPresetOptions.length || isAdmin || this.checkIsCurrentUserOwnDetails()}
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select the User's permissions"
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.changePermission}
                >
                  {permissionPresetOptions ? permissionPresetOptions : ''}
                </Select>
              )}
            </FormItem>
            <AppContextConsumer>
              {({ user }) => {
                return (
                  user.isAdmin && (
                    <FormItem
                      label={
                        <span>
                          Assign Admin&nbsp;
                          <Tooltip title="Make this user an Admin?">
                            <Icon type="question-circle-o" />
                          </Tooltip>
                        </span>
                      }
                      className="users-form-list"
                    >
                      {getFieldDecorator('isAdmin', {
                        message: 'Assign admin to the user'
                      })(<Switch checked={isAdmin} onChange={this.adminChange} disabled={user._id === match.params.id} />)}
                    </FormItem>
                  )
                );
              }}
            </AppContextConsumer>
            {isAdmin && (
              <div className="users-info-wrapper">
                <Alert message="Admins are permitted to all of the modules." type="info" showIcon />
              </div>
            )}
            {roleState === 1 && !isAdmin && (
              <div className="users-info-wrapper">
                <Alert message="Owners are only permitted to view their own payout." type="info" showIcon />
              </div>
            )}
            {roleState === 2 && !isAdmin && (
              <div className="users-info-wrapper">
                <Alert message="Unique users are only permitted to view, edit and create listings." type="info" showIcon />
              </div>
            )}
            {roleState === 3 && !isAdmin && (
              <div className="users-info-wrapper">
                <Alert message="Property agents are only permitted to view the rental details." type="info" showIcon />
              </div>
            )}
            <FormItem className="users-table-wrapper">
              <Table columns={columns} dataSource={permissions} bordered pagination={false} scroll={{ x: 1280 }} />
            </FormItem>
          </Row>
        </Card>
        <div className="save-and-delete-buttons">
          <FormItem className="users-button-wrapper">
            {mode === 'new' ? (
              <Button id="create-button5a-users" type="primary" htmlType="submit" loading={isSaveButtonLoading} disabled={checkIsAdminReadOnly()}>
                Create
              </Button>
            ) : (
              <React.Fragment>
                <Button id="save-button7-edituser" type="primary" htmlType="submit" loading={isSaveButtonLoading} disabled={checkIsAdminReadOnly()}>
                  Save
                </Button>
                {checkIsAllowDeleteUser() && (
                  <Button
                    id="del-button2-edituser"
                    type="danger"
                    onClick={this.handleDelete}
                    className="sp-button-margin"
                    loading={isDeleteButtonLoading}
                    disabled={checkIsAdminReadOnly()}
                  >
                    Delete
                  </Button>
                )}
              </React.Fragment>
            )}
          </FormItem>
        </div>
      </Form>
    );
  }
}
export default withRouter(withAppContext(Form.create()(UsersForm)));
