import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Skeleton } from 'antd';

import RateDistribution from './components/RateDistribution/RateDistribution';
import { getConstants } from 'utils/apis/constants';

import styles from './AdvancedOptionsRateDistributionTab.module.css';

class AdvancedOptionsRateDistributionTab extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    roomsOfRates: PropTypes.array.isRequired,
    currency: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,

      rateModifierTypeConstants: [],
      rateModifierSignConstants: []
    };
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    const rateModifierTypeConstants = await getConstants('rateModifierType').then(res => {
      return Object.values(res.data).map(data => ({ ...data, value: data.label, key: data.code }));
    });
    const rateModifierSignConstants = await getConstants('rateModifierSign').then(res => {
      return Object.values(res.data).map(data => ({ ...data, value: data.label, key: data.code }));
    });

    this.setState({ isLoading: false, rateModifierTypeConstants, rateModifierSignConstants });
  };

  render() {
    const { isLoading, rateModifierTypeConstants, rateModifierSignConstants } = this.state;
    const { form, roomsOfRates, isOccupancyRateApplicable } = this.props;

    return (
      <Skeleton loading={isLoading}>
        <div className={styles.scrollBarStyle}>
          {roomsOfRates.map((roomOfRates, index) => (
            <Card key={index} title={roomOfRates.roomName} className={styles.roomCard} headStyle={{ backgroundColor: '#cbf2f4' }}>
              {roomOfRates.rates.map((rateObject, index) => (
                <RateDistribution
                  key={index}
                  form={form}
                  roomId={roomOfRates.roomId}
                  maxCapacity={roomOfRates.maxCapacity}
                  occupancyRate={rateObject.occupancyRate}
                  otaId={rateObject.otaId}
                  rate={rateObject.rate}
                  isOccupancyRateApplicable={isOccupancyRateApplicable}
                  rateModifierTypeConstants={rateModifierTypeConstants}
                  rateModifierSignConstants={rateModifierSignConstants}
                  currency={this.props.currency}
                />
              ))}
            </Card>
          ))}
        </div>
      </Skeleton>
    );
  }
}

export default AdvancedOptionsRateDistributionTab;
