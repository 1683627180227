import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Row, Col, Form, Button, Modal } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import { updateCTrip } from 'utils/apis/integration';
import FormDatePickerRange from 'components/FormDatePickerRange/FormDatePickerRange';

import '../../Expedia/components/ExpediaSyncForm.css';

class CtripSyncModal extends Component {
  constructor(props) {
    super(props);
    this.handleOnFormSubmit = this.handleOnFormSubmit.bind(this);
  }

  handleOnFormSubmit = e => {
    e.preventDefault();
    const { form, ctrip } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const startDate = moment(values.dateRange[0]._d).format('YYYY-MM-DD');
        const endDate = moment(values.dateRange[1]._d).format('YYYY-MM-DD');
        updateCTrip({
          _id: ctrip._id,
          ctripHotelId: ctrip.foreignPropertyId,
          property: ctrip.property,
          data: ctrip.data,
          startDate,
          endDate
        }).then(() => {
          window.location.reload();
        });
      }
    });
  };

  render() {
    const { visible, onCancel, ctrip, propertyData, form } = this.props;
    const foreignPropertyId = ctrip && ctrip.foreignPropertyId ? '| ' + ctrip.foreignPropertyId : '';
    return (
      <Modal visible={visible} onCancel={onCancel} footer={null} title="Sync Availability, Rates, Inventory to Ctrip">
        <Form onSubmit={this.handleOnFormSubmit} style={{ width: '100%' }}>
          <Card title={`Sync to ${propertyData.name} | ${foreignPropertyId}`}>
            <Row type="flex" justify="space-between" className="formItemContainer">
              <Col span={24} className="formItem">
                <FormDatePickerRange
                  form={form}
                  label="Select date to sync with Ctrip"
                  fieldName="dateRange"
                  requiredErrorMessage="Please select date range."
                  defaultPickerValue={[moment(), moment().add('years', 1)]}
                />
              </Col>
            </Row>
            <Row type="flex" align="center" justify="bottom">
              <Col span={24}>
                <Button type="primary" size="default" htmlType="submit">
                  Sync to Ctrip
                </Button>
              </Col>
            </Row>
          </Card>
        </Form>
      </Modal>
    );
  }
}

CtripSyncModal.propTypes = {
  ctrip: PropTypes.object.isRequired,
  propertyData: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
};

CtripSyncModal.defaultProps = {
  ctrip: {},
  propertyData: {},
  visible: true,
  onCancel: () => {}
};

export default withRouter(Form.create()(CtripSyncModal));
