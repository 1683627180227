import React, { useMemo } from 'react';
import { Alert, Card, Col, Row } from 'antd';
import PropTypes from 'prop-types';

import { constructTwoDecimalsRule } from 'utils/general';

import FormInput from 'components/FormInput/FormInput';

import styles from './BookingEngineConfigDetailsCard.module.css';

const PriceInformation = ({ form, cardClassname, defaultBookingEngineRoomTypeCleaningFee }) => {
  return (
    <Card title="Price Information" className={cardClassname}>
      <FormInput
        form={form}
        name="bookingEngineRoomTypeCleaningFee"
        label="Room Type Cleaning Fee"
        placeholder="0.00"
        defaultValue={defaultBookingEngineRoomTypeCleaningFee}
        extraProps={{ addonBefore: 'RM' }}
        extraRules={[constructTwoDecimalsRule()]}
        size="large"
      />
    </Card>
  );
};

const BasicInformation = ({ form, cardClassname, defaultBookingEngineRoomTypeDisplayName }) => {
  return (
    <Card title="Basic Information" className={cardClassname}>
      <FormInput
        form={form}
        name="bookingEngineRoomTypeDisplayName"
        defaultValue={defaultBookingEngineRoomTypeDisplayName}
        inputType="input"
        label="External Display Name"
        placeholder="Type in display name for booking website"
        size="large"
      />
    </Card>
  );
};

const GeneralAlert = () => {
  return (
    <Alert
      className={styles.notes}
      message="Notes"
      description={
        <div>
          <p className={styles.notesText}>All settings configured here will be shown in booking website.</p>
        </div>
      }
      type="info"
      showIcon
    />
  );
};

const BookingEngineConfigDetailsCard = ({ form, cardClassname, defaultValues }) => {
  const defaultBookingEngineRoomTypeDisplayName = useMemo(() => defaultValues.bookingEngineRoomTypeDisplayName, [defaultValues]);
  const defaultBookingEngineRoomTypeCleaningFee = useMemo(() => defaultValues.bookingEngineRoomTypeCleaningFee, [defaultValues]);

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <GeneralAlert />
      </Col>
      <Col span={24}>
        <BasicInformation
          form={form}
          cardClassname={cardClassname}
          defaultBookingEngineRoomTypeDisplayName={defaultBookingEngineRoomTypeDisplayName}
        />
      </Col>
      <Col span={24}>
        <PriceInformation
          form={form}
          cardClassname={cardClassname}
          defaultBookingEngineRoomTypeCleaningFee={defaultBookingEngineRoomTypeCleaningFee}
        />
      </Col>
    </Row>
  );
};

BookingEngineConfigDetailsCard.propTypes = {
  form: PropTypes.object.isRequired,
  cardClassname: PropTypes.string.isRequired,
  defaultValues: PropTypes.object
};

BookingEngineConfigDetailsCard.defaultProps = {
  defaultValues: {}
};

export default BookingEngineConfigDetailsCard;
