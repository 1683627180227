import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, DatePicker } from 'antd';

import styles from './FormDatePickerRange.module.css';

const FormItem = Form.Item;

const addRules = (requiredErrorMessage, extraRules) => {
  const rules = [];
  if (requiredErrorMessage && typeof requiredErrorMessage === 'string') {
    rules.push({
      required: true,
      message: requiredErrorMessage
    });
  }
  return [...rules, ...extraRules];
};

const FormDatePickerRange = ({
  form,
  fieldName,
  isHideLabel,
  formLabel,
  label,
  requiredErrorMessage,
  extraRules,
  defaultPickerValue,
  allowClear,
  ...otherProps
}) => {
  const rules = addRules(requiredErrorMessage, extraRules);

  return (
    <Fragment>
      {!isHideLabel && !formLabel && <p className={styles.label}>{label}</p>}
      <FormItem label={formLabel} colon={false}>
        {form.getFieldDecorator(fieldName, {
          rules,
          initialValue: defaultPickerValue
        })(<DatePicker.RangePicker className={styles.datePickerInput} size="medium" allowClear={allowClear} {...otherProps} />)}
      </FormItem>
    </Fragment>
  );
};

FormDatePickerRange.propTypes = {
  form: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  isHideLabel: PropTypes.bool,
  formLabel: PropTypes.string,
  label: PropTypes.string,
  requiredErrorMessage: PropTypes.string,
  extraRules: PropTypes.array,
  defaultPickerValue: PropTypes.array
};

FormDatePickerRange.defaultProps = {
  defaultPickerValue: [],
  requiredErrorMessage: '',
  extraRules: []
};

export default FormDatePickerRange;
