import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col, Button, notification, Tabs, Modal, message } from 'antd';

import { withAppContext } from 'context/AppContext';
import {
  deleteRemoveUnit,
  getPotentialParentList,
  getPotentiaChildList,
  getUnitOwners,
  getRelationships,
  putUpdateUnit,
  updateParentChild
} from 'utils/apis/unit';

import { buildListingUri, buildServicePackageEditUri, buildIOTAFormUri } from 'utils/routes';
import { capitalizeFirstLetter, generateDisplayFee, generateFeeFromDisplay, guard } from 'utils/general';

import BasicDetailsCard from './components/BasicDetailsCard/BasicDetailsCard';
import BookingEngineConfigDetailsCard from './components/BookingEngineConfigDetailsCard/BookingEngineConfigDetailsCard';
import ChangeRoomTypeModal from './components/ChangeRoomTypeModal/ChangeRoomTypeModal';
import ContractInfoCard from './components/ContractInfoCard/ContractInfoCard';
import DetailedDescriptionCard from './components/DetailedDescriptionCard/DetailedDescriptionCard';
import FinePrintInfoCard from './components/FinePrintInfoCard/FinePrintInfoCard';
import HostProtectCard from './components/HostProtectCard/HostProtectCard';
import OperationCostsCard from './components/OperationCostsCard/OperationCostsCard';
import RelationshipCard from './components/RelationshipCard/RelationshipCard';
import PhotosCard from '../components/PhotosCard/PhotosCard';

import styles from './Unit.module.css';

const TabPane = Tabs.TabPane;
const SOLO_RELATIONSHIP = 'solo';

class Unit extends React.Component {
  constructor() {
    super();
    this.state = {
      hostProtectCertFile: {},
      owners: [],
      hasFetchedOwners: false,
      isSaving: false,
      isDeleting: false,
      isChangeRoomTypeModalVisible: false,
      relationship: null,
      potentialParents: [],
      potentialChild: [],
      hostProtectDocFiles: []
    };
  }

  componentDidMount() {
    this.fetchOwners();
    this.fetchRelationships();
    this.fetchFile();
  }

  fetchFile = () => {
    const { data } = this.props;
    if (data.protection) {
      const hostProtectPayload = data.protection;
      this.setState({
        hostProtectCertFile: hostProtectPayload.file ? hostProtectPayload.file : {},
        hostProtectDocFiles: hostProtectPayload.files ? hostProtectPayload.files : []
      });
    } else {
      this.setState({
        hostProtectCertFile: {},
        hostProtectDocFiles: []
      });
    }
  };

  fetchOwners = () => {
    const { data } = this.props;
    getUnitOwners(data._id)
      .then(res => {
        this.setOwnersState(res);
      })
      .catch(err => console.error(err));
  };

  fetchRelationships = () => {
    const { data } = this.props;
    getRelationships(data._id)
      .then(res => {
        this.setRelationshipState(res);
      })
      .catch(err => console.error(err));

    getPotentialParentList(data._id).then(res => {
      try {
        if (res) {
          this.setState({
            potentialParents: res
          });
        } else {
          console.log('cannot retrieve parent list');
        }
      } catch (ex) {
        console.log(ex);
      }
    });
    getPotentiaChildList(data._id).then(res => {
      if (res) {
        this.setState({
          potentialChild: res
        });
      } else {
        console.log('cannot retrieve children list');
      }
    });
  };

  setRelationshipState = relationship => {
    this.setState({
      relationship
    });
  };

  setOwnersState = owners => {
    this.setState({
      owners,
      hasFetchedOwners: true
    });
  };

  extractBasicDetailsData = () => {
    const { data } = this.props;
    return {
      name: data.name,
      maxFreeStay: data.maxFreeStay
    };
  };

  extractFinePrintInfoData = () => {
    const { data } = this.props;
    return { finePrint: data.finePrint };
  };

  extractOperationData = () => {
    const { data } = this.props;
    const defaultCost = {
      perCheckOut: 0,
      perDay: 0,
      perMonth: 0
    };
    const operationCost = {
      electricity: defaultCost,
      water: defaultCost,
      internet: defaultCost,
      cleaning: defaultCost,
      laundry: defaultCost,
      service: defaultCost,
      checkIn: defaultCost,
      checkOut: defaultCost,
      ...data.operationCost
    };
    const formattedOperationCost =
      operationCost &&
      Object.keys(operationCost).length > 0 &&
      Object.keys(operationCost).map(key => {
        return {
          key,
          ...defaultCost,
          ...operationCost[key]
        };
      });
    return formattedOperationCost;
  };

  extractContractInfo = () => {
    const { data } = this.props;
    return {
      selectedOwner: data.ownedBy ? data.ownedBy._id : undefined,
      contractPeriod: data.contractPeriod ? data.contractPeriod : {}
    };
  };

  extractPhotosData = () => {
    const { data } = this.props;
    return {
      photos:
        data.images && data.images.length > 0
          ? data.images.map(image => ({
              link: image.imageUrl,
              caption: image.caption[0] && image.caption[0].text ? image.caption[0].text : '',
              image
            }))
          : []
    };
  };

  extractDetailedDescriptions = () => {
    const { data } = this.props;
    const detailedDescriptions = {
      summary: '',
      space: '',
      access: '',
      interaction: '',
      neighborhoodOverview: '',
      transit: '',
      notes: '',
      houseRules: '',
      ...data.detailedDescription
    };
    return detailedDescriptions;
  };

  extractBookingEngineConfigDetailsData = () => {
    const { data } = this.props;
    return {
      bookingEngineDisplayName: guard(() => data.bookingEngine.name),
      bookingEngineCleaningFee: guard(() => generateDisplayFee(data.bookingEngine.priceDetail.cleaningFee, false))
    };
  };

  handleOnDocUploadFinish = e => {
    if (e.file.type === 'application/pdf') {
      this.setState(prevState => {
        const uploadedFile = {
          name: e.file.name,
          link: e.fileUrl
        };
        if (this.state.hostProtectDocFiles.length > 0) {
          const { hostProtectDocFiles } = prevState;
          hostProtectDocFiles.push(uploadedFile);
          return {
            hostProtectDocFiles: hostProtectDocFiles
          };
        }
        return {
          hostProtectDocFiles: [uploadedFile]
        };
      });
    } else {
      message.error('File uploaded must be in pdf format!');
    }
  };

  handleOnDocDelete = index => e => {
    e.preventDefault();
    this.setState(prevState => {
      const { hostProtectDocFiles } = prevState;
      hostProtectDocFiles.splice(index, 1);
      return {
        hostProtectDocFiles: hostProtectDocFiles
      };
    });
  };

  handleOnUploadFinish = e => {
    if (e.file.type === 'application/pdf') {
      this.setState(prevState => {
        const uploadedFile = {
          name: e.file.name,
          link: e.fileUrl
        };
        return {
          hostProtectCertFile: uploadedFile
        };
      });
    } else {
      message.error('File must be uploaded in pdf format!');
    }
  };

  handleOnFileDelete = e => {
    e.preventDefault();
    this.setState({
      hostProtectCertFile: {}
    });
  };

  handleOnChangeRoomTypeButtonClicked = () => {
    this.setState({ isChangeRoomTypeModalVisible: true });
  };

  handleOnCancelChangeRoomTypeModal = () => {
    this.setState({ isChangeRoomTypeModalVisible: false });
  };

  extractHostProtectData = () => {
    const { data } = this.props;
    let hostProtect = {};
    if (data.protection) {
      const hostProtectPayload = data.protection;
      hostProtect = {
        insuredName: hostProtectPayload.insured && hostProtectPayload.insured.insuredName,
        idNo: hostProtectPayload.insured && hostProtectPayload.insured.idNo,
        refCode: hostProtectPayload.insured && hostProtectPayload.insured.refCode,
        location: hostProtectPayload.location,
        certNo: hostProtectPayload.certNo,
        allRisk: hostProtectPayload.masterPolicyNos && hostProtectPayload.masterPolicyNos.allRisk,
        publicLiability: hostProtectPayload.masterPolicyNos && hostProtectPayload.masterPolicyNos.publicLiability,
        insuranceStartDate: hostProtectPayload.insuranceStartDate,
        insuranceEndDate: hostProtectPayload.insuranceEndDate
      };
    }
    return hostProtect;
  };

  formatOperationCost = formFields => {
    const operationCost = {
      checkIn:
        formFields.checkInPerCheckOut || formFields.checkInPerDay || formFields.checkInPerMonth
          ? {
              perCheckOut: formFields.checkInPerCheckOut ? parseFloat(formFields.checkInPerCheckOut) : null,
              perDay: formFields.checkInPerDay ? parseFloat(formFields.checkInPerDay) : null,
              perMonth: formFields.checkInPerMonth ? parseFloat(formFields.checkInPerMonth) : null,
              perMonthUpdatedAt: formFields.checkInPerMonth ? new Date() : null
            }
          : null,
      checkOut:
        formFields.checkOutPerCheckOut || formFields.checkOutPerDay || formFields.checkOutPerMonth
          ? {
              perCheckOut: formFields.checkOutPerCheckOut ? parseFloat(formFields.checkOutPerCheckOut) : null,
              perDay: formFields.checkOutPerDay ? parseFloat(formFields.checkOutPerDay) : null,
              perMonth: formFields.checkOutPerMonth ? parseFloat(formFields.checkOutPerMonth) : null,
              perMonthUpdatedAt: formFields.checkOutPerMonth ? new Date() : null
            }
          : null,
      cleaning:
        formFields.cleaningPerCheckOut || formFields.cleaningPerDay || formFields.cleaningPerMonth
          ? {
              perCheckOut: formFields.cleaningPerCheckOut ? parseFloat(formFields.cleaningPerCheckOut) : null,
              perDay: formFields.cleaningPerDay ? parseFloat(formFields.cleaningPerDay) : null,
              perMonth: formFields.cleaningPerMonth ? parseFloat(formFields.cleaningPerMonth) : null,
              perMonthUpdatedAt: formFields.cleaningPerMonth ? new Date() : null
            }
          : null,
      electricity:
        formFields.electricityPerCheckOut || formFields.electricityPerDay || formFields.electricityPerMonth
          ? {
              perCheckOut: formFields.electricityPerCheckOut ? parseFloat(formFields.electricityPerCheckOut) : null,
              perDay: formFields.electricityPerDay ? parseFloat(formFields.electricityPerDay) : null,
              perMonth: formFields.electricityPerMonth ? parseFloat(formFields.electricityPerMonth) : null,
              perMonthUpdatedAt: formFields.electricityPerMonth ? new Date() : null
            }
          : null,
      internet:
        formFields.internetPerCheckOut || formFields.internetPerDay || formFields.internetPerMonth
          ? {
              perCheckOut: formFields.internetPerCheckOut ? parseFloat(formFields.internetPerCheckOut) : null,
              perDay: formFields.internetPerDay ? parseFloat(formFields.internetPerDay) : null,
              perMonth: formFields.internetPerMonth ? parseFloat(formFields.internetPerMonth) : null,
              perMonthUpdatedAt: formFields.internetPerMonth ? new Date() : null
            }
          : null,
      laundry:
        formFields.laundryPerCheckOut || formFields.laundryPerDay || formFields.laundryPerMonth
          ? {
              perCheckOut: formFields.laundryPerCheckOut ? parseFloat(formFields.laundryPerCheckOut) : null,
              perDay: formFields.laundryPerDay ? parseFloat(formFields.laundryPerDay) : null,
              perMonth: formFields.laundryPerMonth ? parseFloat(formFields.laundryPerMonth) : null,
              perMonthUpdatedAt: formFields.laundryPerMonth ? new Date() : null
            }
          : null,
      service:
        formFields.servicePerCheckOut || formFields.servicePerDay || formFields.servicePerMonth
          ? {
              perCheckOut: formFields.servicePerCheckOut ? parseFloat(formFields.servicePerCheckOut) : null,
              perDay: formFields.servicePerDay ? parseFloat(formFields.servicePerDay) : null,
              perMonth: formFields.servicePerMonth ? parseFloat(formFields.servicePerMonth) : null,
              perMonthUpdatedAt: formFields.servicePerMonth ? new Date() : null
            }
          : null,
      water:
        formFields.waterPerCheckOut || formFields.waterPerDay || formFields.waterPerMonth
          ? {
              perCheckOut: formFields.waterPerCheckOut ? parseFloat(formFields.waterPerCheckOut) : null,
              perDay: formFields.waterPerDay ? parseFloat(formFields.waterPerDay) : null,
              perMonth: formFields.waterPerMonth ? parseFloat(formFields.waterPerMonth) : null,
              perMonthUpdatedAt: formFields.waterPerMonth ? new Date() : null
            }
          : null
    };
    delete formFields.checkInPerCheckOut;
    delete formFields.checkInPerDay;
    delete formFields.checkInPerMonth;
    delete formFields.checkOutPerCheckOut;
    delete formFields.checkOutPerDay;
    delete formFields.checkOutPerMonth;
    delete formFields.cleaningPerCheckOut;
    delete formFields.cleaningPerDay;
    delete formFields.cleaningPerMonth;
    delete formFields.electricityPerCheckOut;
    delete formFields.electricityPerDay;
    delete formFields.electricityPerMonth;
    delete formFields.internetPerCheckOut;
    delete formFields.internetPerDay;
    delete formFields.internetPerMonth;
    delete formFields.laundryPerCheckOut;
    delete formFields.laundryPerDay;
    delete formFields.laundryPerMonth;
    delete formFields.servicePerCheckOut;
    delete formFields.servicePerDay;
    delete formFields.servicePerMonth;
    delete formFields.waterPerCheckOut;
    delete formFields.waterPerDay;
    delete formFields.waterPerMonth;
    return {
      ...formFields,
      operationCost
    };
  };

  formatContractInformation = formFields => {
    const contractInfo = {
      ownedBy: formFields.selectedOwner ? formFields.selectedOwner : null,
      contractPeriod:
        formFields.contractPeriodStart && formFields.contractPeriodEnd
          ? {
              start: formFields.contractPeriodStart.format('YYYY-MM-DD'),
              end: formFields.contractPeriodEnd.format('YYYY-MM-DD')
            }
          : null
    };
    delete formFields.selectedOwner;
    delete formFields.contractPeriodStart;
    delete formFields.contractPeriodEnd;
    return {
      ...formFields,
      ...contractInfo
    };
  };

  formatImages = formFields => {
    return {
      ...formFields,
      photos: undefined,
      images:
        formFields.photos && formFields.photos.length > 0
          ? formFields.photos.map(data => ({
              ...data.image,
              imageUrl: data.link,
              caption: data.caption ? [{ text: data.caption }] : undefined
            }))
          : []
    };
  };

  formatRelationship = formFields => {
    if (!formFields.parent) {
      formFields.parent = null;
    }
    return {
      ...formFields
    };
  };

  formatDetailedDescription = formFields => {
    const detailedDescription = {
      summary: formFields.summary,
      space: formFields.space,
      neighborhoodOverview: formFields.neighborhoodOverview,
      transit: formFields.transit,
      access: formFields.access,
      notes: formFields.notes,
      houseRules: formFields.houseRules,
      interaction: formFields.interaction
    };
    delete formFields.summary;
    delete formFields.space;
    delete formFields.neighborhoodOverview;
    delete formFields.transit;
    delete formFields.access;
    delete formFields.notes;
    delete formFields.houseRules;
    delete formFields.interaction;
    return {
      ...formFields,
      detailedDescription
    };
  };

  formatHostProtectData = isAdmin => formFields => {
    const { hostProtectCertFile, hostProtectDocFiles } = this.state;

    if (!isAdmin) {
      return formFields;
    } else {
      const defaultHostProtectData = this.extractHostProtectData();

      // there is problem here
      // The problem is that once we insert value, it will keep on exisitng on the default data. If we clear it then cannot coz its OR operator, it will still use the value provided
      const hostProtectPayload = {
        insured: {
          insuredName: formFields.insuredName || defaultHostProtectData.insuredName,
          idNo: formFields.idNo || defaultHostProtectData.idNo,
          refCode: formFields.refCode || defaultHostProtectData.refCode
        },
        location: formFields.location || defaultHostProtectData.location,
        certNo: formFields.certNo || defaultHostProtectData.certNo,
        masterPolicyNos: {
          allRisk: formFields.allRisk || defaultHostProtectData.allRisk,
          publicLiability: formFields.publicLiability || defaultHostProtectData.publicLiability
        },
        insuranceStartDate:
          (formFields.insuranceStartDate && formFields.insuranceStartDate.format('YYYY-MM-DD')) || defaultHostProtectData.insuranceStartDate,
        insuranceEndDate:
          (formFields.insuranceEndDate && formFields.insuranceEndDate.format('YYYY-MM-DD')) || defaultHostProtectData.insuranceEndDate,
        file: hostProtectCertFile || defaultHostProtectData.file,
        files: hostProtectDocFiles || defaultHostProtectData.files
      };

      const hasHostProtect = !!hostProtectPayload.certNo;

      return {
        ...formFields,
        ...(hasHostProtect && { protection: hostProtectPayload })
      };
    }
  };

  formatBookingEngineConfigDetails = formFields => {
    return {
      ...formFields,
      bookingEngine: {
        name: formFields.bookingEngineDisplayName,
        priceDetail: {
          cleaningFee: generateFeeFromDisplay(formFields.bookingEngineCleaningFee)
        }
      }
    };
  };

  handleOnSave = e => {
    e.preventDefault();
    const { form, data, checkIsAdmin } = this.props;
    form.validateFieldsAndScroll(undefined, { force: true }, (err, values) => {
      if (!err) {
        const payload = [values]
          .map(this.formatOperationCost)
          .map(this.formatContractInformation)
          .map(this.formatImages)
          .map(this.formatDetailedDescription)
          .map(this.formatBookingEngineConfigDetails)
          .map(this.formatHostProtectData(checkIsAdmin()))
          .map(this.formatRelationship)[0];
        this.setState({
          isSaving: true
        });
        return putUpdateUnit(data._id, payload)
          .then(data => {
            updateParentChild(data._id, payload.children)
              .then(done => {
                this.setState({
                  isSaving: false
                });
                this.fetchRelationships();
                notification.success({
                  message: 'Your unit has been updated!'
                });
              })
              .catch(ex => {
                if (ex.message) {
                  notification.error({
                    message: ex.message
                  });
                } else {
                  notification.error({
                    message: 'Something went wrong when trying to update children.'
                    // description: e.message
                  });
                }
              });
          })
          .catch(e => {
            if (e.message) {
              notification.error({
                message: e.message
              });
            } else {
              notification.error({
                message: 'Something went wrong and your unit is not updated.'
              });
            }
          })
          .finally(() => {
            this.setState({
              isSaving: false
            });
          });
      } else {
        notification.error({
          message: err[Object.keys(err)[0]].errors[0].message
        });
      }
    });
  };

  onDeleteUnit = () => {
    const { data, history } = this.props;
    this.setState({
      isDeleting: true
    });
    deleteRemoveUnit(data._id)
      .then(() => {
        this.setState({
          isDeleting: false
        });
        notification.success({
          message: 'Your unit has been deleted'
        });
        history.push(buildListingUri());
      })
      .catch(err => {
        this.setState({
          isDeleting: false
        });
        if (err.response && err.response.status && err.response.status === 400) {
          const errorRes = err.response.data || {};
          this.unitDeleteErrorMessage(errorRes);
        } else {
          message.error('Oops! Something went wrong. Please try again later.');
        }
      });
  };

  unitDeleteErrorMessage = errorRes => {
    const { data } = this.props;
    const isUnitInRelationship = errorRes.isUnitInRelationship;
    const servicePackageName = errorRes.servicePackage && errorRes.servicePackage.name;
    const servicePackageID = errorRes.servicePackage && errorRes.servicePackage._id;
    const syncedOtas = errorRes.syncedOtas;

    const servicePackageErrMsg = servicePackageName && (
      <span>
        packaged under{' '}
        <a href={buildServicePackageEditUri(servicePackageID)} target="_blank" rel="noopener noreferrer">
          <b>{servicePackageName}</b>
        </a>{' '}
        service package
      </span>
    );

    const syncedOtasErrMsg = !!syncedOtas && Array.isArray(syncedOtas) && syncedOtas.length > 0 && (
      <span>
        synced with{' '}
        <b>
          <a href={buildIOTAFormUri({ propertyId: data.roomType.property._id, unitId: data._id })} target="_blank" rel="noopener noreferrer">
            {syncedOtas
              .map(syncedOta => capitalizeFirstLetter(syncedOta))
              .reduce((syncedOtasErrMsg, source, i) => `${syncedOtasErrMsg}${i === syncedOtas.length - 1 ? ' and ' : ', '}${source}`)}
          </a>
        </b>
      </span>
    );

    let actionIndex = 0;

    Modal.error({
      title: 'Unable to delete this unit',
      content: (
        <div>
          <p>
            This unit is {isUnitInRelationship && `having parent-child relation`}
            {isUnitInRelationship && servicePackageName && ` and `}
            {servicePackageErrMsg}
            {(isUnitInRelationship || servicePackageName) && syncedOtasErrMsg && ` and `}
            {syncedOtasErrMsg}.
          </p>
          <p>
            To delete this unit, please:
            {isUnitInRelationship && (
              <div>
                {++actionIndex}) Unlink the <b>parent-child unit(s)</b>
              </div>
            )}
            {servicePackageName && (
              <div>
                {++actionIndex}) Remove it from the{' '}
                <a href={buildServicePackageEditUri(servicePackageID)} target="_blank" rel="noopener noreferrer">
                  <b>service package</b>
                </a>
              </div>
            )}
            {syncedOtasErrMsg && (
              <div>
                {++actionIndex}) Desync all{' '}
                <a href={buildIOTAFormUri({ propertyId: data.roomType.property._id, unitId: data._id })} target="_blank" rel="noopener noreferrer">
                  <b>listing OTAs</b>
                </a>
              </div>
            )}
            .
          </p>
        </div>
      )
    });
  };

  handleOnDelete = e => {
    e.preventDefault();

    const onOkAction = this.onDeleteUnit;

    Modal.confirm({
      title: 'Are you sure you want to delete this unit?',
      onOk() {
        return onOkAction();
      },
      onCancel() {},
      okButtonProps: { id: 'del-cfm-button3a-editlisting' },
      cancelButtonProps: { id: 'canceldel-cfm-button3b-editlisting' }
    });
  };

  handleOnAfterSaveRoomTypeModal = () => {
    window.location.reload();
  };

  render() {
    const { form, hasFetchedUnit, checkIsAdmin, checkIsAllowDeleteListing, allowBookingEngine, currency, checkIsAdminReadOnly } = this.props;

    const {
      owners,
      hasFetchedOwners,
      isSaving,
      isDeleting,
      isChangeRoomTypeModalVisible,
      relationship,
      potentialParents,
      potentialChild,
      hostProtectCertFile,
      hostProtectDocFiles
    } = this.state;

    const updateRoomTypeButton = (
      <Button type="primary" onClick={this.handleOnChangeRoomTypeButtonClicked} disabled={checkIsAdminReadOnly()}>
        Change Room Type
      </Button>
    );

    return (
      <div>
        <ChangeRoomTypeModal
          form={form}
          isVisible={isChangeRoomTypeModalVisible}
          onCancel={this.handleOnCancelChangeRoomTypeModal}
          unit={this.props.data}
          shouldAllowChangeProperty={relationship && relationship.type === SOLO_RELATIONSHIP}
          onAfterSave={this.handleOnAfterSaveRoomTypeModal}
        />
        <Form onSubmit={this.handleOnSave} style={{ width: '100%' }}>
          <Tabs type="card" className={styles.unitTab} defaultActiveKey="1" tabBarExtraContent={updateRoomTypeButton}>
            <TabPane tab="General Details" key="1" className={styles.unitTabPane} forceRender={hasFetchedUnit}>
              <BasicDetailsCard form={form} cardClassname={styles.cardContainer} defaultValues={this.extractBasicDetailsData()} />
              <FinePrintInfoCard form={form} cardClassname={styles.cardContainer} defaultValues={this.extractFinePrintInfoData()} />
              <OperationCostsCard form={form} cardClassname={styles.cardContainer} defaultValues={this.extractOperationData()} currency={currency} />
              <ContractInfoCard
                form={form}
                cardClassname={styles.cardContainer}
                defaultValues={this.extractContractInfo()}
                owners={owners}
                hasFetchedOwners={hasFetchedOwners}
              />
              <PhotosCard
                form={form}
                defaultValues={this.extractPhotosData()}
                cardClassname={styles.cardContainer}
                hasFetchedPhotos={hasFetchedUnit}
              />
            </TabPane>
            <TabPane
              tab="Detailed Description"
              key="2"
              className={styles.unitTabPane}
              forceRender={Object.keys(this.extractDetailedDescriptions()).length > 0}
            >
              <DetailedDescriptionCard form={form} defaultValues={this.extractDetailedDescriptions()} cardClassname={styles.cardContainer} />
            </TabPane>

            <TabPane
              tab="Child/Parent Units"
              key="3"
              className={styles.unitTabPane}
              forceRender={!!relationship && Object.keys(relationship).length > 0}
            >
              <RelationshipCard form={form} relationship={relationship} potentialParents={potentialParents} potentialChild={potentialChild} />
            </TabPane>

            <TabPane tab="HostProtect" key="4" className={styles.unitTabPane}>
              <HostProtectCard
                form={form}
                hostProtectCertFile={hostProtectCertFile}
                handleOnUploadFinish={this.handleOnUploadFinish}
                handleOnFileDelete={this.handleOnFileDelete}
                hostProtectDocFiles={hostProtectDocFiles}
                handleOnDocUploadFinish={this.handleOnDocUploadFinish}
                handleOnDocDelete={this.handleOnDocDelete}
                defaultValues={this.extractHostProtectData()}
                shouldDisable={!checkIsAdmin()}
                cardClassname={styles.cardContainer}
              />
            </TabPane>

            {allowBookingEngine === true && (
              <TabPane tab="Booking Website Configuration" key="5" className={styles.unitTabPane} forceRender={hasFetchedUnit}>
                <BookingEngineConfigDetailsCard
                  form={form}
                  cardClassname={styles.cardContainer}
                  defaultValues={this.extractBookingEngineConfigDetailsData()}
                />
              </TabPane>
            )}
          </Tabs>
          <Row type="flex" justify="start" gutter={8}>
            <Col>
              <Button
                id="save-button3-editlisting"
                type="primary"
                size="large"
                htmlType="submit"
                loading={isSaving}
                disabled={checkIsAdminReadOnly()}
              >
                {isSaving ? 'Saving' : 'Save'}
              </Button>
            </Col>
            <Col>
              {checkIsAllowDeleteListing() && (
                <Button
                  id="del-button3-editlisting"
                  type="danger"
                  size="large"
                  onClick={this.handleOnDelete}
                  loading={isDeleting}
                  disabled={checkIsAdminReadOnly()}
                >
                  Delete
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

Unit.propTypes = {
  checkIsAdmin: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  data: PropTypes.object,
  hasFetchedUnit: PropTypes.bool,
  allowBookingEngine: PropTypes.bool,
  currency: PropTypes.string
};

Unit.defaultProps = {
  checkIsAdmin: () => {},
  data: {},
  hasFetchedUnit: false,
  allowBookingEngine: false,
  currency: 'RM'
};

const WrappedFormUnit = Form.create()(Unit);

export default withRouter(withAppContext(WrappedFormUnit));
