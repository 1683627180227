import React from 'react';
import PropTypes from 'prop-types';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

const RateFormInputNumber = ({ form, name, label, isDisabled, currency = 'RM' }) => {
  const rateMinValue = 0;
  const ratePrecision = 2;
  const rateFormatter = value => `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const rateParser = value => value.replace(/[^0-9.]/g, '');

  return (
    <FormInputNumber
      form={form}
      name={name}
      label={label}
      requiredErrorMessage="Please enter an amount"
      minValue={rateMinValue}
      precision={ratePrecision}
      formatter={rateFormatter}
      parser={rateParser}
      disabled={isDisabled}
    />
  );
};

RateFormInputNumber.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  isDisabled: PropTypes.bool
};

export default RateFormInputNumber;
