import React, { Component } from 'react';
import { Button, Card, Form, Icon, Message, Modal, Row, Select, Skeleton } from 'antd';
import queryString from 'query-string';
import moment from 'moment';

import { getPackageTypesConstant, getRevenueShareConstant, getIntegrationSourcesConstant } from 'utils/apis/constants';
import { getHostsForPayout } from 'utils/apis/host';
import { getConstants } from 'utils/apis/constants';
import {
  getPayoutDetail,
  getPayoutCalendar,
  getPayoutStatistic,
  getPayoutExpensesAttachment,
  getIsPayoutFreeze,
  freezePayout
} from 'utils/apis/payout';

import { BLANK_IMAGE, MONTH_FORMAT } from 'utils/constants';
import { errorHandlerWrapper } from 'utils/general';
import { buildPayoutUri } from 'utils/routes';

import Header from './components/Header/Header';
import Body from './components/Body/Body';
import styles from './Payout.module.css';
import { withAppContext } from 'context/AppContext';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

const constructIntegrationSourceConstants = async () => {
  return errorHandlerWrapper(
    getIntegrationSourcesConstant().then(constantsInObject => {
      return Object.values(constantsInObject);
    }),
    []
  );
};

const constructPackageTypeConstants = async () => {
  return errorHandlerWrapper(
    getPackageTypesConstant().then(constantsInObject => {
      return Object.values(constantsInObject);
    }),
    []
  );
};

const constructRevenueShareConstants = async () => {
  return errorHandlerWrapper(
    getRevenueShareConstant().then(constantsInObject => {
      return Object.values(constantsInObject);
    }),
    []
  );
};

const constructDefaultHostOptions = async selectedHostId => {
  const constructHostOptions = () => {
    return errorHandlerWrapper(
      getHostsForPayout().then(hosts =>
        hosts.map(host => {
          const hostImage = host.images[0] && host.images[0].imageUrl ? host.images[0].imageUrl : BLANK_IMAGE;
          const hostOwners =
            !!host.owners && host.owners.length > 0
              ? host.owners.map(owner => {
                  return {
                    value: owner._id,
                    label: `${owner.userProfile.firstName} ${owner.userProfile.lastName}`
                  };
                })
              : [];

          return {
            value: host._id,
            label: host.name,
            image: hostImage,
            owners: hostOwners,
            currency: host.currency
          };
        })
      ),
      []
    );
  };

  const hostOptions = await constructHostOptions();

  let firstHostId, firstHostImage, firstHostOwners, currency;

  if (selectedHostId) {
    const foundHost = hostOptions.find(hostOption => String(hostOption.value) === String(selectedHostId));
    firstHostId = selectedHostId;
    firstHostImage = foundHost.image || BLANK_IMAGE;
    firstHostOwners = foundHost.owners || [];
    currency = foundHost.currency;
  } else {
    firstHostId = hostOptions.length > 0 ? hostOptions[0].value : '';
    firstHostImage = hostOptions.length > 0 ? hostOptions[0].image : BLANK_IMAGE;
    firstHostOwners = hostOptions.length > 0 ? hostOptions[0].owners : [];
    currency = hostOptions.length > 0 ? hostOptions[0].currency : 'RM';
  }

  return { hostOptions, firstHostId, firstHostImage, firstHostOwners, currency };
};

const constructDefaultOwnerOptions = async (hostOwners, selectedOwnerId) => {
  const ownerOptions = hostOwners;
  let firstOwnerId, firstOwnerName;
  if (selectedOwnerId) {
    const foundOwner = ownerOptions.find(ownerOption => String(ownerOption.value) === String(selectedOwnerId));
    firstOwnerId = selectedOwnerId || '';
    firstOwnerName = foundOwner ? (foundOwner.label ? foundOwner.label : '') : '';
  } else {
    firstOwnerId = ownerOptions.length > 0 ? ownerOptions[0].value : '';
    firstOwnerName = ownerOptions.length > 0 ? ownerOptions[0].label : '';
  }

  return { ownerOptions, firstOwnerId, firstOwnerName };
};

const constructPayoutDetails = (hostId, ownerId, year, month) => {
  return errorHandlerWrapper(
    getPayoutDetail(hostId, ownerId, year, month).then(payouts => {
      const payoutDetails = payouts.map(payout => {
        return {
          year,
          month,
          totalNetRevenue: payout.totalRevenue,
          numberOfBookings: payout.reservations.length,
          totalExpenses: payout.totalExpenses,
          hostExpenses: payout.totalExpenses - payout.ownerExpenses,
          shouldHideCalendar: payout.hidePayout,
          ...payout
        };
      });
      return payoutDetails;
    }),
    []
  );
};

const constructPayoutCalendars = (hostId, ownerId, year, month) => {
  return errorHandlerWrapper(
    getPayoutCalendar(hostId, ownerId, year, month).then(payoutCalendar => {
      return {
        year,
        month: parseInt(month, 10) + 1,
        payoutCalendars: payoutCalendar
      };
    }),
    {}
  );
};

const constructPayoutGraph = (hostId, ownerId, year, month) => {
  return errorHandlerWrapper(
    getPayoutStatistic(hostId, ownerId, year, month).then(payoutGraphData => {
      return payoutGraphData;
    }),
    {}
  );
};

const constructPayoutExpensesAttachment = (hostId, ownerId, year, month) => {
  return errorHandlerWrapper(
    getPayoutExpensesAttachment(hostId, ownerId, year, month).then(payoutCalendar => {
      return {
        year,
        month: parseInt(month, 10) + 1,
        payoutCalendars: payoutCalendar
      };
    }),
    {}
  );
};

const constructReadableYearMonthFormat = (year, month) => {
  if (year && month > -1) {
    return month < 9 ? `${year}-0${month + 1}` : `${year}-${month + 1}`;
  } else {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    return month < 9 ? `${year}-0${month + 1}` : `${year}-${month + 1}`;
  }
};

const fetchIsPayoutFreeze = (hostId, ownerId, date) => {
  return getIsPayoutFreeze(hostId, ownerId, date);
};
class Payout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHeaderLoading: false,
      isPayoutDetailsLoading: false,
      isPayoutCalendarLoading: false,
      isPayoutGraphLoading: false,
      isPayoutExpensesAttachmentLoading: false,

      year: new Date().getFullYear(),
      month: new Date().getMonth(),

      integrationSourceConstants: [],
      packageTypeConstants: [],
      revenueShareConstants: [],

      hostOptions: [],
      ownerOptions: [],

      selectedHostId: '',
      selectedHostImage: '',
      selectedOwnerId: '',
      selectedOwnerName: '',
      currency: 'RM',

      payoutDetails: [],
      payoutCalendarData: {},
      payoutGraphData: {},
      payoutExpensesAttachment: [],

      freezePayoutData: {
        payoutDetails: [],
        payoutCalendarData: {},
        payoutGraphData: {},
        payoutExpensesAttachment: []
      },
      isPayoutFreeze: false,
      isShowBulkAuditPayoutModal: false,
      auditedOwnerIds: [],
      activityLog: [],
      latestPayoutLog: [],
      isSaving: false,
      isOwner: false
    };
  }

  componentDidMount = async () => {
    const { location, form } = this.props;
    const queryStringDetails = location.search && queryString.parse(location.search);

    getConstants('permissions').then(res => {
      const authObj = JSON.parse(localStorage.getItem('auth'));
      if (res && res.status === 200) {
        this.setState({
          isOwner: authObj.user.isAdmin ? false : authObj.user.roles[0].name === 'Owner' ? true : false
        });
      } else {
        console.log('Error getting permissions constants.');
      }
    });

    const constructDefaultConstants = async () => {
      const integrationSourceConstants = await constructIntegrationSourceConstants();
      const packageTypeConstants = await constructPackageTypeConstants();
      const revenueShareConstants = await constructRevenueShareConstants();

      return { integrationSourceConstants, packageTypeConstants, revenueShareConstants };
    };

    const constructDefaultHeaderData = async (selectedHostId, selectedOwnerId) => {
      const { hostOptions, firstHostId, firstHostImage, firstHostOwners, currency } = await constructDefaultHostOptions(selectedHostId);
      const { ownerOptions, firstOwnerId, firstOwnerName } = await constructDefaultOwnerOptions(firstHostOwners, selectedOwnerId);

      return { hostOptions, ownerOptions, firstHostId, firstHostImage, firstOwnerId, firstOwnerName, currency };
    };

    const constructDefaultBodyData = async (hostId, ownerId, date = '') => {
      const hasHostAndOwner = !!hostId && !!ownerId;
      const year = date ? moment(date).year() : new Date().getFullYear();
      const month = date ? moment(date).month() : new Date().getMonth();
      const payoutDetails = hasHostAndOwner ? await constructPayoutDetails(hostId, ownerId, year, month) : [];
      const payoutCalendarData = hasHostAndOwner ? await constructPayoutCalendars(hostId, ownerId, year, month) : {};
      const payoutGraphData = hasHostAndOwner ? await constructPayoutGraph(hostId, ownerId, year, month) : {};
      const payoutExpensesAttachment = hasHostAndOwner ? await constructPayoutExpensesAttachment(hostId, ownerId, year, month) : [];
      return { year, month, payoutDetails, payoutCalendarData, payoutGraphData, payoutExpensesAttachment };
    };

    this.setState({
      isHeaderLoading: true,
      isPayoutDetailsLoading: true,
      isPayoutCalendarLoading: true,
      isPayoutGraphLoading: true,
      isPayoutExpensesAttachmentLoading: true
    });

    const { integrationSourceConstants, packageTypeConstants, revenueShareConstants } = await constructDefaultConstants();
    const { hostOptions, ownerOptions, firstHostId, firstHostImage, firstOwnerId, firstOwnerName, currency } = await constructDefaultHeaderData(
      queryStringDetails.host,
      queryStringDetails.owner
    );

    const currentDate = queryStringDetails ? moment(queryStringDetails.date).format('YYYY-MM') : new moment(Date()).format('YYYY-MM');
    const isFreezePayout = await fetchIsPayoutFreeze(firstHostId, firstOwnerId, currentDate);

    const { year, month, payoutDetails, payoutCalendarData, payoutGraphData, payoutExpensesAttachment } = await constructDefaultBodyData(
      firstHostId,
      firstOwnerId,
      queryStringDetails.date
    );

    this.setURLQuery({ hostId: firstHostId, ownerId: firstOwnerId, date: queryStringDetails.date });

    form.setFields({
      ownerOptions: { value: isFreezePayout.Ids }
    });

    this.setState({
      isHeaderLoading: false,
      isPayoutDetailsLoading: false,
      isPayoutCalendarLoading: false,
      isPayoutGraphLoading: false,
      isPayoutExpensesAttachmentLoading: false,
      year,
      month,
      integrationSourceConstants,
      packageTypeConstants,
      revenueShareConstants,
      hostOptions,
      ownerOptions,
      selectedHostId: firstHostId,
      selectedHostImage: firstHostImage,
      selectedOwnerId: firstOwnerId,
      selectedOwnerName: firstOwnerName,
      payoutDetails,
      payoutCalendarData,
      payoutGraphData,
      payoutExpensesAttachment,
      isPayoutFreeze: isFreezePayout.data,
      auditedOwnerIds: isFreezePayout.Ids,
      activityLog: isFreezePayout.activityLog,
      latestPayoutLog: isFreezePayout.latestPayoutLog,
      currency: currency
    });
  };

  setURLQuery = ({ hostId, ownerId, date }) => {
    const { month, year, selectedHostId, selectedOwnerId } = this.state;
    const { history } = this.props;

    if (!hostId) {
      hostId = selectedHostId || '';
    }
    if (!ownerId) {
      ownerId = selectedOwnerId || '';
    }

    if (!date) {
      date = constructReadableYearMonthFormat(year, month);
    }
    const monthWithYearInString = moment(date).format(MONTH_FORMAT);
    history.replace(buildPayoutUri({ host: hostId, owner: ownerId, date: monthWithYearInString }));
  };

  fetchIsPayoutFreeze = (hostId, ownerId, date) => {
    return getIsPayoutFreeze(hostId, ownerId, date).then(res => {
      this.setState({ isPayoutFreeze: res.data });
    });
  };

  handleOnPayoutFreeze = async () => {
    this.setState({ isHeaderLoading: true });
    const { year, month, selectedHostId, selectedOwnerId, isPayoutFreeze } = this.state;
    const selectedDate = new moment(`1/${month + 1}/${year}`, 'D-MM-YYYY');
    return freezePayout({
      hostId: selectedHostId,
      ownerId: selectedOwnerId,
      month: month,
      year: year,
      date: selectedDate.format('YYYY-MM'),
      isFreezePayout: isPayoutFreeze,
      data: this.state.freezePayoutData
    }).then(res => {
      this.fetchIsPayoutFreeze(selectedHostId, selectedOwnerId, selectedDate.format('YYYY-MM'));
      this.setState({
        isHeaderLoading: false
      });
    });
  };

  handleOnToggleModal = () => {
    const { isShowBulkAuditPayoutModal } = this.state;
    return this.setState({
      isShowBulkAuditPayoutModal: !isShowBulkAuditPayoutModal
    });
  };

  handleOnMonthChange = async (year, month) => {
    const { form } = this.props;
    const { selectedHostId, selectedOwnerId } = this.state;
    const selectedDate = new moment(`1/${month + 1}/${year}`, 'D-MM-YYYY');

    this.setState({ isPayoutDetailsLoading: true, isPayoutCalendarLoading: true, isPayoutGraphLoading: true });

    const isFreezePayout = await fetchIsPayoutFreeze(selectedHostId, selectedOwnerId, selectedDate.format('YYYY-MM'));
    const payoutDetails = await constructPayoutDetails(selectedHostId, selectedOwnerId, year, month);
    const payoutCalendarData = await constructPayoutCalendars(selectedHostId, selectedOwnerId, year, month);
    const payoutGraphData = await constructPayoutGraph(selectedHostId, selectedOwnerId, year, month);
    const payoutExpensesAttachment = await constructPayoutExpensesAttachment(selectedHostId, selectedOwnerId, year, month);

    this.setURLQuery({ hostId: selectedHostId, ownerId: selectedOwnerId, date: constructReadableYearMonthFormat(year, month) });
    form.setFields({
      ownerOptions: { value: isFreezePayout.Ids }
    });
    this.setState({
      isPayoutDetailsLoading: false,
      isPayoutCalendarLoading: false,
      isPayoutGraphLoading: false,
      year,
      month,
      payoutDetails,
      payoutCalendarData,
      payoutGraphData,
      payoutExpensesAttachment,
      isPayoutFreeze: isFreezePayout.data,
      auditedOwnerIds: isFreezePayout.Ids,
      activityLog: isFreezePayout.activityLog,
      latestPayoutLog: isFreezePayout.latestPayoutLog
    });
  };

  handleOnHostChange = async (currentHostId, currentHostImage, currentHostOwners) => {
    const { form } = this.props;
    const { year, month, hostOptions } = this.state;
    const selectedDate = new moment(`1/${month + 1}/${year}`, 'D-MM-YYYY');

    this.setState({ isHeaderLoading: true, isPayoutDetailsLoading: true, isPayoutCalendarLoading: true, isPayoutGraphLoading: true });

    const { ownerOptions, firstOwnerId, firstOwnerName } = await constructDefaultOwnerOptions(currentHostOwners);

    const hasOwner = !!firstOwnerId;
    const isFreezePayout = hasOwner ? await fetchIsPayoutFreeze(currentHostId, firstOwnerId, selectedDate.format('YYYY-MM')) : {};
    const payoutDetails = hasOwner ? await constructPayoutDetails(currentHostId, firstOwnerId, year, month) : [];
    const payoutCalendarData = hasOwner ? await constructPayoutCalendars(currentHostId, firstOwnerId, year, month) : {};
    const payoutGraphData = hasOwner ? await constructPayoutGraph(currentHostId, firstOwnerId, year, month) : {};
    const payoutExpensesAttachment = hasOwner ? await constructPayoutExpensesAttachment(currentHostId, firstOwnerId, year, month) : {};

    const foundHost = hostOptions.find(hostOption => String(hostOption.value) === String(currentHostId));

    this.setURLQuery({ hostId: currentHostId, ownerId: firstOwnerId });
    form.setFields({
      ownerOptions: { value: isFreezePayout.Ids }
    });
    this.setState({
      isHeaderLoading: false,
      isPayoutDetailsLoading: false,
      isPayoutCalendarLoading: false,
      isPayoutGraphLoading: false,
      ownerOptions,
      selectedHostId: currentHostId,
      selectedHostImage: currentHostImage,
      selectedOwnerId: firstOwnerId,
      selectedOwnerName: firstOwnerName,
      payoutDetails,
      payoutCalendarData,
      payoutGraphData,
      payoutExpensesAttachment,
      isPayoutFreeze: isFreezePayout.data,
      auditedOwnerIds: isFreezePayout.Ids,
      activityLog: isFreezePayout.activityLog,
      latestPayoutLog: isFreezePayout.latestPayoutLog,
      currency: foundHost.currency
    });
  };

  handleOnOwnerChange = async (currentOwnerId, currentOwnerName) => {
    const { form } = this.props;
    const { year, month, selectedHostId } = this.state;
    const selectedDate = new moment(`1/${month + 1}/${year}`, 'D-MM-YYYY');

    this.setState({ isPayoutDetailsLoading: true, isPayoutCalendarLoading: true, isPayoutGraphLoading: true });

    const isFreezePayout = await fetchIsPayoutFreeze(selectedHostId, currentOwnerId, selectedDate.format('YYYY-MM'));
    const payoutDetails = await constructPayoutDetails(selectedHostId, currentOwnerId, year, month);
    const payoutCalendarData = await constructPayoutCalendars(selectedHostId, currentOwnerId, year, month);
    const payoutGraphData = await constructPayoutGraph(selectedHostId, currentOwnerId, year, month);
    const payoutExpensesAttachment = await constructPayoutExpensesAttachment(selectedHostId, currentOwnerId, year, month);

    this.setURLQuery({ hostId: selectedHostId, ownerId: currentOwnerId });
    form.setFields({
      ownerOptions: { value: isFreezePayout.Ids }
    });
    this.setState({
      isPayoutDetailsLoading: false,
      isPayoutCalendarLoading: false,
      isPayoutGraphLoading: false,
      selectedOwnerId: currentOwnerId,
      selectedOwnerName: currentOwnerName,
      payoutDetails,
      payoutCalendarData,
      payoutGraphData,
      payoutExpensesAttachment,
      isPayoutFreeze: isFreezePayout.data,
      auditedOwnerIds: isFreezePayout.Ids,
      activityLog: isFreezePayout.activityLog,
      latestPayoutLog: isFreezePayout.latestPayoutLog
    });
  };

  constructOwnerDetails = (ownerOptions, ownerId) => {
    let ownerName;
    if (ownerId) {
      const foundOwner = ownerOptions.find(ownerOption => String(ownerOption.value) === String(ownerId));
      ownerName = foundOwner ? (foundOwner.label ? foundOwner.label : '') : '';
    }

    return ownerName;
  };

  handleOnFormSubmit = e => {
    e.preventDefault();
    const { year, month, selectedHostId, freezePayoutData, auditedOwnerIds } = this.state;
    const selectedDate = new moment(`1/${month + 1}/${year}`, 'D-MM-YYYY');
    const handleOnIsSaving = boolean => {
      this.setState({
        isSaving: boolean,
        isShowBulkAuditPayoutModal: boolean
      });
    };

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const ownerIds = values.ownerOptions || [];
        const payoutTobeCreated = ownerIds.filter(item => !auditedOwnerIds.includes(item));
        const payoutToBeDeleted = auditedOwnerIds.filter(item => !ownerIds.includes(item));
        Modal.confirm({
          icon: <Icon type="warning" />,
          title: `Confirm bulk audit payout ${moment(month + 1 + '/' + year, 'M/YYYY').format('MMMM YYYY')} payout?`,
          content: `This action will overwrite any existing data.`,
          onOk() {
            handleOnIsSaving(true);
            return Promise.all([
              payoutTobeCreated.map(ownerId => {
                return freezePayout({
                  hostId: selectedHostId,
                  ownerId: ownerId,
                  month: month,
                  year: year,
                  date: selectedDate.format('YYYY-MM'),
                  isFreezePayout: false,
                  data: freezePayoutData
                }).catch(ex => {
                  Message.error(ex.toString());
                });
              }),
              payoutToBeDeleted.map(ownerId => {
                return freezePayout({
                  hostId: selectedHostId,
                  ownerId: ownerId,
                  month: month,
                  year: year,
                  date: selectedDate.format('YYYY-MM'),
                  isFreezePayout: true,
                  data: freezePayoutData
                }).catch(ex => {
                  Message.error(ex.toString());
                });
              })
            ])
              .then(results => {
                Message.loading('Saving bulk audit payout...');
                let progress = 0;
                results[0].forEach(p => p.then(() => progress++));
                results[1].forEach(p => p.then(() => progress++));

                setInterval(() => {
                  Message.loading(((progress / (results[0].length + results[1].length)) * 100).toFixed(0) + '%');
                  if ((progress / (results[0].length + results[1].length)) * 100 === 100) {
                    setTimeout(() => {
                      Message.success('Successfully saved bulk audit payout! Your page will be reloaded shortly');
                    }, 3000);
                    setTimeout(() => {
                      handleOnIsSaving(false);
                      document.location.reload();
                    }, 5000);
                    return;
                  }
                }, 3000);
              })

              .catch(err => {
                console.log(err);
                handleOnIsSaving(false);
              });
            // .finally(res => {
            //   console.log(559, res);
            //   // setTimeout(() => {
            //   //   Message.success('Successfully saved bulk audit payout!');
            //   // }, 2000);
            //   // setTimeout(() => {
            //   //   handleOnIsSaving(false);
            //   //   document.location.reload();
            //   // }, 3000);
            // });
          },
          onCancel() {
            handleOnIsSaving(false);
          }
        });
      }
    });
  };

  render() {
    const {
      isHeaderLoading,
      isPayoutDetailsLoading,
      isPayoutCalendarLoading,
      isPayoutGraphLoading,
      isPayoutExpensesAttachmentLoading,
      year,
      month,
      integrationSourceConstants,
      packageTypeConstants,
      revenueShareConstants,
      hostOptions,
      ownerOptions,
      selectedHostId,
      selectedHostImage,
      selectedOwnerId,
      selectedOwnerName,
      payoutDetails,
      payoutCalendarData,
      payoutGraphData,
      payoutExpensesAttachment,
      isPayoutFreeze,
      auditedOwnerIds,
      isShowBulkAuditPayoutModal,
      activityLog,
      latestPayoutLog,
      isSaving,
      isOwner,
      currency
    } = this.state;

    const { form, checkIsAdminReadOnly } = this.props;

    return (
      <Card className={styles.card}>
        <Skeleton loading={isHeaderLoading} active>
          <Row>
            <Header
              hostOptions={hostOptions}
              ownerOptions={ownerOptions}
              selectedHostId={selectedHostId}
              selectedOwnerId={selectedOwnerId}
              selectedOwnerName={selectedOwnerName}
              defaultDateValue={moment(constructReadableYearMonthFormat(year, month))}
              onMonthChange={this.handleOnMonthChange}
              onHostChange={this.handleOnHostChange}
              onOwnerChange={this.handleOnOwnerChange}
              isPayoutFreeze={isPayoutFreeze}
              handleOnPayoutFreeze={this.handleOnPayoutFreeze}
              month={month}
              year={year}
              handleOnToggleModal={this.handleOnToggleModal}
              isOwner={isOwner}
              checkIsAdminReadOnly={checkIsAdminReadOnly}
            />
          </Row>

          <Row>
            <Skeleton loading={isPayoutDetailsLoading} active>
              <Body
                isPayoutDetailsLoading={isPayoutDetailsLoading}
                isPayoutCalendarLoading={isPayoutCalendarLoading}
                isPayoutGraphLoading={isPayoutGraphLoading}
                isPayoutExpensesAttachmentLoading={isPayoutExpensesAttachmentLoading}
                year={year}
                month={month}
                integrationSourceConstants={integrationSourceConstants}
                packageTypeConstants={packageTypeConstants}
                revenueShareConstants={revenueShareConstants}
                selectedHostImage={selectedHostImage}
                selectedOwnerName={selectedOwnerName}
                payoutDetails={payoutDetails}
                payoutCalendarData={payoutCalendarData}
                payoutGraphData={payoutGraphData}
                payoutExpensesAttachment={payoutExpensesAttachment}
                activityLog={activityLog}
                ownerOptions={ownerOptions}
                isOwner={isOwner}
                currency={currency}
              />
              <Modal
                title={`Bulk Audit Payout for ${moment(month + 1 + '/' + year, 'M/YYYY').format('MMMM YYYY')}`}
                style={{ minHeight: '80vh', minWidth: '200px', overflow: 'hidden' }}
                width="50vw"
                visible={isShowBulkAuditPayoutModal}
                onCancel={this.handleOnToggleModal}
                footer={[
                  <Button key="back" onClick={this.handleOnToggleModal}>
                    Cancel
                  </Button>,
                  <Button key="submit" onClick={this.handleOnFormSubmit} type="primary" loading={isSaving}>
                    Save
                  </Button>
                ]}
              >
                <FormItem label="Select the owner you wish to audit">
                  {form.getFieldDecorator('ownerOptions')(
                    <Select mode="multiple" placeholder="Start typing to choose units." style={{ minWidth: '100%', overflow: 'hidden' }}>
                      <OptGroup label="Unaudited Owner">
                        {ownerOptions
                          .filter(ownerOption => !auditedOwnerIds.includes(ownerOption.value))
                          .map(ownerOption => (
                            <Option key={ownerOption.value} value={ownerOption.value}>
                              {ownerOption.label}
                            </Option>
                          ))}
                      </OptGroup>
                      <OptGroup label="Audited Owners">
                        {ownerOptions
                          .filter(ownerOption => auditedOwnerIds.includes(ownerOption.value))
                          .map(ownerOption => (
                            <Option key={ownerOption.value} value={ownerOption.value} disabled>
                              {ownerOption.label}
                            </Option>
                          ))}
                      </OptGroup>
                    </Select>
                  )}
                </FormItem>
                <span>{`Audited Payouts for ${moment(month + 1 + '/' + year, 'M/YYYY').format('MMMM YYYY')}: `}</span>
                {latestPayoutLog.map(log => {
                  if (log.newData) {
                    return (
                      <div key={log._id}>
                        {log.newData.data.payoutDetails.map((detail, idx) => (
                          <span key={idx}>
                            <br></br>
                            {`${this.constructOwnerDetails(ownerOptions, log.newData.ownerId)} - ${detail.unit.name}: RM ${Number(
                              detail.servicePackage.packageType === 0 ? detail.perUnitRevenue : detail.nettOwnersProfit
                            ).toFixed(2)}`}
                          </span>
                        ))}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </Modal>
            </Skeleton>
          </Row>
        </Skeleton>
      </Card>
    );
  }
}

export default Form.create()(withAppContext(Payout));
