import React from 'react';
import { Row, Col, Checkbox, Form, Card, Tooltip, Icon } from 'antd';
import PropTypes from 'prop-types';

import FormInput from 'components/FormInput/FormInput';
import UploadFiles from 'components/UploadFiles/UploadFiles';

import styles from './BasicDetailsCard.module.css';

const FormItem = Form.Item;

class BasicDetailsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nonSmoking: false,
      cashDeposit: false,
      bankDeposit: false,
      files: []
    };
  }

  componentDidMount() {
    const { defaultValues } = this.props;
    // const roomTypes = form.getFieldValue('roomTypes') || [];

    this.setState({
      nonSmoking: defaultValues.bookingEngine.nonSmoking,
      cashDeposit: defaultValues.bookingEngine.cashDeposit,
      bankDeposit: defaultValues.bookingEngine.bankDeposit,
      files: defaultValues.bookingEngine.files ? defaultValues.bookingEngine.files : []
    });
  }

  onChangeNonSmoke = e => {
    const { form } = this.props;
    const checked = e.target.checked;

    this.setState({
      nonSmoking: checked
    });

    form.setFieldsValue({
      nonSmoking: checked
    });
  };

  onChangeCashDeposit = e => {
    const { form } = this.props;
    const checked = e.target.checked;

    this.setState({
      cashDeposit: checked
    });

    form.setFieldsValue({
      cashDeposit: checked
    });
  };

  onChangeBankDeposit = e => {
    const { form } = this.props;
    const checked = e.target.checked;

    this.setState({
      bankDeposit: checked
    });

    form.setFieldsValue({
      bankDeposit: checked
    });
  };

  handleOnUploadFinish = e => {
    this.setState(prevState => {
      const uploadedFile = {
        name: e.file.name,
        link: e.fileUrl
      };
      const { form } = this.props;
      form.setFieldsValue({
        files: [...prevState.files, uploadedFile]
      });
      return {
        files: [...prevState.files, uploadedFile]
      };
    });
  };

  handleOnFileDelete = index => e => {
    e.preventDefault();
    this.setState(prevState => {
      const { files } = prevState;
      files.splice(index, 1);
      return {
        files
      };
    });
  };

  render() {
    const { form, defaultValues, checkIsAdmin } = this.props;
    const { nonSmoking, cashDeposit, bankDeposit, files } = this.state;
    return (
      <div>
        <Col gutter={16}>
          <br />
          <Row span={24} md={12}>
            <FormInput
              form={form}
              name={'propertyDetails'}
              defaultValue={defaultValues.bookingEngine.propertyDetails}
              inputType="textarea"
              label="Property Details"
              placeholder=""
              size="large"
            />
          </Row>
        </Col>
        <Col gutter={16}>
          <Card title="House Rules" className="houseRules" style={{ marginBottom: '24px' }}>
            <Row className={styles.checkboxSelection}>
              <br />
              <FormItem>
                {form.getFieldDecorator(`nonSmoking`, {
                  initialValue: defaultValues.bookingEngine.nonSmoking
                })(
                  <Checkbox checked={nonSmoking} onChange={this.onChangeNonSmoke}>
                    {'Non-Smoking'}
                  </Checkbox>
                )}
              </FormItem>
            </Row>
            <Row className={styles.checkboxSelection}>
              <FormItem>
                {form.getFieldDecorator(`cashDeposit`, {
                  initialValue: defaultValues.bookingEngine.cashDeposit
                })(
                  <Checkbox checked={cashDeposit} onChange={this.onChangeCashDeposit}>
                    {'Deposit collect in cash upon check in.'}
                  </Checkbox>
                )}
              </FormItem>
            </Row>
            <Row className={styles.checkboxSelection}>
              <FormItem>
                {form.getFieldDecorator(`bankDeposit`, {
                  initialValue: defaultValues.bookingEngine.bankDeposit
                })(
                  <Checkbox checked={bankDeposit} onChange={this.onChangeBankDeposit}>
                    {'Deposit collect via bank transfer'}
                  </Checkbox>
                )}
              </FormItem>
            </Row>
            <Row type="flex" justify="center">
              <UploadFiles files={files} onUploadFinish={this.handleOnUploadFinish} onFileDelete={this.handleOnFileDelete} checkIsAdmin={true}>
                {form.getFieldDecorator(`files`)}
              </UploadFiles>
              <Tooltip
                title={
                  <span style={{ fontSize: '12px' }}>
                    {'Upload a check in/house rules guide to be sent to guests via email after booking. Each file size should not exceed 10MB. '}
                  </span>
                }
              >
                <Icon type="question-circle-o" style={{ cursor: 'pointer' }} />
              </Tooltip>
            </Row>
            <Row span={24} md={12}>
              <FormInput
                form={form}
                name={'houseRules'}
                defaultValue={defaultValues.bookingEngine.houseRules}
                inputType="textarea"
                placeholder=""
                size="large"
              />
            </Row>
          </Card>
        </Col>
      </div>
    );
  }
}

BasicDetailsCard.propTypes = {
  form: PropTypes.object.isRequired,
  defaultValues: PropTypes.object,
  shouldUpdate: PropTypes.bool
};

BasicDetailsCard.defaultProps = {
  defaultValues: {},
  shouldUpdate: true
};

export default BasicDetailsCard;
