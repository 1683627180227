import React from 'react';
import { postLogin } from './../utils/httpClient';
import { getBanner, getAnnouncements } from './../utils/apis/post';
import { getPermissions } from './../utils/apis/constants';
import { checkHostCanAccessBookingEngine, checkHostCanAccessHotelBookingEngine } from 'utils/apis/host';
import { getHostStatus } from 'utils/apis/billingInvoice';
const AppContext = React.createContext();

class AppContextProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      permissions: {},
      isAuth: false,
      isBanned: false,
      user: '',
      banner: {},
      announcements: [],
      isAllowedBookingEngine: false,
      isAllowedHotelBookingEngine: false
    };
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentWillMount() {
    if (this.state.isAuth === false) {
      const authDetails = localStorage.getItem('auth');
      if (authDetails) {
        const authObject = JSON.parse(authDetails);
        this.setState({ isAuth: true, user: authObject.user }, this.checkHostBookingStatus);

        getHostStatus()
          .then(res => {
            if (res.hostStatus === 'block') {
              // only redirect to billing invoice page
              // other page won't be redirected
              this.setState({ isBanned: true });
            }
          })
          .catch(err => {
            // console.log(err, 65);
          });
      }
    }

    getPermissions()
      .then(res => {
        this.setState({ permissions: res }, () => {
          this.setState({ loading: false });
        });
      })
      .catch(ex => {
        console.log(ex);
      });

    getBanner()
      .then(data => {
        this.setState({ banner: data });
      })
      .catch(ex => {
        console.log(ex);
      });

    getAnnouncements()
      .then(data => {
        this.setState({ announcements: data });
      })
      .catch(ex => {
        console.log(ex);
      });
  }

  login = values => {
    return postLogin(values)
      .then(response => {
        if (response.status === 200) {
          this.setState(
            {
              isAuth: true,
              user: response.data.user
            },
            this.checkHostBookingStatus
          );

          return response;
        } else if (response.status === 401) {
          return response;
        }
      })
      .catch(error => {
        return error;
      });
  };

  logout = () => {
    localStorage.removeItem('auth');
    this.setState({ isAuth: false, user: '', isAllowedBookingEngine: false });
    this.setState({ isAuth: false, user: '', isAllowedHotelBookingEngine: false });
  };

  checkHostBookingStatus = () => {
    const {
      user: { roles }
    } = this.state;

    if (Array.isArray(roles) && roles.length > 0) {
      const hostId = roles[0].host;
      checkHostCanAccessBookingEngine(hostId)
        .then(res => {
          this.setState({ isAllowedBookingEngine: res.isAllowed });
        })
        .catch(ex => console.log(ex));
      checkHostCanAccessHotelBookingEngine(hostId)
        .then(res => {
          this.setState({ isAllowedHotelBookingEngine: res.isAllowed });
        })
        .catch(ex => console.log(ex));
    }
  };

  getUserRoles = () => {
    const { user } = this.state;
    return user.roles || [];
  };

  getUserHosts = () => this.getUserRoles().map(role => String(role.host));

  getUserPermissions = () => {
    const { permissions } = this.state;
    return this.checkIsAdmin() ? Object.values(permissions) : this.getFirstRole().permissions;
  };

  checkIsAdmin = () => {
    const { user } = this.state;
    return (user && user.isAdmin) || false;
  };

  checkIsAdminReadOnly = () => {
    const { user } = this.state;
    return user._id === '655714cc0d40eb046ad57bb1' || false;
  };

  getFirstRole = () => {
    const { user } = this.state;
    return (user && user.roles[0]) || {};
  };

  checkPermission = permission => {
    const userPermissions = this.getUserPermissions();
    return this.checkIsAdmin() || (userPermissions && userPermissions.includes(permission));
  };

  checkIsAllowDeleteUser = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.USERS_DELETE);
  };

  checkIsAllowDeleteReservation = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.RESERVATIONS_DELETE);
  };

  checkIsAllowDeleteServicePackage = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.SERVICE_PACKAGE_DELETE);
  };

  checkIsAllowCreateTask = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.TASK_MANAGEMENT_CREATE);
  };

  checkIsAllowDeleteTask = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.TASK_MANAGEMENT_DELETE);
  };

  checkIsAllowEditTask = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.TASK_MANAGEMENT_MODIFY);
  };

  checkIsAllowEditUser = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.USERS_MODIFY);
  };

  /*-----------------------------------Listing---------------------------------*/
  checkIsAllowViewListing = () => {
    const { permissions } = this.state;
    console.log(permissions.LISTINGS_VIEW, 168);
    return this.checkPermission(permissions.LISTINGS_VIEW);
  };

  checkIsAllowDeleteListing = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.LISTINGS_DELETE);
  };

  /*-----------------------------------Rental----------------------------------*/
  checkIsAllowViewRental = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.RENTAL_VIEW);
  };

  checkIsAllowEditRental = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.RENTAL_MODIFY);
  };

  /*--------------------------------Transaction--------------------------------*/
  checkIsAllowViewTransaction = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.TRANSACTION_VIEW);
  };

  checkIsAllowEditTransaction = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.TRANSACTION_MODIFY);
  };

  checkIsAllowDeleteTransaction = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.TRANSACTION_DELETE);
  };

  /*--------------------------------Rate Modifier-------------------------------*/
  checkIsAllowCreateRateModifier = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.RATE_MODIFIER_CREATE);
  };

  checkIsAllowEditRateModifier = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.RATE_MODIFIER_MODIFY);
  };

  checkIsAllowDeleteRateModifier = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.RATE_MODIFIER_DELETE);
  };

  /*--------------------------------Billing-------------------------------*/
  checkIsAllowViewBilling = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.BILLING_VIEW);
  };

  /*--------------------------------Integrations-------------------------------*/
  checkIsAllowViewIntegration = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.INTEGRATIONS_VIEW);
  };

  checkIsAllowCreateIntegration = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.INTEGRATIONS_CREATE);
  };

  checkIsAllowEditIntegration = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.INTEGRATIONS_MODIFY);
  };

  checkIsAllowDeleteIntegration = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.INTEGRATIONS_DELETE);
  };

  checkIsCleaner = () => {
    const { permissions } = this.state;
    return !this.checkPermission(permissions.TASK_MANAGEMENT_CREATE);
  };

  /*--------------------------------Export CSV-------------------------------*/
  checkAbleExportUser = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.USERS_CSV_VIEW);
  };

  checkAbleExportListing = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.LISTINGS_CSV_VIEW);
  };

  checkAbleExportReservation = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.RESERVATIONS_CSV_VIEW);
  };

  checkAbleExportReport = () => {
    const { permissions } = this.state;
    return true;
    return this.checkPermission(permissions.REPORTS_CSV_VIEW);
  };

  checkAbleExportServicePackage = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.SERVICE_PACKAGE_CSV_VIEW);
  };

  checkAbleExportHost = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.HOST_CSV_VIEW);
  };

  checkAbleExportRateModifier = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.RATE_MODIFIER_CSV_VIEW);
  };

  checkAbleExportTaskManagement = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.TASK_MANAGEMENT_CSV_VIEW);
  };

  checkAbleExportOverview = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.OVERVIEW_CSV_VIEW);
  };

  /*--------------------------------Room Rates-------------------------------*/
  checkAbleViewRoomRates = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.ROOM_RATES_VIEW);
  };

  checkAbleEditRoomRates = () => {
    const { permissions } = this.state;
    return this.checkPermission(permissions.ROOM_RATES_MODIFY);
  };

  render() {
    const { isAuth, user, permissions, banner, announcements, isAllowedBookingEngine, isAllowedHotelBookingEngine, isBanned } = this.state;
    return (
      <AppContext.Provider
        value={{
          isAuth,
          isBanned,
          login: this.login,
          logout: this.logout,
          user,
          getUserHosts: this.getUserHosts,
          getUserPermissions: this.getUserPermissions,
          checkIsAdmin: this.checkIsAdmin,
          checkIsAdminReadOnly: this.checkIsAdminReadOnly,
          permissions,
          checkIsCleaner: this.checkIsCleaner,
          checkIsAllowDeleteUser: this.checkIsAllowDeleteUser,
          checkIsAllowDeleteReservation: this.checkIsAllowDeleteReservation,
          checkIsAllowDeleteServicePackage: this.checkIsAllowDeleteServicePackage,
          checkIsAllowViewListing: this.checkIsAllowViewListing,
          checkIsAllowDeleteListing: this.checkIsAllowDeleteListing,
          checkIsAllowViewRental: this.checkIsAllowViewRental,
          checkIsAllowEditRental: this.checkIsAllowEditRental,
          checkIsAllowViewTransaction: this.checkIsAllowViewTransaction,
          checkIsAllowEditTransaction: this.checkIsAllowEditTransaction,
          checkIsAllowDeleteTransaction: this.checkIsAllowDeleteTransaction,
          checkIsAllowCreateRateModifier: this.checkIsAllowCreateRateModifier,
          checkIsAllowEditRateModifier: this.checkIsAllowEditRateModifier,
          checkIsAllowDeleteRateModifier: this.checkIsAllowDeleteRateModifier,
          checkIsAllowViewBilling: this.checkIsAllowViewBilling,
          checkIsAllowViewIntegration: this.checkIsAllowViewIntegration,
          checkIsAllowCreateIntegration: this.checkIsAllowCreateIntegration,
          checkIsAllowEditIntegration: this.checkIsAllowEditIntegration,
          checkIsAllowDeleteIntegration: this.checkIsAllowDeleteIntegration,
          banner,
          announcements,
          isAllowedBookingEngine,
          isAllowedHotelBookingEngine,
          checkIsAllowCreateTask: this.checkIsAllowCreateTask,
          checkIsAllowDeleteTask: this.checkIsAllowDeleteTask,
          checkIsAllowEditTask: this.checkIsAllowEditTask,
          checkIsAllowEditUser: this.checkIsAllowEditUser,
          checkAbleExportUser: this.checkAbleExportUser,
          checkAbleExportListing: this.checkAbleExportListing,
          checkAbleExportReservation: this.checkAbleExportReservation,
          checkAbleExportReport: this.checkAbleExportReport,
          checkAbleExportServicePackage: this.checkAbleExportServicePackage,
          checkAbleExportHost: this.checkAbleExportHost,
          checkAbleExportRateModifier: this.checkAbleExportRateModifier,
          checkAbleExportTaskManagement: this.checkAbleExportTaskManagement,
          checkAbleExportOverview: this.checkAbleExportOverview,
          checkAbleViewRoomRates: this.checkAbleViewRoomRates,
          checkAbleEditRoomRates: this.checkAbleEditRoomRates
        }}
      >
        {!this.state.loading ? this.props.children : '...Loading'}
      </AppContext.Provider>
    );
  }
}

const AppContextConsumer = AppContext.Consumer;

const withAppContext = Component => {
  const AppContextComponent = props => <AppContextConsumer>{appContextProps => <Component {...appContextProps} {...props} />}</AppContextConsumer>;
  return AppContextComponent;
};

export { AppContextProvider, AppContextConsumer, withAppContext, AppContext };
