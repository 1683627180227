import React, { useState, useEffect, useCallback } from 'react';
import { Form, Modal, Row, Col, Icon, Card, Avatar, Button, Divider, Message } from 'antd';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import FormSelection from 'components/FormSelection/FormSelection';
import FormCheckbox from 'components/FormCheckbox/FormCheckbox';
import SeasonalRuleColorPicker from './seasonalRuleColorPicker/SeasonalRuleColorPicker';
import ShowMoreList from 'components/ShowMoreList/ShowMoreList';
import { postSeasonalRuleGroup, putSeasonalRuleGroup } from 'utils/apis/seasonalRule';
import { capitalizeFirstLetter } from 'utils/general';

import './seasonalRuleEdit.css';

const losDaysOptions = [
  {
    key: `2nights`,
    value: `2nights`,
    displayValue: `2 nights`,
    days: 2
  },
  {
    key: `3nights`,
    value: `3nights`,
    displayValue: `3 nights`,
    days: 3
  },
  {
    key: `4nights`,
    value: `4nights`,
    displayValue: `4 nights`,
    days: 4
  },
  {
    key: `5nights`,
    value: `5nights`,
    displayValue: `5 nights`,
    days: 5
  },
  {
    key: `6nights`,
    value: `6nights`,
    displayValue: `6 nights`,
    days: 6
  },
  {
    key: `weeklyDiscount`,
    value: `weeklyDiscount`,
    displayValue: `Weekly discount`,
    days: 7
  },
  {
    key: `14nights`,
    value: `14nights`,
    displayValue: `14 nights`,
    days: 14
  },
  {
    key: `21nights`,
    value: `21nights`,
    displayValue: `21 nights`,
    days: 21
  },
  {
    key: `monthlyDiscount`,
    value: `monthlyDiscount`,
    displayValue: `Monthly discount`,
    days: 30
  }
];

const convertMonthsToDays = months => months * 30;
const convertDaysToMonths = days => days / 30;

const useGenerateGeneralProps = () => {
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const handleOnIsSaving = boolean => {
    setIsSaveLoading(boolean);
  };
  return { isSaveLoading, handleOnIsSaving };
};

const useNightlyPriceDiscountProps = selectedRuleDetail => {
  const [nightlyPriceDiscount, setNightlyPriceDiscount] = useState({ priceChange: 0 });
  const [isNightlyPriceOpen, setNightlyPriceEditClose] = useState(false);

  const setDefaultNightlyPriceDiscount = useCallback(() => {
    const currentNightlyPriceValue =
      selectedRuleDetail && selectedRuleDetail.pricingRule
        ? selectedRuleDetail.pricingRule.filter(pricingRule => pricingRule.ruleType === 'SEASONAL_ADJUSTMENT')
        : [];
    if (currentNightlyPriceValue.length > 0) {
      setNightlyPriceDiscount({
        priceChange: currentNightlyPriceValue[0].priceChange
      });
    } else {
      setNightlyPriceDiscount({
        priceChange: 0
      });
    }
  }, [selectedRuleDetail]);

  useEffect(() => {
    if (selectedRuleDetail && Object.values(selectedRuleDetail).length > 0) {
      setDefaultNightlyPriceDiscount();
    } else {
      setNightlyPriceDiscount({ priceChange: 0 });
    }
  }, [setDefaultNightlyPriceDiscount, selectedRuleDetail]);

  const handleOnIsNightlyPriceOpen = () => {
    if (!isNightlyPriceOpen) {
      setNightlyPriceEditClose(true);
    } else {
      setNightlyPriceEditClose(false);
    }
  };

  return {
    isNightlyPriceOpen,
    setNightlyPriceEditClose,
    nightlyPriceDiscount,
    setNightlyPriceDiscount,
    handleOnIsNightlyPriceOpen,
    setDefaultNightlyPriceDiscount
  };
};

const useLengthOfStayDiscountProps = selectedRuleDetail => {
  const [lengthOfStayDiscountSelection, setLOSDiscountSelection] = useState([]);
  const [lengthOfStayDiscountList, setLengthOfStayDiscountList] = useState([]);
  const [isLengthOfStayOpen, setLengthOfStayEditClose] = useState(false);

  const setLOSDiscountSelections = useCallback(() => {
    let lengthOfStayDiscountList = [];
    const filteredDaySelected =
      selectedRuleDetail && selectedRuleDetail.pricingRule
        ? selectedRuleDetail.pricingRule.filter(pricingRule => pricingRule.ruleType === 'STAYED_AT_LEAST_X_DAYS')
        : [];
    const filteredThresholdOne = filteredDaySelected.map(pricingRule => pricingRule.thresholdOne);
    losDaysOptions.forEach(losDaysOption => {
      filteredDaySelected.forEach(day => {
        if (losDaysOption.days === day.thresholdOne) {
          lengthOfStayDiscountList.push({
            ...losDaysOption,
            defaultValue: Math.abs(day.priceChange)
          });
        }
      });
    });

    const losDaysOptionsWithEnable = losDaysOptions.map(losDaysOption => {
      if (filteredThresholdOne.includes(losDaysOption.days)) {
        return {
          ...losDaysOption,
          disabled: true
        };
      } else {
        return {
          ...losDaysOption
        };
      }
    });
    setLOSDiscountSelection(losDaysOptionsWithEnable);
    setLengthOfStayDiscountList(lengthOfStayDiscountList);
  }, [selectedRuleDetail]);

  useEffect(() => {
    if (selectedRuleDetail && Object.values(selectedRuleDetail).length > 0) {
      setLOSDiscountSelections();
    } else {
      setLOSDiscountSelection(losDaysOptions);
      setLengthOfStayDiscountList([]);
    }
  }, [setLOSDiscountSelections, selectedRuleDetail]);

  const handleOnChangeLengthOfStayDiscount = selectedKey => updatedValue => {
    lengthOfStayDiscountList.filter(list => {
      if (list.key === selectedKey) {
        return (list.defaultValue = updatedValue);
      } else {
        return list;
      }
    });
  };

  const handleOnIsLengthOfStayOpen = () => {
    if (!isLengthOfStayOpen) {
      setLengthOfStayEditClose(true);
    } else {
      setLOSDiscountSelections();
      setLengthOfStayEditClose(false);
    }
  };

  const handleOnAddLengthOfStayDiscount = selectedKey => {
    lengthOfStayDiscountSelection.map(selection => {
      // Some method
      if (selection.key === selectedKey && !lengthOfStayDiscountList.some(list => list.key === selectedKey)) {
        selection.defaultValue = 0;
        lengthOfStayDiscountList.push(selection);
        selection.disabled = true;
      }
      return lengthOfStayDiscountList;
    });

    setLOSDiscountSelection(lengthOfStayDiscountSelection);
    setLengthOfStayDiscountList(lengthOfStayDiscountList);
  };

  const handleOnRemoveLengthOfStayDiscount = (selectedValue, form) => {
    const filteredLengthOfStayDiscountList = lengthOfStayDiscountList.filter(list => list.key !== selectedValue);
    const losKeys = filteredLengthOfStayDiscountList.map(losDiscounts => losDiscounts.key);
    lengthOfStayDiscountSelection.map(selection => {
      if (selection.key === selectedValue) {
        return (selection.disabled = false);
      }
      return selection;
    });

    setLengthOfStayDiscountList(filteredLengthOfStayDiscountList);
    form.validateFields(losKeys, (err, values) => {});
  };

  return {
    isLengthOfStayOpen,
    lengthOfStayDiscountList,
    lengthOfStayDiscountSelection,
    handleOnIsLengthOfStayOpen,
    handleOnChangeLengthOfStayDiscount,
    handleOnAddLengthOfStayDiscount,
    handleOnRemoveLengthOfStayDiscount,
    setLengthOfStayEditClose,
    setLOSDiscountSelections
  };
};

const useLastMinuteDiscountProps = selectedRuleDetail => {
  const [lastMinuteDiscountList, setLastMinuteDiscountList] = useState([]);
  const [isLastMinuteOpen, setLastMinuteEditClose] = useState(false);

  const setDefaultLastMinuteDiscount = useCallback(() => {
    const currentLastMinuteValues =
      selectedRuleDetail && selectedRuleDetail.pricingRule
        ? selectedRuleDetail.pricingRule.filter(pricingRule => pricingRule.ruleType === 'BOOKED_WITHIN_AT_MOST_X_DAYS')
        : [];
    if (currentLastMinuteValues.length > 0) {
      let keys = [];
      let discountStartsArr = [{}];
      let lastMinuteDiscountArr = [{}];

      currentLastMinuteValues.forEach((value, i) => {
        keys.push(i + 1);
        lastMinuteDiscountArr.push(value.priceChange);
        discountStartsArr.push(value.thresholdOne);
      });

      const defaultValues = {
        lastMinuteDiscount: lastMinuteDiscountArr,
        discountStarts: discountStartsArr
      };
      handleLastMinuteDiscount(defaultValues, keys);
    } else {
      setLastMinuteDiscountList([]);
    }
  }, [selectedRuleDetail]);

  useEffect(() => {
    if (selectedRuleDetail && Object.values(selectedRuleDetail).length > 0) {
      setDefaultLastMinuteDiscount();
    } else {
      setLastMinuteDiscountList([]);
    }
  }, [setDefaultLastMinuteDiscount, selectedRuleDetail]);

  const handleOnChangeLastMinuteDiscount = form => {
    const newValues = form.getFieldsValue();
    const lastMinuteKeys = lastMinuteDiscountList.map(list => list.key);
    handleLastMinuteDiscount(newValues, lastMinuteKeys);
  };

  const handleOnIsLastMinuteOpen = () => {
    if (!isLastMinuteOpen) {
      setLastMinuteEditClose(true);
    } else {
      setDefaultLastMinuteDiscount();
      setLastMinuteEditClose(false);
    }
  };

  const handleOnAddLastMinuteDiscount = form => {
    const lastMinuteKeys = lastMinuteDiscountList.map(list => list.key);
    const nextKeys = lastMinuteKeys.concat(Math.max(0, ...lastMinuteKeys) + 1);
    const newValues = form.getFieldsValue();
    if (!newValues.discountStarts) {
      newValues.discountStarts = [];
    }
    if (!newValues.lastMinuteDiscount) {
      newValues.lastMinuteDiscount = [];
    }
    newValues.discountStarts[Math.max(...nextKeys)] = 0;
    newValues.lastMinuteDiscount[Math.max(...nextKeys)] = 0;
    handleLastMinuteDiscount(newValues, nextKeys);
  };

  const handleOnRemoveLastMinuteDiscount = (selectedKey, form) => {
    const currentValues = form.getFieldsValue();
    const currentDiscountStarts = currentValues.discountStarts;
    const currentLastMinuteDiscount = currentValues.lastMinuteDiscount;
    currentDiscountStarts[selectedKey] = null;
    currentLastMinuteDiscount[selectedKey] = null;
    const lastMinuteKeys = lastMinuteDiscountList.map(list => list.key).filter(key => key !== selectedKey);
    const updatedFormValues = {
      ...form.getFieldsValue(),
      discountStarts: currentDiscountStarts,
      lastMinuteDiscount: currentLastMinuteDiscount
    };
    form.setFieldsValue(updatedFormValues);
    handleLastMinuteDiscount(updatedFormValues, lastMinuteKeys);
  };

  const handleLastMinuteDiscount = (values, keys) => {
    const lastMinuteDiscountList = keys.map(key => {
      return {
        key,
        discountStarts: values.discountStarts ? Math.abs(values.discountStarts[key]) : 0,
        lastMinuteDiscount: values.lastMinuteDiscount ? Math.abs(values.lastMinuteDiscount[key]) : 0
      };
    });
    setLastMinuteDiscountList(lastMinuteDiscountList);
  };

  return {
    isLastMinuteOpen,
    lastMinuteDiscountList,
    handleOnIsLastMinuteOpen,
    handleOnChangeLastMinuteDiscount,
    handleOnAddLastMinuteDiscount,
    handleOnRemoveLastMinuteDiscount,
    setLastMinuteEditClose,
    setDefaultLastMinuteDiscount
  };
};

const useEarlyBirdDiscountProps = selectedRuleDetail => {
  const [earlyBirdDiscountList, setEarlyBirdDiscountList] = useState([]);
  const [isEarlyBirdEditOpen, setIsEarlyBirdEditOpen] = useState(false);

  const setDefaultEarlyBirdDiscount = useCallback(() => {
    const currentEarlyBirdValues =
      selectedRuleDetail && selectedRuleDetail.pricingRule
        ? selectedRuleDetail.pricingRule.filter(pricingRule => pricingRule.ruleType === 'BOOKED_BEYOND_AT_LEAST_X_DAYS')
        : [];
    if (currentEarlyBirdValues.length > 0) {
      let keys = [];
      let discountEndsArr = [{}];
      let earlyBirdDiscountArr = [{}];

      currentEarlyBirdValues.forEach((value, i) => {
        keys.push(i + 1);
        earlyBirdDiscountArr.push(value.priceChange);
        discountEndsArr.push(convertDaysToMonths(value.thresholdOne));
      });

      const defaultValues = {
        earlyBirdDiscount: earlyBirdDiscountArr,
        discountEnds: discountEndsArr
      };
      handleEarlyBirdDiscount(defaultValues, keys);
    } else {
      setEarlyBirdDiscountList([]);
    }
  }, [selectedRuleDetail]);

  useEffect(() => {
    if (selectedRuleDetail && Object.values(selectedRuleDetail).length > 0) {
      setDefaultEarlyBirdDiscount();
    } else {
      setEarlyBirdDiscountList([]);
    }
  }, [setDefaultEarlyBirdDiscount, selectedRuleDetail]);

  const handleOnChangeEarlyBirdDiscount = form => {
    const newValues = form.getFieldsValue();
    const earlyBirdKeys = earlyBirdDiscountList.map(list => list.key);
    handleEarlyBirdDiscount(newValues, earlyBirdKeys);
  };

  const handleOnIsEarlyBirdOpen = () => {
    if (!isEarlyBirdEditOpen) {
      setIsEarlyBirdEditOpen(true);
    } else {
      setDefaultEarlyBirdDiscount();
      setIsEarlyBirdEditOpen(false);
    }
  };

  const handleOnAddEarlyBirdDiscount = form => {
    const earlyBirdKeys = earlyBirdDiscountList.map(list => list.key);
    const nextKeys = earlyBirdKeys.concat(Math.max(0, ...earlyBirdKeys) + 1);
    const newValues = form.getFieldsValue();
    if (!newValues.discountEnds) {
      newValues.discountEnds = [];
    }
    if (!newValues.earlyBirdDiscount) {
      newValues.earlyBirdDiscount = [];
    }
    newValues.discountEnds[Math.max(...nextKeys)] = 0;
    newValues.earlyBirdDiscount[Math.max(...nextKeys)] = 0;
    handleEarlyBirdDiscount(newValues, nextKeys);
  };

  const handleOnRemoveEarlyBirdDiscount = (selectedKey, form) => {
    const currentValues = form.getFieldsValue();
    const currentDiscountEnds = currentValues.discountEnds;
    const currentEarlyBirdDiscount = currentValues.earlyBirdDiscount;
    currentDiscountEnds[selectedKey] = null;
    currentEarlyBirdDiscount[selectedKey] = null;
    const earlyBirdKeys = earlyBirdDiscountList.map(list => list.key).filter(key => key !== selectedKey);
    const updatedFormValues = {
      ...form.getFieldsValue(),
      discountEnds: currentDiscountEnds,
      earlyBirdDiscount: currentEarlyBirdDiscount
    };
    form.setFieldsValue(updatedFormValues);
    handleEarlyBirdDiscount(updatedFormValues, earlyBirdKeys);
  };

  const handleEarlyBirdDiscount = (values, keys) => {
    const earlyBirdDiscountList = keys.map(key => {
      return {
        key,
        discountEnds: values.discountEnds ? Math.abs(values.discountEnds[key]) : 0,
        earlyBirdDiscount: values.earlyBirdDiscount ? Math.abs(values.earlyBirdDiscount[key]) : 0
      };
    });
    setEarlyBirdDiscountList(earlyBirdDiscountList);
  };
  return {
    isEarlyBirdEditOpen,
    earlyBirdDiscountList,
    handleOnIsEarlyBirdOpen,
    handleOnChangeEarlyBirdDiscount,
    handleOnAddEarlyBirdDiscount,
    handleOnRemoveEarlyBirdDiscount,
    setIsEarlyBirdEditOpen,
    setDefaultEarlyBirdDiscount
  };
};

const useTripLengthProps = selectedRuleDetail => {
  const [tripLength, setTripLength] = useState({});
  const [isTripLengthOpen, setIsTripLengthOpen] = useState(false);

  const setDefaultTripLength = useCallback(() => {
    const currentTripLengthValues = selectedRuleDetail && selectedRuleDetail.availabilityRules ? selectedRuleDetail.availabilityRules : {};
    if (currentTripLengthValues) {
      // take only trip length properties
      setTripLength({
        minNights: currentTripLengthValues.minNights,
        maxNights: currentTripLengthValues.maxNights
      });
    } else {
      setTripLength({});
    }
  }, [selectedRuleDetail]);

  useEffect(() => {
    if (selectedRuleDetail && selectedRuleDetail.availabilityRules) {
      setDefaultTripLength();
    } else {
      setDefaultTripLength({});
    }
  }, [setDefaultTripLength, selectedRuleDetail]);

  const handleOnIsTripLengthOpen = () => {
    if (!isTripLengthOpen) {
      setIsTripLengthOpen(true);
    } else {
      setDefaultTripLength();
      setIsTripLengthOpen(false);
    }
  };

  return {
    isTripLengthOpen,
    tripLength,
    handleOnIsTripLengthOpen,
    setIsTripLengthOpen,
    setDefaultTripLength
  };
};

const useCICODaysProps = selectedRuleDetail => {
  const [CICODays, setCICODays] = useState({
    closedForCheckin: [],
    closedForCheckout: []
  });
  const [isCICODaysOpen, setIsCICODaysOpen] = useState(false);

  const setDefaultCICODays = useCallback(() => {
    const currentCICODaysValues =
      selectedRuleDetail && selectedRuleDetail.availabilityRules
        ? selectedRuleDetail.availabilityRules
        : {
            closedForCheckin: [],
            closedForCheckout: []
          };
    if (currentCICODaysValues) {
      // take only check in and check out days properties
      setCICODays({
        closedForCheckin: currentCICODaysValues.closedForCheckin,
        closedForCheckout: currentCICODaysValues.closedForCheckout
      });
    } else {
      setCICODays({
        closedForCheckin: [],
        closedForCheckout: []
      });
    }
  }, [selectedRuleDetail]);

  useEffect(() => {
    if (selectedRuleDetail && selectedRuleDetail.availabilityRules) {
      setDefaultCICODays();
    } else {
      setDefaultCICODays({});
    }
  }, [setDefaultCICODays, selectedRuleDetail]);

  const handleOnIsCICODaysOpen = () => {
    if (!isCICODaysOpen) {
      setIsCICODaysOpen(true);
    } else {
      setDefaultCICODays();
      setIsCICODaysOpen(false);
    }
  };

  return {
    isCICODaysOpen,
    CICODays,
    handleOnIsCICODaysOpen,
    setIsCICODaysOpen,
    setDefaultCICODays
  };
};

const generateSideMessage = selectedSection => {
  let title, description, note;
  if (selectedSection === 'instructions') {
    title = 'Here’s how to create rule-sets';
    description = (
      <Row justify="start" gutter={[16, 16]}>
        <Col span={4}>
          <Avatar style={{ backgroundColor: '#008489' }} size="large">
            <h2 style={{ color: 'white' }}>1</h2>
          </Avatar>
        </Col>
        <Col span={20}>
          Give your rule-set a name and choose a color to represent it on your <b>Airbnb calendar</b>.
        </Col>
        <Col span={4}>
          <Avatar style={{ backgroundColor: '#008489' }} size="large">
            <h2 style={{ color: 'white' }}>2</h2>
          </Avatar>
        </Col>
        <Col span={20}>Set any pricing discounts, availability requirements, and promotions.</Col>
        <Col span={4}>
          <Avatar style={{ backgroundColor: '#008489' }} size="large">
            <h2 style={{ color: 'white' }}>3</h2>
          </Avatar>
        </Col>
        <Col span={20}>
          Choose dates and listings you want to apply the rule-set to on your <b>Airbnb calendar</b>.
        </Col>
        <Col>
          <a href="https://www.airbnb.com/help/article/2061/how-do-rule-sets-work">Learn more about rule-sets</a>
        </Col>
      </Row>
    );
  } else if (selectedSection === 'nameAndColor') {
    title = 'Name and color';
    description = (
      <p>
        Give your rule-set a name (ex: High season, Ski week, Holidays) and choose a color to represent it on your <b>Airbnb calendar.</b>
      </p>
    );
  } else if (selectedSection === 'nightlyPrice') {
    title = 'Nightly price';
    description =
      'Adjust your nightly price by adding a percentage increase or decrease. When you apply your rule-set to dates, it will adjust each nightly price.';
  } else if (selectedSection === 'lengthOfStay') {
    title = 'About length-of-stay prices';
    description =
      'Each length-of-stay discount applies to the length you choose or longer. For example, weekly discounts are 7 days or longer. Monthly discounts are 28 days or longer.';
    note = 'Any of your existing discounts will be replaced.';
  } else if (selectedSection === 'lastMinute') {
    title = 'Last-minute discounts';
    description =
      'You can add multiple last-minute discounts, as long as each is within 28 days of check-in. For example, you can offer a bigger discount the closer it is to the arrival date. This discount won’t be shown to guests.';
    note = 'Any of your existing last-minute discounts will be replaced.';
  } else if (selectedSection === 'earlyBird') {
    title = 'Early-bird discounts';
    description =
      'You can add multiple discounts anywhere from 1-36 months ahead of arrival. For example, you can offer a bigger discount for guests who far in advance of their arrival. This discount won’t be shown to guests.';
    note = 'Any of your existing early-bird discounts will be replaced.';
  } else if (selectedSection === 'tripLength') {
    title = 'Trip length';
    description =
      'Add any requirements you have for minimum or maximum number of days a guest can stay. You can customize requirements by which day of the week a guest checks in.';
    note = 'Any of your existing trip length requirements will be replaced.';
  } else if (selectedSection === 'CICODays') {
    title = 'Check-in and check-out days';
    description = 'Specify days of the week when guests can’t check in or check-out.';
    note = 'When you apply your rule-set your existing check-in and check-out day requirements will be replaced.';
  }

  const cardDetails = (
    <Card style={{ width: 300, position: 'fixed', top: '170px', right: '6.7%' }}>
      <h2>{title}</h2>
      <div>{description}</div>
      {note && (
        <>
          <br />
          <div>Note: {note}</div>
        </>
      )}
    </Card>
  );
  return cardDetails;
};

//-- Main component --
const SeasonalRuleEdit = ({
  form,
  airbnbListingId,
  selectedRuleDetail,
  isShowEditModal,
  handleOnCloseEditModal,
  refetchRuleGroup,
  selectedRuleId,
  setSelectedRuleId
}) => {
  const { isSaveLoading, handleOnIsSaving } = useGenerateGeneralProps();

  const {
    isNightlyPriceOpen,
    nightlyPriceDiscount,
    handleOnIsNightlyPriceOpen,
    setNightlyPriceEditClose,
    setDefaultNightlyPriceDiscount
  } = useNightlyPriceDiscountProps(selectedRuleDetail);

  const {
    isLengthOfStayOpen,
    lengthOfStayDiscountList,
    lengthOfStayDiscountSelection,
    handleOnIsLengthOfStayOpen,
    handleOnChangeLengthOfStayDiscount,
    handleOnAddLengthOfStayDiscount,
    handleOnRemoveLengthOfStayDiscount,
    setLengthOfStayEditClose,
    setLOSDiscountSelections
  } = useLengthOfStayDiscountProps(selectedRuleDetail);

  const {
    isLastMinuteOpen,
    lastMinuteDiscountList,
    handleOnChangeLastMinuteDiscount,
    handleOnIsLastMinuteOpen,
    handleOnAddLastMinuteDiscount,
    handleOnRemoveLastMinuteDiscount,
    setLastMinuteEditClose,
    setDefaultLastMinuteDiscount
  } = useLastMinuteDiscountProps(selectedRuleDetail);

  const {
    isEarlyBirdEditOpen,
    earlyBirdDiscountList,
    handleOnIsEarlyBirdOpen,
    handleOnChangeEarlyBirdDiscount,
    handleOnAddEarlyBirdDiscount,
    handleOnRemoveEarlyBirdDiscount,
    setIsEarlyBirdEditOpen,
    setDefaultEarlyBirdDiscount
  } = useEarlyBirdDiscountProps(selectedRuleDetail);

  const { isTripLengthOpen, tripLength, handleOnIsTripLengthOpen, setIsTripLengthOpen, setDefaultTripLength } = useTripLengthProps(
    selectedRuleDetail
  );

  const { isCICODaysOpen, CICODays, handleOnIsCICODaysOpen, setIsCICODaysOpen, setDefaultCICODays } = useCICODaysProps(selectedRuleDetail);

  const [sideMessageSection, setSideMessageSection] = useState('instructions');
  const showNightlyPriceDiscount = nightlyPriceDiscount.priceChange
    ? `${Math.abs(nightlyPriceDiscount.priceChange)}% nightly price ${nightlyPriceDiscount.priceChange > 0 ? 'in' : 'de'}crease`
    : null;
  const showLengthOfStayDiscountList = lengthOfStayDiscountList.map(list => {
    return `${list.defaultValue}% discount · ${list.days} night${list.days > 1 ? 's' : ''} minimum`;
  });
  const showLastMinuteDiscountList = lastMinuteDiscountList.map(list => {
    return `${list.lastMinuteDiscount}% discount · within ${list.discountStarts} day${list.discountStarts > 1 ? 's' : ''} of arrival`;
  });
  const showEarlyBirdDiscountList = earlyBirdDiscountList.map(list => {
    return `${list.earlyBirdDiscount}% discount · ${list.discountEnds} month${list.discountEnds > 1 ? 's' : ''} in advance`;
  });

  const showTripLength = [];
  if (tripLength.minNights) {
    Object.keys(tripLength.minNights).map(minNight => {
      if (minNight === 'UNSPECIFIED' && tripLength.minNights[minNight] !== null) {
        showTripLength.push(`Min nights: ${tripLength.minNights[minNight]}`);
      } else if (tripLength.minNights[minNight] !== null) {
        showTripLength.push(`${capitalizeFirstLetter(minNight, { lowerOthers: true })} check-in: ${tripLength.minNights[minNight]} nights minimum`);
      }
      return showTripLength;
    });
  }

  if (tripLength.maxNights) {
    Object.keys(tripLength.maxNights).map(maxNight => {
      if (maxNight === 'UNSPECIFIED' && tripLength.maxNights[maxNight] !== null) {
        showTripLength.push(`Max nights: ${tripLength.maxNights[maxNight]}`);
      }
      return showTripLength;
    });
  }

  const showCICODays = [];
  if (CICODays.closedForCheckin && CICODays.closedForCheckin.length > 0) {
    const closedForCheckinWithProperCasing = CICODays.closedForCheckin.map(closedForCheckin =>
      capitalizeFirstLetter(closedForCheckin, { lowerOthers: true })
    );
    showCICODays.push(`Check-in: all days except ${closedForCheckinWithProperCasing.join(', ')}`);
  }

  if (CICODays.closedForCheckout && CICODays.closedForCheckout.length > 0) {
    const closedForCheckoutWithProperCasing = CICODays.closedForCheckout.map(closedForCheckout =>
      capitalizeFirstLetter(closedForCheckout, { lowerOthers: true })
    );
    showCICODays.push(`Check-out: all days except ${closedForCheckoutWithProperCasing.join(', ')}`);
  }

  const SideMessage = () => {
    return generateSideMessage(sideMessageSection);
  };

  const generateLengthOfStayDiscountLists = () => {
    return lengthOfStayDiscountList.map(list => {
      return (
        <Row gutter={8}>
          <Col xs={20} sm={20} md={12} lg={6}>
            <FormInputNumber
              form={form}
              formLabel={list.displayValue}
              name={list.value}
              defaultValue={list.defaultValue}
              minValue={0}
              maxValue={99}
              onChange={handleOnChangeLengthOfStayDiscount(list.key)}
              formatter={value => `${value}%`}
              parser={value => value.replace('%', '')}
              extraRules={[
                {
                  validator: checkLongerShorterDiscount
                }
              ]}
            />
          </Col>
          <Col span={2}>
            <Icon type="delete" style={{ marginTop: 49, fontSize: 22 }} onClick={() => handleOnRemoveLengthOfStayDiscount(list.key, form)} />
          </Col>
        </Row>
      );
    });
  };

  // Form Validators
  const checkNightlyAdjustmentRange = (rule, value, callback) => {
    const nightlyPrice = form.getFieldValue('nightlyPrice');
    const nightlyPriceSign = form.getFieldValue('nightlyPriceSign');
    if (nightlyPrice === undefined || nightlyPrice === null || nightlyPrice === 0) {
      callback('Please enter an adjustment value.');
    } else {
      if (nightlyPriceSign < 0) {
        if (nightlyPrice >= 100) {
          callback('Cannot set nightly price rate decrease over 100%.');
        } else {
          callback();
        }
      } else if (nightlyPriceSign > 0) {
        if (nightlyPrice > 500) {
          callback('Cannot set nightly price rate increase over 500%.');
        } else {
          callback();
        }
      }
    }
  };

  const checkLongerShorterDiscount = (rule, value, callback) => {
    if (value === undefined || value === null || value === 0) {
      callback('Please enter a discount.');
    }
    const days = lengthOfStayDiscountList.filter(list => {
      return list.key === rule.field;
    })[0].days;

    const filteredLOS = lengthOfStayDiscountList.filter(list => list.key !== rule.field);
    if (filteredLOS.length === 0) {
      callback();
    } else {
      for (let i = 0; i < filteredLOS.length; i++) {
        const filteredLengthOfStayDays = filteredLOS[i].days;
        const formLengthOfStayValue = form.getFieldValue(filteredLOS[i].key);
        if (
          (days < filteredLengthOfStayDays && value > formLengthOfStayValue) ||
          (days > filteredLengthOfStayDays && value < formLengthOfStayValue)
        ) {
          form.setFields({
            [filteredLOS[i].key]: {
              errors: [new Error('Longer discounts need to be equal to or higher than shorter discounts.')]
            }
          });
          callback();
        } else {
          form.setFields({
            [filteredLOS[i].key]: { value: formLengthOfStayValue }
          });
          callback();
        }
      }
    }
  };

  const checkEmptyInput = (rule, value, callback) => {
    if (!value) {
      callback('Please enter a discount.');
    } else {
      callback();
    }
  };

  const checkDaysBeforeArrival = (rule, value, callback) => {
    if (Number(value) === 0 || value === undefined) {
      callback('Minimum is 1 day');
    } else if (Number(value) > 28) {
      callback('Last-Minute discounts can start up to 28 days before arrival');
    } else {
      callback();
    }
  };

  const checkMonthsBeforeArrival = (rule, value, callback) => {
    if (Number(value) === 0) {
      callback('Minimum is 1 month');
    } else if (Number(value) > 36) {
      callback('You can only schedule discounts up to 36 months ahead');
    } else {
      callback();
    }
  };

  const getNightlyPriceDiscountValues = values => {
    const nightlyPriceDiscountValues = [];
    if (isNightlyPriceOpen) {
      if (values) {
        nightlyPriceDiscountValues.push({
          priceChange: values.nightlyPrice * values.nightlyPriceSign,
          priceChangeType: 'PERCENT',
          ruleType: 'SEASONAL_ADJUSTMENT'
        });
      }
    } else {
      if (nightlyPriceDiscount && nightlyPriceDiscount.priceChange !== 0) {
        nightlyPriceDiscountValues.push({
          priceChange: nightlyPriceDiscount.priceChange,
          priceChangeType: 'PERCENT',
          ruleType: 'SEASONAL_ADJUSTMENT'
        });
      }
    }
    return nightlyPriceDiscountValues;
  };

  const getLengthOfStayDiscountValues = values => {
    const lengthOfStayValues = [];
    if (isLengthOfStayOpen) {
      lengthOfStayDiscountSelection.forEach(item => {
        if (values[item.key] || values[item.key] === 0) {
          lengthOfStayValues.push({
            priceChange: -values[item.key],
            priceChangeType: 'PERCENT',
            ruleType: 'STAYED_AT_LEAST_X_DAYS',
            thresholdOne: item.days
          });
        }
      });
    } else {
      lengthOfStayDiscountList.forEach(item => {
        lengthOfStayValues.push({
          priceChange: -item.defaultValue,
          priceChangeType: 'PERCENT',
          ruleType: 'STAYED_AT_LEAST_X_DAYS',
          thresholdOne: item.days
        });
      });
    }
    return lengthOfStayValues;
  };

  const getLastMinuteDiscountValues = values => {
    const lastMinuteDiscountValues = [];
    if (isLastMinuteOpen) {
      if (values.lastMinuteDiscount && values.discountStarts) {
        for (let i = 1; i < values.lastMinuteDiscount.length; i++) {
          if (values.lastMinuteDiscount[i] && values.discountStarts[i]) {
            lastMinuteDiscountValues.push({
              priceChange: -values.lastMinuteDiscount[i],
              priceChangeType: 'PERCENT',
              ruleType: 'BOOKED_WITHIN_AT_MOST_X_DAYS',
              thresholdOne: values.discountStarts[i]
            });
          }
        }
      }
    } else {
      if (lastMinuteDiscountList.length > 0) {
        lastMinuteDiscountList.forEach(list => {
          lastMinuteDiscountValues.push({
            priceChange: -list.lastMinuteDiscount,
            priceChangeType: 'PERCENT',
            ruleType: 'BOOKED_WITHIN_AT_MOST_X_DAYS',
            thresholdOne: list.discountStarts
          });
        });
      }
    }
    return lastMinuteDiscountValues;
  };

  const getEarlyBirdDiscountValues = values => {
    const earlyBirdDiscountValues = [];
    if (isEarlyBirdEditOpen) {
      if (values.earlyBirdDiscount && values.discountEnds) {
        for (let i = 1; i < values.earlyBirdDiscount.length; i++) {
          if (values.earlyBirdDiscount[i] && values.discountEnds[i]) {
            earlyBirdDiscountValues.push({
              priceChange: -values.earlyBirdDiscount[i],
              priceChangeType: 'PERCENT',
              ruleType: 'BOOKED_BEYOND_AT_LEAST_X_DAYS',
              thresholdOne: convertMonthsToDays(values.discountEnds[i])
            });
          }
        }
      }
    } else {
      if (earlyBirdDiscountList.length > 0) {
        earlyBirdDiscountList.forEach(list => {
          earlyBirdDiscountValues.push({
            priceChange: -list.earlyBirdDiscount,
            priceChangeType: 'PERCENT',
            ruleType: 'BOOKED_BEYOND_AT_LEAST_X_DAYS',
            thresholdOne: convertMonthsToDays(list.discountEnds)
          });
        });
      }
    }
    return earlyBirdDiscountValues;
  };

  const getTripLengthValues = values => {
    let tripLengthValues = {};
    if (isTripLengthOpen) {
      tripLengthValues.minNights = {
        UNSPECIFIED: values.minNights || null,
        SUNDAY: values.minNightsSunday || null,
        MONDAY: values.minNightsMonday || null,
        TUESDAY: values.minNightsTuesday || null,
        WEDNESDAY: values.minNightsWednesday || null,
        THURSDAY: values.minNightsThursday || null,
        FRIDAY: values.minNightsFriday || null,
        SATURDAY: values.minNightsSaturday || null
      };

      tripLengthValues.maxNights = {
        UNSPECIFIED: values.maxNights || null
      };
    } else {
      if (tripLength) {
        tripLengthValues = tripLength;
      }
    }
    return tripLengthValues;
  };

  const getCICODaysValues = values => {
    let CICODaysValues = {
      closedForCheckin: [],
      closedForCheckout: []
    };
    if (isCICODaysOpen) {
      if (values.CICODaysCheckIn.length > 0) {
        CICODaysValues.closedForCheckin = values.CICODaysCheckIn;
      }
      if (values.CICODaysCheckOut.length > 0) {
        CICODaysValues.closedForCheckout = values.CICODaysCheckOut;
      }
    } else {
      if (CICODays) {
        CICODaysValues = CICODays;
      }
    }
    return CICODaysValues;
  };

  const massageAirbnbSeasonalRulesPayload = (
    values,
    nightlyPriceValues,
    lengthOfStayValues,
    lastMinuteValues,
    earlyBirdValues,
    tripLengthValues,
    CICODaysValues
  ) => {
    const pricingRulesObj =
      nightlyPriceValues.length > 0 || lengthOfStayValues.length > 0 || lastMinuteValues.length > 0 || earlyBirdValues.length > 0
        ? [...nightlyPriceValues, ...lengthOfStayValues, ...lastMinuteValues, ...earlyBirdValues]
        : [];
    const availabilityRulesObj = {
      ...tripLengthValues,
      ...CICODaysValues
    };
    return {
      color: values.color,
      title: values.ruleSetName,
      ...(pricingRulesObj && { pricingRule: pricingRulesObj }),
      ...(availabilityRulesObj && { availabilityRules: availabilityRulesObj })
    };
  };

  const handleOnSubmit = async () => {
    form.validateFieldsAndScroll({ force: true }, (err, values) => {
      if (!err) {
        handleOnIsSaving(true);
        const nightlyPriceDiscountValues = getNightlyPriceDiscountValues(values);
        const lengthOfStayDiscountValues = getLengthOfStayDiscountValues(values);
        const lastMinuteDiscountValues = getLastMinuteDiscountValues(values);
        const earlyBirdDiscountValues = getEarlyBirdDiscountValues(values);
        const tripLengthValues = getTripLengthValues(values);
        const CICODaysValues = getCICODaysValues(values);
        const airbnbSeasonalRulesPayload = massageAirbnbSeasonalRulesPayload(
          values,
          nightlyPriceDiscountValues,
          lengthOfStayDiscountValues,
          lastMinuteDiscountValues,
          earlyBirdDiscountValues,
          tripLengthValues,
          CICODaysValues
        );

        if (Object.keys(selectedRuleDetail).length > 0) {
          return putSeasonalRuleGroup(airbnbListingId, selectedRuleDetail.airbnbSeasonalRuleGroupId, airbnbSeasonalRulesPayload)
            .then(res => {
              Message.success(`Sucessfully edited seasonal rule group`);
              handleOnIsSaving(false);
              refetchRuleGroup();
              handleOnClose(res.airbnbSeasonalRuleGroupId)();
            })
            .catch(ex => {
              handleOnIsSaving(false);
              Message.error(ex.toString());
            });
        } else {
          return postSeasonalRuleGroup(airbnbListingId, airbnbSeasonalRulesPayload)
            .then(res => {
              Message.success(`Sucessfully created seasonal rule group`);
              handleOnIsSaving(false);
              refetchRuleGroup();
              handleOnClose(res.airbnbSeasonalRuleGroupId)();
            })
            .catch(ex => {
              handleOnIsSaving(false);
              Message.error(ex.toString());
            });
        }
      }
    });
  };

  const colorSelection = [
    {
      key: 'red',
      value: 0,
      color: [234, 2, 1]
    },
    {
      key: 'brown',
      value: 1,
      color: [140, 65, 0]
    },
    {
      key: 'orange',
      value: 2,
      color: [255, 157, 0]
    },
    {
      key: 'yellow',
      value: 3,
      color: [251, 255, 28]
    },
    {
      key: 'green',
      value: 4,
      color: [98, 255, 0]
    },
    {
      key: 'blue',
      value: 5,
      color: [51, 187, 255]
    },
    {
      key: 'indigo',
      value: 6,
      color: [102, 110, 255]
    },
    {
      key: 'pink',
      value: 7,
      color: [255, 102, 179]
    }
  ];

  const handleOnClose = ruleId => async e => {
    form.resetFields();
    await Promise.all(
      [setNightlyPriceEditClose(false)],
      [setLengthOfStayEditClose(false)],
      [setLastMinuteEditClose(false)],
      [setIsEarlyBirdEditOpen(false)],
      [setIsTripLengthOpen(false)],
      [setIsCICODaysOpen(false)],
      [setDefaultNightlyPriceDiscount()],
      [setLOSDiscountSelections()],
      [setDefaultLastMinuteDiscount()],
      [setDefaultEarlyBirdDiscount()],
      [setDefaultTripLength()],
      [setDefaultCICODays()]
    ).then(() => {
      setSelectedRuleId(ruleId);
      handleOnCloseEditModal(e);
    });
  };

  const checkIsCustomizeMinNightsSet = () => {
    if (
      tripLength.minNights &&
      (tripLength.minNights['SUNDAY'] ||
        tripLength.minNights['MONDAY'] ||
        tripLength.minNights['TUESDAY'] ||
        tripLength.minNights['WEDNESDAY'] ||
        tripLength.minNights['THURSDAY'] ||
        tripLength.minNights['FRIDAY'] ||
        tripLength.minNights['SATURDAY'])
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkIsCustomMinNightsOpen = form => {
    if (form.getFieldValue('customizeMinNights') && form.getFieldValue('customizeMinNights')[0]) {
      return true;
    } else if (!form.getFieldValue('customizeMinNights') && checkIsCustomizeMinNightsSet()) {
      return true;
    } else {
      return false;
    }
  };

  const daysOfWeek = [
    { label: 'N/A', value: 'UNSPECIFIED', code: undefined },
    { label: 'Sunday', value: 'SUNDAY', code: 0 },
    { label: 'Monday', value: 'MONDAY', code: 1 },
    { label: 'Tuesday', value: 'TUESDAY', code: 2 },
    { label: 'Wednesday', value: 'WEDNESDAY', code: 3 },
    { label: 'Thursday', value: 'THURSDAY', code: 4 },
    { label: 'Friday', value: 'FRIDAY', code: 5 },
    { label: 'Saturday', value: 'SATURDAY', code: 6 }
  ];

  return (
    <Modal
      title="Set - Seasonal rule"
      style={{ minHeight: '80vh', minWidth: '340px', overflow: 'hidden' }}
      width="90vw"
      visible={isShowEditModal}
      onCancel={handleOnClose(selectedRuleId)}
      footer={[
        <Button key="back" onClick={handleOnClose(selectedRuleId)}>
          Cancel
        </Button>,
        <Button key="submit" onClick={handleOnSubmit} type="primary" loading={isSaveLoading}>
          Save
        </Button>
      ]}
    >
      <Row justify="start" gutter={16}>
        <Col sm={24} md={24} lg={16}>
          <Row
            justify="start"
            onMouseEnter={() => {
              setSideMessageSection('instructions');
            }}
          >
            <h3>Edit rule-set</h3>
            <p>Edit pricing and availability rules to apply across dates and listings.</p>
          </Row>
          <Row justify="start" gutter={8} onMouseEnter={() => setSideMessageSection('nameAndColor')}>
            <Col sm={24} md={12} lg={12}>
              <FormInput
                form={form}
                formLabel="Rule-set name"
                name={`ruleSetName`}
                requiredErrorMessage="Please input rule-set name"
                defaultValue={selectedRuleDetail && selectedRuleDetail.title}
              />
            </Col>
            <Col sm={24} md={12} lg={12}>
              <SeasonalRuleColorPicker
                form={form}
                formLabel="Color (to be used on the Airbnb Calendar)"
                name={`color`}
                defaultValue={selectedRuleDetail && selectedRuleDetail.color}
                buttonStyle="solid"
                selections={colorSelection}
                requiredErrorMessage="Please select a color"
              />
            </Col>
          </Row>
          <Row justify="start" gutter={8}>
            <h2>Pricing</h2>
            <Col>
              <Row>
                <Col
                  onMouseEnter={() => {
                    setSideMessageSection('nightlyPrice');
                  }}
                >
                  <div>
                    <strong>Nightly Price</strong>
                    <Button style={{ float: 'right', paddingBottom: 10 }} type="link" onClick={() => handleOnIsNightlyPriceOpen()}>
                      {isNightlyPriceOpen ? (
                        <>
                          Cancel <Icon type="up" />
                        </>
                      ) : (
                        <>
                          Customize <Icon type="down" />
                        </>
                      )}
                    </Button>
                  </div>
                  {isNightlyPriceOpen ? (
                    <div>
                      <br />
                      <p>Set a price adjustment for any bookings within a date range. This will be applied before other pricing rules.</p>
                      <Row gutter={8}>
                        <Col xs={20} sm={20} md={10} lg={10}>
                          <FormInputNumber
                            form={form}
                            name={'nightlyPrice'}
                            defaultValue={Math.abs(nightlyPriceDiscount.priceChange)}
                            minValue={0}
                            maxValue={500}
                            formatter={value => `${value}%`}
                            parser={value => value.replace('%', '')}
                            extraRules={[
                              {
                                validator: checkNightlyAdjustmentRange
                              }
                            ]}
                          />
                        </Col>
                        <Col xs={20} sm={20} md={12} lg={12}>
                          <FormRadioButton
                            form={form}
                            buttonStyle={'solid'}
                            name={'nightlyPriceSign'}
                            selections={[
                              { key: 'increase', displayValue: 'Increase', value: 1 },
                              { key: 'decrease', displayValue: 'Decrease', value: -1 }
                            ]}
                            defaultValue={nightlyPriceDiscount.priceChange > 0 ? 1 : -1}
                            extraRules={[
                              {
                                validator: checkNightlyAdjustmentRange
                              }
                            ]}
                          />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div>
                      {showNightlyPriceDiscount && (
                        <>
                          <br />
                          {showNightlyPriceDiscount}
                        </>
                      )}
                    </div>
                  )}
                  <Divider />
                </Col>
                <Col onMouseEnter={() => setSideMessageSection('lengthOfStay')}>
                  <div>
                    <strong>Length-of-stay discounts</strong>
                    <Button style={{ float: 'right', paddingBottom: 10 }} type="link" onClick={() => handleOnIsLengthOfStayOpen()}>
                      {isLengthOfStayOpen ? (
                        <>
                          Cancel <Icon type="up" />
                        </>
                      ) : (
                        <>
                          Customize <Icon type="down" />
                        </>
                      )}
                    </Button>
                  </div>
                  {isLengthOfStayOpen ? (
                    <div>
                      <br />
                      <p>Set discounts based on weekly or monthly stays, or customize your own.</p>
                      {generateLengthOfStayDiscountLists()}
                      <Row>
                        <Col xs={20} sm={20} md={12} lg={6}>
                          <FormSelection
                            form={form}
                            formLabel="Add a custom discount"
                            name={`addCustomDiscount`}
                            defaultValue={'Select nights'}
                            selections={lengthOfStayDiscountSelection}
                            onChange={handleOnAddLengthOfStayDiscount}
                          />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div>
                      {showLengthOfStayDiscountList.length > 0 && (
                        <>
                          <br />
                          <ShowMoreList itemLists={showLengthOfStayDiscountList} itemRow={2} />
                        </>
                      )}
                    </div>
                  )}
                  <Divider />
                </Col>
                <Col onMouseEnter={() => setSideMessageSection('lastMinute')}>
                  <div>
                    <strong>Last-minute discounts</strong>
                    <Button style={{ float: 'right', paddingBottom: 10 }} type="link" onClick={() => handleOnIsLastMinuteOpen()}>
                      {isLastMinuteOpen ? (
                        <>
                          Cancel <Icon type="up" />
                        </>
                      ) : (
                        <>
                          Customize <Icon type="down" />
                        </>
                      )}
                    </Button>
                  </div>
                  {isLastMinuteOpen ? (
                    <div>
                      <br />
                      <p>Offer a discount off the nightly price for bookings that happen close to arrival. Set by days before.</p>
                      {lastMinuteDiscountList.map(list => {
                        return (
                          <Row justify="space-around" gutter={8} key={list.key}>
                            <Col style={{ minWidth: '250px' }} xs={11} sm={11} md={10} lg={6}>
                              <FormInputNumber
                                form={form}
                                formLabel="Discount starts (Days before)"
                                name={`discountStarts[${list.key}]`}
                                defaultValue={list.discountStarts || 0}
                                minValue={0}
                                placeholder="day(s) before arrival"
                                onChange={() => handleOnChangeLastMinuteDiscount(form)}
                                extraRules={[
                                  {
                                    validator: checkDaysBeforeArrival
                                  }
                                ]}
                              />
                            </Col>
                            <Col style={{ minWidth: '250px' }} xs={11} sm={11} md={10} lg={6}>
                              <FormInputNumber
                                form={form}
                                formLabel="Discount"
                                name={`lastMinuteDiscount[${list.key}]`}
                                defaultValue={list.lastMinuteDiscount || 0}
                                minValue={0}
                                maxValue={99}
                                onChange={() => handleOnChangeLastMinuteDiscount(form)}
                                extraRules={[
                                  {
                                    validator: checkEmptyInput
                                  }
                                ]}
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')}
                              />
                            </Col>
                            <Col span={2}>
                              <Icon
                                type="delete"
                                style={{ marginTop: 48, fontSize: 22 }}
                                onClick={() => handleOnRemoveLastMinuteDiscount(list.key, form)}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                      <Row>
                        <Col>
                          <Button type="primary" onClick={() => handleOnAddLastMinuteDiscount(form)}>
                            Add another
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div>
                      {showLastMinuteDiscountList.length > 0 && (
                        <>
                          <br />
                          <ShowMoreList itemLists={showLastMinuteDiscountList} itemRow={2} />
                        </>
                      )}
                    </div>
                  )}
                  <Divider />
                </Col>
                <Col onMouseEnter={() => setSideMessageSection('earlyBird')}>
                  <div>
                    <strong>Early-bird discounts</strong>
                    <Button style={{ float: 'right', paddingBottom: 10 }} type="link" onClick={() => handleOnIsEarlyBirdOpen()}>
                      {isEarlyBirdEditOpen ? (
                        <>
                          Cancel <Icon type="up" />
                        </>
                      ) : (
                        <>
                          Customize <Icon type="down" />
                        </>
                      )}
                    </Button>
                  </div>
                  {isEarlyBirdEditOpen ? (
                    <div>
                      <br />
                      <p>Offer a discount for bookings that happen well in advance. Set by months in advanced.</p>
                      {earlyBirdDiscountList.map(list => {
                        return (
                          <Row justify="space-around" gutter={8} key={list.key}>
                            <Col style={{ minWidth: '250px' }} xs={11} sm={11} md={10} lg={6}>
                              <FormInputNumber
                                form={form}
                                formLabel="Discount ends (Months in advance)"
                                name={`discountEnds[${list.key}]`}
                                defaultValue={list.discountEnds || 0}
                                minValue={0}
                                maxValue={99}
                                placeholder="month(s) before arrival"
                                onChange={() => handleOnChangeEarlyBirdDiscount(form)}
                                extraRules={[
                                  {
                                    validator: checkMonthsBeforeArrival
                                  }
                                ]}
                              />
                            </Col>
                            <Col style={{ minWidth: '250px' }} xs={11} sm={11} md={10} lg={6}>
                              <FormInputNumber
                                form={form}
                                formLabel="Discount"
                                name={`earlyBirdDiscount[${list.key}]`}
                                defaultValue={list.earlyBirdDiscount || 0}
                                minValue={0}
                                maxValue={99}
                                onChange={() => handleOnChangeEarlyBirdDiscount(form)}
                                extraRules={[
                                  {
                                    validator: checkEmptyInput
                                  }
                                ]}
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')}
                              />
                            </Col>
                            <Col span={2}>
                              <Icon
                                type="delete"
                                style={{ marginTop: 48, fontSize: 22 }}
                                onClick={() => handleOnRemoveEarlyBirdDiscount(list.key, form)}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                      <Row>
                        <Col>
                          <Button type="primary" onClick={() => handleOnAddEarlyBirdDiscount(form)}>
                            Add another
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div>
                      {showEarlyBirdDiscountList.length > 0 && (
                        <>
                          <br />
                          <ShowMoreList itemLists={showEarlyBirdDiscountList} itemRow={2} />
                        </>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Divider></Divider>
            <h2>Availability</h2>
            <Col>
              <Row>
                <Col onMouseEnter={() => setSideMessageSection('tripLength')}>
                  <div>
                    <strong>Trip Length</strong>
                    <Button style={{ float: 'right', paddingBottom: 10 }} type="link" onClick={() => handleOnIsTripLengthOpen()}>
                      {isTripLengthOpen ? (
                        <>
                          Cancel <Icon type="up" />
                        </>
                      ) : (
                        <>
                          Customize <Icon type="down" />
                        </>
                      )}
                    </Button>
                  </div>
                  {isTripLengthOpen ? (
                    <div>
                      <br />
                      <p>Set the minimum and maximum stay, and customize it by day if needed.</p>
                      <Row gutter={8}>
                        <Col xs={20} sm={20} md={10} lg={10}>
                          <FormInputNumber
                            form={form}
                            formLabel={'Minimum stay'}
                            name={'minNights'}
                            defaultValue={tripLength.minNights ? tripLength.minNights['UNSPECIFIED'] : null}
                            minValue={0}
                            maxValue={9999}
                            placeholder={'nights'}
                          />
                        </Col>
                        <Col xs={20} sm={20} md={10} lg={10}>
                          <FormInputNumber
                            form={form}
                            formLabel={'Maximum stay'}
                            name={'maxNights'}
                            defaultValue={tripLength.maxNights ? tripLength.maxNights['UNSPECIFIED'] : null}
                            minValue={0}
                            maxValue={9999}
                            placeholder={'nights'}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormCheckbox
                            form={form}
                            name="customizeMinNights"
                            defaultValue={checkIsCustomizeMinNightsSet() ? [true] : [false]}
                            selections={[{ value: true, label: 'Customize minimum stay by check-in day' }]}
                          />
                        </Col>
                      </Row>
                      {checkIsCustomMinNightsOpen(form) && (
                        <Row>
                          <Col xs={14} sm={14} md={14} lg={14}>
                            {daysOfWeek.map(dow => {
                              return (
                                dow.value !== 'UNSPECIFIED' && (
                                  <div
                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
                                    key={dow.label + dow.value}
                                  >
                                    <span style={{ marginBottom: '24px', color: 'rgba(0, 0, 0, 0.85)' }}>{dow.label}s check-in</span>
                                    <FormInputNumber
                                      form={form}
                                      name={`minNights${dow.label}`}
                                      defaultValue={tripLength.minNights ? tripLength.minNights[`${dow.value}`] : null}
                                      placeholder={'nights'}
                                      minValue={0}
                                      maxValue={9999}
                                    />
                                  </div>
                                )
                              );
                            })}
                          </Col>
                        </Row>
                      )}
                    </div>
                  ) : (
                    <div>
                      {showTripLength.length > 0 && (
                        <>
                          <br />
                          <ShowMoreList itemLists={showTripLength} itemRow={2} />
                        </>
                      )}
                    </div>
                  )}
                  <Divider />
                </Col>
                <Col onMouseEnter={() => setSideMessageSection('CICODays')}>
                  <div>
                    <strong>Check-in and checkout days</strong>
                    <Button style={{ float: 'right', paddingBottom: 10 }} type="link" onClick={() => handleOnIsCICODaysOpen()}>
                      {isCICODaysOpen ? (
                        <>
                          Cancel <Icon type="up" />
                        </>
                      ) : (
                        <>
                          Customize <Icon type="down" />
                        </>
                      )}
                    </Button>
                  </div>
                  {isCICODaysOpen ? (
                    <div>
                      <br />
                      <p>Select the days when you don’t want guests to check in and check out</p>
                      <Row gutter={8}>
                        <Col xs={10} sm={10} md={10} lg={10}>
                          <FormCheckbox
                            form={form}
                            formLabel={'Guests can’t check in on'}
                            name={'CICODaysCheckIn'}
                            defaultValue={CICODays.closedForCheckin ? CICODays.closedForCheckin : []}
                            selections={daysOfWeek.filter(dow => dow.code !== undefined).map(dow => ({ value: dow.value, label: dow.label }))}
                          />
                        </Col>
                        <Col xs={10} sm={10} md={10} lg={10}>
                          <FormCheckbox
                            form={form}
                            formLabel={'Guests can’t check out on'}
                            name={'CICODaysCheckOut'}
                            defaultValue={CICODays.closedForCheckout ? CICODays.closedForCheckout : []}
                            selections={daysOfWeek.filter(dow => dow.code !== undefined).map(dow => ({ value: dow.value, label: dow.label }))}
                          />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div>
                      {showCICODays.length > 0 && (
                        <>
                          <br />
                          <ShowMoreList itemLists={showCICODays} itemRow={2} />
                        </>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={0} sm={0} md={0} lg={8}>
          <SideMessage />
        </Col>
      </Row>
    </Modal>
  );
};

export default Form.create()(SeasonalRuleEdit);
