import React from 'react';
import { Chart, Geom, Axis, Tooltip, Coord, Label, Legend } from 'bizcharts';
import PropTypes from 'prop-types';
import DataSet from '@antv/data-set';

class PieChart extends React.Component {
  static propTypes = {
    graphData: PropTypes.array.isRequired,
    dimension: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired
  };

  render() {
    const { graphData, dimension, field, isCustomColor } = this.props;
    const { DataView } = DataSet;
    const dv = new DataView();
    dv.source(graphData).transform({
      type: 'percent',
      field,
      dimension,
      as: 'percent'
    });
    const cols = {
      percent: {
        formatter: val => {
          val = (Number(val) * 100).toFixed(2) + '%';
          return val;
        }
      }
    };
    const colors = isCustomColor
      ? [
          'color',
          color => {
            return color;
          }
        ]
      : dimension;
    const legendExtraProps = isCustomColor
      ? {
          custom: true,
          items: graphData.map(a => {
            return {
              value: a.type,
              marker: {
                symbol: 'circle',
                fill: a.color,
                radius: 5
              }
            };
          })
        }
      : {};
    return (
      <Chart data={dv} scale={cols} forceFit>
        <Coord type={'theta'} radius={0.75} innerRadius={0.6} />
        <Axis name="percent" />
        <Geom
          type="intervalStack"
          position="percent"
          color={colors}
          tooltip={[
            `${dimension}*percent`,
            (name, percent) => {
              percent = (Number(percent) * 100).toFixed(2) + '%';
              return {
                name,
                value: percent
              };
            }
          ]}
          style={{
            lineWidth: 1,
            stroke: '#fff'
          }}
        >
          <Legend
            clickable={false}
            position="bottom-center"
            textStyle={{
              fontSize: '14'
            }}
            {...legendExtraProps}
          />

          <Tooltip showTitle={false} itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>' />

          <Label
            content="percent"
            formatter={(val, item) => {
              return item.point[dimension] + ': ' + val;
            }}
          />
        </Geom>
      </Chart>
    );
  }
}

export default PieChart;
