import { httpClient } from './../httpClient';

// FIX ME: create a new endpoint in BE to get guests details only.
// No need return all bookings back - too heavy.
export const getBookings = query => {
  return httpClient
    .get('/booking', query)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};
