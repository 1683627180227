import styled from '@emotion/styled';
import { Checkbox, Input, Radio, Row } from 'antd';

export const StyledFilterSearchContainer = styled(Row)`
  padding: 8px;
  border-radius: 6px;
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
`;

export const StyledFilterSearchInput = styled(Input)`
  width: 150px;
  margin-right: 8px;
`;

export const StyledFilterRadioContainer = styled.div`
  padding: 8px;
  max-height: 356px;
`;

export const StyledFilterRadioGroup = styled(Radio.Group)`
  max-height: 304px;
  width: 100%;
  overflow-y: auto;
`;

export const StyledFilterRadioButton = styled(Radio)`
  height: 32px;
  line-height: 32px;
`;

export const StyledFilterCheckboxContainer = styled.div`
  padding: 8px;
  max-height: 356px;
`;

export const StyledFilterCheckboxGroup = styled(Checkbox.Group)`
  max-height: 304px;
  width: 100%;
  overflow-y: auto;
`;

export const StyledFilterCheckboxSelection = styled(Checkbox)`
  height: 32px;
  line-height: 32px;
`;
