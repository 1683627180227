import React, { Component, useEffect, useState } from 'react';
import { Row, Col, Collapse, Button } from 'antd';
import { isArray } from 'util';

const PayoutAttachment = props => {
  const [units, setUnit] = useState([]);

  useEffect(() => {
    //    console.log(props, 9);
    if (props.data && props.data.payoutCalendars) {
      setUnit(props.data.payoutCalendars);
    }
  }, []);

  const capitalizeFirstLetter = string => {
    var buffer = String(string).replace('_', ' ');
    return buffer.charAt(0).toUpperCase() + buffer.slice(1);
  };

  const attachment = expenses => {
    var buffer = [];
    console.log(expenses, 22);
    if (expenses) {
      for (const key in expenses.expenses) {
        if (isArray(expenses.expenses[key]) === true && expenses.expenses[key].length > 0) {
          buffer.push({
            key: key,
            data: expenses.expenses[key]
          });
        }
      }
    }

    if (buffer.length === 0) {
      return 'No attachment data available';
    }

    // Sort other at last
    const others_index = buffer.findIndex(x => x.key === 'others');
    if (others_index !== -1) {
      buffer.push(buffer[others_index]);
      buffer.splice(others_index, 1);
    }

    return (
      <Row>
        {buffer.map(expenses => (
          <Col key={expenses.key}>
            <Row>
              <Col style={{ fontWeight: 600, fontSize: 18 }}>{capitalizeFirstLetter(expenses.key)}</Col>
              {expenses.key === 'toiletry' || expenses.key === 'others'
                ? expenses.data.map(item => (
                    <Row key={item._id}>
                      <Col style={{ fontWeight: 600, paddingLeft: 8 }}>{capitalizeFirstLetter(item.label)}</Col>
                      {item.expensesAttachment.map(attachment => (
                        <Col key={attachment.link}>
                          <Row>
                            <Col>
                              <Button type="link" href={attachment.link} target="_blank">
                                > {attachment.name}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      ))}
                    </Row>
                  ))
                : expenses.data.map(attachment => (
                    <Button type="link" href={attachment.link} target="_blank">
                      > {attachment.name}
                    </Button>
                  ))}
            </Row>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <Row justify="start" style={{ width: '100%' }}>
      <Col span={24}>
        <Collapse
          accordion
          style={{
            background: '#f7f7f7',
            borderRadius: 4,
            marginBottom: 24,
            border: 0,
            overflow: 'hidden'
          }}
        >
          {units.map((unit, index) => (
            <Collapse.Panel header={`${unit.unit.name} (${unit.property.name})`} key={index}>
              <div>{attachment(unit.expenses)}</div>
            </Collapse.Panel>
          ))}
        </Collapse>
      </Col>
    </Row>
  );
};

export default PayoutAttachment;
