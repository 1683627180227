import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Row, Switch, Select, Card, Modal, Col, notification, Radio, Input } from 'antd';

import AutoSearch from '../../../../../components/AutoSearch';
import BookingcomSyncForm from './components/BookingcomSyncForm';
import OTARateInput from '../../../OTARateInput/OTARateInput';

import { REACT_APP_IS_SHOW_BCOM_PUSH_CONTENT, REACT_APP_IS_SHOW_BCOM_PRICING_SETTINGS } from 'config/env';

import { getBookingComFeePolicyConstant, getRateModifierTypeConstant, getRateModifierSignConstant } from 'utils/apis/constants';
import { STANDARD_RATE_CODE } from 'utils/constants';
import { errorHandlerWrapper, generatePercentageFromDisplay, guard } from 'utils/general';
import { getBookingcoms, putBookingcom, pushBookingcom, getBookingcomProduct, getBookingcomRoomTypeNames } from 'utils/apis/integration';
import { getBookingcomRoomTypes } from 'utils/apis/constants';
import { getWebRateByRoomType } from 'utils/apis/rate';

import BookingcomAdvancedOptionsModal from './components/BookingcomAdvancedOptionsModal';

const Option = Select.Option;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

const syncTypes = {
  PUSHNEW: 'pushnew',
  SYNCEXISTING: 'syncexisting'
};

const checkIsPushNew = syncType => {
  return !!REACT_APP_IS_SHOW_BCOM_PUSH_CONTENT && syncType === syncTypes.PUSHNEW;
};

class BookingcomForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rateModifierTypeConstant: [],
      rateModifierSignConstant: [],
      bookingcomFeePolicyConstant: {},

      selectedBookingcomId: '',
      selectedHotelId: '',
      legalEntity: '',
      bookingcomPropertyName: '',

      roomMapping: [],
      bookingcom: [],
      roomTypeOptions: [],
      fullRoomTypeOptions: [],
      savedBookingcomData: [],
      syncType: syncTypes.SYNCEXISTING,
      bookingcomRoomTypeOptions: [],
      bookingcomRoomTypeNamesOptions: {},
      currentRoomName: {},
      policies: {},
      pricingSetting: {},

      isSaving: false,
      isBookingcomSyncModalVisible: false,
      isAdvancedOptionsModalVisible: false,
      currency: 'RM'
    };
  }

  componentDidMount = async () => {
    const { data, property } = this.props;

    const [
      [rateModifierTypeConstant, rateModifierSignConstant, bookingcomFeePolicyConstant],
      [bookingcom, bookingcomRoomTypeOptions]
    ] = await Promise.all([this.getConstants(), this.getBookingComDetails()]);

    let selectedBookingcomId = '';
    let selectedHotelId = '';
    let roomMapping = [];
    let policies = {};
    let currency = guard(() => data.currency, 'RM');

    if (!!data) {
      roomMapping = data.roomTypes.map(roomType => {
        return {
          roomType: roomType.roomType._id,
          rates: roomType.rates || [],
          bookingcomRoomTypeId: roomType.bookingcomRoomTypeId
        };
      });
      selectedBookingcomId = data._id;
      selectedHotelId = data.foreignPropertyId;
      policies = data.policies;
    }

    const pricingSetting = this.getPricing(policies, bookingcomFeePolicyConstant);
    this.constructRoomTypeOptions(selectedBookingcomId, bookingcom);

    this.setState(
      {
        rateModifierTypeConstant,
        rateModifierSignConstant,
        bookingcomFeePolicyConstant,
        selectedBookingcomId,
        selectedHotelId,
        bookingcomPropertyName: !!property ? property.name : '',
        bookingcom,
        bookingcomRoomTypeOptions,
        policies,
        pricingSetting,
        roomMapping,
        currency
      },
      () => {
        this.getBookingcomId(selectedHotelId);
      }
    );
  };

  getConstants = () => {
    return errorHandlerWrapper(
      Promise.all([
        getRateModifierTypeConstant().then(constant => Object.values(constant)),
        getRateModifierSignConstant().then(constant => Object.values(constant)),
        getBookingComFeePolicyConstant()
      ]),
      [[], [], {}]
    );
  };

  getBookingComDetails = () => {
    return Promise.all([
      errorHandlerWrapper(getBookingcoms().then(async res => res.data), []),
      errorHandlerWrapper(getBookingcomRoomTypes().then(bcomRoomTypeData => Object.values(bcomRoomTypeData)), [])
    ]);
  };

  getRateByRoomTypeAndRatePlanId = (roomTypeId, bookingcomRateId) => {
    const { roomMapping } = this.state;
    const roomMap = roomMapping.find(roomMap => roomMap.roomType === roomTypeId);
    let currentRate;
    if (bookingcomRateId) {
      currentRate = roomMap.rates.find(rate => String(rate.bookingcomRateId) === String(bookingcomRateId));
    } else {
      currentRate = roomMap.rates[0];
    }
    if (currentRate && currentRate.rate) {
      return currentRate.rate;
    } else {
      getWebRateByRoomType(roomTypeId).then(webRates => {
        const stdRate = webRates.data.find(rate => rate.code === STANDARD_RATE_CODE);
        if (stdRate) {
          const { weekday, weekend, isDerived } = stdRate;
          this.updateRates(roomTypeId, bookingcomRateId)({ weekday, weekend, isDerived });
        }
      });
    }
  };

  extractBookingcomSyncInfo = () => {
    const { savedBookingcomData } = this.state;
    const { property } = this.props;

    if (savedBookingcomData && Object.keys(savedBookingcomData).length > 0) {
      return {
        property: property._id,
        propertyName: property.name,
        bookingcomPropertyId: savedBookingcomData.bookingcomPropertyId,
        bookingcomPropertyName: savedBookingcomData.propertyData ? savedBookingcomData.propertyData.HotelName : '',
        rooms: savedBookingcomData.rooms.filter(room => room.roomType)
      };
    }
  };

  onConfirm = e => {
    const { syncType } = this.state;

    const title =
      syncType === syncTypes.PUSHNEW ? `Are you sure you want to create this new Hotel?` : 'Are you sure you want to save this integration setting?';
    const onOkAction = this.onBookingcomSubmit;

    Modal.confirm({
      title,
      content: '',
      okText: 'Continue',
      okType: 'warning',
      cancelText: 'Back',
      onOk() {
        return onOkAction();
      },
      onCancel() {}
    });
  };

  constructRoomTypeOptions = (selectedBookingcomId, bookingcom) => {
    let fullRoomTypeOptions = [];
    let selectedBookingcomObj = null;
    if (selectedBookingcomId) {
      selectedBookingcomObj = bookingcom && bookingcom.find(bookingcomItem => String(bookingcomItem._id) === selectedBookingcomId);
    }
    let bookingcomRooms = [];

    if (selectedBookingcomObj) {
      bookingcomRooms = selectedBookingcomObj.rooms;
    }
    fullRoomTypeOptions = bookingcomRooms.map(room => ({
      bookingcomRoomTypeId: room.bookingcomRoomTypeId,
      roomType: room.roomType,
      bookingcomRoomType: room.roomData && room.roomData.bookingcomRoomTypeName
    }));

    this.setState({
      fullRoomTypeOptions
    });
  };

  getBookingcomId = async selectedHotelId => {
    const { selectedBookingcomId: stateSelectedBookingcomId, bookingcom: stateBookingcom } = this.state;
    const { property } = this.props;

    let selectedBookingcomId = stateSelectedBookingcomId;
    let bookingcom = stateBookingcom;
    let currency = 'RM';

    if (!!selectedHotelId) {
      const selectedBookingcomObj =
        stateBookingcom && stateBookingcom.length > 0
          ? stateBookingcom.find(
              stateBookingcom =>
                stateBookingcom.propertyData && stateBookingcom.propertyData.ID && String(stateBookingcom.propertyData.ID) === String(selectedHotelId)
            )
          : null;

      if (selectedBookingcomObj) {
        selectedBookingcomId = selectedBookingcomObj._id;
        currency = selectedBookingcomObj.propertyData.CurrencyCode;
      } else {
        const { bookingComId, bookingComData } = await errorHandlerWrapper(
          getBookingcomProduct(selectedHotelId, property.host).then(success =>
            getBookingcoms().then(async bookingcomRes => {
              const newBookingcomObj = bookingcomRes.data.find(bookingcom => String(bookingcom.propertyData.ID) === String(selectedHotelId));

              return { bookingComId: newBookingcomObj ? newBookingcomObj._id : '', bookingcomData: bookingcomRes.data };
            })
          ),
          { bookingComId: '', bookingComData: stateBookingcom }
        );

        selectedBookingcomId = bookingComId;
        bookingcom = bookingComData;
      }

      this.constructRoomTypeOptions(selectedBookingcomId, bookingcom);
    }

    this.setState({ selectedBookingcomId, bookingcom, currency });
  };

  onChangeHotelId = e => {
    if (!e) {
      return;
    }
    this.setState({
      selectedHotelId: e
    });
    this.getBookingcomId(e);
  };

  onChangeBookingcom = e => {
    this.setState({
      selectedBookingcomId: e
    });
    this.constructRoomTypeOptions(e);
  };

  onInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleToSync = roomType => isSync => {
    let { roomMapping } = this.state;
    if (!isSync) {
      roomMapping = roomMapping.filter(roomMap => roomMap.roomType !== roomType);
    } else {
      roomMapping.push({
        roomType,
        rates: []
      });
    }
    this.setState({
      roomMapping
    });
  };

  changeBookingcomRoomType = roomType => value => {
    const { syncType, currentRoomName, roomMapping } = this.state;
    let roomMap = roomMapping.find(roomMap => roomMap.roomType === roomType);
    if (roomMap) {
      if (!checkIsPushNew(syncType)) {
        roomMap.bookingcomRoomTypeId = value;
        this.setState({
          roomMapping
        });
      } else {
        roomMap.bookingcomRoomType = value;
        this.setState(
          {
            roomMapping,
            currentRoomName: {
              ...currentRoomName,
              [roomType]: undefined
            }
          },
          () => {
            return !!value && this.populateBookingComRoomTypeName(value);
          }
        );
      }
      this.setState({
        roomMapping
      });
    }
  };

  updateRates = (roomType, bookingcomRateId) => newRates => {
    if (newRates) {
      const { roomMapping } = this.state;
      const roomMappingForRoomType = roomMapping.find(mapping => mapping.roomType === roomType);
      let currentRoomRate;
      if (bookingcomRateId) {
        currentRoomRate = roomMappingForRoomType.rates.find(rate => String(rate.bookingcomRateId) === String(bookingcomRateId));
      } else {
        currentRoomRate = roomMappingForRoomType.rates[0];
      }
      if (currentRoomRate) {
        currentRoomRate.rate = {
          ...currentRoomRate.rate,
          ...newRates
        };
      } else {
        roomMappingForRoomType.rates.push({ bookingcomRateId, rate: newRates });
      }

      this.setState({ roomMapping });
    }
  };

  populateBookingComRoomTypeName = async bookingcomRoomTypecode => {
    const { bookingcomRoomTypeNamesOptions } = this.state;
    if (!bookingcomRoomTypeNamesOptions[bookingcomRoomTypecode]) {
      const bookingcomRoomTypeNames = await getBookingcomRoomTypeNames(bookingcomRoomTypecode);
      const namesOption = bookingcomRoomTypeNames.map((name, i) => {
        return {
          code: i,
          label: name
        };
      });
      this.setState({
        bookingcomRoomTypeNamesOptions: {
          ...bookingcomRoomTypeNamesOptions,
          [bookingcomRoomTypecode]: namesOption
        }
      });
    }
  };

  handleOnChangeBookingcomRoomTypeNames = roomType => value => {
    const { roomMapping, currentRoomName } = this.state;
    let roomMap = roomMapping.find(roomMap => roomMap.roomType === roomType);
    roomMap.bookingcomRoomTypeName = value;
    this.setState({
      roomMapping,
      currentRoomName: {
        ...currentRoomName,
        [roomType]: value
      }
    });
  };

  onBookingcomSubmit = async () => {
    const { roomMapping, bookingcom, selectedBookingcomId, bookingcomPropertyName, syncType, legalEntity, pricingSetting } = this.state;
    const isReady = this.checkIsReady();
    this.setState({
      isSaving: true
    });
    if (isReady) {
      const { property, onAfterSave, onClose } = this.props;
      const selectedBookingcomObj = bookingcom.find(bookingcom => String(bookingcom._id) === String(selectedBookingcomId));
      if (checkIsPushNew(syncType)) {
        const bookingcomData = {
          bookingcomPropertyName,
          roomMapping,
          pricingSetting
        };
        // Create bookingcom
        pushBookingcom(property._id, bookingcomData, legalEntity)
          .then(res => {
            this.setState({
              savedBookingcomData: res.data,
              isSaving: false
            });
            onAfterSave();
            onClose();
          })
          .catch(ex => {
            this.setState({
              isSaving: false
            });
            notification.error({
              message: ex.message
            });
          });
      } else {
        //Add here
        const bookingcomData = {
          propertyId: property._id,
          bookingcomId: selectedBookingcomId,
          bookingcomPropertyId: selectedBookingcomObj.bookingcomPropertyId,
          bookingcomPropertyName: selectedBookingcomObj.propertyData.HotelName,
          roomMapping,
          pricingSetting
        };

        // Update nebula collection
        putBookingcom(bookingcomData)
          .then(res => {
            this.setState({
              savedBookingcomData: res.data,
              isBookingcomSyncModalVisible: true,
              isSaving: false
            });
            notification.success({
              message: 'Successfully saved Booking.com settings'
            });
            onAfterSave();
          })
          .catch(ex => {
            this.setState({
              isSaving: false
            });
            notification.error({
              message: String(ex.message)
            });
          });
      }
    } else {
      notification.error({
        message: 'No room selected. Please select at least one.'
      });
    }
  };

  handleModalCancel = () => {
    this.setState({
      isBookingcomSyncModalVisible: false
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  selectedHotelDetail = selectedBookingcomObj => {
    if (!selectedBookingcomObj) {
      return <Row>Please input a valid Booking.com Hotel ID</Row>;
    } else {
      return (
        <Row>
          <div>
            <b>Found Hotel: </b> ({selectedBookingcomObj.bookingcomPropertyId}){' '}
            {selectedBookingcomObj.propertyData ? selectedBookingcomObj.propertyData.HotelName || 'No Property Name' : 'No Property Name'}
          </div>
        </Row>
      );
    }
  };

  onChangeStateChange = field => e => {
    this.setState({ [field]: e });
  };

  onSyncTypeChange = e => {
    this.setState({
      syncType: e.target.value,
      roomMapping: []
    });
  };

  handleOnAdvanceOptionsClick = isVisible => e => {
    e.preventDefault();
    this.setState({
      isAdvancedOptionsModalVisible: isVisible
    });
  };

  checkIsReady = () => {
    const { syncType, roomMapping, bookingcomPropertyName, selectedBookingcomId } = this.state;
    if (checkIsPushNew(syncType)) {
      return bookingcomPropertyName && roomMapping.length > 0 && !roomMapping.find(roomMapping => !roomMapping.bookingcomRoomType);
    }
    return selectedBookingcomId && roomMapping.length > 0 && !roomMapping.find(roomMapping => !roomMapping.bookingcomRoomTypeId);
  };

  massageRate = (bookingcomRates, currentRoomMapping) => {
    const { roomType } = currentRoomMapping;
    return bookingcomRates.map(bookingcomRate => {
      const { bookingcomRateId } = bookingcomRate;
      const res = {
        ...bookingcomRate,
        rate: this.getRateByRoomTypeAndRatePlanId(roomType, bookingcomRateId)
      };
      return res;
    });
  };

  constructBookingcomRateRoomTypeMapping = selectedBookingcomObj => {
    const { roomMapping } = this.state;
    const bookingcomRateRoomTypeMapping = {};

    selectedBookingcomObj.rooms.forEach(room => {
      const { bookingcomRoomTypeId, roomData, rates } = room;
      let massagedRates = rates;
      const roomMapForRoomType = roomMapping.find(roomMap => roomMap.bookingcomRoomTypeId === bookingcomRoomTypeId);
      if (rates && roomMapForRoomType) {
        massagedRates = this.massageRate(rates, roomMapForRoomType);
      }
      bookingcomRateRoomTypeMapping[room.bookingcomRoomTypeId] = {
        bookingcomRoomTypeId,
        bookingcomRoomType: roomData.bookingcomRoomType,
        rates: massagedRates
      };
    });
    return bookingcomRateRoomTypeMapping;
  };

  getPricing = (policies, bookingcomFeePolicyConstant) => {
    if (!REACT_APP_IS_SHOW_BCOM_PRICING_SETTINGS) {
      return {};
    }
    const { bookingcomFeePolicyConstant: stateBookingcomFeePolicyConstant } = this.state;

    let pricing = {};
    bookingcomFeePolicyConstant = bookingcomFeePolicyConstant || stateBookingcomFeePolicyConstant;

    const feePolicy = policies && policies.Policy && policies.Policy.FeePolicies && policies.Policy.FeePolicies.FeePolicy;

    const cleaningFeePolicies =
      feePolicy && Array.isArray(feePolicy)
        ? feePolicy.find(policy => Number(policy._attributes.Code) === bookingcomFeePolicyConstant.CLEANING_FEE.code)
        : feePolicy && Number(feePolicy._attributes.Code) === bookingcomFeePolicyConstant.CLEANING_FEE.code
        ? feePolicy
        : {};

    pricing.cleaningFee = cleaningFeePolicies && Object.values(cleaningFeePolicies).length > 0 ? cleaningFeePolicies._attributes.Amount : undefined;
    pricing.chargeType = cleaningFeePolicies && Object.values(cleaningFeePolicies).length > 0 ? cleaningFeePolicies._attributes.ChargeFrequency : 12;
    pricing.decimalPlaces =
      (cleaningFeePolicies && Object.values(cleaningFeePolicies).length > 0 && cleaningFeePolicies._attributes.DecimalPlaces) || 0;
    return pricing;
  };

  handleOnAdvancedOptionsConfirm = payload => {
    const { roomMapping, rateModifierTypeConstant, rateModifierSignConstant } = this.state;

    const processedRoomMapping = roomMapping.map(room => {
      const rateDistribution = payload.rateDistributionPayload.find(rateDistribution => room.roomType === rateDistribution.roomId);

      if (!!rateDistribution) {
        const processedRates = room.rates.map(roomRateObject => {
          const rateObject = rateDistribution.rates.find(rateObject => String(rateObject.otaId) === String(roomRateObject.bookingcomRateId));

          if (!!rateObject) {
            const isDerived = rateObject.rate.isDerived;
            let processedRoomRateObject = {
              bookingcomRateId: String(roomRateObject.bookingcomRateId),
              rate: {
                weekday: rateObject.rate.weekdayRate,
                weekend: rateObject.rate.weekendRate,
                isDerived
              }
            };

            if (isDerived) {
              const isPercentage = !!rateModifierTypeConstant.find(type => type.code === rateObject.rate.modifierType && type.isPercentage);
              const isPositive = !!rateModifierSignConstant.find(sign => sign.code === rateObject.rate.modifierSign && sign.isPositive);
              let amount = rateObject.rate.modifierAmount;
              amount = isPercentage ? generatePercentageFromDisplay(amount) : amount;

              processedRoomRateObject.rate.calculation = {
                type: rateObject.rate.modifierType,
                isPositive,
                amount
              };
            }

            return processedRoomRateObject;
          } else {
            return { ...roomRateObject };
          }
        });

        return {
          ...room,
          rates: processedRates
        };
      }

      return { ...room };
    });

    const pricingSetting = payload.pricingSetting;

    this.setState({ roomMapping: processedRoomMapping, isAdvancedOptionsModalVisible: false, pricingSetting }, () => {});
  };

  render() {
    const {
      isBookingcomSyncModalVisible,
      bookingcom,
      selectedBookingcomId,
      fullRoomTypeOptions,
      roomMapping,
      rateModifierTypeConstant,
      rateModifierSignConstant,
      bookingcomFeePolicyConstant,
      syncType,
      bookingcomRoomTypeOptions,
      bookingcomPropertyName,
      selectedHotelId,
      isAdvancedOptionsModalVisible,
      bookingcomRoomTypeNamesOptions,
      currentRoomName,
      pricingSetting,
      isSaving
    } = this.state;
    const { data, onAfterSave, property, onClose } = this.props;
    let selectedBookingcomObj = null;
    let bookingcomRateRoomTypeMapping = {};

    if (bookingcom && selectedBookingcomId) {
      selectedBookingcomObj = bookingcom.find(bookingcom => bookingcom._id.toString() === selectedBookingcomId.toString());
      bookingcomRateRoomTypeMapping = this.constructBookingcomRateRoomTypeMapping(selectedBookingcomObj);
    }
    const isLoading = !this.checkIsReady();

    return (
      <Row className="scroll-bar-style" style={{ marginTop: '10px' }}>
        {REACT_APP_IS_SHOW_BCOM_PUSH_CONTENT && (
          <Row>
            <RadioGroup buttonStyle={'solid'} value={syncType} onChange={this.onSyncTypeChange} disabled={!!data}>
              <RadioButton key={syncTypes.PUSHNEW} value={syncTypes.PUSHNEW}>
                {'Push New Hotel'}
              </RadioButton>
              <RadioButton key={syncTypes.SYNCEXISTING} value={syncTypes.SYNCEXISTING}>
                {'Sync Existing Hotel'}
              </RadioButton>
            </RadioGroup>
          </Row>
        )}
        <Card title={'Hotel Details'} className="full-length-box" style={{ marginTop: 20 }}>
          {!checkIsPushNew(syncType) ? (
            <>
              <Row className="ota-row">
                <label className="ota-label">Booking.com Hotel ID</label>
                <AutoSearch
                  disabled={!!data}
                  defaultValue={selectedHotelId}
                  dataSource={
                    bookingcom
                      ? bookingcom
                          .filter(bookingcom => bookingcom.propertyData && bookingcom.propertyData.ID && !bookingcom.property)
                          .map(bookingcom => bookingcom.propertyData.ID)
                      : []
                  }
                  onSelect={this.onChangeHotelId}
                />
              </Row>
              {this.selectedHotelDetail(selectedBookingcomObj)}
            </>
          ) : (
            <Row className="ota-row">
              <label className="ota-label">Booking.com Hotel Name</label>
              <Input
                label={'Hotel Name'}
                name="bookingcomPropertyName"
                placeholder="Please insert Booking.com Hotel Name"
                value={bookingcomPropertyName}
                onChange={this.onInputChange}
              />
              <label className="ota-label">Booking.com Legal Entity</label>
              <Input label={'Legal Entity'} name={'legalEntity'} onChange={this.onInputChange} defaultValue={''} style={{ width: '100%' }} />
            </Row>
          )}
        </Card>

        {property &&
          property.roomTypes &&
          property.roomTypes.map(roomType => {
            const roomMap = roomMapping.find(roomMap => roomMap.roomType === roomType._id);
            const isSynced = !!roomMap;
            return (
              <Row key={roomType._id}>
                <Card className="ota-card" title={roomType.name}>
                  <label className="booking-label"> {`Sync ${roomType.name} to Booking.com`}</label>
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={this.toggleToSync(roomType._id)}
                    checked={isSynced}
                    style={{ marginLeft: '5px' }}
                  />
                  {isSynced &&
                    (!checkIsPushNew(syncType) ? (
                      <Fragment>
                        <Row className="ota-row">
                          <div className="form-row">
                            <Select
                              allowClear={true}
                              value={roomMap.bookingcomRoomTypeId}
                              style={{ width: '100%' }}
                              onChange={this.changeBookingcomRoomType(roomType._id)}
                            >
                              {fullRoomTypeOptions &&
                                fullRoomTypeOptions
                                  .filter(
                                    roomTypeOption =>
                                      !roomMapping.map(roomMap => roomMap.bookingcomRoomTypeId).includes(roomTypeOption.bookingcomRoomTypeId) ||
                                      roomMapping.filter(
                                        roomMap =>
                                          roomMap.roomType &&
                                          roomMap.bookingcomRoomTypeId === roomTypeOption.bookingcomRoomTypeId &&
                                          roomMap.roomType === roomType._id
                                      )[0]
                                  )
                                  .map(roomTypeOption => {
                                    return (
                                      <Option value={roomTypeOption.bookingcomRoomTypeId} key={roomTypeOption.bookingcomRoomTypeId}>
                                        {`${roomTypeOption.bookingcomRoomTypeId} | ${roomTypeOption.bookingcomRoomType}`}
                                      </Option>
                                    );
                                  })}
                            </Select>
                          </div>
                        </Row>
                        <Row>
                          {selectedBookingcomId &&
                            bookingcomRateRoomTypeMapping[roomMap.bookingcomRoomTypeId] &&
                            bookingcomRateRoomTypeMapping[roomMap.bookingcomRoomTypeId].rates &&
                            bookingcomRateRoomTypeMapping[roomMap.bookingcomRoomTypeId].rates.length > 0 &&
                            bookingcomRateRoomTypeMapping[roomMap.bookingcomRoomTypeId].rates.map(mapRate => {
                              return (
                                <div key={mapRate.bookingcomRateId}>
                                  <Card title={`${mapRate.bookingcomRateName} | ${mapRate.bookingcomRateId}`}>
                                    <OTARateInput
                                      rate={mapRate.rate}
                                      updateRates={this.updateRates(roomType._id, mapRate.bookingcomRateId)}
                                      currency={this.state.currency}
                                    />
                                  </Card>
                                </div>
                              );
                            })}
                        </Row>
                      </Fragment>
                    ) : (
                      <React.Fragment>
                        <Row className="ota-row">
                          <Select
                            allowClear={true}
                            value={roomMap.bookingcomRoomType}
                            style={{ width: '100%' }}
                            onChange={this.changeBookingcomRoomType(roomType._id)}
                            placeholder="Room Type"
                          >
                            {bookingcomRoomTypeOptions
                              .filter(bookingcomRoomType => {
                                return (
                                  (bookingcomRoomType.minOccupancy ? roomType.capacity.adult >= bookingcomRoomType.minOccupancy : true) &&
                                  (bookingcomRoomType.maxOccupancy ? roomType.capacity.adult <= bookingcomRoomType.maxOccupancy : true)
                                );
                              })
                              .map(roomTypeOption => {
                                return (
                                  <Option value={roomTypeOption.code} key={roomTypeOption.code}>
                                    {roomTypeOption.label}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Row>
                        {roomMap.bookingcomRoomType && (
                          <>
                            <Row className="ota-row">
                              <Select
                                allowClear={true}
                                style={{ width: '100%' }}
                                value={currentRoomName[roomType._id]}
                                onChange={this.handleOnChangeBookingcomRoomTypeNames(roomType._id)}
                                placeholder="Room Name"
                              >
                                {bookingcomRoomTypeNamesOptions &&
                                  bookingcomRoomTypeNamesOptions[roomMap.bookingcomRoomType] &&
                                  bookingcomRoomTypeNamesOptions[roomMap.bookingcomRoomType]
                                    .filter(roomTypeName => !Object.values(currentRoomName).includes(roomTypeName.label))
                                    .map(roomTypeName => {
                                      return (
                                        <Option value={roomTypeName.label} key={roomTypeName.code}>
                                          {roomTypeName.label}
                                        </Option>
                                      );
                                    })}
                              </Select>
                            </Row>
                            {roomMap.bookingcomRoomType && (
                              <Row>
                                <Card title={'Configure Rate'}>
                                  <OTARateInput
                                    updateRates={this.updateRates(roomType._id)}
                                    rate={this.getRateByRoomTypeAndRatePlanId(roomType._id)}
                                    currency={this.state.currency}
                                  />
                                </Card>
                              </Row>
                            )}
                          </>
                        )}
                      </React.Fragment>
                    ))}
                </Card>
              </Row>
            );
          })}
        {!isLoading && (
          <Row>
            <a href=" " onClick={this.handleOnAdvanceOptionsClick(true)}>
              Advanced Options
            </a>
          </Row>
        )}
        <Row gutter={12} type="flex" justify="end" className="ota-action-button-row">
          <Col>
            <Button type="secondary" onClick={onClose} disabled={isSaving}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={this.onConfirm} disabled={isLoading} loading={isSaving}>
              Save
            </Button>
          </Col>
        </Row>
        {/* Bookingcom sync's form */}
        <Modal
          visible={isBookingcomSyncModalVisible}
          onAfterSave={onAfterSave}
          onCancel={this.handleModalCancel}
          footer={null}
          title="Sync Availability, Rates, Inventory to Booking.com"
        >
          <BookingcomSyncForm bookingcomData={this.extractBookingcomSyncInfo()} handleModalCancel={this.handleModalCancel} />
        </Modal>
        {isAdvancedOptionsModalVisible && (
          <BookingcomAdvancedOptionsModal
            roomMapping={roomMapping}
            property={property}
            pricingSetting={pricingSetting}
            retrievedPricing={this.getPricing(selectedBookingcomObj.policies)}
            rateModifierTypeConstants={rateModifierTypeConstant}
            rateModifierSignConstants={rateModifierSignConstant}
            bookingcomFeePolicyConstants={bookingcomFeePolicyConstant}
            isVisible={isAdvancedOptionsModalVisible}
            onConfirm={this.handleOnAdvancedOptionsConfirm}
            onClose={this.handleOnAdvanceOptionsClick(false)}
            currency={this.state.currency}
          />
        )}
      </Row>
    );
  }
}

BookingcomForm.propTypes = {
  data: PropTypes.object,
  property: PropTypes.object.isRequired,
  onAfterSave: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

BookingcomForm.defaultProps = {
  onAfterSave: () => {}
};

export default withRouter(BookingcomForm);
