import React, { Component } from 'react';
import Counter from 'components/Counter/Counter';

class FormCounter extends Component {
  handleOnIncrement = () => {
    const { onChange, value } = this.props;
    onChange(value + 1);
  };

  handleOnDecrement = () => {
    const { onChange, value } = this.props;
    if (value > 0) onChange(value - 1);
  };

  render() {
    const { value } = this.props;
    return <Counter count={value} onDecrement={this.handleOnDecrement} onIncrement={this.handleOnIncrement} />;
  }
}

const WrappedFormCounter = ({ form, name, defaultValue }) => {
  return form.getFieldDecorator(name, {
    rules: [{ required: true }],
    initialValue: defaultValue || 0
  })(<FormCounter />);
};

export default WrappedFormCounter;
