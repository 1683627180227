import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Form } from 'antd';

import styles from './FormDatePicker.module.css';

const dateFormat = 'YYYY-MM-DD';

const FormItem = Form.Item;

const addRules = requiredErrorMessage => {
  const rules = [];
  if (requiredErrorMessage && typeof requiredErrorMessage === 'string') {
    rules.push({
      required: true,
      message: requiredErrorMessage
    });
  }
  return rules;
};

const FormDatePicker = ({ form, name, formLabel, defaultValue, placeholder, requiredErrorMessage, disabled }) => {
  const rules = addRules(requiredErrorMessage);

  return (
    <>
      <FormItem label={formLabel} colon={false}>
        {form.getFieldDecorator(name, {
          rules: rules,
          initialValue: defaultValue
        })(<DatePicker className={styles.datePicker} disabled={disabled} format={dateFormat} placeholder={placeholder} />)}
      </FormItem>
    </>
  );
};

FormDatePicker.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  requiredErrorMessage: PropTypes.string,
  disabled: PropTypes.bool
};

FormDatePicker.defaultProps = {
  disabled: false
};

export default FormDatePicker;
