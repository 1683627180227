import React from 'react';
import PropTypes from 'prop-types';
import { Row, Card, Alert } from 'antd';
import styles from './PhotosCard.module.css';

import UploadPhoto from 'components/UploadPhoto/UploadPhoto';

class Photos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //   photos: props.defaultValues.photos
      isDeletedByUser: false,
      isSetDefaultValue: false,
      photos: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Set photos state with api data
    const { hasFetchedPhotos, defaultValues, form } = props;
    const { isDeletedByUser, isSetDefaultValue } = state;
    const originalPhotos = form.getFieldValue('photos') || [];
    if (
      !isSetDefaultValue &&
      !isDeletedByUser &&
      originalPhotos.length === 0 &&
      hasFetchedPhotos &&
      defaultValues.photos &&
      defaultValues.photos.length > 0
    ) {
      props.form.setFieldsValue({
        photos: defaultValues.photos
      });
      return {
        isSetDefaultValue: true
      };
    }
    return null;
  }

  handleOnUploadFinish = e => {
    const { form, captionPrefix } = this.props;
    const photos = form.getFieldValue('photos') || [];
    const uploadedPhoto = {
      link: e.fileUrl,
      caption: `${captionPrefix} ${photos.length + 1}`
    };
    form.setFieldsValue({
      photos: [...photos, uploadedPhoto]
    });
  };

  handleOnCaptionChange = index => caption => {
    const { form } = this.props;
    const photos = form.getFieldValue('photos');
    photos[index] = {
      ...photos[index],
      caption
    };
    form.setFieldsValue({
      photos
    });
  };

  handleOnImageDelete = index => e => {
    const { form } = this.props;

    e.preventDefault();

    const photos = form.getFieldValue('photos');

    this.setState({
      isDeletedByUser: true
    });

    photos.splice(index, 1);
    form.setFieldsValue({
      photos
    });
  };

  handleOnImageMakePrimary = (index, photo) => e => {
    const { form } = this.props;

    e.preventDefault();

    const photos = photo;
    photos.unshift(photos[index]); // Move the photo to first element
    photos.splice(index + 1, 1); // Remove the photo
    form.setFieldsValue({
      photos
    });
  };

  handleDragPic = updatedPhotos => {
    const { form } = this.props;

    const photos = updatedPhotos;
    form.setFieldsValue({
      photos
    });
  };

  render() {
    const { form, cardClassname } = this.props;
    const photos = form.getFieldValue('photos');
    return (
      <>
        <Alert
          className={styles.notes}
          message="Notes"
          description={
            <div>
              <p className={styles.notesText}>{'Photos size cannot be above 5MB. Recommended sizes are 1080 x 608px or 16:9 aspect ratio'}</p>
            </div>
          }
          type="info"
          showIcon
        />
        <Card title="Photos" className={cardClassname} style={{ height: '100%' }}>
          {form.getFieldDecorator('photos', {
            initialValue: []
          })(<input type="hidden" />)}
          <Row gutter={16} style={{ height: '100%' }}>
            <UploadPhoto
              photos={photos}
              onCaptionChange={this.handleOnCaptionChange}
              onImageDelete={this.handleOnImageDelete}
              onImageMakePrimary={this.handleOnImageMakePrimary}
              onUploadFinish={this.handleOnUploadFinish}
              onDrag={this.handleDragPic}
            />
          </Row>
        </Card>
      </>
    );
  }
}

Photos.propTypes = {
  form: PropTypes.object.isRequired,
  captionPrefix: PropTypes.string,
  cardClassname: PropTypes.string,
  defaultValues: PropTypes.object,
  hasFetchedPhotos: PropTypes.bool
};

Photos.defaultProps = {
  captionPrefix: 'Image',
  defaultValues: {},
  form: {},
  cardClassname: '',
  hasFetchedPhotos: false
};

export default Photos;
