import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row } from 'antd';

import ListingWizardLayout from 'layouts/ListingWizardLayout/ListingWizardLayout';
import FormInput from 'components/FormInput/FormInput';

import styles from './Description.module.css';

class Description extends React.Component {
  handleOnSubmit = e => {
    e.preventDefault();
    const { form, onSave } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSave(values);
      }
    });
  };

  handleOnBackClick = e => {
    e.preventDefault();
    const { form, onBack } = this.props;
    const allFieldsValue = form.getFieldsValue();
    onBack(allFieldsValue);
  };

  render() {
    const { defaultValues, form, isLastPage, percentage, title } = this.props;
    return (
      <ListingWizardLayout
        isLastPage={isLastPage}
        percentage={percentage}
        title={title}
        onBackButtonClick={this.handleOnBackClick}
        onNextButtonClick={this.handleOnSubmit}
      >
        <Row type="flex" justify="space-between" className={styles.formTitleContainer}>
          <h2 className={styles.formTitle}>Describe your unit</h2>
          <p>Provide a detailed description of your unit</p>
        </Row>

        <FormInput
          inputType="textarea"
          label="Summary"
          name="summary"
          placeholder="Describe your unit in general"
          requiredErrorMessage="Please provide a summary of your unit"
          form={form}
          defaultValue={defaultValues.summary}
          size="large"
        />

        <FormInput
          inputType="textarea"
          label="About your unit"
          name="aboutYourPlace"
          placeholder="Describe your unit"
          requiredErrorMessage="Please provide a description of your unit"
          form={form}
          defaultValue={defaultValues.aboutYourPlace}
          size="large"
        />

        <FormInput
          inputType="textarea"
          label="About your neighbourhood"
          name="aboutYourNeighbourhood"
          placeholder="Describe the neighbourhood of your unit"
          requiredErrorMessage="Please provide a description of your unit's neighbourhood"
          form={form}
          defaultValue={defaultValues.aboutYourNeighbourhood}
          size="large"
        />

        <FormInput
          inputType="textarea"
          label="Getting here"
          name="transit"
          placeholder="Describe how a guest can reach your unit"
          requiredErrorMessage="Please provide a description of how a guest can reach your unit"
          form={form}
          defaultValue={defaultValues.transit}
          size="large"
        />

        <FormInput
          inputType="textarea"
          label="Access your place"
          name="access"
          placeholder="Describe what accessibility features are available at your unit"
          form={form}
          defaultValue={defaultValues.access}
          size="large"
        />

        <FormInput
          inputType="textarea"
          label="Things to take note"
          name="notes"
          placeholder="List important things for your guest to know about your unit"
          form={form}
          defaultValue={defaultValues.notes}
          size="large"
        />

        <FormInput
          inputType="textarea"
          label="House rules"
          name="houseRules"
          placeholder="List the house rules for your unit"
          form={form}
          defaultValue={defaultValues.houseRules}
          size="large"
        />

        <FormInput
          inputType="textarea"
          label="Your interaction with guests"
          name="interaction"
          placeholder="Greet your guest!"
          form={form}
          defaultValue={defaultValues.interaction}
          size="large"
        />
      </ListingWizardLayout>
    );
  }
}

Description.propTypes = {
  form: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  percentage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  isLastPage: PropTypes.bool,
  onBack: PropTypes.func
};

Description.defaultProps = {
  defaultValues: {},
  isLastPage: false
};

const WrappedFormDescription = Form.create()(Description);

export default WrappedFormDescription;
