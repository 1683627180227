import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Tabs, Skeleton } from 'antd';

import { SEPARATOR_DASH, RATE_DISTRIBUTION_TAB, RATE_DERIVATIVE, RATE_DERIVATIVES } from 'utils/constants';
import { getSTDRateByRoomType } from 'utils/apis/rate';

import AdvancedOptionsModal from '../../../../AdvancedOptionsModal/AdvancedOptionsModal';
import AdvancedOptionsRateDistributionTab from '../../../../AdvancedOptionsRateDistributionTab/AdvancedOptionsRateDistributionTab';

const TabPane = Tabs.TabPane;

const getRateDistributionPayload = fieldValues => {
  let payload = [];

  const fieldValuesWithKeys = Object.entries(fieldValues);
  const processedFieldValuesWithKeys = fieldValuesWithKeys.filter(fv => fv[0].includes(RATE_DISTRIBUTION_TAB));

  for (let i = 0; i < processedFieldValuesWithKeys.length; i++) {
    const fieldValueWithKey = processedFieldValuesWithKeys[i];
    const fieldKey = fieldValueWithKey[0];
    const fieldValue = fieldValueWithKey[1];

    const splittedFieldKeys = fieldKey.split(SEPARATOR_DASH);
    const roomId = splittedFieldKeys[1];
    const otaId = splittedFieldKeys[2];
    const rateId = splittedFieldKeys[3];
    const fieldName = splittedFieldKeys[4];

    let existingRoom = payload.find(room => room.roomId === roomId);

    if (!existingRoom) {
      existingRoom = { roomId, rates: [] };
      payload.push(existingRoom);
    }

    let existingRateObject = existingRoom.rates.find(rateObject => rateObject.otaId === otaId);

    if (!existingRateObject) {
      existingRateObject = { otaId, rate: { _id: rateId } };
      existingRoom.rates.push(existingRateObject);
    }

    if (fieldName === RATE_DERIVATIVE) {
      const derivativeObject = RATE_DERIVATIVES.find(rateDerivative => rateDerivative.code === fieldValue);
      existingRateObject.rate.isDerived = derivativeObject.isDerived;
    } else {
      existingRateObject.rate[fieldName] = fieldValue;
    }
  }

  return payload;
};

class TiketcomAdvancedOptionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingRateDistribution: false,
      roomsOfRates: []
    };
  }

  componentDidMount = async () => {
    const { rateModifierTypeConstants, rateModifierSignConstants, roomsMapping } = this.props;

    this.setState({ isLoadingRateDistribution: true });

    const roomsOfRates = await this.getRoomsOfRates({ roomsMapping, rateModifierTypeConstants, rateModifierSignConstants });

    this.setState({ roomsOfRates, isLoadingRateDistribution: false });
  };

  getRoomsOfRates = async ({ roomsMapping, rateModifierTypeConstants, rateModifierSignConstants }) => {
    const roomsOfRates = await Promise.all(
      roomsMapping.map(async room => {
        const stdRate = await getSTDRateByRoomType(room._id);

        const roomRates = room.formData.tiketcomRates.map(tiketcomRate => {
          const otaId = String(tiketcomRate.tiketcomRateId);
          let calculation = tiketcomRate.rate.calculation;

          if (!tiketcomRate.rate.calculation) {
            const modifierTypeObject = rateModifierTypeConstants.find(type => type.isDefault);

            calculation = {
              amount: modifierTypeObject.isPercentage ? 0.01 : 1,
              type: modifierTypeObject.code,
              isPositive: !!rateModifierSignConstants.find(sign => sign.isDefault && sign.isPositive)
            };
          }

          calculation = {
            ...calculation,
            parent: {
              weekday: stdRate.weekday,
              weekend: stdRate.weekend
            }
          };

          const rate = {
            isDerived: tiketcomRate.rate.isDerived,
            weekday: tiketcomRate.rate.weekday,
            weekend: tiketcomRate.rate.weekend,
            calculation
          };

          return { otaId, rate };
        });

        return {
          roomId: room._id,
          roomName: room.name,
          rates: roomRates
        };
      })
    );

    return roomsOfRates;
  };

  handleOnSave = e => {
    const { form, onConfirm, onClose } = this.props;

    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const payload = {
          rateDistributionPayload: getRateDistributionPayload(values)
        };

        onConfirm(payload);
        onClose();
      }
    });
  };

  handleOnClose = e => {
    const { onClose } = this.props;

    e.preventDefault();
    onClose();
  };

  render() {
    const { roomsOfRates, isLoadingRateDistribution } = this.state;
    const { form, isVisible, currency } = this.props;

    return (
      <AdvancedOptionsModal isVisible={isVisible} onSave={this.handleOnSave} onClose={this.handleOnClose}>
        <Tabs defaultActiveKey={'rateDistribution'}>
          <TabPane tab="Rate Distribution" key="rateDistribution">
            <Skeleton loading={isLoadingRateDistribution} active>
              <AdvancedOptionsRateDistributionTab form={form} roomsOfRates={roomsOfRates} currency={currency} />
            </Skeleton>
          </TabPane>
        </Tabs>
      </AdvancedOptionsModal>
    );
  }
}

TiketcomAdvancedOptionsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  rateModifierSignConstants: PropTypes.array.isRequired,
  rateModifierTypeConstants: PropTypes.array.isRequired,
  roomsMapping: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired
};

export default Form.create()(TiketcomAdvancedOptionsModal);
