import React, { Component } from 'react';
import { Row, Col, Card, Icon, Tooltip as AntdTooltip } from 'antd';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { numberWithCommas } from 'utils/general';

import './PayoutGraph.css';

class PayoutGraph extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  };

  render() {
    const { monthlyStatistics, bookedNights, totalRevenue, totalNights, bookedNightDiff, revenueDiff, currency, timezone } = this.props.data;

    return (
      <div className="pg-container">
        <Row gutter={16} type="flex" justify="center" className="pg-summary">
          <Col span={24} md={8}>
            <Card className="pg-card-font" bodyStyle={{ height: '100%', wordBreak: 'break-word' }}>
              <h2 className="statisticTitle">
                Total Booked Nights
                <AntdTooltip title={<span style={{ fontSize: '12px' }}>Total nights = No. of days x No. of units</span>}>
                  {' '}
                  <Icon type="question-circle-o" style={{ cursor: 'pointer' }} />
                </AntdTooltip>
              </h2>
              <span className="mainValue"> {bookedNights} </span>
              <span className="subValue">/ {totalNights} </span>
              <span
                style={{
                  color: bookedNightDiff < 0 ? 'red' : bookedNightDiff > 0 ? 'green' : 'grey',
                  fontSize: '100%',
                  position: 'relative',
                  top: '15px'
                }}
              >
                {(bookedNightDiff < 0 ? '▼ ' : bookedNightDiff > 0 ? '▲ ' : '') + numberWithCommas(bookedNightDiff)}%
              </span>
            </Card>
          </Col>
          <Col span={24} md={8}>
            <Card className="pg-card-font" bodyStyle={{ height: '100%', wordBreak: 'break-word' }}>
              <h2 className="statisticTitle">Occupancy Rate</h2>
              <span className="mainValue"> {(totalNights > 0 ? (bookedNights / totalNights) * 100 : 0).toFixed(2)}% </span>
            </Card>
          </Col>
          <Col span={24} md={8}>
            <Card className="pg-card-font" bodyStyle={{ height: '100%', wordBreak: 'break-word' }}>
              <h2 className="statisticTitle">Total Revenue</h2>
              <span className="reportingcurrencyFont">{currency} </span>
              <span className="mainValue">{numberWithCommas(totalRevenue)}</span>
              <span
                style={{
                  color: revenueDiff < 0 ? 'red' : revenueDiff > 0 ? 'green' : 'grey',
                  fontSize: '100%',
                  position: 'relative',
                  top: '15px'
                }}
              >
                {(revenueDiff < 0 ? '▼ RM' : revenueDiff > 0 ? '▲ RM' : '') + numberWithCommas(revenueDiff)}
              </span>
            </Card>
          </Col>
        </Row>
        <Row gutter={16} type="flex" justify="center">
          <Col span={24} md={12} className="pg-graph">
            <Card>
              <h2 className="statisticTitle">Occupancy Trend</h2>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart width={600} height={300} data={monthlyStatistics} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <XAxis dataKey="month" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Occupancy" stroke="#7ea4b3" activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </Card>
          </Col>
          <Col span={24} md={12} className="pg-graph">
            <Card>
              <h2 className="statisticTitle">Revenue Trend</h2>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart width={600} height={300} data={monthlyStatistics} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <XAxis dataKey="month" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Revenue" stroke="#9b97de" activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PayoutGraph;
