import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import styles from './Counter.module.css';

const generateLabel = (count, emptyLabel) => {
  let label = count;
  if (emptyLabel) {
    if (count === 0) {
      label = emptyLabel;
    }
  }
  return label;
};

const Counter = ({ count, onDecrement, onIncrement, emptyLabel }) => {
  const label = generateLabel(count, emptyLabel);
  return (
    <div className={styles.root}>
      <Button ghost className={styles.button} type="primary" icon="minus" size="large" onClick={onDecrement} />
      <p className={styles.text}>{label}</p>
      <Button ghost className={styles.button} type="primary" icon="plus" size="large" onClick={onIncrement} />
    </div>
  );
};

Counter.propTypes = {
  count: PropTypes.number.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onIncrement: PropTypes.func.isRequired,
  emptyLabel: PropTypes.string
};

Counter.defaultProps = {
  count: 0,
  onDecrement: () => {},
  onIncrement: () => {},
  emptyLabel: ''
};

export default Counter;
