import React, { Component } from 'react';
import { Layout } from 'antd';
import './SingleViewLayout.css';
import Footer from './../../components/Footer/Footer';

const { Content } = Layout;

class SingleViewLayout extends Component {
  render() {
    return (
      <Layout className={this.props.className + ' single-view-layout-bg'}>
        <Content>{this.props.children}</Content>
        <Footer />
      </Layout>
    );
  }
}

export default SingleViewLayout;
