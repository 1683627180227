import React, { Component } from 'react';
import intl from 'react-intl-universal';
import http from 'axios';
import _ from 'lodash';

const LOCALES = [
  {
    name: 'English',
    value: 'en-US'
  },
  {
    name: '简体中文',
    value: 'zh-CN'
  }
];

class LocaleProvider extends Component {
  state = { initDone: false };

  componentDidMount() {
    this.loadLocales();
  }

  loadLocales() {
    let currentLocale = intl.determineLocale({
      urlLocaleKey: 'lang',
      cookieLocaleKey: 'lang'
    });

    if (!_.find(LOCALES, { value: currentLocale })) {
      currentLocale = 'en-US';
    }
    http
      .get(`/locales/${currentLocale}.json`)
      .then(res => {
        return intl.init({
          currentLocale,
          locales: {
            [currentLocale]: res.data
          }
        });
      })
      .then(() => {
        this.setState({ initDone: true });
      });
  }

  onSelectLocale(e) {
    let lang = e.target.value;
    window.location.search = `?lang=${lang}`;
  }

  render() {
    return (
      this.state.initDone && (
        <React.Fragment>{this.props.children}</React.Fragment>
      )
    );
  }
}

export default LocaleProvider;
