import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Form, Row, Col, Alert, Button, Message, List, Empty } from 'antd';
import moment from 'moment';

import Card from 'components/Card/Card';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormSelection from 'components/FormSelection/FormSelection';
import FormDatePickerRange from 'components/FormDatePickerRange/FormDatePickerRange';
import FormCheckbox from 'components/FormCheckbox/FormCheckbox';
import RateModifierFormItems from 'components/RateModifierFormItems/RateModifierFormItems';
import { BareRadioButton } from 'components/FormRadioButton/FormRadioButton';

import { getHosts } from 'utils/apis/host';
import { getPromotionsByHost, createRateModifier, updateRateModifier, deleteRateModifier } from 'utils/apis/rateModifier';
import { LETTER_N_NUMBER } from 'utils/constants';
import { checkHasValue, generatePercentageFromDisplay, guard } from 'utils/general';
import { checkIsDate } from 'utils/date';

import styles from './ModalPromotion.module.css';

const formatDate = momentDate => {
  return moment(momentDate).format('YYYY-MM-DD');
};

const PROMOTION_STATUS = {
  ACTIVE: { value: 'active', label: 'Active' },
  INACTIVE: { value: 'inactive', label: 'Inactive' }
};

const daysInWeekSelections = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' }
];

/* ----------------------------------------- useEffect Functions  ----------------------------------------*/

const useFetchHosts = () => {
  const [hostOptions, setHostOptions] = useState([]);

  const fetchHosts = useCallback(async () => {
    const hostOptions = await getHosts().then(res =>
      res.data.map(host => ({
        key: host._id,
        value: host.name,
        currency: host.currency,
        timezone: host.timezone
      }))
    );

    setHostOptions(hostOptions);
  }, []);

  useEffect(() => {
    fetchHosts();
  }, [fetchHosts]);

  return { hostOptions };
};

const useFetchPromotionsOptions = (hostId, form) => {
  const [promotionOptions, setPromotionOptions] = useState([]);

  const fetchPromotions = useCallback(async () => {
    if (!!hostId) {
      const promotionOptions = await getPromotionsByHost(hostId);
      setPromotionOptions(promotionOptions);
    }
  }, [hostId]);

  useEffect(() => {
    fetchPromotions().then(() => {
      // force to validate Rate Name again when onChange of Host
      form.validateFieldsAndScroll(['rateName'], { force: true }, (err, values) => {});
    });
    // Ignore form because form is always trigger updating state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPromotions]);

  return { promotionOptions };
};

const usePromotionDetails = (selectedPromotion, hostOptions) => {
  const [selectedHost, setSelectedHost] = useState('');
  const [promotionStatus, setPromotionStatus] = useState(PROMOTION_STATUS.ACTIVE.value);
  const [datesDisallowed, setDatesDisallowed] = useState([]);

  const isEdit = !!selectedPromotion;
  const promotionDetails = selectedPromotion.promotionDetails;
  const hasStayPeriod = !!promotionDetails && promotionDetails.stayPeriod;
  const hasPromotionPeriod = !!promotionDetails && promotionDetails.promotionPeriod;
  const hasMinimumNight = !!promotionDetails && promotionDetails.minimumNight;
  const hasPromotionStatus = !!promotionDetails && promotionDetails.status;

  const selectDefaultHost = hostOptions => {
    if (hostOptions.length > 0) {
      return hostOptions[0].key;
    } else {
      return '';
    }
  };

  const flatDays = (daysOfWeek = []) => daysOfWeek.map(day => day.day_of_week);
  const flatDates = (dates = []) => dates.map(date => [moment(date.startDate), moment(date.endDate)]);

  const title = useMemo(() => (isEdit ? 'Editing Promotion' : 'Creating Promotion'), [isEdit]);
  const selectedHostId = useMemo(() => (!!selectedHost ? selectedHost : isEdit ? selectedPromotion.hostId : selectDefaultHost(hostOptions)), [
    selectedHost,
    isEdit,
    selectedPromotion.hostId,
    hostOptions
  ]);
  const rateName = useMemo(() => (isEdit ? selectedPromotion.name : ''), [isEdit, selectedPromotion.name]);
  const remarks = useMemo(() => (isEdit ? selectedPromotion.remarks : ''), [isEdit, selectedPromotion.remarks]);

  const stayPeriod = useMemo(() => (isEdit ? (!!hasStayPeriod ? [moment(hasStayPeriod.startDate), moment(hasStayPeriod.endDate)] : []) : []), [
    isEdit,
    hasStayPeriod
  ]);
  const promotionPeriod = useMemo(
    () => (isEdit ? (!!hasPromotionPeriod ? [moment(hasPromotionPeriod.startDate), moment(hasPromotionPeriod.endDate)] : []) : []),
    [isEdit, hasPromotionPeriod]
  );
  const minimumNight = useMemo(() => (isEdit ? Number(hasMinimumNight) : 0), [isEdit, hasMinimumNight]);

  const daysAllowed = useMemo(
    () => (isEdit ? (!!promotionDetails && promotionDetails.daysAllowed ? flatDays(promotionDetails.daysAllowed) : []) : []),
    [isEdit, promotionDetails]
  );

  const defaultDateDisallowed = useMemo(
    () => (isEdit ? (!!promotionDetails && promotionDetails.datesDisallowed ? flatDates(promotionDetails.datesDisallowed) : []) : []),
    [isEdit, promotionDetails]
  );

  useEffect(() => {
    setDatesDisallowed(defaultDateDisallowed);
  }, [defaultDateDisallowed]);

  const handleOnAddNewDisallowedDates = () => {
    setDatesDisallowed([...datesDisallowed, [moment(Date.now()), moment(Date.now())]]);
  };

  const handleOnRemoveDisallowedDates = index => {
    const newDisallowedDates = datesDisallowed.filter((code, i) => i !== index);
    setDatesDisallowed(newDisallowedDates);
  };

  useEffect(() => {
    setPromotionStatus(hasPromotionStatus);
  }, [hasPromotionStatus]);

  return {
    isEdit,
    title,
    rateName,
    remarks,
    selectedHostId,
    setSelectedHost,
    stayPeriod,
    promotionPeriod,
    minimumNight,
    promotionStatus,
    setPromotionStatus,
    daysAllowed,
    defaultDateDisallowed,
    datesDisallowed,
    handleOnAddNewDisallowedDates,
    handleOnRemoveDisallowedDates
  };
};

const useDefaultRateModifierOptions = (selectedPromotion, rateModifierTypes, rateModifierSigns) => {
  const [selectedModifierType, setSelectedModifierType] = useState('');

  const modifierTypeObject = useMemo(
    () => rateModifierTypes.find(type => type.code === (selectedModifierType || (selectedPromotion && selectedPromotion.type)) || type.isDefault),
    [selectedPromotion, selectedModifierType, rateModifierTypes]
  );

  const modifierSignObject = useMemo(
    () => rateModifierSigns.find(sign => (!!selectedPromotion ? sign.isPositive === selectedPromotion.isPositive : sign.isDefault)),
    [selectedPromotion, rateModifierSigns]
  );

  const isTypePercentage = useMemo(() => !!modifierTypeObject && modifierTypeObject.isPercentage, [modifierTypeObject]);
  const defaultModifierType = useMemo(() => (!!modifierTypeObject ? modifierTypeObject.code : ''), [modifierTypeObject]);
  const defaultModifierSign = useMemo(() => (!!modifierSignObject ? modifierSignObject.code : ''), [modifierSignObject]);
  const defaultRateModifierAmount = useMemo(() => (!!selectedPromotion ? selectedPromotion.amount : 0), [selectedPromotion]);

  return { isTypePercentage, defaultModifierType, defaultModifierSign, defaultRateModifierAmount, setSelectedModifierType };
};

const usePromotionCodeDetails = selectedPromotion => {
  const [isEditPromotionCode, setIsEditPromotionCode] = useState(false);
  const [promotionCodes, setPromotionCodes] = useState([]);

  const isEdit = !!selectedPromotion;

  const defaultPromotionCodes = useMemo(
    () => (isEdit ? guard(() => selectedPromotion.promotionDetails && selectedPromotion.promotionDetails.codes, []) : []),
    [isEdit, selectedPromotion.promotionDetails]
  );

  const constructPromotionCodes = defaultPromotionCodes => {
    setPromotionCodes(defaultPromotionCodes);
  };

  useEffect(() => {
    constructPromotionCodes(defaultPromotionCodes);
  }, [defaultPromotionCodes]);

  const handleOnShowEditPromotionCode = boolean => {
    setIsEditPromotionCode(boolean);

    if (!isEditPromotionCode) {
      setPromotionCodes(defaultPromotionCodes);
    }
  };

  const handleOnAddNewCode = () => {
    setPromotionCodes([...promotionCodes, { code: '', redemptionCounts: 1 }]);
  };

  const handleOnRemoveCode = index => {
    const newPromotionCodes = promotionCodes.filter((code, i) => i !== index);
    setPromotionCodes(newPromotionCodes);
  };

  return {
    isEditPromotionCode,
    handleOnShowEditPromotionCode,
    handleOnAddNewCode,
    handleOnRemoveCode,
    defaultPromotionCodes,
    promotionCodes
  };
};

/* ----------------------------------------- Sub Components  ----------------------------------------*/

const PromoCodeShowListDetails = ({ defaultPromotionCodes }) => {
  return defaultPromotionCodes.length > 0 ? (
    <List size="small">
      {defaultPromotionCodes.map((promoCode, index) => (
        <List.Item size="small" key={`${promoCode._id}-${index}`}>
          <List.Item.Meta
            title={<span>{promoCode.code}</span>}
            description={
              <div>
                <span> {`Redemptions left: ${String(checkHasValue(promoCode.redemptionCounts) ? promoCode.redemptionCounts : 0)}`}</span>
              </div>
            }
          ></List.Item.Meta>
        </List.Item>
      ))}
    </List>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      style={{
        height: 30
      }}
      description="Create promotion code here"
    />
  );
};

const PromoCodeEditListDetails = ({ form, promotionCodes, onClickRemoveCode, checkIsDuplicateCode }) => {
  return promotionCodes.map((promotionCode, index) => (
    <Row gutter={16} key={`${promotionCode._id}-${index}`}>
      <Col span={10} md={10}>
        <FormInput
          form={form}
          name={`promotionCodes[${index}].code`}
          formLabel="Promotion Code"
          placeholder="Code"
          defaultValue={promotionCode.code}
          requiredErrorMessage="Please enter a code"
          extraRules={[{ validator: checkIsDuplicateCode }, { pattern: LETTER_N_NUMBER, message: 'Combination of Numbers and Letters only' }]}
          uppercase
        />
      </Col>
      <Col span={10} md={10}>
        <FormInputNumber
          form={form}
          name={`promotionCodes[${index}].redemptionCounts`}
          formLabel="Redemption Left"
          placeholder="Number of redemption"
          requiredErrorMessage="Please enter number of redemptions"
          defaultValue={promotionCode.redemptionCounts}
        />
      </Col>
      <Col span={2} md={2}>
        <Button icon="delete" ghost type="danger" style={{ fontSize: 18, marginTop: 44 }} onClick={e => onClickRemoveCode(index)} />
      </Col>
    </Row>
  ));
};

const DisallowedDatesEditListDetails = ({ form, disallowedDates, onClickRemoveDates, checkIsValidDates }) => {
  return disallowedDates.map((disallowedDate, index) => (
    <Row gutter={16} key={`${disallowedDates._id}-${index}`}>
      <Col span={12} md={12}>
        <FormDatePickerRange
          form={form}
          formLabel={index === 0 ? 'Select date to disallow Promotion' : ''}
          fieldName={`disallowedDates[${index}]`}
          defaultPickerValue={disallowedDate}
          extraRules={[{ validator: checkIsValidDates }]}
        />
      </Col>
      <Col span={2} md={2}>
        <Button
          icon="delete"
          ghost
          type="danger"
          style={index === 0 ? { marginTop: 44 } : { marginTop: 11 }}
          onClick={e => onClickRemoveDates(index)}
        />
      </Col>
    </Row>
  ));
};

/* ----------------------------------------- Main Components  ----------------------------------------*/

const ModalPromotion = ({
  form,
  isShowModalPromotion,
  selectedPromotion,
  onModalPromotionClose,
  rateModifierTypes,
  rateModifierSigns,
  isAllowEditRateModifier,
  isAllowDeleteRateModifier,
  checkIsAdminReadOnly
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currency, setCurrency] = useState('RM');
  const [timezone, setTimezone] = useState('');

  const { hostOptions } = useFetchHosts();
  const {
    isEdit,
    title,
    rateName,
    remarks,
    selectedHostId,
    setSelectedHost,
    stayPeriod,
    promotionPeriod,
    minimumNight,
    promotionStatus,
    setPromotionStatus,
    daysAllowed,
    defaultDateDisallowed,
    datesDisallowed,
    handleOnAddNewDisallowedDates,
    handleOnRemoveDisallowedDates
  } = usePromotionDetails(selectedPromotion, hostOptions);
  const { promotionOptions } = useFetchPromotionsOptions(selectedHostId, form);

  const {
    isEditPromotionCode,
    handleOnShowEditPromotionCode,
    handleOnAddNewCode,
    handleOnRemoveCode,
    defaultPromotionCodes,
    promotionCodes
  } = usePromotionCodeDetails(selectedPromotion);

  const {
    isTypePercentage,
    defaultModifierType,
    defaultModifierSign,
    defaultRateModifierAmount,
    setSelectedModifierType
  } = useDefaultRateModifierOptions(selectedPromotion, rateModifierTypes, rateModifierSigns);

  const hostSelections = useMemo(() => (hostOptions.length > 0 ? hostOptions : []), [hostOptions]);
  const promotions = useMemo(() => (promotionOptions.length > 0 ? promotionOptions : []), [promotionOptions]);

  const handleOnHostChange = selectedHostId => {
    setSelectedHost(selectedHostId);
  };

  useEffect(() => {
    if (!selectedHostId || hostOptions.length === 0) {
      return;
    }
    const selectedHost = hostOptions.find(host => host.key === selectedHostId);
    if (selectedHost) {
      setCurrency(selectedHost.currency);
      setTimezone(selectedHost.timezone);
    }
  }, [selectedHostId, hostOptions]);

  const handleOnModifierTypeChange = selectedType => {
    setSelectedModifierType(selectedType);
  };

  const checkIsDuplicatePromotionName = (rule, value, callback) => {
    const isDuplicated = !!promotions.find(promotion => promotion.name === value) && (!isEdit || rateName !== value);

    if (isDuplicated) {
      callback('Promotion Name exists, please use another Promotion Name');
    } else {
      callback();
    }
  };

  const checkIsDuplicateCode = (rule, value, callback) => {
    const duplicatedFromAllPromotionsCodes = promotions.find(promotion =>
      promotion.promotionDetails.codes.find(promotionCode => promotionCode.code === value)
    );
    const isDefaultValueCode = defaultPromotionCodes.find(defaultCode => defaultCode.code === value);

    const upsertedPromotionCodes = form.getFieldValue('promotionCodes');
    const duplicatedFromUpserted = upsertedPromotionCodes.filter(promotionCode => promotionCode.code === value);

    const isDuplicated = (!!duplicatedFromAllPromotionsCodes && !isDefaultValueCode) || duplicatedFromUpserted.length > 1;

    if (isDuplicated) {
      callback('Code exists, please use another code');
    } else {
      callback();
    }
  };

  const checkIsDisallowedDateValid = (rule, value, callback) => {
    if (stayPeriod.length > 0) {
      if (checkIsDate('before', value[0], stayPeriod[0])) {
        callback('Disallowed date should be after stay period start date');
      } else if (checkIsDate('after', value[1], stayPeriod[1].add(1, 'day'))) {
        callback('Disallowed date should be before stay period end date');
      }
    } else {
      const stayPeriod = form.getFieldValue('stayPeriod');
      if (checkIsDate('before', value[0], stayPeriod[0])) {
        callback('Disallowed date should be after stay period start date');
      } else if (checkIsDate('after', value[1], stayPeriod[1].add(1, 'day'))) {
        callback('Disallowed date should be before stay period end date');
      }
    }

    if (defaultDateDisallowed.length > 0 || datesDisallowed.length > 1) {
      if (datesDisallowed.length > defaultDateDisallowed.length) {
        datesDisallowed.map(dates => {
          if (checkIsDate('after', value[0], dates[0]) && checkIsDate('before', value[0], dates[1])) {
            callback('Disallowed date exists, please use another date');
          }
        });
      }
    }
    callback();
  };

  const handleOnSubmit = e => {
    e.preventDefault();

    form.validateFieldsAndScroll({ force: true }, (err, values) => {
      if (!err) {
        const isPositive = !!rateModifierSigns.find(sign => sign.isPositive && sign.code === values.modifierSign);

        const isPercentage = !!rateModifierTypes.find(type => type.code === values.modifierType && type.isPercentage);
        const amount = isPercentage ? generatePercentageFromDisplay(values.modifierAmount) : values.modifierAmount;

        const formattedPromotion = {
          host: selectedHostId,
          name: values.rateName,
          type: values.modifierType,
          isPositive,
          amount,
          remarks: values.remarks,
          promotionDetails: {
            status: isEdit ? promotionStatus : PROMOTION_STATUS.ACTIVE.value,
            ...(values.promotionPeriod.length > 0 && {
              promotionPeriod: {
                startDate: formatDate(values.promotionPeriod[0]),
                endDate: formatDate(values.promotionPeriod[1])
              }
            }),
            ...(values.stayPeriod.length > 0 && {
              stayPeriod: {
                startDate: formatDate(values.stayPeriod[0]),
                endDate: formatDate(values.stayPeriod[1])
              }
            }),
            minimumNight: values.minimumNight,
            daysAllowed: values.daysAllowed ? values.daysAllowed.map(day => ({ day_of_week: day })) : undefined,
            ...(!!values.disallowedDates &&
              values.disallowedDates.length > 0 && {
                datesDisallowed: values.disallowedDates.map(date => {
                  return {
                    startDate: formatDate(date[0]),
                    endDate: formatDate(date[1])
                  };
                })
              }),
            codes: isEditPromotionCode ? (checkHasValue(values.promotionCodes) ? values.promotionCodes : []) : defaultPromotionCodes
          }
        };

        setIsLoading(true);

        if (isEdit) {
          updateRateModifier(selectedPromotion._id, formattedPromotion)
            .then(() => {
              onModalPromotionClose();
              Message.success('Succesfully updated promotion!');
            })
            .catch(ex => {
              setIsLoading(false);
              Message.Error(ex.toString());
            });
        } else {
          createRateModifier(formattedPromotion)
            .then(() => {
              onModalPromotionClose();
              Message.success('Successfully created promotion!');
            })
            .catch(ex => {
              setIsLoading(false);
              Message.Error(ex.toString());
            });
        }
      }
    });
  };

  const handleOnDelete = e => {
    e.preventDefault();

    Modal.confirm({
      title: 'Are you sure you want to delete this promotion?',
      onOk() {
        setIsLoading(true);

        deleteRateModifier(selectedPromotion._id)
          .then(() => {
            onModalPromotionClose();
            Message.success('Successfully deleted this promotion!');
          })
          .catch(ex => {
            setIsLoading(false);
            Message.error(ex.toString());
            console.error(ex);
          });
      },
      onCancel() {}
    });
  };

  const rateModifierTitle = (
    <>
      <span className={`${styles.rateAdjustmentLabelAsterisk}`}>*</span>
      <span className={`${styles.rateAdjustmentLabel}`}>Rate Modifier</span>
    </>
  );

  return (
    <Modal title={title} visible={isShowModalPromotion} onCancel={() => onModalPromotionClose()} width="60%" footer={null} destroyOnClose="true">
      <Card className={styles.cardContainer} hideCloseIcon="true">
        <Row gutter={16}>
          {isEdit && (
            <Col span={24} md={24} style={{ marginBottom: 16 }}>
              <BareRadioButton
                options={[PROMOTION_STATUS.ACTIVE, PROMOTION_STATUS.INACTIVE]}
                onChange={e => setPromotionStatus(e.target.value)}
                defaultValue={promotionStatus}
              />
            </Col>
          )}
          <Col span={24} md={12}>
            <FormSelection
              form={form}
              name="hostId"
              formLabel="Host Name"
              placeholder="Please select a host"
              requiredErrorMessage="Please select a host"
              defaultValue={selectedHostId}
              selections={hostSelections}
              onChange={handleOnHostChange}
            />
            <FormInput
              form={form}
              name="rateName"
              formLabel="Promotion Name"
              placeholder="Please enter Promotion Name"
              requiredErrorMessage="Please enter Promotion Name"
              extraRules={[{ validator: checkIsDuplicatePromotionName }]}
              defaultValue={rateName}
            />
            <RateModifierFormItems
              form={form}
              title={rateModifierTitle}
              isPercentage={isTypePercentage}
              modifierTypeName={'modifierType'}
              defaultModifierType={defaultModifierType}
              modifierSignName={'modifierSign'}
              defaultModifierSign={defaultModifierSign}
              modifierAmountName={'modifierAmount'}
              defaultRateModifierAmount={defaultRateModifierAmount}
              rateModifierTypeConstants={rateModifierTypes}
              rateModifierSignConstants={rateModifierSigns}
              onTypeChange={handleOnModifierTypeChange}
              currency={currency}
              timezone={timezone}
            />
          </Col>
          <Col span={24} md={12} style={{ marginTop: 7 }}>
            <FormDatePickerRange form={form} formLabel="Promotion Period" fieldName="promotionPeriod" defaultPickerValue={promotionPeriod} />
            <FormDatePickerRange form={form} formLabel="Stay Period" fieldName="stayPeriod" defaultPickerValue={stayPeriod} />
            <FormInputNumber
              form={form}
              formLabel="Minimum Nights"
              name="minimumNight"
              defaultValue={minimumNight}
              placeholder="Booking's minimum night"
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <FormCheckbox
              form={form}
              formLabel="Promotion Applicable (Days)"
              name="daysAllowed"
              defaultValue={daysAllowed}
              selections={daysInWeekSelections}
            />
          </Col>
          <Col span={24}>
            <DisallowedDatesEditListDetails
              form={form}
              disallowedDates={datesDisallowed}
              onClickRemoveDates={handleOnRemoveDisallowedDates}
              checkIsValidDates={checkIsDisallowedDateValid}
            />
          </Col>
          <Col span={24} md={24} style={{ marginBottom: 24 }}>
            <Button type="primary" onClick={e => handleOnAddNewDisallowedDates()}>
              Add new inapplicable date
            </Button>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Card
              type="inner"
              className="styles.promoCardContainer"
              title="Promotion Code"
              extra={
                <Button type="link" onClick={e => handleOnShowEditPromotionCode(isEditPromotionCode ? false : true)}>
                  {isEditPromotionCode ? 'Cancel' : 'Customize'}
                </Button>
              }
              hideCloseIcon="true"
            >
              {isEditPromotionCode ? (
                <Row gutter={8}>
                  <Col span={24} md={24}>
                    <p>Custom type in code</p>
                  </Col>
                  <Col span={24} md={24}>
                    <PromoCodeEditListDetails
                      form={form}
                      promotionCodes={promotionCodes}
                      onClickRemoveCode={handleOnRemoveCode}
                      checkIsDuplicateCode={checkIsDuplicateCode}
                    />
                  </Col>
                  <Col span={24} md={24}>
                    <Button type="primary" onClick={e => handleOnAddNewCode()}>
                      Add a new code
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <PromoCodeShowListDetails defaultPromotionCodes={defaultPromotionCodes} />
                </Row>
              )}
            </Card>
          </Col>

          <Col span={24} style={{ marginTop: 16 }}>
            <FormInput
              form={form}
              name="remarks"
              formLabel="Remarks"
              inputType="textarea"
              placeholder="Please enter remarks (optional)"
              defaultValue={remarks}
            />
          </Col>

          <Col span={24}>
            <Alert type="info" className="styles.info" message="Promotions modifiers will not appear in Booking form's Room Rate Modifier" />
          </Col>
        </Row>
      </Card>

      <Row type="flex" justify="start" gutter={8} className={styles.button}>
        {(!isEdit || isAllowEditRateModifier) && (
          <Col>
            <Button
              id="create-button3a-promotion"
              type="primary"
              size="large"
              onClick={e => handleOnSubmit(e)}
              loading={isLoading}
              disabled={checkIsAdminReadOnly()}
            >
              {isEdit ? 'Save' : 'Create'}
            </Button>
          </Col>
        )}
        {isAllowDeleteRateModifier && (
          <Col>
            <Button
              id="del-button1-editpromotion"
              disabled={!isEdit || checkIsAdminReadOnly()}
              type="danger"
              size="large"
              onClick={e => handleOnDelete(e)}
              loading={isLoading}
            >
              Delete
            </Button>
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default Form.create()(ModalPromotion);
