import { httpClient } from './../httpClient';
import { apiErrorHandler } from './helpers';
import { useCustomPaginatedQuery, useCustomQuery } from 'hooks/reactQuery';

/* ==================================================General================================================= */
// ========================================CREATE
export const createHost = hostDetails =>
  httpClient
    .post('/host/createHost', hostDetails)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);

// ========================================GET
export const getHosts = () =>
  httpClient
    .get('/host/populated')
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);

export const getHostsWithoutParent = id =>
  httpClient
    .get(`/host/filteredHosts`, { params: { id } })
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);

export const getHostsAutomated = () =>
  httpClient
    .get('/host')
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);

export const getHostsForPayout = () =>
  httpClient
    .get('/host/getHostsForPayout')
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getHostById = id =>
  httpClient
    .get(`/host/${id}/info`)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);

export const getOwners = hostId =>
  httpClient
    .get(`/host/${hostId}/owners`)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getUserHosts = () =>
  httpClient
    .get(`/host/populated`)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const useGetHosts = postProcessGetHosts => {
  const getHosts = async () => {
    return httpClient
      .get('/host')
      .then(response => response.data)
      .catch(apiErrorHandler);
  };

  return useCustomQuery('getHosts', [], getHosts, { shouldDefaultEmptyObject: false, postProcessFunc: postProcessGetHosts });
};

export const useGetHostById = hostId => {
  const getHostById = (key, hostId) =>
    httpClient
      .get(`/host/${hostId}/info`)
      .then(response => response.data)
      .catch(apiErrorHandler);

  return useCustomQuery('getHostById', [hostId], getHostById, { shouldDefaultEmptyObject: false });
};

export const useGetPaginatedHosts = query => {
  const getPaginatedHosts = async (key, query) => {
    return httpClient
      .get('/host/paginated', { params: query })
      .then(res => res.data)
      .catch(apiErrorHandler);
  };

  return useCustomPaginatedQuery('hosts', getPaginatedHosts, query);
};

export const useGetHostsCSV = () => {
  const getHostsCSV = async () => {
    return httpClient
      .get('/host/csv')
      .then(response => response.data)
      .catch(apiErrorHandler);
  };

  return useCustomQuery('getHostsCSV', [], getHostsCSV, { shouldDefaultEmptyObject: false });
};

// ========================================UPDATE
export const updateHost = (id, body) =>
  httpClient
    .put(`/host/${id}/updateHost`, body)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);

// ========================================DELETE
export const deleteHost = id =>
  httpClient
    .delete(`/host/${id}`)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);

/* ==================================================Special================================================= */
// ========================================CHECKING
export const checkHostIsAllowedAllOptions = id => {
  return httpClient
    .get(`/host/${id}/checkAllowedAllOptions`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response;
    });
};

export const checkHostCanAccessBookingEngine = id => {
  return httpClient
    .get(`/host/${id}/bookingEngineStatus`)
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const checkHostCanAccessHotelBookingEngine = id => {
  return httpClient
    .get(`/host/${id}/hotelBookingEngineStatus`)
    .then(response => response.data)
    .catch(apiErrorHandler);
};

// ========================================ADMIN

export const updateHostPurchasedPlan = (id, body) =>
  httpClient
    .put(`/admin/host/${id}/purchasedPlan`, body)
    .then(response => {
      return response;
    })
    .catch(apiErrorHandler);

// ========================================STRIPE
export const getStripeConnectAccountLink = hostId => {
  return httpClient
    .get(`/host/${hostId}/stripe-connect`)
    .then(response => response.data)
    .catch(apiErrorHandler);
};

export const patchStripeConnectAccountId = (hostId, stripeConnectAccountId) => {
  return httpClient
    .patch(`/host/${hostId}/stripe-connect/${stripeConnectAccountId}`)
    .then(response => response.data)
    .catch(apiErrorHandler);
};
