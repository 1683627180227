import React, { useState } from 'react';
import { Avatar, Button, Card, Table, Tooltip } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { withAppContext } from 'context/AppContext';
import { useGetPaginatedHosts, useGetHostsCSV } from 'utils/apis/host';

import { useFetchConstant } from 'hooks/constants';
import { getConstantLabel } from 'utils/general';
import circledS from 'images/circled-s.png';
import { constructColumn, constructColumnFilterSearch, constructColumnFilterRadio, handleOnAggregationTableChange } from 'utils/table/table';

import './Host.css';

const moment = require('moment');

const constructCSVName = () => {
  const curDateTime = moment().format('YYYY_MM_DD_HH_mm_ss');
  const csvName = String('host_')
    .concat(curDateTime.toString())
    .concat('.csv');
  return csvName;
};

const getHostRenderProp = () => ({
  render: (text, record) => {
    const isCreatedFromStaySuites = record.isCreatedFromStaySuites;

    return (
      <div>
        {isCreatedFromStaySuites && (
          <Tooltip title="This host is created from StaySuites">
            <Avatar key={`isCreatedFromStaySuites - ${record._id}`} size={16} src={circledS} style={{ marginBottom: '4px' }} />{' '}
          </Tooltip>
        )}
        <Link to={`/host/${record._id}/edit`}>{text}</Link>
      </div>
    );
  }
});

const constructColumns = (hostTypes, partnershipStatuses) => [
  {
    ...constructColumn('Host Name', 'name'),
    ...constructColumnFilterSearch('name', 'Search Host Name'),
    ...getHostRenderProp()
  },
  {
    ...constructColumn('Email Address', 'email'),
    ...constructColumnFilterSearch('email', 'Search Email Address')
  },
  {
    ...constructColumn('Contact Number', 'contactNo'),
    ...constructColumnFilterSearch('contactNo', 'Search Contact Number')
  },
  {
    ...constructColumn('Contract Start Date', 'signUpDate', { hasSorter: true })
  },
  {
    ...constructColumn('Contract End Date', 'endDate', { hasSorter: true })
  },
  {
    ...constructColumn('Host Type', 'hostType'),
    ...constructColumnFilterRadio('hostType', hostTypes),
    render: type => getConstantLabel(hostTypes, type)
  },
  {
    ...constructColumn('Partnership Status', 'partnerStatus'),
    ...constructColumnFilterRadio('partnerStatus', partnershipStatuses),
    render: status => getConstantLabel(partnershipStatuses, status)
  }
];

const useFetchConstants = () => {
  const { selection: partnershipStatuses, isLoading: isPartnerStatusesLoading } = useFetchConstant('partnerStatus');
  const { selection: hostTypes, isLoading: isHostTypesLoading } = useFetchConstant('hostType');
  const { selection: states, isLoading: isStatesLoading } = useFetchConstant('statesMY');
  const { selection: countries, isLoading: isCountriesLoading } = useFetchConstant('countries', { valueKey: 'iso2', labelKey: 'name' });
  console.log(hostTypes);
  const selections = { partnershipStatuses, hostTypes, states, countries };
  const isLoading = isPartnerStatusesLoading || isHostTypesLoading || isStatesLoading || isCountriesLoading;

  return { selections, isLoading };
};

const Host = ({ checkIsAdmin, checkIsAdminReadOnly, ...props }) => {
  const [query, setQuery] = useState({});
  const { paginatedData: hosts, total, isLoading: isHostsLoading } = useGetPaginatedHosts(query);
  const {
    isLoading: isConstantsLoading,
    selections: { partnershipStatuses, hostTypes }
  } = useFetchConstants();
  const { data: hostsCSVData, isLoading: isHostsCSVLoading } = useGetHostsCSV();

  const isLoading = isHostsLoading || isConstantsLoading;
  const csvName = constructCSVName();
  const isAdmin = checkIsAdmin();

  return (
    <Card className="list-card">
      <div className="sp-controls">
        {isAdmin && (
          <Link to={'/host/new'}>
            <Button id="create-button4-host" type="primary" icon="plus" disabled={checkIsAdminReadOnly()}>
              Create Host
            </Button>
          </Link>
        )}
        {props.checkAbleExportHost() &&
          (!isHostsCSVLoading && (
            <div className="host-csv">
              <CSVLink filename={csvName} data={hostsCSVData} className="sp-csv">
                <Button id="csv-button3-host" type="primary" icon="download" disabled={checkIsAdminReadOnly()}>
                  Download CSV
                </Button>
              </CSVLink>
            </div>
          ))}
      </div>
      <Table
        loading={isLoading}
        rowKey={record => record._id}
        dataSource={hosts}
        columns={constructColumns(hostTypes, partnershipStatuses)}
        pagination={{ total }}
        onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
      />
    </Card>
  );
};

export default withRouter(withAppContext(Host));
