import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, notification } from 'antd';

import { errorHandlerWrapper, constructTwoDecimalsRule, generateFeeFromDisplay } from 'utils/general';
import { putRentalUnit } from 'utils/apis/unit';

import FormInput from 'components/FormInput/FormInput';

const EditRentalUnitModal = ({ form, isShowingModal, selectedRentalUnit, closeModal, onEditRentalUnitSuccess, checkIsAdminReadOnly }) => {
  const handleOnEditRentalUnit = e => {
    e.preventDefault();

    form.validateFieldsAndScroll({ force: true }, (err, values) => {
      if (!err) {
        const successNotification = () =>
          notification.success({
            duration: 3,
            message: 'Success',
            description: "You've successfully updated this unit's rental detail."
          });

        const rentalFee = generateFeeFromDisplay(values.rentalFee);
        const cleaningFee = generateFeeFromDisplay(values.cleaningFee);

        const payload = {
          rentalDetail: {
            rentalFee,
            cleaningFee,
            contactNumber: values.contactNumber,
            details: values.details
          }
        };

        errorHandlerWrapper(
          putRentalUnit(selectedRentalUnit._id, payload).then(() => {
            onEditRentalUnitSuccess();
            successNotification();
            closeModal();
          })
        );
      }
    });
  };

  return (
    <Modal
      title="Rental Details"
      visible={isShowingModal}
      okText="Save"
      onOk={handleOnEditRentalUnit}
      okButtonProps={{ id: 'save-button8-editrental', disabled: checkIsAdminReadOnly() }}
      cancelButtonProps={{ id: 'cancel-button1-editrental' }}
      onCancel={closeModal}
      destroyOnClose
    >
      <Form>
        <FormInput form={form} name="unitName" formLabel="Unit" defaultValue={selectedRentalUnit.unitName} disabled />
        <FormInput form={form} name="propertyName" formLabel="Property" defaultValue={selectedRentalUnit.propertyName} disabled />
        <FormInput form={form} name="roomTypeName" formLabel="Room Type" defaultValue={selectedRentalUnit.roomTypeName} disabled />
        <FormInput form={form} name="weekdayPrice" formLabel="Weekday Price" defaultValue={selectedRentalUnit.weekdayPrice} disabled />
        <FormInput form={form} name="weekendPrice" formLabel="Weekend Price" defaultValue={selectedRentalUnit.weekendPrice} disabled />
        <FormInput
          form={form}
          name="rentalFee"
          formLabel="Rental"
          defaultValue={selectedRentalUnit.rentalFee}
          addonBefore={selectedRentalUnit.currency || 'RM'}
          extraRules={[constructTwoDecimalsRule()]}
          placeholder="0.00"
        />
        <FormInput
          form={form}
          name="cleaningFee"
          formLabel="Cleaning Fee"
          defaultValue={selectedRentalUnit.cleaningFee}
          addonBefore={selectedRentalUnit.currency || 'RM'}
          extraRules={[constructTwoDecimalsRule()]}
          placeholder="0.00"
        />
        <FormInput
          form={form}
          name="contactNumber"
          formLabel="Contact No."
          defaultValue={selectedRentalUnit.contactNumber}
          placeholder="+603 7886 7829"
        />
        <FormInput
          form={form}
          name="details"
          formLabel="Details"
          defaultValue={selectedRentalUnit.details}
          inputType="textarea"
          placeholder="Details (Optional)"
        />
      </Form>
    </Modal>
  );
};

EditRentalUnitModal.propTypes = {
  form: PropTypes.object.isRequired,
  isShowingModal: PropTypes.bool.isRequired,
  selectedRentalUnit: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  onEditRentalUnitSuccess: PropTypes.func.isRequired
};

export default Form.create()(EditRentalUnitModal);
