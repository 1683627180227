import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar, Icon, Table, Tooltip } from 'antd';

import circledS from 'images/circled-s.png';
import { useGetPaginatedUnitListings } from 'utils/apis/unit';
import { buildListingDetailsUri } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';
import { generateDisplayFee } from 'utils/general';

const getUnitRenderProp = checkIsAdminReadOnly => ({
  render: (text, record) => {
    const isCreatedFromStaySuites = record.isCreatedFromStaySuites;
    const hasProtection = record.hasProtection;
    const isProtectionActive = record.isProtectionActive;

    const title = `HostProtect ${hasProtection ? (isProtectionActive ? 'Activated' : 'Expired') : 'Not Activated'}`;
    const type = hasProtection ? (isProtectionActive ? 'check-circle' : 'clock-circle-o') : 'warning';
    const twoToneColor = hasProtection && isProtectionActive ? '#52c41a' : '#db4437';

    return (
      <div>
        {isCreatedFromStaySuites && (
          <Tooltip title="This unit is created from StaySuites">
            <Avatar key={`isCreatedFromStaySuites - ${record._id}`} size={16} src={circledS} style={{ marginBottom: '4px' }} />{' '}
          </Tooltip>
        )}
        <Tooltip title={title}>
          <Icon type={type} theme="twoTone" twoToneColor={twoToneColor} />{' '}
        </Tooltip>
        <Link to={buildListingDetailsUri(record._id)}>{text}</Link>
      </div>
    );
  }
});

const constructColumns = ({ filters, sorter }, checkIsAdminReadOnly) => [
  {
    ...constructColumn('Unit name', 'unitName', { hasSorter: true, sorter }),
    ...constructColumnFilterSearch('unitName', 'Search Unit name', filters),
    ...getUnitRenderProp(checkIsAdminReadOnly)
  },
  {
    ...constructColumn('Property Name', 'propertyName'),
    ...constructColumnFilterSearch('propertyName', 'Search Property Name', filters)
  },
  {
    ...constructColumn('Room Type Name', 'roomTypeName'),
    ...constructColumnFilterSearch('roomTypeName', 'Search Room Type Name', filters)
  },
  {
    ...constructColumn('Weekday Price', 'weekdayPrice', { isRM: false }),
    render: (text, record) => <span>{generateDisplayFee(text, true, 0, record.currency)}</span>
  },
  {
    ...constructColumn('Weekend Price', 'weekendPrice', { isRM: false }),
    render: (text, record) => <span>{generateDisplayFee(text, true, 0, record.currency)}</span>
  },
  {
    ...constructColumn('Host Name', 'hostName', { hasSorter: true, sorter }),
    ...constructColumnFilterSearch('hostName', 'Search Host Name', filters)
  },
  {
    ...constructColumn('Owner Name', 'ownerName', { hasSorter: true, sorter }),
    ...constructColumnFilterSearch('ownerName', 'Search Owner Name', filters)
  }
];

const Body = ({ query, tableQuery, onTableChange, checkIsAdminReadOnly }) => {
  const { paginatedData: units, isLoading, total } = useGetPaginatedUnitListings(query);

  return (
    <Table
      rowKey={record => record._id}
      dataSource={units}
      columns={constructColumns(tableQuery, checkIsAdminReadOnly)}
      scroll={{ x: 1000 }}
      onChange={(pagination, filters, sorter) => onTableChange({ pagination, filters, sorter })}
      pagination={{ total }}
      loading={isLoading}
    />
  );
};

Body.propTypes = {
  query: PropTypes.object.isRequired,
  tableQuery: PropTypes.object,
  onTableChange: PropTypes.func.isRequired
};

export default Body;
