import { httpClient } from './../httpClient';
import { apiErrorHandler } from './helpers';

const BILLING_URI = '/billing';
const BILLING_INVOICE_URI = `${BILLING_URI}/invoice`;

export const getInvoices = () =>
  httpClient
    .get(BILLING_INVOICE_URI)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getInvoiceById = id =>
  httpClient
    .get(`${BILLING_INVOICE_URI}/${id}`)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const createInvoice = payload =>
  httpClient
    .post(BILLING_INVOICE_URI, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const updateInvoice = (id, payload) =>
  httpClient
    .put(`${BILLING_INVOICE_URI}/${id}`, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const updatePaymentStatus = token =>
  httpClient
    .put(`${BILLING_INVOICE_URI}/paymentStatus/`, { token })
    .then(response => response.data)
    .catch(apiErrorHandler);

export const deleteInvoice = payload =>
  httpClient
    .delete(`${BILLING_INVOICE_URI}/${payload.id}`)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getPaymentSessionById = id =>
  httpClient
    .get(`${BILLING_INVOICE_URI}/${id}/paymentSession`)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const createPaymentViaFpx = payload =>
  httpClient
    .post(`${BILLING_INVOICE_URI}/payment`, payload)
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getFpxPaymentStatus = token =>
  httpClient
    .put(`${BILLING_INVOICE_URI}/fpxPaymentStatus/`, { token })
    .then(response => response.data)
    .catch(apiErrorHandler);

export const getHostStatus = () =>
  httpClient
    .get(`${BILLING_INVOICE_URI}/host-status`)
    .then(response => response.data)
    .catch(apiErrorHandler);
