import React, { Component } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { BlobProvider, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const calculateTotalCharges = data => {
  const roomRate = data.roomRate || 0.0;
  const stripeCharges = data.stripeCharges || 0.0;
  const cleaningFee = data.cleaningFee || 0.0;
  const extraGuestFee = data.extraGuestFee || 0.0;
  const earlyCheckInFee = data.earlyCheckInFee || 0.0;
  const lateCheckOutFee = data.lateCheckOutFee || 0.0;
  const shuttleFee = data.shuttleFee || 0.0;
  const transportFee = data.transportFee || 0.0;
  const breakfastFee = data.breakfastFee || 0.0;
  const lunchFee = data.lunchFee || 0.0;
  const dinnerFee = data.dinnerFee || 0.0;
  const otherFee = data.otherFee || 0.0;
  const tourismTax = data.tourismTax || 0.0;
  const heritageTax = data.heritageTax || 0.0;
  const otaTax = data.otaTax || 0.0;

  return (
    roomRate +
    stripeCharges +
    cleaningFee +
    extraGuestFee +
    earlyCheckInFee +
    lateCheckOutFee +
    shuttleFee +
    transportFee +
    breakfastFee +
    lunchFee +
    dinnerFee +
    otherFee +
    tourismTax +
    heritageTax +
    otaTax
  );
};

const calculateExtraLines = data => {
  const cleaningFeeLine = data.cleaningFee ? 1 : 0;
  const extraGuestFeeLine = data.extraGuestFee ? 1 : 0;
  const earlyCheckInFeeLine = data.earlyCheckInFee ? 1 : 0;
  const lateCheckOutFeeLine = data.lateCheckOutFee ? 1 : 0;
  const shuttleFeeLine = data.shuttleFee ? 1 : 0;
  const transportFeeLine = data.transportFee ? 1 : 0;
  const breakfastFeeLine = data.breakfastFee ? 1 : 0;
  const lunchFeeLine = data.lunchFee ? 1 : 0;
  const dinnerFeeLine = data.dinnerFee ? 1 : 0;
  const otherFeeLine = data.otherFee ? 1 : 0;
  const tourismTaxLine = data.tourismTax ? 1 : 0;
  const heritageTaxLine = data.heritageTax ? 1 : 0;
  const otaTaxLine = data.otaTax ? 1 : 0;

  return (
    12 -
    (cleaningFeeLine +
      extraGuestFeeLine +
      earlyCheckInFeeLine +
      lateCheckOutFeeLine +
      shuttleFeeLine +
      transportFeeLine +
      breakfastFeeLine +
      lunchFeeLine +
      dinnerFeeLine +
      otherFeeLine +
      tourismTaxLine +
      heritageTaxLine +
      otaTaxLine)
  );
};

class TaxQuotation extends Component {
  static propTypes = {
    data: PropTypes.object,
    currency: PropTypes.string
  };

  handleOnClickPdfBtn = url => {
    window.open(url, '_blank');
  };

  constructCheckOutTimeText = (checkOutTime = '12:00') => {
    const splittedCheckOutTime = checkOutTime.split(':');
    if (splittedCheckOutTime.length > 0) {
      const hourValue = Number(splittedCheckOutTime[0]);
      const minuteValue = Number(splittedCheckOutTime[1]);
      if (hourValue > 12) {
        return `${hourValue - 12}:${minuteValue < 10 ? `0${minuteValue}` : minuteValue} PM`;
      } else if (hourValue === 12) {
        return `${hourValue}:${minuteValue < 10 ? `0${minuteValue}` : minuteValue} PM`;
      } else if (hourValue === 0) {
        return `12:${minuteValue < 10 ? `0${minuteValue}` : minuteValue} AM`;
      } else {
        return `${hourValue}:${minuteValue < 10 ? `0${minuteValue}` : minuteValue} AM`;
      }
    }
    return `${checkOutTime} PM`;
  };

  constructDataForTaxQuotationPdf = reservation => {
    const { platform, charges, depositCollected, depositRefunded, endDate, guestDetails, payment, remarks, startDate, taxes, unit } = reservation;
    const { getGuestNationality } = this.props;
    return {
      propertyName: unit.roomType.property.name || '',
      roomType: unit.roomType.name || '',
      checkOutTime: unit.roomType.property.checkOutTime || '',
      unitName: unit.name || '',
      checkIn: startDate || '',
      checkOut: endDate || '',
      bookingSource: platform || '',
      noPax: guestDetails.numberOfPax || 0,
      remarks: remarks || '',
      depositCollected: depositCollected || 0,
      depositRefunded: depositRefunded || 0,
      roomRate: (charges && charges.rental) || 0,
      stripeCharges: (charges && charges.stripeCharges) || 0,
      cleaningFee: (charges && charges.cleaning) || 0,
      extraGuestFee: (charges && charges.extraGuest) || 0,
      earlyCheckInFee: (charges && charges.earlyCheckIn) || 0,
      lateCheckOutFee: (charges && charges.lateCheckOut) || 0,
      shuttleFee: (charges && charges.shuttle) || 0,
      transportFee: (charges && charges.transportation) || 0,
      breakfastFee: (charges && charges.breakfast) || 0,
      lunchFee: (charges && charges.lunch) || 0,
      dinnerFee: (charges && charges.dinner) || 0,
      otherFee: (charges && charges.other) || 0,
      sstTax: (taxes && taxes.sst) || 0,
      tourismTax: (taxes && taxes.tourism) || 0,
      heritageTax: (taxes && taxes.heritage) || 0,
      otaTax: (taxes && taxes.ota) || 0,
      guestName: (guestDetails.userProfile && `${guestDetails.userProfile.firstName || '-'} ${guestDetails.userProfile.lastName || ''}`) || '-',
      hostEmail: unit.roomType.property.host.email || '-',
      hostContactNo: unit.roomType.property.host.contactNo || '-',
      hostData: unit.roomType.property.host || {},
      nationality: guestDetails.userProfile && getGuestNationality(guestDetails.userProfile.nationality),
      paymentReceived: payment.total
    };
  };

  render() {
    const { data, reservation, currency } = this.props;
    const todayDate = new Date().toISOString().split('T')[0];

    const quotationData = {
      ...this.constructDataForTaxQuotationPdf(reservation),
      ...data
    };

    const styles = StyleSheet.create({
      page: {
        paddingTop: 10,
        paddingRight: 10,
        paddingLeft: 10,
        paddingBottom: 10,
        width: '100%'
      },
      image: {
        width: 80,
        height: 80,
        marginLeft: 0
      },
      fontSize: {
        color: '#212121',
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 10,
        paddingRight: 10,
        paddingLeft: 10
      },
      fontPolicy: {
        color: '#212121',
        width: '100%',
        margin: 'auto',
        textAlign: 'left',
        fontSize: 8,
        paddingRight: 10,
        paddingLeft: 10
      },
      text: {
        color: '#212121',
        width: '30%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 10,
        paddingTop: 5
      },
      addressText: {
        color: '#212121',
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 10,
        paddingTop: 5
      },
      titleText: {
        color: '#212121',
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 18
      },
      TableText: {
        color: '#212121',
        width: '100%',
        marginLeft: 13,
        textAlign: 'left',
        fontSize: 20,
        paddingTop: 10
      },
      flex: {
        flexDirection: 'row',
        width: '100%',
        fontSize: 10,
        justifyContent: 'space-between',
        marginTop: 40,
        marginLeft: 210
      },
      row: {
        flexDirection: 'row'
      },
      fields: {
        color: '#212121',
        width: '98%',
        textAlign: 'left',
        fontSize: 10,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10
      },
      fieldsOne: {
        color: '#212121',
        width: '45%',
        textAlign: 'left',
        fontSize: 10,
        paddingTop: 10
      },
      fieldsTwo: {
        color: '#212121',
        width: '50%',
        textAlign: 'left',
        fontSize: 10,
        paddingTop: 10
      },
      fieldsThree: {
        color: '#212121',
        width: '100%',
        textAlign: 'left',
        fontSize: 10,
        paddingBottom: 5
      },
      fieldsEmpty: {
        width: '5%'
      },
      fieldsLogo: {
        width: '20%',
        textAlign: 'left',
        paddingTop: 10,
        paddingBottom: 5
      },
      fieldsHostDetails: {
        fontSize: 10,
        width: '80%',
        textAlign: 'left',
        paddingLeft: 10,
        paddingTop: 10,
        paddingRight: 10,
        whiteSpace: 'normal'
      },
      fieldsFooter: {
        width: '98%',
        textAlign: 'left',
        fontSize: 10,
        paddingLeft: 10
      },
      borderBottom: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
        borderColor: 'black'
      },
      borderTop: {
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        alignItems: 'stretch',
        borderColor: 'black'
      },
      borderRight: {
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderColor: 'black'
      },
      borderLeft: {
        borderLeftWidth: 1,
        borderLeftStyle: 'solid',
        borderColor: 'black'
      },
      groupFields: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingRight: 15,
        paddingLeft: 15,
        margin: 'auto'
      },
      groupFieldsTwo: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingRight: 15,
        paddingLeft: 15,
        margin: 'auto'
      },
      guestSig: {
        textAlign: 'center',
        width: '100%',
        paddingRight: '3%'
      },
      paddingBtm: {
        paddingBottom: 5
      },
      Policy: {
        paddingTop: 10,
        width: '100%'
      },
      lastPolicyFont: {
        paddingTop: 5,
        width: '100%',
        fontSize: 8,
        paddingRight: 10,
        paddingLeft: 10
      }
    });
    const address = quotationData.hostData.useHostDetails
      ? quotationData.hostData.street +
        (quotationData.hostData.city ? ', ' + quotationData.hostData.city : '') +
        (quotationData.hostData.zipCode ? ', ' + quotationData.hostData.zipCode : '') +
        (quotationData.hostData.state ? ', ' + quotationData.hostData.state : '') +
        (quotationData.hostData.country ? ', ' + quotationData.hostData.country : '')
      : quotationData.street +
        (quotationData.city ? ', ' + quotationData.city : '') +
        (quotationData.zipCode ? ', ' + quotationData.zipCode : '') +
        (quotationData.state ? ', ' + quotationData.state : '') +
        (quotationData.country ? ', ' + quotationData.country : '');
    const paymentReceived = quotationData.paymentReceived === null ? 0.0 : quotationData.paymentReceived;
    const totalCharges = calculateTotalCharges(quotationData);
    const extraLines = calculateExtraLines(quotationData);
    const totalChargesWithSST = totalCharges + (quotationData.sstTax ? quotationData.sstTax : 0);

    let doc = (
      <Document>
        <Page size="A4" wrap>
          <View>
            <View style={styles.page}>
              <View style={styles.groupFields}>
                <View style={styles.fieldsLogo}>
                  <Image
                    style={styles.image}
                    src={
                      quotationData.image
                        ? quotationData.image
                        : 'https://dashboard-operator-image.s3.amazonaws.com/3386bffc-75c9-4390-a670-fc11ed8febe1_blanksquare.png'
                    }
                  />
                </View>
                <View style={styles.fieldsHostDetails}>
                  <Text style={{ ...styles.paddingBtm, fontSize: 12 }}>
                    {quotationData.hostData.useHostDetails ? quotationData.hostData.name : quotationData.propertyName || ''}
                  </Text>
                  <View style={styles.row}>
                    <View style={{ width: '20%' }}>
                      <Text style={styles.paddingBtm}>Address</Text>
                    </View>
                    <View style={{ width: '2%' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '83%' }}>
                      <Text style={{ ...styles.paddingBtm, whiteSpace: 'normal' }}>{address}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '20%' }}>
                      <Text style={styles.paddingBtm}>Email</Text>
                    </View>
                    <View style={{ width: '2%' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '85%' }}>
                      <Text style={styles.paddingBtm}>{quotationData.hostEmail || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '20%' }}>
                      <Text style={styles.paddingBtm}>Contact Number</Text>
                    </View>
                    <View style={{ width: '2%' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '85%' }}>
                      <Text style={styles.paddingBtm}>{quotationData.hostContactNo || '-'}</Text>
                    </View>
                  </View>
                </View>
              </View>
              <Text style={styles.titleText}>QUOTATION</Text>
              <View style={styles.groupFieldsTwo}>
                <View style={styles.fieldsOne}>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Attn</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '53%' }}>
                      <Text style={styles.paddingBtm}>{quotationData.guestName || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Guest Name</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '53%' }}>
                      <Text style={styles.paddingBtm}>{quotationData.guestName || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Nationality</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '53%' }}>
                      <Text style={styles.paddingBtm}>{quotationData.nationality || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Booking Source</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '53%' }}>
                      <Text style={{ ...styles.paddingBtm, textTransform: 'capitalize' }}>{quotationData.bookingSource || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Confirmation Code</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '53%' }}>
                      <Text style={styles.paddingBtm}>{quotationData.confirmationCode || '-'}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.fieldsEmpty} />
                <View style={styles.fieldsTwo}>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Room No</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '66%' }}>
                      <Text style={styles.paddingBtm}>{quotationData.unitName || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Rate</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '66%' }}>
                      <Text style={styles.paddingBtm}>{quotationData.roomType || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>No of pax</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '66%' }}>
                      <Text style={styles.paddingBtm}>{quotationData.noPax || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Date of Arrival</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '66%' }}>
                      <Text style={styles.paddingBtm}>{quotationData.checkIn || '-'}</Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Date of Departure</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '66%' }}>
                      <Text style={styles.paddingBtm}>{quotationData.checkOut || '-'}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.fieldsThree}>
                  <View style={styles.row}>
                    <View style={{ width: '90px' }}>
                      <Text style={styles.paddingBtm}>Remarks</Text>
                    </View>
                    <View style={{ width: '10px' }}>
                      <Text style={styles.paddingBtm}>: </Text>
                    </View>
                    <View style={{ width: '78%' }}>
                      <Text style={styles.paddingBtm}>{quotationData.remarks || '-'}</Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%'
                }}
              >
                <View
                  className="table"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '97%',
                    height: 23,
                    ...styles.fontSize
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      width: '15%',
                      textAlign: 'center',
                      paddingTop: 7,
                      ...styles.borderBottom,
                      ...styles.borderTop,
                      ...styles.borderLeft
                    }}
                  >
                    Date
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      width: '15%',
                      textAlign: 'center',
                      paddingTop: 7,
                      ...styles.borderBottom,
                      ...styles.borderTop,
                      ...styles.borderLeft
                    }}
                  >
                    Ref No.
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      paddingTop: 7,
                      paddingLeft: 22,
                      width: '55%',
                      textAlign: 'left',
                      ...styles.borderBottom,
                      ...styles.borderTop,
                      ...styles.borderLeft
                    }}
                  >
                    Particulars
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      paddingTop: 7,
                      width: '15%',
                      textAlign: 'center',
                      ...styles.borderBottom,
                      ...styles.borderTop,
                      ...styles.borderLeft,
                      ...styles.borderRight
                    }}
                  >
                    {currency ? currency : 'RM'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%'
                }}
              >
                <View
                  className="table"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '95%',
                    ...styles.fontSize
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderLeft
                    }}
                  >
                    {quotationData.checkIn}
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderLeft
                    }}
                  >
                    {quotationData.confirmationCode}
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      paddingLeft: 22,
                      paddingTop: 7,
                      width: '55%',
                      textAlign: 'left',
                      ...styles.borderLeft
                    }}
                  >
                    Room Rate
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderLeft,
                      ...styles.borderRight
                    }}
                  >
                    {quotationData.roomRate ? quotationData.roomRate.toFixed(2) : '0.00'}
                  </Text>
                </View>
              </View>
              {quotationData.stripeCharges === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />

                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Stripe Charges
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {quotationData.stripeCharges ? quotationData.stripeCharges.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {quotationData.cleaningFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />

                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Cleaning Fee
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {quotationData.cleaningFee ? quotationData.cleaningFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {quotationData.extraGuestFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Extra Guest Fee
                    </Text>

                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {quotationData.extraGuestFee ? quotationData.extraGuestFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {quotationData.earlyCheckInFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Early Check-in Fee
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {quotationData.earlyCheckInFee ? quotationData.earlyCheckInFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {quotationData.lateCheckOutFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Late Check-out Fee
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {quotationData.lateCheckOutFee ? quotationData.lateCheckOutFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {quotationData.shuttleFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Shuttle Fee
                    </Text>

                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {quotationData.shuttleFee ? quotationData.shuttleFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {quotationData.transportFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Transportation Fee
                    </Text>

                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {quotationData.transportFee ? quotationData.transportFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {quotationData.breakfastFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Breakfast
                    </Text>

                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {quotationData.breakfastFee ? quotationData.breakfastFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {quotationData.lunchFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Lunch
                    </Text>

                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {quotationData.lunchFee ? quotationData.lunchFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {quotationData.dinnerFee === 0 ? null : (
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Dinner
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {quotationData.dinnerFee ? quotationData.dinnerFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {quotationData.otherFee === 0 ? null : (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                  }}
                >
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Other
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {quotationData.otherFee ? quotationData.otherFee.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {quotationData.tourismTax === 0 ? null : (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                  }}
                >
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Tourism Tax
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {quotationData.tourismTax ? quotationData.tourismTax.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {quotationData.heritageTax === 0 ? null : (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                  }}
                >
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      Heritage Tax
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        width: '15%',
                        paddingTop: 7,
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {quotationData.heritageTax ? quotationData.heritageTax.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {quotationData.otaTax === 0 ? null : (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%'
                  }}
                >
                  <View
                    className="table"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: '95%',
                      ...styles.fontSize
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingTop: 7,
                        width: '15%',
                        ...styles.borderLeft
                      }}
                    />
                    <Text
                      style={{
                        fontSize: 8,
                        paddingLeft: 22,
                        paddingTop: 7,
                        width: '55%',
                        textAlign: 'left',
                        ...styles.borderLeft
                      }}
                    >
                      OTA Tax
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        width: '15%',
                        paddingTop: 7,
                        ...styles.borderLeft,
                        ...styles.borderRight
                      }}
                    >
                      {quotationData.otaTax ? quotationData.otaTax.toFixed(2) : 0.0}
                    </Text>
                  </View>
                </View>
              )}
              {new Array(extraLines || 0).fill(undefined).map((val, i) => {
                return (
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%'
                    }}
                    key={i}
                  >
                    <View
                      className="table"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        width: '95%',
                        ...styles.fontSize
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 8,
                          paddingTop: 7,
                          width: '15%',
                          ...styles.borderLeft
                        }}
                      />
                      <Text
                        style={{
                          fontSize: 8,
                          paddingTop: 7,
                          width: '15%',
                          ...styles.borderLeft
                        }}
                      />
                      <Text
                        style={{
                          fontSize: 8,
                          paddingLeft: 22,
                          paddingTop: 7,
                          width: '55%',
                          textAlign: 'left',
                          ...styles.borderLeft
                        }}
                      >
                        &nbsp;
                      </Text>
                      <Text
                        style={{
                          fontSize: 8,
                          paddingTop: 7,
                          width: '15%',
                          ...styles.borderLeft,
                          ...styles.borderRight
                        }}
                      />
                    </View>
                  </View>
                );
              })}

              <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <View
                  className="table"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '95%',
                    ...styles.fontSize
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderTop
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderTop
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingRight: 22,
                      paddingTop: 7,
                      width: '55%',
                      textAlign: 'right',
                      ...styles.borderTop
                    }}
                  >
                    Total :
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderTop,
                      ...styles.borderLeft,
                      ...styles.borderRight
                    }}
                  >
                    {totalCharges ? totalCharges.toFixed(2) : 0.0}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <View
                  className="table"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '95%',
                    ...styles.fontSize
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingRight: 22,
                      paddingTop: 7,
                      width: '55%',
                      textAlign: 'right'
                    }}
                  >
                    Total SST :
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderLeft,
                      ...styles.borderRight
                    }}
                  >
                    {quotationData.sstTax ? quotationData.sstTax.toFixed(2) : '0.00'}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <View
                  className="table"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '95%',
                    ...styles.fontSize
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingRight: 22,
                      paddingTop: 7,
                      width: '55%',
                      textAlign: 'right'
                    }}
                  >
                    Total Payable :
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderBottom,
                      ...styles.borderLeft,
                      ...styles.borderRight
                    }}
                  >
                    {totalChargesWithSST ? totalChargesWithSST.toFixed(2) : 0.0}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <View
                  className="table"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '95%',
                    ...styles.fontSize
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingRight: 22,
                      paddingTop: 7,
                      width: '55%',
                      textAlign: 'right'
                    }}
                  >
                    Payment :
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderLeft,
                      ...styles.borderRight
                    }}
                  >
                    {paymentReceived ? paymentReceived.toFixed(2) : '0.00'}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <View
                  className="table"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '95%',
                    ...styles.fontSize
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%'
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      paddingRight: 22,
                      paddingTop: 7,
                      width: '55%',
                      textAlign: 'right'
                    }}
                  >
                    Balance :
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      paddingTop: 7,
                      width: '15%',
                      ...styles.borderBottom,
                      ...styles.borderLeft,
                      ...styles.borderRight
                    }}
                  >
                    {((totalChargesWithSST || 0.0) - (paymentReceived || 0.0)).toFixed(2)}
                  </Text>
                </View>
              </View>
              <View style={styles.fields}>
                <Text style={styles.paddingBtm}>
                  Security Deposit Collected : {currency ? currency : 'RM'}{' '}
                  {quotationData.depositCollected ? quotationData.depositCollected.toFixed(2) : '0.00'}
                </Text>
                <Text style={{ ...styles.paddingBtm, borderBottom: '1px solid black' }}>
                  Security Deposit Refunded : {currency ? currency : 'RM'}{' '}
                  {quotationData.depositRefunded ? quotationData.depositRefunded.toFixed(2) : '0.00'}
                </Text>
              </View>
              <View style={styles.Policy}>
                <Text style={styles.fontPolicy}>{quotationData.finePrint}</Text>
              </View>
              <View style={styles.flex}>
                <View style={styles.guestSig}>
                  <Text>___________________________</Text>
                  <Text>Guest Signature</Text>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  width: '100%',
                  fontSize: 10,
                  paddingRight: 10,
                  paddingLeft: 10,
                  paddingTop: 10
                }}
              >
                <View
                  style={{
                    width: '50%'
                  }}
                >
                  <Text>Receptionist : {quotationData.createdBy.firstName + ' ' + quotationData.createdBy.lastName}</Text>
                  <Text>Date : {todayDate}</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );

    return (
      <BlobProvider document={doc} fileName={'quotation.pdf'}>
        {({ blob, url, loading, error }) => (
          <Button type="primary" loading={loading} className="modal-receipts-btn" onClick={() => this.handleOnClickPdfBtn(url)}>
            {loading ? 'Loading' : 'Quotation'}
          </Button>
        )}
      </BlobProvider>
    );
  }
}
export default TaxQuotation;
