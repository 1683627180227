import { Button, Table } from 'antd';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';

import { AppContext } from 'context/AppContext';

import { formatToDateString, getCurrentMoment } from 'utils/date';
import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';
import { buildTaskManagementChecklistCreateUri, buildTaskManagementChecklistEditUri } from 'utils/routes';

import { StyledCard, StyledHeader } from './Checklist.styles';
import { getAllChecklists } from 'utils/apis/taskManagement';

const Checklist = () => {
  const contextProvider = useContext(AppContext);
  const [isLoading, setLoading] = useState(true);
  const [checklists, setChecklists] = useState([]);

  useEffect(() => {
    getAllChecklists().then(res => {
      if (res.status === 200) {
        setChecklists(res.data);
        setLoading(false);
      }
    });
  }, []);

  const constructColumns = () => {
    return [
      {
        ...constructColumn('Checklist Name', 'checklistName', { linkFunc: record => buildTaskManagementChecklistEditUri(record._id) }),
        ...constructColumnFilterSearch('checklistName', 'Search Checklist')
      },
      {
        ...constructColumn('Host Name', 'host'),
        ...constructColumnFilterSearch('host', 'Search Host')
      },
      {
        ...constructColumn('Property Name', 'property'),
        ...constructColumnFilterSearch('property', 'Search Property')
      }
    ];
  };

  const constructCSV = checklists => {
    return checklists.map(checklist => {
      return {
        'Checklist Name': checklist.checklistName,
        Host: checklist.host,
        'Property Name': checklist.property,
        Items: checklist.items
      };
    });
  };

  const currentDate = useMemo(() => formatToDateString(getCurrentMoment()), []);
  const csvName = useMemo(() => `checklists_${currentDate}.csv`, [currentDate]);

  return (
    <StyledCard>
      <StyledHeader>
        {contextProvider.checkIsAllowCreateTask() && (
          <Link to={buildTaskManagementChecklistCreateUri()}>
            <Button id="create-button-checklist" type="primary" icon="plus" disabled={contextProvider.checkIsAdminReadOnly()}>
              Create Checklist
            </Button>
          </Link>
        )}
        <CSVLink filename={csvName} data={constructCSV(checklists)}>
          <Button id="csv-button-checklist" type="primary" icon="download" disabled={contextProvider.checkIsAdminReadOnly()}>
            Download CSV
          </Button>
        </CSVLink>
      </StyledHeader>
      <Table loading={isLoading} scroll={{ x: 1000 }} rowKey={record => record._id} columns={constructColumns()} dataSource={checklists} />
    </StyledCard>
  );
};

export default Checklist;
