import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar, Icon, Table, Tooltip, Tag, Row, Button, Col, Select, Checkbox, Switch } from 'antd';

import circledS from 'images/circled-s.png';
import { useGetPaginatedUnitListings } from 'utils/apis/torusAccess';
import { buildListingDetailsUri } from 'utils/routes';
import { constructColumn, constructColumnFilterSearch } from 'utils/table/table';

import { getAccessList, updateAuthorizeToken } from '../../../../../../../../../utils/apis/torusAccess';

const getUnitRenderProp = checkIsAdminReadOnly => ({
  render: (text, record) => {
    const isCreatedFromStaySuites = record.isCreatedFromStaySuites;
    const hasProtection = record.hasProtection;
    const isProtectionActive = record.isProtectionActive;

    const title = `HostProtect ${hasProtection ? (isProtectionActive ? 'Activated' : 'Expired') : 'Not Activated'}`;
    const type = hasProtection ? (isProtectionActive ? 'check-circle' : 'clock-circle-o') : 'warning';
    const twoToneColor = hasProtection && isProtectionActive ? '#52c41a' : '#db4437';

    return (
      <div>
        {isCreatedFromStaySuites && (
          <Tooltip title="This unit is created from StaySuites">
            <Avatar key={`isCreatedFromStaySuites - ${record._id}`} size={16} src={circledS} style={{ marginBottom: '4px' }} />{' '}
          </Tooltip>
        )}
        <Tooltip title={title}>
          <Icon type={type} theme="twoTone" twoToneColor={twoToneColor} />{' '}
        </Tooltip>

        {checkIsAdminReadOnly() ? <span>{text}</span> : <Link to={buildListingDetailsUri(record._id)}>{text}</Link>}
      </div>
    );
  }
});

const getAuthorizationRenderProp = (selectedToken, refetch, checkIsAdminReadOnly) => ({
  render: (text, record) => {
    const handleOnClick = async (unit, is_add_new) => {
      console.log(selectedToken, unit);

      const res = await updateAuthorizeToken({ token: selectedToken, unit, is_add_new });
      console.log(res);
      refetch();
    };
    // console.log(record);
    if (record.ownerName) {
      return (
        <div>
          {record.authorized ? (
            <Switch checked={true} checkedChildren="Authorized" onClick={e => handleOnClick(record._id, false)} disabled={checkIsAdminReadOnly()} />
          ) : (
            <Switch
              checked={false}
              unCheckedChildren={'Unauthorized'}
              onClick={e => handleOnClick(record._id, true)}
              disabled={checkIsAdminReadOnly()}
            />
          )}
        </div>
      );
    } else {
      return '-';
    }
  }
});

const constructColumns = ({ filters, sorter }, selectedToken, refetch, checkIsAdminReadOnly) => [
  {
    ...constructColumn('Unit name', 'unitName', { hasSorter: true, sorter }),
    ...constructColumnFilterSearch('unitName', 'Search Unit name', filters),
    ...getUnitRenderProp(checkIsAdminReadOnly)
  },
  {
    ...constructColumn('Property Name', 'propertyName'),
    ...constructColumnFilterSearch('propertyName', 'Search Property Name', filters)
  },
  {
    ...constructColumn('Room Type Name', 'roomTypeName'),
    ...constructColumnFilterSearch('roomTypeName', 'Search Room Type Name', filters)
  },
  {
    ...constructColumn('Weekday Price', 'weekdayPrice', { isRM: true })
  },
  {
    ...constructColumn('Weekend Price', 'weekendPrice', { isRM: true })
  },
  {
    ...constructColumn('Host Name', 'hostName', { hasSorter: true, sorter }),
    ...constructColumnFilterSearch('hostName', 'Search Host Name', filters)
  },
  {
    ...constructColumn('Owner Name', 'ownerName', { hasSorter: true, sorter }),
    ...constructColumnFilterSearch('ownerName', 'Search Owner Name', filters)
  },
  {
    ...constructColumn('Authorisation', 'authorized', { hasSorter: true, sorter }),
    ...getAuthorizationRenderProp(selectedToken, refetch, checkIsAdminReadOnly)
  }
];

const Body = ({ query, tableQuery, onTableChange, setToken, checkIsAdminReadOnly }) => {
  console.log(query);
  const [tokenList, setTokenList] = useState([]);
  const [loading, setLoading] = useState([]);
  const [selectedToken, setSelectedToken] = useState('');

  const { paginatedData: units, isLoading, total, refetch } = useGetPaginatedUnitListings(query);

  useEffect(() => {
    getTokenList();
  }, []);

  useEffect(() => {
    setToken(selectedToken);
  }, [selectedToken]);

  const getTokenList = async () => {
    setLoading(true);
    const res = await getAccessList();
    console.log(res);
    if (res && res.status === 200) {
      setLoading(false);
      setTokenList(res.data);
      if (res.data && res.data.length > 0) {
        setSelectedToken(res.data[0].token);
      }
    } else {
      setLoading(false);
      console.log(res);
      console.log('Error get access list');
    }
  };

  console.log(units);

  return (
    <Row>
      <Col span={24}>
        <Row type="flex" align="middle" justify="space-between" style={{ marginBottom: 16 }}>
          <Col style={{ fontWeight: 600, fontSize: 14 }}>Authorized Unit List</Col>

          <Col style={{ zIndex: 100 }}>
            <Button onClick={refetch} loading={isLoading} style={{ marginRight: 16 }}>
              Refresh
            </Button>

            <Select style={{ width: 168 }} value={selectedToken} onChange={e => setSelectedToken(e)}>
              {tokenList.map(e => (
                <Select.Option key={e.token} value={e.token}>
                  {e.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Col>
      <Col>
        <Table
          rowKey={record => record._id}
          dataSource={units}
          columns={constructColumns(tableQuery, selectedToken, refetch, checkIsAdminReadOnly)}
          scroll={{ x: 1000 }}
          onChange={(pagination, filters, sorter) => onTableChange({ pagination, filters, sorter })}
          pagination={{ total }}
          loading={isLoading}
        />
      </Col>
    </Row>
  );
};

Body.propTypes = {
  query: PropTypes.object.isRequired,
  tableQuery: PropTypes.object,
  onTableChange: PropTypes.func.isRequired
};

export default Body;
