import React, { Component, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { Icon } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import BulkImportModal from './component/bulkImportModal';

const UploadExpediaTrx = () => {
  const [loading, setLoading] = useState();
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  return (
    <Row
      type="flex"
      style={{
        width: '100%',
        marginTop: 24
      }}
      justify="center"
    >
      <Col>
        <div>
          <Button type="primary" onClick={() => setUploadModalVisible(true)}>
            <UploadOutlined /> Bulk Import
          </Button>

          <BulkImportModal visible={uploadModalVisible} onCancel={() => setUploadModalVisible(false)} />
        </div>
      </Col>
    </Row>
  );
};

export default UploadExpediaTrx;
