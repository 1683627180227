import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, message, notification, Skeleton } from 'antd';

import { useGetHostById, updateHostPurchasedPlan } from 'utils/apis/host';
import { guard } from 'utils/general';
import { removeNonNumbers } from 'utils/general';

import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

const HostActionModal = ({ form, hostId, onCancel, onOk }) => {
  const { data: host, isLoading: isHostLoading } = useGetHostById(hostId);

  const handleOnUpdate = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) {
        Object.values(err).forEach(field => message.error(field.errors[0].message));
      } else {
        updateHostPurchasedPlan(hostId, values).then(() => {
          notification.success({
            message: 'Success',
            description: "You've successfully updated this host's pre-purchased plans."
          });
          onOk();
        });
      }
    });
  };

  return (
    <Modal title="Pre-purchased Plan" visible okText="Update" onOk={handleOnUpdate} onCancel={onCancel} destroyOnClose>
      {isHostLoading ? (
        <Skeleton />
      ) : (
        <Form>
          <FormInputNumber
            form={form}
            name="entry"
            formLabel="Entry Plans (Y)"
            minValue={0}
            defaultValue={guard(() => host.purchasedPlan.entry, 0)}
            formatter={removeNonNumbers}
            requiredErrorMessage="Please specific the number of entry plan purchased by the host, leave 0 if non"
          />
          <FormInputNumber
            form={form}
            name="starter"
            formLabel="Starter Plans (Y)"
            minValue={0}
            defaultValue={guard(() => host.purchasedPlan.starter, 0)}
            formatter={removeNonNumbers}
            requiredErrorMessage="Please specific the number of starter plan purchased by the host, leave 0 if non"
          />
          <FormInputNumber
            form={form}
            name="standard"
            formLabel="Standard Plans (Y)"
            minValue={0}
            defaultValue={guard(() => host.purchasedPlan.standard, 0)}
            formatter={removeNonNumbers}
            requiredErrorMessage="Please specific the number of standard plan purchased by the host, leave 0 if non"
          />
          <FormInputNumber
            form={form}
            name="hostProtect"
            formLabel="Host Protect"
            minValue={0}
            defaultValue={guard(() => host.purchasedPlan.hostProtect, 0)}
            formatter={removeNonNumbers}
            requiredErrorMessage="Please specific the number of host protect purchased by the host, leave 0 if non"
          />
        </Form>
      )}
    </Modal>
  );
};

HostActionModal.propTypes = {
  hostId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired
};

export default Form.create()(HostActionModal);
