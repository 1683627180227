import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import PayoutDetailCard from './components/PayoutDetailCard/PayoutDetailCard';

import { numberWithCommas } from 'utils/general';

import styles from './PayoutDetails.module.css';

const checkShouldShowEarning = (year, month) => {
  return year < new Date().getFullYear() || (year === new Date().getFullYear() && month < new Date().getMonth());
};

const calculateTotalEarning = (payoutDetails, shouldShowEarning) => {
  if (shouldShowEarning) {
    return payoutDetails.reduce((total, payout) => {
      if (payout.servicePackage.splitBySqft) {
        return total + (payout.perUnitRevenue || 0);
      }
      return total + (payout.nettProfit || 0);
    }, 0);
  } else {
    return 0;
  }
};

const Banner = ({ shouldShowEarning, totalEarning, currency = 'RM' }) => {
  return (
    <Row className={styles.payoutDetailsBanner}>
      <Row className={styles.payoutDetailsBannerTitle}>Total Earnings</Row>
      <Row className={styles.payoutDetailsBannerContent}>
        {shouldShowEarning ? (
          <>
            {currency} {<span className={styles.payoutDetailsBannerAmount}>{numberWithCommas(totalEarning)}</span>}
          </>
        ) : (
          <span className={styles.payoutDetailsBannerNoAmount}>STAY TUNED</span>
        )}
      </Row>
    </Row>
  );
};

const PayoutDetails = ({
  year,
  month,
  integrationSourceConstants,
  packageTypeConstants,
  revenueShareConstants,
  selectedHostImage,
  selectedOwnerName,
  payoutDetails
}) => {
  const shouldShowEarning = checkShouldShowEarning(year, month);
  const totalEarning = calculateTotalEarning(payoutDetails, shouldShowEarning);

  var currency = 'RM';
  if (payoutDetails.length > 0) {
    currency = payoutDetails[0].property.currency;
  }
  return (
    <div>
      <Row>
        <Banner shouldShowEarning={shouldShowEarning} totalEarning={totalEarning} currency={currency} />
      </Row>
      <Row type="flex">
        {payoutDetails.map((payoutDetail, i) => {
          payoutDetail.year = year;
          payoutDetail.month = month;
          payoutDetail.totalUnits = payoutDetails.length;
          payoutDetail.totalPayoutAmount = totalEarning;

          return (
            <Col key={i + '-' + year + '-' + month} xs={24} sm={24} lg={12} xl={8} xxl={6}>
              <PayoutDetailCard
                year={year}
                month={month}
                integrationSourceConstants={integrationSourceConstants}
                packageTypeConstants={packageTypeConstants}
                revenueShareConstants={revenueShareConstants}
                selectedHostImage={selectedHostImage}
                selectedOwnerName={selectedOwnerName}
                payoutDetail={payoutDetail}
                shouldShowEarning={shouldShowEarning}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

PayoutDetails.propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  integrationSourceConstants: PropTypes.array.isRequired,
  packageTypeConstants: PropTypes.array.isRequired,
  revenueShareConstants: PropTypes.array.isRequired,
  selectedHostImage: PropTypes.string.isRequired,
  selectedOwnerName: PropTypes.string.isRequired,
  payoutDetails: PropTypes.array.isRequired
};

export default PayoutDetails;
