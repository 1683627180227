import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import ListingWizardLayout from 'layouts/ListingWizardLayout/ListingWizardLayout';
import UploadPhoto from 'components/UploadPhoto/UploadPhoto';

import styles from './Photos.module.css';

class Photos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: props.defaultValues.photos
    };
  }

  handleOnUploadFinish = e => {
    this.setState(({ photos }) => {
      const uploadedPhoto = {
        link: e.fileUrl,
        caption: `Unit image ${photos.length + 1}`
      };
      return {
        photos: [...photos, uploadedPhoto]
      };
    });
  };

  handleOnCaptionChange = index => caption => {
    this.setState(prevState => {
      const { photos } = prevState;
      photos[index] = {
        ...photos[index],
        caption
      };
      return {
        photos
      };
    });
  };

  handleOnImageDelete = index => e => {
    e.preventDefault();
    this.setState(prevState => {
      const { photos } = prevState;
      photos.splice(index, 1);
      return {
        photos
      };
    });
  };

  handleOnImageMakePrimary = index => e => {
    e.preventDefault();
    this.setState(prevState => {
      const { photos } = prevState;
      photos.unshift(photos[index]); // Move the photo to first element
      photos.splice(index + 1, 1); // Remove the photo
      return {
        photos
      };
    });
  };

  handleOnSubmit = e => {
    e.preventDefault();
    const { onSave } = this.props;
    const { photos } = this.state;
    const value = {
      photos
    };
    onSave(value);
  };

  handleOnBackClick = e => {
    e.preventDefault();
    const { onBack } = this.props;
    const { photos } = this.state;
    const value = { photos };
    onBack(value);
  };

  handleDragPic = updatedPhotos => {
    const { form } = this.props;

    const photos = updatedPhotos;
    form.setFieldsValue({
      photos
    });
  };

  render() {
    const { photos } = this.state;
    const { isLastPage, percentage, title } = this.props;
    return (
      <ListingWizardLayout
        isLastPage={isLastPage}
        percentage={percentage}
        title={title}
        onBackButtonClick={this.handleOnBackClick}
        onNextButtonClick={this.handleOnSubmit}
      >
        <Row type="flex" justify="space-between" className={styles.formTitleContainer}>
          <Col span={24}>
            <h2 className={styles.formTitle}>Upload your unit's photos</h2>
          </Col>
          <Col span={24}>
            <p>Units with photos will attract more guests!</p>
          </Col>
        </Row>
        <UploadPhoto
          containerClassname={styles.formItemContainer}
          photos={photos}
          onCaptionChange={this.handleOnCaptionChange}
          onImageDelete={this.handleOnImageDelete}
          onImageMakePrimary={this.handleOnImageMakePrimary}
          onUploadFinish={this.handleOnUploadFinish}
          onDrag={this.handleDragPic}
        />
      </ListingWizardLayout>
    );
  }
}

Photos.propTypes = {
  onSave: PropTypes.func.isRequired,
  percentage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  isLastPage: PropTypes.bool,
  onBack: PropTypes.func
};

Photos.defaultProps = {
  defaultValues: {},
  isLastPage: false
};

export default Photos;
