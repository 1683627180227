import React, { Component } from 'react';
import { Table, Button, Card, Input, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { getServicePackage } from '../../utils/apis/servicePackage';
import './ServicePackage.css';
import { guard, numberWithCommas } from './../../utils/general';
import { CSVLink } from 'react-csv';
import { withAppContext } from 'context/AppContext';

const moment = require('moment');

class ServicePackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      searchNameText: '',
      searchUnitText: '',
      isLoading: true
    };
  }

  componentWillMount = () => {
    getServicePackage()
      .then(res => {
        if (res && res.status === 200) {
          this.setState({
            data: res.data,
            isLoading: false
          });
        } else {
          console.log('There was an error fetching service package data');
          this.setState({ isLoading: false });
        }
      })
      .catch(ex => {
        console.log(ex);
        this.setState({ isLoading: false });
      });
  };

  handleSearch = (selectedKeys, confirm, name) => () => {
    confirm();
    this.setState({ [name]: selectedKeys[0] });
  };

  handleReset = (clearFilters, name) => () => {
    clearFilters();
    this.setState({ [name]: '' });
  };

  getColumns = checkIsAdminReadOnly => {
    return [
      {
        title: 'Package Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => {
          if (checkIsAdminReadOnly()) {
            return <span className="links">{text}</span>;
          } else {
            return (
              <Link to={`/servicepackage/${record._id}/edit`}>
                <span className="links">{text}</span>
              </Link>
            );
          }
        },

        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.name.localeCompare(b.name),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder="Search package name"
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchNameText"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        }
      },
      {
        title: 'Host',
        dataIndex: 'host',
        key: 'host',
        render: (text, record) => text,
        sorter: (a, b) => a.host.localeCompare(b.host),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder="Search host name"
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchHostNameText"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => {
          if (record.host) return record.host.toLowerCase().includes(value.toLowerCase());
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        }
      },
      {
        title: 'Package Type',
        dataIndex: 'packageType',
        key: 'packageType',
        filters: Array.from(
          new Set(
            this.state.data.map(item => {
              return item.packageType;
            })
          )
        ).map(item => {
          return { text: item, value: item };
        }),
        onFilter: (value, record) => record.packageType === value
      },
      {
        title: 'Revenue Share',
        dataIndex: 'revenueShare',
        key: 'revenueShare',
        filters: Array.from(
          new Set(
            this.state.data.map(item => {
              return item.revenueShare;
            })
          )
        ).map(item => {
          return { text: item, value: item };
        }),
        onFilter: (value, record) => record.revenueShare === value
      },
      {
        title: 'Units',
        dataIndex: 'units',
        key: 'units',
        render: (text, record) => {
          const unitsToRender = record.units.slice(0, 5);
          const firstFiveUnits = unitsToRender.map(unit => {
            return (
              <React.Fragment key={unit.property + unit.code}>
                <span>{`${unit.property} | ${unit.code}`}</span>
                <br />
              </React.Fragment>
            );
          });
          return (
            <React.Fragment>
              {firstFiveUnits}
              {record.units.length > 5 ? (
                <span>
                  ... and {record.units.length - 5} more of {record.units.length} unit(s)
                </span>
              ) : null}
            </React.Fragment>
          );
        },
        sorter: (a, b) => a.name.localeCompare(b.name),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder="Search unit"
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchUnitText"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) =>
          record.units.filter(unit => (unit.property + ' ' + unit.code).toLowerCase().includes(value.toLowerCase())).length > 0,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        }
      },
      {
        title: 'Owner Share',
        dataIndex: 'ownerShare',
        key: 'ownerShare',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.ownerShare - b.ownerShare,
        render: (text, record) => {
          return text ? <span>{text}%</span> : 'N/A';
        }
      },
      {
        title: 'Threshold',
        dataIndex: 'threshold',
        key: 'threshold',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.threshold - b.threshold,
        render: (text, record) => {
          return text ? (
            <span>
              {guard(() => record.currency, 'RM')} {numberWithCommas(text)}
            </span>
          ) : (
            'N/A'
          );
        }
      },
      {
        title: 'Owner Share After',
        dataIndex: 'ownerShareAfterThreshold',
        key: 'ownerShareAfterThreshold',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.ownerShareAfterThreshold - b.ownerShareAfterThreshold,
        render: (text, record) => {
          return text ? <span>{text}%</span> : 'N/A';
        }
      }
    ];
  };

  constructCSV = () => {
    const { data } = this.state;
    return data.map(data => {
      const expensesSettingArray = Object.keys(data.expensesSetting).map(k => k + '-' + data.expensesSetting[k]);

      return {
        'Package Name': data.name,
        'Package Type': data.packageType,
        'Split By Square Feet': data.splitBySqft ? String(data.splitBySqft) : 'false',
        'Revenue Share': data.revenueShare,
        Units: data.units.reduce((accumulator, currentValue) => accumulator + currentValue.property + '|' + currentValue.code + ', ', ''),
        'Owner Share': data.ownerShare,
        Threshold: data.threshold,
        'Owner Share After Threshold': data.ownerShareAfterThreshold,
        'Cleaning Paid By Guest': data.cleaningPaidByGuest ? 'True' : 'False',
        Calculations: undefined,
        'Expenses Settings': expensesSettingArray
      };
    });
  };

  render() {
    const { data, isLoading } = this.state;
    const { checkIsAdminReadOnly } = this.props;
    const curDateTime = moment().format('YYYY_MM_DD_HH_mm_ss');
    const csvName = String('service_package_')
      .concat(curDateTime.toString())
      .concat('.csv');
    return (
      <Card className="sp-list-card">
        <div className="sp-controls">
          <Link to="/servicepackage/new">
            <Button id="create-button7-sp" type="primary" icon="plus" disabled={checkIsAdminReadOnly()}>
              Create Service Package
            </Button>
          </Link>
          {this.props.checkAbleExportServicePackage() && (
            <CSVLink filename={csvName} data={this.constructCSV()} className="sp-csv">
              <Button id="csv-button6-sp" type="primary" icon="download" disabled={checkIsAdminReadOnly()}>
                Download CSV
              </Button>
            </CSVLink>
          )}
        </div>
        <Table columns={this.getColumns(checkIsAdminReadOnly)} dataSource={data} scroll={{ x: 1000 }} loading={isLoading} />
      </Card>
    );
  }
}

export default withRouter(withAppContext(ServicePackage));
