import styled from '@emotion/styled';

export const HeaderContainer = styled.div`
  width: 100%;
`;

export const BodyContainer = styled.div`
  width: 100%;
`;

export const MainContainer = styled.div`
  width: 100%;
  margin: 30px;
`;
