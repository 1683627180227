import React, { Component } from 'react';
import { Chart, Geom, Axis, Tooltip, Legend } from 'bizcharts';
import PropTypes from 'prop-types';
import DataSet from '@antv/data-set';

class LineGraph extends Component {
  static propTypes = {
    height: PropTypes.number,
    graphData: PropTypes.array.isRequired,
    fields: PropTypes.array.isRequired,
    xAxisName: PropTypes.string.isRequired,
    yAxisLabelFormatter: PropTypes.func
  };

  static defaultProps = {
    xAxisName: 'x',
    fields: []
  };
  componentDidMount() {
    // Refer to this issue: https://github.com/alibaba/BizCharts/issues/279#issuecomment-400438180
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    const { height, graphData, fields, xAxisName, yAxisLabelFormatter } = this.props;
    const ds = new DataSet();
    const dv = ds.createView().source(graphData);

    dv.transform({
      type: 'fold',
      fields,
      key: 'key',
      value: 'value'
    });

    return (
      <Chart height={height} data={dv} forceFit>
        <Legend
          textStyle={{
            fontSize: '14'
          }}
        />
        <Axis name={xAxisName} />
        <Axis
          name="value"
          label={{
            formatter: yAxisLabelFormatter
          }}
        />
        <Tooltip
          crosshairs={{
            type: 'y'
          }}
        />
        <Geom type="line" position={`${xAxisName}*value`} size={2} color={'key'} shape={'smooth'} />
        <Geom
          type="point"
          position={`${xAxisName}*value`}
          size={4}
          shape={'circle'}
          color={'key'}
          style={{
            stroke: '#fff',
            lineWidth: 1
          }}
        />
      </Chart>
    );
  }
}

export default LineGraph;
