import React, { Component } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { BlobProvider, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

class GuestRegistrationCard extends Component {
  static propTypes = {
    data: PropTypes.object,
    currency: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.state = {};
  }

  constructDataForGuestRegistrationCardPdf = reservation => {
    const { charges, endDate, guestDetails, payment, startDate, platform, totalPrice, taxes, unit } = reservation;
    const { getPaymentDetails, getGuestNationality } = this.props;
    const paymentDetails = getPaymentDetails(payment);
    return {
      propertyName: unit.roomType.property.name || '',
      roomType: unit.roomType.name || '',
      unitName: unit.name || '',
      checkIn: startDate || '',
      checkOut: endDate || '',
      bookingSource: platform || 'HostPlatform',
      noPax: guestDetails.numberOfPax || 0,
      roomRate: (charges && charges.rental) || 0,
      totalPrice: totalPrice,
      sstTax: (taxes && taxes.sst) || 0,
      firstName: guestDetails.userProfile ? guestDetails.userProfile.firstName : '-',
      lastName: guestDetails.userProfile ? guestDetails.userProfile.lastName : '-',
      guestName: (guestDetails.userProfile && `${guestDetails.userProfile.firstName || '-'} ${guestDetails.userProfile.lastName || ''}`) || '-',
      icNo: guestDetails.userProfile ? guestDetails.userProfile.identificationNo : '',
      email: guestDetails.userProfile ? guestDetails.userProfile.emails : '',
      contactNo: guestDetails.userProfile ? guestDetails.userProfile.contactNos : '-',
      hostEmail: unit.roomType.property.host.email || '-',
      hostContactNo: unit.roomType.property.host.contactNo || '-',
      hostData: unit.roomType.property.host || {},
      nationality: guestDetails.userProfile && getGuestNationality(guestDetails.userProfile.nationality),
      vehicleType: guestDetails
        ? guestDetails.vehicleDetails && guestDetails.vehicleDetails[0]
          ? guestDetails.vehicleDetails[0].vehicleType
          : ''
        : '',
      vehicleModel: guestDetails
        ? guestDetails.vehicleDetails && guestDetails.vehicleDetails[0]
          ? guestDetails.vehicleDetails[0].vehicleModel
          : ''
        : '',
      vehicleNo: guestDetails ? (guestDetails.vehicleDetails && guestDetails.vehicleDetails[0] ? guestDetails.vehicleDetails[0].vehicleNo : '') : '',
      vehicleColor: guestDetails
        ? guestDetails.vehicleDetails && guestDetails.vehicleDetails[0]
          ? guestDetails.vehicleDetails[0].vehicleColor
          : ''
        : '',
      paymentMethod: paymentDetails.methods,
      creditCardNo: paymentDetails.cardNos,
      expiryDate: paymentDetails.expiryDates,
      paymentReceived: payment.total
    };
  };

  handleOnClickPdfBtn = url => {
    window.open(url, '_blank');
  };

  render() {
    const { data, reservation, currency } = this.props;

    const guestData = {
      ...this.constructDataForGuestRegistrationCardPdf(reservation),
      ...data
    };

    const styles = StyleSheet.create({
      page: {
        flexDirection: 'row'
      },
      image: {
        width: 80,
        height: 80
      },
      text: {
        color: '#212121',
        width: '30%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 10,
        paddingTop: 5
      },
      addressText: {
        color: '#212121',
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 10,
        paddingTop: 5
      },
      receiptNumber: {
        width: '10%',
        paddingHorizontal: 50,
        paddingVertical: 30,
        color: 'red'
      },
      flex: {
        flexDirection: 'row',
        width: '100%',
        marginTop: 25,
        fontSize: 10,
        justifyContent: 'space-between'
      },
      row: {
        flexDirection: 'row'
      },
      paddingBtm: {
        paddingBottom: 5
      },
      body: {
        flexDirection: 'column',
        borderBottomWidth: 2,
        borderBottomColor: '#112131',
        borderBottomStyle: 'solid',
        alignItems: 'stretch'
      },
      move: {
        margin: '10px'
      },
      ReceiptNumtext: {
        color: '#212121',
        width: '100%',
        textAlign: 'right',
        fontSize: 10,
        paddingTop: 5,
        paddingRight: 50
      },
      space: {
        paddingTop: 5,
        paddingRight: 20,
        paddingLeft: 20
      },
      line: {
        margin: 'auto',
        width: '85%',
        borderBottomColor: '#112131',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        alignItems: 'stretch'
      },
      amount: {
        paddingLeft: 50
      },
      issuedBy: {
        paddingRight: 50
      },
      groupFields: {
        width: '100%',
        flexDirection: 'row',
        paddingRight: 50,
        paddingLeft: 50,
        paddingBottom: 10,
        margin: 'auto',
        paddingTop: 20,
        alignItems: 'stretch'
      },
      fields: {
        width: '50%',
        textAlign: 'left'
      },
      fontSize: {
        fontSize: 10,
        paddingBottom: 10
      },
      fontSizeSig: {
        fontSize: 10
      },
      guestSig: {
        width: '100%',
        textAlign: 'right',
        paddingRight: 40
      },
      title: {
        textAlign: 'center',
        fontSize: 30,
        paddingTop: 15
      },
      fieldsHostDetails: {
        fontSize: 10,
        width: '80%',
        textAlign: 'left',
        paddingLeft: 10,
        paddingRight: 10,
        whiteSpace: 'normal'
      }
    });
    console.log(194, guestData);
    const address = guestData.hostData.useHostDetails
      ? guestData.hostData.street +
        (guestData.hostData.city ? ', ' + guestData.hostData.city : '') +
        (guestData.hostData.zipCode ? ', ' + guestData.hostData.zipCode : '') +
        (guestData.hostData.state ? ', ' + guestData.hostData.state : '') +
        (guestData.hostData.country ? ', ' + guestData.hostData.country : '')
      : guestData.street +
        (guestData.city ? ', ' + guestData.city : '') +
        (guestData.zipCode ? ', ' + guestData.zipCode : '') +
        (guestData.state ? ', ' + guestData.state : '') +
        (guestData.country ? ', ' + guestData.country : '');
    const roomRate = guestData.roomRate || 0;
    const paymentReceived = guestData.paymentReceived || 0;
    const totalCharges = (guestData.totalPrice || 0) - roomRate - (guestData.sstTax || 0);

    const MyDocument = (
      <Document>
        <Page size="A4" wrap>
          <View>
            <View style={styles.groupFields}>
              <View>
                <Image style={styles.image} src={guestData.image} />
              </View>
              <View style={styles.fieldsHostDetails}>
                <Text style={{ ...styles.paddingBtm, fontSize: 12 }}>
                  {guestData.hostData.useHostDetails ? guestData.hostData.name : guestData.propertyName}
                </Text>
                <View style={styles.row}>
                  <View style={{ width: '20%' }}>
                    <Text style={styles.paddingBtm}>Address</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.paddingBtm}>: </Text>
                  </View>
                  <View style={{ width: '85%' }}>
                    <Text style={{ ...styles.paddingBtm, whiteSpace: 'normal' }}>{address}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '20%' }}>
                    <Text style={styles.paddingBtm}>Email</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.paddingBtm}>: </Text>
                  </View>
                  <View style={{ width: '85%' }}>
                    <Text style={styles.paddingBtm}>{guestData.hostEmail || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '20%' }}>
                    <Text style={styles.paddingBtm}>Contact Number</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.paddingBtm}>: </Text>
                  </View>
                  <View style={{ width: '85%' }}>
                    <Text style={styles.paddingBtm}>{guestData.hostContactNo || '-'}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.title}>
              <Text style={{ fontSize: 20 }}>Guest Registration Card</Text>
            </View>
            <View style={styles.groupFields}>
              <View style={{ width: '55%' }}>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>REGISTRATION NO</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.fontSize}>-</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>FOLIO NO</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.fontSize}>-</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>ROOM NUMBER</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.fontSize}>{guestData.unitName || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>ROOM TYPE</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.fontSize}>{guestData.roomType || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>DESK USER</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.fontSize}>{guestData.createdBy.firstName + ' ' + guestData.createdBy.lastName}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>RESERVATION NO</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.fontSize}>{guestData.confirmationCode}</Text>
                  </View>
                </View>
              </View>
              <View style={{ width: '2%' }} />
              <View style={{ width: '45%' }}>
                <View style={styles.row}>
                  <View style={{ width: '47%' }}>
                    <Text style={styles.fontSize}>CHECK IN DATE</Text>
                  </View>
                  <View style={{ width: '3%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '48%' }}>
                    <Text style={styles.fontSize}>{guestData.checkIn || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '47%' }}>
                    <Text style={styles.fontSize}>CHECK OUT DATE</Text>
                  </View>
                  <View style={{ width: '3%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '48%' }}>
                    <Text style={styles.fontSize}>{guestData.checkOut || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '47%' }}>
                    <Text style={styles.fontSize}>NO OF PAX</Text>
                  </View>
                  <View style={{ width: '3%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '48%' }}>
                    <Text style={styles.fontSize}>{guestData.noPax || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '47%' }}>
                    <Text style={styles.fontSize}>BOOKING SOURCE</Text>
                  </View>
                  <View style={{ width: '3%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '48%' }}>
                    <Text style={{ ...styles.fontSize, textTransform: 'capitalize' }}>{guestData.bookingSource || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '47%' }}>
                    <Text style={styles.fontSize}>VOUCHER NO</Text>
                  </View>
                  <View style={{ width: '3%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '48%' }}>
                    <Text style={styles.fontSize}>-</Text>
                  </View>
                </View>
              </View>
            </View>
            <Text style={styles.line} />
            <View style={styles.groupFields}>
              <View style={{ width: '55%' }}>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>GUEST NAME</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.fontSize}>{guestData.guestName || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>EMAIL</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.fontSize}>{guestData.email[0] || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>PHONE NUMBER</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.fontSize}>{guestData.contactNo[0] || '-'}</Text>
                  </View>
                </View>
              </View>
              <View style={{ width: '2%' }} />
              <View style={{ width: '45%' }}>
                <View style={styles.row}>
                  <View style={{ width: '47%' }}>
                    <Text style={styles.fontSize}>NATIONALITY</Text>
                  </View>
                  <View style={{ width: '3%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '48%' }}>
                    <Text style={styles.fontSize}>{guestData.nationality || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '47%' }}>
                    <Text style={styles.fontSize}>IC/ PASSPORT</Text>
                  </View>
                  <View style={{ width: '3%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '48%' }}>
                    <Text style={styles.fontSize}>{guestData.icNo || '-'}</Text>
                  </View>
                </View>
              </View>
            </View>
            <Text style={styles.line} />
            <View style={styles.groupFields}>
              <View style={{ width: '55%' }}>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>PAYMENT MODE</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={{ ...styles.fontSize, textTransform: 'capitalize' }}>{guestData.paymentMethod || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>CREDIT CARD INFO</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.fontSize}>{guestData.creditCardNo || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>EXP DATE</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.fontSize}>{guestData.expiryDate || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>SST</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.fontSize}>{(guestData.sstTax && `${currency ? currency : 'RM'} ${guestData.sstTax.toFixed(2)}`) || '-'}</Text>
                  </View>
                </View>
              </View>
              <View style={{ width: '2%' }} />
              <View style={{ width: '45%' }}>
                <View style={styles.row}>
                  <View style={{ width: '47%' }}>
                    <Text style={styles.fontSize}>RATE TYPE</Text>
                  </View>
                  <View style={{ width: '3%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '48%' }}>
                    <Text style={styles.fontSize}>{guestData.roomType || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '47%' }}>
                    <Text style={styles.fontSize}>TOTAL ROOM RATE</Text>
                  </View>
                  <View style={{ width: '3%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '48%' }}>
                    <Text style={styles.fontSize}>
                      {currency ? currency : 'RM'} {roomRate.toFixed(2)}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '47%' }}>
                    <Text style={styles.fontSize}>TOTAL CHARGE</Text>
                  </View>
                  <View style={{ width: '3%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '48%' }}>
                    <Text style={styles.fontSize}>
                      {currency ? currency : 'RM'} {totalCharges.toFixed(2)}
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '47%' }}>
                    <Text style={styles.fontSize}>TOTAL PAYMENT</Text>
                  </View>
                  <View style={{ width: '3%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '48%' }}>
                    <Text style={styles.fontSize}>
                      {currency ? currency : 'RM'} {paymentReceived.toFixed(2)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <Text style={styles.line} />
            <View style={styles.groupFields}>
              <View style={{ width: '55%' }}>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>VEHICLE PLATE NO</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.fontSize}>{guestData.vehicleNo || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>VEHICLE MODEL</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.fontSize}>{guestData.vehicleModel || '-'}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={{ width: '38%' }}>
                    <Text style={styles.fontSize}>VEHICLE COLOUR</Text>
                  </View>
                  <View style={{ width: '2%' }}>
                    <Text style={styles.fontSize}>: </Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={styles.fontSize}>{guestData.vehicleColor || '-'}</Text>
                  </View>
                </View>
              </View>
            </View>
            <Text style={styles.line} />
            <View
              style={{
                paddingTop: 20,
                paddingRight: 20,
                paddingLeft: 20
              }}
            >
              <View style={styles.space}>
                <Text style={styles.fontSize}>{guestData.finePrint}</Text>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                marginTop: '24px'
              }}
            >
              <View style={styles.guestSig}>
                <Text style={styles.fontSizeSig}>___________________________</Text>
                <Text style={{ fontSize: 10, paddingRight: 35 }}>Guest Signature</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
    return (
      <BlobProvider document={MyDocument} fileName="Guest_Registration-Card.pdf">
        {({ blob, url, loading, error }) => (
          <Button type="primary" loading={loading} className="modal-receipts-btn" onClick={() => this.handleOnClickPdfBtn(url)}>
            {loading ? 'Loading' : 'Guest Registration Card'}
          </Button>
        )}
      </BlobProvider>
    );
  }
}

export default GuestRegistrationCard;
