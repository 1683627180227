import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Form, Row, Col, DatePicker, Button } from 'antd';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormSelection from 'components/FormSelection/FormSelection';

const FormItem = Form.Item;

class TaxesTabPane extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    taxes: PropTypes.array.isRequired,
    handleOnTaxSubmit: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired
  };

  static defaultProps = {
    form: {},
    taxes: [],
    currency: 'RM',
    handleOnTaxSubmit: () => {}
  };

  render() {
    const { form, taxes, handleOnTaxSubmit, currency, checkIsAdminReadOnly } = this.props;

    return (
      <Form onSubmit={event => handleOnTaxSubmit(event, form)} style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={24}>
            <p style={{ marginBottom: '8px' }}>Date</p>
            <FormItem style={{ marginBottom: 0 }}>
              {form.getFieldDecorator('taxDate', {
                rules: [
                  {
                    required: true,
                    message: 'Please select a date!'
                  }
                ],
                initialValue: moment(new Date(), 'YYYY-MM-DD')
              })(<DatePicker name="chargesDate" size="large" style={{ width: '100%', marginBottom: '16px' }} format={'YYYY-MM-DD'} />)}
            </FormItem>
          </Col>
          <Col span={24} md={18}>
            <FormSelection
              label="Tax Type"
              name="taxType"
              requiredErrorMessage="Please select your tax type"
              placeholder="Choose type of taxes"
              form={form}
              selections={taxes}
              size="large"
            />
          </Col>
          <Col span={24} md={6}>
            <FormInputNumber
              form={form}
              name="amount"
              precision={2}
              minValue={1}
              label={`Amount (${currency})`}
              placeholder="Amount to be taxed"
              size="large"
            />
          </Col>
        </Row>
        <Row>
          <FormInput
            inputType="textarea"
            label="Remarks (optional)"
            name="remarks"
            placeholder="Provide your remark if you have any"
            form={form}
            extraProps={{ rows: 2 }}
            size="large"
          />
        </Row>
        <Row type="flex" justify="center" gutter={16}>
          <Button type="primary" htmlType="submit" size="large" style={{ width: '70%' }} disabled={checkIsAdminReadOnly()}>
            Create Tax Charges
          </Button>
        </Row>
      </Form>
    );
  }
}

export default Form.create()(TaxesTabPane);
