import React from 'react';

import { BasicSelect } from 'components/FormSelection/FormSelection';

import { getIntegrationTypesConstant } from 'utils/general';

import styles from './Filter.module.css';

const { LISTING_TYPE } = getIntegrationTypesConstant();

const Filter = ({ type, propertyOptions, unitOptions, selectedPropertyId, selectedUnitId, onChangeProperty, onChangeUnit }) => {
  const isListingIntegration = type === LISTING_TYPE.code;

  return (
    <div className={styles.selectionRow}>
      <BasicSelect
        label="Property"
        selections={propertyOptions}
        value={selectedPropertyId}
        placeholder="There is no property available"
        className={isListingIntegration ? styles.selectionListing : styles.selectionHotel}
        selectClassName={styles.selectionBody}
        onChange={onChangeProperty}
      />
      {isListingIntegration && (
        <BasicSelect
          label="Unit"
          selections={unitOptions}
          value={selectedUnitId}
          placeholder="This property has no unit"
          className={styles.selectionListing}
          selectClassName={styles.selectionBody}
          onChange={onChangeUnit}
        />
      )}
    </div>
  );
};

export default Filter;
