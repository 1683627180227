import React, { Fragment } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { notification } from 'antd';
import uuid from 'uuid/v1';
import queryString from 'query-string';

import { withAppContext } from 'context/AppContext';
import {
  buildListingWizardUri,
  buildLWCreateUnitUri,
  buildLWCreateUnitBasicUri,
  buildLWCreateUnitDescriptionUri,
  buildLWCreateUnitCostUri,
  buildLWCreateUnitPhotosUri,
  buildLWCreateUnitContractUri,
  buildLWCreateUnitFinishUri
} from 'utils/routes';
import ProtectedRoute from 'utils/ProtectedRoute';
import { getOwners } from 'utils/apis/host';

import BasicDetails from './BasicDetails/BasicDetails';
import ContractInformation from './ContractInformation/ContractInformation';
import Description from './Description/Description';
import OperationCost from './OperationCost/OperationCost';
import Photos from './Photos/Photos';
import Finish from './Finish/Finish';

const generateUnitCode = name => {
  return `${name.substring(0, 2).toUpperCase()}-U00${Math.floor(Math.random() * (9 - 1 + 1) + 1)}-${uuid()}`;
};

class CreateUnit extends React.Component {
  constructor() {
    super();
    this.state = {
      owners: [],
      hasFetchedOwners: false,

      payload: {},
      currency: 'RM',
      timezone: ''
    };
  }

  componentDidMount() {
    const { history, location, currency, timezone } = this.props;

    if (currency) {
      this.setState({ currency: currency });
    } else {
      const query = queryString.parse(location.search);
      if (query.currency) {
        this.setState({ currency: query.currency });
      }
    }

    if (timezone) {
      this.setState({ timezone: timezone });
    } else {
      const query = queryString.parse(location.search);
      if (query.timezone) {
        this.setState({ timezone: query.timezone });
      }
    }

    if (!this.checkHasRequiredQuery(location.search)) {
      history.push(buildListingWizardUri());
      return;
    }

    if (location.pathname !== buildLWCreateUnitUri()) {
      if (location.pathname !== buildLWCreateUnitBasicUri()) {
        history.push(buildLWCreateUnitUri(location.search));
      }
    }
    this.fetchOwners();
  }

  checkHasRequiredQuery = searchQuery => {
    let hasRequiredQuery = true;
    if (searchQuery) {
      const query = queryString.parse(searchQuery);
      if (!query.roomTypeId || !query.hostId) {
        hasRequiredQuery = false;
      }
    } else {
      hasRequiredQuery = false;
    }
    return hasRequiredQuery;
  };

  fetchOwners = () => {
    const { location } = this.props;
    const hostId = queryString.parse(location.search).hostId;
    if (hostId) {
      getOwners(hostId)
        .then(res => {
          this.setOwnersState(res);
        })
        .catch(err => console.log(err));
    }
  };

  setOwnersState = owners => {
    this.setState({
      owners,
      hasFetchedOwners: true
    });
  };

  formatBasicDetailsData = data => ({
    name: data.name,
    maxFreeStay: data.maxFreeStay
  });

  formatDescriptionData = data => ({
    detailedDescription: {
      summary: data.summary,
      space: data.aboutYourPlace,
      neighborhoodOverview: data.aboutYourNeighbourhood,
      transit: data.transit,
      access: data.access,
      notes: data.notes,
      houseRules: data.houseRules,
      interaction: data.interaction
    }
  });

  formatOperationCostData = data => {
    return {
      operationCost: {
        checkIn:
          data.checkInPerCheckOut || data.checkInPerDay || data.checkInPerMonth
            ? {
                perCheckOut: data.checkInPerCheckOut ? parseFloat(data.checkInPerCheckOut) : undefined,
                perDay: data.checkInPerDay ? parseFloat(data.checkInPerDay) : undefined,
                perMonth: data.checkInPerMonth ? parseFloat(data.checkInPerMonth) : undefined,
                perMonthUpdatedAt: data.checkInPerMonth ? new Date() : undefined
              }
            : undefined,
        checkOut:
          data.checkOutPerCheckOut || data.checkOutPerDay || data.checkOutPerMonth
            ? {
                perCheckOut: data.checkOutPerCheckOut ? parseFloat(data.checkOutPerCheckOut) : undefined,
                perDay: data.checkOutPerDay ? parseFloat(data.checkOutPerDay) : undefined,
                perMonth: data.checkOutPerMonth ? parseFloat(data.checkOutPerMonth) : undefined,
                perMonthUpdatedAt: data.checkOutPerMonth ? new Date() : undefined
              }
            : undefined,
        cleaning:
          data.cleaningPerCheckOut || data.cleaningPerDay || data.cleaningPerMonth
            ? {
                perCheckOut: data.cleaningPerCheckOut ? parseFloat(data.cleaningPerCheckOut) : undefined,
                perDay: data.cleaningPerDay ? parseFloat(data.cleaningPerDay) : undefined,
                perMonth: data.cleaningPerMonth ? parseFloat(data.cleaningPerMonth) : undefined,
                perMonthUpdatedAt: data.cleaningPerMonth ? new Date() : undefined
              }
            : undefined,
        electricity:
          data.electricityPerCheckOut || data.electricityPerDay || data.electricityPerMonth
            ? {
                perCheckOut: data.electricityPerCheckOut ? parseFloat(data.electricityPerCheckOut) : undefined,
                perDay: data.electricityPerDay ? parseFloat(data.electricityPerDay) : undefined,
                perMonth: data.electricityPerMonth ? parseFloat(data.electricityPerMonth) : undefined,
                perMonthUpdatedAt: data.electricityPerMonth ? new Date() : undefined
              }
            : undefined,
        internet:
          data.internetPerCheckOut || data.internetPerDay || data.internetPerMonth
            ? {
                perCheckOut: data.internetPerCheckOut ? parseFloat(data.internetPerCheckOut) : undefined,
                perDay: data.internetPerDay ? parseFloat(data.internetPerDay) : undefined,
                perMonth: data.internetPerMonth ? parseFloat(data.internetPerMonth) : undefined,
                perMonthUpdatedAt: data.internetPerMonth ? new Date() : undefined
              }
            : undefined,
        laundry:
          data.laundryPerCheckOut || data.laundryPerDay || data.laundryPerMonth
            ? {
                perCheckOut: data.laundryPerCheckOut ? parseFloat(data.laundryPerCheckOut) : undefined,
                perDay: data.laundryPerDay ? parseFloat(data.laundryPerDay) : undefined,
                perMonth: data.laundryPerMonth ? parseFloat(data.laundryPerMonth) : undefined,
                perMonthUpdatedAt: data.laundryPerMonth ? new Date() : undefined
              }
            : undefined,
        service:
          data.servicePerCheckOut || data.servicePerDay || data.servicePerMonth
            ? {
                perCheckOut: data.servicePerCheckOut ? parseFloat(data.servicePerCheckOut) : undefined,
                perDay: data.servicePerDay ? parseFloat(data.servicePerDay) : undefined,
                perMonth: data.servicePerMonth ? parseFloat(data.servicePerMonth) : undefined,
                perMonthUpdatedAt: data.servicePerMonth ? new Date() : undefined
              }
            : undefined,
        water:
          data.waterPerCheckOut || data.waterPerDay || data.waterPerMonth
            ? {
                perCheckOut: data.waterPerCheckOut ? parseFloat(data.waterPerCheckOut) : undefined,
                perDay: data.waterPerDay ? parseFloat(data.waterPerDay) : undefined,
                perMonth: data.waterPerMonth ? parseFloat(data.waterPerMonth) : undefined,
                perMonthUpdatedAt: data.waterPerMonth ? new Date() : undefined
              }
            : undefined
      }
    };
  };

  formatPhotosData = data => ({
    images: data.photos.map(data => ({
      imageUrl: data.link,
      caption: data.caption ? [{ text: data.caption }] : undefined
    }))
  });

  formatContractInformationData = data => ({
    ownedBy: data.selectedOwner,
    contractPeriod: data.contractPeriod
  });

  extractBasicDetailsData = () => {
    const { payload } = this.state;

    return {
      name: payload.name,
      maxFreeStay: payload.maxFreeStay
    };
  };

  extractDescriptionData = () => {
    const { payload } = this.state;
    const detailDesc = payload.detailedDescription || {};

    return {
      summary: detailDesc.summary,
      aboutYourPlace: detailDesc.space,
      aboutYourNeighbourhood: detailDesc.neighborhoodOverview,
      transit: detailDesc.transit,
      access: detailDesc.access,
      notes: detailDesc.notes,
      houseRules: detailDesc.houseRules,
      interaction: detailDesc.interaction
    };
  };

  extractOperationCostData = () => {
    const { payload } = this.state;
    return payload.operationCost;
  };

  extractPhotosData = () => {
    const { payload } = this.state;
    return {
      photos:
        payload.images && payload.images.length > 0
          ? payload.images.map(image => ({
              link: image.imageUrl,
              caption: image.caption && image.caption[0] && image.caption[0].text ? image.caption[0].text : ''
            }))
          : []
    };
  };

  extractContractInformationData = () => {
    const { payload } = this.state;
    return {
      selectedOwner: payload.owner,
      contractPeriod: payload.contractPeriod
    };
  };

  handleOnSave = (url, formatDataFunc = data => data) => (formData = {}) => {
    const { history } = this.props;
    const formattedData = formatDataFunc(formData);
    this.setState(prevState => ({
      payload: { ...prevState.payload, ...formattedData }
    }));

    history.push(url);
  };

  handleOnSaveAndSend = (formData = {}) => {
    const { location } = this.props;
    const query = queryString.parse(location.search);
    const formattedData = this.formatContractInformationData(formData);
    this.setState({
      isCreatingUnit: true
    });

    this.setState((prevState, props) => {
      const newPayload = {
        ...prevState.payload,
        ...formattedData,
        roomType: query.roomTypeId,
        code: generateUnitCode(prevState.payload.name)
      };
      props
        .onFinish(newPayload)
        .then(() => {
          this.setState({
            isCreatingUnit: false
          });
          props.history.push(buildLWCreateUnitFinishUri());
        })
        .catch(e => {
          this.setState({
            isCreatingUnit: false
          });
          notification.error({
            message: 'Something went wrong and your unit is not created.'
            // description: e.message
          });
        });
      return {
        payload: newPayload
      };
    });
  };

  render() {
    const { title, location } = this.props;
    const { hasFetchedOwners, owners, isCreatingUnit } = this.state;
    return (
      <Fragment>
        <Switch>
          <ProtectedRoute
            path={buildLWCreateUnitUri()}
            render={props => (
              <BasicDetails
                {...props}
                percentage={0}
                title={title}
                onSave={this.handleOnSave(buildLWCreateUnitDescriptionUri(location.search), this.formatBasicDetailsData)}
                defaultValues={this.extractBasicDetailsData()}
              />
            )}
            exact
          />
          <ProtectedRoute
            path={buildLWCreateUnitBasicUri()}
            render={props => (
              <BasicDetails
                {...props}
                percentage={0}
                title={title}
                onSave={this.handleOnSave(buildLWCreateUnitDescriptionUri(location.search), this.formatBasicDetailsData)}
                defaultValues={this.extractBasicDetailsData()}
              />
            )}
          />
          <ProtectedRoute
            path={buildLWCreateUnitDescriptionUri()}
            render={props => (
              <Description
                {...props}
                percentage={20}
                title={title}
                onSave={this.handleOnSave(buildLWCreateUnitCostUri(location.search), this.formatDescriptionData)}
                onBack={this.handleOnSave(buildLWCreateUnitBasicUri(location.search), this.formatDescriptionData)}
                defaultValues={this.extractDescriptionData()}
              />
            )}
          />
          <ProtectedRoute
            path={buildLWCreateUnitCostUri()}
            render={props => (
              <OperationCost
                {...props}
                percentage={40}
                title={title}
                onSave={this.handleOnSave(buildLWCreateUnitPhotosUri(location.search), this.formatOperationCostData)}
                onBack={this.handleOnSave(buildLWCreateUnitDescriptionUri(location.search), this.formatOperationCostData)}
                defaultValues={this.extractOperationCostData()}
                currency={this.state.currency}
                timezone={this.state.timezone}
              />
            )}
          />
          <ProtectedRoute
            path={buildLWCreateUnitPhotosUri()}
            render={props => (
              <Photos
                {...props}
                percentage={60}
                title={title}
                onSave={this.handleOnSave(buildLWCreateUnitContractUri(location.search), this.formatPhotosData)}
                onBack={this.handleOnSave(buildLWCreateUnitCostUri(location.search), this.formatPhotosData)}
                defaultValues={this.extractPhotosData()}
              />
            )}
          />
          <ProtectedRoute
            path={buildLWCreateUnitContractUri()}
            render={props => (
              <ContractInformation
                {...props}
                isLastPage
                percentage={80}
                title={title}
                onSave={this.handleOnSaveAndSend}
                onBack={this.handleOnSave(buildLWCreateUnitPhotosUri(location.search), this.formatContractInformationData)}
                owners={owners}
                hasFetchedOwners={hasFetchedOwners}
                defaultValues={this.extractContractInformationData()}
                isLoading={isCreatingUnit}
              />
            )}
          />
          <ProtectedRoute path={buildLWCreateUnitFinishUri()} component={Finish} />
          <Redirect from="*" to="/404NotFound" />
        </Switch>
      </Fragment>
    );
  }
}

export default withAppContext(CreateUnit);
