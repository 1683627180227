import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import { buildListingUri } from 'utils/routes';

import styles from './Finish.module.css';

const Finish = () => (
  <div className={styles.container}>
    <Icon type="check-circle" style={{ fontSize: '56px', color: '#1F883C' }} />
    <h2 className={styles.title}>Well done!</h2>
    <h3 className={styles.subtitle}>You've successfully created your unit.</h3>
    <Link to={buildListingUri()} className={styles.link}>
      Go to unit listings page
    </Link>
  </div>
);

export default Finish;
