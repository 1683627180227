import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Form, Button, Alert } from 'antd';
import intl from 'react-intl-universal';
import './ResetPasswordForm.css';
import { resetPassword } from '../../utils/apis/authentication';

import FormPassword from 'components/FormPassword/FormPassword';

const FormItem = Form.Item;

class ResetPasswordForm extends Component {
  static propTypes = {
    token: PropTypes.string,
    successCall: PropTypes.func
  };

  constructor() {
    super();
    this.state = {
      password: '',
      confirmPassword: '',
      buttonDisabled: '',
      loading: false,
      resetStatus: ''
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      resetStatus: '',
      loading: false
    });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
          password: values.password,
          confirmPassword: values.confirmPassword
        });
        const resetToken = this.props.token;
        resetPassword(resetToken, {
          password: values.password
        })
          .then(res => {
            if (res.status === 200) {
              this.setState({
                loading: false,
                buttonDisabled: 'disabled',
                resetStatus: 'resetSuccess'
              });
              setTimeout(this.props.successCall, 1500);
            } else {
              this.setState({
                loading: false,
                resetStatus: 'resetFail'
              });
            }
          })
          .catch(error => {});
      }
    });
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormPassword form={this.props.form} isResetPassword />
        <FormItem>
          <Button type="primary" htmlType="submit" className="reset-form-button" loading={this.state.loading} disabled={this.state.buttonDisabled}>
            {intl.get('forms.labels.resetPassword').d('Reset')}
          </Button>
        </FormItem>
        <FormItem>
          {(this.state.resetStatus === 'resetSuccess' && (
            <Alert
              message={intl.get('forms.success.resetEmail').d('Password changed successfully. Please login with your new password.')}
              type="success"
              showIcon
            />
          )) ||
            (this.state.resetStatus === 'resetFail' && (
              <Alert message={intl.get('forms.errors.resetFail').d('Link has expired! Please request again.')} type="error" showIcon />
            ))}
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(ResetPasswordForm);
