import styled from '@emotion/styled';
import { Card } from 'antd';

export const StyledCard = styled(Card)`
  margin: 30px;
  min-width: 600px;
`;

export const StyledHeader = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
