import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'antd';

import ListingWizardLayout from 'layouts/ListingWizardLayout/ListingWizardLayout';
import FormInput from 'components/FormInput/FormInput';
import { capitalizeFirstLetter, constructTwoDecimalsRule } from 'utils/general';

import styles from './OperationCost.module.css';

const CostInput = ({ form, defaultValues, title, prefix = 'RM' }) => (
  <Col span={24} md={11} className={`${styles.formItem} ${styles.costContainer}`}>
    <h3 className={styles.costInputTitle}>{title}</h3>
    <Row>
      <FormInput
        name={generateInputName(title, 'PerDay')}
        placeholder="0.00"
        form={form}
        defaultValue={defaultValues.perDay ? parseFloat(defaultValues.perDay).toFixed(2) : defaultValues.perDay}
        extraProps={{
          addonBefore: prefix,
          addonAfter: '/day'
        }}
        extraRules={[constructTwoDecimalsRule()]}
        size="large"
      />
      <FormInput
        name={generateInputName(title, 'PerMonth')}
        placeholder="0.00"
        form={form}
        defaultValue={defaultValues.perMonth ? parseFloat(defaultValues.perMonth).toFixed(2) : defaultValues.perMonth}
        extraProps={{
          addonBefore: prefix,
          addonAfter: '/month'
        }}
        size="large"
      />
      <FormInput
        name={generateInputName(title, 'PerCheckOut')}
        placeholder="0.00"
        form={form}
        defaultValue={defaultValues.perCheckOut ? parseFloat(defaultValues.perCheckOut).toFixed(2) : defaultValues.perCheckOut}
        extraProps={{
          addonBefore: prefix,
          addonAfter: '/checkout'
        }}
        size="large"
      />
    </Row>
  </Col>
);

const generateInputName = (title, inputLabel) => {
  let newTitle = String(title).toLowerCase();
  const separatedStr = newTitle.split('-');
  if (separatedStr.length > 0) {
    let newTitleWithDashed = '';
    separatedStr.forEach((str, index) => {
      if (index === 0) {
        newTitleWithDashed = str;
      } else {
        newTitleWithDashed = newTitleWithDashed + `${capitalizeFirstLetter(str)}`;
      }
    });
    return String(`${newTitleWithDashed}${inputLabel}`).replace(' fee', '');
  }
  return String(`${newTitle}${inputLabel}`).replace(' fee', '');
};

class OperationCost extends React.Component {
  handleOnSubmit = e => {
    e.preventDefault();
    const { form, onSave } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSave(values);
      }
    });
  };

  handleOnBackClick = e => {
    e.preventDefault();
    const { form, onBack } = this.props;
    const allFieldsValue = form.getFieldsValue();
    onBack(allFieldsValue);
  };

  render() {
    const { defaultValues, form, isLastPage, percentage, title } = this.props;
    return (
      <ListingWizardLayout
        isLastPage={isLastPage}
        percentage={percentage}
        title={title}
        onBackButtonClick={this.handleOnBackClick}
        onNextButtonClick={this.handleOnSubmit}
      >
        <Row type="flex" justify="space-between" className={styles.formTitleContainer}>
          <Col span={24}>
            <h2 className={styles.formTitle}>Tell us your operation cost</h2>
          </Col>
          <Col span={24}>
            <p>This will help us calculate your profits!</p>
          </Col>
        </Row>
        <Row type="flex" justify="space-between" className={styles.costMainContainer}>
          <CostInput form={form} defaultValues={defaultValues.water || {}} title="Water" prefix={this.props.currency} />
          <CostInput form={form} defaultValues={defaultValues.electricity || {}} title="Electricity" prefix={this.props.currency} />
          <CostInput form={form} defaultValues={defaultValues.internet || {}} title="Internet" prefix={this.props.currency} />
          <CostInput form={form} defaultValues={defaultValues.cleaning || {}} title="Cleaning fee" prefix={this.props.currency} />
          <CostInput form={form} defaultValues={defaultValues.laundry || {}} title="Laundry fee" prefix={this.props.currency} />
          <CostInput form={form} defaultValues={defaultValues.service || {}} title="Service fee" prefix={this.props.currency} />
          <CostInput form={form} defaultValues={defaultValues.checkIn || {}} title="Check-in fee" prefix={this.props.currency} />
          <CostInput form={form} defaultValues={defaultValues.checkOut || {}} title="Check-out fee" prefix={this.props.currency} />
        </Row>
      </ListingWizardLayout>
    );
  }
}

OperationCost.propTypes = {
  form: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  percentage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  isLastPage: PropTypes.bool,
  onBack: PropTypes.func
};

OperationCost.defaultProps = {
  defaultValues: {},
  isLastPage: false
};

const WrappedFormOperationCost = Form.create()(OperationCost);

export default WrappedFormOperationCost;
