import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Skeleton, Layout } from 'antd';

import { withAppContext } from 'context/AppContext';
import { getUserProfileById } from 'utils/apis/user';

import { DEFAULT_USER_PROFILE_URL } from 'utils/constants';
import { guard } from 'utils/general';
import { buildOverviewUri } from 'utils/routes';

import Header from './Header/Header';
import Navbar from './Navbar/Navbar';
import HomeLogo from '../../components/HomeLogo/HomeLogo';

import styles from './StandardLayout.module.css';

const useCheckIsAdmin = (history, checkIsAdmin) => {
  useEffect(() => {
    if (!checkIsAdmin()) {
      history.push(buildOverviewUri());
    }
  }, [history, checkIsAdmin]);
};

const useFetchUserProfileAvatar = userId => {
  const [isFetchingUserProfileAvatar, setIsFetchingUserProfileAvatar] = useState(true);
  const [userProfileAvatar, setUserProfileAvatar] = useState(DEFAULT_USER_PROFILE_URL);

  const fetchUserProfileAvatar = useCallback(() => {
    setIsFetchingUserProfileAvatar(true);
    getUserProfileById(userId)
      .then(res => {
        const userProfileAvatar = guard(() => res.data.userProfile.displayPicture.imageUrl, DEFAULT_USER_PROFILE_URL);
        setUserProfileAvatar(userProfileAvatar);
      })
      .finally(() => {
        setIsFetchingUserProfileAvatar(false);
      });
  }, [userId]);

  useEffect(() => {
    fetchUserProfileAvatar();
  }, [fetchUserProfileAvatar]);

  return { isFetchingUserProfileAvatar, userProfileAvatar };
};

const StandardLayout = ({ history, user, checkIsAdmin, children }) => {
  useCheckIsAdmin(history, checkIsAdmin);
  const { isFetchingUserProfileAvatar, userProfileAvatar } = useFetchUserProfileAvatar(user._id);

  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      {isFetchingUserProfileAvatar ? (
        <Skeleton active />
      ) : (
        <Layout>
          <Layout.Sider breakpoint="lg" collapsedWidth="0" collapsible collapsed={isCollapsed} onCollapse={setIsCollapsed}>
            <HomeLogo isCollapsed={isCollapsed} />
            <Navbar />
          </Layout.Sider>
          <Layout>
            <Layout.Header>
              <Header history={history} userId={user._id} firstName={user.firstName} userProfileAvatar={userProfileAvatar} />
            </Layout.Header>
            <Layout.Content className={styles.content}>{children}</Layout.Content>
          </Layout>
        </Layout>
      )}
    </>
  );
};

export default withRouter(withAppContext(StandardLayout));
