import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'antd';

import styles from './FormCheckbox.module.css';

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;

const addRules = requiredErrorMessage => {
  const rules = [];
  if (requiredErrorMessage && typeof requiredErrorMessage === 'string') {
    rules.push({
      required: true,
      message: requiredErrorMessage
    });
  }
  return rules;
};

const FormCheckbox = ({ defaultValue, form, formLabel, label, onChange, name, requiredErrorMessage, size, selections, shouldDisable }) => {
  const rules = addRules(requiredErrorMessage);
  return (
    <Fragment>
      {!formLabel && label && <p className={styles.label}>{label}</p>}
      <FormItem label={formLabel} colon={false}>
        {form.getFieldDecorator(name, {
          rules: rules,
          initialValue: defaultValue
        })(<CheckboxGroup options={selections} onChange={onChange} size={size} disabled={shouldDisable} />)}
      </FormItem>
    </Fragment>
  );
};

FormCheckbox.propTypes = {
  form: PropTypes.object.isRequired,
  formLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  selections: PropTypes.array.isRequired,
  defaultValue: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  requiredErrorMessage: PropTypes.string,
  shouldDisable: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'large'])
};

FormCheckbox.defaultProps = {
  form: {},
  label: '',
  placeholder: '',
  shouldDisable: false,
  requiredErrorMessage: '',
  size: 'default'
};

export default FormCheckbox;
