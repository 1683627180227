import React, { useState } from 'react';
import { Card } from 'antd';
import { withAppContext } from 'context/AppContext';

import Header from './components/Header/Header';
import Body from './components/Body/Body';

import { handleOnAggregationTableChange } from 'utils/table/table';

import styles from './Listing.module.css';

const Listing = props => {
  const [query, setQuery] = useState({});
  const [tableQuery, setTableQuery] = useState({});

  const handleOnClickClearAll = () => {
    const newQuery = {
      pagination: { current: 1, pageSize: 10 },
      filters: {},
      sorter: {}
    };
    handleOnTableChange(newQuery);
  };

  const handleOnTableChange = newQuery => {
    setTableQuery(newQuery);
    handleOnAggregationTableChange(newQuery, setQuery);
  };

  return (
    <Card className={styles.card}>
      <div className={styles.header}>
        <Header onClickClearAll={handleOnClickClearAll} checkIsAdminReadOnly={props.checkIsAdminReadOnly} />
      </div>
      <div>
        <Body query={query} tableQuery={tableQuery} onTableChange={handleOnTableChange} checkIsAdminReadOnly={props.checkIsAdminReadOnly} />
      </div>
    </Card>
  );
};

export default withAppContext(Listing);
