import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'antd';

import ListingWizardLayout from 'layouts/ListingWizardLayout/ListingWizardLayout';
import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';

import styles from './BasicDetails.module.css';

class BasicDetails extends React.Component {
  handleOnSubmit = e => {
    e.preventDefault();
    const { form, onSave } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSave(values);
      }
    });
  };

  render() {
    const { defaultValues, form, hasFetchedPropertyTypes, isLastPage, onBack, percentage, propertyTypes, title } = this.props;

    return (
      <ListingWizardLayout
        isLastPage={isLastPage}
        percentage={percentage}
        title={title}
        onBackButtonClick={onBack}
        onNextButtonClick={this.handleOnSubmit}
      >
        <Row type="flex" justify="space-between" className={styles.formTitleContainer}>
          <h2 className={styles.formTitle}>Tell us more about your property</h2>
        </Row>

        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={24} className={styles.formItem}>
            <FormInput
              label="Property Name"
              name="name"
              placeholder="Type in your property name"
              requiredErrorMessage="Please provide a property name"
              form={form}
              defaultValue={defaultValues.name}
              size="large"
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-between" className={styles.formItemContainer}>
          <Col span={24} className={styles.formItem}>
            <FormSelection
              name="type"
              requiredErrorMessage="Please select your property type"
              placeholder="Choose your property type"
              form={form}
              defaultValue={defaultValues.type}
              shouldDisable={!hasFetchedPropertyTypes}
              selections={propertyTypes}
              size="large"
            />
          </Col>
        </Row>
      </ListingWizardLayout>
    );
  }
}

BasicDetails.propTypes = {
  form: PropTypes.object.isRequired,
  hasFetchedPropertyTypes: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  percentage: PropTypes.number.isRequired,
  propertyTypes: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  defaultValues: PropTypes.object,
  isLastPage: PropTypes.bool,
  onBack: PropTypes.func
};

BasicDetails.defaultProps = {
  defaultValues: {},
  hasFetchedPropertyTypes: false,
  isLastPage: false,
  propertyTypes: []
};

const WrappedFormBasicDetails = Form.create()(BasicDetails);

export default WrappedFormBasicDetails;
