import React, { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { withAppContext } from 'context/AppContext';

const useHandleOnLogout = (history, onLogout) => {
  const logoutAndRedirectUser = useCallback(() => {
    onLogout();
    history.push('/');
  }, [history, onLogout]);

  useEffect(() => {
    logoutAndRedirectUser();
  }, [logoutAndRedirectUser]);
};

const Logout = ({ history, logout: handleOnLogout }) => {
  useHandleOnLogout(history, handleOnLogout);

  return <div></div>;
};

export default withAppContext(withRouter(Logout));
