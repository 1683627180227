import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Tabs, Skeleton } from 'antd';

import {
  SEPARATOR_DASH,
  STANDARD_RATE_CODE,
  RATE_DISTRIBUTION_TAB,
  RATE_DERIVATIVE,
  RATE_DERIVATIVES,
  PAX_THRESHOLD,
  EXTRA_PAX_FEE
} from 'utils/constants';
import { guard } from 'utils/general';
import { getWebRateByRoomType } from 'utils/apis/rate';

import AdvancedOptionsModal from '../../../../AdvancedOptionsModal/AdvancedOptionsModal';
import AdvancedOptionsRateDistributionTab from '../../../../AdvancedOptionsRateDistributionTab/AdvancedOptionsRateDistributionTab';

const TabPane = Tabs.TabPane;

const getRateDistributionPayload = fieldValues => {
  let payload = [];

  const fieldValuesWithKeys = Object.entries(fieldValues);
  const processedFieldValuesWithKeys = fieldValuesWithKeys.filter(fv => fv[0].includes(RATE_DISTRIBUTION_TAB));

  for (let i = 0; i < processedFieldValuesWithKeys.length; i++) {
    const fieldValueWithKey = processedFieldValuesWithKeys[i];
    const fieldKey = fieldValueWithKey[0];
    const fieldValue = fieldValueWithKey[1];

    const splittedFieldKeys = fieldKey.split(SEPARATOR_DASH);
    const roomId = splittedFieldKeys[1];
    const otaId = splittedFieldKeys[2];
    const rateId = splittedFieldKeys[3];
    const fieldName = splittedFieldKeys[4];

    let existingRoom = payload.find(room => room.roomId === roomId);

    if (!existingRoom) {
      existingRoom = { roomId, rates: [] };
      payload.push(existingRoom);
    }

    let existingRateObject = existingRoom.rates.find(rateObject => {
      return rateObject.rate._id === rateId || rateObject.otaId === otaId;
    });

    if (!existingRateObject) {
      existingRateObject = { otaId, rate: { _id: rateId } };
      existingRoom.rates.push(existingRateObject);
    }

    if (fieldName === RATE_DERIVATIVE) {
      const derivativeObject = RATE_DERIVATIVES.find(rateDerivative => rateDerivative.code === fieldValue);
      existingRateObject.rate.isDerived = derivativeObject.isDerived;
    } else if (fieldName === PAX_THRESHOLD || fieldName === EXTRA_PAX_FEE) {
      if (!existingRateObject.occupancyRate) {
        existingRateObject.occupancyRate = { [fieldName]: fieldValue };
      } else {
        existingRateObject.occupancyRate[fieldName] = fieldValue;
      }
    } else {
      existingRateObject.rate[fieldName] = fieldValue;
    }
  }

  return payload;
};

class AgodaAdvancedOptionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingRateDistribution: false,

      roomsOfRates: []
    };
  }

  componentDidMount = async () => {
    const { roomMapping, ratePlans, property, rateModifierTypeConstants, rateModifierSignConstants } = this.props;

    this.setState({ isLoadingRateDistribution: true });
    const roomsOfRates = await this.getRoomsOfRates({ roomMapping, ratePlans, property, rateModifierTypeConstants, rateModifierSignConstants });
    this.setState({ roomsOfRates, isLoadingRateDistribution: false });
  };

  getRoomsOfRates = async ({ roomMapping, ratePlans, property, rateModifierTypeConstants, rateModifierSignConstants }) => {
    const roomsOfRates = await Promise.all(
      roomMapping.map(async room => {
        const roomType = !!property.roomTypes ? Object.values(property.roomTypes).find(roomType => room.roomType === roomType._id) : '';
        const roomName = !!roomType ? roomType.name : '';
        const roomId = !!roomType ? roomType._id : '';
        const maxCapacity = (roomType.capacity.adult || 1) + (roomType.capacity.children || 0);

        const rates = await getWebRateByRoomType(room.roomType);
        const webRate = rates.data.find(rate => rate.code === STANDARD_RATE_CODE);

        const roomRates = room.rates.map(rateObject => {
          const otaId = String(rateObject.agodaRatePlanId) || '';
          const { occupancyRate } = rateObject;
          let calculation = rateObject.rate.calculation;
          const ratePlan = ratePlans.find(ratePlan => String(ratePlan.rateplan_id) === String(rateObject.agodaRatePlanId));
          const ratePlanName = guard(() => ratePlan.rateplan_name, '');

          if (!rateObject.rate.calculation) {
            const modifierTypeObject = rateModifierTypeConstants.find(type => type.isDefault);

            calculation = {
              amount: modifierTypeObject.isPercentage ? 0.01 : 1,
              type: modifierTypeObject.code,
              isPositive: !!rateModifierSignConstants.find(sign => sign.isDefault && sign.isPositive)
            };
          }

          calculation = {
            ...calculation,
            parent: {
              weekday: webRate.weekday,
              weekend: webRate.weekend
            }
          };

          const rate = {
            _id: rateObject.rate._id,
            name: ratePlanName,
            isDerived: rateObject.rate.isDerived,
            weekday: rateObject.rate.weekday,
            weekend: rateObject.rate.weekend,
            calculation
          };

          return { otaId, rate, occupancyRate };
        });

        return {
          roomId,
          roomName,
          maxCapacity,
          rates: roomRates
        };
      })
    );

    return roomsOfRates;
  };

  handleOnSave = () => {
    const { form, onConfirm } = this.props;

    form.validateFields((err, values) => {
      if (!err) {
        const fieldValues = form.getFieldsValue();
        const rateDistributionPayload = getRateDistributionPayload(fieldValues);

        onConfirm(rateDistributionPayload);
      }
    });
  };

  render() {
    const { isLoadingRateDistribution, roomsOfRates } = this.state;
    const { form, isVisible, onClose, currency } = this.props;

    return (
      <AdvancedOptionsModal isVisible={isVisible} onSave={this.handleOnSave} onClose={onClose}>
        <Tabs defaultActiveKey={'rateDistribution'}>
          <TabPane tab="Rate Distribution" key="rateDistribution">
            <Skeleton loading={isLoadingRateDistribution} active>
              <AdvancedOptionsRateDistributionTab
                form={form}
                roomsOfRates={roomsOfRates}
                isOccupancyRateApplicable={process.env.REACT_APP_ALLOW_AGODA_OCCUPANCY_RATE}
                currency={currency}
              />
            </Skeleton>
          </TabPane>
        </Tabs>
      </AdvancedOptionsModal>
    );
  }
}

AgodaAdvancedOptionsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  property: PropTypes.object.isRequired,
  rateModifierTypeConstants: PropTypes.array.isRequired,
  rateModifierSignConstants: PropTypes.array.isRequired,
  roomMapping: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired
};

AgodaAdvancedOptionsModal.defaultProps = {
  isVisible: false,
  currency: 'RM',
  onConfirm: () => {},
  onClose: () => {}
};

export default Form.create()(AgodaAdvancedOptionsModal);
