import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd';
import moment from 'moment';
import queryString from 'query-string';

import { BareRadioButton } from 'components/FormRadioButton/FormRadioButton';
import MonthPicker from 'components/MonthPicker/MonthPicker';
import { BasicSelect } from 'components/FormSelection/FormSelection';

import Overview from './components/Overview/Overview';
import ViewByUnits from './components/ViewByUnits/ViewByUnits';

import { useGetHosts } from 'utils/apis/host';
import { guard } from 'utils/general';
import { buildAPBillingInventoryUri } from 'utils/routes';

import { BodyContainer, HeaderContainer, MainContainer } from './BillingInventory.styles';

const MONTH_YEAR_FORMAT = 'YYYY-MM';
const VIEW_TYPES = { OVERVIEW: { value: 'overview', label: 'Overview' }, UNIT: { value: 'units', label: 'View by Units' } };

const useFetchHostOptions = () => {
  const { data: hosts, isLoading: isHostLoading } = useGetHosts();

  const hostOptions = hosts && hosts.map(host => ({ label: host.name, value: host._id }));
  return { hostOptions, isHostLoading };
};

const BillingInventory = ({ history, location }) => {
  const [selectedHostId, setSelectedHostId] = useState();
  const [selectedView, setSelectedView] = useState();
  const [monthWithYear, setMonthWithYear] = useState();

  const { hostOptions, isHostLoading } = useFetchHostOptions();

  const showHostSelection = selectedView === VIEW_TYPES.UNIT.value;

  const setDefaultValueFromUrlQuery = useCallback(
    (urlKey, setValue, defaultValue) => {
      const urlQuery = queryString.parse(location.search);
      if (urlQuery && urlQuery[urlKey]) {
        setValue(urlQuery[urlKey]);
      } else {
        setValue(defaultValue);
      }
    },
    [location.search]
  );

  useEffect(() => {
    if (!selectedHostId) {
      const defaultHostId = hostOptions && hostOptions[0].value;
      setDefaultValueFromUrlQuery('host', setSelectedHostId, defaultHostId);
    }
    if (!selectedView) {
      setDefaultValueFromUrlQuery('view', setSelectedView, VIEW_TYPES.OVERVIEW.value);
    }
    if (!monthWithYear) {
      setDefaultValueFromUrlQuery('date', setMonthWithYear, moment().format(MONTH_YEAR_FORMAT));
    }
  }, [hostOptions, selectedHostId, selectedView, monthWithYear, setDefaultValueFromUrlQuery]);

  useEffect(() => {
    if (selectedHostId && selectedView && monthWithYear) {
      history.push(buildAPBillingInventoryUri({ host: selectedHostId, view: selectedView, date: monthWithYear }));
    }
  }, [history, selectedHostId, selectedView, monthWithYear]);

  const handleOnMonthYearChange = date => {
    const formattedDate = date.format(MONTH_YEAR_FORMAT);
    setMonthWithYear(formattedDate);
  };

  const handleOnChangeViewType = e => {
    const selectedViewType = e.target.value;
    setSelectedView(selectedViewType);
  };

  const handleOnHostClick = hostId => () => {
    setSelectedHostId(hostId);
    setSelectedView(VIEW_TYPES.UNIT.value);
  };

  const getCsvFileName = () => {
    const defaultFileName = 'Inventory Reporting';
    const stringMonthYear = moment(monthWithYear).format('MMM YYYY');
    const defaultFileNameWithMonthYear = `${stringMonthYear} - ${defaultFileName}`;

    const hostLabel = guard(() => hostOptions.find(option => option.value === selectedHostId).label);
    const fileName = !!hostLabel ? `${hostLabel} - ${defaultFileNameWithMonthYear}` : `${defaultFileNameWithMonthYear}`;

    return `${fileName}.csv`;
  };

  return (
    <MainContainer>
      <HeaderContainer>
        <Row type="flex" justify="space-between" gutter={[16, { xs: 16, sm: 16, md: 0 }]}>
          <Col>
            <BareRadioButton options={Object.values(VIEW_TYPES)} onChange={handleOnChangeViewType} value={selectedView} />
          </Col>
          <Col>
            <Row type="flex" justify="end" gutter={[16, { xs: 16, sm: 16, md: 0 }]}>
              <Col>
                <MonthPicker defaultValue={moment(monthWithYear)} onChange={handleOnMonthYearChange} />
              </Col>
              {!isHostLoading && showHostSelection && (
                <Col>
                  <BasicSelect label="Host" selections={hostOptions} value={selectedHostId} onChange={setSelectedHostId} placeholder="No Host" />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </HeaderContainer>
      <BodyContainer>
        {selectedView === VIEW_TYPES.OVERVIEW.value && <Overview monthWithYear={monthWithYear} onHostClick={handleOnHostClick} />}
        {selectedView === VIEW_TYPES.UNIT.value && (
          <ViewByUnits hostId={selectedHostId} monthWithYear={monthWithYear} getCsvFileName={getCsvFileName} />
        )}
      </BodyContainer>
    </MainContainer>
  );
};

export default withRouter(BillingInventory);
