import React from 'react';
import { Alert, Card, Col, Row } from 'antd';
import PropTypes from 'prop-types';

import FormInput from 'components/FormInput/FormInput';

import { DEFAULT_FINE_PRINT } from 'utils/constants';

import styles from './FinePrintInfoCard.module.css';

const FinePrintInfoCard = ({ form, cardClassname, defaultValues }) => {
  const shouldShowDefaultFinePrint = !form.getFieldValue('finePrint');
  return (
    <Card title="Fine Print Information" className={cardClassname}>
      <Row gutter={16}>
        <Col span={24}>
          <Alert
            className={styles.notes}
            message="Information"
            description={
              <div>
                <p className={styles.notesText}>
                  This fine print text will be used to display in invoices, quotations, and guest registration cards.{' '}
                  {shouldShowDefaultFinePrint && (
                    <span>By default, we've set a universal fine print. You may change it to fit to your business.</span>
                  )}
                </p>
                {shouldShowDefaultFinePrint && <p className={styles.defaultFinePrintText}>{DEFAULT_FINE_PRINT}</p>}
              </div>
            }
            type="info"
            showIcon
          />
          <br />
          <FormInput
            form={form}
            name="finePrint"
            defaultValue={defaultValues.finePrint}
            inputType="textarea"
            label="Fine Print"
            placeholder="Type in your fine print"
          />
        </Col>
      </Row>
    </Card>
  );
};

FinePrintInfoCard.propTypes = {
  form: PropTypes.object.isRequired,
  cardClassname: PropTypes.string.isRequired,
  defaultValues: PropTypes.object
};

FinePrintInfoCard.defaultProps = {
  defaultValues: {}
};

export default FinePrintInfoCard;
