import { httpClient } from './../httpClient';
export const getAudits = (query = '') => {
  return httpClient
    .get('/audit/activitylog/' + query)
    .then(response => {
      console.log(response);
      return response;
    })
    .catch(error => {
      return error.response;
    });
};
