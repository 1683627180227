import React from 'react';
import { Card, Row } from 'antd';

import { BasicSelect } from 'components/FormSelection/FormSelection';

import styles from './HotelDetails.module.css';

const HotelDetails = ({ isEdit, travelokasSelection, selectedTravelokaId, setSelectedTravelokaId, selectedTraveloka }) => {
  return (
    <Card title="Hotel Details">
      <label className="ota-label">Traveloka Hotel</label>
      <BasicSelect
        selectClassName={styles.selection}
        selections={travelokasSelection}
        value={selectedTravelokaId}
        placeholder="Select a Traveloka hotel"
        onChange={setSelectedTravelokaId}
        isDisabled={isEdit}
      />
      {selectedTraveloka.travelokaActualPropertyId ? (
        <Row style={{ marginTop: 12 }}>
          <div>
            <b>Found Hotel: </b> ({selectedTraveloka.travelokaActualPropertyId}) {selectedTraveloka.travelokaPropertyName || 'No Property Name'}
          </div>
        </Row>
      ) : (
        <Row style={{ marginTop: 12 }}>
          <div>Please input a valid Traveloka Hotel Code</div>
        </Row>
      )}
    </Card>
  );
};

export default HotelDetails;
