import React from 'react';
import { Card as AntdCard } from 'antd';

import CloseButton from 'components/CloseButton/CloseButton';

import styles from './Card.module.css';

const Card = ({ children, onClose, hideCloseIcon, ...props }) => (
  <AntdCard className={styles.antdCard} {...props}>
    {!hideCloseIcon && <CloseButton onClick={onClose} />}
    {children}
  </AntdCard>
);

export default Card;
