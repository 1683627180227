import React, { useState } from 'react';
import { Table, Button, Icon, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import OTALogo from 'components/OTALogo/OTALogo';

import { useGetPaginatedReservations } from 'utils/apis/reservation';
import { useFetchConstant } from 'hooks/constants';
import { cleanNumber } from 'utils/general';
import { handleOnAggregationTableChange, constructColumn, constructColumnFilterSearch, constructColumnDateSearch } from 'utils/table/table';

import CsvModal from './../components/CsvModal/CsvModal';
import PdfCardModal from 'components/ReceiptTemplate/PdfCardModal';

import './Reservation.css';

import { MIN_INTL_CONTACT_NO_LEN } from 'utils/constants';
import { withAppContext } from 'context/AppContext';

const COLLECT_COLOUR = { backgroundColor: '#f5222d' };
const REFUND_COLOUR = { backgroundColor: '#52c41a' };
const constructColumns = (bookingTypes, generateReport, { filters, sorter, dateFilter }) => {
  const getReceiptRenderProp = bookingTypes => ({
    render: (text, record) => {
      const bookingType = !!bookingTypes.find(type => type.code === text && type.hasPrice);
      if (bookingType) {
        return (
          <span>
            <Button type="primary" onClick={generateReport(record._id)}>
              <Icon type="download" />
            </Button>
          </span>
        );
      } else {
        return;
      }
    }
  });

  const getCodeRenderProp = () => ({
    render: (text, record) => (
      <Link to={`/reservation/${record._id}/edit`}>
        <OTALogo otaCode={record.platform} text={text} size="small" />
      </Link>
    )
  });

  const getContactNoRenderProp = () => ({
    render: text => {
      const contactNo = text || 'N/A';
      return contactNo && contactNo.length > MIN_INTL_CONTACT_NO_LEN ? (
        <Tooltip title={'Chat on WhatsApp'}>
          <a href={`https://wa.me/${cleanNumber(contactNo)}`} target="_blank" rel="noopener noreferrer">
            {contactNo}
          </a>
        </Tooltip>
      ) : (
        <>{contactNo}</>
      );
    }
  });

  const getDepositRenderProp = () => {
    const DepositDisplay = ({ amount, isRefund = false, isCollect = false }) => {
      const title = (isRefund && 'Refunded') || (isCollect && 'Collected');

      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={title}>
            <div
              style={{
                ...(isRefund && REFUND_COLOUR),
                ...(isCollect && COLLECT_COLOUR),
                borderRadius: '50%',
                width: 8,
                height: 8,
                display: 'inline-block'
              }}
            />
          </Tooltip>
          <span style={{ marginLeft: 4 }}>{amount}</span>
        </div>
      );
    };

    return {
      render: deposit => {
        const { collect, refund } = deposit || {};

        if (!isNaN(refund)) {
          return <DepositDisplay amount={refund} isRefund={true} />;
        } else if (!isNaN(collect)) {
          return <DepositDisplay amount={collect} isCollect={true} />;
        } else {
          return '-';
        }
      }
    };
  };

  return [
    {
      ...constructColumn('Confirmation Code', 'code', { hasSorter: true, sorter, width: 150 }),
      ...constructColumnFilterSearch('code', 'Search Confirmation Code', filters),
      ...getCodeRenderProp()
    },
    {
      ...constructColumn('Property Name', 'propertyName', { hasSorter: true, sorter }),
      ...constructColumnFilterSearch('propertyName', 'Search Property Name', filters)
    },
    {
      ...constructColumn('Unit Name', 'unitName', { hasSorter: true, sorter }),
      ...constructColumnFilterSearch('unitName', 'Search Unit Name', filters)
    },
    {
      ...constructColumn('Check-in Date', 'startDate', { hasSorter: true, sorter }),
      ...constructColumnDateSearch('startDate', 'Search Start Date', dateFilter)
    },
    {
      ...constructColumn('Check-out Date', 'endDate', { hasSorter: true, sorter }),
      ...constructColumnDateSearch('endDate', 'Search End Date', dateFilter)
    },
    {
      ...constructColumn('Guest Name', 'guestName', { hasSorter: true, sorter }),
      ...constructColumnFilterSearch('guestName', 'Search Guest Name', filters)
    },
    {
      ...constructColumn('Contact Number', 'contactNumber'),
      ...constructColumnFilterSearch('contactNumber', 'Search Contact Number', filters),
      ...getContactNoRenderProp()
    },
    {
      ...constructColumn('Deposit', 'deposit'),
      ...getDepositRenderProp()
    },
    {
      ...constructColumn('Receipt', 'bookingType'),
      ...getReceiptRenderProp(bookingTypes)
    }
  ];
};

const useFetchConstants = () => {
  const { selection: bookingStatuses, isLoading: isBookingStatusesLoading } = useFetchConstant('bookingStatus');
  const { selection: bookingTypes, isLoading: isBookingTypesLoading } = useFetchConstant('bookingTypes');
  const { selection: states, isLoading: isStatesLoading } = useFetchConstant('statesMY');
  const { selection: countries, isLoading: isCountriesLoading } = useFetchConstant('countries', { valueKey: 'iso2', labelKey: 'name' });

  const selections = { bookingStatuses, bookingTypes, states, countries };
  const isLoading = isBookingStatusesLoading || isBookingTypesLoading || isStatesLoading || isCountriesLoading;

  return { selections, isLoading };
};

const Standard = props => {
  const [query, setQuery] = useState({});
  const [tableQuery, setTableQuery] = useState({});
  const [isReceiptModalVisible, setIsReceiptModalVisible] = useState(false);
  const [isCSVModalVisible, setIsCSVModalVisible] = useState(false);
  const [selectedReservationId, setSelectedReservationId] = useState();
  const {
    selections: { bookingStatuses, bookingTypes, states, countries },
    isLoading: isConstantLoading
  } = useFetchConstants();
  const { paginatedData: reservations, total, isLoading: isReservationsLoading } = useGetPaginatedReservations(query);

  const { checkIsAdminReadOnly } = props;

  console.log('bookingStatuses', bookingStatuses);
  console.log('paginatedData', total);
  console.log('reservations', reservations);

  const isLoading = isConstantLoading || isReservationsLoading;

  const generateReport = reservationId => e => {
    setSelectedReservationId(reservationId);
    setIsReceiptModalVisible(true);
  };

  const handleClose = () => {
    setSelectedReservationId();
    setIsReceiptModalVisible(false);
  };

  const onTableDataChange = newQuery => {
    setTableQuery(newQuery);
    handleOnAggregationTableChange(newQuery, setQuery);
  };

  const handleOnClickClearAll = () => {
    const newQuery = {
      pagination: { current: 1, pageSize: 10 },
      filters: {},
      sorter: {}
    };
    onTableDataChange(newQuery);
  };

  return (
    <>
      <div className="sp-controls">
        <Link to={'/bookingForm'}>
          <Button id="create-button2-res" type="primary" icon="plus" disabled={checkIsAdminReadOnly()}>
            Create Reservation
          </Button>
        </Link>
        <div>
          <Button onClick={handleOnClickClearAll} style={{ marginRight: 5 }}>
            Reset All
          </Button>
          {props.checkAbleExportReservation() && (
            <Button id="csv-button1-res" icon="download" type="primary" onClick={() => setIsCSVModalVisible(true)} disabled={checkIsAdminReadOnly()}>
              Download CSV
            </Button>
          )}
        </div>
      </div>
      <Table
        rowKey={record => record._id}
        columns={constructColumns(bookingTypes, generateReport, tableQuery)}
        dataSource={reservations}
        scroll={{ x: 1000 }}
        loading={isLoading}
        onChange={(pagination, filters, sorter) => onTableDataChange({ pagination, filters, sorter })}
        pagination={{ total }}
      />
      {selectedReservationId && <PdfCardModal reservationId={selectedReservationId} visible={isReceiptModalVisible} handleClose={handleClose} />}
      <CsvModal
        visible={isCSVModalVisible}
        closeModal={() => setIsCSVModalVisible(false)}
        bookingStatus={bookingStatuses}
        bookingTypes={bookingTypes}
        countries={countries}
        states={states}
        isAdmin={props.checkIsAdmin()}
        isAllowViewTransaction={props.checkIsAllowViewTransaction()}
      />
    </>
  );
};

export default withAppContext(Standard);
