import React, { Component } from 'react';
import { Card, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';

import RowFormCounter from '../RowFormCounter/RowFormCounter';

const FIELD_NAME_NAME = 'name';
const FIELD_NAME_ROOM_SIZE = 'roomSizeInSqFt';
const FIELD_NAME_WEEKDAY_PRICE = 'weekdayPrice';
const FIELD_NAME_WEEKEND_PRICE = 'weekendPrice';
const FIELD_NAME_CAPACITY_ADULT = 'adultCapacity';
const FIELD_NAME_CAPACITY_CHILDREN = 'childrenCapacity';
class BasicDetailsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { form, cardClassname, defaultValues } = this.props;
    return (
      <Card title="Basic Information" className={cardClassname}>
        <Row gutter={16} type="flex" justify="start">
          <Col span={24} md={12}>
            <FormInput
              form={form}
              name={FIELD_NAME_NAME}
              defaultValue={defaultValues[FIELD_NAME_NAME]}
              inputType="input"
              label="Name"
              placeholder="Type in your room type name"
              requiredErrorMessage="Name is required"
              size="large"
            />
          </Col>
          <Col span={24} md={12}>
            <FormInputNumber
              form={form}
              name={FIELD_NAME_ROOM_SIZE}
              defaultValue={defaultValues[FIELD_NAME_ROOM_SIZE]}
              minValue={1}
              inputType="input"
              label="Room Size (sqft)"
              placeholder="Size of your room type"
              requiredErrorMessage="Please provide size of your room type in sqft"
              size="large"
              customStyle={{
                paddingTop: 0
              }}
            />
          </Col>
          <Col span={24} md={12}>
            <FormInputNumber
              form={form}
              name={FIELD_NAME_WEEKDAY_PRICE}
              defaultValue={defaultValues[FIELD_NAME_WEEKDAY_PRICE]}
              label={`Weekday Pricing (${this.props.currency})`}
              minValue={1.0}
              precision={2}
              placeholder="Price to charge on weekday"
              requiredErrorMessage="Please set a price for weekday"
              size="large"
            />
          </Col>
          <Col span={24} md={12}>
            <FormInputNumber
              form={form}
              name={FIELD_NAME_WEEKEND_PRICE}
              defaultValue={defaultValues[FIELD_NAME_WEEKEND_PRICE]}
              label={`Weekend Pricing (${this.props.currency})`}
              minValue={1.0}
              precision={2}
              placeholder="Price to charge on weekend"
              requiredErrorMessage="Please set a price for weekend"
              size="large"
            />
          </Col>
        </Row>
        <p style={{ marginBottom: '12px', fontSize: '16px' }}>Max Occupancy</p>
        <RowFormCounter form={form} label="Adult" name={FIELD_NAME_CAPACITY_ADULT} defaultValue={defaultValues[FIELD_NAME_CAPACITY_ADULT]} />
        <RowFormCounter form={form} label="Children" name={FIELD_NAME_CAPACITY_CHILDREN} defaultValue={defaultValues[FIELD_NAME_CAPACITY_CHILDREN]} />
      </Card>
    );
  }
}

BasicDetailsCard.propTypes = {
  cardClassname: PropTypes.string.isRequired,
  defaultValues: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

BasicDetailsCard.defaultProps = {
  cardClassname: '',
  defaultValues: {},
  form: {}
};

export default BasicDetailsCard;
