const serviceFeeKeys = ['earlyCheckIn', 'lateCheckOut', 'shuttle', 'transportation', 'breakfast', 'lunch', 'dinner'];

const calculateTotalServiceFee = (charges = {}) => {
  const allServiceFees =
    charges &&
    Object.keys(charges).map(key => {
      if (serviceFeeKeys.includes(key)) {
        return charges[key] && charges[key] > 0 ? charges[key] : 0;
      }
      return 0;
    });
  return allServiceFees.reduce((total, serviceFee) => total + serviceFee, 0);
};

const calculateTotalAmount = (charges = {}, taxes = {}, addons = {}) => {
  const allCharges =
    charges &&
    Object.keys(charges).map(key => {
      return charges[key] && charges[key] > 0 ? charges[key] : 0;
    });
  const allTaxes =
    taxes &&
    Object.keys(taxes).map(key => {
      return taxes[key] && taxes[key] > 0 ? taxes[key] : 0;
    });
  const allAddOns =
    addons &&
    Object.keys(addons).map(key => {
      return addons[key] && addons[key] > 0 ? addons[key] : 0;
    });
  return allCharges
    .concat(allTaxes)
    .concat(allAddOns)
    .reduce((total, amount) => total + amount, 0);
};

const calculatePaymentReceived = (payment = {}) => {
  const allPayment = payment
    ? Object.keys(payment).map(key => {
        if (key !== 'details') {
          return payment[key] && payment[key] > 0 ? payment[key] : 0;
        }
        return 0;
      })
    : [];
  return allPayment.reduce((total, paymentAmount) => total + paymentAmount, 0);
};

module.exports = {
  calculateTotalServiceFee,
  calculateTotalAmount,
  calculatePaymentReceived
};
