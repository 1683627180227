import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';

const ColMobileSeperator = ({ margin }) => <Col span={24} md={0} style={{ marginBottom: `${margin}px` }} />;

ColMobileSeperator.propTypes = {
  margin: PropTypes.number.isRequired
};

ColMobileSeperator.defaultProps = {
  margin: 20
};

export default ColMobileSeperator;
