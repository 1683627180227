import React, { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Form, Row, Col, DatePicker, Button, Card, Modal } from 'antd';

import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormSelection from 'components/FormSelection/FormSelection';

const FormItem = Form.Item;
const CollectAllPaymentModal = Form.create()(
  ({
    form,
    totalAmount,
    charges,
    taxes,
    paymentMethods,
    paymentMethodSelected,
    handleOnPaymentMethodChange,
    handleOnAllPaymentSubmit,
    currency = 'RM'
  }) => {
    return (
      <div>
        <Card title={`Total amount to be collected: ${currency} ${totalAmount.toFixed(2)}`}>
          {charges && charges.length > 0 && (
            <Fragment>
              <h3>Charges</h3>
              <div styles={{ margin: '16px auto', width: '30%', borderBottom: '1px solid #e0e7e7' }} />
            </Fragment>
          )}
          {charges &&
            charges.map(charge => (
              <p key={charge.name}>
                {charge.name}: {currency} {charge.amount.toFixed(2)}
              </p>
            ))}

          {taxes && taxes.length > 0 && (
            <Fragment>
              <h3>Taxes</h3>
              <div styles={{ margin: '16px auto', width: '30%', borderBottom: '1px solid #e0e7e7' }} />
            </Fragment>
          )}
          {taxes &&
            taxes.map(tax => (
              <p key={tax.name}>
                {tax.name}: {currency} {tax.amount.toFixed(2)}
              </p>
            ))}
          <Form onSubmit={event => handleOnAllPaymentSubmit(event, form)} style={{ width: '100%' }}>
            <Row>
              <Col span={24}>
                <p style={{ marginBottom: '8px' }}>Date</p>
                <FormItem style={{ marginBottom: 0 }}>
                  {form.getFieldDecorator('paymentDate', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select a date!'
                      }
                    ],
                    initialValue: moment(new Date(), 'YYYY-MM-DD')
                  })(<DatePicker name="paymentDate" size="large" style={{ width: '100%', marginBottom: '16px' }} format={'YYYY-MM-DD'} />)}
                </FormItem>
              </Col>
              <Col span={24}>
                <FormSelection
                  name="paymentMethod"
                  label="Payment Method"
                  requiredErrorMessage="Please select your payment method"
                  placeholder="Choose method of payment"
                  form={form}
                  selections={paymentMethods}
                  onChange={handleOnPaymentMethodChange}
                  size="large"
                />
              </Col>
            </Row>
            {paymentMethodSelected && paymentMethodSelected !== 'cash' && (
              <Row gutter={16} style={{ marginBottom: '16px' }}>
                <Card>
                  {paymentMethodSelected === 'creditCard' && (
                    <Fragment>
                      <Row>
                        <FormInput
                          name="paymentCardNo"
                          label="Credit Card / Debit Card No."
                          placeholder="Provide the card number"
                          form={form}
                          size="large"
                        />
                      </Row>
                      <Row>
                        <FormInput
                          name="paymentExpiryDate"
                          label="Expiry Date (e.g.: 08/21)"
                          placeholder="Provide the card expiry date in the format of MM/YY"
                          form={form}
                          size="large"
                        />
                      </Row>
                    </Fragment>
                  )}
                  <Row>
                    <FormInput
                      label="Remarks (optional)"
                      name="paymentRemarks"
                      placeholder="Provide your payment remark if you have any"
                      form={form}
                      size="large"
                    />
                  </Row>
                </Card>
              </Row>
            )}
            <Button htmlType="submit" type="primary">
              Collect All
            </Button>
          </Form>
        </Card>
      </div>
    );
  }
);

class PaymentTabPane extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    paymentTypes: PropTypes.array.isRequired,
    paymentMethods: PropTypes.array.isRequired,
    amount: PropTypes.number.isRequired,
    paymentMethodSelected: PropTypes.string.isRequired,
    handleOnPaymentTypeChange: PropTypes.func.isRequired,
    handleOnPaymentMethodChange: PropTypes.func.isRequired,
    handleOnPaymentSubmit: PropTypes.func.isRequired,
    handleOnAllPaymentSubmit: PropTypes.func.isRequired,
    getTotalAmountForPayment: PropTypes.func.isRequired,
    getPaymentList: PropTypes.func.isRequired,
    currency: PropTypes.string.isRequired
  };

  static defaultProps = {
    form: {},
    paymentTypes: [],
    paymentMethods: [],
    amount: 0,
    currency: 'RM',
    paymentMethodSelected: '',
    handleOnPaymentTypeChange: () => {},
    handleOnPaymentMethodChange: () => {},
    handleOnPaymentSubmit: () => {}
  };

  constructor() {
    super();
    this.state = {
      isCollectAllPaymentBtnClicked: false
    };
  }

  handleOnClickedCollectAllPaymentBtn = () => {
    this.setState({
      isCollectAllPaymentBtnClicked: true
    });
  };

  handleOnClickedCollectAllPaymentModalCancelBtn = () => {
    this.setState({
      isCollectAllPaymentBtnClicked: false
    });
  };

  render() {
    const {
      form,
      paymentTypes,
      paymentMethods,
      handleOnPaymentTypeChange,
      amount,
      paymentMethodSelected,
      handleOnPaymentMethodChange,
      handleOnPaymentSubmit,
      handleOnAllPaymentSubmit,
      getTotalAmountForPayment,
      getPaymentList,
      currency,
      checkIsAdminReadOnly
    } = this.props;
    const { isCollectAllPaymentBtnClicked } = this.state;

    return (
      <Fragment>
        <Form onSubmit={event => handleOnPaymentSubmit(event, form)} style={{ width: '100%' }}>
          <Row gutter={16}>
            <Col span={24}>
              <p style={{ marginBottom: '8px' }}>Date</p>
              <FormItem style={{ marginBottom: 0 }}>
                {form.getFieldDecorator('paymentDate', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a date!'
                    }
                  ],
                  initialValue: moment(new Date(), 'YYYY-MM-DD')
                })(<DatePicker name="paymentDate" size="large" style={{ width: '100%', marginBottom: '16px' }} format={'YYYY-MM-DD'} />)}
              </FormItem>
            </Col>
            <Col span={24} md={18}>
              <FormSelection
                label="Pay For"
                name="paymentType"
                requiredErrorMessage="Please select your payment type"
                placeholder="Choose type of payment"
                form={form}
                selections={paymentTypes}
                onChange={handleOnPaymentTypeChange}
                size="large"
              />
            </Col>
            <Col span={24} md={6}>
              <FormInputNumber
                form={form}
                name="amount"
                minValue={1}
                precision={2}
                defaultValue={amount}
                label={`Amount (${currency})`}
                placeholder="Amount to be collected"
                size="large"
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <FormInput
                inputType="textarea"
                label="Remarks (optional)"
                name="remarks"
                placeholder="Provide your remark if you have any"
                form={form}
                extraProps={{ rows: 2 }}
                size="large"
              />
            </Col>
            <Col span={24}>
              <FormSelection
                name="paymentMethod"
                label="Payment Method"
                requiredErrorMessage="Please select your payment method"
                placeholder="Choose method of payment"
                form={form}
                selections={paymentMethods}
                onChange={handleOnPaymentMethodChange}
                size="large"
              />
            </Col>
          </Row>
          {paymentMethodSelected && paymentMethodSelected !== 'cash' && (
            <Row gutter={16} style={{ marginBottom: '16px' }}>
              <Card>
                {paymentMethodSelected === 'creditCard' ? (
                  <Fragment>
                    <Row>
                      <FormInput
                        name="paymentCardNo"
                        label="Credit Card / Debit Card No."
                        placeholder="Provide the card number"
                        form={form}
                        size="large"
                      />
                    </Row>
                    <Row>
                      <FormInput
                        name="paymentExpiryDate"
                        label="Expiry Date (e.g.: 08/21)"
                        placeholder="Provide the card expiry date in the format of MM/YY"
                        form={form}
                        size="large"
                      />
                    </Row>
                  </Fragment>
                ) : (
                  <Row>
                    <FormInput
                      label="Remarks (optional)"
                      name="paymentRemarks"
                      placeholder="Provide your payment remark if you have any"
                      form={form}
                      size="large"
                    />
                  </Row>
                )}
              </Card>
            </Row>
          )}
          <Row type="flex" justify="center" gutter={16}>
            <Col span={24} xl={10}>
              <Button type="primary" htmlType="submit" size="large" className="payment-buttons" disabled={checkIsAdminReadOnly()}>
                Collect Payment
              </Button>
            </Col>
            <Col span={24} xl={6}>
              <Button
                type="danger"
                onClick={this.handleOnClickedCollectAllPaymentBtn}
                size="large"
                className="payment-buttons"
                disabled={checkIsAdminReadOnly()}
              >
                Collect All Payment
              </Button>
            </Col>
          </Row>
        </Form>
        <Modal
          visible={isCollectAllPaymentBtnClicked}
          title="Collect All Outstanding Balances"
          onCancel={this.handleOnClickedCollectAllPaymentModalCancelBtn}
          footer={null}
        >
          <CollectAllPaymentModal
            totalAmount={getTotalAmountForPayment()}
            charges={getPaymentList().charges}
            taxes={getPaymentList().taxes}
            paymentMethods={paymentMethods}
            paymentMethodSelected={paymentMethodSelected}
            handleOnPaymentMethodChange={handleOnPaymentMethodChange}
            handleOnAllPaymentSubmit={handleOnAllPaymentSubmit}
            currency={currency}
          />
        </Modal>
      </Fragment>
    );
  }
}

export default Form.create()(PaymentTabPane);
