import { Link } from 'react-router-dom';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Row, Table, Tag } from 'antd';

import { AppContext, withAppContext } from 'context/AppContext';

import { StyledHeader } from './Tasklist.styles';
import styles from './Tasklist.module.css';

import { useFetchConstant } from 'hooks/constants';
import { buildTaskManagementTaskCreateUri, buildTaskManagementTaskEditUri } from 'utils/routes';
import { formatToDateString, getCurrentMoment } from 'utils/date';
import { getConstantLabel } from 'utils/general';
import {
  handleOnAggregationTableChange,
  constructColumn,
  constructColumnFilterRadio,
  constructColumnFilterCheckbox,
  constructColumnFilterSearch
} from 'utils/table/table';
import { getAllTasks, getTasklistOverview, useGetPaginatedTaskList } from 'utils/apis/taskManagement';
import DownloadCSVModal from './components/DownloadCSVModal';

const TODO = 'toDo';
const IN_PROGRESS = 'inProgress';
const ALL = 'all';
const COMPLETED = 'completed';

const CUSTOM_TAG_STYLE = {
  red: { borderColor: '#db4537', color: '#db4537', backgroundColor: '#fff1f0' },
  blue: {
    borderColor: '#00b9c6',
    color: '#00b9c6',
    backgroundColor: '#e6f7ff'
  }
};

const Tasklist = props => {
  const context = useContext(AppContext);
  const { checkIsAllowCreateTask, checkIsAdminReadOnly } = context;

  const [query, setQuery] = useState({
    filter: { status: 'pending' },
    sorter: { priority: -1 },
    currentPage: 1,
    limit: 10
  });
  const [loadTable, setLoadTable] = useState(false);
  const [tableQuery, setTableQuery] = useState({});
  const [overviewLoading, setOverviewLoading] = useState(true);
  const [taskLists, setTasklists] = useState([]);
  const [selectedTabKey, setSelectedTabKey] = useState(TODO);
  const [overview, setOverview] = useState({
    toDo: 0,
    inProgress: 0,
    completed: 0,
    total: 0
  });
  const [csvModalVisible, setModalVisible] = useState(false);
  const { paginatedData: taskListsData, isLoading: isTaskListLoading, refetch } = useGetPaginatedTaskList(query);

  useEffect(() => {
    // getAllTaskByType(selectedTabKey);
    refetch();
  }, [selectedTabKey]);

  useEffect(() => {
    tasklistOverview();
  }, []);

  const { selection: priorityType, isLoading } = useFetchConstant('taskPriority');
  const { selection: taskStatusEnum, isLoading: taskStatusEnumLoading } = useFetchConstant('taskStatus');
  console.log(73, taskStatusEnum);
  const tabList = [
    {
      key: 'toDo',
      tab: 'To Do'
    },
    {
      key: 'inProgress',
      tab: 'In Progress'
    },
    {
      key: 'completed',
      tab: 'Completed'
    },
    {
      key: 'all',
      tab: 'All'
    }
  ];

  const reloadTable = () => {
    setLoadTable(true);
    setTimeout(() => {
      setLoadTable(false);
    }, 100);
  };
  const responseFilterValue = () => {
    switch (selectedTabKey) {
      case TODO:
        return { filteredValue: ['pending'] };

      case IN_PROGRESS:
        return { filteredValue: ['inProgress'] };

      case COMPLETED:
        return { filteredValue: ['completed'] };

      case ALL:
        return { defaultFilteredValue: [] };

      default:
        return {
          filteredValue: []
        };
    }
  };
  const onTableDataChange = newQuery => {
    console.log(newQuery, query);
    handleOnAggregationTableChange(newQuery, setQuery);
  };

  const tasklistOverview = async () => {
    const res = await getTasklistOverview();
    console.log(res);
    if (res.status === 200) {
      setOverview(res.data);
      setOverviewLoading(false);
    } else {
      setOverviewLoading(false);
    }
  };

  // const getAllTaskByType = async type => {
  //   const res = await getAllTasks(type);
  //   console.log(res);
  //   if (res.status === 200) {
  //     setTasklists(res.data);
  //   }
  // };

  const constructColumns = (recordType, priorityType) => {
    const columns = [
      {
        ...constructColumn('Created Date', 'createdAt', { hasSorter: true })
      },
      {
        ...constructColumn('Unit Name', 'unitName', { linkFunc: record => buildTaskManagementTaskEditUri(record._id) }),
        ...constructColumnFilterSearch('unitName', 'Search Unit')
      },
      {
        ...constructColumn('Property Name', 'property'),
        ...constructColumnFilterSearch('property', 'Search Property')
      },
      {
        ...constructColumn('Team Name', 'teamName'),
        ...constructColumnFilterSearch('teamName', 'Search Team')
      },
      {
        ...constructColumn('Cleaner Name', 'cleaner1'),
        ...constructColumnFilterSearch('cleaner1', 'Search Cleaner'),
        render: value => {
          if (value) {
            return value;
          } else {
            return '-';
          }
        }
      },
      {
        ...constructColumn('Status', 'status', { hasSorter: true }),
        ...(recordType === ALL && constructColumnFilterCheckbox('status', taskStatusEnum, recordType !== ALL)),
        ...responseFilterValue(),
        render: type => {
          const value = getConstantLabel(taskStatusEnum, type);
          switch (value) {
            case 'Pending Cleaner':
              return <Tag>{value}</Tag>;

            case 'In Progress':
              return (
                <Tag
                  style={{
                    ...CUSTOM_TAG_STYLE.blue
                  }}
                >
                  {value}
                </Tag>
              );

            case 'Completed':
              return <Tag color="green">{value}</Tag>;

            default:
              break;
          }
        }
      },
      {
        ...constructColumn('Priority', 'priority', { hasSorter: true }),
        ...constructColumnFilterRadio('priority', priorityType),
        defaultSortOrder: 'descend',
        render: type => {
          const value = getConstantLabel(priorityType, type);
          return (
            <Tag
              style={{
                ...(value === 'Urgent' ? CUSTOM_TAG_STYLE.red : CUSTOM_TAG_STYLE.blue)
              }}
            >
              {value}
            </Tag>
          );
        }
      }
    ];

    if (recordType === ALL || recordType === COMPLETED) {
      columns.push({
        ...constructColumn('Hours', 'timeTaken', { hasSorter: true }),
        render: value => `${value} hour(s)`
      });
    }
    return columns;
  };

  const contentList = {
    toDo: (
      <Table
        rowKey={record => record._id}
        dataSource={taskListsData.taskList}
        columns={constructColumns(TODO, priorityType)}
        scroll={{ x: 1000 }}
        onChange={(pagination, filters, sorter) => onTableDataChange({ pagination, filters, sorter })}
        loading={isTaskListLoading}
        pagination={{ total: taskListsData.totalCount, current: query.currentPage }}
      />
    ),
    inProgress: (
      <Table
        rowKey={record => record._id}
        dataSource={taskListsData.taskList}
        columns={constructColumns(IN_PROGRESS, priorityType)}
        scroll={{ x: 1000 }}
        onChange={(pagination, filters, sorter) => onTableDataChange({ pagination, filters, sorter })}
        loading={isTaskListLoading}
        pagination={{ total: taskListsData.totalCount, current: query.currentPage }}
      />
    ),
    completed: (
      <Table
        rowKey={record => record._id}
        dataSource={taskListsData.taskList}
        columns={constructColumns(COMPLETED, priorityType)}
        scroll={{ x: 1000 }}
        onChange={(pagination, filters, sorter) => onTableDataChange({ pagination, filters, sorter })}
        loading={isTaskListLoading}
        pagination={{ total: taskListsData.totalCount, current: query.currentPage }}
      />
    ),
    all: (
      <Table
        rowKey={record => record._id}
        dataSource={taskListsData.taskList}
        columns={constructColumns(ALL, priorityType)}
        scroll={{ x: 1000 }}
        onChange={(pagination, filters, sorter) => onTableDataChange({ pagination, filters, sorter })}
        loading={isTaskListLoading}
        pagination={{ total: taskListsData.totalCount, current: query.currentPage }}
      />
    )
  };

  const currentDate = useMemo(() => formatToDateString(getCurrentMoment()), []);

  return (
    <div style={{ display: 'grid', padding: 30, minWidth: 700 }}>
      <Row type="flex" gutter={[12, 12]} style={{ alignContent: 'top' }} justify="center">
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <Card bodyStyle={{ textAlign: 'center' }} loading={overviewLoading}>
            <div className={`${styles.summaryTitle}`}>To Do</div>
            <div className={`${styles.summaryBody}`}>{overview.toDo}</div>
          </Card>
        </Col>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <Card bodyStyle={{ textAlign: 'center' }} loading={overviewLoading}>
            <div className={`${styles.summaryTitle}`}>In Progress</div>
            <div className={`${styles.summaryBody}`}>{overview.inProgress}</div>
          </Card>
        </Col>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <Card bodyStyle={{ textAlign: 'center' }} loading={overviewLoading}>
            <div className={`${styles.summaryTitle}`}>Completed</div>
            <div className={`${styles.summaryBody}`}>{overview.completed}</div>
          </Card>
        </Col>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <Card bodyStyle={{ textAlign: 'center' }} loading={overviewLoading}>
            <div className={`${styles.summaryTitle}`}>Total</div>
            <div className={`${styles.summaryBody}`}>{overview.total}</div>
          </Card>
        </Col>
      </Row>
      <Row style={{ backgroundColor: '#fff', marginTop: 12 }}>
        <Card size="small">
          <StyledHeader>
            {checkIsAllowCreateTask() && (
              <Link to={buildTaskManagementTaskCreateUri()}>
                <Button id="create-button-task" type="primary" icon="plus" disabled={checkIsAdminReadOnly()}>
                  Create New Task
                </Button>
              </Link>
            )}
            {props.checkAbleExportTaskManagement() && (
              <Button id="csv-button-task" type="primary" icon="download" onClick={() => setModalVisible(true)} disabled={checkIsAdminReadOnly()}>
                Download CSV
              </Button>
            )}
          </StyledHeader>
          <Card
            bodyStyle={{ padding: '12px 0px' }}
            size="small"
            tabList={tabList}
            activeTabKey={selectedTabKey}
            onTabChange={key => {
              setSelectedTabKey(key);
              reloadTable();
              switch (true) {
                case key === TODO:
                  setQuery({
                    filter: { status: 'pending' },
                    sorter: { priority: -1 },
                    pagination: { limit: 10, currentPage: 1 }
                  });
                  break;

                case key === IN_PROGRESS:
                  setQuery({
                    filter: { status: 'inProgress' },
                    sorter: { priority: -1 },
                    pagination: { limit: 10, currentPage: 1 }
                  });
                  break;

                case key === COMPLETED:
                  setQuery({
                    filter: { status: 'completed' },
                    sorter: { priority: -1 },
                    pagination: { limit: 10, currentPage: 1 }
                  });
                  break;

                case key === ALL:
                  setQuery({
                    filter: {},
                    sorter: { priority: -1 },
                    pagination: { limit: 10, currentPage: 1 }
                  });
                  break;

                default:
                  console.log(key);
                  break;
              }
            }}
            bordered={false}
          >
            {!loadTable && contentList[selectedTabKey]}
          </Card>
        </Card>
      </Row>
      <DownloadCSVModal visible={csvModalVisible} onCancel={() => setModalVisible(false)} />
    </div>
  );
};

export default withAppContext(Tasklist);
