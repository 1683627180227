import React, { Component } from 'react';
import { Modal, Row, Col, Alert } from 'antd';

import { calculateTotalAmount, calculateTotalServiceFee } from 'utils/transaction';
import { getCountriesConstant, getStatesConstant } from 'utils/apis/constants';
import { getReservationsById } from 'utils/apis/reservation';

import TaxInvoice from './TaxInvoice';
import TaxQuotation from './TaxQuotation';
import GuestRegistrationCard from './GuestRegistrationCard';
import DepositRefunded from './RefundableDeposit';
import Receipt from './Receipt';

class PdfCardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      states: [],
      reservation: {}
    };
  }

  loadCountries = async () => {
    const countries = await getCountriesConstant();
    this.setState({
      countries: Object.values(countries)
    });
  };

  loadStates = async () => {
    const states = await getStatesConstant('MY');
    this.setState({
      states: Object.values(states)
    });
  };

  loadReservation = async () => {
    const { reservationId } = this.props;
    const reservation = await getReservationsById(reservationId, { isWithTransaction: true });
    const reservationWithTotalAmountAndServiceFee = {
      ...reservation.data,
      totalPrice: calculateTotalAmount(reservation.data.charges, reservation.data.taxes),
      totalServiceFee: calculateTotalServiceFee(reservation.data.charges)
    };
    this.setState({
      reservation: reservationWithTotalAmountAndServiceFee
    });
  };

  getPaymentDetails = (payment = {}) => {
    let methodsUsed = [];
    let remarks = [];
    let cardNos = [];
    let expiryDates = [];
    if (payment && payment.details) {
      payment.details.forEach(detail => {
        if (!methodsUsed.includes(detail.method)) {
          methodsUsed.push(detail.method);
        }
        remarks.push(detail.remarks);
        if (!cardNos.includes(detail.cardNo)) {
          cardNos.push(detail.cardNo);
          expiryDates.push(detail.expiryDate);
        }
      });
    }
    return {
      methods: methodsUsed.length > 0 ? methodsUsed.filter(method => !!method).join(', ') : '',
      remarks: remarks.length > 0 ? remarks.filter(remark => !!remark).join('. ') : '',
      cardNos: cardNos.length > 0 ? cardNos.filter(no => !!no).join(', ') : '',
      expiryDates: expiryDates.length > 0 ? expiryDates.filter(date => !!date).join(', ') : ''
    };
  };

  getGuestNationality = nationality => {
    const { countries } = this.state;
    const filteredCountries = countries.filter(country => country.iso3 === nationality || country.iso2 === nationality);
    if (filteredCountries.length > 0) {
      return filteredCountries[0].name;
    }
    return '';
  };

  componentDidMount = () => {
    this.loadCountries();
    this.loadStates();
    this.loadReservation();
  };

  formatDataForPdf = reservation => {
    const { countries, states } = this.state;
    const taxNo =
      reservation && reservation.unit && reservation.unit.roomType && reservation.unit.roomType.property && reservation.unit.roomType.property.host
        ? reservation.unit.roomType.property.host.taxNo
        : '';
    const image =
      reservation &&
      reservation.unit &&
      reservation.unit.roomType &&
      reservation.unit.roomType.property &&
      reservation.unit.roomType.property.host &&
      reservation.unit.roomType.property.host.images &&
      reservation.unit.roomType.property.host.images[0]
        ? reservation.unit.roomType.property.host.images[0].imageUrl
        : 'https:dashboard-operator-image.s3.amazonaws.com/3386bffc-75c9-4390-a670-fc11ed8febe1_blanksquare.png';
    const city =
      reservation && reservation.unit && reservation.unit.roomType && reservation.unit.roomType.property
        ? reservation.unit.roomType.property.city
        : '';
    const street =
      reservation && reservation.unit && reservation.unit.roomType && reservation.unit.roomType.property
        ? reservation.unit.roomType.property.street
        : '';
    const zipCode =
      reservation && reservation.unit && reservation.unit.roomType && reservation.unit.roomType.property
        ? reservation.unit.roomType.property.zipCode
        : '';
    const user = reservation.createdBy ? reservation.createdBy.userProfile : {};
    let countryCode =
      reservation && reservation.unit && reservation.unit.roomType && reservation.unit.roomType.property
        ? reservation.unit.roomType.property.countryCode
        : '';
    countryCode = countryCode ? countries.filter(c => c.iso3 === countryCode || c.iso2 === countryCode)[0] : '';
    const country = countryCode ? countryCode.name : '';
    let state =
      reservation && reservation.unit && reservation.unit.roomType && reservation.unit.roomType.property
        ? reservation.unit.roomType.property.state
        : '';
    state = state && states.filter(a => a.code === state)[0] ? states.filter(a => a.code === state)[0].label : '';

    const partialData = {
      _id: reservation._id || '',
      confirmationCode: reservation.code || '',
      finePrint: reservation.unit && reservation.unit.finePrint,
      taxNo,
      image,
      city,
      street,
      zipCode,
      createdBy: user,
      country
    };

    return partialData;
  };

  render() {
    const { visible, handleClose, currency } = this.props;
    const { reservation } = this.state;
    if (!reservation._id) {
      return null;
    }
    const partialData = this.formatDataForPdf(reservation);
    return (
      <Modal
        title={'Receipts for Confirmation Code :  ' + reservation.code}
        visible={visible}
        footer={null}
        onCancel={handleClose}
        destroyOnClose={true}
        closable={true}
      >
        <Alert message="Info" description="Please disable Adblocker if receipts can't be opened." type="warning" showIcon />
        <Row type="flex" justify="start" gutter={8}>
          <Col className="modal-receipts-col" sm={12} xs={24}>
            <TaxQuotation data={partialData} reservation={reservation} getGuestNationality={this.getGuestNationality} currency={currency} />
          </Col>
          <Col className="modal-receipts-col" sm={12} xs={24}>
            <TaxInvoice data={partialData} reservation={reservation} getGuestNationality={this.getGuestNationality} currency={currency} />
          </Col>

          <Col className="modal-receipts-col" sm={12} xs={24}>
            <GuestRegistrationCard
              data={partialData}
              reservation={reservation}
              getPaymentDetails={this.getPaymentDetails}
              getGuestNationality={this.getGuestNationality}
              currency={currency}
            />
          </Col>
          <Col className="modal-receipts-col" sm={12} xs={24}>
            <DepositRefunded data={partialData} reservation={reservation} currency={currency} />
          </Col>
          {/* <Col className="modal-receipts-col" sm={12} xs={24}>
            <Receipt data={partialData} reservation={reservation} getGuestNationality={this.getGuestNationality} />
          </Col> */}
        </Row>
      </Modal>
    );
  }
}

export default PdfCardModal;
