import React, { Component } from 'react';
import { Card, Row, Col, Form, Button, notification } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import FormDatePickerRange from 'components/FormDatePickerRange/FormDatePickerRange';
import { syncBookingcomARI } from 'utils/apis/integration';
import '../../Expedia/components/ExpediaSyncForm.css';
import moment from 'moment';

class BookingcomSyncForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false
    };
    this.handleOnFormSubmit = this.handleOnFormSubmit.bind(this);
  }

  handleOnFormSubmit = e => {
    const { handleModalCancel, form, bookingcomData } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      let body = {};
      if (!err) {
        body = {
          bookingcomPropertyId: bookingcomData.bookingcomPropertyId,
          startDate: moment(values.dateRange[0]._d).format('YYYY-MM-DD'),
          endDate: moment(values.dateRange[1]._d).format('YYYY-MM-DD')
        };
        this.setState({
          isSaving: true
        });
        syncBookingcomARI(body)
          .then(() => {
            this.setState({
              isSaving: false
            });
            notification.success({
              message: 'Successfully sync to Booking.com!'
            });
            handleModalCancel();
          })
          .catch(ex => {
            this.setState({
              isSaving: false
            });
            notification.error({
              message: ex.message
            });
          });
      }
    });
  };

  //other functions will be implemented here
  render() {
    const { bookingcomData, form } = this.props;
    const { isSaving } = this.state;
    return (
      <Form onSubmit={this.handleOnFormSubmit} style={{ width: '100%' }}>
        <Card title={`Sync to ${bookingcomData.bookingcomPropertyName} | ${bookingcomData.bookingcomPropertyId}`}>
          <Row type="flex" justify="space-between" className={'formTitleContainer'}>
            <p className={'formTitle'}>{bookingcomData.propertyName}</p>
          </Row>
          <Row type="flex" justify="space-between" className={'formItemContainer'}>
            <Col span={24} className={'formItem'}>
              <FormDatePickerRange
                form={form}
                label="Select date to sync with Booking.com"
                fieldName="dateRange"
                requiredErrorMessage="Please select date range."
                defaultPickerValue={[moment(), moment().add('years', 1)]}
              />
            </Col>
          </Row>
          <Row type="flex" align="center" justify="bottom">
            <Col span={24}>
              <Button type="primary" htmlType="submit" loading={isSaving}>
                {isSaving ? 'Syncing' : 'Sync'}
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

BookingcomSyncForm.propTypes = {
  bookingcomData: PropTypes.object.isRequired,
  handleModalCancel: PropTypes.func.isRequired
};

export default withRouter(Form.create()(BookingcomSyncForm));
