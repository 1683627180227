import React from 'react';
import { Modal, Card, Row, Icon } from 'antd';
import moment from 'moment';

import { MONTH_FORMAT, MONTH_YEAR_DISPLAY_FORMAT } from 'utils/constants';
import { buildMultiCalendarUri } from 'utils/routes';

import styles from './MCModalClashingReservationWarning.module.css';

const LinkToClashingReservation = ({ hostId, propertyId, roomTypeId, date }) => (
  <a href={buildMultiCalendarUri({ host: hostId, property: propertyId, roomType: roomTypeId, date })}>
    <Icon className={`${styles.linkIcon}`} type="arrow-right" />
    &nbsp; View On Calendar
  </a>
);

const MCModalClashingReservationWarning = props => {
  const {
    clashingReservationIconStyle,
    showModalClashingReservationWarning,

    clashingReservationDetails,

    onCancelClashingReservationWarning,
    hostId
  } = props;

  const properties = clashingReservationDetails.properties;
  const modalTitle = (
    <div className={`${styles.modalTitle}`}>
      <Icon className={clashingReservationIconStyle} type="warning" />
      Clashing Reservations
    </div>
  );

  return (
    <Modal
      title={modalTitle}
      visible={showModalClashingReservationWarning}
      onCancel={onCancelClashingReservationWarning}
      footer={null}
      destroyOnClose={true}
    >
      {properties.map(property => {
        const propertyTitle = <span className={`${styles.propertyTitle}`}>{property.name}</span>;
        const monthWithYears = property.monthWithYears;

        return (
          <Card key={property._id} title={propertyTitle} className={`${styles.property}`}>
            {monthWithYears.map(monthWithYear => {
              const yearWithMonthMoment = moment(`${monthWithYear.year}-${monthWithYear.month}`);
              const yearWithMonth = yearWithMonthMoment.format(MONTH_FORMAT);
              const monthWithYearTitle = yearWithMonthMoment.format(MONTH_YEAR_DISPLAY_FORMAT);
              const roomTypes = monthWithYear.roomTypes;

              return (
                <Card
                  key={monthWithYearTitle}
                  type="inner"
                  className={`${styles.monthWithYear}`}
                  title={monthWithYearTitle}
                  extra={<LinkToClashingReservation hostId={hostId} propertyId={property._id} roomTypeId={roomTypes[0]._id} date={yearWithMonth} />}
                >
                  <span className={`${styles.monthWithYearTotalClashingReservationsTitle}`}>Total clashing reservations</span>
                  <br />
                  {roomTypes.map(roomType => (
                    <Row key={roomType._id}>
                      <span className={`${styles.monthWithYearRoomTypeName}`}>{roomType.name}: </span>
                      <span className={`${styles.monthWithYearTotalNumberOfClashes}`}>{roomType.totalNumberOfClashes}</span>
                    </Row>
                  ))}
                </Card>
              );
            })}
          </Card>
        );
      })}
    </Modal>
  );
};

export default MCModalClashingReservationWarning;
