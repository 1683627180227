import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Progress, Select, Divider } from 'antd';
import { Link } from 'react-router-dom';

import Header from 'components/Header/Header';
import { buildListingUri } from 'utils/routes';

import StepsImage from './steps-image.svg';

import styles from './Steps.module.css';

const StepCard = ({ label, title, selections, value, createLink, progress, onSelectionChange, enabled, shouldShowCreate }) => (
  <Row className={styles.stepCardContainer}>
    <h5 className={styles.stepCardLabel}>{label}</h5>
    <h2>{title}</h2>
    <Progress className={styles.stepCardProgressBar} percent={progress} showInfo={false} />
    {shouldShowCreate ? (
      <SelectionWithCreate selections={selections} onSelectionChange={onSelectionChange} value={value} enabled={enabled} createLink={createLink} />
    ) : (
      <SelectionWithoutCreate selections={selections} onSelectionChange={onSelectionChange} value={value} enabled={enabled} createLink={createLink} />
    )}
  </Row>
);

const SelectionWithCreate = ({ selections, onSelectionChange, value, enabled, createLink }) => (
  <Fragment>
    {selections && selections.length > 0 ? (
      <Fragment>
        <Select
          showSearch
          className={styles.stepCardSelection}
          placeholder="Choose one of these"
          optionFilterProp="children"
          onChange={onSelectionChange}
          disabled={!enabled}
          value={value === '' ? undefined : value}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {selections.map(selection => (
            <Select.Option key={selection.id} value={selection.id}>
              {selection.value}
            </Select.Option>
          ))}
        </Select>
        <Divider className={styles.stepCardDivider} type="vertical" />
        <Link className={styles.stepCardLink} to={createLink} disabled={!enabled}>
          create new
        </Link>
      </Fragment>
    ) : (
      <Link to={createLink}>
        <Button type="primary" disabled={!enabled}>
          Create
        </Button>
      </Link>
    )}
  </Fragment>
);

const SelectionWithoutCreate = ({ selections, onSelectionChange, value, enabled }) => (
  <Fragment>
    {selections && selections.length > 0 ? (
      <Fragment>
        <Select
          showSearch
          className={styles.stepCardSelectionNoCreate}
          placeholder="Choose one of these"
          optionFilterProp="children"
          onChange={onSelectionChange}
          disabled={!enabled}
          value={value === '' ? undefined : value}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {selections.map(selection => (
            <Select.Option key={selection.id} value={selection.id}>
              {selection.value}
            </Select.Option>
          ))}
        </Select>
      </Fragment>
    ) : (
      <p>You don't have any selection</p>
    )}
  </Fragment>
);

class Steps extends React.Component {
  render() {
    const { steps, onSelectionChange, history } = this.props;
    return (
      <Fragment>
        <Header />
        <Row className={styles.root} type="flex" align="middle" justify="center">
          <Col span={16} md={20}>
            <h1>Listing Wizard</h1>
            <p>This wizard will guide you through all the steps for you to list your unit.</p>
          </Col>
          <Col span={16} md={10}>
            {steps.map((step, index) => (
              <StepCard
                shouldShowCreate={step.canCreate}
                key={step.title}
                label={`step ${index + 1}`}
                title={step.title}
                selections={step.selections}
                value={step.selectedId}
                createLink={step.canCreate ? `${step.createLink}?${step.createQuery}` : ''}
                progress={step.progress}
                onSelectionChange={onSelectionChange(step.id)}
                enabled={step.enabled}
              />
            ))}
          </Col>
          <Col span={16} md={{ span: 6, offset: 3 }} className={styles.rightContainer}>
            <div className={styles.imageContainer}>
              <img alt="Steps" src={StepsImage} className={styles.image} />
            </div>
            <Button type="primary" ghost onClick={() => history.push(buildListingUri())}>
              View My Listings
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

Steps.propTypes = {
  onSelectionChange: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired
};

export default Steps;
