import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Tabs, Skeleton } from 'antd';

import { getWebRateByRoomType } from 'utils/apis/rate';
import {
  EXTRA_PAX_FEE,
  PAX_THRESHOLD,
  SEPARATOR_DASH,
  STANDARD_RATE_CODE,
  RATE_DISTRIBUTION_TAB,
  RATE_DERIVATIVE,
  RATE_DERIVATIVES
} from 'utils/constants';

import AdvancedOptionsModal from '../../../../AdvancedOptionsModal/AdvancedOptionsModal';
import AdvancedOptionsRateDistributionTab from '../../../../AdvancedOptionsRateDistributionTab/AdvancedOptionsRateDistributionTab';

const TabPane = Tabs.TabPane;

const getRateDistributionPayload = fieldValues => {
  let payload = [];

  const fieldValuesWithKeys = Object.entries(fieldValues);
  const processedFieldValuesWithKeys = fieldValuesWithKeys.filter(fv => fv[0].includes(RATE_DISTRIBUTION_TAB));

  for (let i = 0; i < processedFieldValuesWithKeys.length; i++) {
    const fieldValueWithKey = processedFieldValuesWithKeys[i];
    const fieldKey = fieldValueWithKey[0];
    const fieldValue = fieldValueWithKey[1];

    const splittedFieldKeys = fieldKey.split(SEPARATOR_DASH);
    const roomId = splittedFieldKeys[1];
    const otaId = splittedFieldKeys[2];
    const rateId = splittedFieldKeys[3];
    const fieldName = splittedFieldKeys[4];

    let existingRoom = payload.find(room => room.roomId === roomId);

    if (!existingRoom) {
      existingRoom = { roomId, rates: [] };
      payload.push(existingRoom);
    }

    let existingRateObject = existingRoom.rates.find(rateObject => rateObject.rate._id === rateId || rateObject.otaId === otaId);

    if (!existingRateObject) {
      existingRateObject = { otaId, rate: { _id: rateId } };
      existingRoom.rates.push(existingRateObject);
    }

    if (fieldName === RATE_DERIVATIVE) {
      const derivativeObject = RATE_DERIVATIVES.find(rateDerivative => rateDerivative.code === fieldValue);
      existingRateObject.rate.isDerived = derivativeObject.isDerived;
    } else if (fieldName === PAX_THRESHOLD || fieldName === EXTRA_PAX_FEE) {
      if (!existingRateObject.occupancyRate) {
        existingRateObject.occupancyRate = { [fieldName]: fieldValue };
      } else {
        existingRateObject.occupancyRate[fieldName] = fieldValue;
      }
    } else {
      existingRateObject.rate[fieldName] = fieldValue;
    }
  }

  return payload;
};

class AgodaHomesAdvancedOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingRateDistribution: false,
      roomsOfRates: [],
      rateModifierTypeConstants: [],
      rateModifierSignConstants: []
    };
  }

  componentDidMount = async () => {
    const { rateModifierTypeConstants, rateModifierSignConstants } = this.props;

    this.setState({ isLoadingRateDistribution: true });

    const roomsOfRates = await this.constructRoomsOfRates({ rateModifierTypeConstants, rateModifierSignConstants });

    this.setState({ roomsOfRates, isLoadingRateDistribution: false });
  };

  constructRoomsOfRates = async ({ rateModifierTypeConstants, rateModifierSignConstants }) => {
    const { rate, agodaRoomRates, roomType, occupancyRate } = this.props;

    const webRates = await getWebRateByRoomType(roomType._id);
    const stdRate = webRates.data.find(rate => rate.code === STANDARD_RATE_CODE);
    const maxCapacity = (roomType.capacity.adult || 1) + (roomType.capacity.children || 0);

    let calculation = rate.calculation;

    if (!calculation) {
      const modifierTypeObject = rateModifierTypeConstants.find(type => type.isDefault);

      calculation = {
        amount: modifierTypeObject.isPercentage ? 0.01 : 1,
        type: modifierTypeObject.code,
        isPositive: !!rateModifierSignConstants.find(sign => sign.isDefault && sign.isPositive)
      };
    }

    calculation = {
      ...calculation,
      parent: {
        weekday: stdRate.weekday,
        weekend: stdRate.weekend
      }
    };

    const rateObj = {
      _id: rate._id,
      weekday: rate.weekday,
      weekend: rate.weekend,
      isDerived: rate.isDerived,
      calculation
    };

    const roomsOfRates = [
      {
        roomName: agodaRoomRates.roomData.room_name || '',
        roomId: agodaRoomRates._id || undefined,
        rates:
          agodaRoomRates.rates.length > 0
            ? [{ otaId: String(agodaRoomRates.rates[0].agodaRatePlanId), rate: rateObj, occupancyRate }]
            : [{ otaId: '', rate: rateObj, occupancyRate }],
        maxCapacity
      }
    ];
    return roomsOfRates;
  };

  handleOnSave = () => {
    const { form, onConfirm } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const fieldValues = form.getFieldsValue();
        const rateDistributionPayload = getRateDistributionPayload(fieldValues);

        onConfirm(rateDistributionPayload);
      }
    });
  };

  render() {
    const { form, isVisible, onClose, currency } = this.props;
    const { roomsOfRates, isLoadingRateDistribution } = this.state;
    return (
      <AdvancedOptionsModal isVisible={isVisible} onSave={this.handleOnSave} onClose={onClose}>
        <Tabs defaultActiveKey={'rateDistribution'}>
          <TabPane tab="Rate Distribution" key="rateDistribution">
            <Skeleton loading={isLoadingRateDistribution} active>
              <AdvancedOptionsRateDistributionTab
                form={form}
                roomsOfRates={roomsOfRates}
                isOccupancyRateApplicable={process.env.REACT_APP_ALLOW_AGODA_OCCUPANCY_RATE}
                currency={currency}
              />
            </Skeleton>
          </TabPane>
        </Tabs>
      </AdvancedOptionsModal>
    );
  }
}

AgodaHomesAdvancedOptions.propTypes = {
  agodaRoomRates: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  rate: PropTypes.object.isRequired,
  rateModifierSignConstants: PropTypes.array.isRequired,
  rateModifierTypeConstants: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currency: PropTypes.string
};

AgodaHomesAdvancedOptions.defaultProps = {
  isVisible: false,
  currency: 'RM',
  onConfirm: () => {},
  onClose: () => {}
};

export default Form.create()(AgodaHomesAdvancedOptions);
