import React, { useCallback, useState, useEffect } from 'react';
import { Modal, Form, Radio, Tooltip, Icon, Switch, Empty, message, Select } from 'antd';
import Filter from 'views/OTAIntegration/OTAForm/components/Filter/Filter';
import { errorHandlerWrapper, guard } from 'utils/general';
import { getProperties, getPropertiesWithAgodaSyncStatus } from 'utils/apis/property';
import FormInput from 'components/FormInput/FormInput';
import FormSelection from 'components/FormSelection/FormSelection';
import { getUnits, getUnitsWithSyncStatus, useGetPaginatedUnitListings } from 'utils/apis/unit';
import { getUserRoomTypes } from 'utils/apis/roomtype';
import { pushNewAgodaListing } from 'utils/apis/agodaOnboarding';
import { getAgodaChainIDs } from 'utils/apis/agodaOnboarding';

const _listingType = {
  hotel: {
    code: 'hotel'
  },
  home: {
    code: 'home'
  }
};
const RegisterAgodaModal = props => {
  const { form } = props;
  const [loading, setLoading] = useState(false);
  const [isLoadingPropertyData, setIsLoadingPropertyData] = useState(false);
  const [properties, setProperties] = useState([]);
  const [listingType, setListingType] = useState(_listingType.home.code);

  const [propertyOptions, setPropertyOptions] = useState([]);
  const [availableRoomType, setAvailableRoomType] = useState([]);
  const [availableUnit, setAvailableUnit] = useState([]);

  const [chainIdList, setChainIdList] = useState([]);
  const [disableDueToMissingChainId, setDisableDueToMissingChainId] = useState(false);
  useEffect(() => {
    getChainIDList();
  }, []);

  const fetchPropertyData = async props => {
    setIsLoadingPropertyData(true);

    const localProperties = await errorHandlerWrapper(getPropertiesWithAgodaSyncStatus({ fields: ['_id', 'name', 'host'] }), []);
    console.log(localProperties, 35);
    const propertyOptions = localProperties.map(property => ({
      value: property._id,
      label: property.name,
      isSynced: property.isSynced,
      host: property.host
    }));
    console.log(propertyOptions, 36);
    setProperties(localProperties);
    setPropertyOptions(propertyOptions);
    setIsLoadingPropertyData(false);
  };

  const getChainIDList = async () => {
    const res = await getAgodaChainIDs();
    console.log(res, 20);
    setChainIdList(res);
  };

  const fetchRoomTypeForHotel = async propertyId => {
    const roomTypeList = await errorHandlerWrapper(getUserRoomTypes({ propertyId }));
    setAvailableRoomType(roomTypeList);
  };

  const fetchUnitForHome = async propertyId => {
    const unitList = await errorHandlerWrapper(getUnitsWithSyncStatus(propertyId, {}));
    const unitOptions = unitList.map(property => ({ value: property._id, label: property.name, isSynced: !!property.agoda }));

    setAvailableUnit(unitOptions);
    // if (unitOptions.length > 0) {
    //   setTimeout(() => {
    //     form.setFieldsValue({
    //       unitId: unitOptions[0].value
    //     });
    //   }, 50);
    // }
  };

  useEffect(() => {
    if (props.visible && propertyOptions.length === 0) {
      fetchPropertyData();
    }
  }, [props.visible]);

  const onChangeProperty = e => {
    const propertyName = properties.find(x => x._id === e);
    if (propertyName) {
      props.form.setFieldsValue({
        propertyName: String(propertyName.name).trim()
      });
    }
    console.log(e, propertyName, chainIdList, 93);
    if (chainIdList.filter(x => x.host._id === propertyName.host).length === 0) {
      props.form.setFieldsValue({
        unitId: ''
      });
      setDisableDueToMissingChainId(true);
    } else {
      setDisableDueToMissingChainId(false);
    }

    if (listingType === _listingType.home.code) {
      fetchUnitForHome(e);
    } else {
      fetchRoomTypeForHotel(e);
    }
  };

  const onChangeListingType = e => {
    setListingType(e.target.value);
    form.setFieldsValue({
      propertyId: '',
      propertyName: ''
    });
  };

  const handleSubmit = async () => {
    props.form.validateFields({ first: true }, async (err, values) => {
      if (err) {
        console.log(err);
        return;
      }

      console.log(values);

      if (values.listingType === _listingType.hotel.code) {
        var roomTypeList = [];
        for (const key in values.roomType) {
          if (Object.hasOwnProperty.call(values.roomType, key)) {
            const element = values.roomType[key];
            console.log(element, 104);
            if (element === true) {
              roomTypeList.push(key);
            }
          }
        }

        if (roomTypeList.length < 2) {
          return message.error(
            <span>
              Require atleast 2 room type for hotel category.{' '}
              <a href="/listing-wizard" target="_blank">
                Create room
              </a>
            </span>
          );
        }
      }

      setLoading(true);
      const res = await pushNewAgodaListing(values).catch(err => {
        console.log(err.message, 111);

        switch (err.message) {
          case 'Require atleast 3 images':
            // if (listingType === _listingType.home.code) {
            message.error(
              <span>
                {err.message}.{' '}
                <a target="_blank" href={`/listing/${values.unitId}`}>
                  Upload image
                </a>
              </span>
            );
            // }
            break;

          default:
            message.error(err.message);
            break;
        }
      });
      console.log(res);
      if (res === 'ok') {
        return Modal.success({
          title: 'Done submit, check again after 20 min(s)',
          content: 'Processing in background, please check again after 20 min(s).',
          onOk: () => window.location.reload()
        });
      }

      setLoading(false);
    });
  };

  const agodaHomeSelectUnit = listingType === _listingType.home.code && (
    // <FormSelection
    //   form={form}
    //   name={'unitId'}
    //   formLabel={`Unit to Push`}
    //   placeHolder={`Select property to push`}
    //   selections={availableUnit}
    //   shouldDisable={loading}
    //   allowClear={false}
    //   extraRules={[
    //     {
    //       required: true,
    //       message: 'Unit is required'
    //     }
    //   ]}
    // />

    <Form.Item label="Unit to Push" help={disableDueToMissingChainId && 'Chain ID not registered, contact admin to register chain ID.'}>
      {form.getFieldDecorator('unitId', {
        // initialValue: _listingType.home.code
        rules: [
          {
            required: true,
            message: 'Unit is required'
          }
        ]
      })(
        <Select
          style={{ width: '100%' }}
          optionFilterProp="label"
          filterOption={(e, option) => {
            var regex = new RegExp(e, 'im');
            return regex.test(option.props.children);
          }}
          showSearch
          disabled={disableDueToMissingChainId}
        >
          <Select.OptGroup label="Not connected with Agoda Homes">
            {availableUnit
              .filter(x => x.isSynced === false)
              .map(unit => (
                <Select.Option key={unit.value} value={unit.value}>
                  {unit.label}
                </Select.Option>
              ))}
          </Select.OptGroup>
          <Select.OptGroup label="Connected with Agoda Homes">
            {availableUnit
              .filter(x => x.isSynced === true)
              .map(unit => (
                <Select.Option disabled key={unit.value} value={unit.value}>
                  {unit.label}
                </Select.Option>
              ))}
          </Select.OptGroup>
        </Select>
      )}
    </Form.Item>
  );

  const agodaHotelSelectRoomType =
    listingType === _listingType.hotel.code &&
    (availableRoomType.length !== 0 ? (
      <>
        Room Type
        {availableRoomType.map((roomType, index) => (
          <>
            <Form.Item colon={false} style={{ display: 'flex', marginBottom: 0 }} label={`Sync ${roomType.name} to Agoda`} key={roomType._id}>
              {form.getFieldDecorator(`roomType[${roomType._id}]`, {
                valuePropName: 'checked',
                initialValue: false
                // rules: [
                //   {
                //     required: true,
                //     whitespace: true,
                //     message: "Please input passenger's name or delete this field."
                //   }
                // ]
              })(<Switch checkedChildren={`Yes`} unCheckedChildren={'No'} loading={loading} />)}
            </Form.Item>
          </>
        ))}
      </>
    ) : (
      <div>
        <Empty description="No room type to push" />
      </div>
    ));

  const renderPropertyToPush =
    listingType === _listingType.home.code ? (
      <FormSelection
        form={form}
        name={'propertyId'}
        formLabel={`Property to Push`}
        placeHolder={`Select property to push`}
        selections={propertyOptions}
        shouldDisable={loading || isLoadingPropertyData}
        allowClear={false}
        onChange={onChangeProperty}
        extraRules={[
          {
            required: true,
            message: 'Property is required'
          }
        ]}
      />
    ) : (
      <Form.Item label="Property to Push">
        {form.getFieldDecorator('propertyId', {
          // initialValue: _listingType.home.code
          rules: [
            {
              required: true,
              message: 'Property is required'
            }
          ]
        })(
          <Select
            style={{ width: '100%' }}
            onChange={onChangeProperty}
            showSearch
            optionFilterProp="label"
            filterOption={(e, option) => {
              var regex = new RegExp(e, 'im');
              return regex.test(option.props.children);
            }}
            disabled={isLoadingPropertyData || loading}
          >
            <Select.OptGroup label="Not connected with Agoda Hotel">
              {propertyOptions
                .filter(x => x.isSynced === false)
                .map(unit => (
                  <Select.Option key={unit.value} value={unit.value}>
                    {unit.label}
                  </Select.Option>
                ))}
            </Select.OptGroup>
            <Select.OptGroup label="Connected with Agoda Hotel">
              {propertyOptions
                .filter(x => x.isSynced === true)
                .map(unit => (
                  <Select.Option disabled key={unit.value} value={unit.value}>
                    {unit.label}
                  </Select.Option>
                ))}
            </Select.OptGroup>
          </Select>
        )}
      </Form.Item>
    );

  return (
    <Modal
      title="Push New Listing to Agoda"
      visible={props.visible}
      onCancel={props.onCancel}
      onOk={handleSubmit}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
    >
      <FormInput
        colon
        form={form}
        name="email"
        inputType={'email'}
        disabled={loading}
        label={
          <div>
            <Tooltip title="Insert registered email address in Agoda, if the email is not registered & exists in Agoda account, a new account will be auto-created and informed via email.">
              <Icon type="question-circle" /> Agoda registered email address
            </Tooltip>
          </div>
        }
        placeholder="example@hostplatform.com"
        extraRules={[
          {
            required: true,
            message: 'Email is required'
          },
          {
            validator: (rule, value, callback) => {
              if (
                String(value)
                  .toLowerCase()
                  .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  )
              ) {
                callback();
              } else {
                callback('Invalid email format');
              }
            }
          }
        ]}
      />

      <Form.Item label={'Type'} colon={false}>
        {form.getFieldDecorator('listingType', {
          initialValue: _listingType.home.code
        })(
          <Radio.Group buttonStyle="solid" onChange={onChangeListingType} disabled={loading}>
            <Radio.Button value={_listingType.home.code}>Agoda Homes</Radio.Button>
            <Radio.Button value={_listingType.hotel.code}>Agoda Hotel</Radio.Button>
          </Radio.Group>
        )}
      </Form.Item>

      {renderPropertyToPush}

      <FormInput
        form={form}
        name="propertyName"
        disabled={loading}
        label="Property Name view by Agoda guest"
        // placeholder="example@hostplatform.com"
        extraRules={[
          {
            required: true,
            message: 'Property name is required'
          }
        ]}
      />

      {agodaHomeSelectUnit}
      {agodaHotelSelectRoomType}
    </Modal>
  );
};

export default Form.create()(RegisterAgodaModal);
