import { httpClient } from './../httpClient';
import { apiErrorHandler } from './helpers';
import { useCustomPaginatedQuery, useCustomQuery } from 'hooks/reactQuery';

const BILLING_URI = '/billing';
const BILLING_INVENTORY_URI = `${BILLING_URI}/inventory`;
const ADMIN_BILLING_INVENTORY_URI = `/admin${BILLING_INVENTORY_URI}`;

// =========================================== Admin Panel
export const useGetPaginatedHostInventoryForAdmin = (
  monthWithYear,
  hostId,
  query,
  shouldFetch = !!monthWithYear && !!hostId,
  postProcessHostInventory
) => {
  const getPaginatedHostInventory = async (key, query) => {
    return httpClient
      .get(`${ADMIN_BILLING_INVENTORY_URI}/host/${hostId}`, { params: { ...query, monthWithYear } })
      .then(res => res.data)
      .catch(apiErrorHandler);
  };

  return useCustomPaginatedQuery(
    'inventories',
    getPaginatedHostInventory,
    { ...query, monthWithYear, hostId },
    { shouldFetch, postProcessFunc: postProcessHostInventory }
  );
};

export const useGetHostInventoryCsvDataForAdmin = (monthWithYear, hostId) => {
  const getHostInventoryCsvData = async (key, monthWithYear, hostId) => {
    return httpClient
      .get(`${ADMIN_BILLING_INVENTORY_URI}/host/${hostId}/csv`, { params: { monthWithYear } })
      .then(response => response.data)
      .catch(apiErrorHandler);
  };

  return useCustomQuery('getHostInventoryCsvData', [monthWithYear, hostId], getHostInventoryCsvData, {
    shouldDefaultEmptyObject: false,
    shouldFetch: !!hostId && !!monthWithYear
  });
};

export const useGetHostInventoryUsageForAdmin = (monthWithYear, hostId) => {
  const getHostInventoryUsage = async (key, monthWithYear, hostId) => {
    return httpClient
      .get(`${ADMIN_BILLING_INVENTORY_URI}/host/${hostId}/usage`, { params: { monthWithYear } })
      .then(response => response.data)
      .catch(apiErrorHandler);
  };

  return useCustomQuery('getHostInventoryUsage', [monthWithYear, hostId], getHostInventoryUsage, {
    shouldDefaultEmptyObject: false,
    shouldFetch: !!hostId && !!monthWithYear
  });
};

export const useGetPaginatedInventoryForAdmin = query => {
  const getPaginatedProductStatistics = async (key, query) => {
    return httpClient
      .get(ADMIN_BILLING_INVENTORY_URI, { params: query })
      .then(res => res.data)
      .catch(apiErrorHandler);
  };
  return useCustomPaginatedQuery('hosts', getPaginatedProductStatistics, query, { shouldFetch: !!query.monthWithYear });
};

export const useGetInventoryCsvDataForAdmin = monthWithYear => {
  const getPlanStatisticsCsvDataForAdmin = async (key, monthWithYear) => {
    return httpClient
      .get(`${ADMIN_BILLING_INVENTORY_URI}/csv`, { params: { monthWithYear } })
      .then(response => response.data)
      .catch(apiErrorHandler);
  };

  return useCustomQuery('getPlanStatisticsCsvDataForAdmin', [monthWithYear], getPlanStatisticsCsvDataForAdmin, {
    shouldDefaultEmptyObject: false,
    shouldFetch: !!monthWithYear
  });
};

export const useGetInventoryUsageForAdmin = monthWithYear => {
  const getPlanStatisticsUsageForAdmin = async (key, monthWithYear) => {
    return httpClient
      .get(`${ADMIN_BILLING_INVENTORY_URI}/usage`, { params: { monthWithYear } })
      .then(response => response.data)
      .catch(apiErrorHandler);
  };

  return useCustomQuery('getPlanStatisticsUsageForAdmin', [monthWithYear], getPlanStatisticsUsageForAdmin, {
    shouldDefaultEmptyObject: false,
    shouldFetch: !!monthWithYear
  });
};

// =========================================== Host Platform
export const useGetPaginatedHostInventory = (query, hostId) => {
  const getPaginatedHostInventory = async (key, query) => {
    return httpClient
      .get(`${BILLING_INVENTORY_URI}/host/${query.hostId}`, { params: query })
      .then(res => res.data)
      .catch(apiErrorHandler);
  };

  return useCustomPaginatedQuery('inventories', getPaginatedHostInventory, { ...query, hostId }, { shouldFetch: !!hostId && !!query.monthWithYear });
};

export const useGetHostInventoryCsvData = (monthWithYear, hostId) => {
  const getHostInventoryCsvData = async (key, monthWithYear, hostId) => {
    return httpClient
      .get(`${BILLING_INVENTORY_URI}/host/${hostId}/csv`, { params: { monthWithYear } })
      .then(response => response.data)
      .catch(apiErrorHandler);
  };

  return useCustomQuery('getHostInventoryCsvData', [monthWithYear, hostId], getHostInventoryCsvData, {
    shouldDefaultEmptyObject: false,
    shouldFetch: !!hostId && !!monthWithYear
  });
};

export const useGetHostInventoryUsage = (monthWithYear, hostId) => {
  const getHostInventoryUsage = async (key, monthWithYear, hostId) => {
    return httpClient
      .get(`${BILLING_INVENTORY_URI}/host/${hostId}/usage`, { params: { monthWithYear } })
      .then(response => response.data)
      .catch(apiErrorHandler);
  };

  return useCustomQuery('getHostInventoryUsage', [monthWithYear, hostId], getHostInventoryUsage, {
    shouldDefaultEmptyObject: false,
    shouldFetch: !!hostId && !!monthWithYear
  });
};
