import React, { Component } from 'react';
import { Input, Icon, Button, Table, Card } from 'antd';
import './ActivityLog.css';
import moment from 'moment';
import { getAudits } from './../../utils/apis/audit';
import { CSVLink } from 'react-csv';

class ActivityLog extends Component {
  constructor() {
    super();
    this.state = {
      users: [],
      data: [],
      searchDateText: '',
      searchActivityText: '',
      searchUserText: '',
      searchUrlText: '',
      searchBodyText: '',
      isLoading: true
    };
  }

  async componentDidMount() {
    let [audits] = await Promise.all([getAudits()]);
    if (audits && audits.status === 200) {
      let resData = audits.data.map(audits => {
        let utc = audits.datetime
          ? moment
              .utc(audits.datetime)
              .local()
              .format('YYYY-MM-DD HH:mm:ss')
          : 'N/A';
        return {
          date: utc,
          activity: audits.action,
          url: audits.model,
          body: audits.objectLabel,
          user: audits.username
        };
      });
      // This sorts by date
      resData.sort(function compare(a, b) {
        let dateA = new Date(a.date);
        let dateB = new Date(b.date);
        return dateB - dateA;
      });
      this.setState({
        data: resData,
        isLoading: false
      });
    }
  }

  handleSearch = (selectedKeys, confirm, name) => () => {
    confirm();
    this.setState({ [name]: selectedKeys[0] });
  };

  handleReset = (clearFilters, name) => () => {
    clearFilters();
    this.setState({ [name]: '' });
  };

  getColumns = () => {
    return [
      {
        title: 'Date & Time',
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => a.date.localeCompare(b.date),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder="Search date"
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchDateText"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => record.date.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        }
      },
      {
        title: 'Module',
        dataIndex: 'url',
        key: 'url',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.url - b.url,
        render: (text, record) => {
          return text ? <span>{text}</span> : 'N/A';
        },
        filters: Array.from(
          new Set(
            this.state.data.map(item => {
              return item.url;
            })
          )
        ).map(item => {
          return { text: item, value: item };
        }),
        onFilter: (value, record) => record.url === value
      },
      {
        title: 'By User',
        dataIndex: 'user',
        key: 'user',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.user - b.user,
        render: (text, record) => {
          return text ? <span>{text}</span> : 'N/A';
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder="Search user"
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchUserText"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => (record.user ? record.user.toLowerCase().includes(value.toLowerCase()) : ''),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        }
      },

      {
        title: 'Activity',
        dataIndex: 'activity',
        key: 'activity',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.activity - b.activity,
        render: (text, record) => {
          return text ? <span>{text}</span> : 'N/A';
        },
        filters: Array.from(
          new Set(
            this.state.data.map(item => {
              return item.activity;
            })
          )
        ).map(item => {
          return { text: item, value: item };
        }),
        onFilter: (value, record) => record.activity === value
      },

      {
        title: 'Item',
        dataIndex: 'body',
        key: 'body',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.body - b.body,
        render: (text, record) => {
          return text ? <span>{text}</span> : 'N/A';
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div className="custom-filter-dropdown">
            <Input
              ref={ele => (this.searchInput = ele)}
              placeholder="Search field"
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={this.handleSearch(selectedKeys, confirm)}
              name="searchBodyText"
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
              Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#00b9c6' : '#aaa' }} />,
        onFilter: (value, record) => record.body.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus();
            });
          }
        }
      }
    ];
  };

  constructCSV = () => {
    const { data } = this.state;
    return data.map(data => {
      return {
        'Date & Time': moment.parseZone(data.date).format('DD-MMM-YY HH:mm:ss'),
        Activity: data.activity,
        URL: data.url,
        Field: data.body,
        'By User': data.user
      };
    });
  };

  render() {
    const { data, isLoading } = this.state;
    return (
      <Card className="al-list-card" loading={isLoading}>
        <div className="csvContainer">
          <CSVLink filename="activitylog.csv" data={this.constructCSV()}>
            <Button type="primary" icon="download">
              Download CSV
            </Button>
          </CSVLink>
        </div>
        <Table columns={this.getColumns()} dataSource={data} scroll={{ x: 1000 }} loading={isLoading} />
      </Card>
    );
  }
}
export default ActivityLog;
